import {types} from './constants';
import {types as authTypes} from '../../actions/auth/actionTypes';
import initState from '../../reducers/redux-initial-state';
import {
  getOrganizationsDataForTable,
  getUpdatedOrganizationIndex,
} from './service';
import {getAllowedBlockedNetworkChartData, getValidData} from '../../utils/dashboard';

export default (state = initState.organizationsReducer, action) => {
  switch (action.type) {
    case authTypes.ACTION_LOGOUT_SUCCESS: {
      return {
        ...initState.organizationsReducer,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_ORGANIZATION_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        organizationState: action.organizationState,
      };
    }
    case types.SET_ORGANIZATIONS: {
      return {
        ...state,
        organizations: action.data,
      };
    }
    case types.SET_ORGANIZATION_NETWORKS_STATS: {
      return {
        ...state,
        networkStatsByOrganization: action.data,
      };
    }
    case types.SET_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.data,
      };
    }
    case types.SET_ORGANIZATIONS_TABLE_DATA: {
      return {
        ...state,
        organizationsTableData: getOrganizationsDataForTable(
          state.organizations,
          action.organizationsStats,
        ),
      };
    }
    case types.SET_SELECTED_ORGANIZATION_INDEX: {
      return {
        ...state,
        selectedOrganizationIndex: action.selectedOrganizationIndex,
      };
    }
    case types.SET_UPDATED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganizationIndex: getUpdatedOrganizationIndex(action.data.id, state.organizations),
        selectedOrganization: action.data,
      };
    }
    case types.RESET_SELECTED_ORGANIZATION_INDEX: {
      return {
        ...state,
        selectedOrganizationIndex: initState.organizationsReducer.selectedOrganizationIndex,
        selectedOrganization: initState.organizationsReducer.selectedOrganization,
      };
    }
    case types.RESET_ORGANIZATIONS: {
      return {
        ...state,
        organizations: initState.organizationsReducer.organizations,
      };
    }
    case types.SET_ORGANIZATION_STATS: {
      return {
        ...state,
        requestsPerSecond: getValidData(action.data.requestsPerSecond),
        requestsByDecision: action.data.requestsByDecision,
      };
    }
    case types.SET_ORGANIZATION_THREATS: {
      return {
        ...state,
        threatStats: action.data,
      };
    }
    case types.SET_ORGANIZATION_REQUESTS_GRAPH: {
      return {
        ...state,
        userRequestsGraph: {
          chartData: getAllowedBlockedNetworkChartData(
            action.data,
            action.period,
          ),
        },
      };
    }
    default:
      return state;
  }
};

import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export const getClientLocalDomainsData = (data) => data.map((item) => ({
  ...item,
  resolverIps: (item.useDeviceResolvers) ? [] : item.resolverIps,
  resolvers: (item.useDeviceResolvers) ? 'Auto' : item.resolverIps.join(', '),
}));

export const getUpdatedLocalDomainIndex = (domainId, localDomains) =>
  _findIndex(localDomains, (bundle) => _isEqual(bundle.id, domainId));

export const getLocalForwardingToCreateOrUpdate = (localForwarding) => {
  const newOrUpdatedLocalForwarding = {...localForwarding};
  if (localForwarding.useDeviceResolvers) {
    newOrUpdatedLocalForwarding.resolverIps = [];
  }
  return newOrUpdatedLocalForwarding;
};

export const getEditableLocalForwardingInfo = (localForwarding) => {
  const editableLocalForwardingInfo = {...localForwarding};
  if (localForwarding.resolverIps.length === 0) {
    editableLocalForwardingInfo.resolverIps = [''];
  }
  return editableLocalForwardingInfo;
};

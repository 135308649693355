export const types = {
  SET_EDITABLE_WHITE_BLACK_LIST_INFO: 'SET_EDITABLE_WHITE_BLACK_LIST_INFO',
  SET_VALIDATION_WHITE_BLACK_LIST_INFO: 'SET_VALIDATION_WHITE_BLACK_LIST_INFO',
  RESET_WHITE_BLACK_LIST_FORM_DATA: 'RESET_WHITE_BLACK_LIST_FORM_DATA',
  UPDATE_WHITE_BLACK_LIST_INFO: 'UPDATE_WHITE_BLACK_LIST_INFO',
};

export function getEditableWhiteBlackListInfo(whiteBlackList) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_WHITE_BLACK_LIST_INFO,
      data: whiteBlackList,
    });
  };
}

export function updateWhiteBlackListInfo(updatedWhiteBlackList, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_WHITE_BLACK_LIST_INFO,
      data: updatedWhiteBlackList,
      isValidWhiteBlackListInfo: isValid,
    });
  };
}

export function resetWhiteBlackListEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_WHITE_BLACK_LIST_FORM_DATA,
    });
  };
}

export function setValidationResultWhiteBlackListInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_WHITE_BLACK_LIST_INFO,
      isValid,
    });
  };
}

export const getPersonaBaseSettings = (persona) => ({
  id: persona?.id ?? '',
  name: persona?.name ?? '',
  description: persona?.description ?? '',
});

export const getPersonaDirectoryServiceSettings = (persona) => ({
  domain: persona?.adDomain?.fqdn ?? '',
  domainId: persona?.adDomain?.id,
  configuredGroups: persona?.configuredGroups ?? [],
  observedGroups: persona?.observedGroups ?? [],
});

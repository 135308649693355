import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import _get from 'lodash/get';
import {
  USER_ROLES,
  getRightsByRoleAndPermission,
  getDefaultPermissionByRole,
} from '../../constants';

// by fact role and permission availability check
function RightAvailability(props) {
  const {
    alternativeChild,
    role,
    children,
    currentOrganization,
    accessRights,
    overrideRights,
  } = props;

  let child;

  // for a few cases when need to check rights outside of static setup
  // TODO: override not only to allow, but also to forbid, if needed
  if (overrideRights) {
    child = children;
    return child;
  }

  // define by role if no organization is chosen
  const currentUserPermission = _get(
    currentOrganization,
    'permission',
    getDefaultPermissionByRole(role),
  );

  // get rightList for current role and permission
  const userRightsList = getRightsByRoleAndPermission(role, currentUserPermission);
  // console.log("For component of type ");
  // console.log(children);
  // console.log("Required rights:");
  // console.log(accessRights);
  // console.log("User rights:");
  // console.log(userRightsList);

  if (userRightsList != null && accessRights.every((right) => userRightsList.includes(right))) {
    child = children;
  } else {
    child = alternativeChild;
  }

  return child;
}

RightAvailability.propTypes = {
  alternativeChild: PropTypes.node,
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
  currentOrganization: PropTypes.object,
  accessRights: PropTypes.array.isRequired,
  overrideRights: PropTypes.bool,
};

RightAvailability.defaultProps = {
  alternativeChild: null,
  role: USER_ROLES.VIEWER,
  currentOrganization: null,
  overrideRights: false,
};

const mapStateToProps = (state) => ({
  role: state.userAccountsReducer.loggedAccount.role,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

export default connect(mapStateToProps, null)(RightAvailability);

import {post, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export function updateEmail(email) {
  const url = makeUrl(METHODS.UPDATE_EMAIL);
  const data = {email};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Updating of email failed', error);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update email:', error);
      });
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  CustomButton,
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {
  RenderOrEmpty,
  SectionLinkInUnauthForm,
} from '../index';

import {Visibility, VisibilityOff} from '../../icons';

import {
  ROUTES,
  VALID_PASSWORD_LENGTH,
} from '../../constants';

import style from './style';

class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      validation: {
        areEqualNewPasswords: true,
        isValidNewPassword: true,
      },
      showNewPassword: false,
      showConfirmNewPassword: false,
      submitDisabled: true,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onBlurNewPassword = (e) => {
    const {
      validation,
      confirmNewPassword,
    } = this.state;
    const newPassword = e.target.value.trim();
    this.setState({newPassword});

    const newValidation = _cloneDeep(validation);
    newValidation.isValidNewPassword = newPassword.length >= VALID_PASSWORD_LENGTH;
    if (!_isEqual(confirmNewPassword, '')) {
      newValidation.areEqualNewPasswords = _isEqual(confirmNewPassword, newPassword);
    }
    this.setState({
      validation: newValidation,
      submitDisabled: !(newValidation.areEqualNewPasswords && newValidation.isValidNewPassword),
    });
  };

  onBlurConfirmPassword = (e) => {
    const {
      validation,
      newPassword,
    } = this.state;
    const confirmNewPassword = e.target.value.trim();
    this.setState({confirmNewPassword});

    const areEqualNewPasswords = _isEqual(newPassword, confirmNewPassword);
    this.setState({
      validation: {
        isValidNewPassword: validation.isValidNewPassword,
        areEqualNewPasswords: areEqualNewPasswords,
      },
      submitDisabled: !(areEqualNewPasswords && validation.isValidNewPassword),
    });
  };

  onSubmit = () => {
    const {newPassword} = this.state;
    const {token} = this.props;

    this.props.updatePassword({
      token: token,
      password: newPassword,
    });
    this.setState({submitDisabled: true});
  };

  getEndAdornment = (showPassword, onClick) => (
    <InputAdornment position="end">
      <IconButton
        onClick={onClick}
        onMouseDown={this.handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  showHidePassword = (passwordName) => {
    this.setState((prevState) => ({
      [passwordName]: !prevState[passwordName],
    }));
  };

  showHideNewPassword = () => this.showHidePassword('showNewPassword');

  showHideConfirmNewPassword = () => this.showHidePassword('showConfirmNewPassword');

  render() {
    const {
      newPassword,
      confirmNewPassword,
      validation,
      showNewPassword,
      showConfirmNewPassword,
      submitDisabled,
    } = this.state;

    const {
      classes,
      isUpdatingError,
    } = this.props;

    const propsInput = {
      newPassword: {
        value: newPassword,
        name: 'newPassword',
        onBlur: this.onBlurNewPassword,
        onChange: this.onChange,
        type: showNewPassword ? 'text' : 'password',
        endAdornment: this.getEndAdornment(showNewPassword, this.showHideNewPassword),
      },
      confirmNewPassword: {
        value: confirmNewPassword,
        name: 'confirmNewPassword',
        onBlur: this.onBlurConfirmPassword,
        onChange: this.onChange,
        type: showConfirmNewPassword ? 'text' : 'password',
        endAdornment: this.getEndAdornment(showConfirmNewPassword, this.showHideConfirmNewPassword),
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };
    return (
      <>
        <RenderOrEmpty isShow={isUpdatingError}>
          <div className={classes.updatePasswordForm__errorMessage}>
            {I18n.t('profilePage.passwordBlock.errorMessages.tokenIsIncorrect')}
          </div>
        </RenderOrEmpty>
        <CustomInput
          errorText={validation.isValidNewPassword
            ? undefined
            : I18n.t(
              'profilePage.passwordBlock.errorMessages.newPasswordIsNotValid',
              {
                count: VALID_PASSWORD_LENGTH - 1,
              },
            )}
          error={!validation.isValidNewPassword}
          labelText={I18n.t('profilePage.passwordBlock.newPassword')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.newPassword}
        />
        <CustomInput
          errorText={validation.areEqualNewPasswords
            ? undefined
            : I18n.t('profilePage.passwordBlock.errorMessages.newPasswordsDoNotMatch')}
          error={!validation.areEqualNewPasswords}
          labelText={I18n.t('profilePage.passwordBlock.confirmNewPassword')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.confirmNewPassword}
        />
        <CustomButton
          fullWidth={true}
          disabled={submitDisabled}
          color="secondary"
          customClasses="unauthorizedForm__button"
          onClick={this.onSubmit}
        >
          {I18n.t('profilePage.passwordBlock.update')}
        </CustomButton>
        <SectionLinkInUnauthForm
          text={I18n.t('profilePage.passwordBlock.backToLogin')}
          to={ROUTES.LOGIN}
        />
      </>
    );
  }
}

UpdatePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isUpdatingError: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

UpdatePasswordForm.defaultProps = {};

export default withStyles(style)(UpdatePasswordForm);

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  getLocalDomainToCreate,
  getLocalDomainToUpdate,
  getLocalDomain,
} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';

export const types = {
  CHANGE_EDIT_MODE_AND_LOCAL_DOMAIN_STATE: 'CHANGE_EDIT_MODE_AND_LOCAL_DOMAIN_STATE',
  SET_LOCAL_DOMAINS: 'SET_LOCAL_DOMAINS',
  SET_LOCAL_DOMAINS_TABLE_DATA: 'SET_LOCAL_DOMAINS_TABLE_DATA',
  SET_SELECTED_LOCAL_DOMAIN: 'SET_SELECTED_LOCAL_DOMAIN',
  SET_SELECTED_LOCAL_DOMAIN_INDEX: 'SET_SELECTED_LOCAL_DOMAIN_INDEX',
  SET_UPDATED_LOCAL_DOMAIN: 'SET_UPDATED_LOCAL_DOMAIN',
  RESET_SELECTED_LOCAL_DOMAIN_INDEX: 'RESET_SELECTED_LOCAL_DOMAIN_INDEX',
};

export function getLocalDomainById(localDomainId) {
  const url = makeUrl(METHODS.GET_LOCAL_DOMAIN_BY_ID, {id: localDomainId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = getLocalDomain(response.data);
          dispatch({
            type: types.SET_SELECTED_LOCAL_DOMAIN,
            data,
          });
        }
      });
}

export function createLocalDomain(newLocalDomain, campusId) {
  const url = makeUrl(METHODS.CREATE_LOCAL_DOMAIN);
  const data = getLocalDomainToCreate(newLocalDomain, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndLocalDomainState(false, STATES_ENTITY.NONE));
          dispatch(updateLocalDomainsTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create local domain:', error);
      });
}

export function updateLocalDomain(updatedLocalDomain, campusId) {
  const url = makeUrl(METHODS.UPDATE_LOCAL_DOMAIN);
  const data = getLocalDomainToUpdate(updatedLocalDomain, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndLocalDomainState(false, STATES_ENTITY.NONE));
          dispatch(updateLocalDomainsTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit local domain:', error);
      });
}

export function deleteLocalDomain(localDomainId, campusId) {
  const data = {
    id: localDomainId,
  };

  const url = makeUrl(METHODS.DELETE_LOCAL_DOMAIN);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(updateLocalDomainsTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete local domain:', error);
      });
}

export function getDataToShowLocalDomainDetails(localDomainId, localDomainIndex) {
  return (dispatch) => {
    dispatch(getLocalDomainById(localDomainId));
    dispatch(setSelectedLocalDomainIndex(localDomainIndex));
  };
}

export function getLocalDomains(campusId) {
  const url = makeUrl(METHODS.GET_LOCAL_DOMAINS, {locationId: campusId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_LOCAL_DOMAINS,
            data,
          });
          dispatch({
            type: types.SET_LOCAL_DOMAINS_TABLE_DATA,
          });
        }
      });
}

export function setLocalDomainsTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_LOCAL_DOMAINS_TABLE_DATA,
    });
  };
}

export function changeEditModeAndLocalDomainState(isEditMode, localDomainState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_LOCAL_DOMAIN_STATE,
      isEditMode,
      localDomainState: localDomainState,
    });
  };
}

export function setSelectedLocalDomainIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_LOCAL_DOMAIN_INDEX,
      selectedLocalDomainIndex: index,
    });
  };
}

export function resetSelectedLocalDomainIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_LOCAL_DOMAIN_INDEX,
    });
  };
}

export function updateLocalDomainsTabData(campusId) {
  return (dispatch) => {
    dispatch(getLocalDomains(campusId));
  };
}

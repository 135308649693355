import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import style from './style';

function DeleteEntityModalWindow(props) {
  const {
    classes,
    text,
  } = props;

  const dialogClasses = {
    paper: classes.modalWindow__paper,
    root: classes.modalWindow,
  };
  const dialogTitleClasses = {
    root: classes.modalWindow__titleRoot,
  };
  const dialogActionsClasses = {
    root: classes.modalWindow__actions,
  };

  return (
    <Dialog
      open={true}
      onClose={props.cancel}
      classes={dialogClasses}
    >
      <DialogTitle classes={dialogTitleClasses}>
        <p
          className={classNames(
            classes.modalWindow__title,
            classes.modalWindow__title_deleteEntity,
          )}
        >
          {text}
        </p>
      </DialogTitle>
      <DialogActions classes={dialogActionsClasses}>
        <CustomButton
          customClasses="modalWindow__button"
          color="red"
          onClick={props.confirm}
        >
          {I18n.t('modalWindow.buttonConfirm')}
        </CustomButton>
        <CustomButton
          customClasses="modalWindow__button"
          color="red"
          onClick={props.cancel}
        >
          {I18n.t('modalWindow.buttonCancel')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

DeleteEntityModalWindow.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default withStyles(style)(DeleteEntityModalWindow);

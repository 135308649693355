import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {InfiniteListWithViewModelsCard, StatusIndicator} from '../index';
import {formatPolicy} from '../../utils/policy';

import style from './style';

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.statusIndicatorCellRenderer = (rowInfo) => (
      <StatusIndicator status={`DEVICE_AGENT_${rowInfo.value}`} />
    );
    this.policyRenderer = (rowInfo) =>
      formatPolicy(rowInfo.original.policy, rowInfo.original.policyType, I18n.t);
    this.groupsRenderer = (rowInfo) => {
      const groups = rowInfo.original.groups;
      const topGroupIdx = rowInfo.original.topGroupIdx;
      if (_isNil(groups) || _isEmpty(groups)) {
        return '-';
      }
      if (groups.length === 1) {
        return groups[0].name;
      }
      const groupToShow = (!_isNil(topGroupIdx)) ? groups[topGroupIdx] : groups[0];
      return `${groupToShow.name} (+${groups.length - 1})`;
    };
  }

  get fullScreenColumns() {
    return [
      {
        Header: I18n.t('users.usersManager.table.headers.username'),
        accessor: 'username',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.domain'),
        accessor: 'domain',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      // {
      //   Header: I18n.t('users.usersManager.table.headers.organization'),
      //   accessor: 'organization',
      //   minWidth: 100,
      //   filterable: false,
      //   sortable: true,
      //   isShow: true,
      // },
      // {
      //   Header: I18n.t('users.usersManager.table.headers.persona'),
      //   accessor: 'persona',
      //   minWidth: 100,
      //   filterable: false,
      //   sortable: true,
      //   isShow: true,
      // },
      {
        Header: I18n.t('users.usersManager.table.headers.userStatus'),
        accessor: 'userStatus',
        Cell: this.statusIndicatorCellRenderer,
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.lastSeen'),
        accessor: 'lastSeenAt',
        minWidth: 200,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.lastDevice'),
        accessor: 'lastDevice',
        minWidth: 200,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.site'),
        accessor: 'site',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.groups'),
        accessor: 'group',
        Cell: this.groupsRenderer,
        minWidth: 100,
        filterable: false,
        sortable: false,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.policy'),
        accessor: 'policy',
        Cell: this.policyRenderer,
        minWidth: 100,
        filterable: false,
        sortable: false,
        isShow: true,
      },
    ];
  }

  get compressedColumn() {
    return [
      {
        Header: I18n.t('users.usersManager.table.headers.user'),
        accessor: 'name',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
    ];
  }

  render() {
    const {
      classes,
      customClassNames,
      tableData,
      mode,
      selectedUserIndex,
      minRows,
      isEditMode,
      loadData,
      handleClickRow,
    } = this.props;

    const tableClassNameObj = {
      tbody: classNames(
        classes.usersTable__tbodyInfinite,
        {
          [classes.tbodyInfinite_noItems]: tableData.length === 0,
          [classes[customClassNames.tbody]]: !_isNil(customClassNames.tbody),
        },
      ),
      header: {
        root: classes.usersTableCard__header,
        title: classes.usersTableCard__title,
        action: classes.usersTableCard__action,
      },
      card: classes.usersTableCard,
      card__content: classes.usersTableCard__content,
    };

    return (
      <InfiniteListWithViewModelsCard
          tableData={tableData}
          title={I18n.t('users.usersManager.table.title')}
          headersFullWidthMode={this.fullScreenColumns}
          headersCompressedMode={this.compressedColumn}
          mode={mode}
          minRows={minRows}
          fullscreen={false}
          disabled={isEditMode}
          entityType={I18n.t('entitiesTypes.user')}
          selectedEntityIndex={selectedUserIndex}
          className={classes.usersTable__table}
          classNamesObj={tableClassNameObj}
          handleClickRow={handleClickRow}
          loadData={loadData}
      />
    );
  }
}

UsersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  customClassNames: PropTypes.object,
  tableData: PropTypes.array,
  minRows: PropTypes.number,
  isEditMode: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  selectedUserIndex: PropTypes.number,

  loadData: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func,
};

UsersTable.defaultProps = {
  tableData: [],
  minRows: 10,
  customClassNames: {},
  isEditMode: false,
  selectedUserIndex: null,
  handleClickRow: () => {},
};

export default withStyles(style)(UsersTable);

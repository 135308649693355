import {
  defaultFont,
  secondaryColor,
} from '../../styles/style';

const style = {
  notificationBanner: {
    display: 'block',
    ...defaultFont,
    flexGrow: '0',
    flexWrap: 'unset',
    position: 'relative',
    lineHeight: '20px',
    fontSize: '14px',
    backgroundColor: secondaryColor,
    color: '#FFF',
    borderRadius: '3px',
    boxShadow: 'none',
    padding: '3px 20px',
    minWidth: '295px',
  },
  notificationBanner__message: {
    padding: '0',
    display: 'block',
  },
  notificationBanner__text: {
    paddingLeft: '50px',
    display: 'block',
  },
  notificationBanner__icon: {
    'display': 'block',
    'left': '15px',
    'position': 'absolute',
    'top': '50%',
    'marginTop': '-20px',
    'width': '38px',
    'height': '38px',
    'fontSize': '20px',
    'borderRadius': '50%',
    'maxWidth': '38px',
    'cursor': 'auto',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  notificationBanner__text_link: {
    '&:link,&:visited': {
      color: '#FFF',
      textDecoration: 'underline',
    },
    '&:hover,&active': {
      color: '#FFF',
      textDecoration: 'none',
    },
  },
};

export default style;

import _get from 'lodash/get';
import {getRootGroups, getSubGroupsOfGroup as getSubGroupsOfGroupApi} from '../../api/categories';

export const types = {
  COPY_POLICY: 'COPY_POLICY',
  RESET_SELECTED_POLICY_TEMPLATE_INDEX: 'RESET_SELECTED_POLICY_TEMPLATE_INDEX',
  RESET_POLICY_FORM_VALUES: 'RESET_POLICY_FORM_VALUES',
  SET_APPLICATIONS_TAB_FORM_SCHEME: 'SET_APPLICATIONS_TAB_FORM_SCHEME',
  SET_CONTENT_TAB_FORM_SCHEME: 'SET_APPLICATION_TAB_FORM_SCHEME',
  SET_DEFAULT_POLICIES: 'SET_DEFAULT_POLICIES',
  SET_POLICY_FORM_VALUES: 'SET_POLICY_FORM_VALUES',
  SET_REGIONS_TAB_FORM_SCHEME: 'SET_REGIONS_TAB_FORM_SCHEME',
  SET_THREATS_TAB_FORM_SCHEME: 'SET_THREATS_TAB_FORM_SCHEME',
  SET_SELECTED_POLICY_TEMPLATE_INDEX: 'SET_SELECTED_POLICY_TEMPLATE_INDEX',
  UPDATE_ADVANCED_SETTING: 'UPDATE_ADVANCED_SETTING',
  UPDATE_BASE_SETTING: 'UPDATE_BASE_SETTING',
  UPDATE_CATEGORIES_STATUS: 'UPDATE_CATEGORIES_STATUS',
  UPDATE_STRATEGY_STATUS: 'UPDATE_STRATEGY_STATUS',
  SET_POLICY_VALIDATION: 'SET_POLICY_VALIDATION',
};

export function copyPolicy(newPolicy, currentPolicy) {
  return (dispatch) => {
    dispatch({
      type: types.COPY_POLICY,
      newPolicy: newPolicy,
      currentPolicy: currentPolicy,
    });
  };
}

export function getPolicyFormValues(policy) {
  return (dispatch) => {
    dispatch({
      type: types.SET_POLICY_FORM_VALUES,
      data: policy,
    });
  };
}

export function getTabFormSchemes() {
  return (dispatch) => {
    dispatch(getRootGroups()).then((rootGroups) => {
      rootGroups.forEach((group) => dispatch(getSubGroupsOfGroup(group)));
    });
  };
}

export function updateCategoryStatus(category) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CATEGORIES_STATUS,
      categoryName: category.name,
      categoryStatus: category.status,
    });
  };
}

export function updateStrategyStatus(strategyName, strategyValue) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_STRATEGY_STATUS,
      name: strategyName,
      value: strategyValue,
    });
  };
}

export function updateAdvancedSettings(settingName, settingObject) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ADVANCED_SETTING,
      settingName: settingName,
      settingObject: settingObject,
    });
  };
}

export function updateBaseSetting(settingName, settingObject) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_BASE_SETTING,
      settingName: settingName,
      settingObject: settingObject,
    });
  };
}

export function resetSelectedPolicyTemplateIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_POLICY_TEMPLATE_INDEX,
    });
  };
}

export function resetPolicyFormValues() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_POLICY_FORM_VALUES,
    });
  };
}

export function setSelectedPolicyTemplateIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_POLICY_TEMPLATE_INDEX,
      index,
    });
  };
}

export function setPolicyValidation(isValid) {
  return (dispatch) =>
    dispatch({
      type: types.SET_POLICY_VALIDATION,
      isValid,
    });
}

function getSubGroupsOfGroup(groupName) {
  return (dispatch) =>
    dispatch(getSubGroupsOfGroupApi(groupName))
      .then((data) => dispatch({
        type: _get(types, `SET_${groupName.toUpperCase()}_TAB_FORM_SCHEME`, ''),
        data,
        groupName,
      }));
}

import React from 'react';
import PropTypes from 'prop-types';

import {ArrowForward} from '../../icons/index';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

function ButtonForward(props) {
  const {
    customClasses,
    disabled,
    onClick,
  } = props;

  return (
    <CustomButton
      color="primaryNoBackground"
      customClasses={customClasses}
      onClick={onClick}
      disabled={disabled}
      size="xs"
    >
      <ArrowForward />
    </CustomButton>
  );
}

ButtonForward.propTypes = {
  customClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ButtonForward.defaultProps = {
  customClasses: '',
  disabled: false,
};

export default ButtonForward;

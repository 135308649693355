import {types} from './constants';

export function resetBundleEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CLIENT_BUNDLE_EDIT_FORM_DATA,
    });
  };
}

export function updateBundleInfo(updatedBundleInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CLIENT_BUNDLE_INFO,
      bundle: updatedBundleInfo,
      isValidBundleInfo: isValid,
    });
  };
}

export function setValidationResultBundleInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_CLIENT_BUNDLE_INFO,
      isValid,
    });
  };
}

export function setSelectedPlatformIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_PLATFORM_INDEX,
      index,
    });
  };
}

export function setSelectedArchitectureIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_ARCHITECTURE_INDEX,
      index,
    });
  };
}

export function setSelectedDurationIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_DURATION_INDEX,
      index,
    });
  };
}

export function setArchitectures(architectures) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ARCHITECTURES,
      architectures: architectures || [],
    });
  };
}

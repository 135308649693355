const style = {
  form: {},
  form__ipsBlock: {
    marginTop: '0',
  },
  form__ipsFormControl: {
    padding: '5px',
  },
  form__switch: {
    position: 'relative',
    marginTop: '15px',
  },
};

export default style;

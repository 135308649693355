import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';

import {WB_LIST_TYPE} from '../../app-common/Insights/constants';
import {IconMenu, ModalWindow, RenderOrEmpty} from '../../components';
import {Language, MoreVert} from '../../icons';
import {yellowColor} from '../../styles/style';

import {addDomainToWBlist} from './action';
import {ACCESS_RIGHTS} from '../../constants';
import checkAvailability from '../availability/CheckAvailability';

const rightAvailabilityMap = {
  addOrRemoveToWbList: [
    ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG,
  ],
};

class WbListActionsContainer extends React.Component {
  static INIT_CONFIRMATION_STATE = {
    listType: null,
    listId: null,
    listName: null,
    domain: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmation: {...WbListActionsContainer.INIT_CONFIRMATION_STATE},
    };
  }

  onWBListClick = (list, props) => {
    const domain = props.domainName ? props.domainName : props.fqdnName;

    return this.setConfirmationDataToState(
      list.type,
      list.id,
      list.name,
      domain.replace(/.$/, ''),
    );
  };

  get wbLists() {
    const whiteLists = [];
    const blackLists = [];
    this.props.wbLists.forEach((wbl) => {
      const list = {
        ...wbl,
        label: (
          <div className="wb-list-item">
            {wbl.label}
            {wbl.isGlobal && <Language />}
          </div>
        ),
        onClick: this.onWBListClick,
      };
      whiteLists.push({
        ...list,
        type: WB_LIST_TYPE.ALLOW,
      });
      blackLists.push({
        ...list,
        type: WB_LIST_TYPE.BLOCK,
      });
    });
    return {whiteLists, blackLists};
  }

  get confirmationMessage() {
    const {
      listType,
      listName,
      domain,
    } = this.state.confirmation;
    const {withSubDomains} = this.props;
    const subDomains = `*.${domain}`;
    const highlightedText = {color: yellowColor};
    const action = I18n.t(`commonRowsActions.confirmation.addToWbList.action.${listType}`);
    return (
      <>
        {I18n.t('commonRowsActions.confirmation.addToWbList.message', {action})}
        <span style={highlightedText}>{domain}</span>
        <RenderOrEmpty isShow={withSubDomains}>
          &nbsp;and&nbsp;
          <span style={highlightedText}>{subDomains}</span>
        </RenderOrEmpty>
        {I18n.t('commonRowsActions.confirmation.addToWbList.inList')}
        <span style={highlightedText}>{listName}</span>
        ?
      </>
    );
  }

  setConfirmationDataToState = (listType, listId, listName, domain) =>
    this.setState({
      confirmation: {listType, listId, listName, domain},
    });

  resetConfirmationState = () => this.setState({
    confirmation: {...WbListActionsContainer.INIT_CONFIRMATION_STATE},
  });

  addDomainToWBList = () => {
    const {
      listType,
      listId,
      domain,
    } = this.state.confirmation;
    const {withSubDomains} = this.props;
    if (withSubDomains) {
      this.props.addDomainToWBlist(listType, listId, domain)
        .then(() => this.props.addDomainToWBlist(listType, listId, `*.${domain}`));
    } else {
      this.props.addDomainToWBlist(listType, listId, domain);
    }
    this.resetConfirmationState();
  };

  render() {
    const {
      listId,
      domain,
    } = this.state.confirmation;
    const {
      loggedAccount,
      currentOrganization,
      listProps,
      additionalActions,
    } = this.props;
    const {whiteLists, blackLists} = this.wbLists;
    const isConfirmationOpen = !!listId && !!domain;
    const actions = checkAvailability(
      rightAvailabilityMap.addOrRemoveToWbList,
      currentOrganization,
      loggedAccount,
    ) ? [
        {
          label: I18n.t('commonRowsActions.addToWhiteList'),
          id: 'add-to-white-list',
          subItems: whiteLists,
        },
        {
          label: I18n.t('commonRowsActions.addToBlackList'),
          id: 'add-to-black-list',
          subItems: blackLists,
        },
      ]
      : [];

    return (
      <>
        <IconMenu
          icon={<MoreVert />}
          listPlacement="right-start"
          listItems={[
            ...actions,
            ...additionalActions,
          ]}
          listProps={listProps}
        />
        <ModalWindow
          isOpen={isConfirmationOpen}
          confirm={this.addDomainToWBList}
          cancel={this.resetConfirmationState}
          title={this.confirmationMessage}
        />
      </>

    );
  }
}

WbListActionsContainer.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,

  wbLists: PropTypes.array.isRequired,
  withSubDomains: PropTypes.bool,

  addDomainToWBlist: PropTypes.func.isRequired,

  listProps: PropTypes.object,
  additionalActions: PropTypes.array,
};

WbListActionsContainer.defaultProps = {
  currentOrganization: null,
  withSubDomains: false,
  listProps: {},
  additionalActions: [],
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
  wbLists: state.rowActionsReducer.wbLists,
});

const mapDispatchToProps = {
  addDomainToWBlist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WbListActionsContainer);

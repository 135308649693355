const style = {
  input__underline_before: {
  },
  clientEditForm__nameInput: {
    height: 'unset',
  },
  clientEditForm__nameFormControl: {
    paddingBottom: '0',
  },
};

export default style;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import _isEqual from 'lodash/isEqual';
import {
  CardWithHeader,
  CustomTable,
} from '../../components';

import {getIPs} from './action';

class IPsList extends Component {
  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.ipListHeaders = [
      I18n.t('ipList.headers.resolverLocation'),
      I18n.t('ipList.headers.ipv6'),
    ];
    this.anycastListHeaders = [
      I18n.t('ipList.headers.anycast'),
    ];
  }

  componentDidMount() {
    this.props.getIPs();
  }

  componentDidUpdate(prevProps) {
    if (IPsList.wasDataScopeSwitched(prevProps, this.props)) {
      this.props.getIPs();
    }
  }

  get anycastIPs() {
    return Object.keys(this.props.anycastIPs).map((key) => (
      [
        [I18n.t(`ipList.anycast.${key}`)],
        [this.props.anycastIPs[key]],
      ]
    ));
  }

  render() {
    const {ipTableData} = this.props;
    return (
      <CardWithHeader
        cardTitle={I18n.t('ipList.title')}
        cardTitleColor="primary"
        content={(
          <>
            <CustomTable
              tableData={this.anycastIPs}
              tableHead={this.anycastListHeaders}
              tableHeaderColor="red"
            />
            <CustomTable
              tableData={ipTableData}
              tableHead={this.ipListHeaders}
              tableHeaderColor="red"
            />
          </>
        )}
      />
    );
  }
}

IPsList.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  ipTableData: PropTypes.array.isRequired,
  getIPs: PropTypes.func.isRequired,
  anycastIPs: PropTypes.object.isRequired,
};

IPsList.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: state.userAccountsReducer.loggedAccount,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
  ipTableData: state.ipListReducer.ipTableData,
  anycastIPs: state.ipListReducer.anycastIPs,
});

const mapDispatchToProps = (dispatch) => ({
  getIPs: bindActionCreators(getIPs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IPsList);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';
import {withStyles} from '@material-ui/core/styles';

import {NavPills} from '../../material-dashboard-pro-react/components';
import {OrganizationsTab, UsageBillingTab} from '../../containers';

import style from './style';

class Organizations extends React.PureComponent {
  render() {
    const {accountInfo, classes} = this.props;
    const showUsageBillingTab = _isNil(accountInfo.usageBillingEnabled)
      || accountInfo.usageBillingEnabled;
    const orgPageTabs = [
      {
        tabButton: I18n.t('organizations.page.tabs.organizations'),
        tabContent: <OrganizationsTab />,
      },
    ];
    if (showUsageBillingTab) {
      orgPageTabs.push(
        {
          tabButton: I18n.t('organizations.page.tabs.usageBilling'),
          tabContent: <UsageBillingTab />,
        },
      );
    }

    return (
      <div className={classes.page}>
        <NavPills
          color="secondary"
          className="root_fullScreen"
          wrapperClassName="tab__contentWrapper_fullScreen"
          contentClassName="tab__content_fullScreen"
          isSwipeable={false}
          tabs={orgPageTabs}
        />
      </div>
    );
  }
}

Organizations.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Organizations));

const style = {
  unauthorizedLayout: {
    height: '100vh',
    backgroundColor: '#EEE',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unauthorizedLayout__formWrapper: {
    backgroundColor: '#FFF',
    minWidth: '260px',
    maxWidth: '400px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    padding: '25px 30px',
  },
  formWrapper__logoWrapper: {
    padding: '20px 0',
  },
  formWrapper__logo: {
    width: '100%',
  },
};

export default style;

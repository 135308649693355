import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import _isEqual from 'lodash/isEqual';
import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH, MODAL_WINDOW_NOTIFY_TYPE,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';
import {
  ClientProfileCreating,
  ClientProfileDetails,
  ClientProfilesTable,
  ContainerWithListAndForm,
} from '../../components';
import {
  loadProfiles,
  resetClientsConfigurerData,
  changeEditModeAndProfileState,
  createProfile,
  updateProfile,
  deleteProfile,
  copyProfile,
  getDataToShowProfileDetails,
  resetSelectedProfileIndex,
  loadPlatforms,
  loadInstallers,
  getClientProfilePolicies,
  assignBlockPageToProfile,
} from './action';

import {getBlockPages} from '../../app-common/BlockPage/action';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {TABS as clientTabs} from '../../app-common/Clients/constants';

class ClientsConfigurer extends React.Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateClientProfileTableData(prevProps, props) {
    return !_isEqual(prevProps.profiles, props.profiles);
  }

  constructor(props) {
    super(props);
    this.state = {...ClientsConfigurer.initialLocalState};
  }

  componentDidMount() {
    this.props.loadProfiles();
    this.props.loadPlatforms();
    this.props.loadInstallers();
    this.props.getBlockPages();
  }

  componentDidUpdate(prevProps) {
    if (ClientsConfigurer.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.resetSelectedProfileIndex();
      this.props.loadProfiles();
    }
    if (ClientsConfigurer.shouldUpdateClientProfileTableData(prevProps, this.props)) {
      this.props.loadProfiles();
    }
  }

  componentWillUnmount() {
    this.props.resetClientsConfigurerData();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const isSubTabEditMode = {
      isBundleEditMode: this.props.isBundleEditMode,
      isLocalForwardingEditMode: this.props.isLocalForwardingEditMode,
    };
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <ClientProfileCreating
            getPolicies={this.props.getPolicies}
            handleClickButtonCancel={this.handleClickButtonCancelCreateProfile}
            handleClickButtonSave={this.handleClickButtonSaveNewProfile}
            copyProfile={this.props.copyProfile}
            isValidProfileInfo={this.props.isValidProfileInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <ClientProfileDetails
            profile={this.props.selectedProfile}
            getPolicies={this.props.getPolicies}
            handleClickButtonCancel={this.handleClickButtonCancelEditProfile}
            handleClickButtonSave={this.handleClickButtonSaveProfile}
            handleClickButtonBack={this.goToProfileList}
            handleClickButtonDelete={this.handleClickButtonDeleteProfile}
            handleClickButtonEdit={this.handleClickButtonEditProfile}
            handleClickButtonCancelEdit={this.handleClickButtonCancelEditProfile}
            copyProfile={this.props.copyProfile}
            isEditMode={this.props.isEditMode}
            isSubTabEditMode={isSubTabEditMode}
            isValidProfileInfo={this.props.isValidProfileInfo}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  goToProfileList = () => {
    this.setState(ClientsConfigurer.initialLocalState);
    this.props.resetSelectedProfileIndex();
  };

  handleClickButtonSaveNewProfile = () => {
    const {newOrUpdatedProfile, localDomains} = this.props;
    this.props.createProfile(newOrUpdatedProfile, localDomains)
      .then((response) => {
        if (response.isSuccess) {
          this.goToProfileList();
          this.props.assignBlockPageToProfile(
            newOrUpdatedProfile.blockPageId,
            response.profileId,
          );
        }
      });
  };

  handleClickButtonSaveProfile = () => {
    const {newOrUpdatedProfile, selectedProfile} = this.props;

    this.props.updateProfile(this.props.newOrUpdatedProfile).then((response) => {
      if (response.isSuccess) {
        if (newOrUpdatedProfile.blockPageId !== selectedProfile.blockPageId) {
          this.props.assignBlockPageToProfile(
            newOrUpdatedProfile.blockPageId,
            response.profileId,
          );
        }
      }
    });
  };

  handleClickButtonEditProfile = () => {
    this.props.changeEditModeAndProfileState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonCancelCreateProfile = () => {
    this.goToProfileList();
    this.props.changeEditModeAndProfileState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelEditProfile = () => {
    this.props.changeEditModeAndProfileState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonDeleteProfile = () => {
    const {selectedProfile} = this.props;
    this.props.deleteProfile(selectedProfile.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToProfileList();
        }
      });
  };

  showProfileDetails = (profileIndex) => {
    const {profiles} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowProfileDetails(profiles[profileIndex].id, profileIndex);
  };

  showFormCreatingProfile = () => {
    const {
      accountInfo,
      profiles,
    } = this.props;
    if (profiles.length >= accountInfo.maxClientProfiles) {
      this.props.openModalWindow(
        I18n.t(`clients.${clientTabs.CONFIGURE}.errorMessages.maxProfilesOnAccountExceeded`),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      );
      return;
    }

    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndProfileState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedProfileIndex();
  };

  resetLocalState = () => this.setState(ClientsConfigurer.initialLocalState);

  render() {
    const {
      profiles,
      isEditMode,
      isBundleEditMode,
      isLocalForwardingEditMode,
      selectedProfileIndex,
      // eslint-disable-next-line no-shadow
      copyProfile,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    return (
      <ContainerWithListAndForm
        list={(
          <ClientProfilesTable
            handleClickRow={this.showProfileDetails}
            handleClickButtonNew={this.showFormCreatingProfile}
            copyProfile={copyProfile}
            isEditMode={isEditMode || isBundleEditMode || isLocalForwardingEditMode}
            mode={tableMode}
            tableData={profiles}
            selectedProfileIndex={selectedProfileIndex}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

ClientsConfigurer.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  isBundleEditMode: PropTypes.bool.isRequired,
  isLocalForwardingEditMode: PropTypes.bool.isRequired,
  newOrUpdatedProfile: PropTypes.object.isRequired,
  isValidProfileInfo: PropTypes.bool.isRequired,
  profiles: PropTypes.array.isRequired,
  selectedProfile: PropTypes.object.isRequired,
  selectedProfileIndex: PropTypes.number.isRequired,
  localDomains: PropTypes.array.isRequired,

  loadPlatforms: PropTypes.func.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  loadInstallers: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  resetClientsConfigurerData: PropTypes.func.isRequired,
  changeEditModeAndProfileState: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  copyProfile: PropTypes.func.isRequired,
  getDataToShowProfileDetails: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  resetSelectedProfileIndex: PropTypes.func.isRequired,
  assignBlockPageToProfile: PropTypes.func.isRequired,
  getBlockPages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  isEditMode: state.clientsConfigurerReducer.isEditMode,
  profiles: state.clientsConfigurerReducer.profiles,
  selectedProfile: state.clientsConfigurerReducer.selectedProfile,
  selectedProfileIndex: state.clientsConfigurerReducer.selectedProfileIndex,

  newOrUpdatedProfile: state.clientProfileEditFormReducer.settings.editableProfileInfo,
  isValidProfileInfo: state.clientProfileEditFormReducer.isValidProfileInfo,

  isBundleEditMode: state.clientProfileInstallFormReducer.isEditMode,
  isLocalForwardingEditMode: state.clientProfileLocalForwardingFormReducer.isEditMode,
  localDomains: state.clientProfileLocalForwardingFormReducer.localDomains,
});

ClientsConfigurer.defaultProps = {
  currentOrganization: null,
};

const mapDispatchToProps = (dispatch) => ({
  loadPlatforms: bindActionCreators(loadPlatforms, dispatch),
  loadProfiles: bindActionCreators(loadProfiles, dispatch),
  loadInstallers: bindActionCreators(loadInstallers, dispatch),
  getPolicies: bindActionCreators(getClientProfilePolicies, dispatch),
  resetClientsConfigurerData: bindActionCreators(resetClientsConfigurerData, dispatch),
  assignBlockPageToProfile: bindActionCreators(assignBlockPageToProfile, dispatch),
  getBlockPages: bindActionCreators(getBlockPages, dispatch),

  changeEditModeAndProfileState: bindActionCreators(changeEditModeAndProfileState, dispatch),
  createProfile: bindActionCreators(createProfile, dispatch),
  updateProfile: bindActionCreators(updateProfile, dispatch),
  deleteProfile: bindActionCreators(deleteProfile, dispatch),
  copyProfile: bindActionCreators(copyProfile, dispatch),
  getDataToShowProfileDetails: bindActionCreators(getDataToShowProfileDetails, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  resetSelectedProfileIndex: bindActionCreators(resetSelectedProfileIndex, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsConfigurer);

import {types} from './constants';

export function updateEditOrganizationProfiles(profilesInOrganization, profilesNotInOrganization) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDIT_FROM_ORGANIZATION_PROFILES,
      profilesInOrganization,
      profilesNotInOrganization,
    });
  };
}

export function changeProfilesEditMode(isEditMode) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_ORGANIZATION_PROFILES_EDIT_MODE,
      isEditMode,
    });
  };
}

const style = {
  organizationProfilesTab__tableBody: {
    height: 'calc(100vh - 802px)',
  },
  organizationProfilesTab__tableBody_noDashboard: {
    height: 'calc(100vh - 452px)',
  },
};

export default style;

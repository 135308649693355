import initState from '../../reducers/redux-initial-state';
import {types} from './constants';
import {getClientDataToEdit, getUpdatedClientIndex} from './utils';

export default (state = initState.clientsManagerReducer, action) => {
  switch (action.type) {
    case types.SET_SEARCH_OPTION: {
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          [action.optionName]: action.value,
        },
      };
    }
    case types.SET_PROFILE_NAMES: {
      return {
        ...state,
        profileNames: action.profiles,
      };
    }
    case types.SET_CLIENTS: {
      return {
        ...state,
        tableData: action.data,
        clients: action.data,
        isLastClientsPage: (action.data.length === 0),
      };
    }
    case types.ADD_CLIENTS: {
      return {
        ...state,
        tableData: [
          ...state.tableData,
          ...action.data,
        ],
        isLastClientsPage: (action.data.length === 0),
      };
    }
    case types.RESET_CLIENTS: {
      return {
        ...state,
        clients: initState.clientsManagerReducer.clients,
      };
    }
    case types.RESET_CLIENT_MANAGER_DATA: {
      return {
        ...initState.clientsManagerReducer,
      };
    }
    case types.RESET_FILTERS: {
      return {
        ...state,
        searchOptions: {...initState.clientsManagerReducer.searchOptions},
      };
    }
    case types.SET_SELECTED_CLIENT: {
      return {
        ...state,
        selectedClient: getClientDataToEdit(action.client, action.roamingLabel),
      };
    }
    case types.SET_UPDATED_CLIENT: {
      return {
        ...state,
        selectedClientIndex: getUpdatedClientIndex(action.client.id, state.clients),
        selectedClient: getClientDataToEdit(action.client),
      };
    }
    case types.CHANGE_EDIT_MODE_AND_CLIENT_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        clientState: action.clientState,
      };
    }
    case types.SET_SELECTED_CLIENT_INDEX: {
      return {
        ...state,
        selectedClientIndex: action.selectedClientIndex,
      };
    }
    case types.RESET_SELECTED_CLIENT_INDEX: {
      return {
        ...state,
        selectedClient: initState.clientsManagerReducer.selectedClient,
        selectedClientIndex: initState.clientsManagerReducer.selectedClientIndex,
      };
    }
    case types.SET_SELECTED_CLIENT_STATUS: {
      const updatedClients = [...state.clients];
      updatedClients[action.index].agentStatus = action.status;
      return {
        ...state,
        clients: updatedClients,
      };
    }
    case types.SET_CLIENT_POLICIES: {
      return {
        ...state,
        policies: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import customInputStyle
  from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle';

function CustomLabel({...props}) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    error,
    success,
    children,
  } = props;

  const labelClasses = cx({
    [` ${classes.labelRootError}`]: error,
    [` ${classes.labelRootSuccess}`]: success && !error,
  });

  let formControlClasses = classes.formControl;
  if (formControlProps !== undefined) {
    formControlClasses += ` ${formControlProps.className}`;
  }

  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      aria-describedby={`${id}-text`}
    >
      <InputLabel
        className={classes.labelRoot + labelClasses}
        htmlFor={id}
        {...labelProps}
      >
        {labelText}
      </InputLabel>
      {children}
    </FormControl>
  );
}

CustomLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string.isRequired,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
};

CustomLabel.defaultProps = {
  children: null,
  labelText: '',
  labelProps: {},
  formControlProps: {},
  error: false,
  success: null,
};

export default withStyles(customInputStyle)(CustomLabel);

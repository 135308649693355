import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import _isEqual from 'lodash/isEqual';

import {CustomButton} from '../../material-dashboard-pro-react/components';

import {LoadingLayout} from '../index';
import {InsightsControls, InsightsTable} from '../../containers';
import {CardWithHeader, InsightsFiltersTooltip} from '../../components';
import {ChevronRight} from '../../icons/index';
import {TABS, widgetKeys} from '../../app-common/Insights/constants';
import {getInsights, resetTabFilters, setValidation} from '../../app-common/Insights/action';
import {cancelRequests} from '../../containers/Loading/action';
import {requestWbLists} from '../../containers/WbListActionsContainer/action';

import './style.css';
import {getAreFiltersDisabled} from '../../app-common/Insights/selectors';

class InsightsLayout extends React.PureComponent {
  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  state = {hideFilters: false};

  componentDidMount() {
    this.getData();
    this.props.requestWbLists();
  }

  componentDidUpdate(prevProps) {
    if (InsightsLayout.wasDataScopeSwitched(prevProps, this.props)) {
      this.getData();
      this.props.requestWbLists();
    }
  }

  componentWillUnmount() {
    this.interruptRequests(true);
  }

  get filtersButton() {
    const showFiltersButtonClass = classNames('insights-filters-button', {
      'insights-filters-button_hide': this.state.hideFilters,
    });
    const onButtonClick = () =>
      this.setState((prevState) => ({hideFilters: !prevState.hideFilters}));
    return (
      <CustomButton
        color="primaryNoBackground"
        onClick={onButtonClick}
        size="xs"
        customClasses={showFiltersButtonClass}
      >
        <ChevronRight />
      </CustomButton>
    );
  }

  get clearButton() {
    const clearButtonClass = classNames('insights-filters-actions-button', {
      'insights-filters-actions-button_hide': this.state.hideFilters,
    });
    return (
      <CustomButton
        color="gray"
        onClick={this.resetTabFilters}
        size="sm"
        customClasses={clearButtonClass}
      >
        <span className="insights-layout__filter-actions-title">{I18n.t('insights.filters.actions.clear')}</span>
      </CustomButton>
    );
  }

  get applyButton() {
    const applyButtonClass = classNames('insights-filters-actions-button', {
      'insights-filters-actions-button_hide': this.state.hideFilters,
    });
    return (
      <CustomButton
        color="secondary"
        onClick={this.getData}
        size="sm"
        customClasses={applyButtonClass}
      >
        <span className="insights-layout__filter-actions-title">{I18n.t('insights.filters.actions.apply')}</span>
      </CustomButton>
    );
  }

  getData = () => {
    const {tab, filterData} = this.props;
    const filterTabData = filterData[tab];
    let areFiltersValid = true;

    this.interruptRequests();
    Object.entries(filterTabData).forEach((entry) => {
      const [fieldName, fieldFilter] = entry;
      if (fieldFilter.validator && fieldFilter.value && fieldFilter.value !== '') {
        const fieldIsValid = fieldFilter.validator(fieldFilter.value);
        this.props.setValidation(tab, fieldName, fieldIsValid);
        areFiltersValid = areFiltersValid && fieldIsValid;
      }
    });
    if (areFiltersValid) {
      return this.props.getInsights(this.props.tab);
    }
    return [];
  };

  interruptRequests = (includeDefaultKey = false) => {
    this.props.cancelRequests(Object.values(widgetKeys), includeDefaultKey);
  };

  resetTabFilters = () => this.props.resetTabFilters(this.props.tab);

  render() {
    const filtersClassName = classNames(
      'insights-layout__filter',
      {
        'insights-layout__filter_collapsed': this.state.hideFilters,
        'insights-layout__filter_disabled': this.props.disabled,
      },
    );
    const titleClassName = classNames({
      'insights-layout-filter__title_collapsed': this.state.hideFilters,
    });
    const cardHeaderClassName = classNames({
      insightsFiltersCard__header_collapsed: this.state.hideFilters,
    });
    const cardTitleClassName = classNames({
      insightsFiltersCard__title_collapsed: this.state.hideFilters,
    });
    const cardActionsClassName = classNames({
      insightsFiltersCard__actions_collapsed: this.state.hideFilters,
    });
    const tooltipClasses = {
      popper: 'insights-layout-filter__tooltip-popper',
    };

    return (
      <div className="insights-layout">
        <InsightsFiltersTooltip
          filtersEnabled={!this.props.disabled}
          tooltipOptions={{
            placement: 'right-start',
          }}
          tooltipClasses={tooltipClasses}
        >
          <div className={filtersClassName}>

            <CardWithHeader
              cardTitle={(
                <div className="insights-layout-filter__header">
                  <span className={titleClassName}>{I18n.t('insights.filters.title')}</span>
                  {this.filtersButton}
                </div>
              )}
              content={!this.state.hideFilters && this.props.filters}
              actions={(
                <div className="insights-layout__filter-actions">
                  {this.applyButton}
                  {this.clearButton}
                </div>
              )}
              customCardClasses="insightsFiltersCard"
              customCardHeaderClasses={cardHeaderClassName}
              customCardTitleClasses={cardTitleClassName}
              customCardActionsClasses={cardActionsClassName}
            />
          </div>
        </InsightsFiltersTooltip>
        <div className="insights-layout__content">
          <InsightsControls
            tab={this.props.tab}
            onRefresh={this.getData}
          />
          <LoadingLayout widgetKey={widgetKeys[this.props.tab]}>
            <InsightsTable
              tab={this.props.tab}
              columns={this.props.columns}
            />
            {this.props.children}
          </LoadingLayout>
        </div>
      </div>
    );
  }
}

InsightsLayout.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  children: PropTypes.node,
  filters: PropTypes.node,
  getInsights: PropTypes.func.isRequired,
  resetTabFilters: PropTypes.func.isRequired,
  setValidation: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  requestWbLists: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(Object.values(TABS)).isRequired,
  columns: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  filterData: PropTypes.object.isRequired,
};

InsightsLayout.defaultProps = {
  currentOrganization: null,
  filters: null,
  children: null,
  columns: null,
};

const mapStateToProps = (state, props) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
  disabled: getAreFiltersDisabled(props.tab)(state),
  filterData: state.insightsReducer.filters,
});

const mapDispatchToProps = {
  getInsights,
  resetTabFilters,
  setValidation,
  cancelRequests,
  requestWbLists,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsLayout);

import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {getDefaultRouteByStatus, isComponentAvailable} from '../../constants';

function HasAccess(props) {
  const {
    accountType,
    accountStatus,
    role,
    componentName,
    children,
  } = props;

  if (isComponentAvailable(componentName, role, accountStatus, accountType)) {
    return children;
  }

  return <Redirect to={getDefaultRouteByStatus(accountStatus)} />;
}

HasAccess.propTypes = {
  accountType: PropTypes.string,
  accountStatus: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string,
};

HasAccess.defaultProps = {
  componentName: null,
  accountType: null,
  accountStatus: null,
  role: null,
};

const mapStateToProps = (state) => ({
  accountType: state.operatorLayoutReducer.accountType,
  accountStatus: state.operatorLayoutReducer.notificationBannerData.accountStatus,
  role: state.userAccountsReducer.loggedAccount.role,
});

export default connect(mapStateToProps, null)(HasAccess);

import initState from '../../reducers/redux-initial-state';

import {cloneSingleSignOnConfiguration} from './service';

import {types} from './constants';

export default (
  state = initState.singleSignOnEditFormReducer,
  action) => {
  switch (action.type) {
    case types.SET_EDITABLE_SINGLE_SIGN_ON_CONFIGURATION: {
      return {
        ...state,
        // eslint-disable-next-line max-len
        editableSingleSignOnConfiguration: cloneSingleSignOnConfiguration(action.singleSignOnConfiguration),
      };
    }
    case types.SET_VALIDATION_SINGLE_SIGN_ON_CONFIGURATION: {
      return {
        ...state,
        isValidSingleSignOnConfiguration: action.isValidSingleSignOnConfiguration,
      };
    }
    case types.RESET_SINGLE_SIGN_ON_CONFIGURATION: {
      return {
        ...state,
        ...initState.singleSignOnEditFormReducer,
      };
    }
    case types.UPDATE_SINGLE_SIGN_ON_CONFIGURATION: {
      return {
        ...state,
        editableSingleSignOnConfiguration: action.updatedSingleSignOnConfiguration,
        isValidSingleSignOnConfiguration: action.isValidSingleSignOnConfiguration,
      };
    }
    default:
      return state;
  }
};

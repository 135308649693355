/* eslint-disable max-len */
import {
  defaultFont,
  primaryColor,
} from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';

const style = {
  authWrapper: {
  },
  unAuthWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
  },
  text: {
    ...defaultFont,
    color: primaryColor,
    marginBottom: '10px',
  },
  text_large: {
    fontSize: '18px',
  },
};

export default style;

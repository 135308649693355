import _cloneDeep from 'lodash/cloneDeep';

import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.relayEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_RELAY_INFO: {
      return {
        ...state,
        editableRelayInfo: _cloneDeep(action.data),
      };
    }
    case types.RESET_RELAY_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.relayEditFormReducer,
      };
    }
    case types.UPDATE_RELAY_INFO: {
      return {
        ...state,
        editableRelayInfo: action.data,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import style from '../../styles/components/cards';

function GroupStatsCard(props) {
  const {
    classes,
    customCardClassName,
    groupName,
    content,
  } = props;

  const headerClasses = {
    root: classes.card__header,
    title: classNames(
      classes.card__title,
      classes.card__title_groupStats,
    ),
  };

  return (
    <Card
      className={classNames(
        classes.card,
        {
          [classes[customCardClassName]]: !_isUndefined(customCardClassName),
        },
      )}
    >
      <CardHeader
        classes={headerClasses}
        title={groupName}
      />
      <CardContent
        className={classes.card__content}
      >
        {content}
      </CardContent>
    </Card>
  );
}

GroupStatsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  customCardClassName: PropTypes.string,
  groupName: PropTypes.string,
  content: PropTypes.node,
};

GroupStatsCard.defaultProps = {
  customCardClassName: undefined,
  groupName: '',
  content: '',
};

export default withStyles(style)(GroupStatsCard);

export const types = {
  CHANGE_EDIT_MODE_AND_BLOCK_PAGE_STATE: 'CHANGE_EDIT_MODE_AND_BLOCK_PAGE_STATE',
  SET_BLOCK_PAGES: 'SET_BLOCK_PAGES',
  SET_BLOCK_PAGES_TABLE_DATA: 'SET_BLOCK_PAGES_TABLE_DATA',
  SET_SELECTED_BLOCK_PAGE: 'SET_SELECTED_BLOCK_PAGE',
  SET_SELECTED_BLOCK_PAGE_INDEX: 'SET_SELECTED_BLOCK_PAGE_INDEX',
  SET_UPDATED_BLOCK_PAGE: 'SET_UPDATED_BLOCK_PAGE',
  SET_BLOCK_PAGE_LOGO_URL: 'SET_BLOCK_PAGE_LOGO_URL',
  RESET_SELECTED_BLOCK_PAGE_INDEX: 'RESET_SELECTED_BLOCK_PAGE_INDEX',
};

export const widgetKeys = {
  UPLOAD_BLOCK_PAGE_LOGO: 'blockPages_uploadBlockPageLogo',
};

import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.responseDetailsReducer, action) => {
  switch (action.type) {
    case types.SET_RESPONSE_DETAILS: {
      return {
        ...state,
        response: action.responseDetails,
      };
    }
    case types.RESET_RESPONSE_DETAILS: {
      return {
        ...state,
        response: {...initState.responseDetailsReducer.response},
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';
import {Edit} from '../../icons/index';
import style from '../../styles/components/button';

function ButtonEditEntity(props) {
  const {
    classes,
    customClasses,
    entityType,
    onClick,
    text,
  } = props;
  const buttonText = text || I18n.t('buttons.editEntity', {entityType: entityType});
  return (
    <CustomButton
      color="secondary"
      customClasses={customClasses}
      onClick={onClick}
      size="xs"
    >
      <Edit className={classes.button__icon} />
      {buttonText.toUpperCase()}
    </CustomButton>
  );
}

ButtonEditEntity.propTypes = {
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.string,
  entityType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

ButtonEditEntity.defaultProps = {
  customClasses: '',
  entityType: '',
  text: null,
};

export default withStyles(style)(ButtonEditEntity);

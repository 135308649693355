export const widgetKeys = {
  REQUEST_STATS: 'users_requestStats',
  THREAT_STATS: 'users_threatStats',
  PERFORMANCE_STATS: 'users_performanceStats',
  SESSIONS: 'users_sessions',
  REQUEST_STATS_BY_CATEGORY: 'users_requestStats_byCategory',
};

export const USER_STATUS_FILTERS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  MISSING: 'MISSING',
};

export const USERS_ON_SITE_STATUSES = {
  ONSITE: 'ONSITE',
  ROAMING: 'ROAMING',
};

export const LIMIT_LOAD_DATA = 100;

export const MIN_TABLE_ROWS = 10;

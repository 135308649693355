import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {CardWithHeader} from '../../components';

import {
  LINKS,
  ROOT_CERTIFICATE_FILE_NAME,
} from '../../constants';

import style from './style';

function RootCertificate(props) {
  const {classes} = props;
  return (
    <CardWithHeader
      cardTitle={I18n.t('rootCertificatePage.title')}
      cardTitleColor="primary"
      content={(
        <div className={classes.rootCertificate}>
          <p className={classes.rootCertificate__text}>
            {I18n.t('rootCertificatePage.download.text')}
            <a
              className={classes.rootCertificate__text_link}
              href={LINKS.DOWNLOAD_ROOT_CERTIFICATE}
              download={ROOT_CERTIFICATE_FILE_NAME}
            >
              {I18n.t('rootCertificatePage.download.link')}
            </a>
          </p>
          <p className={classes.rootCertificate__text}>
            {I18n.t('rootCertificatePage.instructions.text')}
            <a
              className={classes.rootCertificate__text_link}
              href={LINKS.IMPORT_ROOT_CERTIFICATE_INSTRUCTIONS}
              target="_blank"
              rel="noreferrer"
            >
              {I18n.t('rootCertificatePage.instructions.link')}
            </a>
          </p>
        </div>
      )}
    />
  );
}

RootCertificate.propTypes = {
  classes: PropTypes.object.isRequired,
};

RootCertificate.defaultProps = {};

export default connect(null, null)((withStyles)(style)(RootCertificate));

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {ROUTES} from '../../constants';

import style from './style';

function SectionLinkInUnauthForm(props) {
  const {
    classes,
    text,
    to,
  } = props;

  return (
    <div className={classes.sectionLinks}>
      <Link
        to={to}
        href={ROUTES.DEFAULT}
        className={classes.sectionLinks__link}
      >
        {text}
      </Link>
    </div>
  );
}

SectionLinkInUnauthForm.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

SectionLinkInUnauthForm.defaultProps = {};

export default withStyles(style)(SectionLinkInUnauthForm);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';

import style from '../../styles/components/cards';

function CardWithHeader(props) {
  const {
    actions,
    classes,
    cardTitle,
    cardTitleColor,
    content,
    titleAlign,
    customCardClasses,
    contentAlign,
    actionsAlign,
    customCardHeaderClasses,
    customCardTitleClasses,
    customCardContentClasses,
    customCardActionsClasses,
  } = props;

  const cardClasses = classNames(
    classes.card,
    {
      [classes[customCardClasses]]: customCardClasses !== undefined,
    },
  );
  return (
    <Card className={cardClasses}>
      <CardHeader
        classes={{
          root: classNames(
            classes.card__header,
            {
              [classes[customCardHeaderClasses]]: customCardHeaderClasses !== undefined,
            },
          ),
          title: classNames(
            classes.card__title,
            classes[titleAlign],
            classes[`card__title_${cardTitleColor}`],
            {
              [classes[customCardTitleClasses]]: customCardTitleClasses !== undefined,
            },
          ),
        }}
        title={cardTitle}
      />
      <CardContent
        className={classNames(
          classes.card__content,
          classes[contentAlign],
          {
            [classes[customCardContentClasses]]: customCardContentClasses !== undefined,
          },
        )}
      >
        {content}
      </CardContent>
      <CardActions
        className={classNames(
          classes.card__actions,
          classes[actionsAlign],
          {
            [classes[customCardActionsClasses]]: customCardActionsClasses !== undefined,
          },
        )}
      >
        {actions}
      </CardActions>
    </Card>
  );
}

CardWithHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  customCardClasses: PropTypes.string,
  customCardHeaderClasses: PropTypes.string,
  customCardTitleClasses: PropTypes.string,
  customCardContentClasses: PropTypes.string,
  customCardActionsClasses: PropTypes.string,
  cardTitle: PropTypes.node,
  cardTitleColor: PropTypes.oneOf([
    'yellow',
    'green',
    'primary',
    'secondary',
    'red',
  ]),
  content: PropTypes.node,
  actions: PropTypes.node,
  titleAlign: PropTypes.oneOf(['right', 'left', 'center']),
  contentAlign: PropTypes.oneOf(['right', 'left', 'center']),
  actionsAlign: PropTypes.oneOf(['right', 'left', 'center']),
};

CardWithHeader.defaultProps = {
  customCardClasses: undefined,
  customCardActionsClasses: undefined,
  customCardHeaderClasses: undefined,
  customCardTitleClasses: undefined,
  customCardContentClasses: undefined,
  cardTitle: '',
  cardTitleColor: 'primary',
  content: '',
  actions: '',
  titleAlign: 'left',
  contentAlign: 'left',
  actionsAlign: 'right',
};

export default withStyles(style)(CardWithHeader);

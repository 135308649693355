import React, {Component} from 'react';
import {I18n} from 'react-redux-i18n';

import {TABS} from '../../app-common/Insights/constants';
import {InsightsLayout} from '../../layouts-common';
import {RecordTypeInsightsFilter} from '../index';

import './style.css';

class RecordTypeInsights extends Component {
  getHeaders = () => [
    {
      Header: I18n.t(`insights.${TABS.RECORD_TYPE}.table.recordType`),
      accessor: 'recordType',
      filterable: false,
    },
    {
      Header: I18n.t(`insights.${TABS.RECORD_TYPE}.table.count`),
      accessor: 'count',
      filterable: false,
    },
  ];

  render() {
    const headers = this.getHeaders();
    return (
      <InsightsLayout
        tab={TABS.RECORD_TYPE}
        columns={headers}
        filters={<RecordTypeInsightsFilter />}
      />
    );
  }
}

export default RecordTypeInsights;

import {DEFAULT_WIDGET_KEY} from '../../constants';

export const types = {
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  CANCEL_REQUESTS: 'CANCEL_REQUESTS',
};

export const startLoading = (dispatch, loadingUUID, abortController, widgetKey, url) => {
  // console.log('Start: ', loadingUUID, ' ', widgetKey, ' ', url);
  if (dispatch) {
    dispatch({
      type: types.UPDATE_LOADING_STATUS,
      isLoading: true,
      loadingUUID,
      abortController,
      widgetKey,
      url,
    });
  }
};

export const endLoading = (dispatch, loadingUUID, widgetKey) => {
  // console.log('End: ', loadingUUID, ' ', widgetKey);
  if (dispatch) {
    dispatch({
      type: types.UPDATE_LOADING_STATUS,
      isLoading: false,
      loadingUUID,
      widgetKey,
    });
  }
};

export const cancelRequests = (requestKeys = [], includeDefaultKey = false) =>
  // console.warn('Cancel: ', requestKeys, includeDefaultKey);
  (dispatch, getState) => {
    const state = getState().loadingReducer;
    const requestKeysExt = (includeDefaultKey) ? [DEFAULT_WIDGET_KEY, ...requestKeys] : requestKeys;
    requestKeysExt.forEach((key) => {
      // console.warn('--:', key, state[key].loadingMap);
      Object.values(state[key].loadingMap).forEach((controller) => controller.abort());
    });
    dispatch({
      type: types.CANCEL_REQUESTS,
      requestKeys,
      includeDefaultKey,
    });
  };

const style = {
  filters: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  filterItem: {
    margin: '0 5px',
  },
  dropdown: {
    minWidth: '100px',
  },
};

export default style;

import initState from '../../reducers/redux-initial-state';
import {types} from './action';
import {
  getNetworksStatusesAsObject,
  getNetworksTableData,
  getUpdatedNetworkIndex,
} from './service';

export default (state = initState.campusNetworksTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_NETWORK_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        networkState: action.networkState,
      };
    }
    case types.SET_CAMPUS_NETWORKS: {
      return {
        ...state,
        networks: action.data,
      };
    }
    case types.SET_NETWORKS_STATUS: {
      const statusObject = getNetworksStatusesAsObject(action.data);
      return {
        ...state,
        networksStatusesObject: statusObject,
        networksTableData: getNetworksTableData(state.networks, statusObject),
      };
    }
    case types.SET_NETWORKS_TABLE_DATA: {
      return {
        ...state,
        networksTableData: getNetworksTableData(state.networks, state.networksStatusesObject),
      };
    }
    case types.SET_SELECTED_NETWORK: {
      return {
        ...state,
        selectedNetwork: action.data,
      };
    }
    case types.SET_SELECTED_NETWORK_INDEX: {
      return {
        ...state,
        selectedNetworkIndex: action.selectedNetworkIndex,
      };
    }
    case types.SET_UPDATED_NETWORK: {
      return {
        ...state,
        selectedNetworkIndex: getUpdatedNetworkIndex(action.data.id, state.networks),
        selectedNetwork: action.data,
      };
    }
    case types.RESET_SELECTED_NETWORK_INDEX: {
      return {
        ...state,
        selectedNetworkIndex: initState.campusNetworksTabReducer.selectedNetworkIndex,
        selectedNetwork: initState.campusNetworksTabReducer.selectedNetwork,
      };
    }
    default:
      return state;
  }
};

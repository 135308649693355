import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import style from './style';

function NotifyAfterActionWithPassword(props) {
  const {
    buttonText,
    buttonColor,
    classes,
    onClick,
    isAuth,
    text,
  } = props;

  const wrapperClassName = classNames({
    [classes.authWrapper]: isAuth,
    [classes.unAuthWrapper]: !isAuth,
  });

  const textClassName = classNames(
    classes.text,
    {
      [classes.text_large]: !isAuth,
    },
  );

  return (
    <div className={wrapperClassName}>
      <div className={textClassName}>
        {text}
      </div>
      <CustomButton
        color={buttonColor}
        fullWidth={isAuth}
        onClick={onClick}
      >
        {buttonText}
      </CustomButton>
    </div>
  );
}

NotifyAfterActionWithPassword.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isAuth: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

NotifyAfterActionWithPassword.defaultProps = {
  buttonColor: 'secondary',
};

export default withStyles(style)(NotifyAfterActionWithPassword);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import {RenderOrEmpty} from '../index';

import {LINKS} from '../../constants';

import {AddAlert} from '../../icons/index';

import style from './style';

const visibleBanner = {
  trial_active: true,
  trial_expired: true,
  trial_suspended: true,
  trial_cancelled: true,
  trial_disabled: false,

  test_active: false,
  test_expired: false,
  test_suspended: true,
  test_cancelled: true,
  test_disabled: false,

  subscriber_active: false,
  subscriber_expired: false,
  subscriber_suspended: true,
  subscriber_cancelled: true,
  subscriber_disabled: false,
};

class NotificationBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerFunctions: {
        active: this.getBannerTextActiveTrial,
        expired: this.getBannerTextExpiredTrial,
        suspended: this.getBannerTextSuspended,
        cancelled: this.getBannerTextCancelled,
      },
    };
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.notificationData, nextProps.notificationData);
  }

  getContent = () => {
    const {
      classes,
      notificationData,
    } = this.props;
    const {bannerFunctions} = this.state;

    const bannerFunc = bannerFunctions[notificationData.accountStatus];
    let message = '';
    if (bannerFunc) {
      message = bannerFunc();
    }
    return (
      <>
        <IconButton
          className={classes.notificationBanner__icon}
          color="inherit"
        >
          <AddAlert />
        </IconButton>
        {message}
      </>
    );
  };

  getBannerTextActiveTrial = () => {
    const {classes, notificationData} = this.props;
    const infoWithLink = I18n.t('notificationBanner.activeTrial.infoWithLink').split('{linkText}');
    return (
      <span className={classes.notificationBanner__text}>
        {I18n.t(
          'notificationBanner.activeTrial.infoWithCountDays',
          {
            countDays: Math.round(notificationData.untilExpirationSeconds / (60 * 60 * 24)),
          },
        )}
        {this.getBr()}
        {infoWithLink[0]}
        {this.getLink(I18n.t('notificationBanner.activeTrial.linkText'))}
        {infoWithLink[1]}
      </span>
    );
  };

  getBannerTextExpiredTrial = () => {
    const {classes} = this.props;
    const infoWithLink = I18n.t('notificationBanner.expiredTrial.infoWithLink').split('{linkText}');
    return (
      <span className={classes.notificationBanner__text}>
        {I18n.t('notificationBanner.expiredTrial.info')}
        {this.getBr()}
        {infoWithLink[0]}
        {this.getLink(I18n.t('notificationBanner.expiredTrial.linkText'))}
        {infoWithLink[1]}
      </span>
    );
  };

  getBannerTextSuspended = () => {
    const {classes} = this.props;
    const infoWithLink = I18n.t('notificationBanner.suspended.infoWithLink').split('{linkText}');
    return (
      <span className={classes.notificationBanner__text}>
        {I18n.t('notificationBanner.suspended.info')}
        {this.getBr()}
        {infoWithLink[0]}
        {this.getLink(I18n.t('notificationBanner.suspended.linkText'))}
        {infoWithLink[1]}
      </span>
    );
  };

  getBannerTextCancelled = () => {
    const {classes} = this.props;
    const infoWithLink = I18n.t('notificationBanner.cancelled.infoWithLink').split('{linkText}');
    return (
      <span className={classes.notificationBanner__text}>
        {I18n.t('notificationBanner.cancelled.info')}
        {this.getBr()}
        {infoWithLink[0]}
        {this.getLink(I18n.t('notificationBanner.cancelled.linkText'))}
        {infoWithLink[1]}
      </span>
    );
  };

  getBr = () => <br />;

  getLink = (text) => {
    const {classes} = this.props;
    return (
      <a href={LINKS.OPEN_TICKET}
         target="_blank"
         rel="noopener noreferrer"
         className={classes.notificationBanner__text_link}
      >
        {text}
      </a>
    );
  };

  isVisibleBanner = () => {
    const {notificationData} = this.props;
    const isExpirationSeconds = _get(notificationData, 'untilExpirationSeconds') !== null;
    return _get(visibleBanner, `${notificationData.statusType}`, false) && isExpirationSeconds;
  };

  render() {
    const {classes} = this.props;
    const content = this.getContent();
    const snackbarClasses = {
      root: classes.notificationBanner,
      message: classes.notificationBanner__message,
    };
    return (
      <RenderOrEmpty isShow={this.isVisibleBanner()}>
        <SnackbarContent
          message={content}
          classes={snackbarClasses}
        />
      </RenderOrEmpty>
    );
  }
}

NotificationBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  notificationData: PropTypes.object.isRequired,
};

NotificationBanner.defaultProps = {};

export default withStyles(style)(NotificationBanner);

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {LocalForwardingEditForm} from '../../containers/index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.SITE_LOCAL_FORWARD_EDIT],
  buttonDelete: [ACCESS_RIGHTS.SITE_LOCAL_FORWARD_DELETE],
};

function LocalForwardingDetails(props) {
  const {
    handleClickButtonBackInLocalForwardingInfo,
    handleClickButtonCancelEditLocalForwarding,
    handleClickButtonDeleteLocalForwarding,
    handleClickButtonEditLocalForwarding,
    handleClickButtonSaveLocalForwarding,
    isEditMode,
    isValidLocalForwardingInfo,
    localForwardingInfo,
  } = props;

  return (
    <EntityEditingCard
      title={localForwardingInfo.name}
      entityType={I18n.t('entitiesTypes.localForwarding')}
      isEditMode={isEditMode}
      isTherePathBack={true}
      isThereDeleteBlock={true}
      isThereEditBlock={true}
      isValidEntity={isValidLocalForwardingInfo}
      handleClickButtonBack={handleClickButtonBackInLocalForwardingInfo}
      handleClickButtonCancel={handleClickButtonCancelEditLocalForwarding}
      handleClickButtonDelete={handleClickButtonDeleteLocalForwarding}
      handleClickButtonEdit={handleClickButtonEditLocalForwarding}
      handleClickButtonSave={handleClickButtonSaveLocalForwarding}
      customCardClass="card_localForwardings"
      customCardContentClass="card__content_localForwardings"
      accessRights={rightAvailabilityMap}
      content={
        <LocalForwardingEditForm />
      }
    />
  );
}

LocalForwardingDetails.propTypes = {
  handleClickButtonBackInLocalForwardingInfo: PropTypes.func.isRequired,
  handleClickButtonCancelEditLocalForwarding: PropTypes.func.isRequired,
  handleClickButtonEditLocalForwarding: PropTypes.func.isRequired,
  handleClickButtonDeleteLocalForwarding: PropTypes.func.isRequired,
  handleClickButtonSaveLocalForwarding: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidLocalForwardingInfo: PropTypes.bool.isRequired,
  localForwardingInfo: PropTypes.object.isRequired,
};

LocalForwardingDetails.defaultProps = {};

export default LocalForwardingDetails;

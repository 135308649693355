const style = {
  form: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

export default style;

import {
  yellowColor,
  primaryColor,
  redColor,
  greenColor,
  grayColor,
  defaultFont,
} from '../../styles/style';

const tableStyle = {
  yellow: {
    color: yellowColor,
  },
  primary: {
    color: primaryColor,
  },
  red: {
    color: redColor,
  },
  green: {
    color: greenColor,
  },
  gray: {
    color: grayColor,
  },
  right: {
    textAlign: 'right',
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    overflow: 'auto',
  },
  tableShoppingHead: {
    fontSize: '0.9em !important',
    textTransform: 'uppercase !important',
  },
  tableHeadFontSize: {
    fontSize: '1.25em !important',
  },
  tableHeadCell: {
    border: 'none !important',
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px!important',
    verticalAlign: 'middle',
    fontSize: '1em',
    borderBottom: 'none',
    borderTop: '1px solid #ddd',
    position: 'relative',
  },
  tableCell_noBorder: {
    border: 'none',
  },
  tableCellTotal: {
    fontWeight: '500',
    fontSize: '1.25em',
    paddingTop: '14px',
    textAlign: 'right',
  },
  tableCellAmount: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: '5px',
    textAlign: 'right',
  },
  tableResponsive: {
    // width: "100%",
    minHeight: '0.1%',
    overflowX: 'auto',
  },
  tableStripedRow: {
    backgroundColor: '#f9f9f9',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  yellowRow: {
    'backgroundColor': '#fcf8e3',
    '&:hover': {
      backgroundColor: '#faf2cc',
    },
  },
  redRow: {
    'backgroundColor': '#f2dede',
    '&:hover': {
      backgroundColor: '#ebcccc',
    },
  },
  greenRow: {
    'backgroundColor': '#dff0d8',
    '&:hover': {
      backgroundColor: '#d0e9c6',
    },
  },
};

export default tableStyle;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';
import {ClientBundleEditForm} from '../../containers';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_EDIT],
  buttonDelete: [ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_DELETE],
};

class ClientBundleDetails extends React.Component {
  render() {
    const {
      bundle,
      handleClickButtonBack,
      handleClickButtonDelete,
      handleClickButtonEdit,
      handleClickButtonCancelEdit,
      handleClickButtonSave,
      isValidBundleInfo,
      isEditMode,
      isProfileEditMode,
    } = this.props;

    return (
      <EntityEditingCard
        title={bundle.id}
        entityType={I18n.t('entitiesTypes.clientBundle')}
        isThereDeleteBlock={true}
        isTherePathBack={true}
        isThereEditBlock={!isProfileEditMode}
        isEditMode={isEditMode || isProfileEditMode}
        isValidEntity={isValidBundleInfo}
        handleClickButtonBack={handleClickButtonBack}
        handleClickButtonDelete={handleClickButtonDelete}
        handleClickButtonEdit={handleClickButtonEdit}
        handleClickButtonCancel={handleClickButtonCancelEdit}
        handleClickButtonSave={handleClickButtonSave}
        customCardClass="card__card_clientBundle"
        accessRights={rightAvailabilityMap}
        content={
          <ClientBundleEditForm />
        }
      />
    );
  }
}

ClientBundleDetails.propTypes = {
  bundle: PropTypes.object.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isProfileEditMode: PropTypes.bool.isRequired,
  isValidBundleInfo: PropTypes.bool.isRequired,
};

export default ClientBundleDetails;

import _cloneDeep from 'lodash/cloneDeep';

import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.localDomainEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_LOCAL_DOMAIN_INFO: {
      return {
        ...state,
        editableLocalDomainInfo: _cloneDeep(action.data),
      };
    }
    case types.SET_VALIDATION_LOCAL_DOMAIN_INFO: {
      return {
        ...state,
        isValidLocalDomainInfo: action.isValid,
      };
    }
    case types.RESET_LOCAL_DOMAIN_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.localDomainEditFormReducer,
      };
    }
    case types.UPDATE_LOCAL_DOMAIN_INFO: {
      return {
        ...state,
        editableLocalDomainInfo: action.data,
        isValidLocalDomainInfo: action.isValidLocalDomainInfo,
      };
    }
    default:
      return state;
  }
};

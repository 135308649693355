export const types = {
  CHANGE_EDIT_MODE_AND_ORGANIZATION_STATE: 'CHANGE_EDIT_MODE_AND_ORGANIZATION_STATE',
  SET_ORGANIZATIONS: 'SET_ORGANIZATIONS',
  SET_ORGANIZATIONS_TABLE_DATA: 'SET_ORGANIZATIONS_TABLE_DATA',
  SET_SELECTED_ORGANIZATION: 'SET_SELECTED_ORGANIZATION',
  SET_SELECTED_ORGANIZATION_INDEX: 'SET_SELECTED_ORGANIZATION_INDEX',
  SET_UPDATED_ORGANIZATION: 'SET_UPDATED_ORGANIZATION',
  RESET_SELECTED_ORGANIZATION_INDEX: 'RESET_SELECTED_ORGANIZATION_INDEX',
  RESET_ORGANIZATIONS: 'RESET_ORGANIZATIONS',
  SET_ORGANIZATION_NETWORKS_STATS: 'SET_ORGANIZATION_NETWORKS_STATS',
};

export const widgetKeys = {
  PERFORMANCE_STATS: 'organizations_performanceStats',
  THREAT_STATS: 'organizations_threatStats',
  REQUEST_STATS: 'organizations_requestStats',
  ORGANIZATION_NETWORK_STATS: 'organizations_organizationNetworkStats',
  SESSIONS: 'organizations_sessions',
  USAGE_BILLING: 'organizations_usageBilling',
};

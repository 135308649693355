import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _findIndex from 'lodash/findIndex';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {
  Dropdown,
  Switch,
} from '../../components/index';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {
  getEditableNetworkInfo,
  setValidationNetworkInfo,
  resetNetworkEditFormData,
  updateNetworkInfo,
} from './action';

import {getBlockPages} from '../../app-common/BlockPage/action';

import {STATES_ENTITY} from '../../constants';

import style from './style';

class NetworkEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
      ipAddressOrDomainName: true,
    },
  };

  static shouldUpdateNetworkObject(prevProps, props) {
    return !_isEqual(prevProps.network, props.network)
      || !_isEqual(prevProps.policies, props.policies);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.networkState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = NetworkEditForm.initialLocalState;
  }

  componentDidMount() {
    this.props.getBlockPages();
    this.props.getEditableNetworkInfo({
      ...this.props.network,
      policyName: _get(this.props, 'policies[0].name', ''),
      policyId: _get(this.props, 'policies[0].id', ''),
    });
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.network, nextProps.network)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableNetworkInfo, nextProps.editableNetworkInfo)
      || !_isEqual(this.props.policies, nextProps.policies)
      || !_isEqual(this.props.blockPages, nextProps.blockPages);
  }

  componentDidUpdate(prevProps) {
    if (NetworkEditForm.shouldUpdateNetworkObject(prevProps, this.props)
      || NetworkEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.getEditableNetworkInfo(this.props.network);
    }
    if (NetworkEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetNetworkEditFormData();
  }

  getSelectedPolicyIndex = () => {
    const {
      editableNetworkInfo,
      policies,
    } = this.props;

    if (_isEqual('', editableNetworkInfo.policyId)) {
      return 0;
    }
    return _findIndex(
      policies,
      (item) => _isEqual(item.id, editableNetworkInfo.policyId),
    );
  };

  getSelectedBlockPageIndex = () => {
    const {
      editableNetworkInfo,
      blockPages,
    } = this.props;

    if (_isEqual('', editableNetworkInfo.blockPageId)) {
      const index = _findIndex(
        blockPages,
        (item) => _isEqual(item.default, true),
      );

      if (index >= 0) {
        this.props.updateNetworkInfo(
          {
            ...editableNetworkInfo,
            blockPageName: blockPages[index].name,
            blockPageId: blockPages[index].id,
          },
          true,
        );
      }

      return index;
    }
    return _findIndex(
      blockPages,
      (item) => _isEqual(item.id, editableNetworkInfo.blockPageId),
    );
  };

  resetValidation = () => {
    this.props.setValidationNetworkInfo(true);
    this.setState(NetworkEditForm.initialLocalState);
  };

  validate = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
      {
        name: 'ipAddressOrDomainName',
        validationRules: [isNotEmpty],
      },
    ],
    data,
    {
      name: true,
      ipAddressOrDomainName: true,
    },
  );

  handleCheckboxChange = (e, checked) => {
    const {editableNetworkInfo} = this.props;
    this.props.updateNetworkInfo(
      {
        ...editableNetworkInfo,
        [e.target.name]: checked,
      },
      true,
    );
  };

  handleInputChange = (e) => {
    const {editableNetworkInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableNetworkInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateNetworkInfo(
      {
        ...editableNetworkInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeNetworkPolicy = (e) => {
    const {
      editableNetworkInfo,
      policies,
    } = this.props;
    this.props.updateNetworkInfo(
      {
        ...editableNetworkInfo,
        policyName: policies[e.target.value].name,
        policyId: policies[e.target.value].id,
      },
      true,
    );
  };

  handleChangeNetworkBlockPage = (e) => {
    const {
      editableNetworkInfo,
      blockPages,
    } = this.props;
    this.props.updateNetworkInfo(
      {
        ...editableNetworkInfo,
        blockPageName: blockPages[e.target.value].name,
        blockPageId: blockPages[e.target.value].id,
      },
      true,
    );
  };

  render() {
    const {
      classes,
      editableNetworkInfo,
      isEditMode,
      policies,
      blockPages,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      name: {
        disabled: !isEditMode,
        value: editableNetworkInfo.name,
        name: 'name',
        onChange: this.handleInputChange,
        type: 'text',
      },
      ipAddressOrDomainName: {
        disabled: !isEditMode,
        value: editableNetworkInfo.ipAddressOrDomainName,
        name: 'ipAddressOrDomainName',
        onChange: this.handleInputChange,
        type: 'text',
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.name
              ? undefined
              : I18n.t('campusPage.networksTab.errorMessages.networkNameIsNotValid')}
            error={!validation.name}
            labelText={I18n.t('campusPage.networksTab.networkNameLabel')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.name}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.ipAddressOrDomainName
              ? undefined
              : I18n.t('campusPage.networksTab.errorMessages.ipIsNotValid')}
            error={!validation.ipAddressOrDomainName}
            labelText={I18n.t('campusPage.networksTab.networkNetAddressLabel')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.ipAddressOrDomainName}
          />
        </div>
        <div className={classes.form__item}>
          <Dropdown
            customSelectClass="dropdown__select_blockPageSettingsTab"
            disabled={!isEditMode}
            dropdownName="blockPage"
            fullWidth={true}
            label={I18n.t('campusPage.networksTab.networkBlockPageLabel')}
            onChangeValue={this.handleChangeNetworkBlockPage}
            options={blockPages.sort((a) => (!a.default ? 1 : -1)).map((item) => item.name)}
            selectedItemIndex={this.getSelectedBlockPageIndex()}
          />
        </div>
        <div className={classes.form__item}>
          <Dropdown
            customSelectClass="dropdown__select_policySettingsTab"
            disabled={!isEditMode}
            dropdownName="policy"
            fullWidth={true}
            label={I18n.t('campusPage.networksTab.networkPolicyLabel')}
            onChangeValue={this.handleChangeNetworkPolicy}
            options={policies.map((item) => item.name)}
            selectedItemIndex={this.getSelectedPolicyIndex()}
          />
        </div>
        <div className={classes.form__item}>
          <Switch
            disabled={!isEditMode}
            isChecked={editableNetworkInfo.enabled}
            label={I18n.t('campusPage.networksTab.networkStateLabel')}
            name="enabled"
            onChange={this.handleCheckboxChange}
          />
        </div>
      </div>
    );
  }
}

NetworkEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  editableNetworkInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  network: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  blockPages: PropTypes.array.isRequired,

  getBlockPages: PropTypes.func.isRequired,
  getEditableNetworkInfo: PropTypes.func.isRequired,
  setValidationNetworkInfo: PropTypes.func.isRequired,
  resetNetworkEditFormData: PropTypes.func.isRequired,
  updateNetworkInfo: PropTypes.func.isRequired,
};

NetworkEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.campusReducer.policies,

  blockPages: state.blockPageReducer.blockPages,

  network: state.campusNetworksTabReducer.selectedNetwork,
  networkState: state.campusNetworksTabReducer.networkState,
  isEditMode: state.campusNetworksTabReducer.isEditMode,

  editableNetworkInfo: state.networkEditFormReducer.editableNetworkInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getEditableNetworkInfo: bindActionCreators(getEditableNetworkInfo, dispatch),
  getBlockPages: bindActionCreators(getBlockPages, dispatch),
  setValidationNetworkInfo: bindActionCreators(setValidationNetworkInfo, dispatch),
  resetNetworkEditFormData: bindActionCreators(resetNetworkEditFormData, dispatch),
  updateNetworkInfo: bindActionCreators(updateNetworkInfo, dispatch),
});

const NetworkEditFormWithStyles = withStyles(style)(NetworkEditForm);
export default connect(mapStateToProps, mapDispatchToProps)(NetworkEditFormWithStyles);

import initState from '../../reducers/redux-initial-state';

import {cloneSubnetInfo} from './service';

import {types} from './action';

export default (state = initState.subnetEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_SUBNET_INFO: {
      return {
        ...state,
        editableSubnetInfo: cloneSubnetInfo(action.data),
      };
    }
    case types.SET_VALIDATION_SUBNET_INFO: {
      return {
        ...state,
        isValidSubnetInfo: action.isValid,
      };
    }
    case types.RESET_SUBNET_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.subnetEditFormReducer,
      };
    }
    case types.UPDATE_SUBNET_INFO: {
      return {
        ...state,
        editableSubnetInfo: action.data,
        isValidSubnetInfo: action.isValidSubnetInfo,
      };
    }
    default:
      return state;
  }
};

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';

export const getOrDefault = (obj, path, defaultValue) => {
  const value = _get(obj, path);
  return _isNil(value) || _isEmpty(value) ? defaultValue : value;
};

export const omitSingle = (key, {[key]: _, ...obj}) => obj;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {Clear} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';

import {
  ACCESS_RIGHTS,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  ORGANIZATION_OPERATOR_PERMISSIONS,
} from '../../constants';
import {
  ContainerWithListAndForm,
  Dropdown,
  EntitiesListWithViewModesCard,
} from '../../components';
import {getAvailableOrganizations, getNames} from './utils';
import {getOrganizationNames, updateOrganizations} from './action';

import style from './style';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE],
};

class OperatorOrganizationsTable extends React.Component {
  static emptyRowTemplate = {
    availableOrganizations: [],
    selectedOrganizationIndex: -1,
    selectedPermissionIndex: 0,
  };

  constructor(props) {
    super(props);
    this.permissions = Object.values(ORGANIZATION_OPERATOR_PERMISSIONS);
    this.permissionLabels = Object.values(ORGANIZATION_OPERATOR_PERMISSIONS)
      .map((permission) =>
        I18n.t(`accessManagementPage.organizations.permissions.${permission}`),
      );
  }

  componentDidMount() {
    this.props.loadAllOrganizations();
    this.resetRowData();
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.operator.id, this.props.operator.id)
      || !_isEqual(prevProps.allOrganizations, this.props.allOrganizations)
    ) {
      this.resetRowData();
    }
  }

  get viewFormColumns() {
    return [
      {
        accessor: 'organization',
        Cell: (row) => {
          if (!this.props.rows[row.index]) {
            return null;
          }

          const organizationNames = this.getOrganizationOperatorNames(row.index);
          const organizationIndex = this.getSelectedOrganizationIndex(row.index);
          return organizationNames && organizationNames[organizationIndex]
            ? organizationNames[organizationIndex]
            : null;
        },
        sortable: false,
        filterable: false,
      },
      {
        accessor: 'permission',
        Cell: (row) => {
          if (!this.props.rows[row.index]) {
            return null;
          }
          const permissionIndex = this.getSelectedPermissionIndex(row.index);
          return this.permissionLabels && this.permissionLabels[permissionIndex]
            ? this.permissionLabels[permissionIndex]
            : null;
        },
        sortable: false,
        filterable: false,
      },
    ];
  }

  get editFormColumns() {
    return [
      {
        accessor: 'organization',
        Cell: this.organizationCellRenderer,
        sortable: false,
        filterable: false,
      },
      {
        accessor: 'permission',
        Cell: this.permissionCellRenderer,
        sortable: false,
        filterable: false,
      },
      {
        accessor: 'action',
        Cell: this.actionCellRenderer,
        sortable: false,
        filterable: false,
      },
    ];
  }

  get columns() {
    if (this.props.isEditMode) {
      return this.editFormColumns;
    }
    return this.viewFormColumns;
  }

  get organizationList() {
    const {rows, isEditMode} = this.props;
    const tableClasses = {
      tableBody: this.props.classes.operatorOrganizationsTable__tableBody,
    };
    return (
      <EntitiesListWithViewModesCard
              mode={FULL_WIDTH}
              headersFullWidthMode={this.columns}
              tableData={rows}
              disabled={!isEditMode}
              title={I18n.t('accessManagementPage.organizations.title')}
              newButtonText={I18n.t('accessManagementPage.organizations.buttons.buttonNew.text')}
              // eslint-disable-next-line max-len
              newButtonTextShort={I18n.t('accessManagementPage.organizations.buttons.buttonNew.textShort')}
              entityType={I18n.t('entitiesTypes.organization')}
              handleClickButtonNew={this.addRowHandlerGetter()}
              customClasses={tableClasses}
              fullscreen={false}
              customCardClass="card_networks"
              accessRights={rightAvailabilityMap}
      />
    );
  }

  get usedOrganizationIds() {
    const usedOrganizationIds = {};
    this.props.rows.forEach((row) => {
      const rowOrg = row.availableOrganizations[row.selectedOrganizationIndex];
      if (!_isNil(rowOrg)) {
        usedOrganizationIds[rowOrg.id] = true;
      }
    });
    return usedOrganizationIds;
  }

  actionCellRenderer = (row) => (
    <div
      className={this.props.classes.operatorOrganizationsTable__cell_action}
      onClick={() => this.removeRow(row.index)}
    >
      <Clear />
    </div>
  );

  permissionCellRenderer = (row) => (
    <Dropdown
      customSelectClass="dropdown__operatorOrganizations"
      disabled={!this.props.isEditMode}
      dropdownName="organization"
      onChangeValue={(e) => this.onSelectOrganizationOperatorPermission(row.index, e.target.value)}
      options={this.permissionLabels}
      selectedItemIndex={this.getSelectedPermissionIndex(row.index)}
    />
  );

  organizationCellRenderer = (row) => {
    const {isEditMode} = this.props;
    const organizationNames = this.getOrganizationOperatorNames(row.index);
    const hasAvailableOrganizations = !_isEmpty(organizationNames);
    return (
      <Dropdown
        customSelectClass="dropdown__operatorOrganizations"
        disabled={!isEditMode || !hasAvailableOrganizations}
        dropdownName="organization"
        onChangeValue={(e) => this.onSelectOrganizationName(row.index, e.target.value)}
        options={organizationNames}
        selectedItemIndex={this.getSelectedOrganizationIndex(row.index)}
      />
    );
  };

  getOrganizationOperatorNames = (index) => {
    const row = this.props.rows[index];
    if (!row) {
      return [];
    }
    return getNames(row.availableOrganizations);
  };

  getSelectedOrganizationIndex = (index) => {
    const row = this.props.rows[index];
    if (!row) {
      return -1;
    }
    return row.selectedOrganizationIndex;
  };

  getSelectedPermissionIndex = (index) => {
    const row = this.props.rows[index];
    if (!row) {
      return 0;
    }
    return row.selectedPermissionIndex;
  };

  onSelectOrganizationName = (rowIndex, organizationIndex) => {
    const currentRow = this.props.rows[rowIndex];
    const prevOrg = currentRow.availableOrganizations[currentRow.selectedOrganizationIndex];
    const newOrg = currentRow.availableOrganizations[organizationIndex];

    const updatedRows = this.props.rows.map((row, index) => {
      if (_isEqual(index, rowIndex)) {
        return {
          ...row,
          selectedOrganizationIndex: organizationIndex,
        };
      }

      const selectedOrgId = _get(
        row.availableOrganizations[row.selectedOrganizationIndex],
        'id',
        -1,
      );
      const updatedOrganizations = row.availableOrganizations
        .filter((org) => !_isEqual(org.id, newOrg.id));
      if (!_isNil(prevOrg)) {
        updatedOrganizations.push(prevOrg);
      }
      const availableOrganizations = _sortBy(
        updatedOrganizations,
        (org) => org.name,
      );
      return {
        ...row,
        availableOrganizations,
        selectedOrganizationIndex: (row.selectedOrganizationIndex >= 0)
          ? availableOrganizations.findIndex((org) => _isEqual(org.id, selectedOrgId))
          : -1,
      };
    });
    this.props.updateRows(updatedRows);
  };

  onSelectOrganizationOperatorPermission = (rowIndex, permissionIndex) => {
    const updatedRows = [...this.props.rows];
    updatedRows[rowIndex] = {
      ...this.props.rows[rowIndex],
      selectedPermissionIndex: permissionIndex,
    };
    this.props.updateRows(updatedRows);
  };

  addRow = () => {
    const {allOrganizations} = this.props;
    const updatedRows = [
      ...this.props.rows,
      {
        ...OperatorOrganizationsTable.emptyRowTemplate,
        availableOrganizations: getAvailableOrganizations(
          allOrganizations,
          this.usedOrganizationIds,
          null,
        ),
      },
    ];
    this.props.updateRows(updatedRows);
  };

  addRowHandlerGetter = () => {
    if (this.props.isEditMode) {
      return this.addRow;
    }
    return null;
  };

  removeRow = (rowIndex) => {
    const removedRow = this.props.rows[rowIndex];
    const removedRowOrg = removedRow.availableOrganizations[removedRow.selectedOrganizationIndex];
    let updatedRows = this.props.rows.filter((row, index) => !_isEqual(index, rowIndex));
    if (!_isNil(removedRowOrg)) {
      updatedRows = updatedRows.map((row) => {
        const rowOrg = row.availableOrganizations[row.selectedOrganizationIndex];
        const rowUpdatedAvailableOrgs = _sortBy(
          [...row.availableOrganizations, removedRowOrg],
          (org) => org.name,
        );
        return {
          ...row,
          availableOrganizations: rowUpdatedAvailableOrgs,
          selectedOrganizationIndex: (!_isNil(rowOrg))
            ? rowUpdatedAvailableOrgs.findIndex((org) => _isEqual(org.id, rowOrg.id))
            : -1,
        };
      });
    }
    this.props.updateRows(updatedRows);
  };

  resetRowData = () => {
    const {operator, allOrganizations} = this.props;
    const operatorOrganizations = _get(operator, 'organizations', []);
    const usedOrganizationIds = {};
    operatorOrganizations.forEach((org) => {
      usedOrganizationIds[org.organizationId] = true;
    });

    const rows = operatorOrganizations.length > 0 ? _sortBy(
      operatorOrganizations.map((org) => {
        const availableOrganizations = getAvailableOrganizations(
          allOrganizations,
          usedOrganizationIds,
          org.organizationId,
        );
        return ({
          availableOrganizations,
          selectedOrganizationIndex: availableOrganizations
            .findIndex((o) => _isEqual(org.organizationId, o.id)),
          selectedPermissionIndex: this.permissions.findIndex((p) => _isEqual(org.permission, p)),
        });
      }),
      (org) => org.name,
    ) : [];

    this.props.updateRows(rows);
  };

  render() {
    return (
      <ContainerWithListAndForm
        list={this.organizationList}
      />
    );
  }
}

OperatorOrganizationsTable.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  operator: PropTypes.object.isRequired,
  allOrganizations: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  accessRights: PropTypes.object,
  updateRows: PropTypes.func.isRequired,
  loadAllOrganizations: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
};

OperatorOrganizationsTable.defaultProps = {
  accessRights: {},
  isEditMode: false,
};

const mapStateTpProps = (state) => ({
  allOrganizations: state.operatorOrganizationsTableReducer.allOrganizations,
  rows: state.operatorOrganizationsTableReducer.rows,
  accountInfo: state.operatorLayoutReducer.accountInfo,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllOrganizations: bindActionCreators(getOrganizationNames, dispatch),
  updateRows: bindActionCreators(updateOrganizations, dispatch),
});

export default
connect(mapStateTpProps, mapDispatchToProps)(withStyles(style)(OperatorOrganizationsTable));

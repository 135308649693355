/* eslint-disable max-len */
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';

const style = {
  updatePasswordForm__errorMessage: {
    ...customInputStyle.labelRoot,
    ...customInputStyle.labelRootError,
    fontSize: '12px',
    textAlign: 'center',
  },
};

export default style;

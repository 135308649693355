import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {TABS as clientTabs} from '../../app-common/Clients/constants';
import {EntityCreatingCard} from '../index';
import {ClientLocalForwardingEditForm} from '../../containers';

class ClientLocalForwardingCreating extends React.Component {
  render() {
    const {
      isValidLocalForwardingInfo,
      handleClickButtonCancel,
      handleClickButtonSave,
      setFormValidatorRef,
    } = this.props;

    return (
      <EntityCreatingCard
        title={I18n.t(`clients.${clientTabs.CONFIGURE}.localForwarding.createDomain`)}
        handleClickButtonCancel={handleClickButtonCancel}
        handleClickButtonSave={handleClickButtonSave}
        isValidEntity={isValidLocalForwardingInfo}
        content={(
          <ClientLocalForwardingEditForm
            isCreating={true}
            setFormValidatorRef={setFormValidatorRef}
          />
        )}
      />
    );
  }
}

// customCardContentClass="card__content_clientBundle"

ClientLocalForwardingCreating.propTypes = {
  isValidLocalForwardingInfo: PropTypes.bool.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  setFormValidatorRef: PropTypes.func.isRequired,
};

export default ClientLocalForwardingCreating;

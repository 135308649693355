import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';
import {Close} from '../../icons/index';
import style from '../../styles/components/button';

function ButtonCancel(props) {
  const {
    classes,
    customClasses,
    onClick,
  } = props;

  return (
    <CustomButton
      color="yellow"
      customClasses={customClasses}
      onClick={onClick}
      size="xs"
    >
      <Close className={classes.button__icon} />
      {I18n.t('buttons.cancel').toUpperCase()}
    </CustomButton>
  );
}

ButtonCancel.propTypes = {
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ButtonCancel.defaultProps = {
  customClasses: '',
};

export default withStyles(style)(ButtonCancel);

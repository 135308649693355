import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {withStyles} from '@material-ui/core/styles';

import {
  OrganizationsTable,
  ContainerWithListAndForm,
  OrganizationCreating,
  OrganizationDetails,
} from '../../components';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

import {
  changeEditModeAndOrganizationState,
  createOrganization,
  deleteOrganization,
  getDataToShowOrganizationDetails,
  getOrganizationsTableData,
  resetPageData,
  resetSelectedOrganizationIndex,
  updateOrganization,
  updateOrganizationPageData,
} from '../../app-common/Organizations/action';
import {cancelRequests} from '../Loading/action';
import {getUserOrganizations} from '../../actions/userOrganizations/getUserOrganizations';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  MODAL_WINDOW_NOTIFY_TYPE,
  STATES_ENTITY,
  ROUTES,
} from '../../constants';
import {widgetKeys} from '../../app-common/Organizations/constants';

import style from './style';

class OrganizationsTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateOrganizationsTableData(prevProps, props) {
    return !_isEqual(prevProps.organizations, props.organizations)
      || !_isEqual(prevProps.networkStatsByOrganization, props.networkStatsByOrganization);
  }

  static shouldGoToRootPage(prevProps, props) {
    return !_isNil(props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = OrganizationsTab.initialLocalState;
  }

  componentDidMount() {
    if (!_isEmpty(this.props.organizationsStats) && !_isEmpty(this.props.organizations)) {
      this.props.getOrganizationsTableData(this.props.organizationsStats);
    }
    this.props.updateOrganizationPageData(widgetKeys.ORGANIZATION_NETWORK_STATS);
  }

  componentDidUpdate(prevProps) {
    if (OrganizationsTab.shouldGoToRootPage(prevProps, this.props)) {
      this.props.history.push(ROUTES.DEFAULT);
    }
    if (OrganizationsTab.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.cancelRequests([widgetKeys.ORGANIZATION_NETWORK_STATS], false);
      this.props.updateOrganizationPageData(widgetKeys.ORGANIZATION_NETWORK_STATS);
    }
    if (OrganizationsTab.shouldUpdateOrganizationsTableData(prevProps, this.props)) {
      this.props.getOrganizationsTableData(this.props.organizationsStats);
    }
  }

  componentWillUnmount() {
    this.props.resetPageData();
    this.interruptRequests(true);
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isSitesSubTabEditMode,
      isProfilesSubTabEditMode,
      selectedOrganization,
      isValidOrganizationInfo,
      accountInfo,
    } = this.props;

    const deviceAgentAllowed = accountInfo.deviceAgentAllowed;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <OrganizationCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateOrganization}
            handleClickButtonSave={this.handleClickButtonSaveNewOrganization}
            isValidOrganizationInfo={isValidOrganizationInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <OrganizationDetails
            organization={selectedOrganization}
            handleClickButtonBack={this.goToOrganizationList}
            handleClickButtonDelete={this.handleClickButtonDeleteOrganization}
            handleClickButtonCancelEdit={this.handleClickButtonCancelOrganizationEditing}
            handleClickButtonEdit={this.handleClickButtonEditOrganization}
            handleClickButtonSave={this.handleClickButtonSaveOrganization}
            isEditMode={isEditMode}
            isSubTabEditMode={{
              isSitesSubTabEditMode,
              isProfilesSubTabEditMode,
            }}
            isValidOrganizationInfo={isValidOrganizationInfo}
            showProfileTab={deviceAgentAllowed}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  resetLocalState = () => this.setState(OrganizationsTab.initialLocalState);

  handleClickButtonSaveNewOrganization = () => {
    const {newOrUpdatedOrganization} = this.props;
    this.props.createOrganization(newOrUpdatedOrganization)
      .then((isSuccess) => {
        if (isSuccess) {
          this.props.getUserOrganizations();
          this.goToOrganizationList();
        }
      });
  };

  handleClickButtonCancelCreateOrganization = () => {
    this.goToOrganizationList();
    this.props.changeEditModeAndOrganizationState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelOrganizationEditing = () => {
    this.props.changeEditModeAndOrganizationState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditOrganization = () => {
    this.props.changeEditModeAndOrganizationState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveOrganization = () => {
    const {newOrUpdatedOrganization, selectedOrganization} = this.props;
    this.props.updateOrganization(newOrUpdatedOrganization)
      .then(() => {
        if (!_isEqual(selectedOrganization.name, newOrUpdatedOrganization.name)) {
          this.props.getUserOrganizations();
        }
      });
  };

  handleClickButtonDeleteOrganization = () => {
    const {selectedOrganization} = this.props;
    this.props.deleteOrganization(selectedOrganization.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.props.getUserOrganizations();
          this.goToOrganizationList();
        }
      });
  };

  showFormCreatingOrganization = () => {
    const {
      accountInfo,
      organizations,
    } = this.props;
    if (organizations.length >= accountInfo.maxOrganizations) {
      this.props.openModalWindow(
        I18n.t('organizationsPage.errorMessages.organizationsListMoreThenMaxOrganizations'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      );
      return;
    }

    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndOrganizationState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedOrganizationIndex();
  };

  showOrganizationDetails = (organizationIndex) => {
    const {organizations} = this.props;
    this.props.getDataToShowOrganizationDetails(
      organizations[organizationIndex].id, organizationIndex);
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
  };

  goToOrganizationList = () => {
    this.setState(OrganizationsTab.initialLocalState);
    this.props.resetSelectedOrganizationIndex();
  };

  interruptRequests = (includeDefaultKey = false) => {
    this.props.cancelRequests(
      Object.values(widgetKeys),
      includeDefaultKey,
    );
  };

  render() {
    const {
      classes,
      organizationsTableData,
      networkStatsByOrganization,
      isEditMode,
      isSitesSubTabEditMode,
      isProfilesSubTabEditMode,
      selectedOrganizationIndex,
      accountInfo,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    const containerClasses = {
      form: classes.form,
      list: classes.list,
      list_compressed: classes.list_compressed,
    };

    const deviceAgentAllowed = accountInfo.deviceAgentAllowed;
    return (
      <ContainerWithListAndForm
        list={(
          <OrganizationsTable
            handleClickOrganizationRow={this.showOrganizationDetails}
            handleClickButtonNew={this.showFormCreatingOrganization}
            isEditMode={isEditMode || isSitesSubTabEditMode || isProfilesSubTabEditMode}
            mode={tableMode}
            tableData={organizationsTableData}
            selectedOrganizationIndex={selectedOrganizationIndex}
            networkStatsByOrganization={networkStatsByOrganization}
            showClients={deviceAgentAllowed}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
        classes={containerClasses}
      />
    );
  }
}

OrganizationsTab.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  history: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  organizationsStats: PropTypes.object,
  networkStatsByOrganization: PropTypes.object.isRequired,
  organizationsTableData: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isSitesSubTabEditMode: PropTypes.bool.isRequired,
  isProfilesSubTabEditMode: PropTypes.bool.isRequired,
  isValidOrganizationInfo: PropTypes.bool.isRequired,
  newOrUpdatedOrganization: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  selectedOrganizationIndex: PropTypes.number.isRequired,

  changeEditModeAndOrganizationState: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  getDataToShowOrganizationDetails: PropTypes.func.isRequired,
  getOrganizationsTableData: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  resetSelectedOrganizationIndex: PropTypes.func.isRequired,
  resetPageData: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  updateOrganizationPageData: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  getUserOrganizations: PropTypes.func.isRequired,
};

OrganizationsTab.defaultProps = {
  currentOrganization: null,
  organizationsStats: {},
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  newOrUpdatedOrganization: state.organizationEditFormReducer.editableOrganizationInfo,
  isValidOrganizationInfo: state.organizationEditFormReducer.isValidOrganizationInfo,

  isSitesSubTabEditMode: state.organizationSitesTabReducer.isEditMode,
  isProfilesSubTabEditMode: state.organizationProfilesTabReducer.isEditMode,

  organizations: state.organizationsReducer.organizations,
  organizationsTableData: state.organizationsReducer.organizationsTableData,
  networkStatsByOrganization: state.organizationsReducer.networkStatsByOrganization,
  isEditMode: state.organizationsReducer.isEditMode,
  selectedOrganization: state.organizationsReducer.selectedOrganization,
  selectedOrganizationIndex: state.organizationsReducer.selectedOrganizationIndex,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndOrganizationState: bindActionCreators(
    changeEditModeAndOrganizationState, dispatch),
  createOrganization: bindActionCreators(createOrganization, dispatch),
  deleteOrganization: bindActionCreators(deleteOrganization, dispatch),
  getDataToShowOrganizationDetails: bindActionCreators(getDataToShowOrganizationDetails, dispatch),
  getOrganizationsTableData: bindActionCreators(getOrganizationsTableData, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  resetSelectedOrganizationIndex: bindActionCreators(resetSelectedOrganizationIndex, dispatch),
  resetPageData: bindActionCreators(resetPageData, dispatch),
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  updateOrganizationPageData: bindActionCreators(updateOrganizationPageData, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
  getUserOrganizations: bindActionCreators(getUserOrganizations, dispatch),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(OrganizationsTab)));

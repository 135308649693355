import _get from 'lodash/get';

import {types} from './action';

import {
  getNotificationBannerData,
  getTextToDisplayInModalWindow,
} from './service';

import initState from '../../../reducers/redux-initial-state';

export default (state = initState.operatorLayoutReducer, action) => {
  switch (action.type) {
    case types.OPEN_MODAL_WINDOW: {
      return {
        ...state,
        modalWindow: {
          isOpen: true,
          text: getTextToDisplayInModalWindow(
            action.data,
            action.notifyType,
          ),
        },
      };
    }
    case types.CLOSE_MODAL_WINDOW: {
      return {
        ...state,
        modalWindow: initState.operatorLayoutReducer.modalWindow,
      };
    }
    case types.SET_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.data,
        notificationBannerData: getNotificationBannerData(action.data),
        accountType: _get(action.data, 'type', '').toLowerCase(),
      };
    }
    case types.SET_ORGANIZATION_INFO: {
      return {
        ...state,
        organizationInfo: action.data,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _isNull from 'lodash/isNull';
import _get from 'lodash/get';


import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import customInputStyle
  from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle';

function CustomInput({...props}) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    helpText,
    errorText,
    rtlActive,
    customUnderlineClasses,
  } = props;

  let labelClasses = cx({
    [` ${classes.labelRootError}`]: error,
    [` ${classes.labelRootSuccess}`]: success && !error,
  });

  let formControlClasses = classes.formControl;
  if (!_isNull(_get(formControlProps, 'className', null))) {
    formControlClasses += ` ${formControlProps.className}`;
  }
  const underlineClasses = cx(
    {
      [classes.underline]: true,
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
    },
    [classes[customUnderlineClasses]],
  );
  if (inputProps !== undefined) {
    formControlClasses =
      `${formControlClasses
      } ${
        cx({
          [classes.inputWithAdornment]:
          (inputProps.startAdornment !== undefined ||
            inputProps.endAdornment !== undefined) &&
          labelText === undefined,
        })
      }`;
      const formCustomClassName = _get(formControlProps, 'className', null);
      if (!_isNull(formCustomClassName)) {
          formControlClasses = cx(formControlClasses, classes[formCustomClassName]);
      }
  }
  if (inputProps !== undefined) {
    labelClasses =
      `${labelClasses
      } ${
        cx({
          [classes.labelWithAdornment]: inputProps.endAdornment !== undefined,
        })}`;
  }
  const input =
    `${classes.input
    } ${
    cx(
      {
        [classes.inputRTL]: rtlActive,
        [classes.inputNoLabel]: labelText === undefined,
      },
      classes[inputProps.className]
    )}`;
  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      aria-describedby={`${id}-text`}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: input,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
      {helpText !== undefined ? (
        <FormHelperText id={`${id}-text`}>{helpText}</FormHelperText>
      ) : null}
      {errorText !== undefined ? (
        <FormHelperText error={true} id={`${id}-error-text`}>{errorText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  rtlActive: PropTypes.bool,
  customUnderlineClasses: PropTypes.string,
};

export default withStyles(customInputStyle)(CustomInput);

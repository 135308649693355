import _sortBy from 'lodash/sortBy';
import initState from '../../reducers/redux-initial-state';

import {types} from './constants';
import {injectNetworkStats, injectSiteStats} from './service';

export default (state = initState.organizationSitesTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_SITES_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    }
    case types.SET_ORGANIZATION_SITES: {
      return {
        ...state,
        sitesNotInOrganization: action.data.sitesNotInOrganization,
        sitesInOrganization: injectSiteStats(
          injectNetworkStats(action.data.sitesInOrganization, state.networkStats),
          state.siteStats,
        ),
      };
    }
    case types.SET_ORGANIZATION_SITE_NETWORKS_STATS: {
      return {
        ...state,
        sitesInOrganization: injectNetworkStats(state.sitesInOrganization, action.data),
        networkStats: action.data,
      };
    }
    case types.SET_ORGANIZATION_SITE_STATS: {
      return {
        ...state,
        sitesInOrganization: injectSiteStats(state.sitesInOrganization, action.data),
        siteStats: action.data,
      };
    }
    case types.REMOVE_SITE_FROM_ORGANIZATION: {
      const newSitesInOrganization = [...state.sitesInOrganization];
      const removedSite = newSitesInOrganization.splice(action.siteIndex, 1);

      return {
        ...state,
        sitesInOrganization: newSitesInOrganization,
        sitesNotInOrganization: _sortBy(
          [...state.sitesNotInOrganization, ...removedSite],
          (site) => site.name,
        ),
      };
    }
    case types.UPDATE_ORGANIZATION_SITES: {
      return {
        ...state,
        sitesInOrganization: [...action.sitesInOrganization],
        sitesNotInOrganization: [...action.sitesNotInOrganization],
      };
    }

    default:
      return state;
  }
};

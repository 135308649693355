import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';

import {CustomInput} from '../../material-dashboard-pro-react/components';

import {formatDateTimeToUserTimezone} from '../../utils/dateTime';

import {RenderOrEmpty} from '../index';

import style from './style';

class ApiKeyForm extends React.Component {
  onChange = (e) => {
    this.props.onChange(e.target.name, e.target.value);
  };

  render() {
    const {classes, apiKey, isNewApiKey, isEditing} = this.props;
    const propsFormControl = {
      fullWidth: true,
    };
    const propsInput = {
      id: {
        disabled: true,
        value: apiKey.id,
        name: 'id',
        type: 'text',
        fullWidth: true,
      },
      createdAt: {
        disabled: true,
        value: formatDateTimeToUserTimezone(apiKey.createdAt),
        name: 'createdAt',
        type: 'text',
        fullWidth: true,
      },
      lastAccessAt: {
        disabled: true,
        value: formatDateTimeToUserTimezone(apiKey.lastAccessAt),
        name: 'lastAccessAt',
        type: 'text',
        fullWidth: true,
      },
      lastIp: {
        disabled: true,
        value: apiKey.lastIp,
        name: 'lastIp',
        type: 'text',
        fullWidth: true,
      },
      description: {
        disabled: !isEditing,
        value: apiKey.description,
        name: 'description',
        type: 'text',
        fullWidth: true,
        onChange: this.onChange,
      },
    };

    return (
      <div className={classes.form}>
        <RenderOrEmpty isShow={!isNewApiKey}>
          <CustomInput
            labelText={I18n.t('apiAccessManagementPage.form.labels.id')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.id}
            labelProps={{shrink: true}}
          />
          <CustomInput
            labelText={I18n.t('apiAccessManagementPage.form.labels.createdAt')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.createdAt}
            labelProps={{shrink: true}}
          />
          <CustomInput
            labelText={I18n.t('apiAccessManagementPage.form.labels.lastAccessAt')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.lastAccessAt}
            labelProps={{shrink: true}}
          />
          <CustomInput
            labelText={I18n.t('apiAccessManagementPage.form.labels.lastIp')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.lastIp}
            labelProps={{shrink: true}}
          />
        </RenderOrEmpty>
        <CustomInput
          labelText={I18n.t('apiAccessManagementPage.form.labels.description')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.description}
          labelProps={{shrink: true}}
        />
      </div>
    );
  }
}

ApiKeyForm.propTypes = {
  apiKey: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    lastAccessAt: PropTypes.string,
    lastIp: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isNewApiKey: PropTypes.bool,
};

ApiKeyForm.defaultProps = {
  isEditing: false,
  isNewApiKey: false,
};

export default withStyles(style)(ApiKeyForm);

import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.notificationsManagementReducer, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_TYPES: {
      return {
        ...state,
        notificationTypes: action.data,
      };
    }
    case types.SET_NOTIFICATION_PROFILES: {
      return {
        ...state,
        notificationProfiles: action.data,
      };
    }
    case types.SET_EDITING_MODE: {
      return {
        ...state,
        isEditingMode: action.isEditingMode,
      };
    }
    case types.RESET_STATE: {
      return {...initState.notificationsManagementReducer};
    }
    default:
      return state;
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

import {
  ContainerWithListAndForm,
  EntityEditingCard,
  OrganizationProfilesTable,
  MessageBox,
  RenderOrEmpty,
} from '../../components/index';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {
  removeProfileFromOrganization,
  getOrganizationProfiles,
  updateOrganizationProfiles,
  changeProfilesEditMode,
  getOrganizationProfileStats,
} from './action';
import {cancelRequests} from '../Loading/action';
import OrganizationProfilesEditForm
  from '../OrganizationProfilesEditForm/OrganizationProfilesEditForm';

import {
  ACCESS_RIGHTS,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
} from '../../constants';
import {widgetKeys} from './constants';

import style from './style';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.ORGANIZATION_PROFILE_LINK],
};

class OrganizationProfilesTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdateProfiles(prevProps, props) {
    return !_isEqual(prevProps.organization.id, props.organization.id)
      && !_isNil(props.organization.id);
  }

  constructor(props) {
    super(props);
    this.state = OrganizationProfilesTab.initialLocalState;
  }

  componentDidMount() {
    const {organization} = this.props;
    if (!_isNil(organization.id)) {
      this.props.getOrganizationProfiles(organization.id);
      this.props.getOrganizationProfileStats(organization.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (OrganizationProfilesTab.shouldUpdateProfiles(prevProps, this.props)) {
      this.gotoProfileListAndReload();
    }
  }

  componentWillUnmount() {
    this.props.cancelRequests([widgetKeys.ORGANIZATION_PROFILES_STATS], false);
  }

  getContainerItemByModeShowingContainer = () => {
    const {accountInfo, isEditMode} = this.props;
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', true);

    let containerItem = null;

    if (isEditMode) {
      containerItem = (
        <>
          <RenderOrEmpty isShow={organizationsAllowed} >
            <OrganizationProfilesEditForm
      isEditMode={isEditMode}
      profilesNotInOrganization={this.props.profilesNotInOrganization}
      profilesInOrganization={this.props.profilesInOrganization}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={!organizationsAllowed}>
            <MessageBox
      message={I18n.t('organizations.errorMessages.organizationsNotAllowed')}
            />
          </RenderOrEmpty>
        </>
      );
    }
    return containerItem;
  };

  getProfileListOrMessage = () => {
    const {tableMode} = this.state;
    const {classes, accountInfo, profilesInOrganization, isEditMode, dashboardShown} = this.props;

    const tableClasses = {
      tableBody: classNames({
        [classes.organizationProfilesTab__tableBody]: dashboardShown,
        [classes.organizationProfilesTab__tableBody_noDashboard]: !dashboardShown,
      }),
    };
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', true);

    let containerItem = null;

    if (!isEditMode) {
      containerItem = (
        <>
          <RenderOrEmpty isShow={organizationsAllowed} >
            <OrganizationProfilesTable
              classes={tableClasses}
              tableData={profilesInOrganization}
              mode={tableMode}
              isEditMode={isEditMode}
              removeProfileFromOrganization={this.handleRemoveProfile}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={!organizationsAllowed}>
            <MessageBox
              message={I18n.t('organizations.errorMessages.organizationsNotAllowed')}
            />
          </RenderOrEmpty>
        </>
      );
    }
    return containerItem;
  };

  handleRemoveProfile = (profileIndex) => {
    const {organization, profilesInOrganization} = this.props;
    this.props.removeProfileFromOrganization(
      profileIndex,
      organization.id,
      profilesInOrganization[profileIndex].id,
    ).then(() => {
      this.props.getOrganizationProfiles(this.props.organization.id);
      if (!_isNil(this.props.onUpdateClientProfiles)) {
        this.props.onUpdateClientProfiles();
      }
    });
  };

  handleClickButtonCancelProfilesEditing = () => {
    this.props.changeProfilesEditMode(false);
  };

  handleClickButtonEditProfiles = () => {
    this.props.changeProfilesEditMode(true);
  };

  handleClickButtonSaveProfiles = () => {
    const {
      organization,
      updatedProfilesInOrganization,
    } = this.props;
    this.props.updateOrganizationProfiles(organization.id, updatedProfilesInOrganization)
      .then(() => {
        if (!_isNil(this.props.onUpdateClientProfiles)) {
          this.props.onUpdateClientProfiles();
        }
      });
    this.props.changeProfilesEditMode(false);
  };

  get isValid() {
    return this.props.profilesInOrganization.length > 0
      || this.props.profilesNotInOrganization.length > 0;
  }

  gotoProfileListAndReload = () => {
    this.setState(OrganizationProfilesTab.initialLocalState);
    this.props.getOrganizationProfiles(this.props.organization.id);
  };

  render() {
    const cardClass = classNames({
      card_organizationSubtab: this.props.dashboardShown,
      card_organizationSubtab_noDashboard: !this.props.dashboardShown,
    });
    const contentClass = classNames({
      card__content_organizationSubtab: this.props.dashboardShown,
      card__content_organizationSubtab_noDashboard: !this.props.dashboardShown,
    });

    return (
      <EntityEditingCard
    key="profiles-subtab"
    entityType={I18n.t('entitiesTypes.organization')}
    title={I18n.t('organizations.tabs.profiles.title')}
    isEditMode={this.props.isEditMode}
    isThereEditBlock={true}
    isValidEntity={this.isValid}
    handleClickButtonCancel={this.handleClickButtonCancelProfilesEditing}
    handleClickButtonEdit={this.handleClickButtonEditProfiles}
    handleClickButtonSave={this.handleClickButtonSaveProfiles}
    customCardClass={cardClass}
    customCardContentClass={contentClass}
    editButtonText={I18n.t('organizations.tabs.profiles.editButtonLabel')}
    accessRights={rightAvailabilityMap}
    content={(
      <ContainerWithListAndForm
        list={this.getProfileListOrMessage()}
        form={this.getContainerItemByModeShowingContainer()}
      />
    )}
      />
    );
  }
}

OrganizationProfilesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  profilesInOrganization: PropTypes.array.isRequired,
  profilesNotInOrganization: PropTypes.array.isRequired,
  dashboardShown: PropTypes.bool.isRequired,
  updatedProfilesInOrganization: PropTypes.array,

  changeProfilesEditMode: PropTypes.func.isRequired,
  removeProfileFromOrganization: PropTypes.func.isRequired,
  getOrganizationProfiles: PropTypes.func.isRequired,
  getOrganizationProfileStats: PropTypes.func.isRequired,
  updateOrganizationProfiles: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  onUpdateClientProfiles: PropTypes.func.isRequired,
};

OrganizationProfilesTab.defaultProps = {
  updatedProfilesInOrganization: [],
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  organization: state.organizationsReducer.selectedOrganization,

  dashboardShown: state.universalDashboardReducer.isShowDashboard,

  isEditMode: state.organizationProfilesTabReducer.isEditMode,
  profilesInOrganization: state.organizationProfilesTabReducer.profilesInOrganization,
  profilesNotInOrganization: state.organizationProfilesTabReducer.profilesNotInOrganization,
  updatedProfilesInOrganization: state.organizationProfilesEditFormReducer.profilesInOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  changeProfilesEditMode: bindActionCreators(changeProfilesEditMode, dispatch),
  removeProfileFromOrganization: bindActionCreators(removeProfileFromOrganization, dispatch),
  getOrganizationProfiles: bindActionCreators(getOrganizationProfiles, dispatch),
  getOrganizationProfileStats: bindActionCreators(getOrganizationProfileStats, dispatch),
  updateOrganizationProfiles: bindActionCreators(updateOrganizationProfiles, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(OrganizationProfilesTab)));

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';
import _get from 'lodash/get';

import classNames from 'classnames';

import {
  MODAL_WINDOW_NOTIFY_TYPE,
  ORGANIZATION_OPERATOR_PERMISSIONS,
  USER_ROLES,
} from '../../../constants';

import {
  Header,
  NotifyModalWindow,
  RenderOrEmpty,
  Sidebar,
} from '../../../components';

import {Loading} from '../../../containers';

import {
  getUserInfo,
  closeModalWindow,
  openModalWindow,
} from './action';

import {logout} from '../../../actions/auth/logout';
import {getUserAccounts} from '../../../actions/userAccounts/getUserAccounts';
import {getUserOrganizations} from '../../../actions/userOrganizations/getUserOrganizations';
import {setLoggedAccountToStore} from '../../../actions/userAccounts/setLoggedAccountToStore';
import {switchAccount} from '../../../actions/userAccounts/switchAccount';
import {switchOrganization} from '../../../actions/userOrganizations/switchOrganization';
import {setLoggedAccount} from '../../../utils/account';
import {getOperatorRoutes} from '../../routes/operator';

import logo from './img/logo.svg';
import logoMini from './img/logoMini.svg';

import style from './style';

class OperatorLayout extends Component {
  static wasAccountSwitched(prevProps, props) {
    return prevProps.loggedAccount.accountId !== props.loggedAccount.accountId;
  }

  static hasAccountsUpdated(prevProps, props) {
    return !_isEmpty(props.userAccounts) &&
    !_isEmpty(props.loggedAccount) &&
    !_isEqual(props.userAccounts, prevProps.userAccounts);
  }

  state = {
    mobileOpen: false,
    miniActive: true,
    isDataLoaded: false,
  };

  componentDidMount() {
    this.props.getUserAccounts();
    this.props.getUserOrganizations();

    if (this.isLoggedAccountObjectLoadedToRedux()) {
      this.getUserInfo();
    } else {
      this.props.setLoggedAccountToStore();
    }
  }

  componentDidUpdate(prevProps) {
    if (OperatorLayout.wasAccountSwitched(prevProps, this.props)) {
      this.getUserInfo();
    }

    if (OperatorLayout.hasAccountsUpdated(prevProps, this.props)) {
      this.updateLoggedAccount();
    }
  }

  get routes() {
    return getOperatorRoutes(this.props.accountInfo, this.props.currentOrganization);
  }

  getUserInfo = () => {
    this.props.getUserInfo()
      .then(() => this.setState({isDataLoaded: true}))
      .catch(() => this.logout());
  };

  updateLoggedAccount = () => {
    const loggedAccountId = _get(this.props.loggedAccount, 'accountId', null);
    if (loggedAccountId) {
      const account = _find(
        this.props.userAccounts,
        ['accountId', this.props.loggedAccount.accountId],
      );
      if (account && !_isEqual(this.props.loggedAccount, account)) {
        Promise.all([setLoggedAccount(
          account.accountId,
          account.role,
          this.props.loggedAccount.email,
          null, // for new account switch to all orgs
        ), this.props.setLoggedAccountToStore()]);
      }
    }
  };

  isLoggedAccountObjectLoadedToRedux = () => {
    const {loggedAccount} = this.props;
    return !_isEmpty(loggedAccount);
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({mobileOpen: !prevState.mobileOpen}));
  };

  sidebarMinimize = () => {
    this.setState((prevState) => ({miniActive: !prevState.miniActive}));
  };

  logout = () => {
    this.props.logoutAction(this.props.history);
  };

  switchAccount = (account) => {
    this.props.switchAccountAction(account, this.props.authData);
  };

  switchOrganization = (organization) => {
    const isOrganizationManager =
      _isEqual(this.props.loggedAccount.role, USER_ROLES.ORGANIZATION_OPERATOR)
        && this.props.userOrganizations.findIndex(
          (org) => _isEqual(org.permission, ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER),
        ) >= 0;

    return this.props.switchOrganizationAction(organization, this.props.history)
      .then(() => {
        if (_isNil(organization) && isOrganizationManager) {
          this.props.openModalWindow(
            I18n.t('operatorLayout.notifications.read-only'),
            MODAL_WINDOW_NOTIFY_TYPE.INFO,
          );
        }
      });
  };

  isEditMode = () => {
    const {editModes} = this.props;
    return Object.keys(editModes).reduce(
      (isEditMode, key) => isEditMode || editModes[key],
      false,
    );
  };

  render() {
    const {
      children,
      classes,
      loggedAccount,
      accountInfo,
      currentOrganization,
      modalWindow,
      notificationBannerData,
      userAccounts,
      userOrganizations,
      ...rest
    } = this.props;

    const isEditMode = this.isEditMode();
    return (
      <div className={classes.operatorLayout}>
        <RenderOrEmpty isShow={modalWindow.isOpen}>
          <NotifyModalWindow
            text={modalWindow.text}
            close={this.props.closeModalWindow}
          />
        </RenderOrEmpty>
        <div
          className={classNames(
            classes.operatorLayout__sidebar,
            {
              [classes.operatorLayout_disabled]: isEditMode,
            },
          )}
        >
          <Sidebar
            isOpen={this.state.mobileOpen}
            loggedAccount={loggedAccount}
            accountInfo={accountInfo}
            currentOrganization={currentOrganization}
            logo={logo}
            logoMini={logoMini}
            miniActive={this.state.mobileOpen ? false : this.state.miniActive}
            notificationBannerData={notificationBannerData}
            routes={this.routes}
            userAccounts={userAccounts}
            userOrganizations={userOrganizations}
            handleDrawerToggle={this.handleDrawerToggle}
            logout={this.logout}
            switchAccount={this.switchAccount}
            switchOrganization={this.switchOrganization}
            {...rest}
          />
        </div>
        <div className={classNames(
          classes.operatorLayout__container,
          {
            [classes.container__sidebar_mini]: this.state.miniActive,
            [classes.container__perfectScrollbar]: navigator.platform.indexOf('Win') > -1,
          },
        )}
        >
          <div
            className={classNames(
              classes.operatorLayout__header,
              {
                [classes.operatorLayout_disabled]: isEditMode,
              },
            )}
          >
            <Header
              compressSidebar={this.sidebarMinimize}
              handleDrawerToggle={this.handleDrawerToggle}
              logout={this.logout}
              loggedAccount={loggedAccount}
              accountInfo={accountInfo}
              currentOrganization={currentOrganization}
              miniActive={this.state.miniActive}
              notificationBannerData={notificationBannerData}
              routes={this.routes}
              switchAccount={this.switchAccount}
              switchOrganization={this.switchOrganization}
              userAccounts={userAccounts}
              userOrganizations={userOrganizations}
              {...rest}
            />
          </div>
          <div className={classes.operatorLayout__content}>
            <Loading />
            {this.state.isDataLoaded && children}
          </div>
        </div>
      </div>
    );
  }
}

OperatorLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  notificationBannerData: PropTypes.object.isRequired,
  modalWindow: PropTypes.object.isRequired,

  authData: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  userAccounts: PropTypes.array.isRequired,
  currentOrganization: PropTypes.object,
  userOrganizations: PropTypes.array.isRequired,

  editModes: PropTypes.object.isRequired,

  closeModalWindow: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  getUserOrganizations: PropTypes.func.isRequired,
  logoutAction: PropTypes.func.isRequired,
  setLoggedAccountToStore: PropTypes.func.isRequired,
  switchAccountAction: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  switchOrganizationAction: PropTypes.func.isRequired,
};

OperatorLayout.defaultProps = {
  currentOrganization: null,
  children: '',
};

const mapStateToProps = (state) => ({
  notificationBannerData: state.operatorLayoutReducer.notificationBannerData,
  modalWindow: state.operatorLayoutReducer.modalWindow,
  accountInfo: state.operatorLayoutReducer.accountInfo,

  authData: state.authReducer,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  userAccounts: state.userAccountsReducer.userAccounts,
  userOrganizations: state.userOrganizationsReducer.userOrganizations,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  editModes: {
    isCampusEditMode: state.campusReducer.isEditMode,
    isNetworkEditMode: state.campusNetworksTabReducer.isEditMode,
    isSubnetEditMode: state.campusSubnetsTabReducer.isEditMode,
    isPolicyEditMode: state.policyReducer.isEditMode,
    isWhiteBlackListEditMode: state.whiteBlackListReducer.isEditMode,
    isAccessManagementEditMode: state.accessManagementReducer.isEditingMode,
    isClientManagerEditMode: state.clientsManagerReducer.isEditMode,
    isClientConfigurerEditMode: state.clientsConfigurerReducer.isEditMode,
    isClientInstallKeyEditMode: state.clientProfileInstallFormReducer.isEditMode,
    isOrganizationEditMode: state.organizationsReducer.isEditMode,
    isOrganizationSitesEditMode: state.organizationSitesTabReducer.isEditMode,
    isOrganizationProfilesEditMode: state.organizationProfilesTabReducer.isEditMode,
    isPersonaEditMode: state.personaReducer.isEditMode,
  },
});

const mapDispatchToProps = (dispatch) => ({
  closeModalWindow: bindActionCreators(closeModalWindow, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  getUserAccounts: bindActionCreators(getUserAccounts, dispatch),
  getUserOrganizations: bindActionCreators(getUserOrganizations, dispatch),
  logoutAction: bindActionCreators(logout, dispatch),
  setLoggedAccountToStore: bindActionCreators(setLoggedAccountToStore, dispatch),
  switchAccountAction: bindActionCreators(switchAccount, dispatch),
  switchOrganizationAction: bindActionCreators(switchOrganization, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(OperatorLayout)));

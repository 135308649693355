import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {TABS as clientTabs} from '../../app-common/Clients/constants';
import {EntityCreatingCard} from '../index';
import {ClientBundleEditForm} from '../../containers';

class ClientBundleCreating extends React.Component {
  render() {
    const {
      isValidBundleInfo,
      handleClickButtonCancel,
      handleClickButtonSave,
    } = this.props;

    return (
      <EntityCreatingCard
        title={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.createBundle`)}
        handleClickButtonCancel={handleClickButtonCancel}
        handleClickButtonSave={handleClickButtonSave}
        isValidEntity={isValidBundleInfo}
        customCardContentClass="card__content_clientBundle"
        content={
          <ClientBundleEditForm isCreating={true} />
        }
      />
    );
  }
}

ClientBundleCreating.propTypes = {
  isValidBundleInfo: PropTypes.bool.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
};

export default ClientBundleCreating;

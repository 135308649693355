import {Component} from 'react';
import PropTypes from 'prop-types';

class RenderOrEmpty extends Component {
  render() {
    let child;
    if (this.props.isShow) {
      child = this.props.children;
    } else {
      child = null;
    }
    return child;
  }
}
RenderOrEmpty.propTypes = {
  children: PropTypes.node.isRequired,
  isShow: PropTypes.bool.isRequired,
};

export default RenderOrEmpty;

export const types = {
  CHANGE_EDIT_MODE_AND_CAMPUS_STATE: 'CHANGE_EDIT_MODE_AND_CAMPUS_STATE',
  SET_CAMPUSES: 'SET_CAMPUSES',
  SET_CAMPUSES_TABLE_DATA: 'SET_CAMPUSES_TABLE_DATA',
  SET_SELECTED_CAMPUS: 'SET_SELECTED_CAMPUS',
  SET_SELECTED_CAMPUS_INDEX: 'SET_SELECTED_CAMPUS_INDEX',
  SET_SELECTED_PERIOD_INDEX: 'SET_SELECTED_PERIOD_INDEX',
  SET_UPDATED_CAMPUS: 'SET_UPDATED_CAMPUS',
  RESET_SELECTED_CAMPUS_INDEX: 'RESET_SELECTED_CAMPUS_INDEX',
  RESET_CAMPUSES: 'RESET_CAMPUSES',
  SET_CAMPUS_POLICIES: 'SET_CAMPUS_POLICIES',
};

export const widgetKeys = {
  REQUEST_STATS: 'campuses_requestStats',
  CAMPUSES_STATS: 'campuses_campusStats',
  PERFORMANCE_STATS: 'campuses_performanceStats',
  TOP_CATEGORIES_ALLOWED: 'campuses_topCategoriesAllowed',
  TOP_CATEGORIES_FORBIDDEN: 'campuses_topCategoriesForbidden',
  BLOCK_STATS: 'campuses_categoryGroupStats',
  NETWORK_STATUSES: 'campuses_networkStatuses',
};

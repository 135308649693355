const style = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  item: {
    flexBasis: '49%',
  },
};

export default style;

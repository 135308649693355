import React from 'react';
import PropTypes from 'prop-types';
import {Help} from './index';

function Unknown(props) {
  const {className, title} = props;
  return (
    <Help className={className} titleAccess={title} />
  );
}

Unknown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

Unknown.defaultProps = {
  className: '',
  title: null,
};

export default Unknown;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import _get from 'lodash/get';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import {Dropdown} from '../index';

import {PERIODS} from '../../constants';

import {Refresh} from '../../icons/index';

import style from './style';

class DashboardOperatorFiltersPanel extends Component {
  static initialLocalState = {
    selectedCampusIndex: 0,
    selectedPeriodIndex: 0,
  };

  static shouldReloadDashboard(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(
        _get(prevProps.currentOrganization, 'id', null),
        _get(props.currentOrganization, 'id', null));
  }

  constructor(props) {
    super(props);
    this.state = DashboardOperatorFiltersPanel.initialLocalState;

    this.campusesOptions = [];
    this.campusesOptions.push(I18n.t('dashboard.filters.ALL_CAMPUSES'));

    this.periodsOptions = [];
    this.periodsOptions.push(...Object.keys(PERIODS)
      .map((period) => I18n.t(`dashboard.filters.${period}`)));
  }

  componentDidMount() {
    this.handleClickOnButtonRefresh();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props.campuses, nextProps.campuses)
      || !_isEqual(this.state.selectedCampusIndex, nextState.selectedCampusIndex)
      || !_isEqual(this.state.selectedPeriodIndex, nextState.selectedPeriodIndex)
      || !_isEqual(this.props.loggedAccount, nextProps.loggedAccount)
      || !_isEqual(
        _get(this.props.currentOrganization, 'id', null),
        _get(nextProps.currentOrganization, 'id', null));
  }

  componentDidUpdate(prevProps) {
    if (DashboardOperatorFiltersPanel.shouldReloadDashboard(prevProps, this.props)) {
      this.resetLocalState();
    }
  }

  getCampusesOptions = () => {
    const {campuses} = this.props;
    if (campuses.length === this.campusesOptions.length - 1) {
      return this.campusesOptions;
    }
    return [
      ...this.campusesOptions,
      ...campuses.map((item) => item.name),
    ];
  };

  resetLocalState = () =>
    this.setState(
      DashboardOperatorFiltersPanel.initialLocalState,
      () => this.handleClickOnButtonRefresh(),
    );

  handleChangeCampus = (e) => {
    this.setState({
      selectedCampusIndex: e.target.value,
    });
  };

  handleChangePeriod = (e) => {
    this.setState({
      selectedPeriodIndex: e.target.value,
    });
  };

  handleClickOnButtonRefresh = () => {
    const {campuses} = this.props;
    const {
      selectedCampusIndex,
      selectedPeriodIndex,
    } = this.state;

    let campusId = null;
    const campusIndex = parseInt(selectedCampusIndex, 10);
    if (campusIndex > 0) {
      campusId = campuses[campusIndex - 1].id;
    }
    this.props.handleRefresh({
      period: this.periodsOptions[selectedPeriodIndex],
      campusId: campusId,
    });
  };

  render() {
    const {classes} = this.props;
    const {
      selectedCampusIndex,
      selectedPeriodIndex,
    } = this.state;
    return (
      <div className={classes.filtersPanel}>
        <div className={classes.filtersPanel__container}>
          <div className={classes.filtersPanel__item}>
            <Dropdown
              options={this.getCampusesOptions()}
              selectedItemIndex={selectedCampusIndex}
              customSelectClass="dropdown__select_dashboard"
              onChangeValue={this.handleChangeCampus}
            />
          </div>
          <div className={classes.filtersPanel__item}>
            <Dropdown
              options={this.periodsOptions}
              selectedItemIndex={selectedPeriodIndex}
              customSelectClass="dropdown__select_dashboard"
              onChangeValue={this.handleChangePeriod}
            />
          </div>
          <div className={classes.filtersPanel__item}>
            <CustomButton
              color="secondary"
              onClick={this.handleClickOnButtonRefresh}
              size="sm"
            >
              <Refresh />
              <span className={classes.buttonRefresh_text}>
                {I18n.t('buttons.refresh').toUpperCase()}
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

DashboardOperatorFiltersPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  campuses: PropTypes.array,
  handleRefresh: PropTypes.func.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
};

DashboardOperatorFiltersPanel.defaultProps = {
  campuses: [],
  currentOrganization: null,
};

export default withStyles(style)(DashboardOperatorFiltersPanel);

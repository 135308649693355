import {editModeDisabledStyle} from '../../styles/style';

const style = () => ({
  loadingLayout: {
    'position': 'relative',
    'top': '0',
    'height': '100%',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  loadingLayout_disabled: {
    ...editModeDisabledStyle,
  },
});

export default style;

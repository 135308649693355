import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import TopCategoriesLineChart from '../../components/TopCategoriesLineChart/TopCategoriesLineChart';
import {cancelRequests} from '../Loading/action';
import {getRequestsStatsByCategories} from './action';

type RequestsByCategoryWidgetProps = {
  filters: {[key: string]: string | number};
  readyToLoadStats: boolean;
  widgetKey: string;
}

/**
 * Widget that shows request stats by categories in line chart.
 * Handles graph data loading
 * @param {filters} filters - filters for loading data. Can include ids, periods, etc
 * @param {readyToLoadStats} readyToLoadStats - boolean, load data when it's true
 * @param {widgetKey} widgetKey - widget key to check if data is loading
 */
function RequestsByCategoryWidget({widgetKey,
  readyToLoadStats,
  filters}: RequestsByCategoryWidgetProps) {
  // Using any as a type for store because function createStore doesn't support types
  // and gives an error. Maybe we should use redux toolkit in the future for creating store
  const graphData =
    useSelector((store: any) => store.requestsByCategoryWidgetReducer.userRequestsByCategories);

  const dispatch = useDispatch();

  useEffect(() => {
    cancelRequests([widgetKey], false);
    if (readyToLoadStats) {
      getRequestsStatsByCategories(filters, filters.period as string, widgetKey)(dispatch);
    }
  }, [filters, widgetKey, readyToLoadStats]);

  return (
    <TopCategoriesLineChart
      graphData={graphData}
      widgetKey={widgetKey}
    />
  );
}

export default RequestsByCategoryWidget;

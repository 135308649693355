import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import {withStyles} from '@material-ui/core/styles';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

import {CustomInput} from '../../material-dashboard-pro-react/components';
import {TABS as clientTabs} from '../../app-common/Clients/constants';
import {STATES_ENTITY} from '../../constants';
import {Dropdown, RenderOrEmpty, LabelWithCopyButton} from '../../components';
import {isPositiveInt, validate} from '../../utils/validators';
import {
  setArchitectures,
  setSelectedArchitectureIndex, setSelectedDurationIndex,
  setSelectedPlatformIndex,
  setValidationResultBundleInfo,
  updateBundleInfo,
} from './action';

import style from './style';

class ClientBundleEditForm extends React.Component {
  static initialLocalState = {
    validation: {
      maxInstalls: true,
    },
  };

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.profileState, STATES_ENTITY.EDITING_CANCELED);
  }

  static shouldUpdateClientBundleObject(prevProps, props) {
    return !_isEqual(prevProps.bundle, props.bundle)
      || (!_isEqual(prevProps.architectures, props.architectures)
        && !_isNil(props.bundle.id));
  }

  durations = ([
    {
      label: I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.durations.hour1`),
      milli: 3600000,
    },
    {
      label: I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.durations.hours24`),
      milli: 3600000 * 24,
    },
    {
      label: I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.durations.hours48`),
      milli: 3600000 * 48,
    },
    {
      label: I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.durations.days7`),
      milli: 3600000 * 24 * 7,
    },
    {
      label: I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.durations.days30`),
      milli: 3600000 * 24 * 30,
    },
    {
      label: I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.durations.indefinite`),
      milli: -1,
    },
  ]);

  constructor(props) {
    super(props);
    this.state = _cloneDeep(ClientBundleEditForm.initialLocalState);
  }

  componentDidMount() {
    this.getFormData(this.props.bundle);
  }

  componentDidUpdate(prevProps) {
    if (ClientBundleEditForm.shouldUpdateClientBundleObject(prevProps, this.props)) {
      this.getFormData(this.props.bundle);
    }
    if (ClientBundleEditForm.shouldResetFormData(prevProps, this.props)) {
      this.getFormData(this.props.bundle);
      this.resetValidation();
    }
  }

  getFormData = (bundleData) => {
    const {
      platforms,
      // eslint-disable-next-line no-shadow
      setSelectedPlatformIndex,
      // eslint-disable-next-line no-shadow
      setSelectedArchitectureIndex,
      // eslint-disable-next-line no-shadow
      setSelectedDurationIndex,
      // eslint-disable-next-line no-shadow
      setArchitectures,
      // eslint-disable-next-line no-shadow
      updateBundleInfo,
    } = this.props;
    const platformIndex = this.getSelectedPlatformIndex(bundleData);
    setSelectedPlatformIndex(platformIndex);
    const architectureIndex = this.getSelectedArchitectureIndex(bundleData);
    setSelectedArchitectureIndex(architectureIndex);
    const durationIndex = this.getSelectedDurationIndex(bundleData);
    setSelectedDurationIndex(durationIndex);
    const selectedArchitectures = _get(platforms[platformIndex], 'architectures', []);
    setArchitectures(selectedArchitectures);
    updateBundleInfo(
      {
        ...bundleData,
        maxInstalls: bundleData.installs.max,
        osId: _get(platforms[platformIndex], 'id', null),
        platformName: _get(platforms[platformIndex], 'name', ''),
        architectureId: _get(selectedArchitectures[architectureIndex], 'id', null),
        architectureName: _get(selectedArchitectures[architectureIndex], 'name', ''),
        durationMilli: _get(this.durations[durationIndex], 'milli', 0),
        durationLabel: _get(this.durations[durationIndex], 'label', ''),
      },
      true,
    );
  };

  getPlatformDropdownOptions = () => this.props.platforms.map((platform) => platform.name);

  getArchitectureDropdownOptions = () => this.props.architectures.map((arch) => arch.name);

  getDurationsDropdownOptions = () => this.durations.map((duration) => duration.label);

  getSelectedPlatformIndex = (bundleData) => _findIndex(
    this.props.platforms,
    (platform) => _isEqual(platform.id, bundleData.osId),
  );

  getSelectedArchitectureIndex = (bundleData) => _findIndex(
    this.props.architectures,
    (arch) => _isEqual(arch.id, bundleData.architectureId),
  );

  getSelectedDurationIndex = (bundleData) => _findIndex(
    this.durations,
    (d) => _isEqual(d.milli, bundleData.durationMilli),
  );

  keyText = (bundle) => _get(bundle, 'key', '');

  hasInstaller = (installers, editableBundleInfo) => {
    const osId = editableBundleInfo.osId;
    const archId = editableBundleInfo.architectureId;
    return installers.find((element) => element.osId === osId && element.architectureId === archId);
  };

  installerURLText = (installer) => _get(installer, 'link', '');

  formatString = (command, arg) => command.replace(/{([0]+)}/g, (match) =>
    // check if the argument is present
    (typeof arg === 'undefined' ? match : arg),
  );

  installCommandText = (installer, key) => {
    const commandStart = _get(installer, 'installCommand', '');
    if (key !== '' && commandStart !== '') {
      return this.formatString(commandStart, key);
    }
    return 'Can\'t form command for this key';
  };

  handleChangePlatform = (e) => {
    const {editableBundleInfo, platforms} = this.props;
    const index = e.target.value;

    this.props.updateBundleInfo(
      {
        ...editableBundleInfo,
        osId: platforms[index].id,
        platformName: platforms[index].name,
        architectureId: _get(platforms[index].architectures[0], 'id', null),
        architectureName: _get(platforms[index].architectures[0], 'name', ''),
      },
      true,
    );
    this.props.setSelectedPlatformIndex(index);
    this.props.setArchitectures(platforms[index].architectures);
    this.props.setSelectedArchitectureIndex(0);
  };

  handleChangeArchitecture = (e) => {
    const {editableBundleInfo, architectures} = this.props;
    const index = e.target.value;

    this.props.updateBundleInfo(
      {
        ...editableBundleInfo,
        architectureId: architectures[index].id,
        architectureName: architectures[index].name,
      },
      true,
    );
    this.props.setSelectedArchitectureIndex(index);
  };

  handleChangeDuration = (e) => {
    const {editableBundleInfo} = this.props;
    const index = e.target.value;

    this.props.updateBundleInfo(
      {
        ...editableBundleInfo,
        durationMilli: this.durations[index].milli,
        durationLabel: this.durations[index].label,
      },
      true,
    );
  };

  handleChangeInputInfo = (e) => {
    const {editableBundleInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableBundleInfo,
      maxInstalls: e.target.value,
    });
    this.props.updateBundleInfo(
      {
        ...editableBundleInfo,
        maxInstalls: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  resetValidation = () => {
    this.props.setValidationResultBundleInfo(true);
    this.setState(ClientBundleEditForm.initialLocalState);
  };

  validate = (data) => validate(
    [
      {
        name: 'maxInstalls',
        validationRules: [isPositiveInt],
      },
    ],
    data,
    {
      maxInstalls: true,
    },
  );

  render() {
    const {
      classes,
      editableBundleInfo,
      isEditMode,
      isCreating,
      selectedPlatformIndex,
      bundle,
      installers,
    } = this.props;
    const {validation} = this.state;
    const installsValue = (isEditMode)
      ? editableBundleInfo.maxInstalls
      // eslint-disable-next-line max-len
      : `${_get(editableBundleInfo.installs, 'used', '')} | ${_get(editableBundleInfo.installs, 'max', '')}`;
    const propsInput = {
      disabled: !isEditMode,
      value: installsValue,
      name: 'installs',
      onChange: this.handleChangeInputInfo,
      type: 'text',
    };
    const propsFormControl = {
      fullWidth: true,
    };
    const filteredInstaller = this.hasInstaller(installers, editableBundleInfo);

    return (
      <div className={classes.clientBundleEdit}>
        <Dropdown
          dropdownName="platform"
          customSelectClass="dropdown__select_clientBundle"
          disabled={!isCreating}
          fullWidth={true}
          label={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.platform`)}
          onChangeValue={this.handleChangePlatform}
          options={this.getPlatformDropdownOptions()}
          selectedItemIndex={this.getSelectedPlatformIndex(editableBundleInfo)}
        />
        <RenderOrEmpty isShow={selectedPlatformIndex >= 0}>
          <Dropdown
            dropdownName="architecture"
            customSelectClass="dropdown__select_clientBundle"
            disabled={!isCreating}
            fullWidth={true}
            label={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.architecture`)}
            onChangeValue={this.handleChangeArchitecture}
            options={this.getArchitectureDropdownOptions()}
            selectedItemIndex={Math.max(0, this.getSelectedArchitectureIndex(editableBundleInfo))}
          />
        </RenderOrEmpty>
        <Dropdown
          dropdownName="duration"
          customSelectClass="dropdown__select_clientBundle"
          disabled={!isEditMode}
          fullWidth={true}
          label={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.duration`)}
          onChangeValue={this.handleChangeDuration}
          options={this.getDurationsDropdownOptions()}
          selectedItemIndex={this.getSelectedDurationIndex(editableBundleInfo)}
        />

        <CustomInput
          errorText={validation.maxInstalls
            ? undefined
            : I18n.t(`clients.${clientTabs.CONFIGURE}.errorMessages.installsCountNotValid`)}
          error={!validation.maxInstalls}
          labelText={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.installs`)}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput}
          labelProps={{shrink: true}}
        />
        <RenderOrEmpty isShow={!isEditMode}>
          <div className={classes.clientBundleEdit__link}>
            <p className={classes.clientBundleEdit__packageInstallerTitle}>{I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.packagedInstaller.title`)}</p>
            <a href={editableBundleInfo.link}
               download={true}
               className={classes.clientBundleEdit__link_text}
            >
              {I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.download`)}
            </a>
          </div>
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!isEditMode && !_isNil(filteredInstaller)}>
          <div className={classes.clientBundleEdit__scriptInstaller}>
            <p className={classes.clientBundleEdit__scriptInstallerTitle}>{I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.scriptInstaller.title`)}</p>
            <LabelWithCopyButton
              id="get-script-installer-url"
              text={this.installerURLText(filteredInstaller)}
              // eslint-disable-next-line max-len
              headerText={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.scriptInstaller.url`)}
              showHeader={true}
            />
            <LabelWithCopyButton
              id="get-script-installer-key"
              text={this.keyText(bundle)}
              // eslint-disable-next-line max-len
              headerText={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.scriptInstaller.key`)}
              showHeader={true}
            />
            <LabelWithCopyButton
              id="get-script-installer-command"
              text={this.installCommandText(filteredInstaller, this.keyText(bundle))}
              // eslint-disable-next-line max-len
              headerText={I18n.t(`clients.${clientTabs.CONFIGURE}.installForm.labels.scriptInstaller.installCommand`)}
              showHeader={true}
            />
          </div>
        </RenderOrEmpty>
      </div>
    );
  }
}

ClientBundleEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  platforms: PropTypes.array.isRequired,
  architectures: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  bundle: PropTypes.object.isRequired,
  installers: PropTypes.array.isRequired,
  editableBundleInfo: PropTypes.object.isRequired,
  selectedPlatformIndex: PropTypes.number.isRequired,
  selectedArchitectureIndex: PropTypes.number.isRequired,
  selectedDurationIndex: PropTypes.number.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,

  updateBundleInfo: PropTypes.func.isRequired,
  setValidationResultBundleInfo: PropTypes.func.isRequired,
  setSelectedPlatformIndex: PropTypes.func.isRequired,
  setArchitectures: PropTypes.func.isRequired,
  setSelectedArchitectureIndex: PropTypes.func.isRequired,
  setSelectedDurationIndex: PropTypes.func.isRequired,
};

ClientBundleEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  platforms: state.clientsConfigurerReducer.platforms,

  isEditMode: state.clientProfileInstallFormReducer.isEditMode,
  bundle: state.clientProfileInstallFormReducer.selectedBundle,
  installers: state.clientsConfigurerReducer.installers,
  architectures: state.clientBundleEditFormReducer.architectures,
  durations: state.clientBundleEditFormReducer.durations,
  editableBundleInfo: state.clientBundleEditFormReducer.editableBundleInfo,
  selectedPlatformIndex: state.clientBundleEditFormReducer.selectedPlatformIndex,
  selectedArchitectureIndex: state.clientBundleEditFormReducer.selectedArchitectureIndex,
  selectedDurationIndex: state.clientBundleEditFormReducer.selectedDurationIndex,
});

const mapDispatchToProps = (dispatch) => ({
  updateBundleInfo: bindActionCreators(updateBundleInfo, dispatch),
  setValidationResultBundleInfo: bindActionCreators(setValidationResultBundleInfo, dispatch),
  setArchitectures: bindActionCreators(setArchitectures, dispatch),
  setSelectedPlatformIndex: bindActionCreators(setSelectedPlatformIndex, dispatch),
  setSelectedArchitectureIndex: bindActionCreators(setSelectedArchitectureIndex, dispatch),
  setSelectedDurationIndex: bindActionCreators(setSelectedDurationIndex, dispatch),
});

export default
connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ClientBundleEditForm));

import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import {RequestsStats} from '../../components/index';
import {getRequestStats} from './action';
import {cancelRequests} from '../Loading/action';

class RequestStatsWidget extends React.Component {
  componentDidMount() {
    const {filters, widgetKey, readyToLoadStats} = this.props;
    this.props.cancelRequests([widgetKey], false);
    if (readyToLoadStats) {
      this.props.getRequestStats(filters, widgetKey);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.readyToLoadStats && (
      !_isEqual(prevProps.filters, this.props.filters)
        || !_isEqual(prevProps.widgetKey, this.props.widgetKey)
    )
    ) {
      this.props.cancelRequests([prevProps.widgetKey], false);
      this.props.getRequestStats(this.props.filters, this.props.widgetKey);
    }
  }

  render() {
    const {
      userRequestsGraph,
      requestsByDecision,
      customGraphClass,
      classes,
      widgetKey,
    } = this.props;
    const allowed = _get(requestsByDecision, 'ALLOWED', 0);
    const forbidden = _get(requestsByDecision, 'FORBIDDEN', 0);

    return (
      <RequestsStats
        widgetKey={widgetKey}
        classes={classes}
        customGraphClass={customGraphClass}
        userRequestsGraph={userRequestsGraph}
        requestsAllowed={allowed}
        requestsBlocked={forbidden}
        requestsTotal={allowed + forbidden}
      />
    );
  }
}

RequestStatsWidget.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.object,
  readyToLoadStats: PropTypes.bool,
  userRequestsGraph: PropTypes.object.isRequired,
  requestsByDecision: PropTypes.object.isRequired,
  customGraphClass: PropTypes.string,
  widgetKey: PropTypes.string.isRequired,
  getRequestStats: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

RequestStatsWidget.defaultProps = {
  classes: {},
  filters: {},
  readyToLoadStats: true,
  customGraphClass: undefined,
};

const mapStateToProps = (state) => ({
  userRequestsGraph: state.requestStatsWidgetReducer.userRequestsGraph,
  requestsByDecision: state.requestStatsWidgetReducer.requestsByDecision,
});

const mapDispatchToProps = (dispatch) => ({
  getRequestStats: bindActionCreators(getRequestStats, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestStatsWidget);

import {
  primaryColor,
  secondaryColor,
  editColor,
} from '../../../../../styles/style';

const navPillsStyle = (theme) => ({
  root: {
    marginBottom: '13px',
    paddingLeft: '0',
    overflow: 'visible !important',
    minHeight: 'max-content',
    backgroundColor: primaryColor,
    borderRadius: '5px',
  },
  root_fullScreen: {
    marginBottom: '0',
  },
  flexContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  indicator: {
    display: 'none !important',
  },
  fixed: {
    overflowX: 'visible',
  },
  flexContainer_horizontal: {
    display: 'block',
  },
  tab: {
    'float': 'left',
    'position': 'relative',
    'display': 'block',
    'borderRadius': '5px',
    'textAlign': 'center',
    'transition': 'all .3s',
    'padding': '0',
    'minWidth': 'max-content',
    'color': '#FFF',
    'height': 'auto',
    'opacity': '1',
    'maxWidth': '100%',
    'minHeight': '34px',
    'width': '110px',
    '&:hover': {
      background: '#4e4e53',
    },
  },
  tab_horizontal: {
    'width': '100%',
    'float': 'none !important',
    '& + button': {
      margin: '10px 0',
    },
  },
  tab_withIcon: {
    borderRadius: '4px',
  },
  tab__icon: {
    width: '30px',
    height: '30px',
    display: 'block',
    margin: '15px 0',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '500',
    position: 'relative',
    display: 'block',
    color: 'inherit',
    borderRadius: '5px',
  },
  tab__contentWrapper: {
    marginTop: '7px',
    padding: '0',
  },
  tab__contentWrapper_fullScreen: {
    marginTop: '0',
    padding: '0',
  },
  tab__content: {
    height: 'calc(100vh - 237px)',
    overflowY: 'auto',
  },
  tab__content_fullScreen: {
    height: 'unset',
  },
  primary: {
    '&,&:hover,&:focus': {
      color: primaryColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  secondary: {
    '&,&:hover': {
      color: '#FFF',
      boxShadow: 'none',
      backgroundColor: secondaryColor,
    },
  },
  edit: {
    '&,&:hover,&:focus': {
      color: editColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
});

export default navPillsStyle;

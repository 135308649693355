import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import classNames from 'classnames';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function CollapseMenuItem(props) {
  const {
    classes,
    isActive,
    item,
  } = props;

  const openCollapse = () => {
    props.openCollapse(item.isOpen);
  };

  return (
    <ListItem
      className={classes.navLinks__item}
      key={uuid()}
    >
      <NavLink
        to="#"
        className={classNames(
          classes.navLinks__item_link,
          {
            [classes.collapseActive]: isActive,
          },
        )}
        onClick={openCollapse}
      >
        <ListItemIcon className={classes.navLinks__item_icon}>
          <item.icon />
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          secondary={(
            <b className={classNames(
              classes.caret,
              // {
              //   [classes.caret_active]: this.state[item.isOpen] ? classes.caret_active : '',
              // },
            )}
            />
          )}
          disableTypography={true}
          className={classNames(
            classes.navLinks__item_text,
            {
              [classes.navLinks__item_textMini]: isActive,
            },
          )}
        />
      </NavLink>
      <Collapse
        // in={this.state[item.state]}
        unmountOnExit={true}
      >
        <List className={classNames(classes.navLinks, classes.collapseNavLinks)}>
          {item.views.map((_prop) => {
            if (item.redirect) {
              return null;
            }
            const navCollapseItemLinkClasses = classNames(
              classes.collapseNavLinks__item_link,
              {
                [classes.route_active]: isActive,
              },
            );
            const collapseItemMini = classes.collapseNavLinks__item_mini;
            return (
              <ListItem key={uuid()} className={classes.collapseNavLinks__item}>
                <NavLink to={_prop.path} className={navCollapseItemLinkClasses}>
                  <span className={collapseItemMini}>
                    {item.mini}
                  </span>
                  <ListItemText
                    primary={_prop.name}
                    disableTypography={true}
                    className={classNames(
                      classes.collapseNavLinks__item_text,
                      {
                        [classes.collapseNavLinks__item_textMini]: isActive,
                      },
                    )}
                  />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </ListItem>
  );
}

CollapseMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  openCollapse: PropTypes.func.isRequired,
};

CollapseMenuItem.defaultProps = {};

export default CollapseMenuItem;

import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import NestedMenuItem from './NestedMenuItem';

import style from './style';

class NestedList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {focusedItemId: null};
  }

  setFocusedItem = (focusedItemId) => this.setState({focusedItemId});

  getMenuList = (items, listName = 'main', isNested = false) => {
    const {classes} = this.props;
    return (
      <MenuList
        key={listName}
        id={listName}
        className={classes.nestedList__menu}
        role="menu"
      >
        {items.map((i) => this.getMenuItem(i, listName, isNested))}
      </MenuList>
    );
  };

  getMenuItem = (item, listName, isNested) => {
    // eslint-disable-next-line no-shadow
    const {classes, close, listProps} = this.props;
    const id = item.id;
    if (item.subItems) {
      return (
        <NestedMenuItem
          key={id}
          id={id}
          isOpen={id === this.state.focusedItemId}
          label={item.label}
          list={this.getMenuList(item.subItems, listName + id, true)}
          focus={this.setFocusedItem}
        />
      );
    }

    const resetFocusedItem = isNested ? () => {} : this.resetFocusedItem;
    const onClick = (e) => {
      item.onClick(item, listProps, e);
      close();
    };
    return (
      <MenuItem
        key={id}
        className={classes.nestedList__menuItem}
        onClick={onClick}
        onMouseOver={resetFocusedItem}
        onFocus={resetFocusedItem}
        disabled={item.disabled}
      >
        {item.label}
      </MenuItem>
    );
  };

  resetFocusedItem = () => this.setState({focusedItemId: null});

  render() {
    return this.getMenuList(this.props.items);
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func,
    subItems: PropTypes.array,
    disabled: PropTypes.bool,
  })).isRequired,
  close: PropTypes.func,
  listProps: PropTypes.object,
};

NestedList.defaultProps = {
  close: () => {},
  listProps: {},
};

export default withStyles(style)(NestedList);

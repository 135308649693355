import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.tldListsReducer, action) => {
  switch (action.type) {
    case types.SET_ALL_TLDS: {
      return {
        ...state,
        allTlds: action.data,
        top100TldNames: action.data.slice(0, 100).map((e) => e.name),
      };
    }
    case types.SET_TLD_FILTERS_INFO: {
      return {
        ...state,
        tldFiltersInfo: action.tldFiltersInfo,
      };
    }
    case types.SET_SELECTED_TLD_FILTER: {
      return {
        ...state,
        selectedTldList: action.selectedTldList,
      };
    }
    case types.RESET_SELECTED_TLD_FILTER: {
      return {
        ...state,
        selectedTldList: initState.tldListsReducer.selectedTldList,
      };
    }
    case types.SET_POLICIES_ASSIGNED_TO_TLD_FILTER: {
      return {
        ...state,
        policiesAssigned: action.policiesAssigned,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _isEqual from 'lodash/isEqual';

import {DOMAIN_TYPES, TABS} from '../../app-common/Insights/constants';
import {getInsightsDomainsData} from '../../app-common/Insights/selectors';
import {getFqdnLogs, resetFqdnLogs} from './action';
import {FQDNInsightsTable} from '../index';

import './style.css';

class InsightsFqdnLogs extends React.PureComponent {
  componentDidMount() {
    this.getFqdns();
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.filterData, this.props.filterData)) {
      this.getFqdns();
    }
  }

  componentWillUnmount() {
    this.props.resetFqdnLogs();
  }

  getFqdns = () => {
    const {
      tab,
      filterData,
    } = this.props;
    this.props.getFqdnLogs(tab, filterData);
  };

  render() {
    const tableStyle = {
      flexGrow: 1,
      height: 'calc(100vh - 235px)',
      minHeight: '300px',
    };
    return (
      <FQDNInsightsTable
        data={this.props.fqdns}
        showLogs={this.props.showLogs}
        tableStyle={tableStyle}
      />
    );
  }
}

InsightsFqdnLogs.propTypes = {
  tab: PropTypes.oneOf(Object.values(TABS)).isRequired,
  fqdns: PropTypes.array.isRequired,
  filterData: PropTypes.object,
  getFqdnLogs: PropTypes.func.isRequired,
  resetFqdnLogs: PropTypes.func.isRequired,
  showLogs: PropTypes.func.isRequired,
};

InsightsFqdnLogs.defaultProps = {
  filterData: {},
};

const mapDispatchToProps = {
  getFqdnLogs,
  resetFqdnLogs,
};

const mapStateToProps = (state, props) => ({
  fqdns: getInsightsDomainsData(props.tab, DOMAIN_TYPES.FQDN, props.filterData)(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsFqdnLogs);

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  GroupStatsCard,
  CardStats,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import style from './style';

function BlocksStats(props) {
  const {
    applications,
    classes,
    content,
    whiteBlackList,
    threats,
    widgetKey,
  } = props;
  return (
    <GroupStatsCard
        groupName={I18n.t('dashboard.blocksStats.title')}
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.container}>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.blocksStats.content')}
                  borderTopColor="primary"
                  value={content}
                />
              </div>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.blocksStats.applications')}
                  borderTopColor="primary"
                  value={applications}
                />
              </div>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.blocksStats.whiteBlackList')}
                  borderTopColor="primary"
                  value={whiteBlackList}
                />
              </div>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.blocksStats.threats')}
                  borderTopColor="primary"
                  value={threats}
                />
              </div>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

BlocksStats.propTypes = {
  applications: PropTypes.number,
  classes: PropTypes.object.isRequired,
  content: PropTypes.number,
  threats: PropTypes.number,
  whiteBlackList: PropTypes.number,
  widgetKey: PropTypes.string,
};
BlocksStats.defaultProps = {
  applications: 0,
  content: 0,
  threats: 0,
  whiteBlackList: 0,
  widgetKey: '',
};

export default withStyles(style)(BlocksStats);

import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.clientSessionsReducer, action) => {
  switch (action.type) {
    case types.SET_CLIENT_SESSIONS: {
      return {
        ...state,
        sessions: action.sessions,
      };
    }

    default: {
      return state;
    }
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {I18n} from 'react-redux-i18n';
import {
  ButtonNew, NotifyModalWindow,
  RenderOrEmpty,
  TableWithPaginationAndFiltration,
} from '../index';

import {RightAvailability, RightAvailabilityOrWarn} from '../../containers/index';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  ACCESS_RIGHTS, USER_ROLES, ORGANIZATION_OPERATOR_PERMISSIONS,
} from '../../constants';

import cardStyle from '../../styles/components/cards';
import style from './style';

class EntitiesListWithViewModesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {openDenyCreateModalWindow: false};
  }

  get headerActions() {
    const {
      additionalHeaderActions,
      entityType,
      handleClickButtonNew,
      disabled,
      newButtonText,
      newButtonTextShort,
      accessRights,
      showOnAccessDenyButtonCreate,
    } = this.props;
    const {openDenyCreateModalWindow} = this.state;

    if (!showOnAccessDenyButtonCreate) {
      return (
        <>
          {additionalHeaderActions}
          <RenderOrEmpty isShow={!!handleClickButtonNew}>
            <RightAvailability accessRights={accessRights.buttonCreate}>
              <ButtonNew
                customClasses="cardActions__button"
                disabled={disabled}
                entityType={entityType.toUpperCase()}
                buttonText={newButtonText}
                buttonTextShort={newButtonTextShort}
                onClick={handleClickButtonNew}
              />
            </RightAvailability>
          </RenderOrEmpty>
        </>
      );
    }
    return (
      <>
        {additionalHeaderActions}
        <RenderOrEmpty isShow={!!handleClickButtonNew}>
          <RightAvailabilityOrWarn
            accessRights={accessRights.buttonCreate}
            onClickDeny={this.handleForbiddenCreateButton}
            limitDenyFor={[
              {
                role: USER_ROLES.ORGANIZATION_OPERATOR,
                permission: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
              },
            ]}
          >
            <ButtonNew
              customClasses="cardActions__button"
              disabled={disabled}
              entityType={entityType.toUpperCase()}
              buttonText={newButtonText}
              buttonTextShort={newButtonTextShort}
              onClick={handleClickButtonNew}
            />
          </RightAvailabilityOrWarn>
        </RenderOrEmpty>
        <RenderOrEmpty isShow={openDenyCreateModalWindow}>
          <NotifyModalWindow
            text={I18n.t(
              'modalWindow.forbidActionCreate',
              {
                entityType: entityType,
              },
            )}
            close={this.handleClickDenyOK}
          />
        </RenderOrEmpty>
      </>
    );
  }

  handleForbiddenCreateButton = () => {
    this.setState({
      openDenyCreateModalWindow: true,
    });
  };

  handleClickDenyOK = () => {
    this.setState({
      openDenyCreateModalWindow: false,
    });
  };

  getHeaders = () => {
    const {
      mode,
      headersFullWidthMode,
      headersCompressedMode,
    } = this.props;
    const headersByMode = {
      [FULL_WIDTH]: headersFullWidthMode,
      [COMPRESSED]: headersCompressedMode,
    };
    return headersByMode[mode];
  };

  getTableStyles = () => {
    const {tableStyles} = this.props;
    if (Object.keys(tableStyles).length > 0) {
      return tableStyles;
    }
    return null;
  };

  render() {
    const {
      classes,
      customCardClass,
      customClasses,
      handleClickRow,
      disabled,
      tableData,
      title,
      selectedEntityIndex,
      defaultSorted,
      fullscreen,
      tableStyles,
      componentHeaderHidden,
      resizable,
    } = this.props;

    const headerClasses = {
      root: classes.card__header,
      title: `${classes.card__title} ${classes.card__title_view}`,
      action: classes.card__actions_edit,
    };
    const tableClasses = {
      tbody: classNames({
        [customClasses.tableBody]: !_isEmpty(customClasses) && _isEmpty(tableStyles),
        [classes.entityWithModesCard__tableBody]: _isEmpty(customClasses) && _isEmpty(tableStyles),
      }),
    };

    return (
      <Card
        className={classNames(
          classes.card,
          {
            [classes[customCardClass]]: !_isUndefined(customCardClass),
            [classes.card_disabled]: disabled,
            [classes.card_fullScreen]: fullscreen,
          },
        )}
      >
        <RenderOrEmpty isShow={!componentHeaderHidden} >
          <CardHeader
            classes={headerClasses}
            title={title}
            action={this.headerActions}
          />
        </RenderOrEmpty>
        <CardContent
          className={classes.card__content}
        >
          <TableWithPaginationAndFiltration
            columns={this.getHeaders()}
            data={tableData}
            defaultSorted={defaultSorted}
            selectedRowIndices={[selectedEntityIndex]}
            handleClickRow={handleClickRow}
            defaultPageSize={tableData.length}
            pageSize={tableData.length}
            showPagination={false}
            style={this.getTableStyles()}
            customClasses={tableClasses}
            resizable={resizable}
          />
        </CardContent>
      </Card>
    );
  }
}

EntitiesListWithViewModesCard.propTypes = {
  additionalHeaderActions: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.object.isRequired,
  customCardClass: PropTypes.string,
  customClasses: PropTypes.object,
  entityType: PropTypes.string,
  newButtonText: PropTypes.string,
  newButtonTextShort: PropTypes.string,
  headersFullWidthMode: PropTypes.array,
  headersCompressedMode: PropTypes.array,
  defaultSorted: PropTypes.array,
  handleClickButtonNew: PropTypes.func,
  handleClickRow: PropTypes.func,
  disabled: PropTypes.bool,
  mode: PropTypes.oneOf([FULL_WIDTH, COMPRESSED]).isRequired,
  tableData: PropTypes.array,
  tableStyles: PropTypes.object,
  title: PropTypes.string,
  selectedEntityIndex: PropTypes.number,
  fullscreen: PropTypes.bool,
  componentHeaderHidden: PropTypes.bool,
  accessRights: PropTypes.object,
  showOnAccessDenyButtonCreate: PropTypes.bool,
  resizable: PropTypes.bool,
};

EntitiesListWithViewModesCard.defaultProps = {
  additionalHeaderActions: [],
  customCardClass: undefined,
  customClasses: {},
  entityType: '',
  newButtonText: undefined,
  newButtonTextShort: undefined,
  headersFullWidthMode: [],
  headersCompressedMode: [],
  resizable: false,
  defaultSorted: [],
  disabled: false,
  tableData: [],
  tableStyles: {},
  title: '',
  selectedEntityIndex: -1,
  handleClickButtonNew: null,
  handleClickRow: () => {},
  fullscreen: true,
  componentHeaderHidden: false,
  accessRights: {buttonCreate: [ACCESS_RIGHTS.CREATE]},
  showOnAccessDenyButtonCreate: false,
};

export default withStyles({...style, ...cardStyle})(EntitiesListWithViewModesCard);

import {defaultFont, primaryColor, disabledColor} from '../../styles/style';

const style = {
  form: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  form__item: {},
  form__error: {
    ...defaultFont,
    fontSize: '14px',
  },
  form__input: {
    color: primaryColor,
  },
  form__inputRoot_description_view: {
    outline: `1px solid ${disabledColor}`,
    padding: '3px',
  },
  form__inputRoot_description_edit: {
    'border': `1px solid ${disabledColor}`,
    'padding': '3px',
    '&:hover': {
      outline: `1px solid ${primaryColor}`,
    },
  },
  form__inputRoot_description_focused: {
    'border': `2px solid ${primaryColor}`,
    '&:hover': {
      outline: `2px solid ${primaryColor}`,
    },
  },
  form__formControl_description: {
    transform: 'translate(5px, 24px)',
  },
  form__shrink_description: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
};

export default style;

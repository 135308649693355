import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.relayBinaryListReducer, action) => {
  switch (action.type) {
    case types.SET_RELAY_BINARIES: {
      return {
        ...state,
        builds: action.builds,
        networkAgentAllowed: action.networkAgentAllowed,
      };
    }
    default: {
      return state;
    }
  }
};

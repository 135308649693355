import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {TABS as clientTabs} from '../../app-common/Clients/constants';
import {EntityCreatingCard} from '../index';
import {
  ClientProfileEditForm,
  ClientProfileSettingsEditForm,
} from '../../containers';

class ClientProfileCreating extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = [
      {
        text: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.settingsTab.tabName`),
        name: 'settings',
      },
    ];
  }

  getTabContent = (tabName) => {
    const tabContentMap = {
      settings: <ClientProfileSettingsEditForm />,
    };

    return tabContentMap[tabName];
  };

  render() {
    const {
      classes,
      getPolicies,
      handleClickButtonCancel,
      handleClickButtonSave,
      isValidProfileInfo,
    } = this.props;

    return (
      <EntityCreatingCard
        title={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.createProfile`)}
        handleClickButtonCancel={handleClickButtonCancel}
        handleClickButtonSave={handleClickButtonSave}
        isValidEntity={isValidProfileInfo}
        customCardContentClass="card__content_clientProfile"
        content={(
          <ClientProfileEditForm
            classes={classes}
            tabs={this.tabs}
            getTabContent={this.getTabContent}
            getPolicies={getPolicies}
          />
        )}
      />
    );
  }
}

ClientProfileCreating.propTypes = {
  classes: PropTypes.object,
  getPolicies: PropTypes.func.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidProfileInfo: PropTypes.bool.isRequired,
  copyProfile: PropTypes.func.isRequired,
};

ClientProfileCreating.defaultProps = {
  classes: {},
};

export default ClientProfileCreating;

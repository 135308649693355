import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Label,
  Sector,
} from 'recharts';
import {v4 as uuid} from 'uuid';

import {
  CardWithHeader,
  CustomTable,
  RenderOrEmpty,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import {
  yellowOtherColor,
  darkBlueColor,
  lightBlueColor,
  brightRedColor,
  darkerGreenColor,
} from '../../styles/style';

/**
 * Renders active shape with onHover effect
 */
function renderActiveShape(props) {
  const {cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill} = props;

  return (
    <g>
      <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 5}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          fillOpacity={0.2}
      />
      <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
      />
    </g>
  );
}

function TopCategoriesCard(props) {
  const {
    classes,
    chartData,
    headers,
    tableData,
    textWhenDataNotAvailable,
    title,
    label,
    titleColor,
    widgetKey,
  } = props;

  const [activeCell, setActiveCell] = useState(null);

  const getPieCells = () => {
    const COLORS = [
      brightRedColor,
      darkerGreenColor,
      yellowOtherColor,
      darkBlueColor,
      lightBlueColor,
    ];
    return chartData.map((entry, index) => (
      <Cell
        fill={COLORS[index % COLORS.length]}
        value={entry.value}
        name={entry.name}
        key={uuid()}
      />
    ));
  };

  const thereIsNotData = tableData.length === 0;

  return (
    <CardWithHeader
        cardTitle={title}
        cardTitleColor={titleColor}
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.topCategories}>
              <RenderOrEmpty isShow={thereIsNotData}>
                <h3 className={classes.topBlock__thereIsNotData}>
                  {textWhenDataNotAvailable}
                </h3>
              </RenderOrEmpty>
              <RenderOrEmpty isShow={!thereIsNotData}>
                <div className={classes.topCategories__graph}>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        isAnimationActive={false}
                        data={chartData}
                        fill="#8884d8"
                        labelLine={false}
                        dataKey="value"
                        innerRadius={70}
                        strokeWidth={0}
                        activeIndex={activeCell}
                        activeShape={renderActiveShape}
                        onMouseEnter={(_, index) => setActiveCell(index)}
                        onMouseLeave={() => setActiveCell(null)}
                      >
                        {getPieCells()}
                        {/* This rule needed for labels with inputs.
                            Chart here doesn't have inputs */}
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <Label value={label} position="center" />
                      </Pie>
                      <Tooltip label="percent" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className={classes.topCategories__table}>
                  <CustomTable
                    tableHead={headers}
                    tableData={tableData}
                    hideDivider={true}
                  />
                </div>
              </RenderOrEmpty>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

TopCategoriesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  chartData: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  textWhenDataNotAvailable: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  titleColor: PropTypes.string.isRequired,
  widgetKey: PropTypes.string.isRequired,
};

TopCategoriesCard.defaultProps = {
  label: '',
};

export default TopCategoriesCard;

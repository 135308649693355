const style = {
  requestsStats: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  requestsStats__graph: {
    flexBasis: '80%',
  },
  requestsStats__graph_campusDashboard: {
    flexBasis: '80%',
  },
  cards__container: {
    flexBasis: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  cards__item: {
    flexBasis: '33%',
  },
  requestsStats__thereIsNotGraphData: {
    margin: '0 auto',
    fontSize: '20px',
    padding: '95px 0 135px 0',
  },
};

export default style;

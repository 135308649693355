import React, {CSSProperties, ReactNode} from 'react';
import {Column} from 'react-table';
import {WithStyles} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {TableWithPaginationAndFiltration} from '../index';

import style from './style';

type UserInfoPanelProps = WithStyles<typeof style> & {
  columns: Column[];
  customClasses: {
    tableBody: string;
  };
  tableData: {
    header: string;
    value?: ReactNode;
    style?: CSSProperties;
  }[];
  title: string;
}

function UserInfoPanel({
  classes,
  customClasses,
  columns,
  tableData,
  title,
}: UserInfoPanelProps) {
  const cardHeaderStyle = {
    root: classes.userInfoTableCard__header,
    title: classes.userInfoTableCard__title,
  };

  return (
    <Card className={classes.userInfoTableCard}>
      <CardHeader
        classes={cardHeaderStyle}
        title={title}
      />
      <CardContent className={classes.userInfoTableCard__content}>
        <TableWithPaginationAndFiltration
          columns={columns}
          data={tableData}
          showPagination={false}
          defaultPageSize={tableData.length}
          pageSize={tableData.length}
          customClasses={{
            tbody: customClasses.tableBody,
          }}
        />
      </CardContent>
    </Card>
  );
}

export default withStyles(style)(UserInfoPanel);

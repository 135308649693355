import {defaultFont, primaryColor, disabledColor} from '../../styles/style';

const style = {
  form: {
    marginTop: '5px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  form__block: {
    flexBasis: '45%',
    margin: 10,
    padding: 20,
  },
  form__switchBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20,
    gap: '10px',
  },
  form__error: {
    ...defaultFont,
    fontSize: '14px',
  },
  form__input: {
    color: primaryColor,
  },
  form__inputRoot_response_view: {
    outline: `1px solid ${disabledColor}`,
    padding: '3px',
  },
  form__inputRoot_response_edit: {
    'border': `1px solid ${disabledColor}`,
    'padding': '3px',
    '&:hover': {
      outline: `1px solid ${primaryColor}`,
    },
  },
  form__inputRoot_response_focused: {
    'border': `2px solid ${primaryColor}`,
    '&:hover': {
      outline: `2px solid ${primaryColor}`,
    },
  },
  form__formControl_response: {
    transform: 'translate(5px, 24px)',
  },
  form__shrink_response: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },

  form__logoUploadLabel: {
    ...defaultFont,
  },

  form__logoPreviewValue: {
    ...defaultFont,
  },

  form__logoPreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '250px',
    maxHeight: '200px',
    overflow: 'hidden',
  },

  form__logoPreview: {
    objectFit: 'contain',
  },

  form__imageUploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

};

export default style;

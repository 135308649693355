import {TypedUseSelectorHook, useDispatch, useSelector, useStore} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/root-reducer';

export const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

type AppStore = typeof store;
type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;

import {types} from './constants';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.organizationDashboardReducer, action) => {
  switch (action.type) {
    case types.SET_DASHBOARD_LOCATIONS: {
      return {
        ...state,
        locations: action.data,
      };
    }
    case types.SET_DASHBOARD_PROFILE_NAMES: {
      return {
        ...state,
        clientProfiles: action.data,
      };
    }

    default:
      return state;
  }
};

const style = {
  universalDashboard: {
  },
  universalDashboard__controlPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  universalDashboard__controlPanel_dashboardIsHidden: {
    justifyContent: 'flex-start',
    height: '48px',
  },
  universalDashboard__widgets_hidden: {
    display: 'none',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';

import {
  CardWithHeader,
  CustomTable,
  RenderOrEmpty,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

function TopDomainsCard(props) {
  const {
    classes,
    headers,
    tableData,
    textWhenDataNotAvailable,
    title,
    titleColor,
    widgetKey,
  } = props;

  const thereIsNotData = tableData.length === 0;
  return (
    <CardWithHeader
        cardTitle={title}
        cardTitleColor={titleColor}
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <RenderOrEmpty isShow={thereIsNotData}>
              <h3 className={classes.topBlock__thereIsNotData}>
                {textWhenDataNotAvailable}
              </h3>
            </RenderOrEmpty>
            <RenderOrEmpty isShow={!thereIsNotData}>
              <CustomTable
                tableHead={headers}
                tableData={tableData}
              />
            </RenderOrEmpty>
          </LoadingLayout>
        )}
    />
  );
}

TopDomainsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  textWhenDataNotAvailable: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  widgetKey: PropTypes.string.isRequired,
};

TopDomainsCard.defaultProps = {};

export default TopDomainsCard;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';
import {Add} from '../../icons/index';
import style from '../../styles/components/button';

function ButtonNew(props) {
  const {
    classes,
    customClasses,
    disabled,
    entityType,
    onClick,
  } = props;
  const btnText = _get(
    props,
    'buttonText',
    I18n.t('buttons.newEntity', {entityType}).toUpperCase(),
  );
  const btnTextShort = _get(
    props,
    'buttonTextShort',
    I18n.t('buttons.newEntity', {entityType}).toUpperCase(),
  );

  return (
    <CustomButton
      color="secondary"
      customClasses={customClasses}
      disabled={disabled}
      onClick={onClick}
      size="xs"
    >
      <Add />
      <span className={classes.cardActions__buttonNew_fullText}>
        {btnText}
      </span>
      <span className={classes.cardActions__buttonNew_shortText}>
        {btnTextShort}
      </span>
    </CustomButton>
  );
}

ButtonNew.propTypes = {
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.string,
  disabled: PropTypes.bool,
  entityType: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  buttonText: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  buttonTextShort: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ButtonNew.defaultProps = {
  customClasses: '',
  disabled: false,
  entityType: '',
  buttonText: undefined,
  buttonTextShort: undefined,
};

export default withStyles(style)(ButtonNew);

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {USER_ROLES} from '../../constants';

function RoleAvailability(props) {
  const {
    alternativeChild,
    role,
    availability,
    children,
  } = props;

  const availabilityMap = {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.ADMIN]: true,
    [USER_ROLES.VIEWER]: true,
    [USER_ROLES.SERVICE_DESK]: true,
    [USER_ROLES.PROJECT_VIEWER]: true,
    [USER_ROLES.ORGANIZATION_OPERATOR]: true,
    [USER_ROLES.OEM_SUPER_ADMIN]: true,
    [USER_ROLES.OEM_ADMIN]: true,
    ...availability,
  };

  let child;
  if (availabilityMap[role]) {
    child = children;
  } else {
    child = alternativeChild;
  }

  return child;
}

RoleAvailability.propTypes = {
  alternativeChild: PropTypes.node,
  availability: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
};

RoleAvailability.defaultProps = {
  alternativeChild: null,
  role: USER_ROLES.VIEWER,
};

const mapStateToProps = (state) => ({
  role: state.userAccountsReducer.loggedAccount.role,
});

export default connect(mapStateToProps, null)(RoleAvailability);

import {
  primaryColor,
  secondaryColor,
  greenColor,
  defaultFont,
} from '../../styles/style';

const style = {
  formControl: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
  },
  formControl_oneLine: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
  },
  formControl_oneLineReversedLeftAligned: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    width: 'fit-content',
  },
  checkbox__label: {
    ...defaultFont,
    color: '#AAAAAA',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '1.42857',
    transition: '0.3s ease all',
    paddingLeft: '14px',
  },
  checkbox__label_oneLine: {
    color: primaryColor,
    fontSize: '14px',
    paddingLeft: '0',
  },
  clients__checkbox: {
    height: '24px',
  },
  checkbox: {},
  checkbox_td: {
    height: 0,
  },
  checkbox_checked: {
    color: greenColor,
  },
  checkbox_unchecked: {
    color: secondaryColor,
  },
  checkbox_disabled: {
    opacity: '0.5',
    cursor: 'none',
  },
  switchBar: {
    marginRight: '15px',
    height: '15px',
  },
};

export default style;

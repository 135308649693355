import _isNull from 'lodash/isNull';

import {
  DashboardIcon,
  Book,
  // Clients,
  Clipboard,
  Funnel,
  Building,
  // Assessment,
  Organizations,
} from '../../icons';

import {ROUTES} from '../../constants';

const permissions = (account, organization) => ({
  [ROUTES.DASHBOARD]: true,
  [ROUTES.INSIGHTS]: true,
  [ROUTES.CAMPUS]: true,
  [ROUTES.CLIENTS]: account.deviceAgentAllowed,

  [ROUTES.ORGANIZATIONS]: account.organizationsAllowed && _isNull(organization),
  // Organizations are allowed on the account and a user is in 'All organizations' scope

  [ROUTES.POLICY]: true,
  [ROUTES.CUSTOM_LISTS]: true,
  [ROUTES.LOG]: true,
});

const operatorRoutes = [
  {
    path: ROUTES.DASHBOARD,
    name: 'dashboard',
    icon: DashboardIcon,
  },
  // {
  //   path: ROUTES.INSIGHTS,
  //   name: 'insights',
  //   icon: Assessment,
  // },
  {
    path: ROUTES.CAMPUS,
    name: 'site',
    icon: Building,
  },
  // {
  //   path: ROUTES.CLIENTS,
  //   name: 'clients',
  //   icon: Clients,
  // },
  {
    path: ROUTES.ORGANIZATIONS,
    name: 'organizations',
    icon: Organizations,
  },
  {
    path: ROUTES.POLICY,
    name: 'policy',
    icon: Funnel,
  },
  {
    path: ROUTES.CUSTOM_LISTS,
    name: 'customLists',
    icon: Clipboard,
  },
  {
    path: ROUTES.LOG,
    name: 'log',
    icon: Book,
  },
];

export const getOperatorRoutes = (account, organization) =>
  operatorRoutes.filter((route) => permissions(account, organization)[route.path]);

import {
  defaultFont,
  primaryColor,
  secondaryColor,
} from '../../styles/style';

const style = (theme) => ({
  checkbox_checked: {
    color: primaryColor,
  },
  checkbox__icon_checked: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  checkbox__icon_unchecked: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  picker__button: {
    position: 'absolute',
    top: '3px',
    left: '120px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginLeft: '30px',
      width: 'auto',
    },
  },
  popper_responsive: {
    zIndex: '2',
    transform: 'none!important',
    position: 'absolute!important',
    top: '45px!important',
    left: '20px!important',
  },
  popper_close: {
    pointerEvents: 'none',
  },
  columnPicker: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    top: '0',
    zIndex: '1000',
    minWidth: '190px',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    position: 'absolute',
  },
  columnPicker__items: {
    height: '315px',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
  },
  columnPicker__item: {
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    clear: 'both',
    height: 'fit-content',
    color: primaryColor,
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    marginRight: '0px',
    zIndex: '2',
  },
  columnPickerItem__label: {
    ...defaultFont,
    fontSize: '15px',
    fontWeight: '300',
  },
  columnPicker__header: {
    backgroundColor: secondaryColor,
    color: '#FFF',
    display: 'inherit',
    textAlign: 'center',
    padding: '10px 20px',
  },
  columnPickerItem__icon: {
    width: '18px',
    height: '11px',
    opacity: '0.5',
    cursor: 'row-resize',
    background: '-webkit-linear-gradient('
      + `top,${primaryColor},${primaryColor} 20%,
      #fff 0,#fff 40%,${primaryColor} 0,
      ${primaryColor} 60%,#fff 0,#fff 80%,
      ${primaryColor} 0,${primaryColor}`
      + ')',
  },
  action_menuList: {
    padding: 0,
  },
});

export default style;

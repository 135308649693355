import React from 'react';
import PropTypes from 'prop-types';

function Mac(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="36"
      viewBox="0 0 50 36"
      version="1.1"
      className={props.className}
    >
      <title>{props.title}</title>
      <path fillOpacity="0.216" d="M 26.758 2.976 C 23.433 5.592, 22.491 9, 25.094 9 C 27.590 9, 31 5.536, 31 3 C 31 0.481, 29.938 0.475, 26.758 2.976 M 14.156 10.603 C 11.552 12.427, 9.734 18.034, 10.574 21.649 C 11.584 25.995, 15.780 32.795, 17.858 33.455 C 18.877 33.778, 20.786 33.645, 22.100 33.159 C 23.531 32.629, 25.602 32.662, 27.262 33.241 C 30.582 34.399, 33.755 32.695, 35.963 28.570 C 37.109 26.427, 37.158 25.522, 36.197 24.185 C 32.793 19.450, 32.579 18.061, 34.791 15.068 C 36.734 12.440, 36.781 12.063, 35.317 10.848 C 33.008 8.932, 16.810 8.744, 14.156 10.603" stroke="none" fill="black" fillRule="evenodd" />
      <path fillOpacity="0.395" d="M 26 4 C 23.712 6.288, 23.357 9, 25.345 9 C 26.879 9, 29.398 6.339, 30.317 3.750 C 31.178 1.321, 28.526 1.474, 26 4 M 13.923 11.923 C 11.639 14.207, 11 15.746, 11 18.963 C 11 23.119, 13.904 30.174, 16.236 31.682 C 16.931 32.132, 20.774 32.551, 24.777 32.613 C 31.663 32.719, 32.186 32.568, 34.527 29.786 C 37.519 26.230, 37.565 25.565, 35 23 C 32.638 20.638, 32.421 17.148, 34.468 14.441 C 36.508 11.745, 36.398 11.408, 33.031 10.013 C 30.837 9.104, 29.260 9.062, 26.993 9.852 C 24.967 10.559, 23.067 10.596, 21.397 9.961 C 17.705 8.557, 17.141 8.705, 13.923 11.923" stroke="none" fill="black" fillRule="evenodd" />
      <path fillOpacity="0.636" d="M 26.174 4.314 C 22.329 8.408, 24.054 10.946, 28 7 C 30.011 4.989, 30.713 2, 29.174 2 C 28.720 2, 27.370 3.042, 26.174 4.314 M 12.872 12.880 C 10.104 16.386, 10.727 23.478, 14.275 28.839 L 16.848 32.727 24.654 32.584 C 32.349 32.444, 32.492 32.394, 34.745 29.051 L 37.030 25.660 35.015 23.516 C 32.638 20.985, 32.363 15.774, 34.512 13.990 C 37.631 11.401, 34.395 10.194, 24.429 10.227 C 15.403 10.258, 14.840 10.387, 12.872 12.880" stroke="none" fill="black" fillRule="evenodd" />
      <path fillOpacity="0.955" d="M 26.455 4.455 C 24.443 6.466, 23.962 7.487, 24.883 7.794 C 26.604 8.368, 30.368 4.604, 29.794 2.883 C 29.487 1.962, 28.466 2.443, 26.455 4.455 M 14.769 11.110 C 10.203 13.665, 10.574 23.963, 15.440 29.746 C 17.680 32.409, 18.269 32.575, 25.040 32.449 C 31.398 32.331, 32.473 32.029, 34.105 29.907 C 36.043 27.386, 36.626 24, 35.121 24 C 34.638 24, 33.703 22.696, 33.043 21.103 C 32.050 18.706, 32.128 17.653, 33.495 15.010 C 34.404 13.252, 34.855 11.522, 34.499 11.165 C 33.480 10.147, 16.574 10.099, 14.769 11.110" stroke="none" fill="black" fillRule="evenodd" />
    </svg>
  );
}

Mac.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

Mac.defaultProps = {
  className: '',
  title: null,
};

export default Mac;

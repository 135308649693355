import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  getAverageLatency,
  getCategoryGroupsStats,
  getCampusesStats,
  getTotalRequestsByDecision,
  getUserRequestsGraph,
} from '../../scout-dns/app/Dashboard/action';

import {resetPolicies} from '../Policy/action';

import {getCampusToCreate} from './service';

import {
  ALLOWED,
  FORBIDDEN,
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {types, widgetKeys} from './constants';

export function getCampuses() {
  const url = makeUrl(METHODS.GET_CAMPUSES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_CAMPUSES,
          data,
        });
      }
    });
}

export function getCampusById(campusId) {
  const url = makeUrl(METHODS.GET_CAMPUS_BY_ID, {id: campusId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_SELECTED_CAMPUS,
          data,
        });
      }
    });
}

export function createCampus(newCampus) {
  const url = makeUrl(METHODS.CREATE_CAMPUS);
  const data = getCampusToCreate(newCampus);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndCampusState(false, STATES_ENTITY.NONE));
        dispatch(updateCampusesPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to create site:', error);
    });
}

export function updateCampus(updatedCampus) {
  const url = makeUrl(METHODS.UPDATE_CAMPUS);
  const data = updatedCampus;
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndCampusState(false, STATES_ENTITY.NONE));
        dispatch(getCampuses()).then(() => dispatch({
          type: types.SET_UPDATED_CAMPUS,
          data: response.data,
        }));
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to edit site:', error);
    });
}

export function deleteCampus(campusId) {
  const data = {
    id: campusId,
  };
  const url = makeUrl(METHODS.DELETE_CAMPUS);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updateCampusesPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to delete site:', error);
    });
}

export function getCampusDashboardData(refreshData) {
  const period = refreshData.period;
  const campusId = refreshData.campusId;

  const queryParamsPeriodCampus = {
    campusId: campusId,
    period: period,
  };
  const queryParamsCampusStats = {
    campusId: campusId,
    period: null,
  };
  const queryParamsDecisionIsAllowed = {
    period: period,
    campusId: campusId,
    decision: ALLOWED,
  };
  const queryParamsDecisionIsForbidden = {
    period: period,
    campusId: campusId,
    decision: FORBIDDEN,
  };
  return (dispatch) => {
    dispatch(getAverageLatency(queryParamsPeriodCampus, widgetKeys.PERFORMANCE_STATS));
    dispatch(getCategoryGroupsStats(queryParamsPeriodCampus, widgetKeys.BLOCK_STATS));
    dispatch(getCampusesStats(queryParamsCampusStats, widgetKeys.CAMPUSES_STATS));
    dispatch(getTotalRequestsByDecision(
      queryParamsDecisionIsForbidden, FORBIDDEN, widgetKeys.REQUEST_STATS));
    dispatch(getTotalRequestsByDecision(
      queryParamsDecisionIsAllowed, ALLOWED, widgetKeys.REQUEST_STATS));
    dispatch(getUserRequestsGraph(queryParamsPeriodCampus, widgetKeys.REQUEST_STATS));
  };
}

export function resetCampuses() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CAMPUSES,
    });
  };
}

export function changeEditModeAndCampusState(isEditMode, campusState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_CAMPUS_STATE,
      isEditMode,
      campusState,
    });
  };
}

export function getDataToShowCampusDetails(campusId, campusIndex) {
  return (dispatch) => {
    dispatch(getCampusById(campusId));
    dispatch(setSelectedCampusIndex(campusIndex));
  };
}

export function getCampusesTableData(campusesStats) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CAMPUSES_TABLE_DATA,
      campusesStats,
    });
  };
}

export function setSelectedCampusIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CAMPUS_INDEX,
      selectedCampusIndex: index,
    });
  };
}

export function resetSelectedCampusIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_CAMPUS_INDEX,
    });
  };
}

export function resetPageData() {
  return (dispatch) => {
    dispatch(resetSelectedCampusIndex());
    dispatch(resetPolicies());
  };
}

export function updateCampusesPageData() {
  return (dispatch) => dispatch(getCampuses())
    .then(() => dispatch(
      getCampusesStats({campusId: null, period: null}, widgetKeys.CAMPUSES_STATS)));
}

export function setSelectedPeriodIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_PERIOD_INDEX,
      index,
    });
  };
}

export function getLocationPolicies(locationId) {
  const url = makeUrl(METHODS.GET_LOCATION_POLICIES, {locationId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_CAMPUS_POLICIES,
          data,
        });
      }
    });
}

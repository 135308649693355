import React, {Component} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import {
  CardWithHeader,
  Dropdown,
  TableWithPaginationAndFiltration,
  RenderOrEmpty,
} from '../../components';
import {getInvoices, goToInvoice} from '../../app-common/Subscription/action';
import {CustomButton} from '../../material-dashboard-pro-react/components';
import {OpenInNew} from '../../icons/index';

const INVOICES_FIELDS = [
  'invoice_date',
  'invoice_id',
  'status',
  'due_date',
  'total',
  'balance',
];
const INVOICE_STATUSES = ['All', 'Sent', 'Paid', 'OverDue', 'Unpaid', 'Void'];
const DEFAULT_PAGE_SIZE = 10;

class Invoices extends Component {
  static getFilterValues() {
    return INVOICE_STATUSES.map((s) => I18n.t(`billingDetails.invoices.filter.values.${s}`));
  }

  state = {selectedFilterIndex: 0, invoicesLoaded: false};

  componentDidMount() {
    this.props.getInvoices().then(() => this.setState({invoicesLoaded: true}));
  }

  get filter() {
    return (
      <Dropdown
      label={I18n.t('billingDetails.invoices.filter.label')}
      options={Invoices.getFilterValues()}
      selectedItemIndex={this.state.selectedFilterIndex}
      customSelectClass="dropdown__select_dashboard"
      onChangeValue={this.filterInvoices}
      />
    );
  }

  get tableProps() {
    let defaultPageSize = DEFAULT_PAGE_SIZE;
    let showPagination = true;

    const invoicesLength = this.props.invoices.length;
    if (invoicesLength < DEFAULT_PAGE_SIZE) {
      defaultPageSize = invoicesLength || 3;
      showPagination = false;
    }

    return {defaultPageSize, showPagination};
  }

  getHeaders = (isTableEmpty) => {
    const headers = INVOICES_FIELDS.map((f) => ({
      Header: I18n.t(`billingDetails.invoices.table.${f}`),
      accessor: f,
      sortable: !isTableEmpty,
      filterable: !isTableEmpty,
    }));

    const gotoInvoiceCellRenderer = (row) => (
      <CustomButton
        onClick={() => this.props.goToInvoice(row.value)}
        color="primaryNoBackground"
        disableRipple={true}
      >
        <OpenInNew />
      </CustomButton>
    );

    headers.push({
      Header: '',
      accessor: 'invoice_id',
      sortable: false,
      filterable: false,
      width: 70,
      Cell: gotoInvoiceCellRenderer,
    });

    return headers;
  };

  filterInvoices = (e) => {
    const selectedFilterIndex = e.target.value;
    this.setState({selectedFilterIndex});
    this.props.getInvoices(INVOICE_STATUSES[selectedFilterIndex]);
  };

  render() {
    const isTableEmpty = _isEmpty(this.props.invoices);
    return (
      <CardWithHeader
        cardTitle={I18n.t('billingDetails.invoices.title')}
        cardTitleColor="secondary"
        content={(
          <RenderOrEmpty isShow={!!this.state.invoicesLoaded}>
            {this.filter}
            <TableWithPaginationAndFiltration
              columns={this.getHeaders(isTableEmpty)}
              data={this.props.invoices}
              {...this.tableProps}
            />
          </RenderOrEmpty>
        )}
      />
    );
  }
}

Invoices.propTypes = {
  invoices: PropTypes.array.isRequired,
  getInvoices: PropTypes.func.isRequired,
  goToInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoices: state.subscriptionReducer.invoices,
});

const mapDispatchToProps = {getInvoices, goToInvoice};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invoices);

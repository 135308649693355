import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

import {
  ContainerWithListAndForm,
  BlockPagesTable,
  BlockPageCreating,
  BlockPageDetails,
} from '../../components';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  MODAL_WINDOW_NOTIFY_TYPE,
  STATES_ENTITY,
  ROUTES,
} from '../../constants';

import {
  changeEditModeAndBlockPageState,
  createBlockPage,
  getDataToShowBlockPageDetails,
  getBlockPagesTableData,
  resetPageData,
  resetSelectedBlockPageIndex,
  uploadBlockPageLogo,
  updateBlockPage,
  updateBlockPageData,
  deleteBlockPage,
} from './action';

import {hasAuthorities} from '../../utils/account';

import style from './style';

class BlockPage extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateBlockPagesTableData(prevProps, props) {
    return !_isEqual(prevProps.blockPages, props.blockPages);
  }

  constructor(props) {
    super(props);
    this.state = BlockPage.initialLocalState;
  }

  componentDidMount() {
    if (hasAuthorities('blockPage')) {
      this.props.updateBlockPageData();
    } else {
      this.props.history.push(ROUTES.DEFAULT);
    }
  }

  componentDidUpdate(prevProps) {
    if (BlockPage.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.resetSelectedBlockPageIndex();
      this.props.updateBlockPageData();
    }
    if (BlockPage.shouldUpdateBlockPagesTableData(prevProps, this.props)) {
      this.props.getBlockPagesTableData(this.props.blockPagesStats);
    }
  }

  componentWillUnmount() {
    this.props.resetPageData();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isValidBlockPageInfo,
      isEditMode,
      selectedBlockPage,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <BlockPageCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateBlockPage}
            handleClickButtonSave={this.handleClickButtonSaveNewBlockPage}
            isValidBlockPageInfo={isValidBlockPageInfo}
            title={I18n.t('blockPage.createBlockPage')}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <BlockPageDetails
            handleClickButtonCancel={this.handleClickButtonCancelCreateBlockPage}
            handleClickButtonEdit={this.handleClickButtonEditBlockPage}
            handleClickButtonSave={this.handleClickButtonSaveUpdatedBlockPage}
            handleClickButtonDelete={this.handleClickButtonDeleteBlockPage}
            handleClickButtonBack={this.goToBlockPagesList}
            isValidBlockPageInfo={isValidBlockPageInfo}
            isEditMode={isEditMode}
            title={selectedBlockPage.name}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  resetLocalState = () => this.setState(BlockPage.initialLocalState);

  handleClickButtonSaveNewBlockPage = () => {
    const {newOrUpdatedBlockPage} = this.props;

    if (newOrUpdatedBlockPage.contentLogo && newOrUpdatedBlockPage.contentHasLogoInserted) {
      this.props.uploadBlockPageLogo(newOrUpdatedBlockPage).then((logoUrl) => {
        if (!_isNil(logoUrl)) {
          this.props.createBlockPage({
            ...newOrUpdatedBlockPage,
            contentLogoURL: logoUrl,
          }).then((isCreated) => {
            if (isCreated) {
              this.goToBlockPagesList();
            }
          });
        }
      });
    } else {
      this.props.createBlockPage(newOrUpdatedBlockPage)
        .then((isCreated) => {
          if (isCreated) {
            this.goToBlockPagesList();
          }
        });
    }
  };

  handleClickButtonSaveUpdatedBlockPage = () => {
    const {newOrUpdatedBlockPage} = this.props;
    if (newOrUpdatedBlockPage.contentLogo && newOrUpdatedBlockPage.contentHasLogoInserted) {
      this.props.uploadBlockPageLogo(newOrUpdatedBlockPage).then((logoUrl) => {
        if (!_isNil(logoUrl)) {
          this.props.updateBlockPage({
            ...newOrUpdatedBlockPage,
            contentLogoURL: logoUrl,
          }).then((isUpdated) => {
            if (isUpdated) {
              this.goToBlockPagesList();
            }
          });
        }
      });
    } else {
      this.props.updateBlockPage(newOrUpdatedBlockPage)
        .then((isUpdated) => {
          if (isUpdated) {
            this.goToBlockPagesList();
          }
        });
    }
  };

  handleClickButtonCancelCreateBlockPage = () => {
    this.goToBlockPagesList();
    this.props.changeEditModeAndBlockPageState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  // eslint-disable-next-line
  handleClickButtonCancelBlockPagesEditing = () => {
    this.props.changeEditModeAndBlockPageState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  // eslint-disable-next-line
  handleClickButtonEditBlockPage = () => {
    this.props.changeEditModeAndBlockPageState(true, STATES_ENTITY.EDITING);
  };

  // eslint-disable-next-line
  handleClickButtonSaveBlockPage = () => {
    const {newOrUpdatedBlockPage} = this.props;
    this.props.updateBlockPage(newOrUpdatedBlockPage);
  };

  handleClickButtonDeleteBlockPage = () => {
    const {selectedBlockPage} = this.props;
    this.props.deleteBlockPage(selectedBlockPage.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToBlockPagesList();
        }
      });
  };

  showFormCreatingBlockPage = () => {
    const {
      accountInfo,
      blockPages,
    } = this.props;
    if (blockPages.length >= accountInfo.maxBlockPages) {
      this.props.openModalWindow(
        I18n.t('blockPage.errorMessages.blockPagesListMoreThenMaxBlockPages'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      );
      return;
    }

    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndBlockPageState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedBlockPageIndex();
  };

  goToBlockPagesList = () => {
    this.setState(BlockPage.initialLocalState);
    this.props.resetSelectedBlockPageIndex();
  };

  showBlockPageDetails = (blockPageIndex) => {
    const {blockPages} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowBlockPageDetails(blockPages[blockPageIndex].id, blockPageIndex);
    this.props.changeEditModeAndBlockPageState(false, STATES_ENTITY.EDITING);
  };

  render() {
    const {
      blockPagesTableData,
      isEditMode,
      selectedBlockPageIndex,
      classes,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    const containerClasses = {
      container: classes.container,
    };

    return (
      <ContainerWithListAndForm
            list={(
              <BlockPagesTable
                handleClickBlockPageRow={(this.showBlockPageDetails)}
                handleClickButtonNew={this.showFormCreatingBlockPage}
                isEditMode={isEditMode}
                mode={tableMode}
                tableData={blockPagesTableData}
                selectedBlockPageIndex={selectedBlockPageIndex}
              />
            )}
            form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
            classes={containerClasses}
      />
    );
  }
}

BlockPage.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  blockPages: PropTypes.array.isRequired,
  blockPagesTableData: PropTypes.array.isRequired,
  blockPagesStats: PropTypes.object,
  classes: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  isValidBlockPageInfo: PropTypes.bool.isRequired,
  newOrUpdatedBlockPage: PropTypes.object.isRequired,
  selectedBlockPage: PropTypes.object.isRequired,
  selectedBlockPageIndex: PropTypes.number.isRequired,
  deleteBlockPage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,

  changeEditModeAndBlockPageState: PropTypes.func.isRequired,
  createBlockPage: PropTypes.func.isRequired,
  getDataToShowBlockPageDetails: PropTypes.func.isRequired,
  getBlockPagesTableData: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  resetSelectedBlockPageIndex: PropTypes.func.isRequired,
  resetPageData: PropTypes.func.isRequired,
  uploadBlockPageLogo: PropTypes.func.isRequired,
  updateBlockPage: PropTypes.func.isRequired,
  updateBlockPageData: PropTypes.func.isRequired,
};

BlockPage.defaultProps = {
  blockPagesStats: {},
  classes: {},
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,

  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  newOrUpdatedBlockPage: state.blockPageEditFormReducer.editableBlockPageInfo,
  isValidBlockPageInfo: state.blockPageEditFormReducer.isValidBlockPageInfo,

  blockPages: state.blockPageReducer.blockPages,
  blockPagesTableData: state.blockPageReducer.blockPagesTableData,
  isEditMode: state.blockPageReducer.isEditMode,
  selectedBlockPage: state.blockPageReducer.selectedBlockPage,
  selectedBlockPageIndex: state.blockPageReducer.selectedBlockPageIndex,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndBlockPageState: bindActionCreators(changeEditModeAndBlockPageState, dispatch),
  createBlockPage: bindActionCreators(createBlockPage, dispatch),
  deleteBlockPage: bindActionCreators(deleteBlockPage, dispatch),
  uploadBlockPageLogo: bindActionCreators(uploadBlockPageLogo, dispatch),
  getDataToShowBlockPageDetails: bindActionCreators(getDataToShowBlockPageDetails, dispatch),
  getBlockPagesTableData: bindActionCreators(getBlockPagesTableData, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  resetSelectedBlockPageIndex: bindActionCreators(resetSelectedBlockPageIndex, dispatch),
  resetPageData: bindActionCreators(resetPageData, dispatch),
  updateBlockPage: bindActionCreators(updateBlockPage, dispatch),
  updateBlockPageData: bindActionCreators(updateBlockPageData, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(style)(BlockPage),
));

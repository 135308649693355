import {matchSorter} from 'match-sorter';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n} from 'react-redux-i18n';

import {TLD_TYPES} from '../../constants';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';
import {
  CardWithHeader,
  TableWithPaginationAndFiltration,
} from '../index';

import style from './style';

class PolicyTldList extends React.Component {
  getColumns = () => {
    const headerStyle = {margin: '0'};
    const columnStyle = {...headerStyle, whiteSpace: 'unset'};
    const hiddenColumnProps = {
      width: 0,
      style: {
        display: 'none',
      },
      headerStyle: {
        display: 'none',
      },
    };
    return [
      {
        Header: I18n.t('policyPage.tldTab.tableHeaders.name'),
        accessor: 'name',
        filterable: false,
        sortable: false,
        resizable: false,
        columnStyle,
        headerStyle,
      },
      {
        Header: I18n.t('policyPage.tldTab.tableHeaders.type'),
        accessor: 'type',
        filterable: false,
        sortable: false,
        resizable: false,
        columnStyle,
        headerStyle,
      },
      {
        Header: I18n.t('policyPage.tldTab.tableHeaders.manager'),
        accessor: 'manager',
        filterable: false,
        sortable: false,
        resizable: false,
        columnStyle,
        headerStyle,
      },
      // This hidden column is implemented for full-text searching purpose:
      // https://github.com/tannerlinsley/react-table/issues/944#issuecomment-386310450
      {
        id: 'all',
        ...hiddenColumnProps,
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
          keys: [
            'name',
            'type',
            'manager',
          ],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        }),
        filterAll: true,
      },
    ];
  };

  getFiltered = () => {
    const {searchQuery, selectedTypeIndex} = this.props;
    return [
      {
        id: 'all',
        value: searchQuery,
      },
      (selectedTypeIndex > 0 && {
        id: 'type',
        value: Object.values(TLD_TYPES)[selectedTypeIndex - 1],
      }),
    ];
  };

  render() {
    const {
      classes,
      title,
      isEditMode,
      tldList,
      pageNumber,
      selectedRows,
      anotherTableSelectedRows,
      onSelectAllClick,
      onDeselectClick,
      onRowClick,
      onPageChange,
    } = this.props;
    const isSelectAllDisabled = (!isEditMode || tldList.length === 0
      || tldList.length === selectedRows.length);
    const isDeselectDisabled = !isEditMode || selectedRows.length === 0;
    const handleClickRow = isEditMode && anotherTableSelectedRows.length === 0
      ? onRowClick
      : undefined;
    const defaultSorted = [{
      index: 'ranking',
      desc: false,
    }];
    const tableStyle = {
      height: 'calc(100vh - 501px)',
      minHeight: '300px',
    };
    const theadFilterProps = {
      style: {
        display: 'none',
      },
    };
    return (
      <CardWithHeader
        cardTitle={title}
        cardTitleColor="primary"
        customCardClasses="card_policyEditFormTldList"
        customCardContentClasses="card__content_policyEditFormTldList"
        content={(
          <>
            <div className={classes.listSection__selectionButtonSection}>
              <CustomButton
                color="secondary"
                disabled={isSelectAllDisabled}
                customClasses="policyTldTab__selectAllButton"
                onClick={onSelectAllClick}
                size="xs"
              >
                {I18n.t('policyPage.tldTab.selectionButtonLabels.selectAll')}
              </CustomButton>
              <CustomButton
                color="secondary"
                disabled={isDeselectDisabled}
                customClasses="policyTldTab__deselectButton"
                onClick={onDeselectClick}
                size="xs"
              >
                {I18n.t('policyPage.tldTab.selectionButtonLabels.deselect')}
              </CustomButton>
            </div>
            <TableWithPaginationAndFiltration
              columns={this.getColumns()}
              data={tldList}
              pageSize={30}
              page={pageNumber}
              filtered={this.getFiltered()}
              showPageSizeOptions={false}
              handleClickRow={handleClickRow}
              defaultSorted={defaultSorted}
              minRows={1}
              selectedRowIndices={selectedRows}
              style={tableStyle}
              theadFilterProps={theadFilterProps}
              onPageChange={onPageChange}
              disableWhiteSpaceNoWrap={true}
            />
          </>
        )}
      />
    );
  }
}

PolicyTldList.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  tldList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    manager: PropTypes.string,
    ranking: PropTypes.number.isRequired,
  })).isRequired,
  pageNumber: PropTypes.number.isRequired,
  searchQuery: PropTypes.string.isRequired,
  selectedTypeIndex: PropTypes.number.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  anotherTableSelectedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onDeselectClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default withStyles(style)(PolicyTldList);

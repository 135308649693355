import initState from '../../reducers/redux-initial-state';

import {cloneNetworkInfo} from './service';

import {types} from './action';

export default (state = initState.networkEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_NETWORK_INFO: {
      return {
        ...state,
        editableNetworkInfo: cloneNetworkInfo(action.data),
      };
    }
    case types.SET_VALIDATION_NETWORK_INFO: {
      return {
        ...state,
        isValidNetworkInfo: action.isValid,
      };
    }
    case types.RESET_NETWORK_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.networkEditFormReducer,
      };
    }
    case types.UPDATE_NETWORK_INFO: {
      return {
        ...state,
        editableNetworkInfo: action.data,
        isValidNetworkInfo: action.isValidNetworkInfo,
      };
    }
    default:
      return state;
  }
};

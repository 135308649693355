import {redColor} from '../../styles/style';

const style = {
  'container': {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    minHeight: '87px',
  },
  'container__message': {
    margin: '25px auto',
  },
  'item': {
    flexBasis: '49%',
    display: 'inline-block',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  'part_threat': {
    display: 'inline-block',
    width: '71%',
    textAlign: 'right',
  },
  'part_count': {
    display: 'inline-block',
    width: '28%',
    textAlign: 'left',
    fontWeight: '500',
    paddingLeft: '7px',
  },
  'part_count_danger': {
    color: redColor,
  },
  '@media (max-width: 1530px)': {
    item: {
      fontSize: '11px',
    },
  },
  '@media (max-width: 1450px)': {
    item: {
      fontSize: '10px',
    },
  },
};

export default style;

import initState from '../../reducers/redux-initial-state';
import {types} from './action';

export default (state = initState.rowActionsReducer, action) => {
  switch (action.type) {
    case types.SET_WHITE_BLACK_LISTS_FOR_ROW_ACTIONS: {
      return {
        ...state,
        wbLists: action.wbLists,
      };
    }
    case types.SET_WHITE_BLACK_LISTS_REQUEST_STATUS: {
      return {
        ...state,
        areWbListsRequested: action.areRequested,
      };
    }
    default:
      return state;
  }
};

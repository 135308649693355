import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import OperatorForm from '../../components/OperatorForm/OperatorForm';
import EntityEditingCard from '../../components/EntityEditingCard/EntityEditingCard';
import {isSuperAdmin} from '../../services/operator/operatorRole';
import {ACCESS_RIGHTS, ROLES_AVAILABILITY, USER_ROLES} from '../../constants';
import {revokeOperatorAccess, setEditingMode} from '../../app-common/AccessManagement/action';
import {getOrganizationsData} from '../OperatorOrganizationsTable/utils';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_EDIT],
  buttonDelete: [ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_REVOKE_ACCESS],
};

class OperatorEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operator: _cloneDeep(props.operator),
    };
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.operator, this.props.operator)) {
      this.updateState();
    }
  }

  onChangeRole = (fieldName, value) => {
    this.setState((prevState) => {
      const newState = _cloneDeep(prevState);
      _set(newState, ['operator', fieldName], value);
      return newState;
    });
  };

  get isSelectedSuperAdmin() {
    return isSuperAdmin(this.props.operator);
  }

  get canUpdateOperators() {
    const role = _get(this.props.loggedAccount, 'role');
    const isAdmin = role === USER_ROLES.ADMIN;

    return _get(ROLES_AVAILABILITY, ['accessManagement', role], true)
      && !(isAdmin && this.isSelectedSuperAdmin)
      && this.props.operators.length > 1
      && !this.isSelectedSuperAdmin;
  }

  setEditingMode = () => this.props.setEditingMode(true);

  updateState = () => {
    this.props.setEditingMode(false);
    this.setState({
      operator: _cloneDeep(this.props.operator),
    });
  };

  revokeAccess = () => {
    const operatorId = _get(this.state, 'operator.id', null);
    this.props.revokeOperatorAccess(operatorId)
      .then(this.props.updatingCallback);
  };

  saveOperator = () => {
    const accountId = _get(this.props, 'loggedAccount.accountId', null);
    const operatorId = _get(this.state, 'operator.id', null);
    const operatorRole = _get(this.state, 'operator.role', null);
    const operatorOrganizations = getOrganizationsData(this.props.operatorOrganizationRows);

    this.props.updateOperator(accountId, operatorId, operatorRole, operatorOrganizations);
  };

  isValidOperator = () => (
    !_isEqual(this.state.operator.role, USER_ROLES.ORGANIZATION_OPERATOR)
      || (_isEqual(this.state.operator.role, USER_ROLES.ORGANIZATION_OPERATOR)
        && !_isEmpty(this.props.operatorOrganizationRows)
        && this.props.operatorOrganizationRows
          .findIndex((item) => item.selectedOrganizationIndex >= 0) >= 0)
  );

  render() {
    const title = _get(this.state.operator, 'email', '');
    return (
      <EntityEditingCard
        entityType={I18n.t('entitiesTypes.operator')}
        title={title}
        content={(
          <OperatorForm
            operator={this.state.operator}
            accountInfo={this.props.accountInfo}
            isSelectedSuperAdmin={this.isSelectedSuperAdmin}
            isEditing={this.props.isEditingMode}
            onChange={this.onChangeRole}
          />
        )}
        isTherePathBack={true}
        isThereEditBlock={this.canUpdateOperators}
        isThereDeleteBlock={this.canUpdateOperators}
        isEditMode={this.props.isEditingMode}
        handleClickButtonDelete={this.revokeAccess}
        handleClickButtonEdit={this.setEditingMode}
        handleClickButtonCancel={this.updateState}
        handleClickButtonBack={this.props.resetSelectedOperator}
        handleClickButtonSave={this.saveOperator}
        deleteButtonText={I18n.t('accessManagementPage.revokeAccess.button')}
        isValidEntity={this.isValidOperator()}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

OperatorEditForm.propTypes = {
  operator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    lastLogin: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  accountInfo: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
  operatorOrganizationRows: PropTypes.array.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  revokeOperatorAccess: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,
  resetSelectedOperator: PropTypes.func.isRequired,
  updateOperator: PropTypes.func.isRequired,
  setEditingMode: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  accountInfo: state.operatorLayoutReducer.accountInfo,
  isEditingMode: state.accessManagementReducer.isEditingMode,
  operatorOrganizationRows: state.operatorOrganizationsTableReducer.rows,
});

const mapDispatchToProps = {
  revokeOperatorAccess,
  setEditingMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorEditForm);

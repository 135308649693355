import {types} from './constants';

export const updateFilter = (filterName, value) => ({
  type: types.UPDATE_FILTER,
  name: filterName,
  value,
});

export const reinitFilters = (filterInitValues) => ({
  type: types.REINIT_FILTERS,
  data: filterInitValues,
});

import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';

import {Loading} from '../../../containers';
import {RenderOrEmpty} from '../../../components';

import logo from './img/logo.svg';

import style from './style';

function UnauthorizedLayout(props) {
  const {
    children,
    classes,
    showSpinner,
  } = props;
  return (
    <div className={classes.unauthorizedLayout}>
      <RenderOrEmpty isShow={showSpinner}>
        <Loading />
      </RenderOrEmpty>
      <div className={classes.unauthorizedLayout__formWrapper}>
        <div className={classes.formWrapper__logoWrapper}>
          <img
            alt="logo.jpg"
            className={classes.formWrapper__logo}
            src={logo}
          />
        </div>
        {children}
      </div>
    </div>
  );
}

UnauthorizedLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  showSpinner: PropTypes.bool,
};
UnauthorizedLayout.defaultProps = {
  children: '',
  showSpinner: true,
};

export default withStyles(style)(UnauthorizedLayout);

import _omit from 'lodash/omit';

import {types} from './constants';

import {
  get,
  makeUrl,
} from '../../utils/fetcher';

import {
  METHODS,
} from '../../constants';

export function setEditableBlockPageInfo(blockPageInfo) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_BLOCK_PAGE_INFO,
      blockPageInfo: blockPageInfo,
      isValidBlockPageInfo: true,
    });
  };
}

export function resetBlockPageEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_BLOCK_PAGE_EDIT_FORM_DATA,
    });
  };
}

export function updateBlockPageInfo(updatedBlockPageInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_BLOCK_PAGE_INFO,
      updatedBlockPageInfo,
      isValidBlockPageInfo: isValid,
    });
  };
}

export function setValidationResultBlockPageInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_BLOCK_PAGE_INFO,
      isValid,
    });
  };
}

export function setDefaultBlockPageFormData() {
  const url = makeUrl(METHODS.GET_DEFAULT_BLOCK_PAGE);

  const fieldsToOmit = [
    'accountId',
    'id',
    'createdAt',
    'updatedAt',
    'default',
    'contentLogoURL',
    'contentLogo',
    'contentLogoName',
    'contentHasLogoInserted',
  ];

  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = _omit(response.data, fieldsToOmit);
          dispatch({
            type: types.SET_EDITABLE_BLOCK_PAGE_INFO,
            blockPageInfo: data,
            isValidBlockPageInfo: true,
          });
        }
      });
}

import moment from 'moment-timezone';
import _isNil from 'lodash/isNil';

import {DATE_FORMAT} from '../constants';

// Example of input: 2020-04-09T01:28:37.452-05:00[America/Chicago]
export const formatDateTimeToUserTimezone = (dateTime) => {
  if (!_isNil(dateTime)) {
    const momentObject = moment.tz(
      dateTime.substr(0, dateTime.indexOf('[')),
      DATE_FORMAT.YYYYMMDDTHHmmss_Z,
      moment.tz.guess(),
    );
    if (!momentObject._isValid) return '—';
    return momentObject.format(DATE_FORMAT.YYYYMMDD_HHmmss_z);
  }
  return '—';
};

const splitToDateTimeAndTimezoneAbbr = (dateTime) => {
  const charIndex = dateTime.indexOf('T');
  const whitespaceIndex = dateTime.indexOf(' ');

  return {
    date: dateTime.substr(0, charIndex),
    time: dateTime.substr(charIndex + 1, whitespaceIndex - charIndex - 1),
    timezoneAbbr: dateTime.substr(whitespaceIndex + 1),
  };
};

const getMomentObjectFromDateTimeWithTCharAndTimezoneAbbr = (dateTime) => {
  const {date, time, timezoneAbbr} = splitToDateTimeAndTimezoneAbbr(dateTime);
  const dateObject = new Date(`${date} ${time} ${timezoneAbbr}`);
  return moment.tz(dateObject, moment.tz.guess());
};

// Example of input: 2020-05-05T21:21:21 CST
export const formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift = (dateTime) => {
  const momentObject = getMomentObjectFromDateTimeWithTCharAndTimezoneAbbr(dateTime);
  if (!momentObject._isValid) return '—';
  return momentObject.format(DATE_FORMAT.YYYYMMDD_HHmmss_z);
};

// Example of input: 2020-05-05T21:21:21 CST
export const formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithTChar = (dateTime) => {
  const momentObject = getMomentObjectFromDateTimeWithTCharAndTimezoneAbbr(dateTime);
  if (!momentObject._isValid) return '—';
  return momentObject.format(DATE_FORMAT.YYYYMMDDTHHmmss);
};

// Example of input: 1647509892
export const timestampToUserDate = (timestamp) => {
  if (_isNil(timestamp)) return '-';
  const momentObject = moment.unix(timestamp);
  if (!momentObject._isValid) return '-';
  return moment.tz(momentObject, moment.tz.guess()).format(DATE_FORMAT.YYYYMMDD_HHmmss_z);
};

export const timestampMilliToUserDate = (timestampMilli) => {
  if (_isNil(timestampMilli)) return '-';
  return timestampToUserDate(timestampMilli / 1000);
};

export const millisToTimeFormatted = (millis) => moment.utc(millis).format('HH:mm:ss');

export const formatMoment = (momentObject, format = DATE_FORMAT.YYYYMMDD_HYPHEN) => {
  if (_isNil(momentObject)) {
    return null;
  }
  return momentObject.format(format);
};

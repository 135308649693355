import {
  get,
  makeUrlToGetMock,
} from '../../utils/fetcher';

import {METHODS} from '../../constants';

export const types = {
  SET_IPs: 'SET_IPs',
};

export function getIPs() {
  const url = makeUrlToGetMock(METHODS.GET_IPs);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_IPs,
          data,
        });
      }
    });
}

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {withStyles} from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import BlockIcon from '@material-ui/icons/Block';
import {
  GroupStatsCard,
  CardStats,
  RenderOrEmpty,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import {brightRedColor, darkerGreenColor} from '../../styles/style';
import style from './style';

function RequestsStats(props) {
  const {
    classes,
    userRequestsGraph,
    requestsAllowed,
    requestsBlocked,
    requestsTotal,
    customGraphClass,
    widgetKey,
  } = props;

  const lineChartStyles = {
    left: '-25px',
  };

  const lineStyles = {
    strokeWidth: '2px',
  };

  const thereIsNotGraphData = _isEmpty(userRequestsGraph.chartData);
  return (
    <GroupStatsCard
        groupName={I18n.t('dashboard.requestsStats.title')}
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.requestsStats}>
              <RenderOrEmpty isShow={thereIsNotGraphData}>
                <h3 className={classes.requestsStats__thereIsNotGraphData}>
                  {I18n.t('dashboard.requestsStats.graphDataNotAvailable')}
                </h3>
              </RenderOrEmpty>
              <RenderOrEmpty isShow={!thereIsNotGraphData}>
                <div
                  className={classNames(
                    classes.requestsStats__graph,
                    {
                      [classes.requestsStats__graph_campusDashboard]:
                        !_isUndefined(customGraphClass),
                    },
                  )}
                >
                  <ResponsiveContainer>
                    <LineChart
                      data={userRequestsGraph.chartData}
                      style={lineChartStyles}
                    >
                      <XAxis
                        axisLine={false}
                        tickLine={false}
                        height={40}
                        dy={18}
                        dataKey="xAxis"
                      />
                      <YAxis axisLine={false} tickLine={false} dx={-10} />
                      <CartesianGrid horizontal={false} />
                      <Tooltip />
                      <Line
                        dataKey="allowed"
                        stroke={darkerGreenColor}
                        style={lineStyles}
                        type="monotone"
                        dot={false}
                      />
                      <Line
                        dataKey="blocked"
                        stroke={brightRedColor}
                        style={lineStyles}
                        type="monotone"
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </RenderOrEmpty>
              <div className={classes.cards__container}>
                <div className={classes.cards__item}>
                  <CardStats
                    description={I18n.t('dashboard.requestsStats.allowed')}
                    borderTopColor="green"
                    value={requestsAllowed}
                    descriptionIcon={<CheckCircleOutlineRoundedIcon fontSize="inherit" />}
                  />
                </div>
                <div className={classes.cards__item}>
                  <CardStats
                    description={I18n.t('dashboard.requestsStats.blocked')}
                    borderTopColor="red"
                    value={requestsBlocked}
                    descriptionIcon={<BlockIcon fontSize="inherit" />}
                  />
                </div>
                <div className={classes.cards__item}>
                  <CardStats
                    description={I18n.t('dashboard.requestsStats.total')}
                    borderTopColor="primary"
                    value={requestsTotal}
                  />
                </div>
              </div>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

RequestsStats.propTypes = {
  classes: PropTypes.object.isRequired,
  userRequestsGraph: PropTypes.object,
  requestsAllowed: PropTypes.number,
  requestsBlocked: PropTypes.number,
  requestsTotal: PropTypes.number,
  customGraphClass: PropTypes.string,
  widgetKey: PropTypes.string.isRequired,
};

RequestsStats.defaultProps = {
  userRequestsGraph: {
    chartData: [],
  },
  requestsAllowed: 0,
  requestsBlocked: 0,
  requestsTotal: 0,
  customGraphClass: undefined,
};

export default withStyles(style)(RequestsStats);

import {primaryColor} from '../../styles/style';
import cardsStyle from '../../styles/components/cards';

const style = (theme) => ({
  th_additionalOptions: {
    textAlign: 'center !important',
  },
  td_additionalOptions: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  td__tooltip: {
    maxWidth: '300px',
    background: primaryColor,
    color: '#FFF',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    opacity: 1,
  },
  tbodyInfinite: {
    display: 'block',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 380px)',
    minWidth: 'max-content',
  },
  tbodyInfinite_noItems: {
    height: '100vh',
  },
  tbodyInfinite_fullScreen: {
    maxHeight: 'calc(100vh - 205px)',
  },
  tbodyInfinite_additionalOptionsIsOpen: {
    maxHeight: 'calc(100vh - 580px)',
  },
  tbodyInfinite_insights: {
    maxHeight: 'unset',
  },
  logTableCard: {
    ...cardsStyle.card,
    margin: '3px 0 4px 0',
  },
  logTableCard_fullScreen: {
    ...cardsStyle.card_fullScreen,
    marginTop: 0,
    height: 'calc(100vh - 130px)',

  },
  logTableCard__header: {
    ...cardsStyle.card__header,
  },
  logTableCard__title: {
    ...cardsStyle.card__title,
    ...cardsStyle.card__title_primary,
    borderBottom: 'none',
  },
  logTableCard__actions: {},
  logTableCard__content: {
    ...cardsStyle.card__content,
    padding: '0px 20px 5px 20px !important',
  },
});

export default style;

import initState from '../../reducers/redux-initial-state';

import {types} from './constants';

export default (state = initState.organizationPersonasTabEditFormReducer, action) => {
  switch (action.type) {
    case types.CHANGE_ORGANIZATION_PERSONAS_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    }
    case types.SET_EDIT_FROM_ORGANIZATION_PERSONAS: {
      return {
        ...state,
        personasNotInOrganization: action.personasNotInOrganization,
        personasInOrganization: action.personasInOrganization,
      };
    }
    default:
      return state;
  }
};

import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.usageBillingReducer, action) => {
  switch (action.type) {
    case types.SET_BILLING_PERIOD: {
      return {
        ...state,
        selectedPeriodIndex: action.selectedPeriodIndex,
        period: action.period,
      };
    }
    case types.SET_INVOICE_DATE: {
      return {
        ...state,
        invoiceDate: action.invoiceDate,
      };
    }
    case types.SET_TABLE_COSTS_CALCULATED: {
      return {
        ...state,
        tableCostsCalculated: action.tableCostsCalculated,
      };
    }
    case types.UPDATE_COST: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case types.SET_ORGANIZATIONS_USAGE_STATS: {
      return {
        ...state,
        organizationUsageStats: action.usageBilling,
      };
    }

    default:
      return state;
  }
};

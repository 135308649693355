import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityCreatingCard} from '../index';

import {SubnetEditForm} from '../../containers/index';

function SubnetCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
    isValidSubnetInfo,
  } = props;

  return (
    <EntityCreatingCard
      title={I18n.t('campusPage.subnetsTab.createSubnet')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidSubnetInfo}
      customCardClass="card_subnets"
      customCardContentClass="card__content_subnets"
      content={
        <SubnetEditForm />
      }
    />
  );
}

SubnetCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidSubnetInfo: PropTypes.bool.isRequired,
};

export default SubnetCreating;

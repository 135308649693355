import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import {ApiKeyForm, EntityCreatingCard} from '../../components/index';
import {createApiKey} from '../../app-common/ApiAccessManagement/action';
import {DEFAULT_MAX_INPUT_STRING_LENGTH} from '../../constants';

class ApiKeyCreatingForm extends React.Component {
  state = {
    apiKey: {
      id: '',
      createdAt: '',
      updatedAt: '',
      description: '',
    },
  };

  handleChangeInputInfo = (inputName, value) => {
    const {description} = this.state.apiKey;
    if (value.length > description.length
      && description.length >= DEFAULT_MAX_INPUT_STRING_LENGTH) {
      return;
    }
    this.setState((prevState) => ({
      apiKey: {
        ...prevState.apiKey,
        [inputName]: value,
      },
    }));
  };

  saveApiKey = () => {
    this.props.createApiKey({...this.state.apiKey})
      .then(this.props.updatingCallback);
  };

  render() {
    return (
      <EntityCreatingCard
        title={I18n.t('apiAccessManagementPage.form.newKey.title')}
        content={(
          <ApiKeyForm
            apiKey={this.state.apiKey}
            accountInfo={this.props.accountInfo}
            onChange={this.handleChangeInputInfo}
            isNewApiKey={true}
            isEditing={true}
          />
        )}
        handleClickButtonCancel={this.props.resetForm}
        handleClickButtonSave={this.saveApiKey}
      />
    );
  }
}

ApiKeyCreatingForm.propTypes = {
  resetForm: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,
  createApiKey: PropTypes.func.isRequired,
  accountInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
});

const mapDispatchToProps = {
  createApiKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyCreatingForm);

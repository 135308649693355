import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';

import initState from '../../reducers/redux-initial-state';

import {types} from './action';
import {DEFAULT_WIDGET_KEY} from '../../constants';

export default (state = initState.loadingReducer, action) => {
  switch (action.type) {
    case types.UPDATE_LOADING_STATUS: {
      const {widgetKey, loadingUUID, isLoading, abortController} = action;
      const newState = _cloneDeep(state);
      const loadingMap = newState[widgetKey].loadingMap;

      if (!_isNil(loadingMap[loadingUUID]) && !isLoading) {
        newState[widgetKey].loadingMap = _omit(loadingMap, loadingUUID);
      }
      if (_isNil(loadingMap[loadingUUID]) && isLoading) {
        loadingMap[loadingUUID] = abortController;
      }
      newState[widgetKey].isLoading = !_isEmpty(newState[widgetKey].loadingMap);
      return newState;
    }

    case types.CANCEL_REQUESTS: {
      const newState = _cloneDeep(state);
      const requestKeys = (action.includeDefaultKey)
        ? [DEFAULT_WIDGET_KEY, ...action.requestKeys]
        : action.requestKeys;

      requestKeys.forEach((key) => {
        newState[key] = {
          loadingMap: {},
          isLoading: false,
        };
      });
      return newState;
    }

    default:
      return state;
  }
};

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';
import {
  ClientBundleCreating,
  ClientBundleDetails,
  ClientBundlesTable,
  ContainerWithListAndForm,
} from '../../components';
import {
  changeEditModeAndBundleState,
  createBundle,
  deleteBundle,
  updateBundle,
  getDataToShowBundleDetails,
  loadBundles,
  resetClientInstallFormData,
  resetSelectedBundleIndex,
  setSelectedBundleIndex,
} from './action';

class ClientProfileInstallForm extends React.Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateBundleTableData(prevProps, props) {
    return !_isEqual(prevProps.bundles, props.bundles)
      || (!_isEqual(prevProps.profile.id, props.profile.id));
  }

  constructor(props) {
    super(props);
    this.state = {...ClientProfileInstallForm.initialLocalState};
  }

  componentDidMount() {
    this.props.loadBundles(this.props.profile.id);
  }

  componentDidUpdate(prevProps) {
    if (
      ClientProfileInstallForm.wasDataScopeSwitched(prevProps, this.props)
      || ClientProfileInstallForm.shouldUpdateBundleTableData(prevProps, this.props)
    ) {
      this.props.loadBundles(this.props.profile.id)
        .then(() => this.goToBundleList());
    }
  }

  componentWillUnmount() {
    this.props.resetClientInstallFormData();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <ClientBundleCreating
            handleClickButtonCancel={this.handleClickButtonCancelGeneratingBundle}
            handleClickButtonSave={this.handleClickButtonGenerateBundle}
            isValidBundleInfo={this.props.isValidBundleInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <ClientBundleDetails
            bundle={this.props.selectedBundle}
            handleClickButtonCancel={this.handleClickButtonCancelEditBundle}
            handleClickButtonSave={this.handleClickButtonSaveBundle}
            handleClickButtonBack={this.goToBundleList}
            handleClickButtonDelete={this.handleClickButtonDeleteBundle}
            handleClickButtonEdit={this.handleClickButtonEditBundle}
            handleClickButtonCancelEdit={this.handleClickButtonCancelEditBundle}
            isEditMode={this.props.isEditMode}
            isProfileEditMode={this.props.isProfileEditMode}
            isValidBundleInfo={this.props.isValidBundleInfo}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  goToBundleList = () => {
    this.setState(ClientProfileInstallForm.initialLocalState);
    this.props.resetSelectedBundleIndex();
  };

  handleClickButtonGenerateBundle = () => {
    const {newOrUpdatedBundle} = this.props;

    this.props.createBundle({
      ...newOrUpdatedBundle,
      profileId: this.props.profile.id,
    })
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToBundleList();
        }
      });
  };

  handleClickButtonSaveBundle = () => {
    this.props.updateBundle(this.props.newOrUpdatedBundle);
  };

  handleClickButtonEditBundle = () => {
    this.props.changeEditModeAndBundleState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonCancelGeneratingBundle = () => {
    this.goToBundleList();
    this.props.changeEditModeAndBundleState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelEditBundle = () => {
    this.props.changeEditModeAndBundleState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonDeleteBundle = () => {
    const {selectedBundle} = this.props;
    this.props.deleteBundle(selectedBundle.id, selectedBundle.profileId)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToBundleList();
        }
      });
  };

  showBundleDetails = (bundleIndex) => {
    const {bundles} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowBundleDetails(bundles[bundleIndex].id, bundleIndex);
  };

  showFormGeneratingBundle = () => {
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndBundleState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedBundleIndex();
  };

  render() {
    const {modeShowingContainer, tableMode} = this.state;
    const {
      bundles,
      isEditMode,
      isProfileEditMode,
      selectedBundleIndex,
    } = this.props;
    const tableStyles = {
      height: 'calc(100vh - 307px)',
    };

    return (
      <ContainerWithListAndForm
        list={(
          <ClientBundlesTable
            tableData={bundles}
            mode={tableMode}
            isEditMode={isEditMode || isProfileEditMode}
            selectedBundleIndex={selectedBundleIndex}
            tableStyles={tableStyles}
            handleClickButtonNew={this.showFormGeneratingBundle}
            handleClickRow={this.showBundleDetails}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

ClientProfileInstallForm.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,

  bundles: PropTypes.array.isRequired,
  selectedBundle: PropTypes.object.isRequired,
  selectedBundleIndex: PropTypes.number.isRequired,
  newOrUpdatedBundle: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isProfileEditMode: PropTypes.bool.isRequired,
  isValidBundleInfo: PropTypes.bool.isRequired,

  loadBundles: PropTypes.func.isRequired,
  setSelectedBundleIndex: PropTypes.func.isRequired,
  resetSelectedBundleIndex: PropTypes.func.isRequired,
  resetClientInstallFormData: PropTypes.func.isRequired,
  getDataToShowBundleDetails: PropTypes.func.isRequired,
  changeEditModeAndBundleState: PropTypes.func.isRequired,
  createBundle: PropTypes.func.isRequired,
  updateBundle: PropTypes.func.isRequired,
  deleteBundle: PropTypes.func.isRequired,
};

ClientProfileInstallForm.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  isProfileEditMode: state.clientsConfigurerReducer.isEditMode,
  profile: state.clientsConfigurerReducer.selectedProfile,

  bundles: state.clientProfileInstallFormReducer.bundles,
  selectedBundle: state.clientProfileInstallFormReducer.selectedBundle,
  selectedBundleIndex: state.clientProfileInstallFormReducer.selectedBundleIndex,
  isEditMode: state.clientProfileInstallFormReducer.isEditMode,

  isValidBundleInfo: state.clientBundleEditFormReducer.isValidBundleInfo,
  newOrUpdatedBundle: state.clientBundleEditFormReducer.editableBundleInfo,
});

const mapDispatchToProps = (dispatch) => ({
  loadBundles: bindActionCreators(loadBundles, dispatch),
  setSelectedBundleIndex: bindActionCreators(setSelectedBundleIndex, dispatch),
  resetSelectedBundleIndex: bindActionCreators(resetSelectedBundleIndex, dispatch),
  resetClientInstallFormData: bindActionCreators(resetClientInstallFormData, dispatch),
  getDataToShowBundleDetails: bindActionCreators(getDataToShowBundleDetails, dispatch),
  changeEditModeAndBundleState: bindActionCreators(changeEditModeAndBundleState, dispatch),
  createBundle: bindActionCreators(createBundle, dispatch),
  updateBundle: bindActionCreators(updateBundle, dispatch),
  deleteBundle: bindActionCreators(deleteBundle, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileInstallForm);

import React from 'react';
import PropTypes from 'prop-types';

import {EntityCreatingCard} from '../index';

import {BlockPageEditForm} from '../../containers/index';

function BlockPageCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
    isValidBlockPageInfo,
    title,
  } = props;

  return (
    <EntityCreatingCard
      title={title}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidBlockPageInfo}
      customCardContentClass="card__content_block-page"
      content={
        <BlockPageEditForm />
      }
    />
  );
}

BlockPageCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isValidBlockPageInfo: PropTypes.bool.isRequired,
};

export default BlockPageCreating;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

import {
  ContainerWithListAndForm,
  EntityEditingCard,
  OrganizationPersonasTable,
  MessageBox,
  RenderOrEmpty,
} from '../../components/index';
import {OrganizationPersonasEditForm} from '../index';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {
  removePersonaFromOrganization,
  getOrganizationPersonas,
  updateOrganizationPersonas,
  changePersonasEditMode,
} from './action';
import {cancelRequests} from '../Loading/action';

import {
  ACCESS_RIGHTS,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
} from '../../constants';

import style from './style';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.ORGANIZATION_PERSONA_LINK],
};

class OrganizationPersonasTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdatePersonas(prevProps, props) {
    return !_isEqual(prevProps.organization.id, props.organization.id)
      && !_isNil(props.organization.id);
  }

  constructor(props) {
    super(props);
    this.state = OrganizationPersonasTab.initialLocalState;
  }

  componentDidMount() {
    const {organization} = this.props;
    if (!_isNil(organization.id)) {
      this.props.getOrganizationPersonas(organization.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (OrganizationPersonasTab.shouldUpdatePersonas(prevProps, this.props)) {
      this.gotoPersonaListAndReload();
    }
  }

  getContainerItemByModeShowingContainer = () => {
    const {
      accountInfo,
      isEditMode,
      personasNotInOrganization,
      personasInOrganization,
    } = this.props;
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', true);

    let containerItem = null;
    if (isEditMode) {
      containerItem = (
        <>
          <RenderOrEmpty isShow={organizationsAllowed}>
            <OrganizationPersonasEditForm
              isEditMode={isEditMode}
              personasNotInOrganization={personasNotInOrganization}
              personasInOrganization={personasInOrganization}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={!organizationsAllowed}>
            <MessageBox
              message={I18n.t('organizations.errorMessages.organizationsNotAllowed')}
            />
          </RenderOrEmpty>
        </>
      );
    }

    return containerItem;
  };

  getPersonaListOrMessage = () => {
    const {tableMode} = this.state;
    const {
      classes,
      accountInfo,
      personasInOrganization,
      isEditMode,
      dashboardShown,
    } = this.props;

    const tableClasses = {
      tableBody: classNames({
        [classes.organizationPersonasTab__tableBody]: dashboardShown,
        [classes.organizationPersonasTab__tableBody_noDashboard]: !dashboardShown,
      }),
    };
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', true);

    let containerItem = null;

    if (!isEditMode) {
      containerItem = (
        <>
          <RenderOrEmpty isShow={organizationsAllowed}>
            <OrganizationPersonasTable
              classes={tableClasses}
              tableData={personasInOrganization}
              mode={tableMode}
              isEditMode={isEditMode}
              removePersonaFromOrganization={this.handleRemovePersona}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={!organizationsAllowed}>
            <MessageBox
              message={I18n.t('organizations.errorMessages.organizationsNotAllowed')}
            />
          </RenderOrEmpty>
        </>
      );
    }
    return containerItem;
  };

  handleRemovePersona = (personaIndex) => {
    const {organization, personasInOrganization} = this.props;
    this.props.removePersonaFromOrganization(
      personaIndex,
      organization.id,
      personasInOrganization[personaIndex].id,
    )
      .then(() => {
        this.props.getOrganizationPersonas(this.props.organization.id);
        if (!_isNil(this.props.onUpdatePersonas)) {
          this.props.onUpdatePersonas();
        }
      });
  };

  handleClickButtonCancelPersonasEditing = () => {
    this.props.changePersonasEditMode(false);
  };

  handleClickButtonEditPersonas = () => {
    this.props.changePersonasEditMode(true);
  };

  handleClickButtonSavePersonas = () => {
    const {
      organization,
      updatedPersonasInOrganization,
    } = this.props;

    this.props.updateOrganizationPersonas(organization.id, updatedPersonasInOrganization)
      .then(() => {
        if (!_isNil(this.props.onUpdatePersonas)) {
          this.props.onUpdatePersonas();
        }
      });
    this.props.changePersonasEditMode(false);
  };

  get isValid() {
    return this.props.personasInOrganization.length > 0
      || this.props.personasNotInOrganization.length > 0;
  }

  gotoPersonaListAndReload = () => {
    this.setState(OrganizationPersonasTab.initialLocalState);
    this.props.getOrganizationPersonas(this.props.organization.id);
  };

  render() {
    const cardClass = classNames({
      card_organizationSubtab: this.props.dashboardShown,
      card_organizationSubtab_noDashboard: !this.props.dashboardShown,
    });
    const contentClass = classNames({
      card__content_organizationSubtab: this.props.dashboardShown,
      card__content_organizationSubtab_noDashboard: !this.props.dashboardShown,
    });

    return (
      <EntityEditingCard
        key="personas-subtab"
        entityType={I18n.t('entitiesTypes.organization')}
        title={I18n.t('organizations.tabs.personas.title')}
        isEditMode={this.props.isEditMode}
        isThereEditBlock={true}
        isValidEntity={this.isValid}
        handleClickButtonCancel={this.handleClickButtonCancelPersonasEditing}
        handleClickButtonEdit={this.handleClickButtonEditPersonas}
        handleClickButtonSave={this.handleClickButtonSavePersonas}
        customCardClass={cardClass}
        customCardContentClass={contentClass}
        editButtonText={I18n.t('organizations.tabs.personas.editButtonLabel')}
        accessRights={rightAvailabilityMap}
        content={(
          <ContainerWithListAndForm
            list={this.getPersonaListOrMessage()}
            form={this.getContainerItemByModeShowingContainer()}
          />
        )}
      />
    );
  }
}

OrganizationPersonasTab.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  personasInOrganization: PropTypes.array.isRequired,
  personasNotInOrganization: PropTypes.array.isRequired,
  updatedPersonasInOrganization: PropTypes.array,
  dashboardShown: PropTypes.bool.isRequired,

  changePersonasEditMode: PropTypes.func.isRequired,
  removePersonaFromOrganization: PropTypes.func.isRequired,
  getOrganizationPersonas: PropTypes.func.isRequired,
  updateOrganizationPersonas: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  onUpdatePersonas: PropTypes.func,
};

OrganizationPersonasTab.defaultProps = {
  updatedPersonasInOrganization: [],
  onUpdatePersonas: () => {},
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  organization: state.organizationsReducer.selectedOrganization,

  dashboardShown: state.universalDashboardReducer.isShowDashboard,

  isEditMode: state.organizationPersonasTabReducer.isEditMode,
  personasInOrganization: state.organizationPersonasTabReducer.personasInOrganization,
  personasNotInOrganization: state.organizationPersonasTabReducer.personasNotInOrganization,
  updatedPersonasInOrganization: state.organizationPersonasEditFormReducer.personasInOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  changePersonasEditMode: bindActionCreators(changePersonasEditMode, dispatch),
  removePersonaFromOrganization: bindActionCreators(removePersonaFromOrganization, dispatch),
  getOrganizationPersonas: bindActionCreators(getOrganizationPersonas, dispatch),
  updateOrganizationPersonas: bindActionCreators(updateOrganizationPersonas, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(OrganizationPersonasTab)));

import _get from 'lodash/get';

import {get, makeUrl, post} from '../utils/fetcher';

const METHODS = {
  CREATE_TLD_FILTER: 'createTldFilter',
  EDIT_TLD_FILTER: 'editTldFilter',
  GET_TLD_FILTER_BY_ID: 'getTldFilterById',
  GET_TLD_FILTERS_INFO: 'getTldFiltersInfo',
  DELETE_TLD_FILTER: 'deleteTldFilter',
};

export function createTldFilter(data) {
  const url = makeUrl(METHODS.CREATE_TLD_FILTER);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => _get(response, 'data', []))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to create TLD filter:', error);
    });
}

export function editTldFilter(data) {
  const url = makeUrl(METHODS.EDIT_TLD_FILTER);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => _get(response, 'data', []))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to edit TLD filter:', error);
    });
}

export function getTldFiltersInfo() {
  const url = makeUrl(METHODS.GET_TLD_FILTERS_INFO);
  return (dispatch) => get(url, dispatch)
    .then((response) => _get(response, 'data', []));
}

export function getTldListById(filterId) {
  const url = makeUrl(METHODS.GET_TLD_FILTER_BY_ID, {filterId});
  return (dispatch) => get(url, dispatch)
    .then((response) => _get(response, 'data', []));
}

export function deleteTldList(data) {
  const url = makeUrl(METHODS.DELETE_TLD_FILTER);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => _get(response, 'data', []))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to delete TLD filter:', error);
    });
}

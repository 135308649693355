import {
  redColor,
  greenColor,
  blackColor,
} from '../../styles/style';

const style = {
  indicator: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
  indicator_red: {
    backgroundColor: redColor,
  },
  indicator_green: {
    backgroundColor: greenColor,
  },
  indicator_yellow: {
    backgroundColor: '#ffc600',
  },
  indicator_black: {
    backgroundColor: blackColor,
  },
};

export default style;

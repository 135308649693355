const style = {
  clientDetails__header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clientDetails__headerButtons: {
  },
};

export default style;

import _includes from 'lodash/includes';

import {getWhiteBlackLists as getWhiteBlackListsAPI} from '../../api/whiteBlackLists';
import {makeUrl, post} from '../../utils/fetcher';
import {WB_LIST_TYPE} from '../../app-common/Insights/constants';
import {METHODS, TAGS} from '../../constants';

export const types = {
  SET_WHITE_BLACK_LISTS_FOR_ROW_ACTIONS: 'SET_WHITE_BLACK_LISTS_FOR_ROW_ACTIONS',
  SET_WHITE_BLACK_LISTS_REQUEST_STATUS: 'SET_WHITE_BLACK_LISTS_REQUEST_STATUS',
  REQUEST_WB_LISTS: 'REQUEST_WB_LISTS',
};

const ADD_DOMAIN_TO_LIST_METHODS = {
  [WB_LIST_TYPE.ALLOW]: METHODS.ADD_DOMAIN_TO_WHITELIST,
  [WB_LIST_TYPE.BLOCK]: METHODS.ADD_DOMAIN_TO_BLACKLIST,
};

export function getWBLists() {
  return (dispatch) => {
    dispatch({
      type: types.SET_WHITE_BLACK_LISTS_REQUEST_STATUS,
      areRequested: true,
    });
    dispatch(getWhiteBlackListsAPI())
      .then((lists) => {
        const wbLists = lists.map((item) => ({
          id: item.id,
          name: item.name,
          label: item.name,
          isGlobal: _includes(item.tags, TAGS.GLOBAL_WHITE_BLACK_LIST),
        }));
        dispatch({
          type: types.SET_WHITE_BLACK_LISTS_FOR_ROW_ACTIONS,
          wbLists,
        });
      });
  };
}

export function addDomainToWBlist(listType, listId, domainName) {
  const METHOD = ADD_DOMAIN_TO_LIST_METHODS[listType];
  const body = {listId, domainName};
  const url = makeUrl(METHOD);
  return (dispatch) =>
    post(url, body, dispatch)
      .then((response) => {
        if (response && response.success) {
          return true;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to add domain to w/b list:', error);
      });
}

export function requestWbLists() {
  return (dispatch) =>
    dispatch({
      type: types.SET_WHITE_BLACK_LISTS_REQUEST_STATUS,
      areRequested: false,
    });
}

const style = {
  settings: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputsRow: {
    display: 'flex',
    gap: '4px',
  },
};

export default style;

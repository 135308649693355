import initState from '../../../reducers/redux-initial-state';
import {types} from './constants';
import {
  getAllowedBlockedNetworkChartData,
  getNetworksStatsAndRequestsPerSecondStats,
  getValidData,
} from '../../../utils/dashboard';

export default (state = initState.dashboardReducer, action) => {
  switch (action.type) {
    case types.SET_AVERAGE_LATENCY: {
      return {
        ...state,
        averageLatency: parseInt(getValidData(action.data), 10),
      };
    }
    case types.SET_CAMPUSES_STATS: {
      const networksStatsAndRequestsPerSecondsStats = getNetworksStatsAndRequestsPerSecondStats(
        action.data,
        action.campusId,
      );
      return {
        ...state,
        campusesStats: action.data,
        networksStats: networksStatsAndRequestsPerSecondsStats.networksStats,
        requestsPerSecond: networksStatsAndRequestsPerSecondsStats.requestsPerSecond,
      };
    }
    case types.SET_TOTAL_REQUEST_BY_DECISION: {
      return {
        ...state,
        requestsByDecision: {
          ...state.requestsByDecision,
          [action.decision]: getValidData(action.data),
        },
      };
    }
    case types.SET_USER_REQUESTS_GRAPH: {
      return {
        ...state,
        userRequestsGraph: {
          chartData: getAllowedBlockedNetworkChartData(
            action.data,
            action.period,
          ),
        },
      };
    }
    case types.SET_ORGS_AND_SITES_STATS: {
      return {
        ...state,
        orgsAndSitesStats: {
          orgs: action.orgs,
          sites: action.sites,
        },
      };
    }
    default:
      return state;
  }
};

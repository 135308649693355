import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.resetPasswordReducer, action) => {
  switch (action.type) {
    case types.ACTION_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordReset: true,
      };
    }
    case types.ACTION_RESET_PASSWORD_FAILED: {
      return {
        ...state,
        isPasswordReset: false,
      };
    }
    default:
      return state;
  }
};

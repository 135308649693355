import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _includes from 'lodash/includes';
import {connect} from 'react-redux';

import {EntityEditingCard} from '../index';

import {PolicyEditForm} from '../../containers/index';

import {ACCESS_RIGHTS, TAGS} from '../../constants';
import {hasOrgTag} from '../../utils/tags';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.POLICY_EDIT],
  buttonDelete: [ACCESS_RIGHTS.POLICY_DELETE],
};

const rightAvailabilityMapOrgOnly = {
  buttonEdit: [ACCESS_RIGHTS.POLICY_EDIT__ORG],
  buttonDelete: [ACCESS_RIGHTS.POLICY_DELETE__ORG],
};

function PolicyDetails(props) {
  const {
    isEditMode,
    handleClickButtonBack,
    handleClickButtonCancel,
    handleClickButtonDelete,
    handleClickButtonEdit,
    handleClickButtonSave,
    policyInfo,
    isValidPolicyInfo,
  } = props;

  const isReadOnlyPolicy = _includes(policyInfo.tags, TAGS.READ_ONLY_POLICY);
  const organizationPolicy = hasOrgTag(policyInfo.tags);

  let rightAvailabilityMapFinal;
  if (!organizationPolicy) {
    rightAvailabilityMapFinal = rightAvailabilityMap;
  } else {
    rightAvailabilityMapFinal = rightAvailabilityMapOrgOnly;
  }
  return (
    <EntityEditingCard
      title={policyInfo.name}
      entityType={I18n.t('entitiesTypes.policy')}
      isEditMode={isEditMode}
      isTherePathBack={true}
      isThereDeleteBlock={!isReadOnlyPolicy}
      isThereEditBlock={!isReadOnlyPolicy}
      handleClickButtonBack={handleClickButtonBack}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonDelete={handleClickButtonDelete}
      handleClickButtonEdit={handleClickButtonEdit}
      handleClickButtonSave={handleClickButtonSave}
      customCardContentClass="card__content_policy"
      accessRights={rightAvailabilityMapFinal}
      isValidEntity={isValidPolicyInfo}
      content={
        <PolicyEditForm />
      }
    />
  );
}

PolicyDetails.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  policyInfo: PropTypes.object.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidPolicyInfo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isValidPolicyInfo: state.policyEditFormReducer.isValidPolicyInfo,
});

export default connect(mapStateToProps)(PolicyDetails);

import {createStyles} from '@material-ui/core';

const style = createStyles({
  requestsStats: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  requestsStats__graph: {
    flexBasis: '80%',
  },
  requestsStats__graph_campusDashboard: {
    flexBasis: '80%',
  },
  requestsStats__thereIsNotGraphData: {
    margin: '0 auto',
    fontSize: '20px',
    padding: '95px 0 135px 0',
  },
});

export default style;

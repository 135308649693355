import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {withStyles} from '@material-ui/core/styles';

import {ButtonCopy, RenderOrEmpty} from '../index';

import style from './style';

class LabelWithCopyButton extends React.Component {
  render() {
    const {
      text,
      id,
      hideOnEmptyText,
      showHeader,
      showText,
      headerText,
      classes,
      snackbarMessage,
    } = this.props;

    if ((_isNil(text) || _isEmpty(text)) && hideOnEmptyText) {
      return null;
    }

    return (
      <>
        <RenderOrEmpty isShow={showHeader}>
          <p className={classes.labelWithCopyButton__headerText}>{headerText}</p>
        </RenderOrEmpty>
        <div>
          <RenderOrEmpty isShow={showText}>
            <p className={classes.labelWithCopyButton__text}>{text}</p>
          </RenderOrEmpty>
          <ButtonCopy id={id} content={text} snackbarMessage={snackbarMessage} />
        </div>
      </>
    );
  }
}

LabelWithCopyButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hideOnEmptyText: PropTypes.bool,
  showHeader: PropTypes.bool,
  showText: PropTypes.bool,
  headerText: PropTypes.string,
  snackbarMessage: PropTypes.string,
};

LabelWithCopyButton.defaultProps = {
  hideOnEmptyText: false,
  showHeader: false,
  showText: true,
  headerText: '',
  snackbarMessage: null,
};

export default withStyles(style)(LabelWithCopyButton);

import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';

import Tooltip from '@material-ui/core/Tooltip';

import {RenderOrEmpty} from '../index';

const StringArrayTd = (props) => props.data.map((item, index) => (
  <span key={uuid()}>
    {item}
    <RenderOrEmpty isShow={index < props.data.length - 1}>
      <br />
    </RenderOrEmpty>
  </span>
));

function MultilineTd(props) {
  const tooltip = {
    classes: {
      tooltip: props.classes.td__tooltip,
    },
    title: props.data,
    placement: 'bottom',
  };
  return (
    <Tooltip
      classes={tooltip.classes}
      placement={tooltip.placement}
      title={tooltip.title}
    >
      <span>{props.data}</span>
    </Tooltip>
  );
}

MultilineTd.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.string.isRequired,
};

export {
  StringArrayTd,
  MultilineTd,
};

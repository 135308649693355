/* eslint-disable max-len */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import {ExpandMore} from '../../../icons/index';

import accordionStyle from '../../assets/jss/material-dashboard-pro-react/components/accordionStyle';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
    };
  }
  handleChange = (panel) => (event, expanded) => {
    this.setState({
      active: expanded ? panel : -1,
    });
  };
  render() {
    const {classes, collapses} = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => (
          <ExpansionPanel
              expanded={this.state.active === key}
              onChange={this.handleChange(key)}
              key={uuid()}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded,
              }}
          >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                classes={{
                  root: classes.expansionPanelSummary,
                  expanded: classes.expansionPanelSummaryExpaned,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon,
                  expandIconExpanded:
                    classes.expansionPanelSummaryExpandIconExpanded,
                }}
            >
              <h4 className={classes.title}>{prop.title}</h4>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              {prop.content}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

Accordion.defaultProps = {
  active: -1,
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(PropTypes.shape({title: PropTypes.string, content: PropTypes.node})).isRequired,
};

export default withStyles(accordionStyle)(Accordion);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import _isEqual from 'lodash/isEqual';
import {GridContainer, ItemGrid} from '../../material-dashboard-pro-react/components';
import {RenderOrEmpty, TabsPanel} from '../../components';
import {
  getEditableProfileInfo,
  resetProfileEditFormData,
} from './action';
import {STATES_ENTITY} from '../../constants';

class ClientProfileEditForm extends Component {
  static initState = {
    activeTabIndex: 0,
  };

  static shouldUpdateClientProfileObject(prevProps, props) {
    return !_isEqual(prevProps.profile, props.profile);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.profileState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = {...ClientProfileEditForm.initState};
  }

  componentDidMount() {
    this.props.getEditableProfileInfo(this.props.profile);
    this.props.getPolicies(this.props.profile.id);
    this.handleClickTab(this.state.activeTabIndex);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props.profile, nextProps.profile)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableProfileInfo, nextProps.editableProfileInfo)
      || !_isEqual(this.props.policies, nextProps.policies)
      || !_isEqual(this.state.activeTabIndex, nextState.activeTabIndex);
  }

  componentDidUpdate(prevProps) {
    if (ClientProfileEditForm.shouldUpdateClientProfileObject(prevProps, this.props)) {
      this.props.getEditableProfileInfo(this.props.profile);
      this.props.getPolicies(this.props.profile.id);
    }
    if (ClientProfileEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.getEditableProfileInfo(this.props.profile);
    }
  }

  componentWillUnmount() {
    this.props.resetProfileEditFormData();
  }

  handleClickTab = (tabIndex) => {
    this.setState({
      activeTabIndex: tabIndex,
    });
  };

  render() {
    const {activeTabIndex} = this.state;
    const {
      classes,
      tabs,
      getTabContent,
    } = this.props;
    const tabContent = getTabContent(tabs[activeTabIndex].name);

    return (
      <GridContainer direction="column" className={classes.grid}>
        <ItemGrid>
          <TabsPanel
            activeTabIndex={activeTabIndex}
            disabled={false}
            handleClickTab={this.handleClickTab}
            tabs={tabs}
          />
        </ItemGrid>
        <RenderOrEmpty isShow={!!tabContent}>
          <ItemGrid>
            {tabContent}
          </ItemGrid>
        </RenderOrEmpty>
      </GridContainer>
    );
  }
}

ClientProfileEditForm.propTypes = ({
  classes: PropTypes.object,
  isEditMode: PropTypes.bool,
  policies: PropTypes.array,
  profile: PropTypes.object.isRequired,
  editableProfileInfo: PropTypes.object,
  tabs: PropTypes.array,
  getTabContent: PropTypes.func.isRequired,
  getEditableProfileInfo: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  resetProfileEditFormData: PropTypes.func.isRequired,
});

ClientProfileEditForm.defaultProps = ({
  classes: {},
  isEditMode: false,
  editableProfileInfo: {},
  policies: [],
  tabs: [],
});

const mapStateToProps = (state) => ({
  profile: state.clientsConfigurerReducer.selectedProfile,
  profileState: state.clientsConfigurerReducer.profileState,
  isEditMode: state.clientsConfigurerReducer.isEditMode,

  editableProfileInfo: state.clientProfileEditFormReducer.settings.editableProfileInfo,
});

const mapDispatchToProps = (dispatch) => ({
  resetProfileEditFormData: bindActionCreators(resetProfileEditFormData, dispatch),
  getEditableProfileInfo: bindActionCreators(getEditableProfileInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileEditForm);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';
import _findIndex from 'lodash/findIndex';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput, CustomLabel} from '../../material-dashboard-pro-react/components/index';

import {ButtonNew, Dropdown, InputList, RenderOrEmpty} from '../../components/index';

import {
  areAllNotEmpty,
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {
  setEditableLocalForwardingInfo,
  setValidationLocalForwardingInfo,
  resetLocalForwardingEditFormData,
  updateLocalForwardingInfo,
  getSubnets,
} from './action';

import {MAX_LOCAL_DNS_IPS, STATES_ENTITY} from '../../constants';

import style from './style';

class LocalForwardingEditForm extends Component {
  static initialLocalState = {
    validation: {
      domain: true,
      ips: [],
    },
  };

  static shouldUpdateLocalForwardingObject(prevProps, props) {
    return !_isEqual(prevProps.localForwarding, props.localForwarding);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.localForwardingState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = LocalForwardingEditForm.initialLocalState;
  }

  componentDidMount() {
    this.props.setEditableLocalForwardingInfo({
      ...this.props.localForwarding,
      subnetName: I18n.t('campusPage.localForwardingsTab.allLANsOptionLabel'),
      subnetId: null,
    });
    this.props.getSubnets(this.props.campus.id);
    this.resetValidation();
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.localForwarding, nextProps.localForwarding)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableLocalForwardingInfo, nextProps.editableLocalForwardingInfo)
      || !_isEqual(this.props.subnets, nextProps.subnets);
  }

  componentDidUpdate(prevProps) {
    if (LocalForwardingEditForm.shouldUpdateLocalForwardingObject(prevProps, this.props)
      || LocalForwardingEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.setEditableLocalForwardingInfo(this.props.localForwarding);
      this.resetValidation();
    }
    if (LocalForwardingEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetLocalForwardingEditFormData();
    this.resetValidation();
  }

  getSelectedSubnetIndex = () => {
    const {editableLocalForwardingInfo, subnets} = this.props;

    if (_isNull(editableLocalForwardingInfo.subnetId)
      || _isEqual('', editableLocalForwardingInfo.subnetId)) {
      return 0;
    }
    return _findIndex(
      subnets,
      (item) => _isEqual(item.id, editableLocalForwardingInfo.subnetId),
    ) + 1;
  };

  getSubnetsOptionsWithAllLAN = () => [
    I18n.t('campusPage.localForwardingsTab.allLANsOptionLabel'),
    ...this.props.subnets.map((subnet) => subnet.name),
  ];

  resetValidation = () => {
    this.props.setValidationLocalForwardingInfo(true);
    const initValidation = {
      ...LocalForwardingEditForm.initialLocalState.validation,
      ips: Array.from(
        {length: Math.max(1, this.props.localForwarding.ips.length)},
        () => true),
    };
    this.setState({validation: initValidation});
  };

  validate = (data) => validate(
    [
      {
        name: 'domain',
        validationRules: [isNotEmpty],
      },
      {
        name: 'ips',
        type: 'array',
        validationRules: [areAllNotEmpty],
      },
    ],
    data,
    {
      name: true,
      ips: true,
    },
  );

  getIpErrorMessage = (index) => {
    if (!this.state.validation.ips[index]) {
      return I18n.t('campusPage.localForwardingsTab.errorMessages.ipIsNotValid');
    }
    return undefined;
  };

  handleInputChange = (e) => {
    const updatedInfo = {
      ...this.props.editableLocalForwardingInfo,
      [e.target.name]: e.target.value,
    };
    const validationResult = this.validate(updatedInfo);
    this.props.updateLocalForwardingInfo(
      updatedInfo,
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeSubnet = (e) => {
    const {
      editableLocalForwardingInfo,
      subnets,
    } = this.props;
    const index = e.target.value;
    if (index === 0) {
      this.props.updateLocalForwardingInfo(
        {
          ...editableLocalForwardingInfo,
          subnetName: I18n.t('campusPage.localForwardingsTab.allLANsOptionLabel'),
          subnetId: null,
          forAllSubnets: true,
        },
        true,
      );
    } else {
      this.props.updateLocalForwardingInfo(
        {
          ...editableLocalForwardingInfo,
          subnetName: subnets[index - 1].name,
          subnetId: subnets[index - 1].id,
          forAllSubnets: false,
        },
        true,
      );
    }
  };

  handleChangeIp = (e, index) => {
    const {editableLocalForwardingInfo} = this.props;
    const ips = [...editableLocalForwardingInfo.ips];
    ips[index] = e.target.value;
    const updatedInfo = {
      ...editableLocalForwardingInfo,
      ips,
    };

    const validationResult = this.validate(updatedInfo);
    this.props.updateLocalForwardingInfo(
      updatedInfo,
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  addIp = () => {
    const {editableLocalForwardingInfo} = this.props;
    if (editableLocalForwardingInfo.ips.length >= MAX_LOCAL_DNS_IPS) {
      return false;
    }
    const ips = [...editableLocalForwardingInfo.ips, ''];
    this.props.updateLocalForwardingInfo(
      {
        ...editableLocalForwardingInfo,
        ips,
      },
      true,
    );

    const {validation} = this.state;
    const ipsValidation = [...validation.ips, true];
    this.setState((prevState) => ({
      validation: {
        ...prevState.validation,
        ips: ipsValidation,
      },
    }));
  };

  removeIp = (index) => {
    const {editableLocalForwardingInfo} = this.props;
    if (editableLocalForwardingInfo.ips.length === 1) {
      return false;
    }
    const ips = [...editableLocalForwardingInfo.ips];
    ips.splice(index, 1);
    this.props.updateLocalForwardingInfo(
      {
        ...editableLocalForwardingInfo,
        ips,
      },
      true,
    );

    const {validation} = this.state;
    const ipsValidation = [...validation.ips];
    ipsValidation.splice(index, 1);
    this.setState((prevState) => ({
      validation: {
        ...prevState.validation,
        ips: ipsValidation,
      },
    }));
  };

  render() {
    const {
      classes,
      editableLocalForwardingInfo,
      isEditMode,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      domain: {
        disabled: !isEditMode,
        value: editableLocalForwardingInfo.domain,
        name: 'domain',
        onChange: this.handleInputChange,
        type: 'text',
      },
    };
    const propsInputLabel = {
      shrink: true,
    };
    const propsIpInputLabel = {
      shrink: true,
    };
    const propsFormControl = {
      fullWidth: true,
    };
    const ipInputListClasses = {
      label: classes.form__ipsLabel,
      inputs: classes.form__ipsBlock,
      formControl: classes.form__ips,
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <Dropdown
            customSelectClass="dropdown__select_subnetsSettingsTab"
            disabled={!isEditMode}
            dropdownName="subnet"
            fullWidth={true}
            label={I18n.t('campusPage.localForwardingsTab.labels.subnet')}
            onChangeValue={this.handleChangeSubnet}
            options={this.getSubnetsOptionsWithAllLAN()}
            selectedItemIndex={this.getSelectedSubnetIndex()}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.domain
              ? undefined
              : I18n.t('campusPage.localForwardingsTab.errorMessages.domainIsNotValid')}
            error={!validation.domain}
            labelText={I18n.t('campusPage.localForwardingsTab.labels.domain')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.domain}
            labelProps={propsInputLabel}
          />
        </div>
        <div className={classes.form__item}>
          <CustomLabel
            id={uuid()}
            labelText={(
              <span>
                {I18n.t('campusPage.localForwardingsTab.labels.ips')}
                <RenderOrEmpty
                  isShow={isEditMode && editableLocalForwardingInfo.ips.length < MAX_LOCAL_DNS_IPS}
                >
                  <ButtonNew
                    customClasses="cardActions__button"
                    onClick={this.addIp}
                  />
                </RenderOrEmpty>
              </span>
            )}
            labelProps={propsIpInputLabel}
            customClasses={classes.label}
            formControlProps={propsFormControl}
            error={false}
          >
            <InputList
              items={editableLocalForwardingInfo.ips}
              isEditMode={isEditMode}
              classes={ipInputListClasses}
              validation={validation.ips}
              getItemErrorMessage={this.getIpErrorMessage}
              onRemoveItem={this.removeIp}
              onChangeItem={this.handleChangeIp}
            />
          </CustomLabel>
        </div>
      </div>
    );
  }
}

LocalForwardingEditForm.propTypes = {
  campus: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editableLocalForwardingInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  localForwarding: PropTypes.object.isRequired,
  subnets: PropTypes.array.isRequired,

  setEditableLocalForwardingInfo: PropTypes.func.isRequired,
  setValidationLocalForwardingInfo: PropTypes.func.isRequired,
  resetLocalForwardingEditFormData: PropTypes.func.isRequired,
  updateLocalForwardingInfo: PropTypes.func.isRequired,
  getSubnets: PropTypes.func.isRequired,
};

LocalForwardingEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  campus: state.campusReducer.selectedCampus,
  policies: state.policyReducer.policies,
  localForwarding: state.campusLocalForwardingTabReducer.selectedLocalForwarding,
  localForwardingState: state.campusLocalForwardingTabReducer.localForwardingState,
  isEditMode: state.campusLocalForwardingTabReducer.isEditMode,
  editableLocalForwardingInfo: state.localForwardingEditFormReducer.editableLocalForwardingInfo,
  subnets: state.localForwardingEditFormReducer.subnets,
});

const mapDispatchToProps = (dispatch) => ({
  setEditableLocalForwardingInfo: bindActionCreators(setEditableLocalForwardingInfo, dispatch),
  setValidationLocalForwardingInfo: bindActionCreators(setValidationLocalForwardingInfo, dispatch),
  resetLocalForwardingEditFormData: bindActionCreators(resetLocalForwardingEditFormData, dispatch),
  updateLocalForwardingInfo: bindActionCreators(updateLocalForwardingInfo, dispatch),
  getSubnets: bindActionCreators(getSubnets, dispatch),
});

const LocalForwardingEditFormWithStyles = withStyles(style)(LocalForwardingEditForm);
export default connect(mapStateToProps, mapDispatchToProps)(LocalForwardingEditFormWithStyles);

/* eslint-disable max-len */
import headerActionsStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/headerActionsStyle';

import {
  defaultFont,
  primaryColor,
  secondaryColor,
} from '../../styles/style';

const style = (theme) => ({
  ...headerActionsStyle(theme),
  menu: {
    '@media (min-width: 749.95px)': {
      display: 'none !important',
    },
  },
  popper_responsive: {
    zIndex: '1640',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  popper_close: {
    pointerEvents: 'none',
  },
  link__text: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    margin: '0!important',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: '24px',
      margin: '0',
    },
  },
  dropdown: {
    minWidth: '100px',
  },
  dropdown__header: {
    ...defaultFont,
    fontWeight: '400',
    fontSize: '13px',
    color: '#FFF',
    padding: '10px 20px',
    textAlign: 'center',
    backgroundColor: secondaryColor,
  },
  dropdown__item: {
    ...defaultFont,
    'display': 'flex',
    'justifyContent': 'flex-start',
    'alignItems': 'center',
    'fontSize': '13px',
    'padding': '10px 20px',
    'borderRadius': '2px',
    'position': 'relative',
    'transition': 'all 150ms linear',
    'clear': 'both',
    'fontWeight': '400',
    'height': 'fit-content',
    'color': '#333',
    'whiteSpace': 'nowrap',
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
    },
  },
  dropdownItem__icon: {
    marginRight: '5px',
    width: '25px',
    height: '25px',
  },
  action__menuList: {
    padding: 0,
    maxHeight: '500px',
    overflowY: 'auto',
  },
});

export default style;

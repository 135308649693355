import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import {
  GroupStatsCard,
  CardStats,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import style from './style';

function StatusStats(props) {
  const {
    classes,
    networksUp,
    networksDown,
    widgetKey,
  } = props;
  return (
    <GroupStatsCard
        groupName={I18n.t('dashboard.statusStats.title')}
        customCardClassName="card_status"
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.container}>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.statusStats.networksUp')}
                  borderTopColor="green"
                  value={networksUp}
                  valueIcon={<ArrowUpwardRounded fontSize="small" />}
                />
              </div>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.statusStats.networksDown')}
                  borderTopColor="red"
                  value={networksDown}
                  valueIcon={<ArrowDownwardRounded fontSize="small" />}
                />
              </div>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

StatusStats.propTypes = {
  classes: PropTypes.object.isRequired,
  networksUp: PropTypes.number,
  networksDown: PropTypes.number,
  widgetKey: PropTypes.string.isRequired,
};
StatusStats.defaultProps = {
  networksUp: 0,
  networksDown: 0,
};

export default withStyles(style)(StatusStats);

import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import {ORGANIZATION_OPERATOR_PERMISSIONS} from '../../constants';

export const getNames = (array) => (
  array.map((item) => item.name)
);

export const getAvailableOrganizations = (
  allOrganizations,
  usedOrganizationIds,
  currentOrganizationId,
) => allOrganizations.filter((org) => (
  !usedOrganizationIds[org.id]
      || (!_isNil(currentOrganizationId) && _isEqual(org.id, currentOrganizationId))
));

export const getOrganizationsData = (tableRows) => {
  const permissions = Object.keys(ORGANIZATION_OPERATOR_PERMISSIONS);
  return tableRows.map((row) => {
    const organizationId = _get(row.availableOrganizations[row.selectedOrganizationIndex], 'id');
    const permission = permissions[row.selectedPermissionIndex];
    if (_isNil(organizationId)) {return null;}
    return {
      organizationId,
      permission,
    };
  }).filter((org) => !_isNil(org));
};

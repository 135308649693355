import React from 'react';
import PropTypes from 'prop-types';

import {
  ExpandLess,
  ExpandMore,
} from '../../icons/index';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import {RenderOrEmpty} from '../index';

function ButtonExpandLessMore(props) {
  const {
    color,
    text,
    isExpandLess,
    customClasses,
    onClick,
  } = props;

  return (
    <CustomButton
      color={color}
      customClasses={customClasses}
      onClick={onClick}
    >
      {text}
      <RenderOrEmpty isShow={isExpandLess}>
        <ExpandLess />
      </RenderOrEmpty>
      <RenderOrEmpty isShow={!isExpandLess}>
        <ExpandMore />
      </RenderOrEmpty>
    </CustomButton>
  );
}

ButtonExpandLessMore.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  isExpandLess: PropTypes.bool,
  customClasses: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ButtonExpandLessMore.defaultProps = {
  color: 'secondaryNoBackground',
  text: '',
  customClasses: '',
  isExpandLess: true,
};

export default ButtonExpandLessMore;

import _get from 'lodash/get';
import {
  getDefaultPermissionByRole,
  getRightsByRoleAndPermission,
} from '../../constants';

export default function checkAvailability(rightsList, currentOrganization, account) {
  // define permission by role if no organization is chosen
  const role = account.role;
  const currentUserPermission = _get(
    currentOrganization,
    'permission',
    getDefaultPermissionByRole(role),
  );
  const userRightsList = getRightsByRoleAndPermission(role, currentUserPermission);

  return (userRightsList != null && rightsList.every((right) => userRightsList.includes(right)));
}

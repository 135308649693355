import {
  dangerColor,
  successColor,
  infoColor,
  defaultFont,
} from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';

const style = {
  header: {
    color: '#a9adae',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '1.5em',
  },
  description: {
    fontWeight: '300',
    fontSize: '17px',
    lineHeight: '1.063rem',
    marginTop: '2px',
  },
  description_green: {
    color: successColor,
  },
  description_red: {
    color: dangerColor,
  },
  description_blue: {
    color: infoColor,
  },
  tableData: {
    padding: '5px 0',
  },
  tableData_newSection: {
    paddingLeft: '25px',
  },
  campusDashboard: {

  },
  campusDashboard__controlPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  campusDashboard__controlPanel_dashboardIsHidden: {
    justifyContent: 'flex-start',
    height: '48px',
  },
  controlPanel__item_dropdown: {
  },
  controlPanel__item_header: {
    ...defaultFont,
    margin: '0',
    fontSize: '17px',
    color: infoColor,
  },
  campusDashboard__statsPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  statsPanel__block: {
    marginBottom: '10px',
  },
  statsPanel__block_left: {
    flex: '1 0 29%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '1%',
    gap: '10px',
  },
  statsPanel__block_right: {
    flex: '0 0 70%',
  },
  statsPanel__block_bottom: {
    display: 'none',
    flexBasis: '100%',
  },
  statsPanel__item_graph: {
    flexBasis: '100%',
  },
  statsPanel__item: {
    flexBasis: '33.5%',
  },
  statsPanel__card: {
    flex: '1',
  },
};

export default style;

import {types} from './constants';
import {getEditableLocalForwardingInfo} from '../ClientProfileLocalForwardingForm/utils';

export function resetLocalForwardingEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CLIENT_LOCAL_FORWARDING_EDIT_FORM_DATA,
    });
  };
}

export function updateLocalForwardingInfo(updatedLocalForwardingInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CLIENT_LOCAL_FORWARDING_INFO,
      localForwarding: getEditableLocalForwardingInfo(updatedLocalForwardingInfo),
      isValidLocalForwardingInfo: isValid,
    });
  };
}

export function setValidationResultLocalForwardingInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_CLIENT_LOCAL_FORWARDING_INFO,
      isValid,
    });
  };
}

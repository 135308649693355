import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';

import {EntityCreatingCard} from '../index';
import {PolicyEditForm} from '../../containers/index';

function PolicyCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
    isValidPolicyInfo,
  } = props;

  return (
    <EntityCreatingCard
      title={I18n.t('policyPage.createPolicyTitle')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      customCardContentClass="card__content_policy"
      isValidEntity={isValidPolicyInfo}
      content={(
        <PolicyEditForm />
      )}
    />
  );
}

PolicyCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidPolicyInfo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isValidPolicyInfo: state.policyEditFormReducer.isValidPolicyInfo,
});

export default connect(mapStateToProps)(PolicyCreating);

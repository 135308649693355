export const types = {
  SET_EDITABLE_ORGANIZATION_INFO: 'SET_EDITABLE_ORGANIZATION_INFO',
  RESET_ORGANIZATION_EDIT_FORM_DATA: 'RESET_ORGANIZATION_EDIT_FORM_DATA',
  SET_VALIDATION_ORGANIZATION_INFO: 'SET_VALIDATION_ORGANIZATION_INFO',
  UPDATE_ORGANIZATION_INFO: 'UPDATE_ORGANIZATION_INFO',
  SET_ORGANIZATION_SELECTED_PERIOD_INDEX: 'SET_ORGANIZATION_SELECTED_PERIOD_INDEX',
  SET_ORGANIZATION_STATS: 'SET_ORGANIZATION_STATS',
  SET_ORGANIZATION_THREATS: 'SET_ORGANIZATION_THREATS',
  SET_ORGANIZATION_AVERAGE_LATENCY: 'SET_ORGANIZATION_AVERAGE_LATENCY',
  SET_ORGANIZATION_REQUESTS_GRAPH: 'SET_ORGANIZATION_REQUESTS_GRAPH',
};

export const DESCRIPTION_ROW_COUNT = 5;
export const DESCRIPTION_MAX_LEN = 1000;

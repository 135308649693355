const style = {
  settings: {
    display: 'flex',
    flexDirection: 'column',
  },
  settings__templateSection: {
  },
  settings__baseSection: {
  },
  settings__advancedSection: {
  },
  advancedSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  advancedSection__item: {
    flexBasis: '49%',
    width: '0px', // to value of multi-drop-down doesn't broke form structure
  },
  baseSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  baseSection__item: {
    flexBasis: '49%',
    width: '0px', // to value of drop-downs doesn't broke form structure
  },

  settings__globalWbListIcon: {
    marginRight: '0.5em',
  },
};

export default style;

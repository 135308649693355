import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_RELAY_BINARIES: 'SET_RELAY_BINARIES',
};

export function getRelayBinaries() {
  const url = makeUrl(METHODS.GET_RELAY_BINARIES);
  return (dispatch) => {
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_RELAY_BINARIES,
            builds: response.data.builds,
            networkAgentAllowed: response.data.networkAgentAllowed,
          });
        }
      });
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {
  getEditableRelayInfo,
  resetRelayEditFormData,
  updateRelayInfo,
} from './action';
import {
  adoptRelay,
  deleteRelay,
} from '../CampusRelaysTab/action';

import {RELAY_STATUSES, STATES_ENTITY} from '../../constants';

import style from './style';
import RelayStatusControl from '../../components/RelayStatusControl/RelayStatusControl';

const formControlProps = {
  fullWidth: true,
};

class RelayEditForm extends Component {
  static shouldUpdateRelayObject(prevProps, props) {
    return !_isEqual(prevProps.selectedRelayTableRowData, props.selectedRelayTableRowData);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.editMode, props.editMode)
      && _isEqual(props.relayState, STATES_ENTITY.EDITING_CANCELED);
  }

  componentDidMount() {
    this.props.getEditableRelayInfo(this.props.selectedRelayTableRowData);
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(
      this.props.selectedRelayTableRowData,
      nextProps.selectedRelayTableRowData,
    )
      || !_isEqual(this.props.editMode, nextProps.editMode)
      || !_isEqual(this.props.editableRelayInfo, nextProps.editableRelayInfo);
  }

  componentDidUpdate(prevProps) {
    if (RelayEditForm.shouldUpdateRelayObject(prevProps, this.props)
      || RelayEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.getEditableRelayInfo(this.props.selectedRelayTableRowData);
    }
  }

  componentWillUnmount() {
    this.props.resetRelayEditFormData();
  }

  getInputProps = (fieldName) => ({
    disabled: true,
    value: this.props.editableRelayInfo[fieldName] || '—',
    name: fieldName,
    type: 'text',
  });

  // handleCheckboxChange = (e, checked) => {
  //   const {editableRelayInfo} = this.props;
  //
  //   this.props.updateRelayInfo(
  //     {
  //       ...editableRelayInfo,
  //       [e.target.name]: checked,
  //     },
  //     true,
  //   );
  // };

  handleAdoptRelay = () => {
    const {editableRelayInfo} = this.props;

    this.props.adoptRelay(editableRelayInfo.id);
    this.props.updateRelayInfo(
      {
        ...editableRelayInfo,
        adopted: true,
      },
      true,
    );
  };

  handleForgetRelay = () => {
    this.props.deleteRelay(this.props.editableRelayInfo.id);
    this.props.closeForm();
  };

  render() {
    const {classes, editableRelayInfo} = this.props;

    return (
      <div className={classes.form}>
        <div>
          <CustomInput
            labelText={I18n.t('campusPage.relaysTab.labels.id')}
            id={uuid()}
            formControlProps={formControlProps}
            inputProps={this.getInputProps('id')}
          />
        </div>
        <div>
          <CustomInput
            labelText={I18n.t('campusPage.relaysTab.labels.network')}
            id={uuid()}
            formControlProps={formControlProps}
            inputProps={this.getInputProps('network')}
          />
        </div>
        <div>
          <CustomInput
            labelText={I18n.t('campusPage.relaysTab.labels.version')}
            id={uuid()}
            inputProps={this.getInputProps('version')}
          />
        </div>
        <div>
          <RelayStatusControl
            relayId={editableRelayInfo.id}
            editableRelayInfo={editableRelayInfo}
            label={I18n.t('campusPage.relaysTab.labels.actions')}
            adoptRelay={this.handleAdoptRelay}
            forgetRelay={this.handleForgetRelay}
          />
        </div>
        <div>
          <CustomInput
            labelText={I18n.t('campusPage.relaysTab.labels.lastConfigUpdatingAt')}
            id={uuid()}
            formControlProps={formControlProps}
            inputProps={this.getInputProps('lastConfigUpdatingAt')}
          />
        </div>
      </div>
    );
  }
}

RelayEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  selectedRelayTableRowData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    lastConfigUpdatingAt: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(RELAY_STATUSES)).isRequired,
  }).isRequired,

  // This prop is used in 'shouldResetFormData' helper function only,
  // so ESLint thinks that it is unused:
  // eslint-disable-next-line react/no-unused-prop-types
  relayState: PropTypes.string.isRequired,

  editableRelayInfo: PropTypes.object.isRequired,

  getEditableRelayInfo: PropTypes.func.isRequired,
  resetRelayEditFormData: PropTypes.func.isRequired,
  updateRelayInfo: PropTypes.func.isRequired,
  deleteRelay: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  adoptRelay: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editMode: state.campusRelaysTabReducer.editMode,
  selectedRelayTableRowData: state.campusRelaysTabReducer.selectedRelayTableRowData,
  relayState: state.campusRelaysTabReducer.relayState,

  editableRelayInfo: state.relayEditFormReducer.editableRelayInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getEditableRelayInfo: bindActionCreators(getEditableRelayInfo, dispatch),
  resetRelayEditFormData: bindActionCreators(resetRelayEditFormData, dispatch),
  updateRelayInfo: bindActionCreators(updateRelayInfo, dispatch),
  adoptRelay: bindActionCreators(adoptRelay, dispatch),
  deleteRelay: bindActionCreators(deleteRelay, dispatch),
});

const RelayEditFormWithStyles = withStyles(style)(RelayEditForm);

export default connect(mapStateToProps, mapDispatchToProps)(RelayEditFormWithStyles);

import {createSelector} from 'reselect';
import _get from 'lodash/get';
import _set from 'lodash/set';

import {DEFAULT_SEARCH_OPTIONS, FILTERS, TABS, DOMAIN_TYPES} from './constants';

const filtersState = (state) => state.insightsReducer.filters;
const areFiltersDisabledState = (state) => state.insightsReducer.areFiltersDisabled;
const searchOptionsState = (state) => state.insightsReducer.searchOptions;
const filtersData = (state) => state.insightsReducer.filtersData;
const dataState = (state) => state.insightsReducer.data;
const componentsState = (state) => state.insightsReducer.components;
const selectedRecordsState = (state) => state.insightsReducer.selectedRecords;
const logState = (state) => state.insightsLogsReducer;
const site = (state) => state.campusReducer.campuses;
const policy = (state) => state.policyReducer.policies;
const network = (state) => state.networkReducer.networks;
const profile = (state) => state.clientsConfigurerReducer.profiles;
const client = (state) => state.clientsManagerReducer.clients;

export const getCategories = createSelector(
  filtersData,
  (fd) => _get(fd, 'categories', {}),
);

export const getTldTypes = createSelector(
  filtersData,
  (fd) => _get(fd, 'tldTypes', {}),
);

export const getTabFilters = (tab) => createSelector(
  filtersState,
  site,
  policy,
  network,
  profile,
  client,
  // eslint-disable-next-line no-shadow
  (filtersState, site, policy, network, profile, client) => {
    const filters = {..._get(filtersState, tab, {})};
    _set(filters, `${FILTERS.SITE}.options`, site);
    _set(filters, `${FILTERS.POLICY}.options`, policy);
    _set(filters, `${FILTERS.NETWORK}.options`, network);
    _set(filters, `${FILTERS.PROFILE}.options`, profile);
    _set(filters, `${FILTERS.CLIENT}.options`, client);
    return filters;
  },
);

export const getTabSearchOptions = (tab) => createSelector(
  searchOptionsState,
  (so) => _get(so, tab, {...DEFAULT_SEARCH_OPTIONS}),
);

export const getAreFiltersDisabled = (tab) => createSelector(
  areFiltersDisabledState,
  (afd) => _get(afd, tab, false),
);

export const getInsightsData = (tab) => createSelector(
  dataState,
  (d) => _get(d, tab, []),
);

export const getInsightsComponents = (tab) => createSelector(
  componentsState,
  (c) => _get(c, tab, []),
);

export const getInsightsSelectedRecords = (tab) => createSelector(
  selectedRecordsState,
  (sr) => _get(sr, tab, {}),
);

export const getInsightsDomainsData = (tab, domainType, filterData) => createSelector(
  logState,
  dataState,
  (ls, ds) => {
    if (tab === TABS.DOMAIN) {
      if (filterData !== undefined && filterData.parentDomain !== null) {
        return _get(ls, 'fqdns', []);
      }
      return _get(ds, `${tab}.${domainType}`, []);
    }
    return _get(ls, domainType === DOMAIN_TYPES.FQDN ? 'fqdns' : 'domains', []);
  },
);

import _cloneDeep from 'lodash/cloneDeep';

export function cloneNetworkInfo(network) {
  const newNetwork = _cloneDeep(network);
  newNetwork.ipAddressOrDomainName = getValidIpAddressOrDomainName(
    newNetwork.ip,
    newNetwork.domainName,
  );
  return newNetwork;
}

function getValidIpAddressOrDomainName(ip, domainName) {
  if (domainName) {
    return domainName;
  }
  if (ip.length > 0) {
    return ip[0];
  }
  return '';
}

export const types = {
  SET_PROFILES: 'LOAD_PROFILES',
  SET_SEARCH_OPTION: 'SET_SEARCH_OPTION',
  SET_CLIENTS: 'SET_CLIENTS',
  ADD_CLIENTS: 'ADD_CLIENTS',
  RESET_CLIENT_MANAGER_DATA: 'RESET_CLIENT_MANAGER_DATA',
  RESET_CLIENT_CONFIGURER_DATA: 'RESET_CLIENT_CONFIGURER_DATA',
};

export const ROAMING_LABEL = 'ROAMING';

export const TABS = {
  MANAGE: 'manage',
  CONFIGURE: 'configure',
};

export const DEVICE_AGENT_STATUS_FILTERS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const ON_SITE_STATUSES = {
  ONSITE: 'ONSITE',
  ROAMING: 'ROAMING',
};

export const CLIENT_ACTIONS = {
  DISABLE: 'DISABLE',
  UNINSTALL: 'UNINSTALL',
  FORGET: 'FORGET',
  ENABLE: 'ENABLE',
};

export const LIMIT_LOAD_DATA = 100;

export const MIN_TABLE_ROWS = 10;

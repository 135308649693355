import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import {
  Manager,
  Popper,
  Target,
} from 'react-popper';

import {withStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {ButtonExpandLessMore} from '../index';

import style from './style';

class AccountSwitcherHeaderAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      accounts: [],
      currentAccount: {
        accountName: '',
      },
    };
  }

  componentDidMount() {
    this.updateAccountsInLocalStore();
  }

  componentDidUpdate(prevProps) {
    if (
      !_isEqual(prevProps.userAccounts, this.props.userAccounts)
      || !_isEqual(prevProps.loggedAccount, this.props.loggedAccount)
    ) {
      this.updateAccountsInLocalStore();
    }
  }

  updateAccountsInLocalStore = () => {
    const {
      loggedAccount,
      userAccounts,
    } = this.props;
    const MAX_ACCOUNT_NAME_LENGTH = 20;

    const currentAccount = _cloneDeep(userAccounts.filter((account) =>
      _isEqual(account.accountId, loggedAccount.accountId))[0]);

    if (currentAccount) {
      if (currentAccount.accountName.length > MAX_ACCOUNT_NAME_LENGTH) {
        currentAccount.accountName = `${currentAccount.accountName.substr(0, 20)}...`;
      }

      this.setState({
        accounts: userAccounts,
        currentAccount: currentAccount,
      });
    }
  };

  searchAccounts = (e) => {
    const {userAccounts} = this.props;

    const searchText = e.target.value.toLowerCase();
    if (searchText !== '') {
      this.setState({
        accounts: userAccounts.filter((account) =>
          account.accountName.toLowerCase().includes(searchText)),
      });
    } else {
      this.setState({
        accounts: userAccounts,
      });
    }
  };

  handleSwitchAccount = (account) => {
    const p = Promise.resolve();
    p.then(() => this.props.switchAccount(account)).then(() => {
      this.setState({currentAccount: account});
    });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  renderAccountsList = () => {
    const {
      classes,
      loggedAccount,
    } = this.props;

    const {accounts} = this.state;

    return accounts.map((account) => {
      const isLoggedAccount = loggedAccount.accountId === account.accountId;
      return (
        <MenuItem
          className={classNames(
            classes.dropdown__item,
            {
              [classes.dropdown_item_loggedInAccount]: isLoggedAccount,
            },
          )}
          key={uuid()}
          onClick={() => this.handleSwitchAccount(account)}
        >
          <div
            className={classes.dropdownItem__accountName}
            title={account.accountName}
          >
            {account.accountName}
          </div>
        </MenuItem>
      );
    });
  };

  render() {
    const {
      classes,
      placement,
    } = this.props;
    const {
      currentAccount,
      isOpen,
    } = this.state;

    const managerStyle = {display: 'inline-block'};
    const growStyle = {transformOrigin: '0 0 0'};
    const searchInputProps = {
      formControl: {
        fullWidth: true,
      },
      input: {
        placeholder: 'Search',
        onChange: this.searchAccounts,
      },
    };

    const buttonExpandLessMoreStyle = {
      header: {
        color: 'primaryNoBackground',
        customClasses: 'accountSwitcher__showHideAccountListButton_header',
      },
      sidebar: {
        color: 'whiteNoBackground',
        customClasses: 'accountSwitcher__showHideAccountListButton_sidebar',
      },
    };

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <Manager style={managerStyle}>
          <Target>
            <ButtonExpandLessMore
              color={buttonExpandLessMoreStyle[placement].color}
              customClasses={classNames(
                'accountSwitcher__showHideAccountListButton',
                [buttonExpandLessMoreStyle[placement].customClasses],
              )}
              isExpandLess={isOpen}
              onClick={this.handleClick}
              text={currentAccount.accountName}
            />
          </Target>
          <Popper
            placement="bottom-start"
            eventsEnabled={isOpen}
            className={classNames(
              classes.popper_responsive,
              {
                [classes.popper_close]: !isOpen,
              },
            )}
          >
            <Grow
              in={isOpen}
              id={uuid()}
              style={growStyle}
            >
              <Paper className={classes.dropdown}>
                <div
                  className={classNames(
                    classes.dropdown,
                    classes.dropdown__header,
                    classes.dropdown__header_accountSwitcher,
                  )}
                >
                  <CustomInput
                    formControlProps={searchInputProps.formControl}
                    inputProps={searchInputProps.input}
                  />
                </div>
                <MenuList
                  className={classes.action__menuList}
                  role="menu"
                >
                  {this.renderAccountsList()}
                </MenuList>
              </Paper>
            </Grow>
          </Popper>
        </Manager>
      </ClickAwayListener>
    );
  }
}

AccountSwitcherHeaderAction.propTypes = {
  classes: PropTypes.object.isRequired,
  placement: PropTypes.oneOf(['header', 'sidebar']),
  loggedAccount: PropTypes.object.isRequired,
  switchAccount: PropTypes.func.isRequired,
  userAccounts: PropTypes.array.isRequired,
};

AccountSwitcherHeaderAction.defaultProps = {
  placement: 'header',
};
export default withStyles(style)(AccountSwitcherHeaderAction);

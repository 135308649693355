import React from 'react';
import {Provider} from 'react-redux';
import {Redirect, Route, Router, Switch} from 'react-router-dom';

import {PAGES, ROUTES} from '../constants';

import {
  Campus,
  Organizations,
  Log,
  BlockPage,
  // LogAndAuditReports,
  Login,
  NoAccounts,
  Policy,
  // RootCertificate,
  // ResetPassword,
  Subscription,
  // UpdatePassword,
  CustomLists,
  AccessManagement,
  ApiAccessManagement,
  NotificationsManagement,
  Profile,
  // ConfirmEmail,
  // UnsubscribeEmailFromNotification,
  NoData,
  // Insights,
  // DownloadRelay,
} from '../app-common/index';

import {
  Dashboard,
} from './app/index';

import {HasAccess} from '../containers';

import {
  OperatorLayout,
  UnauthorizedLayout,
} from './layouts/index';

import '../material-dashboard-pro-react/assets/scss/material-dashboard-pro-react.css';

import {isLoggedIn} from '../utils/jwt';
import {hasAccount} from '../utils/account';

import browserHistory from '../history';

const renderOperatorLayout = (App, componentName, props) => {
  if (isLoggedIn()) {
    if (hasAccount()) {
      if (props != null) {
        return (
          <OperatorLayout>
            <HasAccess componentName={componentName}>
              <App {...props} />
            </HasAccess>
          </OperatorLayout>
        );
      }
      return (
        <OperatorLayout>
          <HasAccess componentName={componentName}>
            <App />
          </HasAccess>
        </OperatorLayout>
      );
    }
    return <Redirect to={ROUTES.NO_ACCOUNTS} />;
  }
  return <Redirect to={ROUTES.LOGIN} />;
};

const renderUnauthorizedLayout = (App) => {
  if (isLoggedIn()) {
    return <Redirect to={ROUTES.DEFAULT} />;
  }
  return <UnauthorizedLayout><App /></UnauthorizedLayout>;
};

const renderNoAccountsLayout = (App) => {
  if (isLoggedIn()) {
    if (hasAccount()) {
      return <Redirect to={ROUTES.DEFAULT} />;
    }
    return <UnauthorizedLayout><App /></UnauthorizedLayout>;
  }
  return <Redirect to={ROUTES.LOGIN} />;
};

export const getApplication = (store) => (
  <Provider store={store}>
    <Router history={browserHistory}>
      <Switch>
        <Route
          exact={true}
          path={ROUTES.LOGIN}
          render={() => renderUnauthorizedLayout(Login)}
        />
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.RESET_PASSWORD} */}
        {/*  render={() => renderUnauthorizedLayout(ResetPassword)} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.UPDATE_PASSWORD} */}
        {/*  render={() => <UnauthorizedLayout><UpdatePassword/></UnauthorizedLayout>} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={false} */}
        {/*  path={ROUTES.CONFIRM_EMAIL} */}
        {/*  render={() => <UnauthorizedLayout><ConfirmEmail/></UnauthorizedLayout>} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={false} */}
        {/*  path={ROUTES.UNSUBSCRIBE_EMAIL_FROM_NOTIFICATION} */}
        {/*  render={() => ( */}
        {/*    <UnauthorizedLayout> */}
        {/*      <UnsubscribeEmailFromNotification/> */}
        {/*    </UnauthorizedLayout> */}
        {/*  )} */}
        {/* /> */}
        <Route
          exact={true}
          path={ROUTES.NO_ACCOUNTS}
          render={() => renderNoAccountsLayout(NoAccounts)}
        />
        <Route
          exact={true}
          path={ROUTES.DASHBOARD}
          render={() => renderOperatorLayout(Dashboard, 'Dashboard')}
        />
        <Route
          exact={true}
          path={ROUTES.CAMPUS}
          render={() => renderOperatorLayout(Campus)}
        />
        <Route
          exact={true}
          path={ROUTES.BLOCK_PAGE}
          render={() => renderOperatorLayout(BlockPage)}
        />
        <Route
          exact={true}
          path={ROUTES.POLICY}
          render={() => renderOperatorLayout(Policy)}
        />
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.WHITE_BLACK_LIST} */}
        {/*  render={() => renderOperatorLayout(CustomLists)} */}
        {/* /> */}
        <Route
          exact={true}
          path={ROUTES.CUSTOM_LISTS}
          render={() => renderOperatorLayout(CustomLists)}
        />
        <Route
          exact={true}
          path={ROUTES.LOG}
          render={() => renderOperatorLayout(Log, 'Log')}
        />
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.LOG_AUDIT} */}
        {/*  render={(props) => renderOperatorLayout(LogAndAuditReports, 'Log', props)} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.IPs_LIST} */}
        {/*  render={() => renderOperatorLayout(IPsList)} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.ROOT_CERTIFICATE} */}
        {/*  render={() => renderOperatorLayout(RootCertificate)} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.DOWNLOAD_RELAY} */}
        {/*  render={() => renderOperatorLayout(DownloadRelay)} */}
        {/* /> */}
        <Route
          exact={true}
          path={ROUTES.SUBSCRIPTION}
          render={() => renderOperatorLayout(Subscription, PAGES.SUBSCRIPTION)}
        />
        <Route
          exact={true}
          path={ROUTES.ACCESS_MANAGEMENT}
          render={() => renderOperatorLayout(AccessManagement, PAGES.ACCESS_MANAGEMENT)}
        />
        <Route
          exact={true}
          path={ROUTES.API_ACCESS_MANAGEMENT}
          render={() => renderOperatorLayout(ApiAccessManagement, PAGES.API_ACCESS_MANAGEMENT)}
        />
        <Route
          exact={true}
          path={ROUTES.PROFILE}
          render={() => renderOperatorLayout(Profile)}
        />
        <Route
          exact={true}
          path={ROUTES.NOTIFICATIONS_MANAGEMENT}
          render={() =>
            renderOperatorLayout(NotificationsManagement, PAGES.NOTIFICATION_MANAGEMENT)}
        />
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.INSIGHTS} */}
        {/*  render={() => renderOperatorLayout(Insights)} */}
        {/* /> */}
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.CLIENTS} */}
        {/*  render={() => renderOperatorLayout(Clients)} */}
        {/* /> */}
        <Route
          exact={true}
          path={ROUTES.ORGANIZATIONS}
          render={() => renderOperatorLayout(Organizations, PAGES.ORGANIZATIONS)}
        />
        {/* <Route */}
        {/*  exact={true} */}
        {/*  path={ROUTES.PRINT_RESPONSE} */}
        {/*  render={() => <ResponsePrint/>} */}
        {/* /> */}
        <Route
          exact={true}
          path={ROUTES.CANCELLED}
          render={() => renderOperatorLayout(NoData, PAGES.CANCELLED)}
        />
        <Redirect to={ROUTES.DASHBOARD} />
        {/* <Redirect to={ROUTES.LOG_AUDIT} /> */}
      </Switch>
    </Router>
  </Provider>
);

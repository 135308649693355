import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _isNull from 'lodash/isNull';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import CreatableSelect from 'react-select/creatable';
import Select, {components} from 'react-select';

import customAutocompleteStyle
  // eslint-disable-next-line max-len
  from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customAutocompleteStyle';

import {ArrowDropDown} from '../../icons';

import styles from './style';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
}

function Autocomplete({...props}) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    helpText,
    errorText,
    isDisabled,
    isCreatable,
    closeMenuOnSelect,
  } = props;

  let labelClasses = cx({
    [` ${classes.labelRootError}`]: error,
    [` ${classes.labelRootSuccess}`]: success && !error,
  });

  let formControlClasses = classes.formControl;

  if (!_isNull(_get(formControlProps, 'className', null))) {
    formControlClasses += ` ${formControlProps.className}`;
  }
  if (inputProps !== undefined) {
    formControlClasses = `${formControlClasses
    } ${
      cx({
        [classes.inputWithAdornment]:
          (inputProps.startAdornment !== undefined
            || inputProps.endAdornment !== undefined)
          && labelText === undefined,
      })
    }`;
    const formCustomClassName = _get(formControlProps, 'className', null);
    if (!_isNull(formCustomClassName)) {
      formControlClasses = cx(formControlClasses, classes[formCustomClassName]);
    }
  }
  if (inputProps !== undefined) {
    labelClasses = `${labelClasses
    } ${
      cx({
        [classes.labelWithAdornment]: inputProps.endAdornment !== undefined,
      })}`;
  }
  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      aria-describedby={`${id}-text`}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      {isCreatable && (
        <CreatableSelect
        {...inputProps}
        id={id}
        isDisabled={isDisabled || false}
        components={{DropdownIndicator}}
        styles={styles(errorText, labelText)}
        closeMenuOnSelect={closeMenuOnSelect}
        />
      )}
      {!isCreatable && (
        <Select
        {...inputProps}
        id={id}
        isDisabled={isDisabled || false}
        components={{DropdownIndicator}}
        styles={styles(errorText, labelText)}
        closeMenuOnSelect={closeMenuOnSelect}
        />
      )}
      {helpText !== undefined ? (
        <FormHelperText id={`${id}-text`}>{helpText}</FormHelperText>
      ) : null}
      {errorText !== undefined ? (
        <FormHelperText error={true} id={`${id}-error-text`}>{errorText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
};

Autocomplete.defaultProps = {
  closeMenuOnSelect: false,
  labelText: '',
  labelProps: {},
  formControlProps: {},
  id: '',
  inputProps: {},
  error: false,
  success: true,
  helpText: '',
  errorText: '',
  isDisabled: false,
  isCreatable: false,
};

export default withStyles(customAutocompleteStyle)(Autocomplete);

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  getSubnetToCreate,
  getSubnetToUpdate,
} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';

export const types = {
  CHANGE_EDIT_MODE_AND_SUBNET_STATE: 'CHANGE_EDIT_MODE_AND_SUBNET_STATE',
  SET_CAMPUS_SUBNETS: 'SET_CAMPUS_SUBNETS',
  SET_SUBNETS: 'SET_SUBNETS',
  SET_SUBNET_TABLE_DATA: 'SET_SUBNET_TABLE_DATA',
  SET_SELECTED_SUBNET: 'SET_SELECTED_SUBNET',
  SET_SELECTED_SUBNET_INDEX: 'SET_SELECTED_SUBNET_INDEX',
  SET_UPDATED_SUBNET: 'SET_UPDATED_SUBNET',
  RESET_SELECTED_SUBNET_INDEX: 'RESET_SELECTED_SUBNET_INDEX',
};

export function getSubnetById(subnetId) {
  const url = makeUrl(METHODS.GET_SUBNET_BY_ID, {id: subnetId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_SELECTED_SUBNET,
            data,
          });
          dispatch({
            type: types.SET_SUBNET_TABLE_DATA,
          });
        }
      });
}

export function createSubnet(newSubnet, campusId) {
  const url = makeUrl(METHODS.CREATE_SUBNET);
  const data = getSubnetToCreate(newSubnet, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndSubnetState(false, STATES_ENTITY.NONE));
          dispatch(getSubnets(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create subnet:', error);
      });
}

export function updateSubnet(updatedSubnet, campusId) {
  const url = makeUrl(METHODS.UPDATE_SUBNET);
  const data = getSubnetToUpdate(updatedSubnet, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndSubnetState(false, STATES_ENTITY.NONE));
          dispatch(getSubnets(campusId));
          dispatch({
            type: types.SET_SELECTED_SUBNET,
            data: response.data,
          });
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit subnet:', error);
      });
}

export function deleteSubnet(subnetId, campusId) {
  const data = {
    id: subnetId,
  };

  const url = makeUrl(METHODS.DELETE_SUBNET);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(getSubnets(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete subnet:', error);
      });
}

export function getDataToShowSubnetDetails(subnetId, subnetIndex) {
  return (dispatch) => {
    dispatch(getSubnetById(subnetId));
    dispatch(setSelectedSubnetIndex(subnetIndex));
  };
}

export function getSubnets(campusId) {
  const url = makeUrl(METHODS.GET_SUBNETS, {locationId: campusId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_SUBNETS,
            data,
          });
        }
      });
}

export function setSubnetTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_SUBNET_TABLE_DATA,
    });
  };
}

export function changeEditModeAndSubnetState(isEditMode, subnetState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_SUBNET_STATE,
      isEditMode,
      subnetState,
    });
  };
}

export function setSelectedSubnetIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_SUBNET_INDEX,
      selectedSubnetIndex: index,
    });
  };
}

export function resetSelectedSubnetIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_SUBNET_INDEX,
    });
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {TableWithPaginationAndFiltration} from '../index';
import style from './style';

class ClientInfoTable extends Component {
  render() {
    const {classes, customClasses, columns, tableData, title, getTdProps} = this.props;
    const cardHeaderStyle = {
      root: classes.clientInfoTableCard__header,
      title: classes.clientInfoTableCard__title,
    };
    return (
      <Card className={classes.clientInfoTableCard}>
        <CardHeader
          classes={cardHeaderStyle}
          title={title}
        />
        <CardContent className={classes.clientInfoTableCard__content}>
          <TableWithPaginationAndFiltration
            columns={columns}
            data={tableData}
            showPagination={false}
            defaultPageSize={tableData.length}
            pageSize={tableData.length}
            getTdProps={getTdProps}
            customClasses={{
              tbody: customClasses.tableBody,
            }}
          />
        </CardContent>
      </Card>
    );
  }
}

ClientInfoTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  getTdProps: PropTypes.func,
};

ClientInfoTable.defaultProps = {
  title: undefined,
  tableData: [],
  getTdProps: undefined,
  customClasses: {},
};

export default withStyles(style)(ClientInfoTable);

import {getOrDefault} from '../../utils/common';
import {formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift} from '../../utils/dateTime';

export const getSessionData = (data) => data.map((item) => ({
  ...item,
  networkIp: getOrDefault(item, 'networkIp', '-'),
  subnetIp: getOrDefault(item, 'subnetIp', '-'),
  locationName: getOrDefault(item, 'locationName', 'ROAMING'),
  startTime: formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift(
    getOrDefault(item, 'sessionStartAt', '')),
}));

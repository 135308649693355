import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import {timestampMilliToUserDate} from '../../utils/dateTime';

export const getClientsTableData = (data, roamingLabel) => data.map((item) => ({
  ...item,
  lastSync: timestampMilliToUserDate(item.lastSyncAt),
  site: (_isNil(item.site)) ? roamingLabel : item.site,
  wanIP: (_isNil(item.wanIP)) ? '-' : item.wanIP,
  lanIP: (_isNil(item.lanIP)) ? '-' : item.lanIP,
  expireDate: (_isNil(item.expireDate)) ? '-' : item.expireDate,
  link: (_isNil(item.link)) ? '-' : item.link,
}));

export const getClientDataToEdit = (client, roamingLabel = 'ROAMING') => ({
  ...client,
  lastSyncAt: timestampMilliToUserDate(client.lastSyncAt),
  lastLocationName: (_isNil(client.lastLocationName) || _isEmpty(client.lastLocationName))
    ? roamingLabel
    : client.lastLocationName,
});

export const getUpdatedClientIndex = (updatedClientId, clients) =>
  _findIndex(clients, (client) => _isEqual(client.id, updatedClientId));

import {
  post,
  makeUrl,
} from '../../utils/fetcher';

import {
  removeJWT,
  removeRefresh,
  isSSO,
  clearLocalStorage,
} from '../../utils/jwt';

import {
  removeLoggedAccount,
} from '../../utils/account';

import {
  METHODS,
  ROUTES,
} from '../../constants';

import {types} from './actionTypes';

export function logout(browserHistory) {
  const url = makeUrl(METHODS.DISABLE_TOKEN);
  const data = {};
  return (dispatch) => {
    post(url, data, dispatch)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Logout API error:', error);
      });
    removeJWT();
    removeRefresh();
    removeLoggedAccount();
    dispatch(logoutSuccess());
    if (isSSO()) {
      browserHistory.push(ROUTES.LOGOUT_SSO);
      clearLocalStorage();
    } else browserHistory.push(ROUTES.LOGIN);
    return null;
  };
}

export function logoutSuccess() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_LOGOUT_SUCCESS,
    });
  };
}

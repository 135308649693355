import React from 'react';
import PropTypes from 'prop-types';
import _pullAt from 'lodash/pullAt';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {updateEditOrganizationPersonas} from './action';
import {insertIntoSortedArray} from '../../utils/array';
import {SelectableEntityVerticalPairedList} from '../../components/index';

class OrganizationPersonasEditForm extends React.Component {
  componentDidMount() {
    this.props.updateEditOrganizationPersonas(
      this.props.personasTabPersonasInOrganization,
      this.props.personasTabPersonasNotInOrganization,
    );
  }

  moveToInOrganization = (indices) => {
    const personasInOrganization = this.props.personasInOrganization;
    const personasNotInOrganization = this.props.personasNotInOrganization;
    const personaLists = this.moveItemsBetweenLists(
      personasNotInOrganization,
      personasInOrganization,
      indices,
    );
    this.props.updateEditOrganizationPersonas(personaLists.destination, personaLists.source);
  };

  moveToNotInOrganization = (indices) => {
    const personasInOrganization = this.props.personasInOrganization;
    const personasNotInOrganization = this.props.personasNotInOrganization;
    const personaLists = this.moveItemsBetweenLists(
      personasInOrganization,
      personasNotInOrganization,
      indices,
    );
    this.props.updateEditOrganizationPersonas(personaLists.source, personaLists.destination);
  };

  moveItemsBetweenLists = (source, destination, indices) => {
    const newSource = [...source];
    const itemsToMove = _pullAt(newSource, indices);
    const newDestination = insertIntoSortedArray(destination, itemsToMove, 'ranking');
    return {
      source: newSource,
      destination: newDestination,
    };
  };

  render() {
    const {
      isEditMode,
      personasInOrganization,
      personasNotInOrganization,
    } = this.props;

    return (
      <SelectableEntityVerticalPairedList
          handleMoveFromLeftToRightList={this.moveToInOrganization}
          handleMoveFromRightToLeftList={this.moveToNotInOrganization}
          isEditMode={isEditMode}
          leftList={personasNotInOrganization}
          rightList={personasInOrganization}
          titleLeft={I18n.t('organizations.tabs.personas.notInOrganizationLabel')}
          titleRight={I18n.t('organizations.tabs.personas.inOrganizationLabel')}
      />

    );
  }
}

OrganizationPersonasEditForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  personasNotInOrganization: PropTypes.array.isRequired,
  personasInOrganization: PropTypes.array.isRequired,
  personasTabPersonasInOrganization: PropTypes.array.isRequired,
  personasTabPersonasNotInOrganization: PropTypes.array.isRequired,
  updateEditOrganizationPersonas: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  personasTabPersonasInOrganization: state.organizationPersonasTabReducer.personasInOrganization,
  personasTabPersonasNotInOrganization:
    state.organizationPersonasTabReducer.personasNotInOrganization,
  isEditMode: state.organizationPersonasTabReducer.isEditMode,
  personasInOrganization: state.organizationPersonasEditFormReducer.personasInOrganization,
  personasNotInOrganization: state.organizationPersonasEditFormReducer.personasNotInOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditOrganizationPersonas: bindActionCreators(updateEditOrganizationPersonas, dispatch),
});

// eslint-disable-next-line max-len
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationPersonasEditForm));

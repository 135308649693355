import {get, makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_OPERATORS: 'SET_OPERATORS',
  SET_EDITING_MODE: 'SET_ACCESS_MANAGEMENT_EDITING_MODE',
  RESET_STATE: 'RESET_ACCESS_MANAGEMENT_STATE',
};

export function getOperators() {
  const url = makeUrl(METHODS.GET_OPERATORS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_OPERATORS,
          data,
        });
      }
    });
}

export function revokeOperatorAccess(operatorId) {
  const url = makeUrl(METHODS.REVOKE_OPERATOR_ACCESS);
  const data = {id: operatorId};
  return (dispatch) => post(url, data, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Deleting of operator failed', error);
    });
}

export function updateOperator(accountId, operatorId, role, operatorOrganizations) {
  const url = makeUrl(METHODS.UPDATE_OPERATOR);
  const data = {
    accountId,
    operatorId,
    role,
    organizations: operatorOrganizations,
  };
  return (dispatch) => post(url, data, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Updating of operator role failed', error);
    });
}

export function createOperator(operatorInfo) {
  const url = makeUrl(METHODS.CREATE_OPERATOR);
  const data = {...operatorInfo};
  return (dispatch) => post(url, data, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Adding of operator failed', error);
    });
}

export function setEditingMode(isEditingMode) {
  return (dispatch) => dispatch({
    type: types.SET_EDITING_MODE,
    isEditingMode,
  });
}

export function resetState() {
  return (dispatch) => dispatch({
    type: types.RESET_STATE,
  });
}

export function enableDisableMFA(enableMfa) {
  const url = makeUrl(METHODS.MANAGE_MFA);
  return (dispatch) => post(url, enableMfa, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Toggling MFA access failed', error);
    });
}

export function assignSuperAdmin(data) {
  const url = makeUrl(METHODS.ASSIGN_SUPER_ADMIN);
  return (dispatch) => post(url, data, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Toggling MFA access failed', error);
    });
}

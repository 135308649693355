import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {CardWithHeader} from '../../components';
import {RelayBinariesList} from '../../containers';

import style from './style';

function DownloadRelay(props) {
  const {classes} = props;
  return (
    <CardWithHeader
      cardTitle={I18n.t('downloadRelayPage.title')}
      cardTitleColor="primary"
      content={(
        <div className={classes.downloadRelay}>
          <p className={classes.downloadRelay__text}>
            {I18n.t('downloadRelayPage.text')}
          </p>
          <RelayBinariesList />
        </div>
      )}
    />
  );
}

DownloadRelay.propTypes = {
  classes: PropTypes.object.isRequired,
};

DownloadRelay.defaultProps = {};

export default connect(null, null)((withStyles)(style)(DownloadRelay));

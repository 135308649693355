import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.apiAccessManagementReducer, action) => {
  switch (action.type) {
    case types.SET_API_KEYS: {
      return {
        ...state,
        apiKeys: action.data,
      };
    }
    case types.SET_API_KEYS_EDITING_MODE: {
      return {
        ...state,
        isEditingMode: action.isEditingMode,
      };
    }
    case types.RESET_API_KEYS_STATE: {
      return {...initState.apiAccessManagementReducer};
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import _isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {I18n} from 'react-redux-i18n';
import {ButtonAdopt, ButtonForget, DeleteEntityModalWindow, RenderOrEmpty} from '../index';

import style from './style';
import {ACCESS_RIGHTS} from '../../constants';
import {RightAvailability} from '../../containers';

const rightAvailabilityMap = {
  buttonAdopt: [ACCESS_RIGHTS.SITE_RELAYS_ADOPT],
  buttonForget: [ACCESS_RIGHTS.SITE_RELAYS_FORGET],
  buttonForm: [ACCESS_RIGHTS.SITE_RELAYS_FORGET, ACCESS_RIGHTS.SITE_RELAYS_ADOPT],
};

class RelayStatusControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalWindowOpened: false,
    };
  }

  handleAdoptButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.adoptRelay();
  };

  handleForgetButtonClick = (e) => {
    e.stopPropagation();
    this.setState({
      isModalWindowOpened: true,
    });
  };

  handleCancelDelete = () => {
    this.setState({
      isModalWindowOpened: false,
    });
  };

  handleConfirmDelete = () => {
    this.props.forgetRelay();
    this.setState({
      isModalWindowOpened: false,
    });
  };

  render() {
    const {
      classes,
      label,
      labelFormClassName,
      labelClassName,
      editableRelayInfo,
    } = this.props;
    const labelFormClasses = {
      root: classNames(
        classes.formControl,
        {
          [classes[labelFormClassName]]: !_isUndefined(labelFormClassName),
        },
      ),
      label: classNames(
        classes.relayStatusControl__label,
        {
          [classes[labelClassName]]: !_isUndefined(labelClassName),
        },
      ),
    };

    const ControlComponent = (
      <div>
        <RenderOrEmpty isShow={!editableRelayInfo.adopted}>
          <RightAvailability accessRights={rightAvailabilityMap.buttonAdopt}>
            <ButtonAdopt
              onClick={this.handleAdoptButtonClick}
              customClasses="relay-edit-form-button"
            />
          </RightAvailability>
        </RenderOrEmpty>
        <RightAvailability accessRights={rightAvailabilityMap.buttonForget}>
          <ButtonForget
            onClick={this.handleForgetButtonClick}
            customClasses="relay-edit-form-button"
          />
        </RightAvailability>
      </div>
    );

    return (
      <>
        <RenderOrEmpty isShow={!_isUndefined(label)}>
          <RightAvailability accessRights={rightAvailabilityMap.buttonForm}>
            <FormControlLabel
              control={ControlComponent}
              label={label}
              classes={labelFormClasses}
            />
          </RightAvailability>
        </RenderOrEmpty>
        <RenderOrEmpty isShow={this.state.isModalWindowOpened}>
          <DeleteEntityModalWindow
            text={I18n.t('modalWindow.forgetRelay')}
            cancel={this.handleCancelDelete}
            confirm={this.handleConfirmDelete}
          />
        </RenderOrEmpty>
      </>
    );
  }
}

RelayStatusControl.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelFormClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  editableRelayInfo: PropTypes.object.isRequired,
  adoptRelay: PropTypes.func.isRequired,
  forgetRelay: PropTypes.func.isRequired,
};

RelayStatusControl.defaultProps = {
  label: '',
  labelFormClassName: undefined,
  labelClassName: undefined,
  disabled: false,
};

export default withStyles(style)(RelayStatusControl);

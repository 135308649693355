import initState from '../../reducers/redux-initial-state';
import {types} from './constants';
import {getUpdatedBundleIndex} from './utils';

export default (state = initState.clientProfileInstallFormReducer, action) => {
  switch (action.type) {
    case types.SET_BUNDLES: {
      return {
        ...state,
        bundles: action.bundles,
      };
    }
    case types.SET_SELECTED_CLIENT_BUNDLE: {
      return {
        ...state,
        selectedBundle: action.bundle,
      };
    }
    case types.SET_UPDATED_CLIENT_BUNDLE: {
      return {
        ...state,
        selectedBundleIndex: getUpdatedBundleIndex(action.bundle.id, state.bundles),
        selectedBundle: action.bundle,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_CLIENT_BUNDLE_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        bundleState: action.bundleState,
      };
    }
    case types.SET_SELECTED_CLIENT_BUNDLE_INDEX: {
      return {
        ...state,
        selectedBundleIndex: action.selectedBundleIndex,
      };
    }
    case types.RESET_SELECTED_CLIENT_BUNDLE_INDEX: {
      return {
        ...state,
        selectedBundle: initState.clientProfileInstallFormReducer.selectedBundle,
        selectedBundleIndex: initState.clientProfileInstallFormReducer.selectedBundleIndex,
      };
    }

    default: {
      return state;
    }
  }
};

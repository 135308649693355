const style = {
  labelWithCopyButton__container: {
  },
  labelWithCopyButton__text: {
    display: 'inline-block',
    margin: 0,
    padding: 0,
  },
  labelWithCopyButton__button: {
    display: 'inline-block',
  },
  labelWithCopyButton__headerText: {
    margin: 0,
    padding: 0,
  },
};

export default style;

const style = {
  categoriesTab: {
  },
  categoriesTab__block: {
  },
  block__heading: {

  },
  block__content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  block__item: {
    alignItems: 'baseline',
    display: 'flex',
    flexBasis: '49%',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
};

export default style;

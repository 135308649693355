import React from 'react';
import {I18n} from 'react-redux-i18n';

import EntityEditingCard from '../EntityEditingCard/EntityEditingCard';
import {ACCESS_RIGHTS} from '../../constants';
import UserEditForm from '../../containers/UserEditForm/UserEditForm';
import {ADUserDetails} from '../../app-common/Users/types';
import UserActions from '../UserActions/UserActions';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.USER_EDIT],
};

type UserDetailsProps = {
  user: ADUserDetails,
  handleClickButtonBack: () => void,
  handleClickButtonCancel: () => void,
  handleClickButtonEdit: () => void,
  handleClickButtonSave: () => void,
  isEditMode: boolean,
  isValidUserInfo: boolean,
};

function UserDetails({
  user,
  handleClickButtonBack,
  handleClickButtonCancel,
  handleClickButtonEdit,
  handleClickButtonSave,
}: UserDetailsProps) {
  return (
    <EntityEditingCard
      title={user.name}
      entityType={I18n.t('entitiesTypes.user')}
      isThereDeleteBlock={false}
      isTherePathBack={true}
      isThereEditBlock={false} // TODO: set true when edit user will be implemented
      isEditMode={false}
      isFullscreen={false}
      handleClickButtonBack={handleClickButtonBack}
      handleClickButtonEdit={handleClickButtonEdit}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      customCardContentClass="card__content_persona"
      customCardClass="card__persona"
      // additionalActions={(
      //   <UserActions
      //     user={user}
      //     handleBlock={() => {}}
      //     handleDisableAgents={() => {}}
      //   />
      // )} // TODO: blocking and disabling
      accessRights={rightAvailabilityMap}
      content={(
        <UserEditForm
          userInfo={user}
          isEditMode={false}
        />
      )}
    />
  );
}

export default UserDetails;

import _sortBy from 'lodash/sortBy';
import initState from '../../reducers/redux-initial-state';

import {types} from './constants';

export default (state = initState.organizationPersonasTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_PERSONAS_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    }
    case types.SET_ORGANIZATION_PERSONAS: {
      return {
        ...state,
        personasNotInOrganization: action.data.personasNotInOrganization,
        personasInOrganization: action.data.personasInOrganization,
      };
    }
    case types.REMOVE_PERSONA_FROM_ORGANIZATION: {
      const newPersonasInOrganization = [...state.personasInOrganization];
      const removedPersona = newPersonasInOrganization.splice(action.personaIndex, 1);

      return {
        ...state,
        personasInOrganization: newPersonasInOrganization,
        personasNotInOrganization: _sortBy(
          [...state.personasNotInOrganization, ...removedPersona],
          (persona) => persona.name,
        ),
      };
    }
    case types.UPDATE_ORGANIZATION_PERSONAS: {
      return {
        ...state,
        personasInOrganization: [...action.personasInOrganization],
        personasNotInOrganization: [...action.personasNotInOrganization],
      };
    }

    default:
      return state;
  }
};

import {
  defaultFont,
  secondaryColor,
} from '../../styles/style';

const style = {
  rootCertificate: {
    ...defaultFont,
    padding: '10px 0',
    fontSize: '16px',
  },
  rootCertificate__text: {},
  rootCertificate__text_link: {
    'color': `${secondaryColor}!important`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityCreatingCard} from '../index';

import {CampusEditForm} from '../../containers/index';

function CampusCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
    isValidCampusInfo,
  } = props;

  return (
    <EntityCreatingCard
      title={I18n.t('campusPage.createCampus')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidCampusInfo}
      customCardContentClass="card__content_campus"
      content={
        <CampusEditForm />
      }
    />
  );
}

CampusCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidCampusInfo: PropTypes.bool.isRequired,
};

export default CampusCreating;

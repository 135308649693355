export const types = {
  SET_PROFILES: 'SET_PROFILES',
  SET_PLATFORMS: 'SET_PLATFORMS',
  SET_INSTALLERS: 'SET_INSTALLERS',
  RESET_CLIENT_CONFIGURER_DATA: 'RESET_CLIENT_CONFIGURER_DATA',
  SET_SELECTED_CLIENT_PROFILE: 'SET_SELECTED_CLIENT_PROFILE',
  SET_SELECTED_CLIENT_PROFILE_COPY: 'SET_SELECTED_CLIENT_PROFILE_COPY',
  SET_UPDATED_CLIENT_PROFILE: 'SET_UPDATED_CLIENT_PROFILE',
  CHANGE_EDIT_MODE_AND_CLIENT_PROFILE_STATE: 'CHANGE_EDIT_MODE_AND_CLIENT_PROFILE_STATE',
  SET_SELECTED_CLIENT_PROFILE_INDEX: 'SET_SELECTED_CLIENT_PROFILE_INDEX',
  RESET_SELECTED_CLIENT_PROFILE_INDEX: 'RESET_SELECTED_CLIENT_PROFILE_INDEX',
  SET_CLIENT_PROFILE_POLICIES: 'SET_CLIENT_PROFILE_POLICIES',
};

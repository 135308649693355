import {
  secondaryColor,
} from '../../styles/style';

const style = {
  relayBinariesTable__text_link: {
    'color': `${secondaryColor}!important`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export default style;

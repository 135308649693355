import initState from '../../reducers/redux-initial-state';
import {types} from './actionTypes';

export default (state = initState.networkReducer, action) => {
  switch (action.type) {
    case types.SET_NETWORKS: {
      return {
        ...state,
        networks: action.data,
      };
    }
    default:
      return state;
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import {I18n} from 'react-redux-i18n';

import _isEqual from 'lodash/isEqual';
import {ContainerWithListAndForm, TldListTable} from '../../components';
import {TldListForm} from '..';

import {getTldListsInfo, resetSelectedTldFilter} from './action';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {MODAL_WINDOW_NOTIFY_TYPE} from '../../constants';

class TldLists extends Component {
  static INIT_STATE = {selectedListIndex: -1, isNewList: false, isEditing: false};

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = {...TldLists.INIT_STATE};
  }

  componentDidMount() {
    this.props.getTldListsInfo();
  }

  componentDidUpdate(prevProps) {
    if (TldLists.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetState();
      this.props.getTldListsInfo();
    }
  }

  get isListSelected() {
    return this.state.selectedListIndex > -1;
  }

  get isCompressed() {
    return this.isListSelected || this.state.isNewList;
  }

  get selectedListId() {
    return _get(this.props.tldFiltersInfo, [this.state.selectedListIndex, 'id'], null);
  }

  get form() {
    if (this.isCompressed) {
      return (
        <TldListForm
          isNew={this.state.isNewList}
          isEditing={this.state.isEditing}
          selectedListId={this.selectedListId}
          setEditing={this.setEditing}
          cancelEditing={this.unsetEditing}
          resetSelection={this.resetState}
          updatingCallback={this.updatingCallback}
          deletingCallback={this.deletingCallback}
        />
      );
    }
    return null;
  }

  getListIndex = (id) => this.props.tldFiltersInfo.findIndex((filter) => filter.id === id);

  setEditing = () => this.setState({isEditing: true});

  unsetEditing = () => this.setState({isEditing: false});

  handleClickNew = () => {
    const {
      tldFiltersInfo,
      accountInfo,
    } = this.props;
    if (tldFiltersInfo.length < accountInfo.maxTldFilters) {
      this.setState({isNewList: true});
    } else {
      this.props.openModalWindow(
        I18n.t('tldListsPage.newList.errors.maxListsReached'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      );
    }
  };

  selectList = (selectedListIndex) => this.setState({selectedListIndex});

  resetState = () => {
    this.props.resetSelectedTldFilter();
    this.setState({...TldLists.INIT_STATE});
  };

  updatingCallback = (listId) => {
    this.props.getTldListsInfo().then(() => this.setState({
      isEditing: false,
      isNewList: false,
      selectedListIndex: this.getListIndex(listId),
    }));
  };

  deletingCallback = () => {
    this.props.getTldListsInfo();
    this.resetState();
  };

  render() {
    return (
      <ContainerWithListAndForm
        list={(
          <TldListTable lists={this.props.tldFiltersInfo}
                        handleClickRow={this.selectList}
                        isCompressed={this.isCompressed}
                        isEditing={this.state.isEditing}
                        selectedListIndex={this.state.selectedListIndex}
                        handleClickNew={this.handleClickNew}
          />
        )}
        form={this.form}
      />
    );
  }
}

TldLists.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  tldFiltersInfo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  getTldListsInfo: PropTypes.func.isRequired,
  resetSelectedTldFilter: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
};

TldLists.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  tldFiltersInfo: state.tldListsReducer.tldFiltersInfo,
  accountInfo: state.operatorLayoutReducer.accountInfo,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

const mapDispatchToProps = {
  getTldListsInfo,
  resetSelectedTldFilter,
  openModalWindow,
};

export default connect(mapStateToProps, mapDispatchToProps)(TldLists);

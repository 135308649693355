import {post, get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_OLD_FIRST_AND_LAST_NAME: 'SET_OLD_FIRST_AND_LAST_NAME',
};

export function updateFirstAndLastName(firstName, lastName) {
  const url = makeUrl(METHODS.UPDATE_FIRST_AND_LAST_NAME);
  const data = {firstName, lastName};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Updating of first and last name failed', error);
      });
}

export function getFirstAndLastName() {
  const url = makeUrl(METHODS.GET_FIRST_AND_LAST_NAME);
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const {firstName, lastName} = response.data;
          dispatch({
            type: types.SET_OLD_FIRST_AND_LAST_NAME,
            firstName,
            lastName,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Getting first and last name failed', error);
      });
}

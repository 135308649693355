import {types} from '../actions/userOrganizations/actionTypes';
import initState from './redux-initial-state';
import {convertUserOrganization, convertUserOrganizations} from './utils';

export default (state = initState.userOrganizationsReducer, action) => {
  switch (action.type) {
    case types.SET_USER_ORGANIZATIONS: {
      return {
        ...state,
        userOrganizations: convertUserOrganizations(action.data), // TODO: sort it?
      };
    }
    case types.SET_USER_ORGANIZATION: {
      return {
        ...state,
        currentOrganization: convertUserOrganization(action.data),
      };
    }

    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import CustomInput from '../../material-dashboard-pro-react/components/CustomInput/CustomInput';

import {filterTypes} from './constants';
import {Dropdown, RenderDivOrEmpty} from '../../components';

import {reinitFilters, updateFilter} from './action';
import {getOrDefault} from '../../utils/common';

import style from './style';

class Filters extends React.Component {
  initValueGetterByType = {
    [filterTypes.INPUT]: (filter) => getOrDefault(filter, 'initValue', ''),
    [filterTypes.DROPDOWN]: (filter) => _get(filter, 'initIndex', -1),
  };

  componentDidMount() {
    this.props.reinitFilters(this.initFilterValues);
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.filtersMetadata, this.props.filtersMetadata)) {
      this.props.reinitFilters(this.initFilterValues);
    }
  }

  get initFilterValues() {
    const values = {};
    this.props.filtersMetadata.forEach(
      (filter) => {
        const valueGetter = this.initValueGetterByType[filter.type];
        if (_isNil(valueGetter)) {
          // eslint-disable-next-line no-console
          console.error('Unable to get init value getter for the filter', filter);
        }
        values[filter.name] = valueGetter(filter);
      },
    );
    return values;
  }

  handleChangeFilterValue = (metadata, value) => {
    this.props.updateFilter(metadata.name, value);
    if (metadata.onChange) {
      metadata.onChange(value);
    }
  };

  renderInputFilter = (metadata) => {
    const {classes} = this.props;
    const {name, label, labelProps, disabled} = metadata;
    const inputProps = {
      name: name,
      value: this.props.filters[name],
      type: 'text',
      onChange: (e) => this.handleChangeFilterValue(metadata, e.target.value),
    };
    const formControlProps = {
      fullWidth: true,
      ...metadata.formControlProps,
    };

    return (
      <div className={classes.filterItem}>
        <CustomInput
          key={`filter-input-${name}`}
          labelText={label}
          id={uuid()}
          formControlProps={formControlProps}
          inputProps={inputProps}
          labelProps={labelProps}
          disabled={disabled}
        />
      </div>
    );
  };

  renderDropdownFilter = (metadata) => {
    const {classes} = this.props;
    const {options, name, disabled} = metadata;
    const selectedIndex = _get(this.props.filters, name, 0);
    const dropdownClasses = {
      dropdown__menu: classes.dropdown,
    };

    return (
      <div
        key={`filter-dropdown-${name}`}
        className={classes.filterItem}
      >
        <Dropdown
          classes={dropdownClasses}
          options={options}
          selectedItemIndex={selectedIndex}
          onChangeValue={(e) => this.handleChangeFilterValue(metadata, e.target.value)}
          customSelectClass="dropdown__select_filter"
          customFormControlClass="dropdown__formControl_filter"
          disabled={disabled}
        />
      </div>
    );
  };

  rendererByType = {
    [filterTypes.INPUT]: this.renderInputFilter,
    [filterTypes.DROPDOWN]: this.renderDropdownFilter,
  };

  renderFilters = () => this.props.filtersMetadata.map((filter) => {
    const renderer = this.rendererByType[filter.type];
    if (_isNil(renderer)) {
      // eslint-disable-next-line no-console
      console.error('Unable to get renderer for the filter', filter);
    }
    return renderer(filter);
  });

  render() {
    const {classes, isShowFilters, filtersMetadata} = this.props;
    return (
      <RenderDivOrEmpty
        className={classes.filters}
        isShow={isShowFilters && !_isEmpty(filtersMetadata)}
      >
        {this.renderFilters()}
      </RenderDivOrEmpty>
    );
  }
}

Filters.propTypes = {
  classes: PropTypes.object,
  isShowFilters: PropTypes.bool,
  filtersMetadata: PropTypes.array,
  filters: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  reinitFilters: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  classes: {},
  isShowFilters: true,
  filtersMetadata: [],
};

const mapStateToProps = (state) => ({
  filters: state.filtersReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: bindActionCreators(updateFilter, dispatch),
  reinitFilters: bindActionCreators(reinitFilters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Filters));

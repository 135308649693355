import initState from '../../reducers/redux-initial-state';

import {cloneCampusInfo} from './service';

import {types} from './action';

export default (state = initState.campusEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_CAMPUS_INFO: {
      return {
        ...state,
        editableCampusInfo: cloneCampusInfo(action.data),
      };
    }
    case types.SET_VALIDATION_CAMPUS_INFO: {
      return {
        ...state,
        isValidCampusInfo: action.isValid,
      };
    }
    case types.RESET_CAMPUS_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.campusEditFormReducer,
      };
    }
    case types.UPDATE_CAMPUS_INFO: {
      return {
        ...state,
        editableCampusInfo: action.data,
        isValidCampusInfo: action.isValidCampusInfo,
      };
    }
    default:
      return state;
  }
};

const style = {
  unsubscribeEmailFromNotification: {
    textAlign: 'center',
  },
  unsubscribeEmailFromNotification__text: {
    margin: '10px 0 20px 0',
  },
};

export default style;

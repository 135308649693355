export const types = {
  CHANGE_PROFILES_EDIT_MODE: 'CHANGE_PROFILES_EDIT_MODE',
  REMOVE_PROFILE_FROM_ORGANIZATION: 'REMOVE_PROFILE_FROM_ORGANIZATION',
  SET_ORGANIZATION_PROFILES: 'SET_ORGANIZATION_PROFILES',
  SET_ORGANIZATION_PROFILE_STATS: 'SET_ORGANIZATION_PROFILE_STATS',
  UPDATE_ORGANIZATION_PROFILES: 'UPDATE_ORGANIZATION_PROFILES',
};

export const widgetKeys = {
  ORGANIZATION_PROFILES_STATS: 'organizations_profileStats',
};

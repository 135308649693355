import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export const getClientProfilesData = (data) => data.map((item) => ({
  ...item,
  clientStats: item.agentStats,
  defaultPolicy: item.defaultPolicyName,
  blockPage: item.blockPageName,
}));

export const getProfileToCreate = (newProfile, localDomains) => {
  const newData = {
    ...newProfile,
    localDomains: localDomains.map((item) => ({
      profileId: '',
      domain: item.domain,
      resolverIps: item.resolverIps,
      useDeviceResolvers: item.useDeviceResolvers,
    })),
  };
  delete newData.id;
  if (!newData.hasDynamicPolicies) {
    newData.onsitePolicyId = null;
    newData.offsitePolicyId = null;
    newData.onsitePolicySiteDefault = false;
    newData.onsitePolicyProfileDefault = true;
    newData.offsitePolicyProfileDefault = true;
  }
  return newData;
};

export const getUpdatedProfileIndex = (updatedProfileId, profiles) =>
  _findIndex(profiles, (profile) => _isEqual(profile.id, updatedProfileId));

export const getPlatforms = (platformsData) => {
  const architectureById = {};
  platformsData.architectures.forEach((arch) => {
    architectureById[arch.id] = arch;
  });
  return platformsData.platforms.map((platform) => ({
    id: platform.id,
    name: platform.name,
    iconKey: platform.iconKey,
    architectures: platform.architectureIds.map((archId) => ({
      id: archId,
      name: architectureById[archId].name,
    })),
  }));
};

export function getDataToAssignBlockPageToProfile(blockPageId, profileId) {
  return {
    id: blockPageId,
    profileId: profileId,
  };
}

import PropTypes from 'prop-types';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import {CardWithHeader} from '../index';
import style from './style';

function PoliciesAssignedTable({classes, policies, isGlobal}) {
  let contentForRendering;
  if (isGlobal) {
    contentForRendering = (
      <p className={classNames(classes.policiesAssignedTable__row, classes.policiesAssignedTable__row_italic)}>
        {I18n.t('customListsPage.policiesAssignedTable.allPolicies')}
      </p>
    );
  } else if (policies.length > 0) {
    contentForRendering = policies.map((policy) => (
      <div className={classes.policiesAssignedTable__row} key={policy.id}>
        {policy.name}
      </div>
    ));
  } else {
    contentForRendering = (
      <div className={classes.policiesAssignedTable__noPoliciesFound}>
        {I18n.t('customListsPage.policiesAssignedTable.noPoliciesFound')}
      </div>
    );
  }
  return (
    <CardWithHeader
      cardTitle={I18n.t('customListsPage.policiesAssignedTable.title')}
      cardTitleColor="primary"
      customCardClasses="card_policiesAssignedTable"
      customCardContentClasses="card__content_policiesAssignedTable"
      content={contentForRendering}
    />
  );
}

PoliciesAssignedTable.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  classes: PropTypes.object.isRequired,
  isGlobal: PropTypes.bool,
};

PoliciesAssignedTable.defaultProps = {
  isGlobal: false,
};

export default withStyles(style)(PoliciesAssignedTable);

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {types, widgetKeys} from './constants';
import {getOrganizationSitesData, getSiteIds} from './service';

export function getOrganizationSites(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_LOCATIONS, {organizationId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_SITES,
            data: getOrganizationSitesData(data),
          });
        }
      });
}

export function getOrganizationSiteStats(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_LOCATION_STATS, {organizationId});

  return (dispatch) =>
    get(url, dispatch, true, widgetKeys.ORGANIZATION_SITES_STATS)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_SITE_STATS,
            data,
          });
        }
      });
}

export function getOrganizationNetworkStats(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_NETWORK_STATS_BY_LOCATION, {organizationId});
  return (dispatch) =>
    get(url, dispatch, true, widgetKeys.ORGANIZATION_NETWORK_STATS)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_SITE_NETWORKS_STATS,
            data,
          });
        }
      });
}

export function removeSiteFromOrganization(siteIndex, organizationId, siteId) {
  const url = makeUrl(METHODS.REMOVE_SITE_FROM_ORGANIZATION);
  const data = {
    locationId: siteId,
  };

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: types.REMOVE_SITE_FROM_ORGANIZATION,
            siteIndex,
          });
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to remove site from organization:', error);
      });
}

export function updateOrganizationSites(organizationId, sitesInOrganization) {
  const url = makeUrl(METHODS.UPDATE_ORGANIZATION_LOCATIONS);
  const data = {
    organizationId,
    locationIds: getSiteIds(sitesInOrganization),
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeSitesEditMode(false, STATES_ENTITY.NONE));
          dispatch(getOrganizationSites(organizationId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update organization sites:', error);
      });
}

export function changeSitesEditMode(isEditMode) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_SITES_EDIT_MODE,
      isEditMode,
    });
  };
}

/* eslint-disable max-len */
import {
  defaultFont,
  primaryColor,
} from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';

const style = {
  sectionLinks: {
    marginTop: '5px',
    textAlign: 'center',
  },
  sectionLinks__link: {
    ...defaultFont,
    'color': primaryColor,
    'fontSize': '12px',
    '&:hover': {
      color: primaryColor,
      textDecoration: 'underline',
    },
  },
};

export default style;

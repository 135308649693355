import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import _isEqual from 'lodash/isEqual';
import {getCampuses} from '../../app-common/Campus/action';
import {
  getCategories,
  resetState,
  setFilter,
  setValidation,
  updateCategorySubtypesOptions,
} from '../../app-common/Insights/action';
import {getPolicies} from '../../app-common/Policy/action';
import {getNetworks} from '../../app-common/Network/action';
import {getClients} from '../ClientsManager/action';
import {types as clientTypes} from '../ClientsManager/constants';
import {loadProfiles} from '../ClientsConfigurer/action';
import {FILTERS, TABS, LIMIT_LOAD_CLIENTS} from '../../app-common/Insights/constants';
import {
  getTabFilters,
  getCategories as getCategoriesSelector,
} from '../../app-common/Insights/selectors';
import {InsightsFilters} from '../../components';

const RECORD_TYPE_FILTERS = [
  FILTERS.SITE,
  FILTERS.NETWORK,
  FILTERS.PROFILE,
  FILTERS.POLICY,
  FILTERS.CLIENT,
  FILTERS.USER,
  FILTERS.NETWORK_IP,
  FILTERS.SUBNET_IP,
  FILTERS.CATEGORY,
  FILTERS.RECORD_TYPE,
  FILTERS.CATEGORY_TYPE,
];

// TODO: need more universal solution
const RECORD_TYPE_FILTERS_WITHOUT_CLIENTS_REQUESTS = [
  FILTERS.SITE,
  FILTERS.NETWORK,
  FILTERS.POLICY,
  FILTERS.NETWORK_IP,
  FILTERS.SUBNET_IP,
  FILTERS.CATEGORY,
  FILTERS.RECORD_TYPE,
  FILTERS.CATEGORY_TYPE,
];

class RecordTypeInsightsFilter extends React.PureComponent {
  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  componentDidMount() {
    const {accountInfo} = this.props;

    if (_isEmpty(this.props.campuses)) {
      this.props.getCampuses();
    }
    if (_isEmpty(this.props.categories)) {
      this.props.getCategories()
        .then(() => this.props.updateCategorySubtypesOptions(TABS.RECORD_TYPE));
    } else {
      this.props.updateCategorySubtypesOptions(TABS.RECORD_TYPE);
    }
    if (_isEmpty(this.props.policies)) {
      this.props.getPolicies();
    }
    if (_isEmpty(this.props.networks)) {
      this.props.getNetworks();
    }
    if (accountInfo.deviceAgentAllowed) {
      if (_isEmpty(this.props.clients)) {
        this.props.getClients({limit: LIMIT_LOAD_CLIENTS}, clientTypes.SET_CLIENTS);
      }
      if (_isEmpty(this.props.profiles)) {
        this.props.loadProfiles();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (RecordTypeInsightsFilter.wasDataScopeSwitched(prevProps, this.props)) {
      const {accountInfo} = this.props;
      this.props.resetState();
      this.props.getCampuses();
      this.props.getPolicies();
      this.props.getNetworks();

      if (accountInfo.deviceAgentAllowed) {
        this.props.getClients({limit: LIMIT_LOAD_CLIENTS}, clientTypes.SET_CLIENTS);
        this.props.loadProfiles();
      }
    }
  }

  get filters() {
    const {accountInfo} = this.props;
    if (accountInfo.deviceAgentAllowed) {
      return RECORD_TYPE_FILTERS.map((df) => this.getFilterDetails(df));
    }
    return RECORD_TYPE_FILTERS_WITHOUT_CLIENTS_REQUESTS.map((df) => this.getFilterDetails(df));
  }

  get filtersUpdatingActions() {
    return {
      [FILTERS.CATEGORY_TYPE]: this.props.updateCategorySubtypesOptions,
    };
  }

  getFilterDetails = (field) => ({
    id: field,
    name: I18n.t(`insights.filters.${field}`),
    options: [],
    placeholder: I18n.t(`insights.filters.placeholders.${field}`),
    ...this.props.filters[field],
  });

  setFilter = (filterName, value) => {
    this.props.setFilter(TABS.RECORD_TYPE, filterName, value);

    const onFilterUpdated = _get(this.filtersUpdatingActions, filterName, () => {});
    onFilterUpdated(
      TABS.RECORD_TYPE,
      _get(this.props.filters, [filterName, 'options', value.value, 'id'], null),
    );
  };

  setValidation = (field, fieldValidation) => {
    this.props.setValidation(TABS.RECORD_TYPE, field, fieldValidation);
  };

  render() {
    return (
      <InsightsFilters
        filters={this.filters}
        setFilter={this.setFilter}
        tabName={TABS.RECORD_TYPE}
        setValidation={this.setValidation}
      />
    );
  }
}

RecordTypeInsightsFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  updateCategorySubtypesOptions: PropTypes.func.isRequired,
  getCampuses: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  getNetworks: PropTypes.func.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  setValidation: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  campuses: PropTypes.array.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  categories: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  networks: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  accountInfo: PropTypes.object.isRequired,
};

RecordTypeInsightsFilter.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  filters: getTabFilters(TABS.RECORD_TYPE)(state),
  categories: getCategoriesSelector(state),
  campuses: state.campusReducer.campuses,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
  policies: state.policyReducer.policies,
  networks: state.networkReducer.networks,
  profiles: state.clientsConfigurerReducer.profiles,
  clients: state.clientsManagerReducer.clients,
  accountInfo: state.operatorLayoutReducer.accountInfo,
});

const mapDispatchToProps = {
  setFilter,
  getCampuses,
  getCategories,
  getPolicies,
  getNetworks,
  loadProfiles,
  getClients,
  updateCategorySubtypesOptions,
  setValidation,
  resetState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecordTypeInsightsFilter);

import {types} from './constants';
import initState from '../../reducers/redux-initial-state';
import {getValidData} from '../../utils/dashboard';

export default (state = initState.performanceStatsWidgetReducer, action) => {
  switch (action.type) {
    case types.SET_ORGANIZATION_PERFORMANCE_STATS: {
      return {
        ...state,
        averageLatency: parseInt(getValidData(action.averageLatency), 10),
        requestsPerSecond: action.requestsPerSecond.toFixed(2),
      };
    }

    default:
      return state;
  }
};

import cardsStyle from '../../styles/components/cards';

const style = () => ({
  clientsTable__table: {
    'height': 'calc(100vh - 250px)',
    '@media (max-width: 640px)': {
      height: 'calc(100vh - 360px)',
    },
  },
  clientsTable__tbodyInfinite: {
    display: 'block',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 275px)',
    minWidth: 'max-content',
  },
  tbodyInfinite_noItems: {
    height: 'calc(100vh - 275px)',
  },
  clientsTable__tbodyInfinite_noControls: {
    maxHeight: 'calc(100vh - 180px)',
  },
  clientsTableCard: {
    ...cardsStyle.card,
    // height: 'calc(100vh - 99px)',
  },
  clientsTableCard__header: {
    ...cardsStyle.card__header,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '15px',
    padding: '15px 20px',
  },
  clientsTableCard__additionalHeaderActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  clientsTableCard__total: {
    fontSize: '17px',
    marginRight: '20px',
  },
  clientsTableCard__dropdown: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
  },
  clientsTableCard__title: {
    ...cardsStyle.card__title,
    ...cardsStyle.card__title_primary,
    borderBottom: 'none',
  },
  clientsTableCard__content: {
    ...cardsStyle.card__content,
    padding: '0px 20px 5px 20px !important',
  },
  clientsTableCard__action: {
    ...cardsStyle.card__action,
    width: '100%',
  },
});

export default style;

import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';
import {timestampMilliToUserDate} from '../../utils/dateTime';

export const getClientBundlesData = (data) => data.map((item) => ({
  ...item,
  file: item.link,
  expireDate: (_isNull(item.expireDate)) ? '-' : timestampMilliToUserDate(item.expireDate),
}));

export const getUpdatedBundleIndex = (updatedBundleId, bundles) =>
  _findIndex(bundles, (bundle) => _isEqual(bundle.id, updatedBundleId));

import moment from 'moment';

import {DATE_FORMAT} from '../../constants';

export function getDateTimeFilterValue(date, time) {
  return moment(
    `${date}, ${time}`,
    `${DATE_FORMAT.MMDDYYYY_RIGHT_SLASH}, ${DATE_FORMAT.TIME_PICKER_LOG_FILTERS}`,
  ).format(DATE_FORMAT.LOG_FILTER_QUERY_PARAM);
}

export function getTimezone() {
  return moment.tz.guess();
}

import React from 'react';
import PropTypes from 'prop-types';
import {RenderOrEmpty} from '../index';

function RenderDivOrEmpty(props) {
  const {isShow, ...divProps} = props;
  return (
    <RenderOrEmpty isShow={props.isShow}>
      <div {...divProps}>
        {props.children}
      </div>
    </RenderOrEmpty>
  );
}

RenderDivOrEmpty.propTypes = {
  children: PropTypes.node.isRequired,
  isShow: PropTypes.bool.isRequired,
};

export default RenderDivOrEmpty;

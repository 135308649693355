import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {ApiKeyForm, EntityEditingCard} from '../../components/index';
import {ACCESS_RIGHTS, DEFAULT_MAX_INPUT_STRING_LENGTH} from '../../constants';
import {revokeApiKey, setEditingMode} from '../../app-common/ApiAccessManagement/action';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_EDIT],
  buttonDelete: [ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_REVOKE],
};

class ApiKeyEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: _cloneDeep(props.apiKey),
    };
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.apiKey, this.props.apiKey)) {
      this.updateState();
    }
  }

  setEditingMode = () => this.props.setEditingMode(true);

  updateState = () => {
    this.props.setEditingMode(false);
    this.setState({
      apiKey: _cloneDeep(this.props.apiKey),
    });
  };

  revokeApiKey = () => {
    const keyId = _get(this.state, 'apiKey.id', null);
    this.props.revokeApiKey(keyId)
      .then(this.props.updatingCallback);
  };

  saveApiKey = () => {
    this.props.updateApiKey(this.state.apiKey);
  };

  handleChangeInputInfo = (inputName, value) => {
    const {description} = this.state.apiKey;
    if (value.length > description.length
      && description.length >= DEFAULT_MAX_INPUT_STRING_LENGTH) {
      return;
    }
    this.setState((prevState) => ({
      apiKey: {
        ...prevState.apiKey,
        [inputName]: value,
      },
    }));
  };

  render() {
    const title = _get(this.state.apiKey, 'id', '');
    return (
      <EntityEditingCard
        entityType={I18n.t('entitiesTypes.apiKey')}
        title={title}
        content={(
          <ApiKeyForm
            apiKey={this.state.apiKey}
            accountInfo={this.props.accountInfo}
            isEditing={this.props.isEditingMode}
            onChange={this.handleChangeInputInfo}
          />
        )}
        isTherePathBack={true}
        isThereEditBlock={true}
        isThereDeleteBlock={true}
        isEditMode={this.props.isEditingMode}
        handleClickButtonDelete={this.revokeApiKey}
        handleClickButtonEdit={this.setEditingMode}
        handleClickButtonCancel={this.updateState}
        handleClickButtonBack={this.props.resetSelectedApiKey}
        handleClickButtonSave={this.saveApiKey}
        deleteButtonText={I18n.t('apiAccessManagementPage.form.buttons.revokeKey')}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

ApiKeyEditForm.propTypes = {
  apiKey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  apiKeys: PropTypes.array.isRequired,
  accountInfo: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  revokeApiKey: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,
  updateApiKey: PropTypes.func.isRequired,
  setEditingMode: PropTypes.func.isRequired,
  resetSelectedApiKey: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  accountInfo: state.operatorLayoutReducer.accountInfo,
  isEditingMode: state.apiAccessManagementReducer.isEditingMode,
});

const mapDispatchToProps = {
  revokeApiKey,
  setEditingMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyEditForm);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import CustomInput from '../../material-dashboard-pro-react/components/CustomInput/CustomInput';
import {EntityEditingCard, NotificationCardWithHeader} from '../../components';
import {ACCESS_RIGHTS} from '../../constants';

import {updateEmail} from './action';
import {isEmail, isNotEmpty, validate} from '../../utils/validators';

import style from './style';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT],
};

class UpdateEmailForm extends Component {
  static validationRules = [{
    name: 'email',
    validationRules: [isNotEmpty, isEmail],
  }];

  static initialState = {
    email: '',
    isEditMode: false,
    validationResult: {
      validationState: {},
      isValid: true,
    },
    isNotificationActive: false,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  state = UpdateEmailForm.initialState;

  componentDidMount() {
    this.setInitState();
  }

  componentDidUpdate(prevProps) {
    if (UpdateEmailForm.wasDataScopeSwitched(prevProps, this.props)) {
      this.setInitState();
    }
  }

  get emailInput() {
    const propsFormControl = {
      fullWidth: true,
    };
    const inputProps = {
      value: this.state.email,
      name: 'updateEmail',
      onChange: this.handleChange,
      disabled: !this.state.isEditMode,
    };

    return (
      <CustomInput
        errorText={undefined}
        error={false}
        labelText={I18n.t('profilePage.emailBlock.label')}
        formControlProps={propsFormControl}
        inputProps={inputProps}
        {...this.error}
      />
    );
  }

  get initialEmail() {
    return _get(this.props, 'loggedAccount.email', '');
  }

  get error() {
    if (!_get(this.state, 'validationResult.isValid', true)) {
      return {
        error: true,
        errorText: I18n.t('profilePage.emailBlock.validationError'),
      };
    }
    return {};
  }

  setInitState = () =>
    this.setState({
      ...UpdateEmailForm.initialState,
      email: this.initialEmail,
    });

  handleChange = (e) => {
    const email = _get(e, 'target.value', '');
    this.setState((prevState) => {
      const validationResult = validate(
        UpdateEmailForm.validationRules,
        {email},
        prevState.validationResult.validationState,
      );
      return {
        email,
        validationResult,
      };
    });
  };

  save = () => this.props.updateEmail(this.state.email)
    .then((responseStatus) => {
      if (responseStatus) {
        this.setState({isNotificationActive: true});
      }
    });

  render() {
    const {classes} = this.props;
    const isValid = _get(this.state, 'validationResult.isValid', true);
    const isSaveAvailable = isValid && !_isEqual(this.initialEmail, this.state.email);
    if (this.state.isNotificationActive) {
      return (
        <NotificationCardWithHeader
        cardTitle={I18n.t('profilePage.emailBlock.title')}
        content={(
          <div className={classes.updateEmailForm__notificationMessage}>
            {I18n.t('profilePage.emailBlock.notification', {email: this.state.email})}
          </div>
        )}
        onClickOk={this.setInitState}
        />
      );
    }
    return (
      <EntityEditingCard
        title={I18n.t('profilePage.emailBlock.title')}
        entityType={I18n.t('profilePage.emailBlock.title')}
        handleClickButtonSave={this.save}
        isValidEntity={isSaveAvailable}
        isFullscreen={false}
        content={this.emailInput}
        isThereEditBlock={true}
        isEditMode={this.state.isEditMode}
        handleClickButtonEdit={() => this.setState({isEditMode: true})}
        handleClickButtonCancel={() => this.setInitState()}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

UpdateEmailForm.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  updateEmail: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

UpdateEmailForm.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

const mapDispatchToProps = {
  updateEmail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((withStyles(style)(UpdateEmailForm)));

import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _get from 'lodash/get';

import initState from '../../reducers/redux-initial-state';
import {types} from './action';
import {clearFilters, getInitialFilterState} from './service';

export default (state = initState.insightsReducer, action) => {
  switch (action.type) {
    case types.SET_SEARCH_OPTION: {
      const newState = _cloneDeep(state);
      _set(newState, ['searchOptions', action.tab, action.optionName], action.value);
      return newState;
    }
    case types.SET_DOMAIN_TYPE: {
      const newState = _cloneDeep(state);
      _set(newState, ['domainType'], action.value);
      return newState;
    }
    case types.SET_FILTER: {
      const newState = _cloneDeep(state);
      const path = ['filters', action.tab, action.filterName];
      const filter = {
        ..._get(newState, path, {}),
        ...action.value,
      };
      _set(newState, path, filter);
      return newState;
    }
    case types.SET_DATA: {
      const newState = _cloneDeep(state);
      _set(newState, action.domainType === null
        ? ['data', action.tab]
        : ['data', action.tab, action.domainType], action.data);
      return newState;
    }
    case types.SET_COMPONENTS: {
      const newState = _cloneDeep(state);
      _set(newState, ['components', action.tab], action.components);
      return newState;
    }
    case types.SET_SELECTED_RECORDS: {
      const newState = _cloneDeep(state);
      _set(newState, ['selectedRecords', action.tab], action.selectedRecords);
      return newState;
    }
    case types.SET_OPTIONS: {
      const newState = _cloneDeep(state);
      _set(newState, ['filters', action.tab, action.filterName, 'options'], action.options);
      _set(
        newState,
        ['filters', action.tab, action.filterName, 'value'],
        getInitialFilterState(action.tab, action.filterName),
      );
      return newState;
    }
    case types.SET_CATEGORIES: {
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          categories: action.categories,
        },
      };
    }
    case types.RESET_STATE: {
      return {...initState.insightsReducer};
    }
    case types.RESET_TAB_FILTERS: {
      const newState = _cloneDeep(state);
      _set(newState, ['filters', action.tab], clearFilters(state.filters[action.tab]));
      return newState;
    }
    case types.DISABLE_FILTERS: {
      const newState = _cloneDeep(state);
      _set(newState, ['areFiltersDisabled', action.tab], true);
      return newState;
    }
    case types.ENABLE_FILTERS: {
      const newState = _cloneDeep(state);
      _set(newState, ['areFiltersDisabled', action.tab], false);
      return newState;
    }
    case types.SET_FIELD_VALIDATION: {
      const newState = _cloneDeep(state);
      _set(newState, ['filters', action.tab, action.filterName, 'isValid'], action.isValid);
      return newState;
    }
    default:
      return state;
  }
};

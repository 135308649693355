import _sortBy from 'lodash/sortBy';

import {types} from './constants';
import {METHODS} from '../../constants';
import {widgetKeys} from '../ClientDashboard/constants';
import {get, makeUrl} from '../../utils/fetcher';
import {getClientStatsData} from './utils';
import {convertThreatNames} from '../../utils/dashboard';

export function getEditableClientInfo(client) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_CLIENT_INFO,
      clientInfo: client,
      isValidClientInfo: true,
    });
  };
}

export function resetClientEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CLIENT_EDIT_FORM_DATA,
    });
  };
}

export function updateClientInfo(updatedClientInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CLIENT_INFO,
      updatedClientInfo,
      isValidClientInfo: isValid,
    });
  };
}

export function setValidationResultClientInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_CLIENT_INFO,
      isValid,
    });
  };
}

export function setSelectedPolicyIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CLIENT_SELECTED_POLICY_INDEX,
      index,
    });
  };
}

export function setSelectedProfileIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CLIENT_SELECTED_PROFILE_INDEX,
      index,
    });
  };
}

export function setSelectedPeriodIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CLIENT_SELECTED_PERIOD_INDEX,
      index,
    });
  };
}

export function getClientRequestsGraph(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_USER_REQUESTS_GRAPH, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_CLIENT_REQUESTS_GRAPH,
            period: queryParams.period,
            data,
          });
        } else {
          dispatch({
            type: types.SET_CLIENT_REQUESTS_GRAPH,
            period: queryParams.period,
            data: {},
          });
        }
      });
}

export function getClientAverageLatency(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_AVERAGE_LATENCY, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_CLIENT_AVERAGE_LATENCY,
            data,
          });
        } else {
          dispatch({
            type: types.SET_CLIENT_AVERAGE_LATENCY,
            data: 0.0,
          });
        }
      });
}

export function getClientStats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CLIENT_STATS, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_CLIENT_STATS,
            data: getClientStatsData(response.data),
          });
        } else {
          dispatch({
            type: types.SET_CLIENT_STATS,
            data: getClientStatsData(null),
          });
        }
      });
}

export function getClientThreats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CLIENT_THREATS, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        const data = _sortBy(response.data, (item) => item.threat);
        if (response && response.data) {
          dispatch({
            type: types.SET_CLIENT_THREATS,
            data: convertThreatNames(data),
          });
        } else {
          dispatch({
            type: types.SET_CLIENT_THREATS,
            data: [],
          });
        }
      });
}

export function getDashboardData(params) {
  const period = params.period;
  const clientId = params.clientId;
  const queryParamsPeriodClient = {clientId, period};

  return (dispatch) => {
    dispatch(getClientAverageLatency(queryParamsPeriodClient, widgetKeys.PERFORMANCE_STATS));
    dispatch(getClientStats(queryParamsPeriodClient, widgetKeys.PERFORMANCE_STATS));
    dispatch(getClientThreats(queryParamsPeriodClient, widgetKeys.THREAT_STATS));
    dispatch(getClientRequestsGraph(queryParamsPeriodClient, widgetKeys.REQUEST_STATS));
  };
}

import {types} from '../actions/auth/actionTypes';
import initState from './redux-initial-state';

export default (state = initState.authReducer, action) => {
  switch (action.type) {
    case types.ACTION_LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        isLoggedInFailed: initState.authReducer.isLoggedInFailed,
        show2FAForm: false,
        authCodeChecked: action.data.authCodeChecked,
      };
    }
    case types.ACTION_LOGIN_FAILED: {
      return {
        ...state,
        loggedIn: false,
        isLoggedInFailed: action.showErrorMessage,
        show2FAForm: false,
        authCodeChecked: false,
      };
    }
    case types.ACTION_SET_LOGIN_SECOND_FACTOR_STATUS: {
      return {
        ...state,
        loggedIn: action.passed,
        isSecondFactorFailed: !action.passed,
      };
    }
    case types.ACTION_LOGOUT_SUCCESS: {
      return {
        ...state,
        loggedIn: false,
        isLoggedInFailed: initState.authReducer.isLoggedInFailed,
        show2FAForm: false,
        authCodeChecked: false,
      };
    }
    case types.ACTION_OPEN_LOGIN_2FA_FORM: {
      return {
        ...state,
        show2FAForm: true,
      };
    }
    case types.ACTION_HIDE_LOGIN_2FA_FORM: {
      return {
        ...state,
        show2FAForm: false,
      };
    }
    case types.ACTION_SET_AUTH_CODE_CHECKED_STATUS: {
      return {
        ...state,
        authCodeChecked: action.isAuthCodeChecked,
      };
    }
    default:
      return state;
  }
};

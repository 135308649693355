import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';
import style from './style';

function NetworkPolicyIpAddresses(props) {
  const {
    classes,
    ipAddresses,
  } = props;

  if (ipAddresses.length <= 1) {
    return ipAddresses;
  }
  const cell = [];
  for (let i = 0; i < ipAddresses.length - 1; i++) {
    cell.push((
      <React.Fragment key={uuid()}>
        {ipAddresses[i]}
        <br />
      </React.Fragment>
    ));
  }
  cell.push(ipAddresses[ipAddresses.length - 1]);

  return (
    <p className={classes.td_policyIpAddresses}>
      {cell}
    </p>
  );
}

NetworkPolicyIpAddresses.propTypes = {
  classes: PropTypes.object.isRequired,
  ipAddresses: PropTypes.array,
};
NetworkPolicyIpAddresses.defaultProps = {
  ipAddresses: [],
};

export default withStyles(style)(NetworkPolicyIpAddresses);

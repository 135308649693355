import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export function getCampusesTableData(campuses, campusesStats) {
  return campuses.map((item) => ({
    campusName: item.name,
    up: _get(campusesStats, `${item.id}.networkUp`, 0),
    down: _get(campusesStats, `${item.id}.networkDown`, 0),
    requestsPerSecond: _get(campusesStats, `${item.id}.requestsPerSecond`, 0).toFixed(2),
  }));
}

export function getCampusToCreate(newCampus) {
  const newData = _cloneDeep(newCampus);
  delete newData.id;
  return newData;
}

export function getUpdatedCampusIndex(updatedCampusId, campuses) {
  return _findIndex(campuses, (o) => _isEqual(o.id, updatedCampusId));
}

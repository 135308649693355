import React from 'react';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'perfect-scrollbar';
import {NavLink} from 'react-router-dom';
import cx from 'classnames';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {
  ProfileHeaderAction,
  HelpAndSupportHeaderAction,
  NotificationBanner,
  NotificationsHeaderAction,
  SettingsHeaderAction,
} from '../../../components/index';

import sidebarStyle from '../../assets/jss/material-dashboard-pro-react/components/sidebarStyle';
import RenderOrEmpty from '../../../components/RenderOrEmpty/RenderOrEmpty';

let ps;

// We've created this component
// so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
      console.warn('Sidebar did mount', this.props)
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
      console.warn('Sidebar will unmount', this.props)
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const {
      className,
      user,
      headerLinks,
      links,
    } = this.props;
    return (
      <div
        className={className}
        ref="sidebarWrapper"
      >
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miniActive: true,
    };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => this.props.location.pathname.indexOf(routeName) > -1;

  openCollapse = (collapse) => {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      logout,
      notificationBannerData,
      operatorData,
      accountInfo,
    } = this.props;
    const links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses = cx(
              classes.itemLink,
              {
                [` ${classes.collapseActive}`]: this.activeRoute(prop.path),
              }
            );
            const itemText = cx(
              classes.itemText,
              {
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
              }
            );
            const collapseItemText = cx(
              classes.collapseItemText,
              {
                [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
              }
            );
            const itemIcon = classes.itemIcon;
            const caret = classes.caret;
            return (
              <ListItem
                  key={uuid()}
                  className={classes.item}
              >
                <NavLink
                  to="#"
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={(
                      <b className={cx(
                        caret,
                        {
                          [classes.caretActive]: this.state[prop.state] ? classes.caretActive : '',
                        }
                      )}
                      />
                    )}
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit={true}>
                  <List className={`${classes.list} ${classes.collapseList}`}>
                    {prop.views.map((_prop, _key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      const navCollapseItemLinkClasses = cx(
                        classes.collapseItemLink,
                        {
                          [classes[color]]: this.activeRoute(prop.path),
                        }
                      );
                      const collapseItemMini = classes.collapseItemMini;
                      return (
                        <ListItem key={_key} className={classes.collapseItem}>
                          <NavLink to={_prop.path} className={navCollapseItemLinkClasses}>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                            <ListItemText
                              primary={_prop.name}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>
            );
          }
          const navLinkClasses = cx(
            classes.itemLink,
            {
              [classes[color]]: this.activeRoute(prop.path),
            }
          );
          const itemText = cx(
            classes.itemText,
            {
              [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
            }
          );
          const itemIcon = classes.itemIcon;
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    const logoNormal = cx(
      classes.logoNormal,
      {
        [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
      }
    );
    const logoMini = classes.logoMini;
    const logoClasses = cx(
      classes.logo,
      {
        [classes.whiteAfter]: bgColor === 'white',
      },
    );
    const brand = (
      <div className={logoClasses}>
        <a href="/" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a href="/" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );
    const drawerPaper = cx(
      classes.drawerPaper,
      {
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      }
    );
    const sidebarWrapper = cx(
      classes.sidebarWrapper,
      {
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      }
    );
    const modalProps = {
      keepMounted: true, // Better open performance on mobile.
    };
    const drawerClasses = {
      paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
    };

    return (
      <div ref="mainPanel">
        <Hidden mdUp={true}>
          <Drawer
            variant="temporary"
            anchor="right"
            open={this.props.open}
            classes={drawerClasses}
            onClose={this.props.handleDrawerToggle}
            ModalProps={modalProps}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={(
                <>
                  <NotificationBanner notificationData={notificationBannerData} />
                  <NotificationsHeaderAction />
                  <HelpAndSupportHeaderAction />
                  <SettingsHeaderAction />
                  <ProfileHeaderAction
                    logout={logout}
                    operatorData={operatorData}
                    accountInfo={accountInfo}
                  />
                </>
              )}
              links={links}
            />
            <RenderOrEmpty isShow={image !== undefined}>
              <div className={classes.background} />
            </RenderOrEmpty>
          </Drawer>
        </Hidden>
        <Hidden smDown={true}>
          <Drawer
            onMouseOver={() => this.setState({miniActive: false})}
            onMouseOut={() => this.setState({miniActive: true})}
            anchor="left"
            variant="permanent"
            open={true}
            classes={drawerClasses}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              links={links}
            />
            <RenderOrEmpty isShow={image !== undefined}>
              <div className={classes.background} />
            </RenderOrEmpty>
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  logout: PropTypes.func.isRequired,
  notificationBannerData: PropTypes.object.isRequired,
  operatorData: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
};

export default withStyles(sidebarStyle)(Sidebar);

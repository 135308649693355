import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import {arrayToString} from '../../utils/array';

export function getLocalForwarding(localForwarding) {
  return ({
    id: localForwarding.id,
    domain: localForwarding.domainName,
    subnetId: localForwarding.subnetId,
    subnet: localForwarding.forAllSubnets ? '<All LANs>' : localForwarding.subnetName,
    locationId: localForwarding.locationId,
    ips: localForwarding.ips,
    forAllSubnets: localForwarding.forAllSubnets,
  });
}
export function getLocalForwardingsTableData(localForwardings) {
  return localForwardings.map((localForwarding) => ({
    id: localForwarding.id,
    domain: localForwarding.domainName,
    subnetId: localForwarding.subnetId,
    subnet: localForwarding.forAllSubnets ? '<All LANs>' : localForwarding.subnetName,
    locationId: localForwarding.locationId,
    ips: arrayToString(localForwarding.ips),
    forAllSubnets: localForwarding.forAllSubnets,
  }));
}

export function getLocalForwardingToCreate(newLocalForwarding, campusId) {
  return {
    domainName: newLocalForwarding.domain,
    subnetId: newLocalForwarding.subnetId,
    ips: newLocalForwarding.ips,
    locationId: campusId,
    forAllSubnets: !newLocalForwarding.subnetId,
  };
}

export function getLocalForwardingToUpdate(srcLocalForwarding, campusId) {
  return {
    ...getLocalForwardingToCreate(srcLocalForwarding, campusId),
    id: srcLocalForwarding.id,
  };
}

export function getUpdatedLocalForwardingIndex(updatedLocalForwardingId, localForwardings) {
  return _findIndex(localForwardings, (o) => _isEqual(o.id, updatedLocalForwardingId));
}

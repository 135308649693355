import _filter from 'lodash/filter';
import _range from 'lodash/range';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n} from 'react-redux-i18n';

import {
  MOVE_TO_PERMIT_LIST_OPTIONS,
  TLD_TYPES_WITHOUT_INFRASTRUCTURE,
} from '../../constants';
import {CustomInput} from '../../material-dashboard-pro-react/components/index';
import {
  ButtonBack,
  ButtonForward,
  Dropdown,
  PillsWithLabel,
  PolicyTldList,
} from '../index';

import style from './style';

class TldSettings extends React.Component {
  state = {
    selectedBlockRows: [],
    selectedPermitRows: [],
    blockListPage: 0,
    permitListPage: 0,
    searchQuery: '',
    selectedTypeIndex: 0,
  };

  componentDidUpdate() {
    if (
      !this.props.isEditMode
      && (this.state.selectedBlockRows.length > 0 || this.state.selectedPermitRows.length > 0)
    ) {
      this.removeRowSelection();
    }
  }

  onSelectedTypeChange = (e) => {
    this.setState({selectedTypeIndex: e.target.value});
  };

  onBlockListPageChange = (blockListPage) => {
    this.setState({blockListPage});
  };

  onPermitListPageChange = (permitListPage) => {
    this.setState({permitListPage});
  };

  onMovingFromBlockToPermitList = () => {
    this.props.handleMoveFromBlockToPermitList([...this.state.selectedBlockRows]);
    this.removeRowSelection();
  };

  onMovingFromPermitToBlockList = () => {
    this.props.handleMoveFromPermitToBlockList([...this.state.selectedPermitRows]);
    this.removeRowSelection();
  };

  get moveToPermitListOptions() {
    return [
      () => this.props.handleMoveFromBlockToPermitListByButton(MOVE_TO_PERMIT_LIST_OPTIONS.ALL),
      () => this.props.handleMoveFromBlockToPermitListByButton(MOVE_TO_PERMIT_LIST_OPTIONS.TOP_25),
      () => this.props.handleMoveFromBlockToPermitListByButton(MOVE_TO_PERMIT_LIST_OPTIONS.TOP_50),
      () => this.props.handleMoveFromBlockToPermitListByButton(MOVE_TO_PERMIT_LIST_OPTIONS.TOP_100),
    ];
  }

  removeRowSelection = () => this.setState({
    selectedBlockRows: [],
    selectedPermitRows: [],
  });

  removeBlockRowSelection = () => this.setState({
    selectedBlockRows: [],
  });

  removePermitRowSelection = () => this.setState({
    selectedPermitRows: [],
  });

  handleClickBlockRow = (index) => {
    if (!this.props.isEditMode || this.state.selectedPermitRows.length > 0) {
      return;
    }
    const {selectedBlockRows} = this.state;
    if (selectedBlockRows.includes(index)) {
      this.setState({
        selectedBlockRows: _filter(
          [...selectedBlockRows],
          (elem) => elem !== index,
        ),
      });
    } else {
      this.setState({
        selectedBlockRows: [
          ...selectedBlockRows,
          index,
        ],
      });
    }
  };

  handleClickPermitRow = (index) => {
    if (!this.props.isEditMode || this.state.selectedBlockRows.length > 0) {
      return;
    }
    const {selectedPermitRows} = this.state;
    if (selectedPermitRows.includes(index)) {
      this.setState({
        selectedPermitRows: _filter(
          [...selectedPermitRows],
          (elem) => elem !== index,
        ),
      });
    } else {
      this.setState({
        selectedPermitRows: [
          ...selectedPermitRows,
          index,
        ],
      });
    }
  };

  selectAllBlockRows = () => this.setState({
    selectedBlockRows: [
      ..._range(0, this.props.tldFilteringSettingsObject.blockList.length),
    ],
  });

  selectAllPermitRows = () => this.setState({
    selectedPermitRows: [
      ..._range(0, this.props.tldFilteringSettingsObject.permitList.length),
    ],
  });

  handleMoveToPermitListOptionSelect = (_, index) => {
    const options = this.moveToPermitListOptions;
    options[index]();
  };

  doesPermitListContainTldNames = (tldNames) => {
    const permitList = this.props.tldFilteringSettingsObject.permitList
      .map((elem) => elem.name);
    return tldNames.every((tldName) => permitList.includes(tldName));
  };

  render() {
    const {
      classes,
      isEditMode,
      top100TldNames,
      tldFilteringSettingsObject,
    } = this.props;
    const {
      selectedBlockRows,
      selectedPermitRows,
    } = this.state;
    const searchFieldInputProps = {
      value: this.state.searchQuery,
      name: 'searchField',
      onChange: (e) => this.setState({searchQuery: e.target.value}),
      type: 'text',
    };
    const searchFieldFormControlProps = {
      className: classes.filterSection__searchField,
    };
    const moveToPermitListOptionsDisabled = {
      [MOVE_TO_PERMIT_LIST_OPTIONS.ALL]:
        !isEditMode || tldFilteringSettingsObject.blockList.length === 0,
      [MOVE_TO_PERMIT_LIST_OPTIONS.TOP_25]:
        !isEditMode || this.doesPermitListContainTldNames(top100TldNames.slice(0, 25)),
      [MOVE_TO_PERMIT_LIST_OPTIONS.TOP_50]:
        !isEditMode || this.doesPermitListContainTldNames(top100TldNames.slice(0, 50)),
      [MOVE_TO_PERMIT_LIST_OPTIONS.TOP_100]:
        !isEditMode || this.doesPermitListContainTldNames(top100TldNames),
    };
    const moveToPermitListPills = {
      name: 'moveToPermitList',
      label: I18n.t('policyPage.tldTab.moveToPermitListButtonSectionLabel'),
      options: [
        ...Object.values(MOVE_TO_PERMIT_LIST_OPTIONS).map((option, index) => ({
          value: index,
          label: I18n.t(`policyPage.tldTab.moveToPermitListButtonLabels.${option}`),
          disabled: moveToPermitListOptionsDisabled[option],
        })),
      ],
    };
    return (
      <>
        <div className={classes.tld__controls}>
          <div>
            <PillsWithLabel
              {...moveToPermitListPills}
              buttonsClassName="pills-buttons_tld"
              optionsColor="red"
              selectOption={this.handleMoveToPermitListOptionSelect}
            />
          </div>
          <div className={classes.tldControls__searchbar}>
            <CustomInput
              id="tld-filter"
              labelText={I18n.t('policyPage.tldTab.searchFieldLabel')}
              inputProps={searchFieldInputProps}
              formControlProps={searchFieldFormControlProps}
            />
            <Dropdown
              options={[
                'All',
                ...Object.values(TLD_TYPES_WITHOUT_INFRASTRUCTURE),
              ]}
              customSelectClass="dropdown__select_policyTldTab"
              selectedItemIndex={this.state.selectedTypeIndex}
              onChangeValue={this.onSelectedTypeChange}
            />
          </div>
        </div>
        <div className={classes.tld__listSection}>
          <PolicyTldList
            title={I18n.t('policyPage.tldTab.blockListLabel')}
            isEditMode={isEditMode}
            tldList={tldFilteringSettingsObject.blockList}
            pageNumber={this.state.blockListPage}
            searchQuery={this.state.searchQuery}
            selectedTypeIndex={this.state.selectedTypeIndex}
            selectedRows={this.state.selectedBlockRows}
            anotherTableSelectedRows={this.state.selectedPermitRows}
            onSelectAllClick={this.selectAllBlockRows}
            onDeselectClick={this.removeBlockRowSelection}
            onRowClick={this.handleClickBlockRow}
            onPageChange={this.onBlockListPageChange}
          />
          <div className={classes.listSection__buttonSection}>
            <ButtonBack
              onClick={this.onMovingFromPermitToBlockList}
              disabled={!isEditMode || selectedPermitRows.length === 0}
            />
            <ButtonForward
              onClick={this.onMovingFromBlockToPermitList}
              disabled={!isEditMode || selectedBlockRows.length === 0}
            />
          </div>
          <PolicyTldList
            title={I18n.t('policyPage.tldTab.permitListLabel')}
            isEditMode={isEditMode}
            tldList={tldFilteringSettingsObject.permitList}
            pageNumber={this.state.permitListPage}
            searchQuery={this.state.searchQuery}
            selectedTypeIndex={this.state.selectedTypeIndex}
            selectedRows={this.state.selectedPermitRows}
            anotherTableSelectedRows={this.state.selectedBlockRows}
            onSelectAllClick={this.selectAllPermitRows}
            onDeselectClick={this.removePermitRowSelection}
            onRowClick={this.handleClickPermitRow}
            onPageChange={this.onPermitListPageChange}
          />
        </div>
      </>
    );
  }
}

TldSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  tldFilteringSettingsObject: PropTypes.shape({
    permitList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      manager: PropTypes.string,
      ranking: PropTypes.number.isRequired,
    })).isRequired,
    blockList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      manager: PropTypes.string,
      ranking: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
  top100TldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleMoveFromBlockToPermitList: PropTypes.func.isRequired,
  handleMoveFromPermitToBlockList: PropTypes.func.isRequired,
  handleMoveFromBlockToPermitListByButton: PropTypes.func.isRequired,
};

export default withStyles(style)(TldSettings);

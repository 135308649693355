import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {CustomButton, Snackbar} from '../../material-dashboard-pro-react/components/index';
import {ContentCopy as ContentCopyIcon} from '../../icons/index';

import './style.css';

class ButtonCopy extends React.Component {
  state = {isSnackbarOpened: false};

  closeSnackbar = () => this.setState({isSnackbarOpened: false});

  copy = (e) => {
    e.stopPropagation();
    const content = document.getElementById(this.props.id);
    content.select();
    content.setSelectionRange(0, 99999);
    document.execCommand('copy');
    this.setState({isSnackbarOpened: true});
  };

  render() {
    const {id, content, snackbarMessage} = this.props;

    return (
      <>
        <CustomButton
          color="secondaryNoBackground"
          onClick={this.copy}
          size="sm"
          customClasses="cardActions__button_copy"
        >
          <ContentCopyIcon />
          <span className="button-copy__text">
            {I18n.t('buttons.copy.name').toUpperCase()}
          </span>
        </CustomButton>
        <textarea
          readOnly={true}
          value={content}
          id={id}
          className="button-copy__content"
        />
        <Snackbar
          open={this.state.isSnackbarOpened}
          message={snackbarMessage || I18n.t('buttons.copy.confirmation')}
          autoHideDuration={2000}
          onClose={this.closeSnackbar}
        />
      </>
    );
  }
}

ButtonCopy.propTypes = {
  content: PropTypes.string.isRequired,
  snackbarMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
};

ButtonCopy.defaultProps = {
  snackbarMessage: null,
};

export default ButtonCopy;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';

import {ButtonExpandLessMore} from '../../components';
import {Filters} from '../index';
import {toggleDashboard} from './action';

import style from './style';

class UniversalDashboard extends React.Component {
  render() {
    const {classes, filtersMetadata, widgets, isShowDashboard} = this.props;
    const widgetClasses = classNames({
      [classes.universalDashboard__widgets_hidden]: !isShowDashboard,
    });

    return (
      <div className={classes.universalDashboard}>
        <div
          className={classNames(
            classes.universalDashboard__controlPanel,
            {
              [classes.universalDashboard__controlPanel_dashboardIsHidden]: !isShowDashboard,
            },
          )}
        >
          <ButtonExpandLessMore
            customClasses="universalDashboard__buttonShowHideDashboard"
            isExpandLess={isShowDashboard}
            onClick={this.props.toggleDashboard}
            text={I18n.t('universalDashboard.title')}
          />
          <Filters
            isShowFilters={isShowDashboard}
            filtersMetadata={filtersMetadata}
          />
        </div>
        <div className={widgetClasses}>
          {widgets}
        </div>
      </div>
    );
  }
}

UniversalDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  filtersMetadata: PropTypes.array,
  widgets: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
  ]),
  isShowDashboard: PropTypes.bool.isRequired,
  toggleDashboard: PropTypes.func.isRequired,
};

UniversalDashboard.defaultProps = {
  filtersMetadata: [],
  widgets: [],
};

const mapStateToProps = (state) => ({
  isShowDashboard: state.universalDashboardReducer.isShowDashboard,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDashboard: bindActionCreators(toggleDashboard, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(UniversalDashboard));

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
} from '../index';

import style from './style';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.SITE_CREATE],
};

class CampusTable extends Component {
  constructor(props) {
    super(props);

    this.upTableCellRenderer = (row) => (
      <span className={props.classes.up}>
        {I18n.t('campusPage.upTableData', {up: row.value})}
      </span>
    );

    this.downTableCellRenderer = (row) => (
      <span className={props.classes.down}>
        {I18n.t('campusPage.downTableData', {down: row.value})}
      </span>
    );

    this.requestsPerSecondCellRenderer = (row) => (
      <span className={props.classes.requestsPerSecond}>
        {I18n.t(
          'campusPage.requestsPerSecondTableData',
          {requestsPerSecond: row.value},
        )}
      </span>
    );

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];

    this.headersFullWidthMode.push(
      {
        Header: I18n.t('campusPage.campusNameHeader'),
        accessor: 'campusName',
        ...CampusTable.getFilter(),
        sortable: false,
        width: 300,
      },
      {
        Header: I18n.t('campusPage.upHeader'),
        accessor: 'up',
        Cell: this.upTableCellRenderer,
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('campusPage.downHeader'),
        accessor: 'down',
        Cell: this.downTableCellRenderer,
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('campusPage.requestsPerSecondHeader'),
        accessor: 'requestsPerSecond',
        Cell: this.requestsPerSecondCellRenderer,
        sortable: true,
        filterable: false,
      },
    );
    this.headersCompressedMode.push({
      Header: null,
      accessor: 'campusName',
      sortable: false,
      ...CampusTable.getFilter(),
    });
  }

  static getFilter = () => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t('campusPage.campusNameFilterPlaceholder'),
      ['campusName'],
    )
  );

  render() {
    const {
      mode,
      handleClickButtonNew,
      handleClickCampusRow,
      isEditMode,
      tableData,
      selectedCampusIndex,
    } = this.props;
    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('campusPage.campusesTableTitle')}
        entityType={I18n.t('entitiesTypes.campus')}
        selectedEntityIndex={selectedCampusIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickCampusRow}
        accessRights={rightAvailabilityMap}
        showOnAccessDenyButtonCreate={true}
      />
    );
  }
}

CampusTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickCampusRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedCampusIndex: PropTypes.number.isRequired,
};

CampusTable.defaultProps = {
  tableData: [],
};

export default withStyles(style)(CampusTable);

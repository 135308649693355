import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import {
  CustomInput,
  GridContainer,
  ItemGrid,
} from '../../material-dashboard-pro-react/components';

import style from './style';

import {policyTypes} from './constants';
import {TABS as clientsTabs} from '../../app-common/Clients/constants';
import {STATES_ENTITY} from '../../constants';
import {widgetKeys} from '../ClientDashboard/constants';

import {
  ClientInfoTable,
  Dropdown,
  RenderOrEmpty,
  StatusIndicator,
  TabsPanel,
} from '../../components';
import {
  ClientDashboard,
  ClientSessions,
} from '../index';

import {getClientPolicies} from '../ClientsManager/action';
import {
  getEditableClientInfo,
  resetClientEditFormData,
  setValidationResultClientInfo,
  updateClientInfo,
  setSelectedProfileIndex,
  setSelectedPolicyIndex,
} from './action';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

class ClientEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
    },
    activeTabIndex: 0,
  };

  static shouldUpdateClientObject(prevProps, props) {
    return !_isEqual(prevProps.client, props.client);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.profileState, STATES_ENTITY.EDITING_CANCELED);
  }

  static shouldResetPolicies(prevProps, props) {
    return !_isEqual(prevProps.policies, props.policies);
  }

  static shouldResetProfile(prevProps, props) {
    return !_isEqual(prevProps.profile, props.profiles);
  }

  constructor(props) {
    super(props);
    this.state = {...ClientEditForm.initialLocalState};
    this.tabs = [
      {
        text: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.tabLabels.info`),
        name: 'info',
      },
      {
        text: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.tabLabels.sessions`),
        name: 'sessions',
      },
    ];
  }

  componentDidMount() {
    const {
      client,
      getPolicies,
      // eslint-disable-next-line no-shadow
      getEditableClientInfo,
    } = this.props;
    getPolicies(client.profileId);
    getEditableClientInfo(client);
    this.setProfile();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props.client, nextProps.client)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableClientInfo, nextProps.editableClientInfo)
      || !_isEqual(this.props.policies, nextProps.policies)
      || !_isEqual(this.props.profiles, nextProps.profiles)
      || !_isEqual(this.state.activeTabIndex, nextState.activeTabIndex);
  }

  componentDidUpdate(prevProps) {
    if (ClientEditForm.shouldUpdateClientObject(prevProps, this.props)
      || ClientEditForm.shouldResetFormData(prevProps, this.props)
    ) {
      this.props.getEditableClientInfo(this.props.client);
      this.props.getPolicies(this.props.client.profileId);
      this.setProfile();
      this.setPolicy();
    } else {
      if (ClientEditForm.shouldResetProfile(prevProps, this.props)) {
        this.setProfile();
      }
      if (ClientEditForm.shouldResetPolicies(prevProps, this.props)) {
        this.setPolicy();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetClientEditFormData();
  }

  getProfileDropdownOptions = () => this.props.profiles.map((profile) => profile.name);

  getPolicyDropdownOptions = () => [
    I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.profileDefined`),
    ...this.props.policies.map((policy) => policy.name),
  ];

  getColumns = (isEditMode) => {
    const {
      classes,
      editableClientInfo,
      selectedProfileIndex,
      selectedPolicyIndex,
    } = this.props;
    const editableCells = [
      <CustomInput
        id={uuid()}
        formControlProps={{
          className: 'clientEditForm__nameFormControl',
        }}
        inputProps={{
          value: editableClientInfo.clientName,
          name: 'clientName',
          onChange: this.handleChangeClientName,
          type: 'text',
          className: 'clientEditForm__nameInput',
        }}
      />,
      null,
      null,
      <Dropdown
        customSelectClass="dropdown__select_clientEditForm"
        customFormControlClass="dropdown__formControl_clientEditForm"
        disabled={!isEditMode}
        fullWidth={true}
        onChangeValue={this.handleChangeProfile}
        options={this.getProfileDropdownOptions()}
        selectedItemIndex={selectedProfileIndex}
      />,
      <Dropdown
        customSelectClass="dropdown__select_clientEditForm"
        customFormControlClass="dropdown__formControl_clientEditForm"
        disabled={!isEditMode}
        fullWidth={true}
        onChangeValue={this.handleChangePolicy}
        options={this.getPolicyDropdownOptions()}
        selectedItemIndex={selectedPolicyIndex}
      />,
      null,
    ];

    const valueCellGetter = (isEditMode)
      ? (row) => editableCells[row.index] || row.value || null
      : (row) => row.value || null;

    const headerCellGetter = (row) => (
      <span className={classes.tableHeader}>
        {row.value}
      </span>
    );

    return ([
      {
        Header: null,
        accessor: 'header',
        width: 150,
        filterable: false,
        sortable: false,
        isShow: true,
        Cell: headerCellGetter,
      },
      {
        Header: null,
        accessor: 'value',
        width: 200,
        filterable: false,
        sortable: false,
        isShow: true,
        Cell: valueCellGetter,
      },
    ]);
  };

  getFullHostname = (client) => client.fqdn;

  getClientInfoViewData = (client) => ([
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.name`),
      value: client.clientName,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.hostname`),
      value: client.hostname,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.fullHostname`),
      value: this.getFullHostname(client),
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.profile`),
      value: client.profileName,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.policy`),
      value: (!_isNil(client.activePolicyType))
        // eslint-disable-next-line max-len
        ? `${I18n.t(`policyTypes.${client.activePolicyType}`)} : ${client.activePolicyName}`
        : '',
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.lastSync`),
      value: client.lastSyncAt,
    },
  ]);

  getClientInfoEditData = (client) => ([
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.name`),
      value: client.clientName,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.hostname`),
      value: client.hostname,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.fullHostname`),
      value: this.getFullHostname(client),
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.profile`),
      value: client.profileName,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.policy`),
      value: client.policyName,
    },
    {
      header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.lastSync`),
      value: client.lastSyncAt,
    },
  ]);

  getNetworkInfoData = () => {
    const {editableClientInfo} = this.props;
    return [
      {
        header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.status`),
        value:
  <StatusIndicator status={this.getClientStatus(editableClientInfo.agentStatus)} />,
        style: {
          paddingTop: '4px',
          paddingBottom: '4px',
          minHeight: '35px',
        },
      },
      {
        header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.lastWanIp`),
        value: editableClientInfo.lastWanIp,
      },
      {
        header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.lastLanIp`),
        value: editableClientInfo.lastLanIp,
      },
      {
        header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.lastUser`),
        value: editableClientInfo.username,
      },
      {
        header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.lastSite`),
        value: editableClientInfo.lastLocationName,
      },
      {
        // eslint-disable-next-line max-len
        header: I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.clientDomain`),
        value: editableClientInfo.clientDomain,
      },
    ];
  };

  getClientStatus = (clientStatus) => {
    if (_isNil(clientStatus)) {
      return null;
    }
    return `DEVICE_AGENT_${clientStatus}`;
  };

  getTdProps = (rows, rowIndex) => ({
    style: _get(rows[rowIndex], 'style', undefined),
  });

  getClientInfo = () => {
    const {isEditMode, editableClientInfo} = this.props;
    if (isEditMode) {return this.getClientInfoEditData(editableClientInfo);}
    return this.getClientInfoViewData(editableClientInfo);
  };

  getInfoSubTab = () => {
    const {classes, isEditMode, dashboardShown} = this.props;
    const networkInfoData = this.getNetworkInfoData();
    const clientInfoData = this.getClientInfo();
    const tableBodyClasses = {
      tableBody: classNames({
        [classes.clientEditForm__tableBody_info]: dashboardShown,
      }),
    };

    return (
      <ItemGrid className={classes.clientEditForm__infoSection}>
        <div className={classes.infoSection__item}>
          <ClientInfoTable
            title={I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.clientInfoTable.title`)}
            columns={this.getColumns(isEditMode)}
            tableData={clientInfoData}
            getTdProps={(rowIndex) => this.getTdProps(clientInfoData, rowIndex)}
            customClasses={tableBodyClasses}
          />
        </div>
        <div className={classes.infoSection__item}>
          <ClientInfoTable
            title={I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.networkInfoTable.title`)}
            columns={this.getColumns(false)}
            tableData={networkInfoData}
            getTdProps={(rowIndex) => this.getTdProps(networkInfoData, rowIndex)}
            customClasses={tableBodyClasses}
          />
        </div>
      </ItemGrid>
    );
  };

  getSessionSubTab = () => {
    const {classes, client, dashboardShown} = this.props;
    const tableBodyClasses = {
      tableBody: classNames({
        [classes.clientEditForm__tableBody_sessions]: dashboardShown,
        [classes.clientEditForm__tableBody_sessions_noDashboard]: !dashboardShown,
      }),
    };
    return (
      <ItemGrid className={classes.clientEditForm__sessionSection}>
        <ClientSessions
          clientId={client.id}
          customClasses={tableBodyClasses}
          widgetKey={widgetKeys.SESSIONS}
        />
      </ItemGrid>
    );
  };

  getTabContent = (tabName) => {
    const tabContentMap = {
      info: this.getInfoSubTab(),
      sessions: this.getSessionSubTab(),
    };
    return tabContentMap[tabName];
  };

  handleClickTab = (tabIndex) => {
    this.setState({
      activeTabIndex: tabIndex,
    });
  };

  handleChangeClientName = (e) => {
    const {editableClientInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableClientInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateClientInfo(
      {
        ...editableClientInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      // eslint-disable-next-line
      validation: validationResult.validationState,
    });
  };

  handleChangeProfile = (e) => {
    const {editableClientInfo, profiles} = this.props;
    const index = e.target.value;

    this.props.updateClientInfo(
      {
        ...editableClientInfo,
        profileName: profiles[index].name,
        profileId: profiles[index].id,
      },
      true,
    );
    this.props.setSelectedProfileIndex(index);
  };

  handleChangePolicy = (e) => {
    const {editableClientInfo, policies} = this.props;
    const index = e.target.value;

    if (index === 0) {
      this.props.updateClientInfo(
        {
          ...editableClientInfo,
          policyName: '',
          policyId: null,
        },
        true,
      );
    } else {
      this.props.updateClientInfo(
        {
          ...editableClientInfo,
          policyName: policies[index - 1].name,
          policyId: policies[index - 1].id,
          activePolicyName: policies[index - 1].name,
          activePolicyId: policies[index - 1].id,
          activePolicyType: policyTypes.client,
        },
        true,
      );
    }
    this.props.setSelectedPolicyIndex(index);
  };

  setProfile = () => {
    const {editableClientInfo, profiles} = this.props;
    const index = _findIndex(
      profiles || [],
      (item) => _isEqual(item.id, editableClientInfo.profileId),
    );
    this.props.setSelectedProfileIndex(index);
  };

  setPolicy = () => {
    const {editableClientInfo, policies} = this.props;
    const policyIndex = _findIndex(
      policies || [],
      (item) => _isEqual(item.id, editableClientInfo.policyId),
    );
    this.props.setSelectedPolicyIndex(policyIndex + 1);
  };

  validate = (data) => validate(
    [
      {
        name: 'clientName',
        validationRules: [isNotEmpty],
      },
    ],
    data,
    {
      name: true,
    },
  );

  render() {
    const {
      classes,
      client,
    } = this.props;
    const {activeTabIndex} = this.state;
    const tabContent = this.getTabContent(this.tabs[activeTabIndex].name);

    return (
      <GridContainer direction="column" className={classes.clientEditForm}>
        <ItemGrid className={classes.clientEditForm__dashboardSection}>
          <ClientDashboard
            clientId={client.id}
          />
        </ItemGrid>
        <ItemGrid>
          <TabsPanel
            activeTabIndex={activeTabIndex}
            disabled={false}
            handleClickTab={this.handleClickTab}
            tabs={this.tabs}
          />
        </ItemGrid>
        <RenderOrEmpty isShow={!_isNil(tabContent)}>
          {tabContent}
        </RenderOrEmpty>
      </GridContainer>
    );
  }
}

ClientEditForm.propTypes = ({
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  editableClientInfo: PropTypes.object.isRequired,
  selectedProfileIndex: PropTypes.number.isRequired,
  selectedPolicyIndex: PropTypes.number.isRequired,
  dashboardShown: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,

  resetClientEditFormData: PropTypes.func.isRequired,
  getEditableClientInfo: PropTypes.func.isRequired,
  setSelectedPolicyIndex: PropTypes.func.isRequired,
  setSelectedProfileIndex: PropTypes.func.isRequired,
  setValidationResultClientInfo: PropTypes.func.isRequired,
  updateClientInfo: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
});

const mapStateToProps = (state) => ({
  client: state.clientsManagerReducer.selectedClient,
  clientState: state.clientsManagerReducer.clientState,
  isEditMode: state.clientsManagerReducer.isEditMode,
  profiles: state.clientsManagerReducer.profileNames,
  policies: state.clientsManagerReducer.policies,

  dashboardShown: state.universalDashboardReducer.isShowDashboard,

  editableClientInfo: state.clientEditFormReducer.editableClientInfo,
  selectedProfileIndex: state.clientEditFormReducer.selectedProfileIndex,
  selectedPolicyIndex: state.clientEditFormReducer.selectedPolicyIndex,
});

const mapDispatchToProps = (dispatch) => ({
  resetClientEditFormData: bindActionCreators(resetClientEditFormData, dispatch),
  getEditableClientInfo: bindActionCreators(getEditableClientInfo, dispatch),
  setValidationResultClientInfo: bindActionCreators(setValidationResultClientInfo, dispatch),
  updateClientInfo: bindActionCreators(updateClientInfo, dispatch),
  getPolicies: bindActionCreators(getClientPolicies, dispatch),
  setSelectedProfileIndex: bindActionCreators(setSelectedProfileIndex, dispatch),
  setSelectedPolicyIndex: bindActionCreators(setSelectedPolicyIndex, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ClientEditForm));

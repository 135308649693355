import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

import './style.css';

const NO_DATA_VALUE = '—';
export default function PlainResponseDetails(props) {
  const {response, log} = props;

  const field = (f) =>
    `${I18n.t(`responseView.details.${f}`)} ${_get(response, f, NO_DATA_VALUE)};\n`;

  const controlData = () => {
    const data = _get(response, 'control', []);
    return Object.keys(data)
      .map((c) => `\t${I18n.t(`responseView.details.${c}`)}: ${_get(data, c, NO_DATA_VALUE)};`)
      .join('\n');
  };

  const control = `${I18n.t('responseView.details.control')}:\n${controlData()}\n`;

  const details = (data, removeFields, pickFields) => {
    let filteredData = data;
    if (pickFields) {
      filteredData = _pick(data, pickFields);
    }
    if (removeFields) {
      filteredData = _omit(data, removeFields);
    }

    return Object.keys(filteredData)
      .map((d) => `\t${I18n.t(`responseView.detailsList.${d}`)} ${_get(data, d, NO_DATA_VALUE)};`)
      .join('\n');
  };

  const query = (q) => {
    const domain = _get(q, 'domainName', NO_DATA_VALUE);
    const type = _get(q, 'dnsType', NO_DATA_VALUE);
    const dnsClass = _get(q, 'dnsClass', NO_DATA_VALUE);
    const detailsList = details(q);
    const queryRow = I18n.t('responseView.details.queryRow', {domain, type, dnsClass});
    return `\t${queryRow};\n${detailsList}`;
  };

  const queriesData = () => {
    const data = _pick(log, ['domainName', 'dnsType', 'dnsClass']);
    if (_isEmpty(data)) return NO_DATA_VALUE;
    return query(data);
  };
  const queries = `${I18n.t('responseView.details.queries')}:\n${queriesData()}\n`;

  const answer = (a) => {
    const domain = _get(a, 'domainName', NO_DATA_VALUE);
    const recordType = _get(a, 'recordType', NO_DATA_VALUE);
    const dnsClass = _get(a, 'dnsClass', NO_DATA_VALUE);
    const address = _get(a, 'address', NO_DATA_VALUE);
    const detailsList = details(a, ['length']);
    const answerRow = I18n.t(
      'responseView.details.answerRow',
      {domain, recordType, dnsClass, address},
    );
    return `\t${answerRow};\n${detailsList}`;
  };
  const answersData = () => {
    const data = _get(response, 'answers', null);
    if (!data) return NO_DATA_VALUE;

    return data.map((d) => answer(d)).join('\n\n');
  };
  const answers = `${I18n.t('responseView.details.answers')}:\n${answersData()}\n`;
  return (
    field('id')
    + control
    + field('question')
    + field('answerRRs')
    + field('authorityRRs')
    + field('additionalRRs')
    + queries
    + answers
  );
}

PlainResponseDetails.propTypes = {
  response: PropTypes.object,
  log: PropTypes.object.isRequired,
};

PlainResponseDetails.defaultProps = {
  isPrint: false,
  response: {},
};

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _set from 'lodash/set';
import _without from 'lodash/without';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {
  deleteNotificationProfile,
  setEditingMode,
} from '../../app-common/NotificationsManagement/action';
import EntityEditingCard from '../../components/EntityEditingCard/EntityEditingCard';
import NotificationProfileForm
  from '../../components/NotificationProfileForm/NotificationProfileForm';
import {isEmail, isNotEmpty, validate} from '../../utils/validators';
import {areTwoArraysEqualIgnoringOrder} from '../../utils/array';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_EDIT],
  buttonDelete: [ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_DELETE],
};

class EditNotificationProfileForm extends React.Component {
  static validationRules = [{
    name: 'email',
    validationRules: [isNotEmpty, isEmail],
  }];

  constructor(props) {
    super(props);
    this.state = {
      notificationProfile: _cloneDeep(props.notificationProfile),
      validationResult: {
        validationState: {},
        isValid: true,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.notificationProfile, this.props.notificationProfile)) {
      this.updateState();
    }
  }

  onChange = (fieldName, value) =>
    this.setState((prevState) => {
      const notificationProfile = _cloneDeep(prevState.notificationProfile);
      _set(
        notificationProfile,
        fieldName,
        fieldName === 'description'
          ? value
          : value.trim(),
      );

      const validationResult = validate(
        EditNotificationProfileForm.validationRules,
        notificationProfile,
        prevState.validationResult.validationState,
      );

      return {notificationProfile, validationResult};
    });

  onSwitchToggle = (subtype, active) => {
    const notificationProfile = _cloneDeep(this.state.notificationProfile);
    const {notifications} = notificationProfile;
    let newNotifications;

    if (active) {
      newNotifications = [
        ...notifications,
        subtype,
      ];
    } else {
      newNotifications = _without(notifications, subtype);
    }

    this.setState((prevState) => ({
      notificationProfile: {
        ...prevState.notificationProfile,
        notifications: newNotifications,
      },
    }));
  };

  setEditingMode = () => this.props.setEditingMode(true);

  deleteNotificationProfile = () => {
    const id = _get(this.state, 'notificationProfile.id', null);
    this.props.deleteNotificationProfile(id)
      .then(this.props.updatingCallback);
  };

  updateState = () => {
    this.props.setEditingMode(false);
    this.setState({
      notificationProfile: _cloneDeep(this.props.notificationProfile),
    });
  };

  saveNotificationProfile = () => {
    this.props.updateNotificationProfile(this.state.notificationProfile);
  };

  render() {
    const {notificationProfile: initialNotificationProfile} = this.state;
    const {notificationProfile} = this.props;
    const title = notificationProfile.email;
    const {isValid} = this.state.validationResult;
    const wereEmailOrDescriptionChanged = (
      initialNotificationProfile.email !== notificationProfile.email
      || initialNotificationProfile.description !== notificationProfile.description
    );
    const wereNotificationsChanged = !areTwoArraysEqualIgnoringOrder(
      initialNotificationProfile.notifications,
      notificationProfile.notifications,
    );
    const isSaveAvailable = isValid && (wereEmailOrDescriptionChanged || wereNotificationsChanged);
    return (
      <EntityEditingCard
        entityType={I18n.t('entitiesTypes.notificationProfile')}
        title={title}
        content={(
          <NotificationProfileForm
            notificationProfile={this.state.notificationProfile}
            isEditing={this.props.isEditingMode}
            notificationTypes={this.props.notificationTypes}
            onChange={this.onChange}
            validationState={this.state.validationResult.validationState}
            onSwitchToggle={this.onSwitchToggle}
          />
        )}
        isTherePathBack={true}
        isThereEditBlock={true}
        isThereDeleteBlock={true}
        isEditMode={this.props.isEditingMode}
        isValidEntity={isSaveAvailable}
        handleClickButtonDelete={this.deleteNotificationProfile}
        handleClickButtonEdit={this.setEditingMode}
        handleClickButtonCancel={this.updateState}
        handleClickButtonBack={this.props.resetSelectedNotificationProfile}
        handleClickButtonSave={this.saveNotificationProfile}
        deleteButtonText={I18n.t('notificationsManagementPage.delete.button')}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

EditNotificationProfileForm.propTypes = {
  notificationTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    subtypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired).isRequired,

  notificationProfile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,

  deleteNotificationProfile: PropTypes.func.isRequired,
  resetSelectedNotificationProfile: PropTypes.func.isRequired,
  updateNotificationProfile: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,

  setEditingMode: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,

  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  isEditingMode: state.notificationsManagementReducer.isEditingMode,
});

const mapDispatchToProps = {
  deleteNotificationProfile,
  setEditingMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNotificationProfileForm);

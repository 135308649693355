import {
  post,
  makeUrl,
} from '../../utils/fetcher';

import {METHODS} from '../../constants';

export const types = {
  ACTION_UPDATE_PASSWORD_SUCCESS: 'ACTION_UPDATE_PASSWORD_SUCCESS',
  ACTION_UPDATE_PASSWORD_FAILED: 'ACTION_UPDATE_PASSWORD_FAILED',
  ACTION_UPDATE_PASSWORD_ERROR: 'ACTION_UPDATE_PASSWORD_ERROR',
  RESET_UPDATE_PASSWORD_PAGE_DATA: 'RESET_UPDATE_PASSWORD_PAGE_DATA',
};

export function updatePasswordByToken(data) {
  const url = makeUrl(METHODS.UPDATE_PASSWORD_BY_TOKEN);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updatePasswordSuccess());
        return null;
      }
      if (response && !response.success) {
        dispatch(updatePasswordError());
        dispatch(updatePasswordFailed());
        return null;
      }
      dispatch(updatePasswordFailed());
      return null;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Change password failed', error);
    });
}

function updatePasswordSuccess() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_UPDATE_PASSWORD_SUCCESS,
    });
  };
}

function updatePasswordError() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_UPDATE_PASSWORD_ERROR,
    });
  };
}

function updatePasswordFailed() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_UPDATE_PASSWORD_FAILED,
    });
  };
}

export function resetPageData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_UPDATE_PASSWORD_PAGE_DATA,
    });
  };
}

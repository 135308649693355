import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import Cookies from 'js-cookie';
import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Check} from '../../icons';

import {LINKS, TRUST_DEVICE_COOKIE_NAME} from '../../constants';
import {is2FACode} from '../../utils/validators';
import {millisToTimeFormatted} from '../../utils/dateTime';

import {
  CustomButton,
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {
  RenderDivOrEmpty,
  RenderOrEmpty,
} from '../index';

import style from './style';

class LoginForm2FA extends Component {
  static resendCodeDelayMS = 30000;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      submitDisabled: true,
      resendCodeMessageShown: false,
      ticsToResendCode: LoginForm2FA.resendCodeDelayMS,
      trustDevice: false,
    };
    this.timer = null;
    this.tics = null;
  }

  componentDidMount() {
    this.setResendCodeTimer();
    if (_isEqual(Cookies.get(TRUST_DEVICE_COOKIE_NAME), 'true')) {
      this.setState({trustDevice: true});
    }
  }

  componentWillUnmount() {
    this.clearResendCodeTimer();
  }

  getResendCodeLinkOrTimer = () => {
    const {classes} = this.props;
    const {ticsToResendCode} = this.state;
    return (
      <div className={classes.loginForm2FA__linkItem}>
        <RenderOrEmpty isShow={ticsToResendCode <= 0}>
          <span className={classes.loginForm2FA__link}
                onClick={this.resendCode}
          >
            {I18n.t('loginPage2FA.resendCode')}
          </span>
        </RenderOrEmpty>
        <RenderOrEmpty isShow={ticsToResendCode > 0}>
          <span className={classes.loginForm2FA__timer}>
            {I18n.t('loginPage2FA.canGetNewCodeAfter')}
            :&nbsp;
          </span>
          <span className={classes.loginForm2FA__time}>
            {millisToTimeFormatted(ticsToResendCode)}
          </span>
        </RenderOrEmpty>
      </div>
    );
  };

  onChange = (e) => {
    const newState = {
      ...this.state,
      [e.target.name]: e.target.value,
    };
    const isValid = this.validate(newState);
    this.setState({
      ...newState,
      submitDisabled: !isValid,
    });
  };

  onSubmit = () => {
    const {trustDevice} = this.state;
    this.props.login2FA(
      {code: this.state.code, trustDevice},
      this.props.history,
    );
  };

  validate = (data) => {
    const validatingRules = [
      {
        name: 'code',
        validatingRules: [is2FACode],
      },
    ];

    return validatingRules.reduce(
      (result, rule) => {
        const value = data[rule.name];
        return result && rule.validatingRules.reduce(
          (_result, func) => _result && func(value),
          true,
        );
      },
      true,
    );
  };

  setResendCodeTimer = () => {
    if (_isNil(this.timer)) {
      this.setState(
        {ticsToResendCode: LoginForm2FA.resendCodeDelayMS},
      );
      this.startResendCodeTics();
      this.timer = setTimeout(
        () => this.stopResendCodeTics(),
        LoginForm2FA.resendCodeDelayMS,
      );
    }
  };

  clearResendCodeTimer = () => {
    if (!_isNil(this.timer)) {
      this.stopResendCodeTics();
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  startResendCodeTics = () => {
    if (_isNil(this.tics)) {
      this.tics = setInterval(
        () => {
          this.setState(
            (prevState) => ({ticsToResendCode: Math.max(0, prevState.ticsToResendCode - 1000)}),
          );
        },
        1000,
      );
    }
  };

  stopResendCodeTics = () => {
    if (!_isNil(this.tics)) {
      clearInterval(this.tics);
      this.setState({ticsToResendCode: 0});
      this.tics = null;
    }
  };

  resendCode = () => {
    this.props.resendCode()
      .then((success) => {
        if (success) {
          this.clearResendCodeTimer();
          this.setResendCodeTimer();
        }
      });
  };

  toggleTrustDevice = () => {
    const {trustDevice} = this.state;
    if (trustDevice) {
      this.setState({trustDevice: false});
      Cookies.remove(TRUST_DEVICE_COOKIE_NAME);
    } else {
      this.setState({trustDevice: true});
    }
  };

  render() {
    const {
      code,
      submitDisabled,
      trustDevice,
    } = this.state;

    const {
      classes,
      isSecondFactorFailed,
      showTrustDeviceCheckbox,
    } = this.props;

    const propsInput = {
      code: {
        value: code,
        name: 'code',
        onChange: this.onChange,
        type: 'text',
        placeholder: I18n.t('loginPage2FA.codePlaceholder'),
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <>
        <RenderOrEmpty isShow={isSecondFactorFailed}>
          <div className={classes.loginForm2FA__errorMessage}>
            {I18n.t('loginPage2FA.errorMessageLoginFailed')}
          </div>
        </RenderOrEmpty>

        <CustomInput
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.code}
        />
        <span>
          {I18n.t('loginPage2FA.codeWasSentMessage')}
        </span>

        <div className={classes.loginForm2FA__sectionLinks}>
          {this.getResendCodeLinkOrTimer()}
          <div className={classes.loginForm2FA__linkItem}>
            <a
              href={LINKS.MFA_HELP}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.loginForm2FA__link}
            >
              {I18n.t('loginPage2FA.getHelp')}
            </a>
          </div>
        </div>

        <CustomButton
          fullWidth={true}
          disabled={submitDisabled}
          color="secondary"
          customClasses="unauthorizedForm__button"
          onClick={this.onSubmit}
        >
          {I18n.t('loginPage2FA.login')}
        </CustomButton>

        <RenderDivOrEmpty
          isShow={showTrustDeviceCheckbox}
          className={classes.loginForm2FA__sectionTrustDevice}
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={trustDevice}
                onClick={this.toggleTrustDevice}
                checkedIcon={<Check className={classes.loginForm2FA__checkboxIcon_checked} />}
                icon={<Check className={classes.loginForm2FA__checkboxIcon_unchecked} />}
                name="trustDevice"
                classes={{
                  checked: classes.loginForm2FA__checkbox_checked,
                }}
              />
            )}
            classes={{
              root: classes.loginForm2FA__checkboxFormControl,
              label: classes.loginForm2FA__checkboxLabel,
            }}
            label={I18n.t('loginPage2FA.trustDevice')}
          />
        </RenderDivOrEmpty>
      </>
    );
  }
}

LoginForm2FA.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSecondFactorFailed: PropTypes.bool.isRequired,
  showTrustDeviceCheckbox: PropTypes.bool,
  login2FA: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
};

LoginForm2FA.defaultProps = {
  showTrustDeviceCheckbox: true,
};

export default withRouter(withStyles(style)(LoginForm2FA));

import LS from './localStorage';

const LOCAL_STORAGE_KEY = 'scout_dns_jwt';
const LOCAL_STORAGE_REFRESH_KEY = 'scout_dns_refresh';
const LOCAL_STORAGE_SSO_TOKENS_KEY = 'msal.token.keys.';
const LOCAL_STORAGE_SSO_ACCOUNT_KEY = 'msal.account.keys';

export function isLoggedIn() {
  return !!getJWT();
}

export function getJWT() {
  return LS.getItem(LOCAL_STORAGE_KEY);
}

export function getRefresh() {
  return LS.getItem(LOCAL_STORAGE_REFRESH_KEY);
}

export function setJWT(jwt) {
  LS.setItem(LOCAL_STORAGE_KEY, jwt);
}

export function setRefresh(refresh) {
  LS.setItem(LOCAL_STORAGE_REFRESH_KEY, refresh);
}

export function removeJWT() {
  LS.removeItem(LOCAL_STORAGE_KEY);
}

export function removeRefresh() {
  LS.removeItem(LOCAL_STORAGE_REFRESH_KEY);
}

export function clearLocalStorage() {
  LS.clear();
}

export function isSSO() {
  const ssoTokenKeys = LS.getItem(LOCAL_STORAGE_SSO_ACCOUNT_KEY);
  if (!ssoTokenKeys) return false;
  return true;
}

export function getSSOTokens(localStorageTokenId) {
  const ssoTokenKeys = JSON.parse(LS.getItem(LOCAL_STORAGE_SSO_TOKENS_KEY + localStorageTokenId));

  if (!ssoTokenKeys) {
    return {
      accessToken: '',
      refreshToken: '',
      idToken: '',
    };
  }

  const preparedSSOTokenKeys = Object.keys(ssoTokenKeys).reduce((acc, key) => {
    acc[key] = ssoTokenKeys[key][0];
    return acc;
  }, {});

  return {
    accessToken: JSON.parse(LS.getItem(preparedSSOTokenKeys.accessToken)).secret,
    refreshToken: JSON.parse(LS.getItem(preparedSSOTokenKeys.refreshToken)).secret,
    idToken: JSON.parse(LS.getItem(preparedSSOTokenKeys.idToken)).secret,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';

import {RegularCard} from '../../material-dashboard-pro-react/components';
import './style.css';

const SEPARATOR = '>';
export default class Breadcrumbs extends React.Component {
  get title() {
    const title = [];
    const items = _cloneDeep(this.props.items);
    const last = items.splice(items.length - 1, 1)[0] || {};
    const crumbs = items.map((c) => (
      <React.Fragment key={c.id}>
        <button
          type="button"
          onClick={() => this.props.goToComponent(c.id)}
          className="breadcrumbs__name"
        >
          {c.name}
        </button>
        {c.details ? <span className="breadcrumbs__details">{c.details}</span> : ''}
        <span className="breadcrumbs__separator">{SEPARATOR}</span>
      </React.Fragment>
    ));

    title.push(...crumbs, last.name);

    return title;
  }

  render() {
    return (
      <RegularCard
        cardTitle={this.title}
        content={this.props.children}
        action={this.props.action}
        customCardClasses="card_breadcrumbs"
      />
    );
  }
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    details: PropTypes.string,
  }).isRequired).isRequired,
  children: PropTypes.node.isRequired,
  action: PropTypes.node,
  goToComponent: PropTypes.func.isRequired,
};

Breadcrumbs.defaultProps = {
  action: null,
};

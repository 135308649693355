import _get from 'lodash/get';
import {post, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {widgetKeys} from '../../app-common/Log/constants';

export const types = {
  SET_RESPONSE_DETAILS: 'SET_RESPONSE_DETAILS',
  RESET_RESPONSE_DETAILS: 'RESET_RESPONSE_DETAILS',
};
export function getResponseDetails(params, widgetKey = widgetKeys.RESPONSE_VIEW) {
  const data = {
    eventDate: params.originEventDate,
    time: params.originTime,
    queryId: params.queryId,
    domainName: params.domainName,
    clientIp: params.networkIp,
  };
  const url = makeUrl(METHODS.GET_RESPONSE_DETAILS);
  return (dispatch) =>
    post(url, data, dispatch, true, widgetKey)
      .then((response) => {
        if (response) {
          dispatch({
            type: types.SET_RESPONSE_DETAILS,
            responseDetails: _get(response, 'data', {}),
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to get response details:', error);
      });
}
export function resetResponseDetails() {
  return (dispatch) =>
    dispatch({
      type: types.RESET_RESPONSE_DETAILS,
    });
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import style from './style';

import {
  GridContainer,
  ItemGrid,
} from '../../material-dashboard-pro-react/components/index';

import {
  EntityEditingCard,
  TabsPanel,
  RenderOrEmpty,
  CampusDashboard,
} from '../index';

import {
  CampusEditForm,
  CampusNetworksTab,
  CampusSubnetsTab,
  CampusRelaysTab,
  CampusLocalDomainsTab,
  CampusLocalForwardingTab,
} from '../../containers/index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonDelete: [ACCESS_RIGHTS.SITE_DELETE],
  buttonEdit: [ACCESS_RIGHTS.SITE_EDIT],
};

class CampusDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
    };
    this.tabs = [];
    this.tabs.push(
      {
        text: I18n.t('campusPage.networksTab.tabName'),
        name: 'networks',
        accessRights: [ACCESS_RIGHTS.SITE_WAN_SEE],
      },
      {
        text: I18n.t('campusPage.subnetsTab.tabName'),
        name: 'subnets',
        accessRights: [ACCESS_RIGHTS.SITE_LAN_SEE],
      },
      {
        text: I18n.t('campusPage.localForwardingsTab.tabName'),
        name: 'localForwardings',
        accessRights: [ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE],
      },
      {
        text: I18n.t('campusPage.localDomainsTab.tabName'),
        name: 'localDomains',
        accessRights: [ACCESS_RIGHTS.SITE_REDIRECTS_SEE],
      },
      {
        text: I18n.t('campusPage.relaysTab.tabName'),
        name: 'relays',
        accessRights: [ACCESS_RIGHTS.SITE_RELAYS_SEE],
      },
      {
        text: I18n.t('campusPage.campusInfoTab.tabName'),
        name: 'campusInfo',
        accessRights: [ACCESS_RIGHTS.SITE_INFO_SEE],
      },
    );
    this.campusDashboardRights = [ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE];
  }

  componentDidMount() {
    this.handleClickTab(this.state.activeTabIndex);
  }

  getCampusInfoTabContent = () => {
    const {
      handleClickButtonCancelEditCampus,
      handleClickButtonEditCampus,
      handleClickButtonSaveCampus,
      isEditMode,
      isValidCampusInfo,
    } = this.props;

    return (
      <EntityEditingCard
        entityType={I18n.t('entitiesTypes.campus')}
        title={I18n.t('campusPage.campusInfoTab.cardTitle')}
        isEditMode={isEditMode}
        isThereEditBlock={true}
        isValidEntity={isValidCampusInfo}
        handleClickButtonCancel={handleClickButtonCancelEditCampus}
        handleClickButtonEdit={handleClickButtonEditCampus}
        handleClickButtonSave={handleClickButtonSaveCampus}
        customCardClass="card_campusInfo"
        customCardContentClass="card__content_campusInfo"
        accessRights={rightAvailabilityMap}
        content={(
          <CampusEditForm />
        )}
      />
    );
  };

  getTabContent = (tabName) => {
    const tabContentMap = {
      networks: <CampusNetworksTab />,
      campusInfo: this.getCampusInfoTabContent(),
      subnets: <CampusSubnetsTab />,
      relays: <CampusRelaysTab />,
      localDomains: <CampusLocalDomainsTab />,
      localForwardings: <CampusLocalForwardingTab />,
    };

    return tabContentMap[tabName];
  };

  handleClickTab = (tabIndex) => {
    this.setState({
      activeTabIndex: tabIndex,
    });
  };

  render() {
    const {activeTabIndex} = this.state;

    const {
      classes,
      campusInfo,
      dashboardData,
      handleClickButtonBackInCampusInfo,
      handleClickButtonDeleteCampus,
      isEditMode,
      isSubTabEditMode,
      selectedPeriodIndex,
    } = this.props;

    const tabContent = this.getTabContent(this.tabs[activeTabIndex].name);
    const hasEditMode = isEditMode
      || Object.keys(isSubTabEditMode).reduce((allValid, key) =>
        allValid || isSubTabEditMode[key], false);

    return (
      <EntityEditingCard
        title={campusInfo.name}
        entityType={I18n.t('entitiesTypes.campus')}
        isThereDeleteBlock={true}
        isTherePathBack={true}
        isEditMode={hasEditMode}
        handleClickButtonBack={handleClickButtonBackInCampusInfo}
        handleClickButtonDelete={handleClickButtonDeleteCampus}
        customCardContentClass="card__content_campus"
        accessRights={rightAvailabilityMap}
        showOnAccessDenyButtonDelete={true}
        content={(
          <GridContainer direction="column" className={classes.grid}>
            <ItemGrid>
              <CampusDashboard
                campusId={campusInfo.id}
                averageLatency={dashboardData.averageLatency}
                blocksStats={dashboardData.blocksStats}
                handleChangePeriod={this.props.handleChangePeriodOnDashboard}
                networksStats={dashboardData.networksStats}
                requestsByDecision={dashboardData.requestsByDecision}
                requestsPerSecond={dashboardData.requestsPerSecond}
                selectedPeriodIndex={selectedPeriodIndex}
                userRequestsGraph={dashboardData.userRequestsGraph}
                accessRights={this.campusDashboardRights}
              />
            </ItemGrid>
            <ItemGrid>
              <TabsPanel
                activeTabIndex={activeTabIndex}
                disabled={hasEditMode}
                handleClickTab={this.handleClickTab}
                tabs={this.tabs}
              />
            </ItemGrid>
            <RenderOrEmpty isShow={!!tabContent}>
              <ItemGrid>
                {tabContent}
              </ItemGrid>
            </RenderOrEmpty>
          </GridContainer>
        )}
      />
    );
  }
}

CampusDetails.propTypes = {
  campusInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dashboardData: PropTypes.object.isRequired,
  handleClickButtonBackInCampusInfo: PropTypes.func.isRequired,
  handleClickButtonCancelEditCampus: PropTypes.func.isRequired,
  handleClickButtonDeleteCampus: PropTypes.func.isRequired,
  handleClickButtonEditCampus: PropTypes.func.isRequired,
  handleClickButtonSaveCampus: PropTypes.func.isRequired,
  handleChangePeriodOnDashboard: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isSubTabEditMode: PropTypes.object.isRequired,
  isValidCampusInfo: PropTypes.bool.isRequired,
  selectedPeriodIndex: PropTypes.number.isRequired,
};

export default withStyles(style)(CampusDetails);

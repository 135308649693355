import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
} from '../index';

import style from './style';
import {getOrDefault} from '../../utils/common';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.ORGANIZATION_CREATE],
};

class OrganizationTable extends Component {
  constructor(props) {
    super(props);

    const {networkStatsByOrganization, classes} = this.props;

    this.networksCellRenderer = (row) => {
      const organizationId = row.original.id;
      const stats = getOrDefault(networkStatsByOrganization, organizationId, {up: 0, down: 0});
      return (
        <div className={classes.statsCell}>
          <span className={classes.total}>{stats.up + stats.down}</span>
          &nbsp;/&nbsp;
          <span className={classes.up}>{stats.up}</span>
          &nbsp;/&nbsp;
          <span className={classes.down}>{stats.down}</span>
        </div>
      );
    };

    this.clientsCellRenderer = (row) => {
      const {online, offline} = row.original.clientCount;
      return (
        <div className={classes.statsCell}>
          <span className={classes.total}>{online + offline}</span>
          &nbsp;/&nbsp;
          <span className={classes.online}>{online}</span>
          &nbsp;/&nbsp;
          <span className={classes.offline}>{offline}</span>
        </div>
      );
    };
  }

  get headersFullWidthMode() {
    const {showClients} = this.props;

    if (showClients) {
      return [
        {
          Header: I18n.t('organizations.table.headers.organizationName'),
          accessor: 'name',
          ...this.getFilter(),
          sortable: true,
          width: 300,
        },
        {
          Header: I18n.t('organizations.table.headers.sites'),
          accessor: 'locationCount',
          sortable: true,
          filterable: false,
        },
        {
          Header: I18n.t('organizations.table.headers.networks'),
          Cell: this.networksCellRenderer,
          sortable: false,
          filterable: false,
        },
        {
          Header: I18n.t('organizations.table.headers.clients'),
          accessor: 'clientCount',
          Cell: this.clientsCellRenderer,
          sortable: false,
          filterable: false,
        },
      ];
    }
    return [
      {
        Header: I18n.t('organizations.table.headers.organizationName'),
        accessor: 'name',
        ...this.getFilter(),
        sortable: true,
        width: 300,
      },
      {
        Header: I18n.t('organizations.table.headers.sites'),
        accessor: 'locationCount',
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('organizations.table.headers.networks'),
        Cell: this.networksCellRenderer,
        sortable: false,
        filterable: false,
      },
    ];
  }

  get headersCompressedMode() {
    return [{
      Header: null,
      accessor: 'name',
      sortable: false,
      ...this.getFilter(),
    }];
  }

  getFilter = () => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t('organizations.table.placeholders.organizationSearch'),
      ['name'],
    )
  );

  render() {
    const {
      mode,
      handleClickButtonNew,
      handleClickOrganizationRow,
      isEditMode,
      tableData,
      selectedOrganizationIndex,
    } = this.props;
    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('organizations.table.title')}
        entityType={I18n.t('entitiesTypes.organization')}
        selectedEntityIndex={selectedOrganizationIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickOrganizationRow}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

OrganizationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickOrganizationRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  networkStatsByOrganization: PropTypes.object,
  selectedOrganizationIndex: PropTypes.number.isRequired,
  showClients: PropTypes.bool,
};

OrganizationTable.defaultProps = {
  tableData: [],
  showClients: true,
  networkStatsByOrganization: {},
};

export default withStyles(style)(OrganizationTable);

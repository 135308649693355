import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.logReducer, action) => {
  switch (action.type) {
    case types.SET_AUDIT_JSON: {
      return {
        ...state,
        tableData: action.data,
      };
    }
    case types.UPDATE_AUDIT_JSON: {
      return {
        ...state,
        tableData: [
          ...state.tableData,
          ...action.data,
        ],
      };
    }
    case types.CLEAR_TABLE_DATA: {
      return {
        ...state,
        tableData: initState.logReducer.tableData,
      };
    }
    case types.SET_CLIENT_NAMES: {
      return {
        ...state,
        clientNames: action.data,
      };
    }
    case types.SET_ORGANIZATION_NAMES_FOR_LOGS: {
      return {
        ...state,
        organizations: action.data,
      };
    }
    case types.CLEAR_CLIENT_NAMES: {
      return {
        ...state,
        clientNames: [...initState.logReducer.clientNames],
      };
    }
    case types.CLEAR_ORGANIZATIONS_FOR_LOGS: {
      return {
        ...state,
        organizations: [...initState.logReducer.organizations],
      };
    }

    default:
      return state;
  }
};

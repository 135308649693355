import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import {I18n} from 'react-redux-i18n';

import {
  ResponseDetails,
  ButtonPrint,
  RenderOrEmpty,
  ButtonCopy,
  PlainResponseDetails,
} from '../../components';
import LS from '../../utils/localStorage';

import {PRINT_RESPONSE_LOCAL_STORAGE_KEY} from './constants';
import {widgetKeys} from '../../app-common/Log/constants';
import {DEFAULT_WIDGET_KEY} from '../../constants';
import {LoadingLayout} from '../../layouts-common';
import {getResponseDetails, resetResponseDetails} from './action';
import {cancelRequests} from '../Loading/action';

import './style.css';

class ResponseView extends React.Component {
  componentDidMount() {
    const params = _pick(
      this.props.logData,
      ['originEventDate', 'originTime', 'queryId', 'domainName', 'networkIp'],
    );
    this.props.getResponseDetails(params);
  }

  componentWillUnmount() {
    this.props.resetResponseDetails();
    this.props.cancelRequests(Object.values(widgetKeys), false);
  }

  get textResponse() {
    return PlainResponseDetails({
      response: this.props.response,
      log: this.props.logData,
    });
  }

  print = () => {
    const data = {
      response: this.props.response,
      log: this.props.logData,
    };
    LS.setItem(PRINT_RESPONSE_LOCAL_STORAGE_KEY, JSON.stringify(data));
    window.open('/print-response');
  };

  render() {
    return (
      <LoadingLayout widgetKey={this.props.widgetKey}>
        <RenderOrEmpty isShow={!_isEmpty(this.props.response)}>
          <div className="response-view__buttons">
            <ButtonCopy id="inspect-response" content={this.textResponse} />
            <ButtonPrint onClick={this.print} />
          </div>
          <ResponseDetails response={this.props.response} log={this.props.logData} />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!this.props.response}>
          <div className="response-view__noData">
            {I18n.t('responseView.noData')}
          </div>
        </RenderOrEmpty>
      </LoadingLayout>
    );
  }
}

ResponseView.propTypes = {
  response: PropTypes.object,
  getResponseDetails: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  resetResponseDetails: PropTypes.func.isRequired,
  logData: PropTypes.object.isRequired,
  widgetKey: PropTypes.string,
};

ResponseView.defaultProps = {
  response: null,
  widgetKey: DEFAULT_WIDGET_KEY,
};

const mapStateToProps = (state) => ({
  response: state.responseDetailsReducer.response,
});

const mapDispatchToProps = {
  getResponseDetails,
  resetResponseDetails,
  cancelRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseView);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import {RenderOrEmpty} from '../../components/index';
import {DEFAULT_WIDGET_KEY} from '../../constants';

import loadingImg from './img/loading.gif';
import style from './style';

function Loading(props) {
  const {
    classes,
    widgetKey,
    loadingReducer,
    spinnerSize,
  } = props;
  const isLoading = _isEqual(widgetKey, DEFAULT_WIDGET_KEY)
    ? loadingReducer[widgetKey].isLoading
    : loadingReducer[widgetKey].isLoading && !loadingReducer[DEFAULT_WIDGET_KEY].isLoading;
  const imgClasses = classNames(classes.loading, {
    [classes.loading_default]: !_isEqual(spinnerSize, 'sm'),
    [classes.loading_sm]: _isEqual(spinnerSize, 'sm'),
  });

  return (
    <RenderOrEmpty isShow={isLoading}>
      <div className={classes.background}>
        <img
          src={loadingImg}
          alt={I18n.t('loading.altText')}
          className={imgClasses}
        />
      </div>
    </RenderOrEmpty>
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  spinnerSize: PropTypes.string,
  widgetKey: PropTypes.string,
  loadingReducer: PropTypes.object.isRequired,
};

Loading.defaultProps = {
  widgetKey: DEFAULT_WIDGET_KEY,
  spinnerSize: '',
};

const mapStateToProps = (state) => ({
  loadingReducer: state.loadingReducer,
});

export default connect(mapStateToProps, null)(withStyles(style)(Loading));

const style = {
  apiKeysTable__tableBody: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 197px)',
      marginBottom: '10px',
    },
  },
};

export default style;

import _get from 'lodash/get';

import {
  post,
  makeUrl,
} from '../../utils/fetcher';

import {METHODS} from '../../constants';

export const types = {
  ACTION_CHANGE_PASSWORD_SUCCESS: 'ACTION_CHANGE_PASSWORD_SUCCESS',
  ACTION_CHANGE_PASSWORD_FAILED: 'ACTION_CHANGE_PASSWORD_FAILED',
  ACTION_ENTERED_INCORRECT_CURRENT_PASSWORD: 'ACTION_ENTERED_INCORRECT_CURRENT_PASSWORD',
  RESET_ENTERED_INCORRECT_CURRENT_PASSWORD_ERROR: 'RESET_ENTERED_INCORRECT_CURRENT_PASSWORD_ERROR',
  RESET_CHANGE_PASSWORD_PAGE_DATA: 'RESET_CHANGE_PASSWORD_PAGE_DATA',
};

export function changePassword(data) {
  const url = makeUrl(METHODS.CHANGE_PASSWORD);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changePasswordSuccess());
          return null;
        }
        if (response.errors && _get(response, 'errors.currentPassword', false)) {
          dispatch(currentPasswordIncorrect());
          dispatch(changePasswordFailed());
          return null;
        }
        dispatch(changePasswordFailed());
        return null;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Change password failed', error);
      });
}

function changePasswordSuccess() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_CHANGE_PASSWORD_SUCCESS,
    });
  };
}

function changePasswordFailed() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_CHANGE_PASSWORD_FAILED,
    });
  };
}

function currentPasswordIncorrect() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_ENTERED_INCORRECT_CURRENT_PASSWORD,
    });
  };
}

export function resetEnteredIncorrectCurrentPasswordError() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_ENTERED_INCORRECT_CURRENT_PASSWORD_ERROR,
    });
  };
}

export function resetChangePasswordPageData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CHANGE_PASSWORD_PAGE_DATA,
    });
  };
}

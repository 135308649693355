import _cloneDeep from 'lodash/cloneDeep';
import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.clientLocalForwardingEditFormReducer, action) => {
  switch (action.type) {
    case types.RESET_CLIENT_LOCAL_FORWARDING_EDIT_FORM_DATA: {
      return _cloneDeep(initState.clientLocalForwardingEditFormReducer);
    }
    case types.SET_VALIDATION_CLIENT_LOCAL_FORWARDING_INFO: {
      return {
        ...state,
        isValidLocalForwardingInfo: action.isValid,
      };
    }
    case types.UPDATE_CLIENT_LOCAL_FORWARDING_INFO: {
      return {
        ...state,
        editableLocalForwardingInfo: action.localForwarding,
        isValidLocalForwardingInfo: action.isValidLocalForwardingInfo,
      };
    }

    default:
      return state;
  }
};

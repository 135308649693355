import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';
import _isNil from 'lodash/isNil';

import style from './style';

function Tags(props) {
  const {
    classes,
    tags,
  } = props;
  return (
    <div className={classes.container}>
      {tags.map((item) => {
        const parts = item.split(': ');
        const [tagName, details] = parts;
        const tagDetails = (!_isNil(details)) ? `: ${details}` : '';
        const tag = I18n.t(`tags.${tagName}`) + tagDetails;
        return (
          <div
            className={classes.item}
            key={uuid()}
          >
            {tag}
          </div>
        );
      })}
    </div>
  );
}

Tags.propTypes = {
  classes: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

Tags.defaultProps = {
  tags: [],
};

export default withStyles(style)(Tags);

import {
  makeUrl,
  get,
} from '../../utils/fetcher';

import {METHODS} from '../../constants';

import {types} from './actionTypes';

export function getUserAccounts() {
  const url = makeUrl(METHODS.GET_USER_ACCOUNTS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      let accounts = [];
      if (response && response.data) {
        accounts = response.data.accounts;
        dispatch({
          type: types.SET_USER_ACCOUNTS,
          data: accounts,
        });
      }
      return accounts;
    });
}

import _get from 'lodash/get';

import {METHODS} from '../../constants';
import {get, makeUrl} from '../../utils/fetcher';

export const types = {
  SET_INVOICES: 'SET_INVOICES',
  SET_SUBSCRIPTIONS_DETAILS: 'SET_SUBSCRIPTIONS_DETAILS',
  RESET_BILLING_STATE: 'RESET_BILLING_STATE',
};

export const getInvoices = (invoiceStatus = null) => {
  const url = makeUrl(METHODS.GET_INVOICES, {invoiceStatus});
  return (dispatch) => get(url, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        const invoices = response.data;
        dispatch({
          type: types.SET_INVOICES,
          invoices,
        });
      }
    });
};

export const goToInvoice = (invoiceId) => {
  const url = makeUrl(METHODS.GET_INVOICE_URL, {invoiceId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      const invoiceUrl = _get(response, 'data', null);
      if (url) {
        window.open(invoiceUrl, '_blank');
      }
    });
};

export const getSubscriptionDetails = () => {
  const url = makeUrl(METHODS.GET_SUBSCRIPTION_DETAILS);
  return (dispatch) => get(url, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_SUBSCRIPTIONS_DETAILS,
          subscriptionDetails: response.data,
        });
      }
    });
};

import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.usersManagerReducer, action) => {
  switch (action.type) {
    case types.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.data,
      };
    }
    case types.SET_SELECTED_USER_INDEX: {
      return {
        ...state,
        selectedUserIndex: action.index,
      };
    }
    case types.RESET_SELECTED_USER_INDEX: {
      return {
        ...state,
        selectedUser: initState.usersManagerReducer.selectedUser,
        selectedUserIndex: initState.usersManagerReducer.selectedUserIndex,
      };
    }
    case types.SET_SEARCH_OPTION: {
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          [action.optionName]: action.value,
        },
      };
    }
    case types.SET_GROUP_NAMES: {
      return {
        ...state,
        groupNames: action.groups,
      };
    }
    case types.SET_USERS: {
      return {
        ...state,
        tableData: action.data,
        users: action.data,
        isLastUsersPage: (action.data.length === 0),
      };
    }
    case types.ADD_USERS: {
      return {
        ...state,
        tableData: [
          ...state.tableData,
          ...action.data,
        ],
        isLastUsersPage: (action.data.length === 0),
      };
    }
    case types.RESET_USERS_MANAGER_DATA: {
      return {
        ...initState.usersManagerReducer,
        groupNames: state.groupNames,
      };
    }
    case types.RESET_FILTERS: {
      return {
        ...state,
        searchOptions: {...initState.usersManagerReducer.searchOptions},
      };
    }

    default: {
      return state;
    }
  }
};

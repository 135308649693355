import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';

import {
  ProfileHeaderAction,
  HelpAndSupportHeaderAction,
  NotificationBanner,
  RenderOrEmpty,
  AccountSwitcherHeaderAction, OrganizationSwitcherHeaderAction,
} from '../index';

import CollapseMenuItem from './CollapseMenuItem';
import MenuItem from './MenuItem';
import SidebarWrapper from './SidebarWrapper';

import style from './style';
import {ROLES_AVAILABILITY} from '../../constants';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      miniActive: true,
    };
  }

  getNavLinks = () => {
    const {
      classes,
      routes,
      loggedAccount,
    } = this.props;
    return (
      <List className={classes.navLinks}>
        {routes.map((item) => {
          const isAvailable = _get(ROLES_AVAILABILITY, [item.name, loggedAccount.role], true);
          if (!isAvailable) return null;

          const isActive = this.isActiveRoute(item.path);
          const isMiniText = this.props.miniActive && this.state.miniActive;
          if (item.redirect) {
            return null;
          }
          if (item.collapse) {
            return (
              <CollapseMenuItem
                classes={classes}
                isActive={isActive}
                item={item}
                key={uuid()}
                openCollapse={this.openCollapse}
              />
            );
          }
          return (
            <MenuItem
              classes={classes}
              isActive={isActive}
              isMiniText={isMiniText}
              item={item}
              key={uuid()}
            />
          );
        })}
      </List>
    );
  };

  getLogo = () => {
    const {
      classes,
      logo,
      logoMini,
    } = this.props;
    const miniActive = this.props.miniActive && this.state.miniActive;
    return (
      <a
        className={classNames(
          classes.logo,
          {
            [classes.logo_mini]: miniActive,
          },
        )}
        href="/"
      >
        <RenderOrEmpty isShow={!miniActive}>
          <img
            src={logo}
            alt="logo"
            className={classes.logo__img}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={miniActive}>
          <img
            src={logoMini}
            alt="logo_mini"
            className={classes.logo__img_mini}
          />
        </RenderOrEmpty>
      </a>
    );
  };

  openCollapse = (collapse) => {
    this.setState((prevState) => ({
      [collapse]: !prevState[collapse],
    }));
  };

  isActiveRoute = (routeName) => this.props.location.pathname.indexOf(routeName) > -1;

  render() {
    const {
      classes,
      isOpen,
      loggedAccount,
      accountInfo,
      currentOrganization,
      notificationBannerData,
      userAccounts,
      userOrganizations,
    } = this.props;
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', false);

    const drawerPaper = classNames(
      classes.drawerPaper,
      {
        [classes.drawerPaper_mini]: this.props.miniActive && this.state.miniActive,
      },
    );
    const modalProps = {
      keepMounted: true,
    };

    const drawerClasses = {
      paper: classNames(drawerPaper, classes.drawerPaper_primary),
    };
    const sidebarWrapperClassName = classNames(
      classes.sidebarWrapper,
      {
        [classes.drawerPaper_mini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
      },
    );
    const logo = this.getLogo();
    const navLinks = this.getNavLinks();
    return (
      <>
        <Hidden mdUp={true}>
          <Drawer
            variant="temporary"
            anchor="right"
            open={isOpen}
            classes={drawerClasses}
            onClose={this.props.handleDrawerToggle}
            ModalProps={modalProps}
          >
            {logo}
            <SidebarWrapper className={sidebarWrapperClassName}>
              <>
                <NotificationBanner notificationData={notificationBannerData} />
                <AccountSwitcherHeaderAction
                  loggedAccount={loggedAccount}
                  placement="sidebar"
                  switchAccount={this.props.switchAccount}
                  userAccounts={userAccounts}
                />
                <RenderOrEmpty isShow={organizationsAllowed}>
                  <OrganizationSwitcherHeaderAction
                    currentOrganization={currentOrganization}
                    placement="sidebar"
                    switchOrganization={this.props.switchOrganization}
                    userOrganizations={userOrganizations}
                  />
                </RenderOrEmpty>
                <div className={classes.sectionDivider} />
                <HelpAndSupportHeaderAction />
                <ProfileHeaderAction
                  logout={this.props.logout}
                  loggedAccount={loggedAccount}
                  accountInfo={accountInfo}
                />
                <div className={classes.sectionDivider} />
                {navLinks}
              </>
            </SidebarWrapper>
          </Drawer>
        </Hidden>
        <Hidden smDown={true}>
          <Drawer
            anchor="left"
            variant="permanent"
            open={true}
            classes={drawerClasses}
          >
            {logo}
            <SidebarWrapper className={sidebarWrapperClassName}>
              {navLinks}
            </SidebarWrapper>
          </Drawer>
        </Hidden>
      </>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  location: PropTypes.object,
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  logo: PropTypes.string,
  logoMini: PropTypes.string,
  miniActive: PropTypes.bool.isRequired,
  notificationBannerData: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  userAccounts: PropTypes.array.isRequired,
  userOrganizations: PropTypes.array.isRequired,

  handleDrawerToggle: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  switchAccount: PropTypes.func.isRequired,
  switchOrganization: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  isOpen: false,
  location: {
    pathname: '',
  },
  logo: undefined,
  logoMini: undefined,
  currentOrganization: null,
};

export default withStyles(style)(Sidebar);

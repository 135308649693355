const style = {
  form: {
    '@media(min-height: 480px)': {
      height: 'calc(100vh - 100px)',
    },
  },
  list: {
    '@media(min-height: 480px)': {
      height: 'calc(100vh - 100px)',
    },
  },
  list_compressed: {
    '@media(min-height: 480px)': {
      height: 'calc(100vh - 100px)',
    },
  },
};

export default style;

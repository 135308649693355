import {makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {setJWT, setRefresh} from '../../utils/jwt';
import {setLoggedAccount} from '../../utils/account';

export function confirmEmailUpdating(token) {
  const url = makeUrl(METHODS.CONFIRM_EMAIL);
  const requestData = {token};
  return (dispatch) => post(url, requestData, dispatch, false)
    .then(((result) => {
      if (result && result.success) {
        const data = result.data;
        if (data) {
          setJWT(data.token);
          setRefresh(data.refreshToken);
          setLoggedAccount(
            data.accountId,
            data.role,
            data.name,
          );
        }
        return true;
      }
      return false;
    }))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Confirmation of email failed', error);
    });
}

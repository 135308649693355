import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {I18n} from 'react-redux-i18n';

import {CardWithHeader, TableWithPaginationAndFiltration} from '../index';

import style from './style';
import {CustomButton} from '../../material-dashboard-pro-react/components';

class SelectableEntityVerticalPairedListElement extends React.Component {
  getColumns = () => {
    const headerStyle = {margin: '0'};
    const columnStyle = {...headerStyle, whiteSpace: 'unset'};
    return [
      {
        Header: I18n.t('components.selectableEntityVerticalPairedList.element.headerName'),
        accessor: 'name',
        filterable: false,
        sortable: false,
        resizable: false,
        columnStyle,
        headerStyle,
      },
    ];
  };

  // getFiltered = () => {
  //   const {searchQuery} = this.props;
  //   return [
  //     {
  //       id: 'all',
  //       value: searchQuery,
  //     },
  //   ];
  // };

  render() {
    const {
      classes,
      title,
      isEditMode,
      dataList,
      selectedRows,
      anotherTableSelectedRows,
      onDeselectClick,
      onSelectAllClick,
      onRowClick,
    } = this.props;
    const isSelectAllDisabled = (!isEditMode || dataList.length === 0
      || dataList.length === selectedRows.length);
    const isDeselectDisabled = !isEditMode || selectedRows.length === 0;
    const handleClickRow = isEditMode && anotherTableSelectedRows.length === 0
      ? onRowClick
      : undefined;
    const defaultSorted = [{
      index: 'ranking',
      desc: false,
    }];
    const tableStyle = {
      height: 'calc(100vh - 501px)',
      minHeight: '300px',
    };
    const theadFilterProps = {
      style: {
        display: 'none',
      },
    };
    return (
      <CardWithHeader
        cardTitle={title}
        cardTitleColor="primary"
        customCardClasses="card_selectableEntityVerticalPairedList"
        customCardContentClasses="card__content_selectableEntityVerticalPairedList"
        content={(
          <>
            <div className={classes.selectableEntityListElement__selectionButtonSection}>
              <CustomButton
                color="secondary"
                disabled={isDeselectDisabled}
                customClasses="selectableEntityListElement__deselectButton"
                onClick={onDeselectClick}
                size="xs"
              >
                {I18n.t('components.selectableEntityVerticalPairedList.element.deselectButton')}
              </CustomButton>
              <CustomButton
                color="secondary"
                disabled={isSelectAllDisabled}
                customClasses="selectableEntityListElement__selectAllButton"
                onClick={onSelectAllClick}
                size="xs"
              >
                {I18n.t('components.selectableEntityVerticalPairedList.element.selectAllButton')}
              </CustomButton>
            </div>
            <TableWithPaginationAndFiltration
              columns={this.getColumns()}
              data={dataList}
              showPageSizeOptions={false}
              handleClickRow={handleClickRow}
              defaultSorted={defaultSorted}
              minRows={1}
              selectedRowIndices={selectedRows}
              style={tableStyle}
              theadFilterProps={theadFilterProps}
              showPagination={false}
              defaultPageSize={dataList.length}
              pageSize={dataList.length}
              // onPageChange={this.onPageChange}
              disableWhiteSpaceNoWrap={true}
            />
          </>
        )}
      />
    );
  }
}

SelectableEntityVerticalPairedListElement.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  dataList: PropTypes.array.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  anotherTableSelectedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  onDeselectClick: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default withStyles(style)(SelectableEntityVerticalPairedListElement);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';

import {UsageBillingTable, UsageBillingSettings} from '../../components/index';

import {cancelRequests} from '../Loading/action';
import {
  getOrganizationsUsageStats,
  clearOrganizationUsageStats,
  exportCSV,
  changeInvoiceDate,
  setBillingPeriod,
  setTableCostsCalculated,
  updateTableCost,
} from './action';

import {getUsageBillingTableData} from './utils';
import {ROUTES} from '../../constants';
import {widgetKeys} from '../../app-common/Organizations/constants';

class UsageBillingTab extends Component {
  static initialLocalState = {
    tableData: [],
    isButtonCalculateEnabled: false,
    isButtonExportEnabled: false,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateTableData(prevProps, props) {
    return !_isEqual(prevProps.organizationUsageStats, props.organizationUsageStats)
      || !_isEqual(prevProps.tableCostsCalculated, props.tableCostsCalculated)
      || !_isEqual(prevProps.selectedPeriodIndex, props.selectedPeriodIndex);
  }

  static shouldGoToRootPage(prevProps, props) {
    return !_isNil(props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = {...UsageBillingTab.initialLocalState};
  }

  componentDidMount() {
    const {
      queryCost,
      seatCost,
      changeInvoiceDate,
      setTableCostsCalculated,
    } = this.props;
    const tableCostsCalculated = !_isEmpty(queryCost) || !_isEmpty(seatCost);

    setTableCostsCalculated(tableCostsCalculated);
    this.setButtonCalculateEnabled(false);
    this.setButtonExportEnabled(tableCostsCalculated);
    changeInvoiceDate(moment());
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      period,
      queryCost,
      seatCost,
      organizationUsageStats,
      getOrganizationsUsageStats,
      setTableCostsCalculated,
      cancelRequests,
      clearOrganizationUsageStats,
    } = this.props;

    if (UsageBillingTab.shouldGoToRootPage(prevProps, this.props)) {
      clearOrganizationUsageStats();
      cancelRequests([widgetKeys.USAGE_BILLING], false);
      history.push(ROUTES.DEFAULT);
      return;
    }
    if (UsageBillingTab.wasDataScopeSwitched(prevProps, this.props)) {
      const tableCostsCalculated = !_isEmpty(queryCost) || !_isEmpty(seatCost);
      setTableCostsCalculated(tableCostsCalculated);
      this.setButtonCalculateEnabled(!tableCostsCalculated);
      this.setButtonExportEnabled(tableCostsCalculated);
      cancelRequests([widgetKeys.USAGE_BILLING], false);
      getOrganizationsUsageStats(period, {queryCost, seatCost});
      return;
    }
    if (UsageBillingTab.shouldUpdateTableData(prevProps, this.props)) {
      this.setState({
        tableData: getUsageBillingTableData(organizationUsageStats),
      });
    }
  }

  componentWillUnmount() {
    this.props.clearOrganizationUsageStats();
    this.props.cancelRequests(Object.values(widgetKeys), true);
  }

  handleClickCalculate = () => {
    const {
      period,
      queryCost,
      seatCost,
      getOrganizationsUsageStats,
      setTableCostsCalculated,
    } = this.props;
    getOrganizationsUsageStats(period, {queryCost, seatCost})
      .then(() => {
        this.setButtonCalculateEnabled(false);
        this.setButtonExportEnabled(true);
        setTableCostsCalculated(true);
      });
  };

  handleExportCSV = () => {
    const {period, queryCost, seatCost, exportCSV} = this.props;
    exportCSV(period, {queryCost, seatCost});
  };

  handleChangeBillingPeriod = (newPeriodIndex, newPeriod) => {
    const {
      queryCost,
      seatCost,
      tableCostsCalculated,
      setBillingPeriod,
      getOrganizationsUsageStats,
    } = this.props;
    setBillingPeriod(newPeriodIndex, newPeriod);
    getOrganizationsUsageStats(newPeriod, {queryCost, seatCost});

    const shouldEnableButtonCalculate = !tableCostsCalculated
      && (!_isEmpty(queryCost)
      || !_isEmpty(seatCost));
    this.setButtonCalculateEnabled(shouldEnableButtonCalculate);
    this.setButtonExportEnabled(tableCostsCalculated);
  };

  handleChangeInvoiceDate = (date) => {
    const {changeInvoiceDate} = this.props;
    changeInvoiceDate(date);
  };

  handleChangeCostInput = (name, value) => {
    const {queryCost, seatCost, setTableCostsCalculated, updateTableCost} = this.props;
    updateTableCost(name, value);
    setTableCostsCalculated(false);
    const updatedCosts = {
      queryCost,
      seatCost,
      [name]: value,
    };
    this.setButtonCalculateEnabled(
      !_isEmpty(updatedCosts.queryCost) || !_isEmpty(updatedCosts.seatCost));
    this.setButtonExportEnabled(false);
  };

  setButtonCalculateEnabled = (flag) => {
    this.setState({isButtonCalculateEnabled: flag});
  };

  setButtonExportEnabled = (flag) => {
    this.setState({isButtonExportEnabled: flag});
  };

  render() {
    const {
      period,
      selectedPeriodIndex,
      queryCost,
      seatCost,
      invoiceDate,
    } = this.props;
    const {tableData, isButtonCalculateEnabled, isButtonExportEnabled} = this.state;

    return (
      <div>
        <UsageBillingSettings
          period={period}
          selectedPeriodIndex={selectedPeriodIndex}
          queryCost={queryCost}
          seatCost={seatCost}
          invoiceDate={invoiceDate}
          isCalculateEnabled={isButtonCalculateEnabled}
          isExportCSVEnabled={isButtonExportEnabled}
          onChangePeriod={this.handleChangeBillingPeriod}
          onChangeInvoiceDate={this.handleChangeInvoiceDate}
          onChangeCost={this.handleChangeCostInput}
          onClickCalculate={this.handleClickCalculate}
          onClickExportCSV={this.handleExportCSV}
        />
        <UsageBillingTable tableData={tableData} />
      </div>
    );
  }
}

UsageBillingTab.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  history: PropTypes.object.isRequired,
  organizationUsageStats: PropTypes.array.isRequired,
  period: PropTypes.object,
  selectedPeriodIndex: PropTypes.number.isRequired,
  queryCost: PropTypes.string,
  seatCost: PropTypes.string,
  invoiceDate: PropTypes.object,
  tableCostsCalculated: PropTypes.bool.isRequired,

  changeInvoiceDate: PropTypes.func.isRequired,
  setBillingPeriod: PropTypes.func.isRequired,
  setTableCostsCalculated: PropTypes.func.isRequired,
  exportCSV: PropTypes.func.isRequired,
  getOrganizationsUsageStats: PropTypes.func.isRequired,
  clearOrganizationUsageStats: PropTypes.func.isRequired,
  updateTableCost: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

UsageBillingTab.defaultProps = {
  period: null,
  currentOrganization: null,
  queryCost: null,
  seatCost: null,
  invoiceDate: null,
};

const mapStateToProps = (state) => ({
  period: state.usageBillingReducer.period,
  selectedPeriodIndex: state.usageBillingReducer.selectedPeriodIndex,
  queryCost: state.usageBillingReducer.queryCost,
  seatCost: state.usageBillingReducer.seatCost,
  invoiceDate: state.usageBillingReducer.invoiceDate,
  tableCostsCalculated: state.usageBillingReducer.tableCostsCalculated,
  organizationUsageStats: state.usageBillingReducer.organizationUsageStats,

  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

const mapDispatchToProps = ({
  cancelRequests,
  exportCSV,
  getOrganizationsUsageStats,
  setBillingPeriod,
  changeInvoiceDate,
  setTableCostsCalculated,
  clearOrganizationUsageStats,
  updateTableCost,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsageBillingTab));

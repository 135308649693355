import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityCreatingCard} from '../index';

import {NetworkEditForm} from '../../containers/index';

function NetworkCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
    isValidNetworkInfo,
  } = props;

  return (
    <EntityCreatingCard
      title={I18n.t('campusPage.networksTab.createNetwork')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidNetworkInfo}
      customCardClass="card_networks"
      customCardContentClass="card__content_networks"
      content={
        <NetworkEditForm />
      }
    />
  );
}

NetworkCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidNetworkInfo: PropTypes.bool.isRequired,
};

export default NetworkCreating;

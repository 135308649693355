import initState from '../../reducers/redux-initial-state';
import {types} from './action';
import {
  getLocalDomainsTableData,
  getUpdatedLocalDomainIndex,
} from './service';

export default (state = initState.campusLocalDomainsTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_LOCAL_DOMAIN_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        localDomainState: action.localDomainState,
      };
    }
    case types.SET_LOCAL_DOMAINS: {
      return {
        ...state,
        localDomains: action.data.localDomains,
        networkAgentAllowed: action.data.networkAgentAllowed,
        localDomainsTableData: getLocalDomainsTableData(
          action.data.localDomains,
          action.defaultPolicyName,
        ),
      };
    }
    case types.SET_LOCAL_DOMAINS_TABLE_DATA: {
      return {
        ...state,
        localDomains: state.localDomains,
      };
    }
    case types.SET_SELECTED_LOCAL_DOMAIN: {
      return {
        ...state,
        selectedLocalDomain: action.data,
      };
    }
    case types.SET_SELECTED_LOCAL_DOMAIN_INDEX: {
      return {
        ...state,
        selectedLocalDomainIndex: action.selectedLocalDomainIndex,
      };
    }
    case types.SET_UPDATED_LOCAL_DOMAIN: {
      return {
        ...state,
        selectedLocalDomainIndex: getUpdatedLocalDomainIndex(action.data.id, state.localDomains),
        selectedLocalDomain: action.data,
      };
    }
    case types.RESET_SELECTED_LOCAL_DOMAIN_INDEX: {
      return {
        ...state,
        selectedLocalDomainIndex: initState.campusLocalDomainsTabReducer.selectedLocalDomainIndex,
        selectedLocalDomain: initState.campusLocalDomainsTabReducer.selectedLocalDomain,
      };
    }
    case types.CLEAR_LOCAL_DOMAINS: {
      return {...initState.campusLocalDomainsTabReducer};
    }
    default:
      return state;
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {Settings} from '../../icons/index';

import ActionTemplate from './ActionTemplate';

import style from './style';

class SettingsHeaderAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const {classes} = this.props;
    const {isOpen} = this.state;
    return (
      <ActionTemplate
        actionName={I18n.t('headerUser.settingsOption.name')}
        classes={classes}
        handleClick={() => {}}
        handleClose={() => {}}
        icon={<Settings className={classes.links} />}
        isOpen={isOpen}
      >
        <div />
      </ActionTemplate>
    );
  }
}

SettingsHeaderAction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(SettingsHeaderAction);

import {
  post,
  makeUrl,
} from '../../utils/fetcher';

import {
  setJWT,
  setRefresh,
  clearLocalStorage,
} from '../../utils/jwt';

import {
  METHODS,
} from '../../constants';

function finishFailedRefresh() {
  return () => {
    clearLocalStorage();
  };
}

export function refreshToken(refreshToken, token) {
  const url = makeUrl(METHODS.REFRESH_TOKEN);
  return (dispatch) => post(url, {refreshToken, token}, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        setJWT(response.data.token);
        setRefresh(response.data.refreshToken);
        return true;
      }
      dispatch(finishFailedRefresh());
      return false;
    })
    .catch(() => {
      dispatch(finishFailedRefresh());
      return false;
    });
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

import {NavPills} from '../../material-dashboard-pro-react/components';

import {TABS} from './constants';
import {ROUTES, USER_ROLES, ROLES_AVAILABILITY} from '../../constants';
import {
  OperatorsTable,
  AssignNewSuperAdminPopup,
  ContainerWithListAndForm,
} from '../../components';
import {
  NewOperatorForm,
  OperatorEditForm,
  AuthCodeChecker,
} from '../../containers';

import {
  assignSuperAdmin,
  enableDisableMFA,
  getOperators,
  resetState,
  setEditingMode,
  updateOperator,
} from './action';
import {getUserInfo} from '../../scout-dns/layouts/Operator/action';
import {getUserAccounts} from '../../actions/userAccounts/getUserAccounts';
import {openLogin2FAForm} from '../../actions/auth/login';
import {getAuthCode} from '../../containers/AuthCodeChecker/action';

import {hasAuthorities} from '../../utils/account';

import SingleSignOn from '../SingleSignOn/SingleSignOn';

class AccessManagement extends React.Component {
  static authCheckReasons = {
    ASSIGN_SUPER_ADMIN: 'ASSIGN_SUPER_ADMIN',
    CREATE_OPERATOR: 'CREATE_OPERATOR',
  };

  static initialState = {
    selectedOperator: null,
    selectedOperatorIndex: -1,
    operatorToBeNewSA: null,
    isAssigningWindow: false,
    isNewOperator: false,
    authCodeCheckReason: '',
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = {...AccessManagement.initialState};
  }

  componentDidMount() {
    this.props.getOperators();
  }

  componentDidUpdate(prevProps) {
    if (AccessManagement.wasDataScopeSwitched(prevProps, this.props)) {
      if (hasAuthorities('accessManagement')) {
        this.props.getOperators();
        this.resetState();
      } else {
        this.props.history.push(ROUTES.DEFAULT);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  get operatorForm() {
    if (this.state.selectedOperator) {
      return (
        <OperatorEditForm operator={this.state.selectedOperator}
                          operators={this.props.operators}
                          updatingCallback={this.updatingCallback}
                          resetSelectedOperator={this.resetState}
                          updateOperator={this.updateOperator}
        />
      );
    }
    if (this.state.isNewOperator) {
      return (
        <NewOperatorForm resetAddingOfOperator={this.resetState}
                         updatingCallback={this.updatingCallback}
        />
      );
    }
    return null;
  }

  get assigningNewSuperAdminWindow() {
    if (!this.state.isAssigningWindow) return null;

    let operators = _cloneDeep(this.props.operators);
    operators = operators.filter((o) => o.role !== USER_ROLES.SUPER_ADMIN);

    return (
      <AssignNewSuperAdminPopup
        operators={operators}
        cancel={() => this.setState({isAssigningWindow: false})}
        confirm={this.checkAuthCodeAndAssignNewSA}
        newRole={USER_ROLES.ADMIN}
      />
    );
  }

  get isTableCompressed() {
    return !!this.state.selectedOperator || this.state.isNewOperator;
  }

  openAssigningWindow = () => this.setState({isAssigningWindow: true});

  selectOperator = (e) => this.setState({
    selectedOperator: _get(this.props.operators, e, null),
    selectedOperatorIndex: e,
  });

  resetState = () => {
    this.props.setEditingMode(false);
    this.setState({...AccessManagement.initialState});
  };

  // eslint-disable-next-line
  changeSuperAdmin = (accountId, operatorId, newSuperAdminRole) => this.props.assignSuperAdmin({accountId, operatorId, newSuperAdminRole})
    .then(this.props.getUserAccounts)
    .then(() => {
      if (hasAuthorities('accessManagement')) {
        this.updatingCallback();
      } else {
        this.props.history.push(ROUTES.DEFAULT);
      }
    });

  // eslint-disable-next-line
  updateOperator = (accountId, operatorId, operatorRole, operatorOrganizations) => this.props.updateOperator(accountId, operatorId, operatorRole, operatorOrganizations)
    .then(this.props.getUserAccounts)
    .then(() => {
      if (hasAuthorities('accessManagement')) {
        this.updatingCallback();
      } else {
        this.props.history.push(ROUTES.DEFAULT);
      }
    });

  assignNewSuperAdmin = (operator) => {
    const accountId = _get(this.props, 'loggedAccount.accountId', null);
    const operatorId = _get(operator, 'id', null);
    const newSARole = _get(this.state, 'selectedOperator.role', USER_ROLES.ADMIN);

    this.changeSuperAdmin(accountId, operatorId, newSARole);
  };

  checkAuthCodeAndAssignNewSA = (operator) => {
    // eslint-disable-next-line no-shadow
    const {accountInfo, authCodeChecked, openLogin2FAForm, getAuthCode} = this.props;
    const mfaAllowed = _get(accountInfo, 'mfaAllowed', false);
    const mfaEnabled = _get(accountInfo, 'mfaEnabled', false);

    if (mfaAllowed && mfaEnabled && !authCodeChecked) {
      this.setState({
        isAssigningWindow: false,
        authCodeCheckReason: AccessManagement.authCheckReasons.ASSIGN_SUPER_ADMIN,
      });
      getAuthCode();
      openLogin2FAForm();
      this.setState({operatorToBeNewSA: operator});
    } else {
      this.assignNewSuperAdmin(operator);
    }
  };

  updatingCallback = () => {
    this.props.setEditingMode(false);
    this.setState({...AccessManagement.initialState});
    this.props.getOperators();
  };

  checkAuthCodeAndOpenAddingForm = () => {
    // eslint-disable-next-line no-shadow
    const {accountInfo, authCodeChecked, openLogin2FAForm, getAuthCode} = this.props;
    const mfaAllowed = _get(accountInfo, 'mfaAllowed', false);
    const mfaEnabled = _get(accountInfo, 'mfaEnabled', false);

    if (mfaAllowed && mfaEnabled && !authCodeChecked) {
      this.setState({
        isAssigningWindow: false,
        authCodeCheckReason: AccessManagement.authCheckReasons.CREATE_OPERATOR,
      });
      getAuthCode();
      openLogin2FAForm();
    } else {
      this.openAddingForm();
    }
  };

  openAddingForm = () => {
    this.props.setEditingMode(true);
    this.setState((prevState) => ({
      ...AccessManagement.initialState,
      isNewOperator: true,
      authCodeCheckReason: prevState.authCodeCheckReason,
    }));
  };

  toggleMFA = () => {
    const mfaEnabled = _get(this.props.accountInfo, 'mfaEnabled', false);
    this.props.enableDisableMFA(!mfaEnabled)
      .then(() => this.props.getUserInfo());
  };

  onSuccessCodeCheckAssignSuperAdmin = () => {
    const {operatorToBeNewSA} = this.state;
    this.assignNewSuperAdmin(operatorToBeNewSA);
    this.setState({operatorToBeNewSA: null});
  };

  onSuccessCodeCheck = () => {
    const {authCodeCheckReason} = this.state;
    const reasonMap = {
      [AccessManagement.authCheckReasons.ASSIGN_SUPER_ADMIN]:
        this.onSuccessCodeCheckAssignSuperAdmin,
      [AccessManagement.authCheckReasons.CREATE_OPERATOR]: this.openAddingForm,
    };
    const callback = reasonMap[authCodeCheckReason];
    if (!_isNil(callback)) {
      callback();
      this.setState({authCodeCheckReason: ''});
    }
  };

  render() {
    const {isShowCheckAuthCode, isSecondFactorFailed, loggedAccount} = this.props;

    const tabs = [
      {
        tabButton: I18n.t(`accessManagementPage.${TABS.OPERATORS}.name`),
        tabContent:
  <>
    {this.assigningNewSuperAdminWindow}
    <AuthCodeChecker
              isOpen={isShowCheckAuthCode}
              onSuccess={this.onSuccessCodeCheck}
              isAuthCodeFailed={isSecondFactorFailed}
    />
    <ContainerWithListAndForm
              list={(
                <OperatorsTable
                  operators={this.props.operators}
                  selectedOperatorIndex={this.state.selectedOperatorIndex}
                  accountInfo={this.props.accountInfo}
                  isCompressed={this.isTableCompressed}
                  isEditing={this.props.isEditingMode}
                  handleClickNew={this.checkAuthCodeAndOpenAddingForm}
                  handleClickRow={this.selectOperator}
                  onPromoteSAClick={this.openAssigningWindow}
                  enableDisableMFA={this.toggleMFA}
                />
              )}
              form={this.operatorForm}
    />
  </>,
      },
    ];

    if (ROLES_AVAILABILITY.configureSSO[loggedAccount.role]) {
      tabs.push(
        {
          tabButton: I18n.t(`accessManagementPage.${TABS.SSO}.name`),
          tabContent: <SingleSignOn />,
        },
      );
    }

    return (
      <NavPills
        color="secondary"
        onChange={this.onTabChange}
        className="root_fullScreen"
        wrapperClassName="tab__contentWrapper_fullScreen"
        contentClassName="tab__content_fullScreen"
        isSwipeable={false}
        tabs={tabs}
      />
    );
  }
}

AccessManagement.propTypes = {
  getOperators: PropTypes.func.isRequired,
  operators: PropTypes.array.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  isShowCheckAuthCode: PropTypes.bool,
  isSecondFactorFailed: PropTypes.bool,
  authCodeChecked: PropTypes.bool,
  updateOperator: PropTypes.func.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setEditingMode: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  resetState: PropTypes.func.isRequired,
  enableDisableMFA: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  openLogin2FAForm: PropTypes.func.isRequired,
  assignSuperAdmin: PropTypes.func.isRequired,
  getAuthCode: PropTypes.func.isRequired,
};

AccessManagement.defaultProps = {
  currentOrganization: null,
  isShowCheckAuthCode: false,
  isSecondFactorFailed: false,
  authCodeChecked: false,

};

const mapStateToProps = (state) => ({
  isShowCheckAuthCode: state.authReducer.show2FAForm,
  isSecondFactorFailed: state.authReducer.isSecondFactorFailed,
  authCodeChecked: state.authReducer.authCodeChecked,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  accountInfo: state.operatorLayoutReducer.accountInfo,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  operators: state.accessManagementReducer.operators,
  isEditingMode: state.accessManagementReducer.isEditingMode,
});

const mapDispatchToProps = {
  getOperators,
  updateOperator,
  getUserAccounts,
  setEditingMode,
  resetState,
  enableDisableMFA,
  getUserInfo,
  getAuthCode,
  openLogin2FAForm,
  assignSuperAdmin,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccessManagement));

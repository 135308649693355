// eslint-disable-next-line max-len
import regularCardStyle from '../../assets/jss/material-dashboard-pro-react/components/regularCardStyle';

const style = {
  ...regularCardStyle,
  card: {
    ...regularCardStyle.card,
    verticalAlign: 'top',
  },
  cardContent: {
    ...regularCardStyle.cardContent,
    padding: '12px 20px !important',
  },
};

export default style;

import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';

export function getSubnetsTableData(subnets) {
  return subnets.map((item) => ({
    id: item.id,
    name: item.name,
    networkId: item.networkId,
    networkName: item.forAllNetworks ? '<All WANs>' : item.networkName,
    policyId: item.policyId,
    policyName: (!_isNull(item.policyId))
      ? item.policyName : '<WAN Default>',
    ipAddressRange: item.ipAddressRange,
    state: item.enabled,
    forAllNetworks: item.forAllNetworks,
  }));
}

export function getSubnetToCreate(newSubnet, campusId) {
  return {
    name: newSubnet.name,
    networkId: newSubnet.networkId,
    policyId: newSubnet.policyId,
    ipAddressRange: newSubnet.ipAddressRange,
    locationId: campusId,
    enabled: newSubnet.enabled,
    forAllNetworks: !newSubnet.networkId,
  };
}

export function getSubnetToUpdate(srcSubnet, campusId) {
  return {
    ...getSubnetToCreate(srcSubnet, campusId),
    id: srcSubnet.id,
  };
}

export function getUpdatedSubnetIndex(updatedSubnetId, subnets) {
  return _findIndex(subnets, (o) => _isEqual(o.id, updatedSubnetId));
}

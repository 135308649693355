import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';

import EntityCreatingCard from '../EntityCreatingCard/EntityCreatingCard';
import PersonasEditForm from '../../containers/PersonasEditForm/PersonasEditForm';
import {resetPersonaFormValues} from '../../containers/PersonasEditForm/action';

function PersonaCreating({handleClickButtonCancel,
  handleClickButtonSave,
  isValidPersonaInfo,
  domains,
  getPolicies}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPersonaFormValues());
  }, []);

  return (
    <EntityCreatingCard
      title={I18n.t('users.usersConfigurer.persona.newPersona')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidPersonaInfo}
      customCardContentClass="card__content_clientProfile"
      content={(
        <PersonasEditForm
          getPolicies={getPolicies}
          domains={domains}
          isEditMode={true}
        />
      )}
    />
  );
}

PersonaCreating.propTypes = {
  getPolicies: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidPersonaInfo: PropTypes.bool.isRequired,
};

export default PersonaCreating;

import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.changePasswordReducer, action) => {
  switch (action.type) {
    case types.ACTION_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordChanged: true,
        enteredIncorrectCurrentPassword:
          initState.changePasswordReducer.enteredIncorrectCurrentPassword,
      };
    }
    case types.ACTION_CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        isPasswordChanged: false,
      };
    }
    case types.ACTION_ENTERED_INCORRECT_CURRENT_PASSWORD: {
      return {
        ...state,
        isPasswordChanged: false,
        enteredIncorrectCurrentPassword: true,
      };
    }
    case types.RESET_ENTERED_INCORRECT_CURRENT_PASSWORD_ERROR: {
      return {
        ...state,
        enteredIncorrectCurrentPassword:
          initState.changePasswordReducer.enteredIncorrectCurrentPassword,
      };
    }
    case types.RESET_CHANGE_PASSWORD_PAGE_DATA: {
      return {
        ...state,
        ...initState.changePasswordReducer,
      };
    }
    default:
      return state;
  }
};

import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _without from 'lodash/without';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {createNotificationProfile} from '../../app-common/NotificationsManagement/action';
import EntityCreatingCard from '../../components/EntityCreatingCard/EntityCreatingCard';
import NotificationProfileForm
  from '../../components/NotificationProfileForm/NotificationProfileForm';
import {isEmail, isNotEmpty, validate} from '../../utils/validators';

class NewNotificationProfileForm extends React.Component {
  static validationRules = [{
    name: 'email',
    validationRules: [isNotEmpty, isEmail],
  }];

  state = {
    notificationProfile: {
      email: '',
      description: '',
      notifications: this.props.notificationTypes.reduce((accum, current) => (
        [...accum, ...current.subtypes]
      ), []),
    },
    validationResult: {
      validationState: {},
      isValid: false,
    },
  };

  onChange = (fieldName, value) =>
    this.setState((prevState) => {
      const notificationProfile = _cloneDeep(prevState.notificationProfile);
      _set(
        notificationProfile,
        fieldName,
        fieldName === 'description'
          ? value
          : value.trim(),
      );

      const validationResult = validate(
        NewNotificationProfileForm.validationRules,
        notificationProfile,
        prevState.validationResult.validationState,
      );

      return {notificationProfile, validationResult};
    });

  onSwitchToggle = (subtype, active) => {
    const notificationProfile = _cloneDeep(this.state.notificationProfile);
    const {notifications} = notificationProfile;
    let newNotifications;

    if (active) {
      newNotifications = [
        ...notifications,
        subtype,
      ];
    } else {
      newNotifications = _without(notifications, subtype);
    }

    this.setState((prevState) => ({
      notificationProfile: {
        ...prevState.notificationProfile,
        notifications: newNotifications,
      },
    }));
  };

  saveNotificationProfile = () => {
    this.props.createNotificationProfile(this.state.notificationProfile)
      .then(this.props.updatingCallback);
  };

  render() {
    return (
      <EntityCreatingCard
        title={I18n.t('notificationsManagementPage.newNotificationProfile.title')}
        content={(
          <NotificationProfileForm
            notificationProfile={this.state.notificationProfile}
            notificationTypes={this.props.notificationTypes}
            onChange={this.onChange}
            isNewNotificationProfile={true}
            isEditing={true}
            validationState={this.state.validationResult.validationState}
            onSwitchToggle={this.onSwitchToggle}
          />
        )}
        handleClickButtonCancel={this.props.resetAddingOfNotificationProfile}
        handleClickButtonSave={this.saveNotificationProfile}
        isValidEntity={this.state.validationResult.isValid}
      />
    );
  }
}

NewNotificationProfileForm.propTypes = {
  notificationTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    subtypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired).isRequired,

  resetAddingOfNotificationProfile: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,
  createNotificationProfile: PropTypes.func.isRequired,
};

const mapDispatchToProps = {createNotificationProfile};

export default connect(null, mapDispatchToProps)(NewNotificationProfileForm);

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {getWhiteBlackLists as getWhiteBlackListsAPI} from '../../api/whiteBlackLists';

import {
  getWhiteBlackListToCreate,
  getWhiteBlackListToUpdate,
} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';

export const types = {
  CHANGE_EDIT_MODE_AND_WHITE_BLACK_LIST_STATE: 'CHANGE_EDIT_MODE_AND_WHITE_BLACK_LIST_STATE',
  SET_WHITE_BLACK_LISTS: 'SET_WHITE_BLACK_LISTS',
  SET_WHITE_BLACK_LISTS_TABLE_DATA: 'SET_WHITE_BLACK_LISTS_TABLE_DATA',
  SET_SELECTED_WHITE_BLACK_LIST: 'SET_SELECTED_WHITE_BLACK_LIST',
  SET_SELECTED_WHITE_BLACK_LIST_INDEX: 'SET_SELECTED_WHITE_BLACK_LIST_INDEX',
  SET_UPDATED_WHITE_BLACK_LIST: 'SET_UPDATED_WHITE_BLACK_LIST',
  RESET_SELECTED_WHITE_BLACK_LIST_INDEX: 'RESET_SELECTED_WHITE_BLACK_LIST_INDEX',
  SET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST: 'SET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST',
  RESET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST: 'RESET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST',
};

export function getWhiteBlackLists() {
  return (dispatch) =>
    dispatch(getWhiteBlackListsAPI())
      .then((lists) => {
        dispatch({
          type: types.SET_WHITE_BLACK_LISTS,
          data: lists,
        });
      });
}

export function getWhiteBlackListById(whiteBlackListId) {
  const url = makeUrl(METHODS.GET_WHITE_BLACK_LIST_BY_ID, {listId: whiteBlackListId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_SELECTED_WHITE_BLACK_LIST,
            data,
          });
        }
      });
}

export function createWhiteBlackList(currentWhiteBlackList, newData) {
  const url = makeUrl(METHODS.CREATE_WHITE_BLACK_LIST);
  const data = getWhiteBlackListToCreate(currentWhiteBlackList, newData);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndWhiteBlackListState(false, STATES_ENTITY.NONE));
          dispatch(updateWhiteBlackListPageData());
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create w/b list:', error);
      });
}

export function updateWhiteBlackList(currentWhiteBlackList, newData) {
  const url = makeUrl(METHODS.UPDATE_WHITE_BLACK_LIST);
  const data = getWhiteBlackListToUpdate(currentWhiteBlackList, newData);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndWhiteBlackListState(false, STATES_ENTITY.NONE));
          dispatch(getWhiteBlackLists())
            .then(() => dispatch({
              type: types.SET_UPDATED_WHITE_BLACK_LIST,
              data: response.data,
            }))
            .then(() => dispatch(getWhiteBlackListsTableData()));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update w/b list:', error);
      });
}

export function deleteWhiteBlackList(whiteBlackListId) {
  const url = makeUrl(METHODS.DELETE_WHITE_BLACK_LIST);
  const data = {
    id: whiteBlackListId,
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(updateWhiteBlackListPageData());
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete w/b list:', error);
      });
}

export function getDataToShowWhiteBlackListDetails(whiteBlackListId, whiteBlackListIndex) {
  return (dispatch) => {
    dispatch(getWhiteBlackListById(whiteBlackListId));
    dispatch(setSelectedWhiteBlackListIndex(whiteBlackListIndex));
    dispatch(getPoliciesAssigned(whiteBlackListId));
  };
}

export function changeEditModeAndWhiteBlackListState(isEditMode, whiteBlackListState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_WHITE_BLACK_LIST_STATE,
      isEditMode,
      whiteBlackListState,
    });
  };
}

export function getWhiteBlackListsTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_WHITE_BLACK_LISTS_TABLE_DATA,
    });
  };
}

export function setSelectedWhiteBlackListIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_WHITE_BLACK_LIST_INDEX,
      selectedWhiteBlackListIndex: index,
    });
  };
}

export function resetSelectedWhiteBlackListIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_WHITE_BLACK_LIST_INDEX,
    });
  };
}

export function updateWhiteBlackListPageData() {
  return (dispatch) => {
    dispatch(resetSelectedWhiteBlackListIndex());
    dispatch(getWhiteBlackLists());
  };
}

export function getPoliciesAssigned(wbListId) {
  const url = makeUrl(METHODS.GET_POLICIES_BY_WB_LIST, {wbListId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const policiesAssigned = response.data;
          dispatch({
            type: types.SET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST,
            policiesAssigned,
          });
        }
      });
}

export function resetPoliciesAssigned() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST,
    });
  };
}

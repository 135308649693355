import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

export const getUsageBillingTableData = (orgStats) => {
  if (_isNil(orgStats)) {
    return [];
  }

  return orgStats.map((org) => ({
    ...org,
    name: (_isEmpty(org.name)) ? '<No org>' : org.name,
    queryCost: (!_isNil(org.queryCost) && !Number.isNaN(org.queryCost)) ? org.queryCost : '',
    seatCost: (!_isNil(org.seatCost) && !Number.isNaN(org.seatCost)) ? org.seatCost : '',
  }));
};

export const convertUsageBillingTableData = (data) => data.map((item) => ({
  id: item.organizationId,
  name: item.organizationName,
  networkQueries: item.networkQueries,
  clientQueries: item.clientQueries,
  totalQueries: item.networkQueries + item.clientQueries,
  networkSeats: item.networkSeats,
  clientSeats: item.clientSeats,
  totalSeats: item.networkSeats + item.clientSeats,
  queryCost: item.queryCost !== null ? parseFloat(item.queryCost) : null,
  seatCost: item.seatCost !== null ? parseFloat(item.seatCost) : null,
}));

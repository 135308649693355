import _isNil from 'lodash/isNil';
import {types} from './constants';
import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const getPerformanceStats = (queryParams, widgetKey) => {
  const {locationId, ...params} = queryParams;
  if (!_isNil(locationId)) {params.campusId = locationId;}
  const url = makeUrl(METHODS.GET_PERFORMANCE_STATS, params);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const {averageLatency, requestsPerSecond} = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_PERFORMANCE_STATS,
            averageLatency,
            requestsPerSecond,
          });
        } else {
          dispatch({
            type: types.SET_ORGANIZATION_PERFORMANCE_STATS,
            averageLatency: 0,
            requestsPerSecond: 0.0,
          });
        }
      });
  };
};

import {
  defaultFont,
} from '../../styles/style';

const style = {
  formControl: {
    display: 'inline-flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
  },
  formControl_oneLine: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
  },
  formControl_oneLineReversedLeftAligned: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    width: 'fit-content',
  },
  relayStatusControl__label: {
    ...defaultFont,
    color: '#AAAAAA',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '1.42857',
    transition: '0.3s ease all',
    paddingLeft: '14px',
  },
};

export default style;

import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.threatStatsWidgetReducer, action) => {
  switch (action.type) {
    case types.SET_THREATS: {
      return {
        ...state,
        threatStats: action.data,
      };
    }

    default:
      return state;
  }
};

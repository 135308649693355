import {CLIENT_STATUSES, RELAY_STATUSES} from '../../constants';
import {
  redColor,
  greenColor,
  yellowColor,
  blackColor,
  grayColor,
  orangeColor,
} from '../../styles/style';

const style = {
  'compact': {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
  [`compact_${RELAY_STATUSES.OFFLINE}`]: {
    backgroundColor: redColor,
  },
  [`compact_${RELAY_STATUSES.ONLINE}`]: {
    backgroundColor: greenColor,
  },
  [`compact_${RELAY_STATUSES.DISABLING}`]: {
    backgroundColor: greenColor,
  },
  [`compact_${RELAY_STATUSES.ENABLING}`]: {
    backgroundColor: greenColor,
  },
  [`compact_${RELAY_STATUSES.ADOPTING}`]: {
    backgroundColor: greenColor,
  },
  [`compact_${RELAY_STATUSES.PROBABLY_OFFLINE}`]: {
    backgroundColor: yellowColor,
  },
  [`compact_${RELAY_STATUSES.UPDATING_CONFIG}`]: {
    backgroundColor: greenColor,
  },
  [`compact_${RELAY_STATUSES.NOT_ADOPTED}`]: {
    backgroundColor: blackColor,
  },
  [`compact_${CLIENT_STATUSES.OFFLINE}`]: {
    backgroundColor: redColor,
  },
  [`compact_${CLIENT_STATUSES.ONLINE}`]: {
    backgroundColor: greenColor,
  },
  [`compact_${CLIENT_STATUSES.UNINSTALL}`]: {
    backgroundColor: blackColor,
  },
  [`compact_${CLIENT_STATUSES.DISABLED}`]: {
    backgroundColor: grayColor,
  },
  [`compact_${CLIENT_STATUSES.MISSING}`]: {
    backgroundColor: orangeColor,
  },
  'full': {
    width: 'fit-content',
    height: 'fit-content',
    padding: '2px 5px',
    borderRadius: '10px',
    border: '1px solid',
  },
  [`full_${RELAY_STATUSES.OFFLINE}`]: {
    borderColor: redColor,
    color: redColor,
  },
  [`full_${RELAY_STATUSES.ONLINE}`]: {
    borderColor: greenColor,
    color: greenColor,
  },
  [`full_${RELAY_STATUSES.PROBABLY_OFFLINE}`]: {
    borderColor: yellowColor,
    color: yellowColor,
  },
  [`full_${RELAY_STATUSES.UPDATING_CONFIG}`]: {
    borderColor: greenColor,
    color: greenColor,
  },
  [`full_${RELAY_STATUSES.ENABLING}`]: {
    borderColor: greenColor,
    color: greenColor,
  },
  [`full_${RELAY_STATUSES.DISABLING}`]: {
    borderColor: redColor,
    color: redColor,
  },
  [`full_${RELAY_STATUSES.ADOPTING}`]: {
    borderColor: greenColor,
    color: greenColor,
  },
  [`full_${RELAY_STATUSES.NOT_ADOPTED}`]: {
    borderColor: blackColor,
    color: blackColor,
  },
  [`full_${CLIENT_STATUSES.OFFLINE}`]: {
    borderColor: redColor,
    color: redColor,
  },
  [`full_${CLIENT_STATUSES.ONLINE}`]: {
    borderColor: greenColor,
    color: greenColor,
  },
  [`full_${CLIENT_STATUSES.UNINSTALL}`]: {
    borderColor: blackColor,
    color: blackColor,
  },
  [`full_${CLIENT_STATUSES.DISABLED}`]: {
    borderColor: grayColor,
    color: grayColor,
  },
  [`full_${CLIENT_STATUSES.MISSING}`]: {
    borderColor: orangeColor,
    color: orangeColor,
  },
  'centered': {
    margin: 'auto',
  },
  'relay-edit-form-status': {
    margin: '5px 0 5px 14px',
    fontWeight: '300',
    cursor: 'default',
  },
};

export default style;

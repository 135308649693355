import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.SITE_LOCAL_FORWARD_CREATE],
};

class LocalForwardingTable extends Component {
  constructor(props) {
    super(props);

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];
    this.headersFullWidthMode.push(
      {
        Header: I18n.t('campusPage.localForwardingsTab.labels.subnet'),
        accessor: 'subnet',
        ...this.getFilter(
          I18n.t('campusPage.localForwardingsTab.filterPlaceholders.subnet'),
          'subnet',
        ),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.localForwardingsTab.labels.domain'),
        accessor: 'domain',
        ...this.getFilter(
          I18n.t('campusPage.localForwardingsTab.filterPlaceholders.domain'),
          'domain',
        ),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.localForwardingsTab.labels.ips'),
        accessor: 'ips',
        sortable: false,
        filterable: false,
      },
    );
    this.headersCompressedMode.push(
      {
        Header: null,
        accessor: 'domain',
        ...this.getFilter(
          I18n.t('campusPage.localForwardingsTab.filterPlaceholders.domain'), 'domain'),
        sortable: false,
      },
    );
  }

  getFilter = (filterLabel, fieldName) => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      filterLabel,
      [fieldName],
    )
  );

  render() {
    const {
      handleClickButtonNew,
      handleClickLocalForwardingRow,
      isEditMode,
      mode,
      tableData,
      selectedLocalForwardingIndex,
    } = this.props;

    const localForwardingTableStyles = {
      minHeight: '300px',
      height: 'auto',
      maxHeight: '800px',
    };

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('campusPage.localForwardingsTab.title')}
        entityType={I18n.t('entitiesTypes.localForwarding')}
        tableStyles={localForwardingTableStyles}
        selectedEntityIndex={selectedLocalForwardingIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickLocalForwardingRow}
        customCardClass="card_localForwardings"
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

LocalForwardingTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickLocalForwardingRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedLocalForwardingIndex: PropTypes.number.isRequired,
};

LocalForwardingTable.defaultProps = {
  tableData: [],
};

export default LocalForwardingTable;

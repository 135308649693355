import {types} from './constants';
import {
  getSingleSignOnConfigurationToCreate,
  getSingleSignOnConfigurationToUpdate,
} from './service';

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';

export function createSingleSignOnConfiguration(newSingleSignOnConfiguration) {
  const url = makeUrl(METHODS.CREATE_SSO_CONFIGURATION);
  const data = getSingleSignOnConfigurationToCreate(newSingleSignOnConfiguration);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndSingleSignOnState(false, STATES_ENTITY.NONE));
        dispatch(updateSingleSignOnConfigurationData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to create SSO:', error);
    });
}

export function updateSingleSignOnConfigurationData() {
  return (dispatch) => dispatch(getSingleSignOnConfigurations());
}

export function getSingleSignOnConfigurations() {
  const url = makeUrl(METHODS.GET_SSO_CONFIGURATIONS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;

        dispatch({
          type: types.SET_SINGLE_SIGN_ON_CONFIGURATIONS,
          data,
        });

        dispatch({
          type: types.SET_SELECTED_SINGLE_SIGN_ON_CONFIGURATION,
          data: data.length ? data[0] : {
            callbackURL: process.env.REACT_APP_AZURE_REDIRECT_URI,
            clientId: '',
            oauthTenantId: '',
            selectedProviderAdminRoles: [],
            selectedProviderViewerRoles: [],
            selectedProviderServiceDeskRoles: [],
            ssoEnabled: true,
          },
        });
      }
    });
}

export function changeEditModeAndSingleSignOnState(isEditMode, singleSignOnState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_SINGLE_SIGN_ON_STATE,
      isEditMode,
      singleSignOnState,
    });
  };
}

export function deleteSingleSignOnConfiguration(id) {
  const url = makeUrl(METHODS.DELETE_SSO_CONFIGURATION);
  const data = {id};

  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updateSingleSignOnConfigurationData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to delete SSO:', error);
    });
}

export function updateSingleSignOnConfiguration(newSingleSignOnConfiguration) {
  const url = makeUrl(METHODS.EDIT_SSO_CONFIGURATION);
  const data = getSingleSignOnConfigurationToUpdate(newSingleSignOnConfiguration);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndSingleSignOnState(false, STATES_ENTITY.NONE));
        dispatch(updateSingleSignOnConfigurationData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to update SSO:', error);
    });
}

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {NetworkEditForm} from '../../containers/index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.SITE_WAN_EDIT],
  buttonDelete: [ACCESS_RIGHTS.SITE_WAN_DELETE],
};

function NetworkDetails(props) {
  const {
    handleClickButtonBackInNetworkInfo,
    handleClickButtonCancelEditNetwork,
    handleClickButtonDeleteNetwork,
    handleClickButtonEditNetwork,
    handleClickButtonSaveNetwork,
    isEditMode,
    isValidNetworkInfo,
    networkInfo,
  } = props;

  return (
    <EntityEditingCard
      title={networkInfo.name}
      entityType={I18n.t('entitiesTypes.network')}
      isEditMode={isEditMode}
      isTherePathBack={true}
      isThereDeleteBlock={true}
      isThereEditBlock={true}
      isValidEntity={isValidNetworkInfo}
      handleClickButtonBack={handleClickButtonBackInNetworkInfo}
      handleClickButtonCancel={handleClickButtonCancelEditNetwork}
      handleClickButtonDelete={handleClickButtonDeleteNetwork}
      handleClickButtonEdit={handleClickButtonEditNetwork}
      handleClickButtonSave={handleClickButtonSaveNetwork}
      customCardClass="card_networks"
      customCardContentClass="card__content_networks"
      accessRights={rightAvailabilityMap}
      content={
        <NetworkEditForm />
      }
    />
  );
}

NetworkDetails.propTypes = {
  handleClickButtonBackInNetworkInfo: PropTypes.func.isRequired,
  handleClickButtonCancelEditNetwork: PropTypes.func.isRequired,
  handleClickButtonEditNetwork: PropTypes.func.isRequired,
  handleClickButtonDeleteNetwork: PropTypes.func.isRequired,
  handleClickButtonSaveNetwork: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidNetworkInfo: PropTypes.bool.isRequired,
  networkInfo: PropTypes.object.isRequired,
};

NetworkDetails.defaultProps = {};

export default NetworkDetails;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {LocalDomainEditForm} from '../../containers/index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.SITE_REDIRECTS_EDIT],
  buttonDelete: [ACCESS_RIGHTS.SITE_REDIRECTS_DELETE],
};

function LocalDomainDetails(props) {
  const {
    handleClickButtonBackInLocalDomainInfo,
    handleClickButtonCancelEditLocalDomain,
    handleClickButtonDeleteLocalDomain,
    handleClickButtonEditLocalDomain,
    handleClickButtonSaveLocalDomain,
    isEditMode,
    isValidLocalDomainInfo,
    localDomainInfo,
  } = props;

  return (
    <EntityEditingCard
      title={localDomainInfo.name}
      entityType={I18n.t('entitiesTypes.localDomain')}
      isEditMode={isEditMode}
      isTherePathBack={true}
      isThereDeleteBlock={true}
      isThereEditBlock={true}
      isValidEntity={isValidLocalDomainInfo}
      handleClickButtonBack={handleClickButtonBackInLocalDomainInfo}
      handleClickButtonCancel={handleClickButtonCancelEditLocalDomain}
      handleClickButtonDelete={handleClickButtonDeleteLocalDomain}
      handleClickButtonEdit={handleClickButtonEditLocalDomain}
      handleClickButtonSave={handleClickButtonSaveLocalDomain}
      customCardClass="card_localDomains"
      customCardContentClass="card__content_localDomains"
      accessRights={rightAvailabilityMap}
      content={
        <LocalDomainEditForm />
      }
    />
  );
}

LocalDomainDetails.propTypes = {
  handleClickButtonBackInLocalDomainInfo: PropTypes.func.isRequired,
  handleClickButtonCancelEditLocalDomain: PropTypes.func.isRequired,
  handleClickButtonEditLocalDomain: PropTypes.func.isRequired,
  handleClickButtonDeleteLocalDomain: PropTypes.func.isRequired,
  handleClickButtonSaveLocalDomain: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidLocalDomainInfo: PropTypes.bool.isRequired,
  localDomainInfo: PropTypes.object.isRequired,
};

LocalDomainDetails.defaultProps = {};

export default LocalDomainDetails;

import {primaryColor} from '../../styles/style';

const style = {
  authCodeChecker: {
    zIndex: '5000',
  },
  authCodeChecker__paper: {
    backgroundColor: primaryColor,
    borderRadius: '5px',
    border: `1px solid ${primaryColor}`,
    padding: '10px 20px',
  },
  authCodeChecker__layout: {
    height: 'unset',
  },
  authCodeChecker__background: {
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    zIndex: '-1',
    position: 'fixed',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: '0.5',
    // willChange: 'opacity',
    // transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // -webkit-tap-highlight-color: transparent;

    display: 'flex',
    justifyContent: 'center',
  },
};

export default style;

import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import moment from 'moment';

// eslint-disable-next-line
export const calculatePeriod = (periodOption, invoiceDate) => {
  const now = moment();
  if (_isEqual(periodOption, 'last30Days')) {
    return ({
      from: moment(now).subtract(29, 'days'),
      till: now,
    });
  }
  if (_isEqual(periodOption, 'invoiceDate')) {
    if (_isNil(invoiceDate)) {
      return null;
    }
    return ({
      from: moment(invoiceDate).subtract(1, 'months'),
      till: moment(invoiceDate).subtract(1, 'days'),
      invoiceDate: moment(invoiceDate),
    });
  }
};

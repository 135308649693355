import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import _range from 'lodash/range';

import _filter from 'lodash/filter';
import {
  ButtonBack,
  ButtonForward,
  SelectableEntityVerticalPairedListElement,
} from '../index';

import style from './style';

class SelectableEntityVerticalPairedList extends React.Component {
  state = {
    selectedLeftRows: [],
    selectedRightRows: [],
  };

  removeRowSelection = () => this.setState({
    selectedLeftRows: [],
    selectedRightRows: [],
  });

  removeLeftRowSelection = () => this.setState({
    selectedLeftRows: [],
  });

  removeRightRowSelection = () => this.setState({
    selectedRightRows: [],
  });

  allLeftRowSelection = () => {
    if (!this.props.isEditMode || this.props.leftList.length <= 0) {
      return;
    }
    this.setState({
      selectedLeftRows: _range(this.props.leftList.length),
    });
  };

  allRightRowSelection = () => {
    if (!this.props.isEditMode || this.props.rightList.length <= 0) {
      return;
    }
    this.setState({
      selectedRightRows: _range(this.props.rightList.length),
    });
  };

  onMovingFromFromLeftToRightList = () => {
    this.props.handleMoveFromLeftToRightList([...this.state.selectedLeftRows]);
    this.removeRowSelection();
  };

  onMovingFromRightToLeftList = () => {
    this.props.handleMoveFromRightToLeftList([...this.state.selectedRightRows]);
    this.removeRowSelection();
  };

  handleClickLeftRow = (index) => {
    if (!this.props.isEditMode || this.state.selectedRightRows.length > 0) {
      return;
    }
    const {selectedLeftRows} = this.state;
    if (selectedLeftRows.includes(index)) {
      this.setState({
        selectedLeftRows: _filter(
          [...selectedLeftRows],
          (elem) => elem !== index,
        ),
      });
    } else {
      this.setState({
        selectedLeftRows: [
          ...selectedLeftRows,
          index,
        ],
      });
    }
  };

  handleClickRightRow = (index) => {
    if (!this.props.isEditMode || this.state.selectedLeftRows.length > 0) {
      return;
    }
    const {selectedRightRows} = this.state;
    if (selectedRightRows.includes(index)) {
      this.setState({
        selectedRightRows: _filter(
          [...selectedRightRows],
          (elem) => elem !== index,
        ),
      });
    } else {
      this.setState({
        selectedRightRows: [
          ...selectedRightRows,
          index,
        ],
      });
    }
  };

  render() {
    const {
      classes,
      isEditMode,
      leftList,
      rightList,
      titleLeft,
      titleRight,
    } = this.props;

    const {
      selectedLeftRows,
      selectedRightRows,
    } = this.state;

    return (
      <div className={classes.selectableEntityList__listSection}>
        <SelectableEntityVerticalPairedListElement
            title={titleLeft}
            isEditMode={isEditMode}
            dataList={leftList}
            selectedRows={selectedLeftRows}
            anotherTableSelectedRows={selectedRightRows}
            onDeselectClick={this.removeLeftRowSelection}
            onSelectAllClick={this.allLeftRowSelection}
            onRowClick={this.handleClickLeftRow}
        />
        <div className={classes.siteListSection__buttonSection}>
          <ButtonBack
              onClick={this.onMovingFromRightToLeftList}
              disabled={!isEditMode || selectedRightRows.length === 0}
          />
          <ButtonForward
              onClick={this.onMovingFromFromLeftToRightList}
              disabled={!isEditMode || selectedLeftRows.length === 0}
          />
        </div>
        <SelectableEntityVerticalPairedListElement
            title={titleRight}
            isEditMode={isEditMode}
            dataList={rightList}
            selectedRows={this.state.selectedRightRows}
            anotherTableSelectedRows={this.state.selectedLeftRows}
            onDeselectClick={this.removeRightRowSelection}
            onSelectAllClick={this.allRightRowSelection}
            onRowClick={this.handleClickRightRow}
        />
      </div>
    );
  }
}

SelectableEntityVerticalPairedList.propTypes = {
  classes: PropTypes.object.isRequired,
  titleLeft: PropTypes.string.isRequired,
  titleRight: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  leftList: PropTypes.array.isRequired,
  rightList: PropTypes.array.isRequired,

  handleMoveFromLeftToRightList: PropTypes.func.isRequired,
  handleMoveFromRightToLeftList: PropTypes.func.isRequired,
};

export default withStyles(style)(SelectableEntityVerticalPairedList);

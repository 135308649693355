import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'perfect-scrollbar';

let ps;

class SidebarWrapper extends Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(
        this.refs.sidebarWrapper,
        {
          suppressScrollX: true,
          suppressScrollY: false,
        },
      );
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const {
      className,
      children,
    } = this.props;

    return (
      <div
        className={className}
        ref="sidebarWrapper"
      >
        {children}
      </div>
    );
  }
}

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

SidebarWrapper.defaultProps = {
  className: '',
  children: '',
};

export default SidebarWrapper;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {
  ROUTES,
} from '../../constants';
import {CustomButton} from '../../material-dashboard-pro-react/components';

import style from './style';
import {confirmEmailUpdating} from './action';

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmed: true,
    };
  }

  componentDidMount() {
    this.confirm();
  }

  goToApp = () => {
    this.props.history.push(ROUTES.DEFAULT);
  };

  confirm = () => {
    const token = _get(this.props.location, 'pathname', '').split('/')[2];
    this.props.confirmEmailUpdating(token).then((isConfirmed) => this.setState({isConfirmed}));
  };

  render() {
    const {classes} = this.props;
    const messageKey = this.state.isConfirmed ? 'successful' : 'unsuccessful';
    return (
      <div className={classes.confirmEmail}>
        <div className={classes.confirmEmail__message}>
          {I18n.t(`emailConfirmationPage.message.${messageKey}`)}
        </div>
        <CustomButton
          fullWidth={true}
          color="secondary"
          customClasses="uppercase"
          onClick={this.goToApp}
        >
          {I18n.t('emailConfirmationPage.goToApp')}
        </CustomButton>
      </div>
    );
  }
}

ConfirmEmail.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  confirmEmailUpdating: PropTypes.func.isRequired,
};

ConfirmEmail.defaultProps = {};

const mapDispatchToProps = {confirmEmailUpdating};

export default withRouter(connect(null, mapDispatchToProps)(withStyles(style)(ConfirmEmail)));

import {
  bgColor,
  bgGreenColor,
  bgRedColor,
  defaultFont,
  greenColor,
  primaryColor,
  redColor,
  yellowColor,
} from '../../styles/style';

const cardStats = {
  borderTop: 'none',
  textAlign: 'center',
};

const style = {
  cardStats: {
    height: '60px',
    boxShadow: 'none',
  },
  cardStats__content: {
    textAlign: 'center',
    padding: '7px 0',
  },
  cardStats__description: {
    color: primaryColor,
    ...defaultFont,
    fontSize: '12px',
    margin: 0,
  },
  cardStats__textContainer: {
    'width': '100%',
    'display': 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'gap': '4px',
  },
  cardStats__value: {
    ...defaultFont,
    'fontSize': '20px',
    'fontWeight': 'normal',
    'margin': 0,
    'maxWidth': '175px',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    '@media (max-width: 1445px)': {
      maxWidth: '150px',
    },
    '@media (max-width: 1170px)': {
      fontSize: '18px',
      maxWidth: '100px',
    },
  },
  cardStats__borderTop_green: {
    ...cardStats,
    color: greenColor,
    backgroundColor: bgGreenColor,
  },
  cardStats__borderTop_primary: {
    ...cardStats,
    color: primaryColor,
    backgroundColor: bgColor,
  },
  cardStats__borderTop_red: {
    ...cardStats,
    color: redColor,
    backgroundColor: bgRedColor,
  },
  cardStats__borderTop_yellow: {
    ...cardStats,
    color: yellowColor,
    backgroundColor: bgRedColor,
  },
  cardStats__tooltip: {
    background: primaryColor,
    color: '#FFF',
    fontSize: 17,
  },
  cardStats__tooltipPopper: {},
};

export default style;

import {types} from './constants';
import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const updateOrganizations = (updatedRows) => ({
  type: types.UPDATE_OPERATOR_ORGANIZATION_TABLE_ROWS,
  data: updatedRows,
});

export const getOrganizationNames = () => {
  const url = makeUrl(METHODS.GET_ORGANIZATION_NAMES);
  return (dispatch) =>
    get(url, dispatch, true)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_NAMES,
            data,
          });
        }
      });
};

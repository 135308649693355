import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {SubnetEditForm} from '../../containers/index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.SITE_LAN_EDIT],
  buttonDelete: [ACCESS_RIGHTS.SITE_LAN_DELETE],
};

function SubnetDetails(props) {
  const {
    handleClickButtonBackInSubnetInfo,
    handleClickButtonCancelEditSubnet,
    handleClickButtonDeleteSubnet,
    handleClickButtonEditSubnet,
    handleClickButtonSaveSubnet,
    isEditMode,
    isValidSubnetInfo,
    subnetInfo,
  } = props;

  return (
    <EntityEditingCard
      title={subnetInfo.name}
      entityType={I18n.t('entitiesTypes.subnet')}
      isEditMode={isEditMode}
      isTherePathBack={true}
      isThereDeleteBlock={true}
      isThereEditBlock={true}
      isValidEntity={isValidSubnetInfo}
      handleClickButtonBack={handleClickButtonBackInSubnetInfo}
      handleClickButtonCancel={handleClickButtonCancelEditSubnet}
      handleClickButtonDelete={handleClickButtonDeleteSubnet}
      handleClickButtonEdit={handleClickButtonEditSubnet}
      handleClickButtonSave={handleClickButtonSaveSubnet}
      customCardClass="card_networks"
      customCardContentClass="card__content_networks"
      accessRights={rightAvailabilityMap}
      content={
        <SubnetEditForm />
      }
    />
  );
}

SubnetDetails.propTypes = {
  handleClickButtonBackInSubnetInfo: PropTypes.func.isRequired,
  handleClickButtonCancelEditSubnet: PropTypes.func.isRequired,
  handleClickButtonEditSubnet: PropTypes.func.isRequired,
  handleClickButtonDeleteSubnet: PropTypes.func.isRequired,
  handleClickButtonSaveSubnet: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidSubnetInfo: PropTypes.bool.isRequired,
  subnetInfo: PropTypes.object.isRequired,
};

SubnetDetails.defaultProps = {};

export default SubnetDetails;

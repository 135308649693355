import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import buttonStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle';

function RegularButton({...props}) {
  const {
    classes,
    color,
    border,
    round,
    children,
    fullWidth,
    disabled,
    customClasses,
    right,
    justIcon,
    size,
    wd,
    ...rest
  } = props;
  let btnClasses = cx({
    [classes[border]]: border,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.right]: right,
    [classes.justIcon]: justIcon,
    [classes.wd]: wd,
    [classes[size]]: size,
  });
  if (customClasses) {
    customClasses.split(' ').forEach((classItem) => {
      btnClasses = cx(btnClasses, classes[classItem]);
    });
  }
  return (
    <Button {...rest} className={`${classes.button} ${btnClasses}`}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'primaryNoBackground',
    'red',
    'redNoBackground',
    'secondary',
    'secondaryNoBackground',
    'green',
    'greenNoBackground',
    'yellow',
    'gray',
    'white',
    'whiteNoBackground',
    'grayBordered',
  ]),
  border: PropTypes.oneOf([
    'solid',
    'none',
  ]),
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  customClasses: PropTypes.string,
  // make the button's min width to 160px
  wd: PropTypes.bool,
  // make the button smaller
  justIcon: PropTypes.bool,
  // button will float right
  right: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xs']),
  children: PropTypes.node,
};

RegularButton.defaultProps = {
  color: 'primary',
  border: 'none',
  round: false,
  fullWidth: false,
  disabled: false,
  customClasses: '',
  wd: false,
  justIcon: false,
  right: false,
  size: 'sm',
  children: '',
};

export default withStyles(buttonStyle)(RegularButton);

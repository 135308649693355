import React from 'react';
import PropTypes from 'prop-types';
import {Manager, Target, Popper} from 'react-popper';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';

import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';

function ActionTemplate(props) {
  const {
    actionName,
    children,
    classes,
    handleClick,
    handleClose,
    icon,
    isOpen,
  } = props;
  const managerStyle = {display: 'inline-block'};
  const growStyle = {transformOrigin: '0 0 0'};
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Manager style={managerStyle} className={classes.root}>
        <Target>
          <IconButton
            color="inherit"
            className={classes.button}
            onClick={handleClick}
          >
            {icon}
            <Hidden mdUp={true}>
              <div
                className={classes.link__text}
                onClick={handleClick}
                onKeyPress={() => {}}
              >
                {actionName}
              </div>
            </Hidden>
          </IconButton>
        </Target>
        <Popper
          placement="bottom-start"
          eventsEnabled={isOpen}
          className={classNames(
            classes.popper_responsive,
            {
              [classes.popper_close]: !isOpen,
            },
          )}
        >
          <Grow
            in={isOpen}
            id={uuid()}
            style={growStyle}
          >
            {children}
          </Grow>
        </Popper>
      </Manager>
    </ClickAwayListener>
  );
}

ActionTemplate.propTypes = {
  actionName: PropTypes.string.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

ActionTemplate.defaultProps = {
  children: '',
};

export default ActionTemplate;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import ActionTemplate from './ActionTemplate';

import {
  RoleAvailability,
} from '../../containers/index';

import {
  Person,
  PowerSettingsNew,
  Subscriptions,
  SupervisorAccount,
  Notifications,
  VpnKey,
  Block,
} from '../../icons/index';

import {
  ROLES_AVAILABILITY,
  ROUTES,
} from '../../constants';

import style from './style';
import {RenderOrEmpty} from '../index';

class ProfileHeaderAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  render() {
    const {
      classes,
      logout,
      loggedAccount,
      accountInfo,
    } = this.props;
    const {isOpen} = this.state;
    const apiAccessAllowed = _get(accountInfo, 'apiAccessAllowed', false);

    return (
      <ActionTemplate
        actionName={I18n.t('headerUser.accountOption.name')}
        classes={classes}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        icon={<Person className={classes.links} />}
        isOpen={isOpen}
      >
        <Paper className={classes.dropdown}>
          <div
            className={classNames(
              classes.dropdown__header,
              classes.dropdown__header_profile,
            )}
          >
            {loggedAccount.email}
          </div>
          <MenuList
            className={classes.action__menuList}
            role="menu"
          >
            <Link
              to={ROUTES.PROFILE}
              href={ROUTES.DEFAULT}
            >
              <MenuItem className={classes.dropdown__item}>
                <Person className={classes.dropdownItem__icon} />
                {I18n.t('headerUser.accountOption.profile')}
              </MenuItem>
            </Link>
            <RoleAvailability availability={ROLES_AVAILABILITY.accessManagement}>
              <Link
                to={ROUTES.ACCESS_MANAGEMENT}
                href={ROUTES.DEFAULT}
              >
                <MenuItem className={classes.dropdown__item}>
                  <SupervisorAccount className={classes.dropdownItem__icon} />
                  {I18n.t('headerUser.accountOption.accessManagement')}
                </MenuItem>
              </Link>
            </RoleAvailability>
            <RoleAvailability availability={ROLES_AVAILABILITY.apiAccessManagement}>
              <RenderOrEmpty isShow={apiAccessAllowed}>
                <Link
                  to={ROUTES.API_ACCESS_MANAGEMENT}
                  href={ROUTES.DEFAULT}
                >
                  <MenuItem className={classes.dropdown__item}>
                    <VpnKey className={classes.dropdownItem__icon} />
                    {I18n.t('headerUser.accountOption.apiAccessManagement')}
                  </MenuItem>
                </Link>
              </RenderOrEmpty>
            </RoleAvailability>
            <RoleAvailability availability={ROLES_AVAILABILITY.blockPage}>
              <Link
                to={ROUTES.BLOCK_PAGE}
                href={ROUTES.DEFAULT}
              >
                <MenuItem className={classes.dropdown__item}>
                  <Block className={classes.dropdownItem__icon} />
                  {I18n.t('headerUser.accountOption.blockPage')}
                </MenuItem>
              </Link>
            </RoleAvailability>
            <RoleAvailability availability={ROLES_AVAILABILITY.notificationsManagement}>
              <Link
                to={ROUTES.NOTIFICATIONS_MANAGEMENT}
                href={ROUTES.DEFAULT}
              >
                <MenuItem className={classes.dropdown__item}>
                  <Notifications className={classes.dropdownItem__icon} />
                  {I18n.t('headerUser.accountOption.notificationsManagement')}
                </MenuItem>
              </Link>
            </RoleAvailability>
            <RoleAvailability availability={ROLES_AVAILABILITY.subscription}>
              <Link
                to={ROUTES.SUBSCRIPTION}
                href={ROUTES.DEFAULT}
              >
                <MenuItem className={classes.dropdown__item}>
                  <Subscriptions className={classes.dropdownItem__icon} />
                  {I18n.t('headerUser.accountOption.subscription')}
                </MenuItem>
              </Link>
            </RoleAvailability>
            <MenuItem
              className={classes.dropdown__item}
              onClick={logout}
            >
              <PowerSettingsNew className={classes.dropdownItem__icon} />
              {I18n.t('headerUser.accountOption.logout')}
            </MenuItem>
          </MenuList>
        </Paper>
      </ActionTemplate>
    );
  }
}

ProfileHeaderAction.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
};

export default withStyles(style)(ProfileHeaderAction);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {
  ButtonSave,
  ButtonCancel,
  RenderOrEmpty,
} from '../index';

import style from '../../styles/components/cards';

function EntityCreatingCard(props) {
  const {
    classes,
    content,
    customCardClass,
    customCardContentClass,
    handleClickButtonSave,
    handleClickButtonCancel,
    isValidEntity,
    title,
    isFullscreen,
  } = props;

  const headerClasses = {
    root: classes.card__header,
    title: `${classes.card__title} ${classes.card__title_edit}`,
    action: classes.card__actions_edit,
  };

  return (
    <Card
      className={classNames(
        classes.card,
        {
          [classes.card_fullScreen]: isFullscreen,
          [classes[customCardClass]]: !_isUndefined(customCardClass),
        },
      )}
    >
      <CardHeader
        classes={headerClasses}
        title={title}
        action={(
          <>
            <ButtonSave
              customClasses="cardActions__button"
              disabled={!isValidEntity}
              onClick={handleClickButtonSave}
            />
            <RenderOrEmpty isShow={!!handleClickButtonCancel}>
              <ButtonCancel
                customClasses={classNames(
                  'cardActions__button',
                  'cardActions__button_last',
                  'cardActions__button_cancel',
                )}
                onClick={handleClickButtonCancel}
              />
            </RenderOrEmpty>
          </>
        )}
      />
      <CardContent
        className={classNames(
          classes.card__content,
          {
            [classes[customCardContentClass]]: !_isUndefined(customCardContentClass),
          },
        )}
      >
        {content}
      </CardContent>
    </Card>
  );
}

EntityCreatingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.node,
  customCardClass: PropTypes.string,
  customCardContentClass: PropTypes.string,
  handleClickButtonSave: PropTypes.func,
  handleClickButtonCancel: PropTypes.func,
  isValidEntity: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isFullscreen: PropTypes.bool,
};

EntityCreatingCard.defaultProps = {
  content: '',
  customCardClass: undefined,
  customCardContentClass: undefined,
  handleClickButtonSave: () => {},
  handleClickButtonCancel: null,
  isValidEntity: true,
  title: '',
  isFullscreen: true,
};

export default withStyles(style)(EntityCreatingCard);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
  NetworkPolicyIpAddresses,
  Switch,
} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.SITE_LAN_CREATE],
};

class SubnetTable extends Component {
  constructor(props) {
    super(props);

    this.stateSwitchCellRenderer = (row) => (
      <Switch
        isChecked={row.value}
        disabled={true}
        switchClassName="switch_td"
      />
    );
    this.ipAddressRangeCellRenderer = (row) => {
      let value = row.value;
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value.length > 1) {
        value = value.slice(0, 1);
        value.push('...');
      }
      return (
        <NetworkPolicyIpAddresses ipAddresses={value} />
      );
    };

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];
    this.headersFullWidthMode.push(
      {
        Header: I18n.t('campusPage.subnetsTab.subnetNameHeader'),
        accessor: 'name',
        ...this.getFilter(I18n.t('campusPage.subnetsTab.subnetNameFilterPlaceholder'), 'name'),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.subnetsTab.networkNameHeader'),
        accessor: 'networkName',
        ...this.getFilter(
          I18n.t('campusPage.subnetsTab.networkNameFilterPlaceholder'),
          'networkName',
        ),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.subnetsTab.subnetAddressRangeHeader'),
        accessor: 'ipAddressRange',
        Cell: this.ipAddressRangeCellRenderer,
        filterable: false,
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.subnetsTab.policyHeader'),
        accessor: 'policyName',
        filterable: false,
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.subnetsTab.stateHeader'),
        accessor: 'state',
        Cell: this.stateSwitchCellRenderer,
        filterable: false,
        sortable: false,
        maxWidth: 70,
      },
    );
    this.headersCompressedMode.push(
      {
        Header: null,
        accessor: 'name',
        ...this.getFilter(I18n.t('campusPage.subnetsTab.subnetNameFilterPlaceholder'), 'name'),
        sortable: false,
      },
    );
  }

  getFilter = (filterLabel, fieldName) => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      filterLabel,
      [fieldName],
    )
  );

  render() {
    const {
      handleClickButtonNew,
      handleClickSubnetRow,
      isEditMode,
      mode,
      tableData,
      selectedSubnetIndex,
    } = this.props;

    const subnetTableStyles = {
      minHeight: '300px',
      height: 'auto',
      maxHeight: '800px',
    };

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('campusPage.subnetsTab.title')}
        entityType={I18n.t('entitiesTypes.subnet')}
        tableStyles={subnetTableStyles}
        selectedEntityIndex={selectedSubnetIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickSubnetRow}
        customCardClass="card_subnets"
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

SubnetTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickSubnetRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedSubnetIndex: PropTypes.number.isRequired,
};

SubnetTable.defaultProps = {
  tableData: [],
};

export default SubnetTable;

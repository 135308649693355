import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';

import {
  WhitelistTable,
  WhitelistDetails,
  WhitelistCreating,
  ContainerWithListAndForm,
} from '../../components';

import {
  changeEditModeAndWhiteBlackListState,
  createWhiteBlackList,
  deleteWhiteBlackList,
  getDataToShowWhiteBlackListDetails,
  getWhiteBlackLists,
  getWhiteBlackListsTableData,
  setSelectedWhiteBlackListIndex,
  resetSelectedWhiteBlackListIndex,
  updateWhiteBlackList,
  getPoliciesAssigned,
  resetPoliciesAssigned,
} from './action';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM, STATES_ENTITY, MODAL_WINDOW_NOTIFY_TYPE,
} from '../../constants';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

class Whitelist extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateWhiteBlackListsTableData(prevProps, props) {
    return !_isEqual(prevProps.whiteBlackLists, props.whiteBlackLists);
  }

  constructor(props) {
    super(props);
    this.state = Whitelist.initialLocalState;
  }

  componentDidMount() {
    this.props.getWhiteBlackLists();
  }

  componentDidUpdate(prevProps) {
    if (Whitelist.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.getWhiteBlackLists();
    }
    if (Whitelist.shouldUpdateWhiteBlackListsTableData(prevProps, this.props)) {
      this.props.getWhiteBlackListsTableData();
    }
  }

  componentWillUnmount() {
    this.props.resetSelectedWhiteBlackListIndex();
    this.props.resetPoliciesAssigned();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isValidWhiteBlackListInfo,
      selectedWhiteBlackList,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <WhitelistCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateWhiteBlackList}
            handleClickButtonSave={this.handleClickButtonSaveNewWhiteBlackList}
            isValidWhiteBlackListInfo={isValidWhiteBlackListInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <WhitelistDetails
            handleClickButtonBack={this.goToWhiteBlackListsTable}
            handleClickButtonCancelEdit={this.handleClickButtonCancelWhiteBlackListEditing}
            handleClickButtonDelete={this.handleClickButtonDeleteWhiteBlackList}
            handleClickButtonEdit={this.handleClickButtonEditWhiteBlackList}
            handleClickButtonSave={this.handleClickButtonSaveWhiteBlackList}
            isEditMode={isEditMode}
            isValidWhiteBlackListInfo={isValidWhiteBlackListInfo}
            whiteBlackListInfo={selectedWhiteBlackList}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  resetLocalState = () => this.setState(Whitelist.initialLocalState);

  handleClickButtonSaveNewWhiteBlackList = () => {
    const {
      newOrUpdatedWhiteBlackList,
      selectedWhiteBlackList,
    } = this.props;
    this.props.createWhiteBlackList(selectedWhiteBlackList, newOrUpdatedWhiteBlackList)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToWhiteBlackListsTable();
        }
      });
  };

  handleClickButtonCancelCreateWhiteBlackList = () => {
    this.goToWhiteBlackListsTable();
    this.props.changeEditModeAndWhiteBlackListState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelWhiteBlackListEditing = () => {
    this.props.changeEditModeAndWhiteBlackListState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditWhiteBlackList = () => {
    this.props.changeEditModeAndWhiteBlackListState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveWhiteBlackList = () => {
    const {
      newOrUpdatedWhiteBlackList,
      selectedWhiteBlackList,
    } = this.props;
    this.props.updateWhiteBlackList(selectedWhiteBlackList, newOrUpdatedWhiteBlackList)
      .then(() => this.props.getPoliciesAssigned(selectedWhiteBlackList.id));
  };

  handleClickButtonDeleteWhiteBlackList = () => {
    const {selectedWhiteBlackList} = this.props;
    this.props.deleteWhiteBlackList(selectedWhiteBlackList.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToWhiteBlackListsTable();
        }
      });
  };

  showFormCreatingWhiteBlackList = () => {
    const {
      accountInfo,
      whiteBlackLists,
    } = this.props;
    if (whiteBlackLists.length >= accountInfo.maxWBLists) {
      this.props.openModalWindow(
        I18n.t('whitelistPage.errorMessages.wbListMoreThenMaxWBLists'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      );
      return;
    }
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndWhiteBlackListState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedWhiteBlackListIndex();
    this.props.resetPoliciesAssigned();
  };

  showWhitelistDetails = (listIndex) => {
    const {whiteBlackLists} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowWhiteBlackListDetails(whiteBlackLists[listIndex].id, listIndex);
  };

  goToWhiteBlackListsTable = () => {
    this.setState(Whitelist.initialLocalState);
    this.props.resetSelectedWhiteBlackListIndex();
    this.props.resetPoliciesAssigned();
  };

  render() {
    const {
      isEditMode,
      selectedWhiteBlackListIndex,
      whiteBlackListsTableData,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    return (
      <ContainerWithListAndForm
        list={(
          <WhitelistTable
            handleClickButtonNew={this.showFormCreatingWhiteBlackList}
            handleClickCampusRow={this.showWhitelistDetails}
            isEditMode={isEditMode}
            mode={tableMode}
            selectedWhiteBlackListIndex={selectedWhiteBlackListIndex}
            tableData={whiteBlackListsTableData}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

Whitelist.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  isValidWhiteBlackListInfo: PropTypes.bool.isRequired,
  newOrUpdatedWhiteBlackList: PropTypes.object.isRequired,
  selectedWhiteBlackList: PropTypes.object.isRequired,
  selectedWhiteBlackListIndex: PropTypes.number.isRequired,
  whiteBlackLists: PropTypes.array.isRequired,
  whiteBlackListsTableData: PropTypes.array.isRequired,

  changeEditModeAndWhiteBlackListState: PropTypes.func.isRequired,
  createWhiteBlackList: PropTypes.func.isRequired,
  deleteWhiteBlackList: PropTypes.func.isRequired,
  getDataToShowWhiteBlackListDetails: PropTypes.func.isRequired,
  getWhiteBlackLists: PropTypes.func.isRequired,
  getWhiteBlackListsTableData: PropTypes.func.isRequired,
  resetSelectedWhiteBlackListIndex: PropTypes.func.isRequired,
  updateWhiteBlackList: PropTypes.func.isRequired,
  getPoliciesAssigned: PropTypes.func.isRequired,
  resetPoliciesAssigned: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
};

Whitelist.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  newOrUpdatedWhiteBlackList: state.whiteBlackListEditFormReducer.editableWhiteBlackListInfo,
  isValidWhiteBlackListInfo: state.whiteBlackListEditFormReducer.isValidWhiteBlackListInfo,

  isEditMode: state.whiteBlackListReducer.isEditMode,
  selectedWhiteBlackList: state.whiteBlackListReducer.selectedWhiteBlackList,
  selectedWhiteBlackListIndex: state.whiteBlackListReducer.selectedWhiteBlackListIndex,
  whiteBlackLists: state.whiteBlackListReducer.whiteBlackLists,
  whiteBlackListsTableData: state.whiteBlackListReducer.whiteBlackListsTableData,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndWhiteBlackListState: bindActionCreators(
    changeEditModeAndWhiteBlackListState,
    dispatch,
  ),
  createWhiteBlackList: bindActionCreators(createWhiteBlackList, dispatch),
  deleteWhiteBlackList: bindActionCreators(deleteWhiteBlackList, dispatch),
  getDataToShowWhiteBlackListDetails: bindActionCreators(
    getDataToShowWhiteBlackListDetails,
    dispatch,
  ),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  getWhiteBlackLists: bindActionCreators(getWhiteBlackLists, dispatch),
  getWhiteBlackListsTableData: bindActionCreators(getWhiteBlackListsTableData, dispatch),
  setSelectedWhiteBlackListIndex: bindActionCreators(setSelectedWhiteBlackListIndex, dispatch),
  resetSelectedWhiteBlackListIndex: bindActionCreators(resetSelectedWhiteBlackListIndex, dispatch),
  updateWhiteBlackList: bindActionCreators(updateWhiteBlackList, dispatch),
  getPoliciesAssigned: bindActionCreators(getPoliciesAssigned, dispatch),
  resetPoliciesAssigned: bindActionCreators(resetPoliciesAssigned, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Whitelist);

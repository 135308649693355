import _get from 'lodash/get';

export function injectProfileStats(profiles, stats) {
  return profiles.map((profile) => {
    const profileStats = _get(stats, profile.id, {allowed: 0, blocked: 0});
    return ({
      ...profile,
      requestsAllowed: profileStats.allowed,
      requestsBlocked: profileStats.blocked,
      requestsTotal: profileStats.allowed + profileStats.blocked,
    });
  });
}

export function getProfileIds(profiles) {
  return profiles.map((profile) => profile.id);
}

export function getOrganizationProfilesData(data) {
  return {
    ...data,
    profilesInOrganization: data.profilesInOrganization.map((profile) => {
      const statsOrDefault = _get(profile, 'clientStats', {onlineCount: 0, offline: 0});
      return {
        ...profile,
        clientsOnline: statsOrDefault.onlineCount,
        clientsOffline: statsOrDefault.offlineCount,
        clientsTotal: statsOrDefault.onlineCount + statsOrDefault.offlineCount,
      };
    }),
  };
}

import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export function getNetworksTableData(networks, networksStatusesObject) {
  return networks.map((item) => ({
    id: item.id,
    name: item.name,
    dynamicDns: item.domainName,
    publicIpAddress: item.ip,
    policy: item.policyName,
    blockPage: item.blockPageName,
    state: item.enabled,
    status: networksStatusesObject[item.id] ? networksStatusesObject[item.id].status : null,
  }));
}

export function getNetworksStatusesAsObject(networksStatus) {
  const result = {};
  networksStatus.forEach((item) => {
    result[item.networkId] = item;
  });
  return result;
}

export function getNetworkToCreate(newNetwork, campusId) {
  const newData = getNetworkToUpdate(newNetwork, campusId);
  delete newData.id;
  return newData;
}

export function getDataToAssignBlockPageToNetwork(blockPageId, networkId) {
  return {
    id: blockPageId,
    networkId: networkId,
  };
}

export function getNetworkToUpdate(newNetwork, campusId) {
  return {
    policyId: newNetwork.policyId,
    policyName: newNetwork.policyName,
    name: newNetwork.name,
    address: newNetwork.ipAddressOrDomainName,
    locationId: campusId,
    id: newNetwork.id,
    enabled: newNetwork.enabled,
  };
}

export function getUpdatedNetworkIndex(updatedNetworkId, networks) {
  return _findIndex(networks, (o) => _isEqual(o.id, updatedNetworkId));
}

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {METHODS, STATES_ENTITY} from '../../constants';

export const types = {
  SET_RELAYS: 'SET_RELAYS',
  UPDATE_RELAY_DATA: 'UPDATE_RELAY_DATA',
  DELETE_RELAY: 'DELETE_RELAY',
  SET_SELECTED_RELAY_INDEX: 'SET_SELECTED_RELAY_INDEX',
  RESET_SELECTED_RELAY_INDEX: 'RESET_SELECTED_RELAY_INDEX',
  SET_SELECTED_RELAY_TABLE_ROW_DATA: 'SET_SELECTED_RELAY_TABLE_ROW_DATA',
  RESET_SELECTED_RELAY_TABLE_ROW_DATA: 'RESET_SELECTED_RELAY_TABLE_ROW_DATA',
  CHANGE_EDIT_MODE_AND_RELAY_STATE: 'CHANGE_EDIT_MODE_AND_RELAY_STATE',
  SET_NETWORK_AGENT_ALLOWED_STATUS: 'SET_NETWORK_AGENT_ALLOWED_STATUS',
  CLEAR_RELAYS: 'CLEAR_RELAYS',
};

export function getRelays(locationId) {
  const url = makeUrl(METHODS.GET_NETWORK_AGENTS, {locationId});

  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          const relays = data.networkAgentAllowed ? data.agents : [];

          dispatch({
            type: types.SET_NETWORK_AGENT_ALLOWED_STATUS,
            networkAgentAllowed: data.networkAgentAllowed,
          });
          dispatch({
            type: types.SET_RELAYS,
            relays,
          });
        }
      });
}

export function setRelaysTableData(relays) {
  return (dispatch) => {
    dispatch({
      type: types.SET_RELAYS,
      relays,
    });
  };
}

export function updateRelayData(data) {
  const url = makeUrl(METHODS.UPDATE_NETWORK_AGENT_DATA);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndRelayState(false, STATES_ENTITY.NONE));

          dispatch({
            type: types.UPDATE_RELAY_DATA,
            data: response.data,
          });

          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update relay data:', error);
      });
}

export function deleteRelay(id) {
  const url = makeUrl(METHODS.DELETE_NETWORK_AGENT);
  const data = {id};

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: types.DELETE_RELAY,
            data,
          });

          return true;
        }

        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete relay:', error);
      });
}

export function setSelectedRelayIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_RELAY_INDEX,
      selectedRelayIndex: index,
    });
  };
}

export function resetSelectedRelayIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_RELAY_INDEX,
    });
  };
}

export function clearRelays() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_RELAYS,
    });
  };
}

export function setSelectedRelayTableRowData(selectedRelayTableRowData) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_RELAY_TABLE_ROW_DATA,
      selectedRelayTableRowData,
    });
  };
}

export function resetSelectedRelayTableRowData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_RELAY_TABLE_ROW_DATA,
    });
  };
}

export function changeEditModeAndRelayState(editMode, relayState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_RELAY_STATE,
      editMode,
      relayState,
    });
  };
}

export function adoptRelay(id) {
  return (dispatch) => {
    const url = makeUrl(METHODS.ADOPT_RELAY);
    const data = {id};
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: types.UPDATE_RELAY_DATA,
            data: response.data,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to adopt relay:', error);
      });
  };
}

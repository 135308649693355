import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {CustomInput} from '../../material-dashboard-pro-react/components';
import {
  MANAGEMENT_TYPES,
  NON_SUPER_ADMIN_USER_ROLES as nonSuperAdminRoles,
  NON_SUPER_ADMIN_OEM_USER_ROLES as nonSuperAdminOemRoles,
  USER_ROLES as roles,
} from '../../constants';

import {formatDateTimeToUserTimezone} from '../../utils/dateTime';
import {omitSingle} from '../../utils/common';

import {OperatorOrganizationsTable} from '../../containers';
import {ContainerWithListAndForm, Dropdown, RenderOrEmpty} from '../index';

import style from './style';
import {getManagementType} from '../../utils/tenants';

class OperatorForm extends React.Component {
  static editOperatorFields = [
    'email',
    'firstName',
    'lastName',
    'lastLogin',
  ];

  static newOperatorFields = [
    'email',
    'firstName',
    'lastName',
  ];

  static roleField = 'role';

  static initState() {
    return OperatorForm.newOperatorFields.reduce((res, fieldName) => ({
      ...res,
      [fieldName]: false,
    }), {});
  }

  state = {
    hasFieldChanged: OperatorForm.initState(),
  };

  onChangeRole = (e) =>
    this.props.onChange(e.target.name, _get(this.roleOptions, e.target.value));

  onChange = (e) => {
    const fieldName = e.target.name;
    this.setState((prevState) => ({
      hasFieldChanged: {
        ...prevState.hasFieldChanged,
        [fieldName]: true,
      },
    }));
    this.props.onChange(fieldName, e.target.value);
  };

  get operatorInfo() {
    const {operator, isNewOperator} = this.props;
    const fields = isNewOperator ? OperatorForm.newOperatorFields : OperatorForm.editOperatorFields;
    return fields.map((f) => {
      let value = _get(operator, f, '');
      if (!isNewOperator && (!value || value === '')) {
        value = '—';
      }
      if (f === 'lastLogin') {
        value = formatDateTimeToUserTimezone(value);
      }
      const inputProps = {
        disabled: !isNewOperator,
        onChange: this.onChange,
        value,
        name: f,
        type: 'text',
        fullWidth: true,
      };
      const error = this.getError(f);
      return (
        <CustomInput
          labelText={I18n.t(`accessManagementPage.operatorInfo.${f}`)}
          id={f}
          key={f}
          inputProps={inputProps}
          {...error}
        />
      );
    });
  }

  get organizationsAllowed() {
    return _get(this.props.accountInfo, 'organizationsAllowed', false);
  }

  get roleOptions() {
    const managementType = getManagementType();
    let roleOptions = (managementType === MANAGEMENT_TYPES.OEM)
      ? nonSuperAdminOemRoles
      : nonSuperAdminRoles;
    if (this.props.isSelectedSuperAdmin) {
      roleOptions = roles;
    }
    if (!this.organizationsAllowed) {
      roleOptions = omitSingle('ORGANIZATION_OPERATOR', roleOptions);
    }
    roleOptions = omitSingle('PROJECT_VIEWER', roleOptions);
    return Object.values(roleOptions);
  }

  get roleIndex() {
    const role = _get(this.props.operator, OperatorForm.roleField, null);
    return this.roleOptions.indexOf(role);
  }

  get operatorRole() {
    return (
      <Dropdown
          disabled={!this.props.isEditing || this.props.isSelectedSuperAdmin}
          dropdownName={OperatorForm.roleField}
          label={I18n.t(`accessManagementPage.operatorInfo.${OperatorForm.roleField}`)}
          onChangeValue={this.onChangeRole}
          options={this.roleOptions.map((r) => I18n.t(`userRoles.${r}`))}
          selectedItemIndex={this.roleIndex}
      />
    );
  }

  get organizationOperators() {
    const {operator, isEditing} = this.props;
    return (
      <ContainerWithListAndForm
        list={(
          <OperatorOrganizationsTable
            operator={operator}
            isEditMode={isEditing}
          />
        )}
      />
    );
  }

  getError = (field) => {
    const isValid = _get(this.props.validationState, field, true);
    const hasChanged = _get(this.state.hasFieldChanged, field, false);
    if (!isValid && hasChanged) {
      const errorText = I18n.t(`accessManagementPage.newOperator.errorMessages.${field}`);
      return {
        error: true,
        errorText,
      };
    }
    return {};
  };

  hasOrganizations = () =>
    _isEqual(this.props.operator.role, nonSuperAdminRoles.ORGANIZATION_OPERATOR);

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.form}>
        {this.operatorInfo}
        {this.operatorRole}
        <RenderOrEmpty isShow={this.organizationsAllowed && this.hasOrganizations()} >
          {this.organizationOperators}
        </RenderOrEmpty>
      </div>
    );
  }
}

OperatorForm.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  operator: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    lastLogin: PropTypes.string,
    organizations: PropTypes.array,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isSelectedSuperAdmin: PropTypes.bool,
  isNewOperator: PropTypes.bool,
  validationState: PropTypes.object,
};

OperatorForm.defaultProps = {
  isEditing: false,
  isSelectedSuperAdmin: false,
  isNewOperator: false,
  validationState: {},
};

export default withStyles(style)(OperatorForm);

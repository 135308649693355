import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import {Manager, Popper, Target} from 'react-popper';

import {RenderOrEmpty, NestedList} from '../index';

import style from './style';

// TODO: use this component for header actions
// TODO: update material-ui library and use IconMenu component
class IconMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {isOpen: false};
  }

  open = () => this.setState({isOpen: true});

  close = () => this.setState({isOpen: false});

  render() {
    const {
      classes,
      icon,
      listPlacement,
      listItems,
      listProps,
    } = this.props;
    const {isOpen} = this.state;
    const managerStyle = {display: 'inline-block'};
    return (
      <Manager style={managerStyle}>
        <Target>
          <IconButton
            className={classes.button}
            onClick={this.open}
          >
            {icon}
          </IconButton>
        </Target>
        <RenderOrEmpty isShow={isOpen}>
          <Popper
            placement={listPlacement}
            eventsEnabled={isOpen}
          >
            <ClickAwayListener onClickAway={this.close}>
              <Paper>
                <NestedList
                  items={listItems}
                  close={this.close}
                  listProps={listProps}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        </RenderOrEmpty>
      </Manager>
    );
  }
}

IconMenu.propTypes = {
  icon: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  listPlacement: PropTypes.oneOf(['right', 'bottom-start', 'right-start']),
  listItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    subItems: PropTypes.array,
    disabled: PropTypes.bool,
  })).isRequired,
  listProps: PropTypes.object,
};

IconMenu.defaultProps = {
  listPlacement: 'bottom-start',
  listProps: {},
};

export default withStyles(style)(IconMenu);

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import {get, makeUrl} from '../../utils/fetcher';
import initState from '../../reducers/redux-initial-state';
import {types} from './constants';
import {METHODS, PERIODS} from '../../constants';

export const getRequestStatsByDecisions = (queryParams, widgetKey) => {
  const {locationId, ...params} = queryParams;
  if (!_isNil(locationId)) {params.campusId = locationId;}
  const url = makeUrl(METHODS.GET_REQUEST_STATS_BY_DECISIONS, params);
  return (dispatch) => get(url, dispatch, true, widgetKey)
    .then((response) => {
      if (response && response.data) {
        const requestsByDecision = response.data;
        dispatch({
          type: types.SET_REQUEST_STATS,
          requestsByDecision,
        });
      } else {
        dispatch({
          type: types.SET_REQUEST_STATS,
          requestsByDecision: initState.requestStatsWidgetReducer.requestsByDecision,
        });
      }
    });
};

export const getUserRequestsGraph = (queryParams, widgetKey) => {
  const {locationId, ...params} = queryParams;
  if (!_isNil(locationId)) {params.campusId = locationId;}
  const url = makeUrl(METHODS.GET_USER_REQUESTS_GRAPH, params);
  return (dispatch) => get(url, dispatch, true, widgetKey)
    .then((response) => {
      if (response && response.data) {
        const userRequestsGraph = response.data;
        dispatch({
          type: types.SET_USER_REQUEST_GRAPH,
          userRequestsGraph,
          period: _get(queryParams, 'period', PERIODS.LAST_24_HOURS),
        });
      } else {
        dispatch({
          type: types.SET_USER_REQUEST_GRAPH,
          userRequestsGraph: initState.requestStatsWidgetReducer.userRequestsGraph,
        });
      }
    });
};

export const getRequestStats = (queryParams, widgetKey) => (dispatch) => {
  dispatch(getRequestStatsByDecisions(queryParams, widgetKey));
  dispatch(getUserRequestsGraph(queryParams, widgetKey));
};

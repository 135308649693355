import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.subscriptionsReducer, action) => {
  switch (action.type) {
    case types.SET_INVOICES: {
      return {
        ...state,
        invoices: action.invoices,
      };
    }
    case types.SET_SUBSCRIPTIONS_DETAILS: {
      return {
        ...state,
        subscriptionDetails: action.subscriptionDetails,
      };
    }
    case types.RESET_BILLING_STATE: {
      return {...initState.subscriptionsReducer};
    }
    default:
      return state;
  }
};

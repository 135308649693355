import {makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export function unsubscribeEmailFromNotification(email, subtype, accountId, signature) {
  const url = makeUrl(METHODS.UNSUBSCRIBE_EMAIL_FROM_NOTIFICATION);
  const data = {email, subtype, accountId, signature};
  return (dispatch) => post(url, data, dispatch, false)
    .then(((result) => result && result.success))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unsubscribing of email failed', error);
    });
}

// ##############################
// // // Button styles
// #############################

import {
  defaultFont,
  greenColor,
  yellowColor,
  secondaryColor,
  primaryColor,
  grayColor,
  redColor,
} from '../../../../../styles/style';

import buttonsStyles from '../../../../../styles/components/button';

const buttonStyle = {
  button: {
    ...defaultFont,
    'minWidth': 'auto',
    'minHeight': 'auto',
    'backgroundColor': grayColor,
    'color': '#FFFFFF',
    'boxShadow':
      '0 2px 2px 0 rgba(153, 153, 153, 0.14),' +
      '0 3px 1px -2px rgba(153, 153, 153, 0.2),' +
      '0 1px 5px 0 rgba(153, 153, 153, 0.12)',
    'border': 'none',
    'borderRadius': '3px',
    'position': 'relative',
    'padding': '12px 30px',
    'fontSize': '12px',
    'fontWeight': '400',
    'textTransform': 'none',
    'letterSpacing': '0',
    'willChange': 'box-shadow, transform',
    'transition':
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),' +
      'background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    'lineHeight': '1.3',
    'textAlign': 'center',
    'whiteSpace': 'nowrap',
    'verticalAlign': 'middle',
    'touchAction': 'manipulation',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: grayColor,
      color: '#FFFFFF',
      boxShadow:
        '0 14px 26px -12px rgba(153, 153, 153, 0.42),' +
          '0 4px 23px 0px rgba(0, 0, 0, 0.12),' +
          '0 8px 10px -5px rgba(153, 153, 153, 0.2)',
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  fullWidth: {
    width: '100%',
  },
  primary: {
    'backgroundColor': primaryColor,
    'boxShadow':
      '0 2px 2px 0 rgba(153, 153, 153, 0.14),' +
      '0 3px 1px -2px rgba(153, 153, 153, 0.2),' +
      '0 1px 5px 0 rgba(153, 153, 153, 0.12)',
    '&:hover': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(153, 153, 153, 0.42),' +
          '0 4px 23px 0px rgba(0, 0, 0, 0.12),' +
          '0 8px 10px -5px rgba(153, 153, 153, 0.2)',
    },
  },
  primaryNoBackground: {
    '&,&:focus,&:hover': {
      color: primaryColor,
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  green: {
    'backgroundColor': greenColor,
    'boxShadow':
      '0 2px 2px 0 rgba(76, 175, 80, 0.14),' +
        '0 3px 1px -2px rgba(76, 175, 80, 0.2),' +
        '0 1px 5px 0 rgba(76, 175, 80, 0.12)',
    '&:hover': {
      backgroundColor: greenColor,
      boxShadow:
        '0 14px 26px -12px rgba(76, 175, 80, 0.42),' +
          '0 4px 23px 0px rgba(0, 0, 0, 0.12),' +
          '0 8px 10px -5px rgba(76, 175, 80, 0.2)',
    },
  },
  greenNoBackground: {
      '&,&:focus,&:hover': {
          color: greenColor,
          background: 'transparent',
          boxShadow: 'none',
      },
  },
  yellow: {
    'backgroundColor': yellowColor,
    'boxShadow':
      '0 2px 2px 0 rgba(255, 152, 0, 0.14),' +
        '0 3px 1px -2px rgba(255, 152, 0, 0.2),' +
        '0 1px 5px 0 rgba(255, 152, 0, 0.12)',
    '&:hover': {
      backgroundColor: yellowColor,
      boxShadow:
        '0 14px 26px -12px rgba(255, 152, 0, 0.42),' +
          '0 4px 23px 0px rgba(0, 0, 0, 0.12),' +
          '0 8px 10px -5px rgba(255, 152, 0, 0.2)',
    },
  },
  secondary: {
    'backgroundColor': secondaryColor,
    'boxShadow':
      '0 2px 2px 0 rgba(244, 67, 54, 0.14),' +
        '0 3px 1px -2px rgba(244, 67, 54, 0.2),' +
        '0 1px 5px 0 rgba(244, 67, 54, 0.12)',
    '&:hover': {
      backgroundColor: secondaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(244, 67, 54, 0.42),' +
          '0 4px 23px 0px rgba(0, 0, 0, 0.12),' +
          '0 8px 10px -5px rgba(244, 67, 54, 0.2)',
    },
  },
  secondaryNoBackground: {
    '&,&:focus,&:hover': {
      color: secondaryColor,
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  red: {
    'backgroundColor': redColor,
    'boxShadow':
            '0 2px 2px 0 rgba(244, 67, 54, 0.14),' +
            '0 3px 1px -2px rgba(244, 67, 54, 0.2),' +
            '0 1px 5px 0 rgba(244, 67, 54, 0.12)',
    '&:hover': {
      backgroundColor: redColor,
      boxShadow:
                '0 14px 26px -12px rgba(244, 67, 54, 0.42),' +
                '0 4px 23px 0px rgba(0, 0, 0, 0.12),' +
                '0 8px 10px -5px rgba(244, 67, 54, 0.2)',
    },
  },
  redNoBackground: {
    '&,&:focus,&:hover': {
      color: redColor,
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  gray: {
    'backgroundColor': grayColor,
    '&:hover': {
      backgroundColor: grayColor,
    },
  },
  grayBordered: {
    'backgroundColor': 'transparent',
    'border': `1px solid ${grayColor}`,
    'color': grayColor,
    '&:hover': {
      backgroundColor: grayColor,
    },
  },
  white: {
    'backgroundColor': '#FFF',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  whiteNoBackground: {
    '&,&:focus,&:hover': {
      color: '#FFF',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  transparent: {
    '&,&:focus,&:hover': {
      color: 'inherit',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  round: {
    borderRadius: '30px',
  },
  disabled: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  right: {
    float: 'right',
  },
  justIcon: {
    minWidth: '10px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
  },
  wd: {
    minWidth: '160px',
  },
  lg: {
    fontSize: '14px',
    padding: '18px 36px',
  },
  sm: {
    padding: '5px 20px',
    fontSize: '11px',
    height: '35px',
  },
  xs: {
    padding: '4px 15px',
    fontSize: '10px',
  },
  none: {
    border: 'none',
  },
  solid: {
    border: 'solid 1px',
  },
  hidden: {
    display: 'none',
  },
  clientActions__actionButton: {
    margin: '0 5px',
    height: '24px',
    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  clientActions__actionMenuButton: {
    margin: '0 5px',
    height: '24px',
    '@media (min-width: 749px)': {
      display: 'none',
    },
  },
  userActions__actionButton: {
    height: '24px',
    '@media (max-width: 749px)': {
      display: 'none',
    },
  },
  ...buttonsStyles,
};

export default buttonStyle;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
  StatusIndicator,
  RenderOrEmpty,
  ButtonAdopt,
} from '../index';

import {
  FULL_WIDTH_MODE_TABLE,
  RELAY_STATUSES,
  RELAY_STATUS_INDICATOR_MODES,
  ACCESS_RIGHTS,
} from '../../constants';

import style from '../../styles/components/cards';
import {RightAvailability} from '../../containers';

const styles = {
  minHeight: '300px',
  height: 'auto',
  maxHeight: '800px',
};

const rightAvailabilityMap = {
  buttonAdopt: [ACCESS_RIGHTS.SITE_RELAYS_ADOPT],
};

class RelaysTable extends Component {
  actionCellRenderer = (rowInfo) => (
    <div>
      <RenderOrEmpty isShow={!rowInfo.original.adopted}>
        <RightAvailability accessRights={rightAvailabilityMap.buttonAdopt}>
          <ButtonAdopt
              onClick={(e) => this.props.adoptRelay(e, rowInfo.original.id)}
          />
        </RightAvailability>
      </RenderOrEmpty>
    </div>
  );

  statusIndicatorCellRenderer = (rowInfo) => (
    <StatusIndicator status={rowInfo.value} />
  );

  compactStatusIndicatorCellRenderer = (rowInfo) => (
    <StatusIndicator
      status={rowInfo.value}
      mode={RELAY_STATUS_INDICATOR_MODES.COMPACT}
    />
  );

  getHeadersFullWidthMode = () => ([
    {
      Header: I18n.t('campusPage.relaysTab.labels.id'),
      accessor: 'id',
      sortable: false,
      ...this.getFilter('id'),
    },
    {
      Header: I18n.t('campusPage.relaysTab.labels.status'),
      accessor: 'status',
      Cell: this.statusIndicatorCellRenderer,
      sortable: false,
      filterable: false,
      width: 130,
    },
    {
      Header: I18n.t('campusPage.relaysTab.labels.network'),
      accessor: 'network',
      sortable: false,
      ...this.getFilter('network'),
    },
    {
      Header: I18n.t('campusPage.relaysTab.labels.version'),
      accessor: 'version',
      sortable: false,
      ...this.getFilter('version'),
    },
    {
      Header: I18n.t('campusPage.relaysTab.labels.lastConfigUpdatingAt'),
      accessor: 'lastConfigUpdatingAt',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('campusPage.relaysTab.labels.actions'),
      accessor: 'actions',
      Cell: this.actionCellRenderer,
      sortable: false,
      filterable: false,
      width: 110,
    },
  ]);

  getHeadersCompressedMode = () => ([
    {
      Header: null,
      accessor: 'id',
      sortable: false,
      ...this.getFilter('id'),
    },
    {
      Header: null,
      accessor: 'status',
      Cell: this.compactStatusIndicatorCellRenderer,
      sortable: false,
      filterable: false,
      maxWidth: 30,
    },
  ]);

  getFilter = (accessor) => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t(`campusPage.relaysTab.filterPlaceholders.${accessor}`),
      [accessor],
    )
  );

  render() {
    const {
      editMode,
      mode,
      tableData,
      selectedRelayIndex,
      onRowClick,
    } = this.props;

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.getHeadersFullWidthMode()}
        headersCompressedMode={this.getHeadersCompressedMode()}
        tableData={tableData}
        disabled={editMode}
        title={I18n.t('campusPage.relaysTab.title')}
        entityType={I18n.t('entitiesTypes.relay')}
        tableStyles={styles}
        selectedEntityIndex={selectedRelayIndex}
        handleClickRow={onRowClick}
        customCardClass="card_relays"
      />
    );
  }
}

RelaysTable.propTypes = {
  editMode: PropTypes.bool,
  mode: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    lastConfigUpdatingAt: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(RELAY_STATUSES)).isRequired,
  })),
  selectedRelayIndex: PropTypes.number,
  onRowClick: PropTypes.func,
  adoptRelay: PropTypes.func,
};

RelaysTable.defaultProps = {
  editMode: false,
  mode: FULL_WIDTH_MODE_TABLE,
  tableData: [],
  selectedRelayIndex: -1,
  onRowClick: () => { },
  adoptRelay: () => { },
};

export default withStyles(style)(RelaysTable);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

import {
  ContainerWithListAndForm,
  EntityEditingCard,
  OrganizationSitesTable,
  MessageBox,
  RenderOrEmpty,
} from '../../components/index';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {
  removeSiteFromOrganization,
  getOrganizationSites,
  updateOrganizationSites,
  changeSitesEditMode,
  getOrganizationNetworkStats,
  getOrganizationSiteStats,
} from './action';
import {cancelRequests} from '../Loading/action';

import {
  ACCESS_RIGHTS,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
} from '../../constants';
import {widgetKeys} from './constants';
import OrganizationSitesEditForm from '../OrganizationSitesEditForm/OrganizationSitesEditForm';

import style from './style';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.ORGANIZATION_SITE_LINK],
};

class OrganizationSitesTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdateSites(prevProps, props) {
    return !_isEqual(prevProps.organization.id, props.organization.id)
      && !_isNil(props.organization.id);
  }

  constructor(props) {
    super(props);
    this.state = OrganizationSitesTab.initialLocalState;
  }

  componentDidMount() {
    const {organization} = this.props;
    if (!_isNil(organization.id)) {
      this.props.getOrganizationSites(organization.id);
      this.props.getOrganizationSiteStats(organization.id);
      this.props.getOrganizationNetworkStats(organization.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (OrganizationSitesTab.shouldUpdateSites(prevProps, this.props)) {
      this.gotoSiteListAndReload();
      this.props.getOrganizationSiteStats(this.props.organization.id);
      this.props.getOrganizationNetworkStats(this.props.organization.id);
    }
  }

  componentWillUnmount() {
    this.props.cancelRequests([widgetKeys.ORGANIZATION_SITES_STATS], false);
  }

  // showSitesDetails = () => {
  //   this.setState({
  //     modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_FORM_FULL_SCREEN,
  //     tableMode: COMPRESSED,
  //   });
  // };

  getContainerItemByModeShowingContainer = () => {
    const {accountInfo, isEditMode} = this.props;
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', true);

    let containerItem = null;
    if (isEditMode) {
      containerItem = (
        <>
          <RenderOrEmpty isShow={organizationsAllowed}>
            <OrganizationSitesEditForm
              isEditMode={isEditMode}
              sitesNotInOrganization={this.props.sitesNotInOrganization}
              sitesInOrganization={this.props.sitesInOrganization}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={!organizationsAllowed}>
            <MessageBox
              message={I18n.t('organizations.errorMessages.organizationsNotAllowed')}
            />
          </RenderOrEmpty>
        </>
      );
    }

    return containerItem;
  };

  getSiteListOrMessage = () => {
    const {tableMode} = this.state;
    const {classes, accountInfo, sitesInOrganization, isEditMode, dashboardShown} = this.props;

    const tableClasses = {
      tableBody: classNames({
        [classes.organizationSitesTab__tableBody]: dashboardShown,
        [classes.organizationSitesTab__tableBody_noDashboard]: !dashboardShown,
      }),
    };
    const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', true);

    let containerItem = null;

    if (!isEditMode) {
      containerItem = (
        <>
          <RenderOrEmpty isShow={organizationsAllowed}>
            <OrganizationSitesTable
              classes={tableClasses}
              tableData={sitesInOrganization}
              mode={tableMode}
              isEditMode={isEditMode}
              removeSiteFromOrganization={this.handleRemoveSite}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={!organizationsAllowed}>
            <MessageBox
              message={I18n.t('organizations.errorMessages.organizationsNotAllowed')}
            />
          </RenderOrEmpty>
        </>
      );
    }
    return containerItem;
  };

  handleRemoveSite = (siteIndex) => {
    const {organization, sitesInOrganization} = this.props;
    this.props.removeSiteFromOrganization(
      siteIndex,
      organization.id,
      sitesInOrganization[siteIndex].id,
    )
      .then(() => {
        this.props.getOrganizationSites(this.props.organization.id);
        this.props.getOrganizationSiteStats(this.props.organization.id);
        if (!_isNil(this.props.onUpdateSites)) {
          this.props.onUpdateSites();
        }
      });
  };

  handleClickButtonCancelSitesEditing = () => {
    this.props.changeSitesEditMode(false);
  };

  handleClickButtonEditSites = () => {
    this.props.changeSitesEditMode(true);
  };

  handleClickButtonSaveSites = () => {
    const {
      organization,
      updatedSitesInOrganization,
    } = this.props;

    this.props.updateOrganizationSites(organization.id, updatedSitesInOrganization)
      .then(() => {
        if (!_isNil(this.props.onUpdateSites)) {
          this.props.onUpdateSites();
        }
      });
    this.props.changeSitesEditMode(false);
  };

  get isValid() {
    return this.props.sitesInOrganization.length > 0
      || this.props.sitesNotInOrganization.length > 0;
  }

  gotoSiteListAndReload = () => {
    this.setState(OrganizationSitesTab.initialLocalState);
    this.props.getOrganizationSites(this.props.organization.id);
  };

  // list on default, OrganizationSitesEditForm on edit
  render() {
    const cardClass = classNames({
      card_organizationSubtab: this.props.dashboardShown,
      card_organizationSubtab_noDashboard: !this.props.dashboardShown,
    });
    const contentClass = classNames({
      card__content_organizationSubtab: this.props.dashboardShown,
      card__content_organizationSubtab_noDashboard: !this.props.dashboardShown,
    });

    return (
      <EntityEditingCard
        key="sites-subtab"
        entityType={I18n.t('entitiesTypes.organization')}
        title={I18n.t('organizations.tabs.sites.title')}
        isEditMode={this.props.isEditMode}
        isThereEditBlock={true}
        isValidEntity={this.isValid}
        handleClickButtonCancel={this.handleClickButtonCancelSitesEditing}
        handleClickButtonEdit={this.handleClickButtonEditSites}
        handleClickButtonSave={this.handleClickButtonSaveSites}
        customCardClass={cardClass}
        customCardContentClass={contentClass}
        editButtonText={I18n.t('organizations.tabs.sites.editButtonLabel')}
        accessRights={rightAvailabilityMap}
        content={(
          <ContainerWithListAndForm
            list={this.getSiteListOrMessage()}
            form={this.getContainerItemByModeShowingContainer()}
          />
        )}
      />
    );
  }
}

OrganizationSitesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  sitesInOrganization: PropTypes.array.isRequired,
  sitesNotInOrganization: PropTypes.array.isRequired,
  updatedSitesInOrganization: PropTypes.array,
  dashboardShown: PropTypes.bool.isRequired,

  changeSitesEditMode: PropTypes.func.isRequired,
  removeSiteFromOrganization: PropTypes.func.isRequired,
  getOrganizationSites: PropTypes.func.isRequired,
  getOrganizationNetworkStats: PropTypes.func.isRequired,
  getOrganizationSiteStats: PropTypes.func.isRequired,
  updateOrganizationSites: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  onUpdateSites: PropTypes.func,
};

OrganizationSitesTab.defaultProps = {
  updatedSitesInOrganization: [],
  onUpdateSites: () => {},
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  organization: state.organizationsReducer.selectedOrganization,

  dashboardShown: state.universalDashboardReducer.isShowDashboard,

  isEditMode: state.organizationSitesTabReducer.isEditMode,
  sitesInOrganization: state.organizationSitesTabReducer.sitesInOrganization,
  sitesNotInOrganization: state.organizationSitesTabReducer.sitesNotInOrganization,
  updatedSitesInOrganization: state.organizationSitesEditFormReducer.sitesInOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  changeSitesEditMode: bindActionCreators(changeSitesEditMode, dispatch),
  removeSiteFromOrganization: bindActionCreators(removeSiteFromOrganization, dispatch),
  getOrganizationSites: bindActionCreators(getOrganizationSites, dispatch),
  getOrganizationNetworkStats: bindActionCreators(getOrganizationNetworkStats, dispatch),
  getOrganizationSiteStats: bindActionCreators(getOrganizationSiteStats, dispatch),
  updateOrganizationSites: bindActionCreators(updateOrganizationSites, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(OrganizationSitesTab)));

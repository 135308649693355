import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import {EntityEditingCard} from '../../components/index';
import {OrganizationEditForm} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.ORGANIZATION_EDIT],
};

class OrganizationInfoTab extends Component {
  render() {
    const {
      isEditMode,
      dashboardShown,
      isValidOrganizationInfo,
      handleClickButtonCancelEdit,
      handleClickButtonEdit,
      handleClickButtonSave,
    } = this.props;

    const contentClass = classNames({
      card__content_organizationInfo: dashboardShown,
      card__content_organizationInfo_noDashboard: !dashboardShown,
    });

    return (
      <EntityEditingCard
        key="info-subtab"
        entityType={I18n.t('entitiesTypes.organization')}
        title={I18n.t('organizations.tabs.info.title')}
        isEditMode={isEditMode}
        isThereEditBlock={true}
        isValidEntity={isValidOrganizationInfo}
        handleClickButtonCancel={handleClickButtonCancelEdit}
        handleClickButtonEdit={handleClickButtonEdit}
        handleClickButtonSave={handleClickButtonSave}
        customCardClass="card_organizationInfo"
        customCardContentClass={contentClass}
        accessRights={rightAvailabilityMap}
        content={(
          <OrganizationEditForm />
        )}
      />
    );
  }
}

OrganizationInfoTab.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  dashboardShown: PropTypes.bool.isRequired,
  isValidOrganizationInfo: PropTypes.bool.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isValidOrganizationInfo: state.organizationEditFormReducer.isValidOrganizationInfo,
  isEditMode: state.organizationsReducer.isEditMode,
  dashboardShown: state.universalDashboardReducer.isShowDashboard,
});

export default withRouter(connect(mapStateToProps, null)(OrganizationInfoTab));

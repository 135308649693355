import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {CustomButton} from '../../material-dashboard-pro-react/components';

import {RoleAvailability} from '../../containers/index';

import {
  EntitiesListWithViewModesCard,
  FilterWithPlaceholderAndFullTextFilterMethod, RenderOrEmpty, Switch,
} from '../index';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED_WIDTH, ROLES_AVAILABILITY, ACCESS_RIGHTS,
} from '../../constants';

import {hasAuthorities} from '../../utils/account';
import {formatDateTimeToUserTimezone} from '../../utils/dateTime';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE],
};

class OperatorsTable extends Component {
  get headersFullMode() {
    return [
      {
        Header: I18n.t('accessManagementPage.table.headers.email'),
        accessor: 'email',
        sortable: true,
        ...this.getFilter(['email', 'firstName', 'lastName']),
      },
      {
        Header: I18n.t('accessManagementPage.table.headers.firstName'),
        accessor: 'firstName',
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('accessManagementPage.table.headers.lastName'),
        accessor: 'lastName',
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('accessManagementPage.table.headers.role'),
        accessor: 'role',
        Cell: (c) => I18n.t(`userRoles.${c.value}`),
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('accessManagementPage.table.headers.authProviderType'),
        accessor: 'authProviderType',
        Cell: (c) => I18n.t(`authenticationProviderTypes.${c.value}`),
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('accessManagementPage.table.headers.lastLogin'),
        accessor: 'lastLogin',
        Cell: (rowInfo) => formatDateTimeToUserTimezone(rowInfo.value),
        sortable: true,
        filterable: false,
      },
    ];
  }

  get headersCompressedMode() {
    return [
      {
        Header: I18n.t('accessManagementPage.table.headers.email'),
        accessor: 'email',
        sortable: true,
        ...this.getFilter(['email']),
      },
    ];
  }

  get additionalHeaderActions() {
    const {accountInfo, isCompressed} = this.props;
    if (isCompressed) return null;

    const mfaAllowed = _get(accountInfo, 'mfaAllowed', false);
    const mfaEnabled = _get(accountInfo, 'mfaEnabled', false);

    return ([
      <RenderOrEmpty isShow={mfaAllowed}
                     key="enableMFA"
      >
        <RoleAvailability
          availability={ROLES_AVAILABILITY.enableMFA}
        >
          <Switch
            isChecked={mfaEnabled}
            label={I18n.t('accessManagementPage.force2FA')}
            name="force2FA"
            switchClassName="switch_inline"
            labelFormClassName="formControl_oneLine"
            labelClassName="switch__label_oneLine"
            onChange={this.props.enableDisableMFA}
          />
        </RoleAvailability>
      </RenderOrEmpty>,
      <RoleAvailability
        availability={ROLES_AVAILABILITY.promoteNewSuperAdmin}
        key="promoteNewSuperAdmin"
      >
        <CustomButton
          disabled={this.props.operators.length <= 1}
          color="yellow"
          customClasses="cardActions__button"
          onClick={this.props.onPromoteSAClick}
          size="xs"
        >
          {I18n.t('accessManagementPage.promoteNewSuperAdmin').toUpperCase()}
        </CustomButton>
      </RoleAvailability>,
    ]);
  }

  getFilter = (keys) => FilterWithPlaceholderAndFullTextFilterMethod(
    I18n.t('accessManagementPage.table.search'),
    keys,
  );

  render() {
    const mode = this.props.isCompressed ? COMPRESSED_WIDTH : FULL_WIDTH;
    const handleClickNew = hasAuthorities('accessManagement') ? this.props.handleClickNew : null;
    return (
      <EntitiesListWithViewModesCard
        handleClickButtonNew={handleClickNew}
        additionalHeaderActions={this.additionalHeaderActions}
        mode={mode}
        headersFullWidthMode={this.headersFullMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={this.props.operators}
        disabled={this.props.isEditing}
        title={I18n.t('accessManagementPage.title')}
        entityType={I18n.t('entitiesTypes.operator')}
        handleClickRow={this.props.handleClickRow}
        selectedEntityIndex={this.props.selectedOperatorIndex}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

OperatorsTable.propTypes = {
  classes: PropTypes.object,
  accountInfo: PropTypes.object.isRequired,
  onPromoteSAClick: PropTypes.func.isRequired,
  enableDisableMFA: PropTypes.func.isRequired,
  operators: PropTypes.array.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  isCompressed: PropTypes.bool.isRequired,
  selectedOperatorIndex: PropTypes.number.isRequired,
  handleClickNew: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

OperatorsTable.defaultProps = {
  classes: {},
};

export default OperatorsTable;

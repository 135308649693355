export const types = {
  SET_AVERAGE_LATENCY: 'SET_AVERAGE_LATENCY',
  SET_CAMPUSES_STATS: 'SET_CAMPUSES_STATS',
  SET_TOTAL_REQUEST_BY_DECISION: 'SET_TOTAL_REQUEST_BY_DECISION',
  SET_USER_REQUESTS_GRAPH: 'SET_USER_REQUESTS_GRAPH',
  SET_ORGS_AND_SITES_STATS: 'SET_ORGS_AND_SITES_STATS',
};

export const widgetKeys = {
  REQUEST_STATS: 'mainDashboard_requestStats',
  CAMPUSES_STATS: 'mainDashboard_campusStats',
  PERFORMANCE_STATS: 'mainDashboard_performanceStats',
};

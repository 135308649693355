import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function MessageBox(props) {
  const {
    boxClasses,
    messageClasses,
    message,
  } = props;
  return (
    <div className={`message-box ${boxClasses}`}>
      <span className={`message-box__message ${messageClasses}`}>{message}</span>
    </div>
  );
}

MessageBox.propTypes = {
  boxClasses: PropTypes.string,
  messageClasses: PropTypes.string,
  message: PropTypes.string,
};

MessageBox.defaultProps = {
  boxClasses: '',
  messageClasses: '',
  message: '',
};

export default MessageBox;

import {types} from './constants';
import {makeUrl, post, get} from '../../utils/fetcher';

import {METHODS, STATES_ENTITY} from '../../constants';
import {getClientBundlesData} from './utils';

export const loadBundles = (profileId) => {
  const url = makeUrl(METHODS.GET_CLIENT_BUNDLES, {profileId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_BUNDLES,
          bundles: getClientBundlesData(response.data),
        });
      }
    });
};

export const resetClientInstallFormData = () => (dispatch) => dispatch({
  type: types.RESET_CLIENT_INSTALL_FORM_DATA,
});

export function getBundleById(bundleId) {
  const url = makeUrl(METHODS.GET_CLIENT_BUNDLE_BY_ID, {id: bundleId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_SELECTED_CLIENT_BUNDLE,
            bundle: response.data,
          });
        }
      });
}

export function createBundle(newBundle) {
  const url = makeUrl(METHODS.CREATE_CLIENT_BUNDLE);
  return (dispatch) =>
    post(url, newBundle, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndBundleState(false, STATES_ENTITY.NONE));
          dispatch(loadBundles(newBundle.profileId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create roaming client bundle:', error);
      });
}

export function updateBundle(updatedBundle) {
  const url = makeUrl(METHODS.UPDATE_CLIENT_BUNDLE);
  return (dispatch) =>
    post(url, updatedBundle, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(loadBundles(updatedBundle.profileId)).then(() => dispatch({
            type: types.SET_UPDATED_CLIENT_BUNDLE,
            bundle: response.data,
          }));
          dispatch(changeEditModeAndBundleState(false, STATES_ENTITY.NONE));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update roaming client bundle:', error);
      });
}

export function deleteBundle(bundleId, profileId) {
  const data = {
    id: bundleId,
  };
  const url = makeUrl(METHODS.DELETE_CLIENT_BUNDLE);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(loadBundles(profileId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete roaming client bundle:', error);
      });
}

export function changeEditModeAndBundleState(isEditMode, bundleState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_CLIENT_BUNDLE_STATE,
      isEditMode,
      bundleState,
    });
  };
}

export function getDataToShowBundleDetails(bundleId, bundleIndex) {
  return (dispatch) => {
    dispatch(getBundleById(bundleId));
    dispatch(setSelectedBundleIndex(bundleIndex));
  };
}

export function setSelectedBundleIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CLIENT_BUNDLE_INDEX,
      selectedBundleIndex: index,
    });
  };
}

export function resetSelectedBundleIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_CLIENT_BUNDLE_INDEX,
    });
  };
}

import {types} from './constants';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.universalDashboardReducer, action) => {
  switch (action.type) {
    case types.TOGGLE_DASHBOARD: {
      return {
        ...state,
        isShowDashboard: !state.isShowDashboard,
      };
    }
    default:
      return state;
  }
};

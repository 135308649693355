import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {I18n} from 'react-redux-i18n';

import {CustomButton} from '../../material-dashboard-pro-react/components';
import {ROUTES, REG_EXR} from '../../constants';

import style from './style';
import {unsubscribeEmailFromNotification} from './action';

class UnsubscribeEmailFromNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      subtype: '',
      accountId: '',
      signature: '',
      isRequestSent: false,
      isUnsubscribingSuccessful: false,
      isInputDataValid: true,
    };
  }

  componentDidMount() {
    this.setInitState();
  }

  get textToRender() {
    const {
      email,
      subtype,
      accountId,
      isRequestSent,
      isUnsubscribingSuccessful,
      isInputDataValid,
    } = this.state;
    const textBeforeRequestSending = isInputDataValid ? 'question' : 'message.invalidInputData';
    return I18n.t(`unsubscribingEmailFromNotificationPage.${
      isRequestSent
        ? `message.${isUnsubscribingSuccessful ? 'success' : 'failure'}`
        : textBeforeRequestSending
    }`, {email, subtype, accountId});
  }

  get buttonToRender() {
    const {isRequestSent, isInputDataValid} = this.state;

    if (!isRequestSent && isInputDataValid) {
      return (
        <CustomButton
          fullWidth={true}
          color="secondary"
          customClasses="uppercase"
          onClick={this.unsubscribeEmailFromNotification}
        >
          {I18n.t('unsubscribingEmailFromNotificationPage.unsubscribe')}
        </CustomButton>
      );
    }

    return (
      <CustomButton
        fullWidth={true}
        color="secondary"
        customClasses="uppercase"
        onClick={this.goToApp}
      >
        {I18n.t('unsubscribingEmailFromNotificationPage.goToApp')}
      </CustomButton>
    );
  }

  setInitState() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const email = queryParams.get('email');
    const subtype = queryParams.get('subtype');
    const accountId = queryParams.get('accountId');
    const signature = queryParams.get('signature');
    if (!email || !subtype || !accountId || !signature || !REG_EXR.EMAIL.test(email)) {
      this.setState({isInputDataValid: false});
    } else {
      this.setState({email, subtype, accountId, signature});
    }
  }

  goToApp = () => {
    this.props.history.push(ROUTES.DEFAULT);
  };

  unsubscribeEmailFromNotification = () => {
    const {email, subtype, accountId, signature} = this.state;
    this.props.unsubscribeEmailFromNotification(email, subtype, accountId, signature)
      .then((result) => this.setState({
        isRequestSent: true,
        isUnsubscribingSuccessful: result,
      }));
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.unsubscribeEmailFromNotification}>
        <div
          className={classes.unsubscribeEmailFromNotification__text}
        >
          {this.textToRender}
        </div>
        {this.buttonToRender}
      </div>
    );
  }
}

UnsubscribeEmailFromNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  unsubscribeEmailFromNotification: PropTypes.func.isRequired,
};

const mapDispatchToProps = {unsubscribeEmailFromNotification};

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(withStyles(style)(UnsubscribeEmailFromNotification)));

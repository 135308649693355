/* eslint-disable max-len */
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';

const style = {
  filtersPanel: {
    marginTop: '10px',
  },
  commonFilters__container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  commonFilters__item: {
    flexBasis: '49%',
  },
  filtersPanel__showHideControl: {

  },
  additionalFilters__container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  additionalFilters__item: {
    flexBasis: '49%',
  },
  additionalFiltersItem__timePickers: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  additionalFilters__timePicker: {
    flexBasis: '49%',
  },
  filters__label: {
    ...customInputStyle.labelRoot,
    fontSize: '11px',
  },
  buttons__container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons__item: {
    marginLeft: '10px',
  },
};

export default style;

const style = {
  dialogDescription: {
    color: '#e4e4e4',
    marginBottom: '15px',
  },
  dialogWarning: {
    color: '#ffbe56',
    marginTop: '15px',
    display: 'flex',
  },
  warningIcon: {
    marginRight: '10px',
  },
  dropdownMenu: {
    zIndex: '6000',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import style from './style';

import {OS_NAMES} from '../../constants';
import {Mac, Unknown, Windows} from '../../icons';

class ClientOsIcon extends React.Component {
  get osIcons() {
    const {classes, osName} = this.props;
    return {
      [OS_NAMES.Windows]: <Windows className={classes.icon} title={osName} />,
      [OS_NAMES.Mac]: <Mac className={classes.icon} title={osName} />,
    };
  }

  render() {
    const {classes, iconKey, osName} = this.props;
    const icon = (this.osIcons[iconKey] !== undefined)
      ? this.osIcons[iconKey]
      : <Unknown className={classes.icon} title={osName} />;
    return (icon);
  }
}

ClientOsIcon.propTypes = {
  osName: PropTypes.string.isRequired,
  iconKey: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ClientOsIcon);

import {types} from './constants';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.singleSignOnReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_SINGLE_SIGN_ON_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        singleSignOnState: action.singleSignOnState,
      };
    }
    case types.SET_SINGLE_SIGN_ON_CONFIGURATIONS: {
      return {
        ...state,
        singleSignOnConfigurations: action.data,
      };
    }
    case types.SET_SELECTED_SINGLE_SIGN_ON_CONFIGURATION: {
      return {
        ...state,
        selectedSingleSignOnConfiguration: action.data,
      };
    }
    default:
      return state;
  }
};

import initState from '../../reducers/redux-initial-state';
import {types} from './constants';
import {getUpdatedLocalDomainIndex} from './utils';

export default (state = initState.clientProfileLocalForwardingFormReducer, action) => {
  switch (action.type) {
    case types.SET_CLIENT_LOCAL_DOMAINS: {
      return {
        ...state,
        localDomains: action.localDomains,
      };
    }
    case types.SET_SELECTED_CLIENT_LOCAL_FORWARDING: {
      return {
        ...state,
        selectedLocalDomain: action.localForwarding,
      };
    }
    case types.SET_UPDATED_CLIENT_LOCAL_FORWARDING: {
      return {
        ...state,
        selectedLocalDomainIndex: getUpdatedLocalDomainIndex(
          action.localForwarding.id,
          state.localDomains,
        ),
        selectedLocalDomain: action.localForwarding,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_CLIENT_LOCAL_FORWARDING_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        localForwardingState: action.localForwardingState,
      };
    }
    case types.SET_SELECTED_CLIENT_LOCAL_DOMAIN_INDEX: {
      return {
        ...state,
        selectedLocalDomainIndex: action.selectedLocalDomainIndex,
      };
    }
    case types.RESET_SELECTED_CLIENT_LOCAL_FORWARDING_INDEX: {
      return {
        ...state,
        selectedLocalDomain: initState.clientProfileLocalForwardingFormReducer.selectedLocalDomain,
        // eslint-disable-next-line max-len
        selectedLocalDomainIndex: initState.clientProfileLocalForwardingFormReducer.selectedLocalDomainIndex,
      };
    }

    default: {
      return state;
    }
  }
};

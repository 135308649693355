import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classNames from 'classnames';
import _isNull from 'lodash/isNull';

import _isUndefined from 'lodash/isUndefined';
import InfiniteTbody from './InfiniteTbody';

import {primaryColor} from '../../styles/style';
import Checkbox from '../Checkbox/Checkbox';

class InfiniteTable extends Component {
  constructor(props) {
    super(props);

    this.isSelectedCellRenderer = ({original}) => (
      <Checkbox
        isChecked={original.isSelected}
        inputProps={{
          onClick: (e) => this.handleCheckboxChange(e, original),
        }}
      />
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const dataArray = [...this.props.data];
      const dataWithSelectArray = [...this.props.dataWithSelect];

      dataArray.forEach((dataItem) => {
        const dataWithSelectItemFound = dataWithSelectArray
          .find((dataWithSelectItem) => dataWithSelectItem.id === dataItem.id);
        dataItem.isSelected = dataWithSelectItemFound
          ? dataWithSelectItemFound.isSelected
          : prevProps.selectAll;
      });

      this.props.handleChangeSelectAll(prevProps.selectAll);
      this.props.handleChangeDataWithSelect(dataArray);
    }
  }

  handleCheckboxChange = (e, row) => {
    e.stopPropagation();
    const {dataWithSelect} = this.props;
    const newData = dataWithSelect.map((item) =>
      (item.id === row.id ? {...item, isSelected: !item.isSelected} : item),
    );
    const updatedSelectAll = newData.every((item) => item.isSelected === true);
    this.props.handleChangeSelectAll(updatedSelectAll);
    this.props.handleChangeDataWithSelect(newData);
  };

  selectAll = () => {
    const {dataWithSelect, selectAll} = this.props;
    const newData = dataWithSelect.map((item) => ({
      ...item,
      isSelected: !selectAll,
    }));

    this.props.handleChangeSelectAll(!selectAll);
    this.props.handleChangeDataWithSelect(newData);
  };

  getTheadThProps = (state, rowInfo, column) => ({
    style: {
      lineHeight: '1em',
      display: _isNull(column.Header) ? 'none' : '',
    },
  });

  getTheadProps = () => ({
    style: {
      flex: '0.25 0 auto',
      borderTop: `1px solid ${primaryColor}`,
    },
  });

  getTbodyProps = () => {
    const {
      classNamesObj,
      loadData,
      loadDataEnd,
      data,
    } = this.props;
    return {
      style: {},
      loadData: loadData,
      loadDataEnd: loadDataEnd,
      infiniteTbodyClassName: classNamesObj.tbody,
      isThereTbodyData: data.length > 0,
    };
  };

  getTdProps = (state, rowInfo) => {
    const functionResult = {};
    if (rowInfo && !_isUndefined(this.props.handleClickRow)) {
      functionResult.onClick = () => this.props.handleClickRow(rowInfo.index);
      functionResult.style = {
        ...functionResult.style,
        cursor: 'pointer',
      };
    }
    functionResult.style = {
      ...functionResult.style,
      ...(this.props.disableWhiteSpaceNoWrap && {
        whiteSpace: 'normal',
      }),
    };
    return functionResult;
  };

  getTrProps = (state, rowInfo) => {
    const {selectedRowIndices} = this.props;
    let backgroundColor = null;
    if (rowInfo && selectedRowIndices.includes(rowInfo.index)) {
      backgroundColor = '#e5e5e5';
    }
    return {
      style: {
        backgroundColor: backgroundColor,
      },
    };
  };

  render() {
    const {
      filterable,
      columns,
      defaultPageSize,
      sortable,
      data,
      selectable,
      loading,
      style,
      isStriped,
      isHighlight,
      minRows,
      className,
      dataWithSelect,
      selectAll,
    } = this.props;

    const classNameWithEffects = classNames(className, {
      '-striped': isStriped,
      '-highlight': isHighlight,
    });

    const extendedColumns = [
      {
        Header: (
          <Checkbox onChange={this.selectAll}
                    isChecked={selectAll}
                    checkboxClassName="clients__checkbox"
          />
        ),
        accessor: 'isSelected',
        Cell: this.isSelectedCellRenderer,
        sortable: false,
        width: 50,
        resizable: false,
        filterable: false,
      },
      ...columns,
    ];

    return (
      <ReactTable
        data={selectable ? dataWithSelect : data}
        filterable={filterable}
        sortable={sortable}
        columns={selectable ? extendedColumns : columns}
        defaultPageSize={defaultPageSize}
        showPagination={false}
        style={style}
        className={classNameWithEffects}
        onFetchData={this.props.loadData}
        loading={loading}
        manual={true}
        minRows={minRows}
        getTheadProps={this.getTheadProps}
        getTheadThProps={this.getTheadThProps}
        getTbodyProps={this.getTbodyProps}
        getTrProps={this.getTrProps}
        getTdProps={this.getTdProps}
        TbodyComponent={InfiniteTbody}
      />
    );
  }
}

InfiniteTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  dataWithSelect: PropTypes.array,
  disableWhiteSpaceNoWrap: PropTypes.bool,
  columns: PropTypes.array,
  classNamesObj: PropTypes.object,
  defaultPageSize: PropTypes.number,
  filterable: PropTypes.bool,
  selectable: PropTypes.bool,
  sortable: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
  isStriped: PropTypes.bool,
  isHighlight: PropTypes.bool,
  minRows: PropTypes.number,
  selectedRowIndices: PropTypes.array,
  selectAll: PropTypes.bool,

  loadData: PropTypes.func.isRequired,
  loadDataEnd: PropTypes.func,
  handleClickRow: PropTypes.func,
  handleChangeDataWithSelect: PropTypes.func,
  handleChangeSelectAll: PropTypes.func,
};

InfiniteTable.defaultProps = {
  className: '',
  classNamesObj: {
    infiniteTbodyClassName: '',
  },
  columns: [],
  data: [],
  dataWithSelect: [],
  defaultPageSize: undefined,
  disableWhiteSpaceNoWrap: undefined,
  filterable: false,
  loading: false,
  sortable: true,
  style: {},
  isStriped: false,
  isHighlight: true,
  minRows: 10,
  selectedRowIndices: [],
  selectAll: false,

  loadDataEnd: () => {},
  handleClickRow: undefined,
  handleChangeDataWithSelect: () => {},
  handleChangeSelectAll: () => {},
  selectable: false,
};

export default InfiniteTable;

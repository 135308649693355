import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

import {Loading} from '../../containers/index';
import {DEFAULT_WIDGET_KEY} from '../../constants';

import style from './style';

class LoadingLayout extends Component {
  // eslint-disable-next-line
  isEditMode = () => {
    const {editModes} = this.props;
    return Object.keys(editModes).reduce(
      (isEditMode, key) => isEditMode || editModes[key],
      false,
    );
  };

  render() {
    const {classes, children, isEditMode, widgetKey} = this.props;
    const layoutClasses = classNames(
      classes.loadingLayout,
      {
        [classes.loadingLayout_disabled]: isEditMode,
      },
    );
    return (
      <div className={layoutClasses}>
        <Loading widgetKey={widgetKey} spinnerSize="sm" />
        {children}
      </div>
    );
  }
}

LoadingLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  widgetKey: PropTypes.string,
  editModes: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
};

LoadingLayout.defaultProps = {
  children: '',
  widgetKey: DEFAULT_WIDGET_KEY,
  isEditMode: false,
};

const mapStateToProps = (state) => ({
  notificationBannerData: state.operatorLayoutReducer.notificationBannerData,
  modalWindow: state.operatorLayoutReducer.modalWindow,
  accountInfo: state.operatorLayoutReducer.accountInfo,

  editModes: {
    isCampusEditMode: state.campusReducer.isEditMode,
    isNetworkEditMode: state.campusNetworksTabReducer.isEditMode,
    isSubnetEditMode: state.campusSubnetsTabReducer.isEditMode,
    isPolicyEditMode: state.policyReducer.isEditMode,
    isWhiteBlackListEditMode: state.whiteBlackListReducer.isEditMode,
    isAccessManagementEditMode: state.accessManagementReducer.isEditingMode,
    isClientManagerEditMode: state.clientsManagerReducer.isEditMode,
    isClientConfigurerEditMode: state.clientsConfigurerReducer.isEditMode,
    isClientInstallKeyEditMode: state.clientProfileInstallFormReducer.isEditMode,
  },
});

export default withRouter(connect(
  mapStateToProps,
  null,
)(withStyles(style)(LoadingLayout)));

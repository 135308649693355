import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _cloneDeep from 'lodash/cloneDeep';
import _pullAt from 'lodash/pullAt';
import _remove from 'lodash/remove';

import NavPills from '../../material-dashboard-pro-react/components/NavPills/NavPills';
import {CustomInput} from '../../material-dashboard-pro-react/components';

import {
  createList,
  deleteList,
  editList,
  getAllTlds,
  getListById,
  getPoliciesAssigned,
} from '../TldLists/action';

import {isNotEmpty, validate} from '../../utils/validators';
import {insertIntoSortedArray} from '../../utils/array';
import {hasOrgTag} from '../../utils/tags';

import {
  EntityCreatingCard,
  EntityEditingCard,
  TldSettings,
  PoliciesAssignedTable,
  RenderOrEmpty,
} from '../../components';
import {ACCESS_RIGHTS, MOVE_TO_PERMIT_LIST_OPTIONS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT],
  buttonDelete: [ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE],
};

const rightAvailabilityMapOrgOnly = {
  buttonEdit: [ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT__ORG],
  buttonDelete: [ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE__ORG],
};

class TldListForm extends React.Component {
  static validationRules = [{
    name: 'name',
    validationRules: [isNotEmpty],
  }];

  static INIT_STATE = {
    tldList: {
      name: '',
      description: '',
      allowedTldList: [],
    },
    validationResult: {
      validationState: {},
      isValid: true,
    },
  };

  state = {...TldListForm.INIT_STATE};

  componentDidMount() {
    if (this.props.selectedListId) {
      this.fetchCurrentList();
    } else {
      this.setNewListToStore();
      this.setState({validationResult: _cloneDeep(TldListForm.INIT_STATE.validationResult)});
    }
    if (_isEmpty(this.props.allTlds)) {
      this.props.getAllTlds().then(() => {
        if (this.props.isNew) {
          this.setNewListToStore();
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isEditing && this.props.isEditing !== prevProps.isEditing) {
      this.updateState();
    }

    if (this.props.isNew && this.props.isNew !== prevProps.isNew) {
      this.setNewListToStore();
    }

    if (this.props.selectedListId !== prevProps.selectedListId) {
      this.fetchCurrentList();
    }
  }

  get settings() {
    const tldFilteringSettingsObject = {
      permitList: _get(this.state.tldList, 'allowedTldList', []),
      blockList: _get(this.state.tldList, 'blockedTldList', []),
    };
    return (
      <TldSettings
        top100TldNames={this.props.top100TldNames}
        isEditMode={this.props.isEditing || this.props.isNew}
        tldFilteringSettingsObject={tldFilteringSettingsObject}
        handleMoveFromBlockToPermitList={this.permit}
        handleMoveFromPermitToBlockList={this.block}
        handleMoveFromBlockToPermitListByButton={this.moveFromBlockToPermitListByButton}
      />
    );
  }

  get information() {
    const tldName = _get(this.state.tldList, 'name', '');
    const tldDescription = _get(this.state.tldList, 'description', '');
    return (
      <>
        {this.getInformationField('name', tldName)}
        {this.getInformationField('description', tldDescription)}
        <RenderOrEmpty isShow={!this.props.isNew}>
          <PoliciesAssignedTable policies={this.props.policiesAssigned} />
        </RenderOrEmpty>
      </>
    );
  }

  get form() {
    return (
      <NavPills
        color="secondary"
        tabs={[{
          tabButton: I18n.t('tldListsPage.form.information'),
          tabContent: this.information,
        },
        {
          tabButton: I18n.t('tldListsPage.form.tldLists'),
          tabContent: this.settings,
        }]}
      />
    );
  }

  getInformationField = (fieldName, value) => {
    const formControlProps = {
      fullWidth: true,
    };
    const inputProps = {
      disabled: !this.props.isEditing && !this.props.isNew,
      onChange: this.updateFieldValue,
      type: 'text',
      name: fieldName,
      value,
    };
    const error = this.getError(fieldName);
    return (
      <CustomInput
      id={`information-${fieldName}`}
      inputProps={inputProps}
      labelText={I18n.t(`tldListsPage.form.${fieldName}`)}
      formControlProps={formControlProps}
      {...error}
      />
    );
  };

  getError = (field) => {
    const isError = !_get(this.state.validationResult, `validationState.${field}`, true);
    return {
      error: isError,
      errorText: isError ? I18n.t(`tldListsPage.form.error.${field}`) : undefined,
    };
  };

  setNewListToStore = () => this.setState({
    tldList: {
      ...TldListForm.INIT_STATE.tldList,
      blockedTldList: [...this.props.allTlds],
    },
  });

  updateFieldValue = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => {
      const tldList = _cloneDeep(prevState.tldList);
      tldList[fieldName] = value;

      const validationResult = validate(
        TldListForm.validationRules,
        tldList,
        prevState.validationResult.validationState,
      );

      return {tldList, validationResult};
    });
  };

  updateState = () => this.setState({
    tldList: this.props.tldList,
    validationResult: {
      validationState: {},
      isValid: !this.props.isNew,
    },
  });

  delete = () => this.props.deleteList(this.props.selectedListId)
    .then(this.props.deletingCallback);

  edit = () => this.props.editList(this.state.tldList)
    .then((id) => {
      if (!_isNil(id)) {
        this.props.updatingCallback(id);
      }
    });

  create = () => this.props.createList(this.state.tldList)
    .then((id) => {
      if (!_isNil(id)) {
        this.props.updatingCallback(id);
      }
    });

  cancelEditing = () => this.props.cancelEditing();

  permit = (indices) => this.setState((prevState) => {
    const tldList = _cloneDeep(prevState.tldList);
    const tldLists = this.moveItemsBetweenLists(
      tldList.blockedTldList,
      tldList.allowedTldList,
      indices,
    );
    return {
      tldList: {
        ...tldList,
        blockedTldList: tldLists.source,
        allowedTldList: tldLists.destination,
      },
    };
  });

  block = (indices) => this.setState((prevState) => {
    const tldList = _cloneDeep(prevState.tldList);
    const tldLists = this.moveItemsBetweenLists(
      tldList.allowedTldList,
      tldList.blockedTldList,
      indices,
    );
    return {
      tldList: {
        ...tldList,
        allowedTldList: tldLists.source,
        blockedTldList: tldLists.destination,
      },
    };
  });

  moveFromBlockToPermitListByButton = (option) => this.setState((prevState) => {
    const top100TldNames = _cloneDeep(this.props.top100TldNames);
    const tldList = _cloneDeep(prevState.tldList);
    const blockedTldList = tldList.blockedTldList;
    const allowedTldList = tldList.allowedTldList;

    let itemsToMove = [];
    switch (option) {
      case MOVE_TO_PERMIT_LIST_OPTIONS.ALL: {
        itemsToMove = _remove(blockedTldList);
        break;
      }
      case MOVE_TO_PERMIT_LIST_OPTIONS.TOP_25: {
        itemsToMove = _remove(
          blockedTldList,
          (elem) => top100TldNames.slice(0, 25).includes(elem.name),
        );
        break;
      }
      case MOVE_TO_PERMIT_LIST_OPTIONS.TOP_50: {
        itemsToMove = _remove(
          blockedTldList,
          (elem) => top100TldNames.slice(0, 50).includes(elem.name),
        );
        break;
      }
      case MOVE_TO_PERMIT_LIST_OPTIONS.TOP_100: {
        itemsToMove = _remove(
          blockedTldList,
          (elem) => top100TldNames.includes(elem.name),
        );
        break;
      }
      default: {
        break;
      }
    }
    const newAllowedTldList = insertIntoSortedArray(allowedTldList, itemsToMove, 'ranking');

    return {
      tldList: {
        ...tldList,
        blockedTldList,
        allowedTldList: newAllowedTldList,
      },
    };
  });

  moveItemsBetweenLists = (source, destination, indices) => {
    const newSource = [...source];
    const itemsToMove = _pullAt(newSource, indices);
    const newDestination = insertIntoSortedArray(destination, itemsToMove, 'ranking');
    return {
      source: newSource,
      destination: newDestination,
    };
  };

  fetchCurrentList = () =>
    this.props.getListById(this.props.selectedListId)
      .then(() => this.props.getPoliciesAssigned(this.props.selectedListId))
      .then(() => this.updateState());

  render() {
    if (this.props.isNew) {
      return (
        <EntityCreatingCard
          title={I18n.t('tldListsPage.newList.title')}
          content={this.form}
          handleClickButtonCancel={this.props.resetSelection}
          handleClickButtonSave={this.create}
          isValidEntity={this.state.validationResult.isValid}
          isFullscreen={false}
          customCardClass="card_tldFilter"
        />
      );
    }

    const title = _get(this.props.tldList, 'name', '');
    const tags = _get(this.props.tldList, 'tags', []);

    const organizationPolicy = hasOrgTag(tags);

    let rightAvailabilityMapFinal;
    if (!organizationPolicy) {
      rightAvailabilityMapFinal = rightAvailabilityMap;
    } else {
      rightAvailabilityMapFinal = rightAvailabilityMapOrgOnly;
    }
    return (
      <EntityEditingCard
        entityType={I18n.t('entitiesTypes.tldFilterList')}
        title={title}
        content={this.form}
        isTherePathBack={true}
        isThereEditBlock={true}
        isThereDeleteBlock={true}
        isFullscreen={false}
        isValidEntity={this.state.validationResult.isValid}
        isEditMode={this.props.isEditing}
        handleClickButtonDelete={this.delete}
        handleClickButtonEdit={this.props.setEditing}
        handleClickButtonCancel={this.cancelEditing}
        handleClickButtonBack={this.props.resetSelection}
        handleClickButtonSave={this.edit}
        accessRights={rightAvailabilityMapFinal}
      />
    );
  }
}

TldListForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,
  deletingCallback: PropTypes.func.isRequired,
  tldList: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tags: PropTypes.array,
    description: PropTypes.string,
    allowedTldList: PropTypes.array,
    blockedTldList: PropTypes.array,
  }),
  selectedListId: PropTypes.string,
  policiesAssigned: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,

  getAllTlds: PropTypes.func.isRequired,
  createList: PropTypes.func.isRequired,
  getListById: PropTypes.func.isRequired,
  editList: PropTypes.func.isRequired,
  deleteList: PropTypes.func.isRequired,
  getPoliciesAssigned: PropTypes.func.isRequired,

  allTlds: PropTypes.array.isRequired,
  top100TldNames: PropTypes.array.isRequired,
};

TldListForm.defaultProps = {
  tldList: null,
  selectedListId: null,
};

const mapStateToProps = (state) => ({
  tldList: state.tldListsReducer.selectedTldList,
  allTlds: state.tldListsReducer.allTlds,
  top100TldNames: state.tldListsReducer.top100TldNames,
  policiesAssigned: state.tldListsReducer.policiesAssigned,
});

const mapDispatchToProps = {
  getAllTlds,
  createList,
  editList,
  getListById,
  deleteList,
  getPoliciesAssigned,
};

export default connect(mapStateToProps, mapDispatchToProps)(TldListForm);

import {
  defaultFont,
  primaryColor,
  secondaryColor,
  sidebarWidth,
  sidebarMiniWidth,
  sidebarRightWidth,
} from '../../styles/style';

import {
  boxShadow,
  transition,
} from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  drawerPaper: {
    'border': 'none',
    'position': 'fixed',
    'top': '0',
    'bottom': '0',
    'left': '0',
    'zIndex': '1032',
    'transitionProperty': 'top, bottom, width',
    'transitionDuration': '.2s, .2s, .35s',
    'transitionTimingFunction': 'linear, linear, ease',
    // overflow: 'auto',
    ...boxShadow,
    'width': sidebarWidth,
    [theme.breakpoints.up('md')]: {
      width: sidebarWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: sidebarRightWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      padding: '0',
      transform: `translate3d(${sidebarRightWidth}, 0, 0)`,
      ...transition,
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
  drawerPaper_primary: {
    'color': '#FFFFFF',
    'backgroundColor': primaryColor,
    '&:after': {
      backgroundColor: primaryColor,
      opacity: '0',
    },
  },
  drawerPaper_mini: {
    width: `${sidebarMiniWidth}!important`,
    overflowX: 'hidden!important',
  },
  navLinks: {
    'margin': '0',
    'padding': '0',
    'listStyle': 'none',
    'color': 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  navLinks__tooltipPopper: {
    left: '70px!important',
  },
  navLinks__tooltip: {
    background: primaryColor,
    color: '#FFF',
    boxShadow: theme.shadows[1],
    fontSize: 17,
  },
  navLinks__item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
  },
  navLinks__item_link: {
    'margin': '10px 10px 0',
    'padding': '10px 0 10px 5px',
    'transition': 'all 300ms linear',
    'borderRadius': '3px',
    'position': 'relative',
    'display': 'block',
    'backgroundColor': 'transparent',
    ...defaultFont,
    'width': 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  navLinks__item_linkMini: {
    padding: '10px',
    margin: '10px',
  },
  navLinks__item_activeLink: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      background: secondaryColor,
    },
  },
  navLinks__item_icon: {
    color: 'white',
    width: '30px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '10px',
    opacity: '0.9',
  },
  navLinks__item_iconMini: {
    margin: '0 0 0 4px',
    float: 'none',
  },
  navLinks__item_text: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
  },
  collapseNavLinks: {
    marginTop: '0',
  },
  collapseNavLinks_active: {
    outline: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    boxShadow: 'none',
  },
  collapseNavLinks__item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
  },
  collapseNavLinks__item_link: {
    'transition': 'all 300ms linear',
    'margin': '0 15px',
    'borderRadius': '3px',
    'position': 'relative',
    'display': 'block',
    'padding': '10px',
    'backgroundColor': 'transparent',
    ...defaultFont,
    'width': 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  collapseNavLinks__item_mini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseNavLinks__item_text: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseNavLinks__item_textMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  logo: {
    padding: '10px 10px',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: 'solid 1px #FFF',
    zIndex: '4',
  },
  logo__img: {
    height: '33px',
    width: '150px',
    verticalAlign: 'middle',
    border: '0',
  },
  logo_mini: {
    padding: '0',
    display: 'inline',
    borderBottom: 'none',
    zIndex: '4',
    margin: '10px auto 10px',
  },
  logo__img_mini: {
    width: '70px',
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'visible',
    zIndex: '4',
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '18px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  caret_active: {
    transform: 'rotate(180deg)',
  },
  sectionDivider: {
    height: '1px',
    backgroundColor: '#FFF',
  },
  listItem__root: {
    display: 'block',
  },
});

export default style;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {TABS as clientsTabs} from '../../app-common/Clients/constants';
import {
  EntitiesListWithViewModesCard,
} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_CREATE],
};

class ClientProfileLocalDomainsTable extends Component {
  constructor(props) {
    super(props);

    this.headersFullWidthMode = [
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.localForwarding.table.headers.domain`),
        accessor: 'domain',
        filterable: false,
        sortable: true,
        minWidth: 200,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.localForwarding.table.headers.resolvers`),
        accessor: 'resolvers',
        sortable: false,
        filterable: false,
        minWidth: 400,
      },
    ];
    this.headersCompressedMode = [
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.localForwarding.table.headers.domain`),
        accessor: 'domain',
        filterable: false,
        sortable: true,
      },
    ];
  }

  render() {
    const {
      mode,
      handleClickButtonNew,
      handleClickRow,
      isEditMode,
      tableData,
      selectedLocalDomainIndex,
    } = this.props;
    const tableStyles = {
      height: 'calc(100vh - 308px)',
    };

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t(`clients.${clientsTabs.CONFIGURE}.localForwarding.table.title`)}
        entityType={I18n.t('entitiesTypes.clientProfileLocalForwarding')}
        selectedEntityIndex={selectedLocalDomainIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickRow}
        tableStyles={tableStyles}
        fullscreen={false}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

ClientProfileLocalDomainsTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedLocalDomainIndex: PropTypes.number.isRequired,
};

ClientProfileLocalDomainsTable.defaultProps = {
  tableData: [],
};

export default ClientProfileLocalDomainsTable;

import React from 'react';
import {I18n} from 'react-redux-i18n';

import NavPills from '../../material-dashboard-pro-react/components/NavPills/NavPills';

import {TldLists, Whitelist, RoleAvailability} from '../../containers';

import {USER_ROLES} from '../../constants';

function CustomLists() {
  const userAvailabilityMap = {
    [USER_ROLES.OEM_ADMIN]: false,
    [USER_ROLES.OEM_SUPER_ADMIN]: false,
  };

  const alternativeNavigation = (
    <NavPills
    color="secondary"
    className="root_fullScreen"
    wrapperClassName="tab__contentWrapper_fullScreen"
    contentClassName="tab__content_fullScreen"
    tabs={[
      {
        tabButton: I18n.t('customListsPage.wbLists'),
        tabContent: <Whitelist />,
      },
    ]}
    />
  );

  return (
    <RoleAvailability availability={userAvailabilityMap} alternativeChild={alternativeNavigation}>
      <NavPills
        color="secondary"
        className="root_fullScreen"
        wrapperClassName="tab__contentWrapper_fullScreen"
        contentClassName="tab__content_fullScreen"
        tabs={[
          {
            tabButton: I18n.t('customListsPage.wbLists'),
            tabContent: <Whitelist />,
          },
          {
            tabButton: I18n.t('customListsPage.tldFilter'),
            tabContent: <TldLists />,
          },
        ]}
      />
    </RoleAvailability>
  );
}

export default CustomLists;

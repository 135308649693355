import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import style from './style';

function NotifyModalWindow(props) {
  const {
    classes,
    text,
  } = props;

  const dialogClasses = {
    paper: classes.modalWindow__paper,
    root: classes.modalWindow,
  };
  const dialogTitleClasses = {
    root: classes.modalWindow__titleRoot,
  };
  const dialogActionsClasses = {
    root: classes.modalWindow__actions,
  };

  return (
    <Dialog
      open={true}
      onClose={props.close}
      classes={dialogClasses}
    >
      <DialogTitle classes={dialogTitleClasses}>
        <p
          className={classNames(
            classes.modalWindow__title,
            classes.modalWindow__title_notify,
          )}
        >
          {text}
        </p>
      </DialogTitle>
      <DialogActions classes={dialogActionsClasses}>
        <CustomButton
          customClasses="modalWindow__button"
          color="red"
          onClick={props.close}
        >
          {I18n.t('modalWindow.buttonOk')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

NotifyModalWindow.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default withStyles(style)(NotifyModalWindow);

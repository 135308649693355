import PropTypes from 'prop-types';
import {connect} from 'react-redux';

function AccountTypeAvailability(props) {
  const {
    accountType,
    alternativeChild,
    availability,
    children,
  } = props;

  const availabilityMap = {
    subscriber: true,
    test: true,
    trial: true,
    ...availability,
  };

  let child;
  if (availabilityMap[accountType]) {
    child = children;
  } else {
    child = alternativeChild;
  }
  return child;
}

AccountTypeAvailability.propTypes = {
  accountType: PropTypes.string.isRequired,
  alternativeChild: PropTypes.node,
  availability: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

AccountTypeAvailability.defaultProps = {
  alternativeChild: null,
};

const mapStateToProps = (state) => ({
  accountType: state.operatorLayoutReducer.accountType,
});

export default connect(mapStateToProps, null)(AccountTypeAvailability);

import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import {CustomInput} from '../../material-dashboard-pro-react/components';

const FIELD_PROPS = {
  disabled: true,
  fullWidth: true,
};

function FieldsList(props) {
  return props.fields.map((f) => {
    const id = uuid();
    const value = f.value !== null ? f.value : '';
    return (
      <CustomInput
        key={id}
        id={id}
        labelText={f.label}
        formControlProps={FIELD_PROPS}
        inputProps={{value}}
      />
    );
  });
}

FieldsList.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
};

export default FieldsList;

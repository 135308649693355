import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {EntityEditingCard, NotificationCardWithHeader} from '../../components';
import {ACCESS_RIGHTS, MAX_FIRST_OR_LAST_NAME_LENGTH} from '../../constants';
import CustomInput from '../../material-dashboard-pro-react/components/CustomInput/CustomInput';
import {isNotEmpty, validate} from '../../utils/validators';
import {getFirstAndLastName, updateFirstAndLastName} from './action';
import style from './style';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT],
};

class UpdateFirstAndLastNameForm extends Component {
  static firstNameValidationRules = [{
    name: 'firstName',
    validationRules: [
      isNotEmpty,
      (str) => str.length <= MAX_FIRST_OR_LAST_NAME_LENGTH,
    ],
  }];

  static lastNameValidationRules = [{
    name: 'lastName',
    validationRules: [
      isNotEmpty,
      (str) => str.length <= MAX_FIRST_OR_LAST_NAME_LENGTH,
    ],
  }];

  static initialState = {
    firstName: '',
    lastName: '',
    isEditMode: false,
    firstNameValidationResult: {
      validationState: {},
      isValid: true,
    },
    lastNameValidationResult: {
      validationState: {},
      isValid: true,
    },
    isNotificationActive: false,
  };

  static wasDaraScopeSwithed(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  state = UpdateFirstAndLastNameForm.initialState;

  componentDidMount() {
    this.setInitState();
  }

  componentDidUpdate(prevProps) {
    if (UpdateFirstAndLastNameForm.wasDaraScopeSwithed(prevProps, this.props)) {
      this.setInitState();
    }
  }

  get initialFirstName() {
    return _get(this.props, 'oldFirstName', '');
  }

  get initialLastName() {
    return _get(this.props, 'oldLastName', '');
  }

  get firstNameInput() {
    const propsFormControl = {
      fullWidth: true,
    };
    const inputProps = {
      value: this.state.firstName,
      name: 'updateFirstName',
      onChange: this.handleFirstNameChange,
      disabled: !this.state.isEditMode,
    };
    const validationError = this.firstNameValidationError;

    return (
      <CustomInput
        errorText={validationError.error ? validationError.errorText : undefined}
        error={!!validationError.error}
        labelText={I18n.t('profilePage.generalInfoBlock.labels.firstName')}
        formControlProps={propsFormControl}
        inputProps={inputProps}
        {...this.error}
      />
    );
  }

  get lastNameInput() {
    const propsFormControl = {
      fullWidth: true,
    };
    const inputProps = {
      value: this.state.lastName,
      name: 'updateLastName',
      onChange: this.handleLastNameChange,
      disabled: !this.state.isEditMode,
    };
    const validationError = this.lastNameValidationError;

    return (
      <CustomInput
        errorText={validationError.error ? validationError.errorText : undefined}
        error={!!validationError.error}
        labelText={I18n.t('profilePage.generalInfoBlock.labels.lastName')}
        formControlProps={propsFormControl}
        inputProps={inputProps}
        {...this.error}
      />
    );
  }

  get firstNameValidationError() {
    if (!_get(this.state, 'firstNameValidationResult.isValid', true)) {
      return {
        error: true,
        errorText: I18n.t(
          'profilePage.generalInfoBlock.validationErrors.firstNameIsNotValid',
          {maxLength: MAX_FIRST_OR_LAST_NAME_LENGTH},
        ),
      };
    }
    return {};
  }

  get lastNameValidationError() {
    if (!_get(this.state, 'lastNameValidationResult.isValid', true)) {
      return {
        error: true,
        errorText: I18n.t(
          'profilePage.generalInfoBlock.validationErrors.lastNameIsNotValid',
          {maxLength: MAX_FIRST_OR_LAST_NAME_LENGTH},
        ),
      };
    }
    return {};
  }

  setInitState = () => {
    this.props.getFirstAndLastName()
      .then(() => this.setState({
        ...UpdateFirstAndLastNameForm.initialState,
        firstName: this.initialFirstName,
        lastName: this.initialLastName,
      }));
  };

  handleFirstNameChange = (e) => {
    const firstName = _get(e, 'target.value', '');
    this.setState((prevState) => {
      const firstNameValidationResult = validate(
        UpdateFirstAndLastNameForm.firstNameValidationRules,
        {firstName: firstName.trim()},
        prevState.firstNameValidationResult.validationState,
      );
      return {
        firstName,
        firstNameValidationResult,
      };
    });
  };

  handleLastNameChange = (e) => {
    const lastName = _get(e, 'target.value', '');
    this.setState((prevState) => {
      const lastNameValidationResult = validate(
        UpdateFirstAndLastNameForm.lastNameValidationRules,
        {lastName: lastName.trim()},
        prevState.lastNameValidationResult.validationState,
      );
      return {
        lastName,
        lastNameValidationResult,
      };
    });
  };

  save = () => this.props.updateFirstAndLastName(
    this.state.firstName.trim(),
    this.state.lastName.trim(),
  )
    .then((responseStatus) => {
      if (responseStatus) {
        this.setState({isNotificationActive: true});
      }
    });

  render() {
    const isValid = (
      _get(this.state, 'firstNameValidationResult.isValid', true)
      && _get(this.state, 'lastNameValidationResult.isValid', true)
    );
    const isSaveAvailable = isValid && (
      !_isEqual(this.initialFirstName, this.state.firstName)
      || !_isEqual(this.initialLastName, this.state.lastName)
    );
    const {classes} = this.props;

    if (this.state.isNotificationActive) {
      return (
        <NotificationCardWithHeader
          cardTitle={I18n.t('profilePage.generalInfoBlock.title')}
          content={(
            <div className={classes.updateFirstAndLastNameForm__notificationMessage}>
              {I18n.t('profilePage.generalInfoBlock.notification')}
            </div>
          )}
          onClickOk={this.setInitState}
        />
      );
    }

    return (
      <EntityEditingCard
        title={I18n.t('profilePage.generalInfoBlock.title')}
        entityType={I18n.t('profilePage.generalInfoBlock.title')}
        handleClickButtonSave={this.save}
        isValidEntity={isSaveAvailable}
        isFullscreen={false}
        content={(
          <>
            {this.firstNameInput}
            {this.lastNameInput}
          </>
        )}
        isThereEditBlock={true}
        isEditMode={this.state.isEditMode}
        handleClickButtonEdit={() => this.setState({isEditMode: true})}
        handleClickButtonCancel={() => this.setInitState()}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

UpdateFirstAndLastNameForm.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  updateFirstAndLastName: PropTypes.func.isRequired,
  getFirstAndLastName: PropTypes.func.isRequired,
  oldFirstName: PropTypes.string,
  oldLastName: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

UpdateFirstAndLastNameForm.defaultProps = {
  oldFirstName: '',
  oldLastName: '',
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
  oldFirstName: state.updateFirstAndLastNameReducer.oldFirstName,
  oldLastName: state.updateFirstAndLastNameReducer.oldLastName,
});

const mapDispatchToProps = {updateFirstAndLastName, getFirstAndLastName};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(UpdateFirstAndLastNameForm));

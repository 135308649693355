import {
  secondaryColor,
} from '../../styles/style';

const style = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  item: {
    backgroundColor: secondaryColor,
    borderRadius: '25px',
    color: '#FFF',
    boxSizing: 'border-box',
    padding: '2px 10px',
    maxWidth: 'min(100%, 20em)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';
import {Print as PrintIcon} from '../../icons/index';

import './style.css';

function ButtonPrint(props) {
  return (
    <CustomButton
      color="secondaryNoBackground"
      onClick={props.onClick}
      size="sm"
      customClasses="cardActions__button_print"
    >
      <PrintIcon />
      <span className="button-print__text">
        {I18n.t('buttons.print').toUpperCase()}
      </span>
    </CustomButton>
  );
}

ButtonPrint.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ButtonPrint;

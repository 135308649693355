import {darkerGreenColor, redColor} from '../../styles/style';

const style = {
  cellContainer: {
    textAlign: 'center',
  },
  usersOnline: {
    color: darkerGreenColor,
  },
  usersOffline: {
    color: redColor,
  },
};

export default style;

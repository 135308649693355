import {
  greenColor,
  redColor,
  primaryColor,
} from '../../styles/style';

const style = {
  up: {
    color: greenColor,
  },
  down: {
    color: redColor,
  },
  requestsPerSecond: {
    color: primaryColor,
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';

import style from './style';

function CardStats({...props}) {
  const {
    classes,
    value,
    description,
    borderTopColor,
    descriptionIcon,
    valueIcon,
  } = props;

  const tooltip = {
    classes: {
      tooltip: classes.cardStats__tooltip,
      popper: classes.cardStats__tooltipPopper,
    },
    title: value,
    placement: 'top',
  };

  return (
    <Card className={classes.cardStats}>
      <CardContent
        className={classNames(
          classes.cardStats__content,
          classes[`cardStats__borderTop_${borderTopColor}`],
        )}
      >
        <Tooltip
          open={false}
          classes={tooltip.classes}
          placement={tooltip.placement}
          title={tooltip.title}
        >
          <div className={classes.cardStats__textContainer}>
            {valueIcon}
            <h2 className={classes.cardStats__value}>
              {value}
            </h2>
          </div>
        </Tooltip>
        <div className={classes.cardStats__textContainer}>
          {descriptionIcon}
          <p className={classes.cardStats__description}>
            {description}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}

CardStats.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.node,
  description: PropTypes.node,
  borderTopColor: PropTypes.oneOf([
    'green',
    'yellow',
    'primary',
    'red',
  ]),
  descriptionIcon: PropTypes.node,
  valueIcon: PropTypes.node,
};

CardStats.defaultProps = {
  value: '',
  description: '',
  borderTopColor: 'primary',
  descriptionIcon: '',
  valueIcon: '',
};

export default withStyles(style)(CardStats);

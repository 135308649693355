import {USER_ROLES} from '../../constants';

const rolesWeight = {
  [USER_ROLES.SUPER_ADMIN]: 0,
  [USER_ROLES.ADMIN]: 1,
  [USER_ROLES.PROJECT_VIEWER]: 2,
  [USER_ROLES.VIEWER]: 3,
  [USER_ROLES.ORGANIZATION_OPERATOR]: 4,
  [USER_ROLES.OEM_SUPER_ADMIN]: 5,
  [USER_ROLES.OEM_ADMIN]: 6,
};

export default function sortUserAccounts(userAccounts) {
  userAccounts.sort((a, b) => {
    const weightRoleA = rolesWeight[a.role];
    const weightRoleB = rolesWeight[b.role];
    return weightRoleA - weightRoleB;
  });
  return userAccounts;
}

import {types} from './constants';
import {get, getWithInterrupt, makeUrl, post, postWithCsvResponse} from '../../utils/fetcher';

import {METHODS, STATES_ENTITY} from '../../constants';
import {getClientsTableData} from './utils';

export const loadProfiles = () => {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILE_NAMES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_PROFILE_NAMES,
          profiles: response.data,
        });
      }
    });
};

export function getClientPolicies(profileId) {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILE_POLICIES, {profileId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_CLIENT_POLICIES,
            data,
          });
        }
      });
}

export const setSearchOption = (optionName, value) => (dispatch) => dispatch({
  type: types.SET_SEARCH_OPTION,
  optionName,
  value,
});

export const getClients = (pageOptions, actionAfterLoad, roamingLabel) => {
  const url = makeUrl(METHODS.GET_CLIENTS, pageOptions);
  return (dispatch) => {
    const requestInfo = getWithInterrupt(url, dispatch);
    requestInfo.request
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: actionAfterLoad,
            data: getClientsTableData(response.data, roamingLabel),
          });
        }
      });
    return requestInfo.request;
  };
};

export const getClientsTotal = (pageOptions) => {
  const url = makeUrl(METHODS.GET_CLIENTS_TOTAL, pageOptions);
  return (dispatch) => {
    const requestInfo = getWithInterrupt(url, dispatch);
    requestInfo.request
      .then((response) => {
        if (response && response.data) {
          return response;
        }
      });
    return requestInfo.request;
  };
};

export const loadClients = (pageOptions, roamingLabel) =>
  getClients(pageOptions, types.SET_CLIENTS, roamingLabel);

export const loadMoreClients = (pageOptions, roamingLabel) =>
  getClients(pageOptions, types.ADD_CLIENTS, roamingLabel);

export const resetClients = () => (dispatch) => {
  dispatch({
    type: types.RESET_CLIENTS,
  });
};

export const resetClientsManagerData = () => (dispatch) => dispatch({
  type: types.RESET_CLIENT_MANAGER_DATA,
});

export const resetFilters = () => (dispatch) => dispatch({
  type: types.RESET_FILTERS,
});

export function getClientById(clientId, roamingLabel) {
  const url = makeUrl(METHODS.GET_CLIENT_BY_ID, {id: clientId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_SELECTED_CLIENT,
            client: response.data,
            roamingLabel,
          });
          return response.data;
        }
      });
}

export function updateClient(updatedClient, searchOptionsWithSorting, roamingLabel) {
  const url = makeUrl(METHODS.UPDATE_CLIENT);
  const data = updatedClient;
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndClientState(false, STATES_ENTITY.NONE));
          dispatch(loadClients(
            searchOptionsWithSorting,
            roamingLabel,
          )).then(() => dispatch({
            type: types.SET_UPDATED_CLIENT,
            client: response.data,
          }));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit roaming client:', error);
      });
}

export function changeEditModeAndClientState(isEditMode, clientState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_CLIENT_STATE,
      isEditMode,
      clientState,
    });
  };
}

export function getDataToShowClientDetails(clientId, clientIndex, roamingLabel) {
  return (dispatch) => {
    dispatch(getClientById(clientId, roamingLabel));
    dispatch(setSelectedClientIndex(clientIndex));
  };
}

export function setSelectedClientIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CLIENT_INDEX,
      selectedClientIndex: index,
    });
  };
}

export function resetSelectedClientIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_CLIENT_INDEX,
    });
  };
}

export function setClientStatus(newStatus, selectedClientIndex) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CLIENT_STATUS,
      index: selectedClientIndex,
      status: newStatus,
    });
  };
}

export function disableClient(clientId, selectedClientIndex, roamingLabel) {
  const url = makeUrl(METHODS.DISABLE_CLIENT);
  const data = {id: clientId};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(getClientById(clientId, roamingLabel))
            .then((client) =>
              dispatch(setClientStatus(client.agentStatus, selectedClientIndex)),
            );
        }
        return response && response.success;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to disable roaming client:', error);
      });
}

export function enableClient(clientId, selectedClientIndex, roamingLabel) {
  const url = makeUrl(METHODS.ENABLE_CLIENT);
  const data = {id: clientId};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(getClientById(clientId, roamingLabel))
            .then((client) =>
              dispatch(setClientStatus(client.agentStatus, selectedClientIndex)),
            );
        }
        return response && response.success;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to enable roaming client:', error);
      });
}

export function forgetClient(clientId) {
  const url = makeUrl(METHODS.FORGET_CLIENT);
  const data = {id: clientId};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to forget roaming client:', error);
      });
}

export function uninstallClient(clientId) {
  const url = makeUrl(METHODS.UNINSTALL_CLIENT);
  const data = {id: clientId};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to uninstall roaming client:', error);
      });
}

export function disableClientsByIdList(idList, pageOptions, processAllClients) {
  const url = makeUrl(METHODS.DISABLE_CLIENTS_BY_ID_LIST);
  const data = {
    idList,
    processAllClients: processAllClients,
    agentStatus: pageOptions.agentStatus,
    onSiteStatus: pageOptions.onSiteStatus,
    clientName: pageOptions.clientName,
    profileId: pageOptions.profileId,
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to disable roaming client list:', error);
      });
}

export function enableClientsByIdList(idList, pageOptions, processAllClients) {
  const url = makeUrl(METHODS.ENABLE_CLIENTS_BY_ID_LIST);
  const data = {
    idList,
    processAllClients: processAllClients,
    agentStatus: pageOptions.agentStatus,
    onSiteStatus: pageOptions.onSiteStatus,
    clientName: pageOptions.clientName,
    profileId: pageOptions.profileId,
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to enable roaming client list:', error);
      });
}

export function forgetClientsByIdList(idList, pageOptions, processAllClients) {
  const url = makeUrl(METHODS.FORGET_CLIENTS_BY_ID_LIST);
  const data = {
    idList,
    processAllClients: processAllClients,
    agentStatus: pageOptions.agentStatus,
    onSiteStatus: pageOptions.onSiteStatus,
    clientName: pageOptions.clientName,
    profileId: pageOptions.profileId,
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to forget roaming client list:', error);
      });
}

export function uninstallClientsByIdList(idList, pageOptions, processAllClients) {
  const url = makeUrl(METHODS.UNINSTALL_CLIENTS_BY_ID_LIST);
  const data = {
    idList,
    processAllClients: processAllClients,
    agentStatus: pageOptions.agentStatus,
    onSiteStatus: pageOptions.onSiteStatus,
    clientName: pageOptions.clientName,
    profileId: pageOptions.profileId,
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to unindtall roaming client list:', error);
      });
}

export function cancelUninstallClient(clientId, selectedClientIndex, roamingLabel) {
  const url = makeUrl(METHODS.CANCEL_UNINSTALL_CLIENT);
  const data = {id: clientId};
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(getClientById(clientId, roamingLabel))
            .then((client) => {
              dispatch({
                type: types.SET_SELECTED_CLIENT_STATUS,
                index: selectedClientIndex,
                status: client.agentStatus,
              });
            });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to cancel roaming client uninstall:', error);
      });
}

export function exportCSV(params) {
  const url = makeUrl(METHODS.EXPORT_CLIENTS);
  return (dispatch) => postWithCsvResponse(url, params, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to export Clients to CSV:', error);
    });
}

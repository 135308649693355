import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export function getOrganizationsDataForTable(organizations) {
  return organizations.map((item) => ({
    ...item,
    sites: item.locationCount,
  }));
}

export function getOrganizationToCreate(newOrganization) {
  const newData = _cloneDeep(newOrganization);
  delete newData.id;
  return newData;
}

export function getUpdatedOrganizationIndex(updatedOrganizationId, organizations) {
  return _findIndex(organizations, (o) => _isEqual(o.id, updatedOrganizationId));
}

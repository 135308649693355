export const types = {
  SET_CLIENT_LOCAL_DOMAINS: 'SET_CLIENT_LOCAL_DOMAINS',
  RESET_CLIENT_LOCAL_FORWARDING_FORM_DATA: 'RESET_CLIENT_LOCAL_FORWARDING_FORM_DATA',
  SET_SELECTED_CLIENT_LOCAL_FORWARDING: 'SET_SELECTED_CLIENT_LOCAL_FORWARDING',
  SET_UPDATED_CLIENT_LOCAL_FORWARDING: 'SET_UPDATED_CLIENT_LOCAL_FORWARDING',
  // eslint-disable-next-line max-len
  CHANGE_EDIT_MODE_AND_CLIENT_LOCAL_FORWARDING_STATE: 'CHANGE_EDIT_MODE_AND_CLIENT_LOCAL_FORWARDING_STATE',
  SET_SELECTED_CLIENT_LOCAL_DOMAIN_INDEX: 'SET_SELECTED_CLIENT_LOCAL_DOMAIN_INDEX',
  RESET_SELECTED_CLIENT_LOCAL_FORWARDING_INDEX: 'RESET_SELECTED_CLIENT_LOCAL_FORWARDING_INDEX',
};

export const types = {
  SET_EDITABLE_NETWORK_INFO: 'SET_EDITABLE_NETWORK_INFO',
  SET_VALIDATION_NETWORK_INFO: 'SET_VALIDATION_NETWORK_INFO',
  RESET_NETWORK_EDIT_FORM_DATA: 'RESET_NETWORK_EDIT_FORM_DATA',
  UPDATE_NETWORK_INFO: 'UPDATE_NETWORK_INFO',
};

export function getEditableNetworkInfo(network) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_NETWORK_INFO,
      data: network,
    });
  };
}

export function updateNetworkInfo(updatedNetworkInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_NETWORK_INFO,
      data: updatedNetworkInfo,
      isValidNetworkInfo: isValid,
    });
  };
}

export function resetNetworkEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_NETWORK_EDIT_FORM_DATA,
    });
  };
}

export function setValidationNetworkInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_NETWORK_INFO,
      isValid,
    });
  };
}

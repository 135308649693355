import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityCreatingCard} from '../index';

import {WhiteBlackListEditForm} from '../../containers/index';

import {ENTITY_OPENING_MODE} from '../../constants';

function WhitelistCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
    isValidWhiteBlackListInfo,
  } = props;

  return (
    <EntityCreatingCard
      title={I18n.t('whitelistPage.createListTitle')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidWhiteBlackListInfo}
      customCardClass="card_whitelist"
      isFullscreen={false}
      content={<WhiteBlackListEditForm mode={ENTITY_OPENING_MODE.CREATE} />}
    />
  );
}

WhitelistCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidWhiteBlackListInfo: PropTypes.bool.isRequired,
};

export default WhitelistCreating;

import moment from 'moment';
import _isNil from 'lodash/isNil';

import {
  formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift,
} from '../../utils/dateTime';

import {isAuditDataAvailableForDate} from './logsAvailability';

export default function getLogsTableData(logs, maxInspectionDays = null) {
  return logs.map((item) => ({
    dateTime: formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift(item.time),
    campusName: item.campusName,
    resolver: item.resolverID,
    domainName: item.domainName,
    decision: item.decision,
    reason: item.reason,
    policyName: item.policyName,
    filterType: item.filterType,
    dnsType: item.dnsType,
    apply: `${item.apply}`,
    categories: item.categories,
    latency: item.latency,
    networkIp: item.clientIP,
    subnetIp: item.clientSubnetIP,
    time: item.time,
    queryId: item.queryID,
    agentId: item.agentID,
    clientUsername: item.clientUsername,
    clientDomain: item.clientDomain,
    clientHostname: item.clientHostname,
    sourceType: item.sourceType,
    policyType: item.policyType,
    areResponsesAvailable: isAuditDataAvailableForDate(
      moment(item.originEventDate),
      maxInspectionDays,
    ),
    dnsClass: 'IN',
    originTime: item.originTime,
    originEventDate: item.originEventDate,
    organizationName: (!_isNil(item.organizationName)) ? item.organizationName : '-',
  }));
}

export const types = {
  CHANGE_SITES_EDIT_MODE: 'CHANGE_SITES_EDIT_MODE',
  REMOVE_SITE_FROM_ORGANIZATION: 'REMOVE_SITE_FROM_ORGANIZATION',
  SET_ORGANIZATION_SITES: 'SET_ORGANIZATION_SITES',
  SET_ORGANIZATION_SITE_STATS: 'SET_ORGANIZATION_SITE_STATS',
  SET_ORGANIZATION_SITE_NETWORKS_STATS: 'SET_ORGANIZATION_SITE_NETWORKS_STATS',
  UPDATE_ORGANIZATION_SITES: 'UPDATE_ORGANIZATION_SITES',
};

export const widgetKeys = {
  ORGANIZATION_SITES_STATS: 'organizations_siteStats',
  ORGANIZATION_NETWORK_STATS: 'organizations_networkStats',
};

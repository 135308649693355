export const types = {
  SET_EDITABLE_CAMPUS_INFO: 'SET_EDITABLE_CAMPUS_INFO',
  SET_VALIDATION_CAMPUS_INFO: 'SET_VALIDATION_CAMPUS_INFO',
  RESET_CAMPUS_EDIT_FORM_DATA: 'RESET_CAMPUS_EDIT_FORM_DATA',
  UPDATE_CAMPUS_INFO: 'UPDATE_CAMPUS_INFO',
};

export function getEditableCampusInfo(campus) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_CAMPUS_INFO,
      data: campus,
    });
  };
}

export function updateCampusInfo(updatedCampusInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CAMPUS_INFO,
      data: updatedCampusInfo,
      isValidCampusInfo: isValid,
    });
  };
}

export function resetCampusEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CAMPUS_EDIT_FORM_DATA,
    });
  };
}

export function setValidationResultCampusInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_CAMPUS_INFO,
      isValid,
    });
  };
}

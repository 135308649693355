import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {RenderOrEmpty} from '../index';

import style from './style';

class Dropdown extends Component {
  getOptionLabel = (option) => {
    if (typeof option === 'object') {
      return option.label;
    }
    return option;
  };

  getOptionsAsMenuItems = () => {
    const {
      classes,
      options,
    } = this.props;
    return options.map((item, index) => (
      <MenuItem
        classes={{
          root: classes.dropdown__option,
          selected: classes.dropdown__option_selected,
        }}
        key={uuid()}
        value={`${index}`}
      >
        {this.getOptionLabel(item)}
      </MenuItem>
    ));
  };

  getSelectedValueAsString = (selectedValue) => {
    const {options, placeholder} = this.props;
    if (_isNil(selectedValue) || _isNil(options[selectedValue])) {
      return placeholder;
    }
    return this.getOptionLabel(options[selectedValue]);
  };

  handleClickOnMenuItem = (e) => {
    this.props.onChangeValue({
      ...e,
      target: {
        ...e.target,
        value: parseInt(e.target.value, 10),
      },
    });
  };

  render() {
    const {
      classes,
      dropdownName,
      disabled,
      fullWidth,
      label,
      selectedItemIndex,
      customLabelClass,
      customSelectClass,
      customIconClass,
      customFormControlClass,
    } = this.props;
    const formControlProps = {
      fullWidth: true,
    };
    const inputProps = {
      disabled: disabled,
      type: 'text',
      value: this.getSelectedValueAsString(selectedItemIndex),
    };
    const formControlClassNames = classNames(
      classes.dropdown__formControl,
      {
        [classes[customFormControlClass]]: !_isNil(customFormControlClass),
      },
    );

    return (
      <FormControl
        fullWidth={fullWidth}
        className={formControlClassNames}
        disabled={disabled}
      >
        <RenderOrEmpty isShow={disabled}>
          <CustomInput
            formControlProps={formControlProps}
            labelText={label}
            id={uuid()}
            inputProps={inputProps}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!disabled}>
          <RenderOrEmpty isShow={label !== undefined}>
            <InputLabel
              className={classNames(
                classes.dropdown__label,
                {
                  [classes[customLabelClass]]: !_isNil(customLabelClass),
                },
              )}
              htmlFor="dropdown-component"
            >
              {label}
            </InputLabel>
          </RenderOrEmpty>
          <Select
            MenuProps={{
              className: classes.dropdown__menu,
            }}
            classes={{
              select: classNames(
                classes.dropdown__select,
                {
                  [classes[customSelectClass]]: !_isNil(customSelectClass),
                },
              ),
              icon: classNames({
                [classes[customIconClass]]: !_isNil(customIconClass),
              }),
            }}
            onChange={this.handleClickOnMenuItem}
            value={`${selectedItemIndex}`}
            renderValue={(selected) => (
              <p className={classes.dropdownSelect__renderValue}>
                {this.getSelectedValueAsString(selected)}
              </p>
            )}
            inputProps={{
              name: dropdownName,
              id: 'dropdown-component',
            }}
          >
            {this.getOptionsAsMenuItems()}
          </Select>
        </RenderOrEmpty>
      </FormControl>
    );
  }
}

Dropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  dropdownName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  selectedItemIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChangeValue: PropTypes.func.isRequired,
  customLabelClass: PropTypes.string,
  customSelectClass: PropTypes.string,
  customIconClass: PropTypes.string,
  customFormControlClass: PropTypes.string,
  placeholder: PropTypes.string,
};

Dropdown.defaultProps = {
  dropdownName: 'dropdown',
  disabled: false,
  fullWidth: false,
  label: undefined,
  options: [],
  selectedItemIndex: 0,
  customLabelClass: undefined,
  customSelectClass: undefined,
  customIconClass: undefined,
  customFormControlClass: undefined,
  placeholder: '',
};

export default withStyles(style)(Dropdown);

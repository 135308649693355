import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  getLocalForwardingToCreate,
  getLocalForwardingToUpdate,
  getLocalForwarding,
} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';

export const types = {
  CHANGE_EDIT_MODE_AND_LOCAL_FORWARDING_STATE: 'CHANGE_EDIT_MODE_AND_LOCAL_FORWARDING_STATE',
  SET_CAMPUS_LOCAL_FORWARDINGS: 'SET_CAMPUS_LOCAL_FORWARDINGS',
  SET_LOCAL_FORWARDINGS: 'SET_LOCAL_FORWARDINGS',
  SET_LOCAL_FORWARDINGS_TABLE_DATA: 'SET_LOCAL_FORWARDINGS_TABLE_DATA',
  SET_SELECTED_LOCAL_FORWARDING: 'SET_SELECTED_LOCAL_FORWARDING',
  SET_SELECTED_LOCAL_FORWARDING_INDEX: 'SET_SELECTED_LOCAL_FORWARDING_INDEX',
  SET_UPDATED_LOCAL_FORWARDING: 'SET_UPDATED_LOCAL_FORWARDING',
  RESET_SELECTED_LOCAL_FORWARDING_INDEX: 'RESET_SELECTED_LOCAL_FORWARDING_INDEX',
};

export function getLocalForwardingById(localForwardingId) {
  const url = makeUrl(METHODS.GET_LOCAL_FORWARDING_BY_ID, {id: localForwardingId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = getLocalForwarding(response.data);
          dispatch({
            type: types.SET_SELECTED_LOCAL_FORWARDING,
            data,
          });
        }
      });
}

export function createLocalForwarding(newLocalForwarding, campusId) {
  const url = makeUrl(METHODS.CREATE_LOCAL_FORWARDING);
  const data = getLocalForwardingToCreate(newLocalForwarding, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndLocalForwardingState(false, STATES_ENTITY.NONE));
          dispatch(updateLocalForwardingsTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create local forwarding:', error);
      });
}

export function updateLocalForwarding(updatedLocalForwarding, campusId) {
  const url = makeUrl(METHODS.UPDATE_LOCAL_FORWARDING);
  const data = getLocalForwardingToUpdate(updatedLocalForwarding, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndLocalForwardingState(false, STATES_ENTITY.NONE));
          dispatch(updateLocalForwardingsTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit local forwarding:', error);
      });
}

export function deleteLocalForwarding(localForwardingId, campusId) {
  const data = {
    id: localForwardingId,
  };

  const url = makeUrl(METHODS.DELETE_LOCAL_FORWARDING);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(updateLocalForwardingsTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete local forwarding:', error);
      });
}

export function getDataToShowLocalForwardingDetails(localForwardingId, localForwardingIndex) {
  return (dispatch) => {
    dispatch(getLocalForwardingById(localForwardingId));
    dispatch(setSelectedLocalForwardingIndex(localForwardingIndex));
  };
}

export function getLocalForwardings(campusId) {
  const url = makeUrl(METHODS.GET_LOCAL_FORWARDINGS, {locationId: campusId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_LOCAL_FORWARDINGS,
            data,
          });
          dispatch({
            type: types.SET_CAMPUS_LOCAL_FORWARDINGS,
          });
        }
      });
}

export function setLocalForwardingsTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_CAMPUS_LOCAL_FORWARDINGS,
    });
  };
}

export function changeEditModeAndLocalForwardingState(isEditMode, localForwardingState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_LOCAL_FORWARDING_STATE,
      isEditMode,
      localForwardingState: localForwardingState,
    });
  };
}

export function setSelectedLocalForwardingIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_LOCAL_FORWARDING_INDEX,
      selectedLocalForwardingIndex: index,
    });
  };
}

export function resetSelectedLocalForwardingIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_LOCAL_FORWARDING_INDEX,
    });
  };
}

export function updateLocalForwardingsTabData(campusId) {
  return (dispatch) => {
    dispatch(getLocalForwardings(campusId));
  };
}

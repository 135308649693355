import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';
import {ContainerWithListAndForm} from '../../components';
import NotificationProfilesTable
  from '../../components/NotificationProfilesTable/NotificationProfilesTable';
import {ROUTES} from '../../constants';
import EditNotificationProfileForm
  from '../../containers/EditNotificationProfileForm/EditNotificationProfileForm';
import NewNotificationProfileForm
  from '../../containers/NewNotificationProfileForm/NewNotificationProfileForm';
import {hasAuthorities} from '../../utils/account';
import {
  getNotificationProfiles,
  getNotificationTypes,
  resetState,
  setEditingMode,
  updateNotificationProfile,
} from './action';

import style from './style';

class NotificationsManagement extends React.Component {
  static initialState = {
    selectedNotificationProfile: null,
    selectedNotificationProfileIndex: -1,
    isNewNotificationProfile: false,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = {...NotificationsManagement.initialState};
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (NotificationsManagement.wasDataScopeSwitched(prevProps, this.props)) {
      if (hasAuthorities('notificationsManagement')) {
        this.fetchData();
        this.resetState();
      } else {
        this.props.history.push(ROUTES.DEFAULT);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  get notificationProfileForm() {
    if (this.state.selectedNotificationProfile) {
      return (
        <EditNotificationProfileForm
          notificationTypes={this.props.notificationTypes}
          notificationProfile={this.state.selectedNotificationProfile}
          notificationProfiles={this.props.notificationProfiles}
          updatingCallback={this.updatingCallback}
          resetSelectedNotificationProfile={this.resetState}
          updateNotificationProfile={this.updateNotificationProfile}
        />
      );
    }
    if (this.state.isNewNotificationProfile) {
      return (
        <NewNotificationProfileForm
          notificationTypes={this.props.notificationTypes}
          resetAddingOfNotificationProfile={this.resetState}
          updatingCallback={this.updatingCallback}
        />
      );
    }
    return null;
  }

  get notificationProfilesForTable() {
    return this.props.notificationProfiles
      .map((profile) => ({
        ...profile,
        notifications: profile.notifications
          .map((notification) => I18n.t(`notificationsManagementPage.subtypes.${notification}`))
          .sort()
          .join(', '),
      }));
  }

  get isTableCompressed() {
    return (
      !!this.state.selectedNotificationProfile
      || this.state.isNewNotificationProfile
    );
  }

  selectedNotificationProfile = (e) => this.setState({
    selectedNotificationProfile: _get(this.props.notificationProfiles, e, null),
    selectedNotificationProfileIndex: e,
  });

  resetState = () => {
    this.props.setEditingMode(false);
    this.setState({...NotificationsManagement.initialState});
  };

  updateNotificationProfile = (profile) => this.props.updateNotificationProfile(profile)
    .then(() => this.updatingCallback());

  updatingCallback = () => {
    this.props.setEditingMode(false);
    this.setState({...NotificationsManagement.initialState});
    this.props.getNotificationProfiles();
  };

  openAddingForm = () => {
    this.props.setEditingMode(true);
    this.setState({
      ...NotificationsManagement.initialState,
      isNewNotificationProfile: true,
    });
  };

  fetchData = () => Promise.all([
    this.props.getNotificationTypes(),
    this.props.getNotificationProfiles(),
  ]);

  render() {
    const containerClasses = {
      container: this.props.classes.container,
    };

    return (
      <ContainerWithListAndForm
        list={(
          <NotificationProfilesTable
            notificationProfiles={this.notificationProfilesForTable}
            handleClickRow={this.selectedNotificationProfile}
            isCompressed={this.isTableCompressed}
            isEditing={this.props.isEditingMode}
            selectedNotificationProfileIndex={this.state.selectedNotificationProfileIndex}
            handleClickNew={this.openAddingForm}
          />
        )}
        form={this.notificationProfileForm}
        classes={containerClasses}
      />
    );
  }
}

NotificationsManagement.propTypes = {
  classes: PropTypes.object,

  getNotificationTypes: PropTypes.func.isRequired,

  getNotificationProfiles: PropTypes.func.isRequired,
  updateNotificationProfile: PropTypes.func.isRequired,

  setEditingMode: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,

  resetState: PropTypes.func.isRequired,

  notificationTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    subtypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired).isRequired,

  notificationProfiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired).isRequired,

  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentAccount: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  history: PropTypes.object.isRequired,
};

NotificationsManagement.defaultProps = {
  classes: {},
  currentAccount: null,
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  notificationTypes: state.notificationsManagementReducer.notificationTypes,
  notificationProfiles: state.notificationsManagementReducer.notificationProfiles,
  isEditingMode: state.notificationsManagementReducer.isEditingMode,
  loggedAccount: state.userAccountsReducer.loggedAccount,
});

const mapDispatchToProps = {
  getNotificationTypes,
  getNotificationProfiles,
  updateNotificationProfile,
  setEditingMode,
  resetState,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(NotificationsManagement)));

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';

import {
  CustomButton,
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {SectionLinkInUnauthForm} from '../index';

import {
  ROUTES,
} from '../../constants';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      submitDisabled: true,
    };
  }

  onChange = (e) => {
    const newState = {
      ...this.state,
      [e.target.name]: e.target.value,
    };
    const isValid = this.validate(newState);
    this.setState({
      ...newState,
      submitDisabled: !isValid,
    });
  };

  onSubmit = () => {
    const {email} = this.state;
    this.props.resetPassword({
      email: email.toLowerCase(),
    });
    this.setState({submitDisabled: true});
  };

  validate = (data) => {
    const isNotEmpty = (str) => !!str;
    const validatingRules = [
      {
        name: 'email',
        validatingRules: [isNotEmpty],
      },
    ];

    return validatingRules.reduce(
      (result, rule) => {
        const value = data[rule.name];
        return result && rule.validatingRules.reduce(
          (_result, func) => _result && func(value),
          true,
        );
      },
      true,
    );
  };

  render() {
    const {
      email,
      submitDisabled,
    } = this.state;

    const propsInput = {
      email: {
        value: email,
        name: 'email',
        onChange: this.onChange,
        type: 'text',
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <>
        <CustomInput
          labelText={I18n.t('resetPasswordPage.email')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.email}
        />
        <CustomButton
          fullWidth={true}
          disabled={submitDisabled}
          color="secondary"
          customClasses="unauthorizedForm__button"
          onClick={this.onSubmit}
        >
          {I18n.t('resetPasswordPage.reset')}
        </CustomButton>
        <SectionLinkInUnauthForm
          text={I18n.t('resetPasswordPage.backToLogin')}
          to={ROUTES.LOGIN}
        />
      </>
    );
  }
}

ResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

ResetPasswordForm.defaultProps = {};

export default ResetPasswordForm;

import React, {useState} from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';

import {CustomInput} from '../../material-dashboard-pro-react/components';

import style from './style';

/**
 *
 * @param {baseSettings} baseSettings - object with settings
 * @param {handleSettingsChange} handleSettingsChange
 *  - callback on settings change. Passes settings object with new fields as a param
 */
function UsersSettingsTab({classes,
  baseSettings,
  isEditMode,
  handleSettingsChange}) {
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  const formControlProps = {
    fullWidth: true,
  };

  const handleNameChange = (e) => {
    setIsNameEmpty(!e.target.value);
    handleSettingsChange({
      ...baseSettings,
      name: e.target.value,
    });
  };

  const handleDescriptionChange = (e) => {
    handleSettingsChange({
      ...baseSettings,
      description: e.target.value,
    });
  };

  return (
    <div className={classes.settings}>
      <div className={classes.inputsRow}>
        <CustomInput
          formControlProps={formControlProps}
          labelText={I18n.t('users.usersConfigurer.persona.settings.personaName')}
          id="persona-name"
          inputProps={{
            name: 'personaName',
            onChange: handleNameChange,
            type: 'text',
            value: baseSettings?.name ?? '',
            disabled: !isEditMode,
          }}
          error={isNameEmpty}
          errorText={isNameEmpty
            ? I18n.t('users.usersConfigurer.errorMessages.personaNameEmpty')
            : ''}
        />
        <CustomInput
          formControlProps={formControlProps}
          labelText={I18n.t('users.usersConfigurer.persona.settings.personaDescription')}
          id="persona-description"
          inputProps={{
            name: 'personaDescription',
            onChange: handleDescriptionChange,
            type: 'text',
            value: baseSettings?.description ?? '',
            disabled: !isEditMode,
          }}
        />
      </div>
    </div>
  );
}

UsersSettingsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  baseSettings: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleSettingsChange: PropTypes.func.isRequired,
};

export default withStyles(style)(UsersSettingsTab);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {NavPills} from '../../material-dashboard-pro-react/components';

import {DomainInsights, TldInsights, CategoryInsights, RecordTypeInsights} from '../../containers';

import {TABS, widgetKeys} from './constants';
import {resetState, enableFilters} from './action';
import {cancelRequests} from '../../containers/Loading/action';
import style from './style';

class Insights extends React.PureComponent {
  componentWillUnmount() {
    this.props.resetState();
    this.props.cancelRequests([], true);
  }

  interruptRequests = (includeDefaultKey = false) => {
    this.props.cancelRequests(Object.values(widgetKeys), includeDefaultKey);
  };

  onTabChange = () => {
    this.interruptRequests();
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.insights}>
        <NavPills
          color="secondary"
          onChange={this.onTabChange}
          className="root_fullScreen"
          wrapperClassName="tab__contentWrapper_fullScreen"
          contentClassName="tab__content_fullScreen"
          isSwipeable={false}
          tabs={[
            {
              tabButton: I18n.t(`insights.${TABS.DOMAIN}.name`),
              tabContent: <DomainInsights />,
            },
            {
              tabButton: I18n.t(`insights.${TABS.CATEGORY}.name`),
              tabContent: <CategoryInsights />,
            },
            {
              tabButton: I18n.t(`insights.${TABS.TLD}.name`),
              tabContent: <TldInsights />,
            },
            {
              tabButton: I18n.t(`insights.${TABS.RECORD_TYPE}.name`),
              tabContent: <RecordTypeInsights />,
            },
          ]}
        />
      </div>
    );
  }
}

Insights.propTypes = {
  classes: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  enableFilters: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

Insights.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  resetState,
  enableFilters,
  cancelRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Insights));

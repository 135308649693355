import {secondaryColor} from '../../styles/style';

const style = {
  clientBundleEdit: {},
  clientBundleEdit__link: {
    marginTop: '10px',
  },
  clientBundleEdit__link_text: {
    'color': `${secondaryColor}!important`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  clientBundleEdit__packageInstallerTitle: {
    fontSize: '17px',
  },

  clientBundleEdit__scriptInstaller: {
    marginTop: '20px',
  },

  clientBundleEdit__scriptInstallerTitle: {
    fontSize: '17px',
  },
};

export default style;

const style = {
  tld__controls: {
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'baseline',
    'justifyContent': 'space-between',
    'margin': '0 14px 10px 14px',
    '@media (max-width: 1450px)': {
      flexDirection: 'column',
    },
  },
  tldControls__searchbar: {
    '@media (max-width: 1450px)': {
      width: '100%',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
    },
  },
  tld__section: {
    margin: '14px',
  },
  filterSection__searchField: {
    'marginRight': '14px',
    '@media (max-width: 1450px)': {
      width: '60%',
    },
  },
  tld__listSection: {
    'display': 'flex',
    'flexDirection': 'row',
    'marginTop': '5px',
    '@media (max-width: 1250px)': {
      flexDirection: 'column',
    },
  },
  listSection__buttonSection: {
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    '@media (max-width: 1250px)': {
      transform: 'rotate(90deg)',
    },
  },
};

export default style;

import _isNaN from 'lodash/isNaN';

import _sortBy from 'lodash/sortBy';
import {
  get,
  makeUrl,
  makeUrlToGetMock,
} from '../../../utils/fetcher';

import {
  ALLOWED,
  FORBIDDEN,
  METHODS,
} from '../../../constants';
import {widgetKeys, types} from './constants';

export function getDashboardData(refreshData) {
  const period = refreshData.period;
  const campusId = refreshData.campusId;
  const queryParamsPeriodCampus = {
    campusId: campusId,
    period: period,
  };
  const queryParamsPeriodLocation = {
    locationId: campusId,
    period: period,
  };
  const queryParamsCampusStats = {
    campusId: campusId,
    period: null,
  };
  const queryParamsDecisionIsAllowed = {
    period: period,
    campusId: campusId,
    decision: ALLOWED,
  };
  const queryParamsDecisionIsForbidden = {
    period: period,
    campusId: campusId,
    decision: FORBIDDEN,
  };
  const periodParams = {period};

  return (dispatch) => {
    dispatch(getAverageLatency(queryParamsPeriodCampus, widgetKeys.PERFORMANCE_STATS));
    dispatch(getCampusesStats(queryParamsCampusStats, widgetKeys.CAMPUSES_STATS));
    dispatch(getTop5Categories(
      queryParamsDecisionIsForbidden,
      FORBIDDEN,
      widgetKeys.TOP_CATEGORIES_FORBIDDEN,
    ));
    dispatch(getTop5Categories(
      queryParamsDecisionIsAllowed,
      ALLOWED,
      widgetKeys.TOP_CATEGORIES_ALLOWED,
    ));
    dispatch(getTop5Domains(
      queryParamsDecisionIsForbidden,
      FORBIDDEN,
      widgetKeys.TOP_DOMAINS_FORBIDDEN,
    ));
    dispatch(getTop5Domains(
      queryParamsDecisionIsAllowed,
      ALLOWED,
      widgetKeys.TOP_DOMAINS_ALLOWED,
    ));
    dispatch(getTotalRequestsByDecision(
      queryParamsDecisionIsForbidden,
      FORBIDDEN,
      widgetKeys.REQUEST_STATS,
    ));
    dispatch(getTotalRequestsByDecision(
      queryParamsDecisionIsAllowed,
      ALLOWED,
      widgetKeys.REQUEST_STATS,
    ));
    dispatch(getUserRequestsGraph(queryParamsPeriodCampus, widgetKeys.REQUEST_STATS));
    dispatch(getClientCountStats(periodParams, widgetKeys.CLIENT_STATS));
    dispatch(getClientThreats(queryParamsPeriodLocation, widgetKeys.THREAT_STATS));

    // Uncomment if corresponding widgets will be enabled:
    // dispatch(getCategoryGroupsStats(queryParamsPeriodCampus, widgetKeys.BLOCKED_STATS));
    // dispatch(getRequestsPerNetwork(queryParamsPeriodCampus, widgetKeys.REQUESTS_PER_NETWORK));
  };
}

export function getAverageLatency(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_AVERAGE_LATENCY, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_AVERAGE_LATENCY,
            data,
          });
        } else {
          dispatch({
            type: types.SET_AVERAGE_LATENCY,
            data: 0.0,
          });
        }
      });
  };
}

export function getCategoryGroupsStats(queryParams, widgetKey) {
  const url = makeUrlToGetMock(METHODS.GET_CATEGORY_GROUPS_STATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_CATEGORY_GROUPS_STATS,
            data,
          });
        } else {
          dispatch({
            type: types.SET_CATEGORY_GROUPS_STATS,
            data: {},
          });
        }
      });
  };
}

export function getCampusesStats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CAMPUSES_STATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          Object.keys(data).forEach((key) => {
            const item = data[key];
            let requestsPerSecond = item.requestsPerSecond || 0.0;
            if (_isNaN(requestsPerSecond) || requestsPerSecond === 'Infinity') {
              requestsPerSecond = 0.0;
            }
            item.requestsPerSecond = requestsPerSecond;
          });
          dispatch({
            type: types.SET_CAMPUSES_STATS,
            data,
            campusId: queryParams.campusId,
          });
        } else {
          dispatch({
            type: types.SET_CAMPUSES_STATS,
            data: {},
            campusId: queryParams.campusId,
          });
        }
      });
  };
}

// Uncomment if the component will be restored on the Dashboard.
// function getRequestsPerNetwork(queryParams, widgetKey) {
//   const url = makeUrl(METHODS.GET_REQUESTS_PER_NETWORKS, queryParams);
//   return (dispatch) => {
//     get(url, dispatch, true, widgetKey)
//       .then((response) => {
//         if (response && response.data) {
//           const data = response.data;
//           dispatch({
//             type: types.SET_REQUESTS_PER_NETWORK,
//             data,
//           });
//         } else {
//           dispatch({
//             type: types.SET_REQUESTS_PER_NETWORK,
//             data: {},
//           });
//         }
//       });
//   };
// }

function getTop5Categories(queryParams, decision, widgetKey) {
  const url = makeUrl(METHODS.GET_TOP_5_CATEGORIES, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_TOP_5_CATEGORIES,
            data,
            decision,
          });
        } else {
          dispatch({
            type: types.SET_TOP_5_CATEGORIES,
            data: {},
            decision,
          });
        }
      });
  };
}

function getTop5Domains(queryParams, decision, widgetKey) {
  const url = makeUrl(METHODS.GET_TOP_5_DOMAINS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_TOP_5_DOMAINS,
            data,
            decision,
          });
        } else {
          dispatch({
            type: types.SET_TOP_5_DOMAINS,
            data: {},
            decision,
          });
        }
      });
  };
}

export function getTotalRequestsByDecision(queryParams, decision, widgetKey) {
  const url = makeUrl(METHODS.GET_TOTAL_REQUESTS_BY_DECISION, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_TOTAL_REQUEST_BY_DECISION,
            data,
            decision,
          });
        } else {
          dispatch({
            type: types.SET_TOTAL_REQUEST_BY_DECISION,
            data: 0,
            decision,
          });
        }
      });
  };
}

export function getUserRequestsGraph(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_USER_REQUESTS_GRAPH, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_USER_REQUESTS_GRAPH,
            period: queryParams.period,
            data,
          });
        } else {
          dispatch({
            type: types.SET_USER_REQUESTS_GRAPH,
            period: queryParams.period,
            data: {},
          });
        }
      });
  };
}

export function getClientThreats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CLIENT_THREATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        const data = _sortBy(response.data, (item) => item.threat);
        if (response && response.data) {
          dispatch({
            type: types.SET_DASHBOARD_CLIENT_THREATS,
            data,
          });
        } else {
          dispatch({
            type: types.SET_DASHBOARD_CLIENT_THREATS,
            data: [],
          });
        }
      });
  };
}

export function getClientCountStats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CLIENT_COUNT_STATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_DASHBOARD_CLIENT_COUNT_STATS,
            stats: response.data,
          });
        }
      });
  };
}

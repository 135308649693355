import React, {useState} from 'react';
import {I18n} from 'react-redux-i18n';

import TabsPanel from '../TabsPanel/TabsPanel';
import {RequestStatsWidget, RequestsByCategoryWidget} from '../../containers';
import {widgetKeys} from '../../app-common/Users/constants';
import {GridContainer, ItemGrid} from '../../material-dashboard-pro-react/components';

type UserDetailsGraphsProps = {
  filters: {[key: string]: string | number};
  readyToLoadStats: boolean;
}

/**
 * Graphs for user details requests with tab navigation
 * @param {filters} filters - filters for loading data. Can include ids, periods, etc
 * @param {readyToLoadStats} readyToLoadStats - boolean, load data when it's true
 */
function UserDetailsGraphs({filters, readyToLoadStats}: UserDetailsGraphsProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const graphsProps = {
    filters,
    readyToLoadStats,
  };

  const tabs = [
    {
      text: I18n.t('users.usersManager.editUserForm.dashboard.tabs.allowBlock'),
      name: 'info',
      content: <RequestStatsWidget
        {...graphsProps}
        widgetKey={widgetKeys.REQUEST_STATS}
      />,
    },
    {
      text: I18n.t('users.usersManager.editUserForm.dashboard.tabs.categories'),
      name: 'info',
      content: <RequestsByCategoryWidget
        {...graphsProps}
        widgetKey={widgetKeys.REQUEST_STATS_BY_CATEGORY}
      />,
    },
  ];

  return (
    <GridContainer direction="column">
      <ItemGrid>
        <TabsPanel
          activeTabIndex={activeTabIndex}
          disabled={false}
          handleClickTab={(i) => setActiveTabIndex(i)}
          tabs={tabs}
        />
      </ItemGrid>
      <ItemGrid>
        {tabs[activeTabIndex]?.content ?? null}
      </ItemGrid>
    </GridContainer>
  );
}

export default UserDetailsGraphs;

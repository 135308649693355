import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import style from './style';

function ModalWindow(props) {
  const {classes} = props;

  const dialogClasses = {
    paper: classes.modalWindow__paper,
    root: classes.modalWindow,
  };
  const dialogTitleClasses = {
    root: classes.modalWindow__titleRoot,
  };
  const dialogActionsClasses = {
    root: classes.modalWindow__actions,
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.cancel}
      classes={dialogClasses}
      {...props.dialogProps}
    >
      <DialogTitle classes={dialogTitleClasses}>
        <p className={classes.modalWindow__title}>
          {props.title}
        </p>
      </DialogTitle>
      {props.children}
      <DialogActions classes={dialogActionsClasses}>
        <CustomButton
          customClasses="modalWindow__button"
          color="red"
          onClick={props.confirm}
        >
          {I18n.t('modalWindow.buttonConfirm')}
        </CustomButton>
        <CustomButton
          customClasses="modalWindow__button"
          color="red"
          onClick={props.cancel}
        >
          {I18n.t('modalWindow.buttonCancel')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

ModalWindow.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  dialogProps: PropTypes.object,
  isOpen: PropTypes.bool,
};

ModalWindow.defaultProps = {
  isOpen: true,
  children: null,
  title: null,
  dialogProps: {},
};

export default withStyles(style)(ModalWindow);

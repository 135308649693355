import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import {LogTable} from '../../components';
import {
  INSIGHTS_LOGS_TABLE_HEADERS_LOCAL_STORAGE_KEY, LIMIT_LOAD_LOGS,
  MIN_LOG_TABLE_ROWS,
  TABS,
} from '../../app-common/Insights/constants';

import {getLogs, loadMoreLogs, resetLogs} from './action';

class InsightsLogs extends React.PureComponent {
  componentDidMount() {
    this.getLogs();
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.filterData, this.props.filterData)) {
      this.getLogs();
    }
  }

  componentWillUnmount() {
    this.props.resetLogs();
  }

  getLogs = () => {
    const {
      total,
      tab,
      filterData,
    } = this.props;
    this.props.getLogs(tab, {
      ...filterData,
      offset: 0,
      rows: total < LIMIT_LOAD_LOGS ? total : LIMIT_LOAD_LOGS,
    });
  };

  loadMoreLogs = () => {
    const {logs, filterData, tab, total} = this.props;
    if (_isEmpty(logs)) return;
    if (logs.length < total) {
      this.props.loadMoreLogs(tab, {
        ...filterData,
        offset: LIMIT_LOAD_LOGS,
        rows: logs.length,
      });
    }
  };

  render() {
    const {logs, accountInfo} = this.props;
    const logTableStyles = {
      height: 'calc(100vh - 285px)',
      minHeight: '300px',
    };
    const tableClassNames = {
      tbody: [{
        className: 'tbodyInfinite_insights',
        condition: true,
      }],
    };
    return (
      <LogTable
        accountInfo={accountInfo}
        customHeadersLocalStorageKey={INSIGHTS_LOGS_TABLE_HEADERS_LOCAL_STORAGE_KEY}
        tableClassNames={tableClassNames}
        hasHeadersPicker={true}
        minRows={MIN_LOG_TABLE_ROWS}
        tableData={logs}
        tableStyles={logTableStyles}
        loadData={this.loadMoreLogs}
        showResponse={this.props.showResponse}
      />
    );
  }
}

InsightsLogs.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  tab: PropTypes.oneOf(Object.values(TABS)).isRequired,
  logs: PropTypes.array.isRequired,
  filterData: PropTypes.object,
  getLogs: PropTypes.func.isRequired,
  loadMoreLogs: PropTypes.func.isRequired,
  resetLogs: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  showResponse: PropTypes.func.isRequired,
};

InsightsLogs.defaultProps = {
  filterData: {},
};

const mapDispatchToProps = {
  getLogs,
  loadMoreLogs,
  resetLogs,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  logs: state.insightsLogsReducer.logs,
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsLogs);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {TableWithPaginationAndFiltration} from '../../components';
import {WbListActionsContainer, WbListsContainer} from '../index';
import {TABS} from '../../app-common/Insights/constants';

import './style.css';

class FQDNInsightsTable extends Component {
  get fqdns() {
    const {data, tableStyle, selectedRows} = this.props;
    const tableSortingProps = [{
      id: 'count',
      desc: true,
    }];
    const headers = this.getHeaders();
    return (
      <TableWithPaginationAndFiltration
        columns={headers}
        data={data}
        pageSize={data.length}
        showPagination={false}
        defaultSorted={tableSortingProps}
        style={tableStyle}
        selectedRowIndices={selectedRows}
        filterable={false}
      />
    );
  }

  filterRenderer = ({filter, onChange}) => (
    <input
      type="text"
      maxLength="255"
      onChange={(e) => onChange(e.target.value)}
      style={{width: '100%'}}
      value={filter && filter.value ? filter.value : ''}
      placeholder={I18n.t(`insights.${TABS.DOMAIN}.table.search`)}
    />
  );

  actionsCellRenderer = (row) => {
    const fqdn = {
      ..._get(row, 'original', {}),
      index: row.index,
    };

    const fqdnName = _get(fqdn, 'domain', '');

    return (
      <WbListActionsContainer
        listProps={{fqdnName}}
        withSubDomains={false}
        additionalActions={[
          {
            label: I18n.t('insights.rowAction.showLogs'),
            id: 'show-logs',
            onClick: () => this.props.showLogs(fqdn),
          },
        ]}
      />
    );
  };

  getHeaders = () => [
    {
      accessor: 'actions',
      filterable: false,
      sortable: false,
      width: 70,
      Cell: this.actionsCellRenderer,
    },
    {
      Header: I18n.t(`insights.${TABS.DOMAIN}.table.domain`),
      accessor: 'domain',
      Filter: this.filterRenderer,
    },
    {
      Header: I18n.t(`insights.${TABS.DOMAIN}.table.parentDomain`),
      accessor: 'parentDomain',
      Filter: this.filterRenderer,
    },
    {
      Header: I18n.t(`insights.${TABS.DOMAIN}.table.fqdnCategory`),
      accessor: 'category',
      filterable: false,
      sortable: false,
      Cell: (row) => {
        const value = _get(row, 'original.categories', []);
        return value.join(', ');
      },
    },
    {
      Header: I18n.t(`insights.${TABS.DOMAIN}.table.fqdnCategoryType`),
      accessor: 'type',
      filterable: false,
      sortable: false,
      Cell: (row) => {
        const value = _get(row, 'original.type', []);
        return value.join(', ');
      },
    },
    {
      Header: I18n.t(`insights.${TABS.DOMAIN}.table.count`),
      accessor: 'count',
      filterable: false,
    },
  ];

  render() {
    return (
      <>
        {this.fqdns}
        <WbListsContainer />
      </>
    );
  }
}

FQDNInsightsTable.propTypes = {
  showLogs: PropTypes.func.isRequired,

  data: PropTypes.array.isRequired,
  tableStyle: PropTypes.object,
  selectedRows: PropTypes.array,
};

FQDNInsightsTable.defaultProps = {
  tableStyle: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 230px)',
  },
  selectedRows: [],
};

export default FQDNInsightsTable;

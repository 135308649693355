import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {TAGS} from '../constants';

export const hasOrgTag = (tags) => {
  if (_isNil(tags)) {return false;}
  return !_isEmpty(
    tags.map((tag) => tag.startsWith(TAGS.ORGANIZATION_POLICY)),
  );
};

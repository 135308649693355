import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import _isEqual from 'lodash/isEqual';

import {ThreatStats} from '../../components';

import {getThreats} from './action';
import {cancelRequests} from '../Loading/action';

class ThreatStatsWidget extends React.Component {
  componentDidMount() {
    const {filters, widgetKey, readyToLoadStats} = this.props;
    this.props.cancelRequests([widgetKey], false);
    if (readyToLoadStats) {
      this.props.getThreats(filters, widgetKey);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.readyToLoadStats && (
      !_isEqual(prevProps.filters, this.props.filters)
        || !_isEqual(prevProps.widgetKey, this.props.widgetKey)
    )
    ) {
      this.props.cancelRequests([prevProps.widgetKey], false);
      this.props.getThreats(this.props.filters, this.props.widgetKey);
    }
  }

  render() {
    const {threatStats, widgetKey} = this.props;
    return (
      <ThreatStats
        stats={threatStats}
        widgetKey={widgetKey}
      />
    );
  }
}

ThreatStatsWidget.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.object,
  readyToLoadStats: PropTypes.bool,
  threatStats: PropTypes.array.isRequired,
  widgetKey: PropTypes.string.isRequired,
  getThreats: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

ThreatStatsWidget.defaultProps = {
  classes: {},
  filters: {},
  readyToLoadStats: true,
};

const mapStateToProps = (state) => ({
  threatStats: state.threatStatsWidgetReducer.threatStats,
});

const mapDispatchToProps = (dispatch) => ({
  getThreats: bindActionCreators(getThreats, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreatStatsWidget);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';

import ReactTable from 'react-table';

class TableWithPaginationAndFiltration extends Component {
  shouldComponentUpdate(nextProps) {
    return !_isEqual(nextProps.data, this.props.data)
    || !_isEqual(nextProps.pages, this.props.pages)
    || !_isEqual(nextProps.onFetchData, this.props.onFetchData)
    || !_isEqual(nextProps.columns, this.props.columns)
    || !_isEqual(nextProps.defaultPageSize, this.props.defaultPageSize)
    || !_isEqual(nextProps.defaultSorted, this.props.defaultSorted)
    || !_isEqual(nextProps.showPagination, this.props.showPagination)
    || !_isEqual(nextProps.showPaginationTop, this.props.showPaginationTop)
    || !_isEqual(nextProps.showPaginationBottom, this.props.showPaginationBottom)
    || !_isEqual(nextProps.filterable, this.props.filterable)
    || !_isEqual(nextProps.sortable, this.props.sortable)
    || !_isEqual(nextProps.style, this.props.style)
    || !_isEqual(nextProps.customClasses, this.props.customClasses)
    || !_isEqual(nextProps.page, this.props.page)
    || !_isEqual(nextProps.onPageChange, this.props.onPageChange)
    || !_isEqual(nextProps.isStriped, this.props.isStriped)
    || !_isEqual(nextProps.isHighlight, this.props.isHighlight)
    || !_isEqual(nextProps.selectedRowIndices, this.props.selectedRowIndices)
    || !_isEqual(nextProps.handleClickRow, this.props.handleClickRow)
    || !_isEqual(nextProps.filtered, this.props.filtered)
    || !_isEqual(nextProps.theadFilterProps, this.props.theadFilterProps)
    || !_isEqual(nextProps.showPageSizeOptions, this.props.showPageSizeOptions)
    || !_isEqual(nextProps.disableWhiteSpaceNoWrap, this.props.disableWhiteSpaceNoWrap)
    || !_isEqual(nextProps.minRows, this.props.minRows)
    || !_isEqual(nextProps.resizable, this.props.resizable);
  }

  getPageSize = () => {
    const {pageSize} = this.props;
    if (pageSize === 0) {
      return undefined;
    }
    return pageSize;
  };

  getTrProps = (state, rowInfo) => {
    const {selectedRowIndices} = this.props;
    let backgroundColor = null;
    if (rowInfo && selectedRowIndices.includes(rowInfo.index)) {
      backgroundColor = '#e5e5e5';
    }
    return {
      style: {
        backgroundColor: backgroundColor,
      },
    };
  };

  getTheadThProps = (state, rowInfo, column) => {
    if (_isNull(column.Header)) {
      return {
        style: {
          display: 'none',
        },
      };
    }
    return {};
  };

  getTdProps = (state, rowInfo) => {
    let customProps = {};
    if (!_isNil(rowInfo) && !_isNil(this.props.getTdProps)) {
      customProps = this.props.getTdProps(rowInfo.index) || {};
    }
    const customStyle = _get(customProps, 'style', {});
    const tdProps = {};
    let style = {};
    if (rowInfo && !_isUndefined(this.props.handleClickRow)) {
      tdProps.onClick = () => this.props.handleClickRow(rowInfo.index);
      style = {
        cursor: 'pointer',
      };
    }
    style = {
      ...style,
      ...(this.props.disableWhiteSpaceNoWrap && {
        whiteSpace: 'normal',
      }),
      ...customStyle,
    };
    return {
      ...tdProps,
      ...customProps,
      style,
    };
  };

  getTbodyProps = () => ({
    style: {
      display: 'block',
      overflow: 'auto',
    },
    className: this.props.customClasses.tbody,
  });

  getTheadFilterProps = this.props.theadFilterProps
    ? () => this.props.theadFilterProps
    : undefined;

  getTheadProps = this.props.theadProps
    ? () => this.props.theadProps
    : undefined;

  render() {
    const {
      filterable,
      columns,
      data,
      pages,
      page,
      onFetchData,
      filtered,
      defaultPageSize,
      defaultSorted,
      showPagination,
      showPaginationTop,
      showPaginationBottom,
      sortable,
      style,
      isStriped,
      isHighlight,
      minRows,
      showPageSizeOptions,
      onPageChange,
      resizable,
    } = this.props;
    const className = classNames({
      '-striped': isStriped,
      '-highlight': isHighlight,
    });
    return (
      <ReactTable
        data={data}
        filterable={filterable}
        sortable={sortable}
        columns={columns}
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        pageSize={this.getPageSize()}
        page={page}
        pages={pages}
        filtered={filtered}
        onFetchData={onFetchData}
        showPageSizeOptions={showPageSizeOptions}
        showPagination={showPagination}
        showPaginationTop={showPaginationTop}
        showPaginationBottom={showPaginationBottom}
        style={style}
        className={className}
        getTrProps={this.getTrProps}
        getTdProps={this.getTdProps}
        getTheadProps={this.getTheadProps}
        getTheadThProps={this.getTheadThProps}
        getTbodyProps={this.getTbodyProps}
        minRows={minRows}
        getTheadFilterProps={this.getTheadFilterProps}
        onPageChange={onPageChange}
        resizable={resizable}
      />
    );
  }
}

TableWithPaginationAndFiltration.propTypes = {
  data: PropTypes.array,
  pages: PropTypes.number,
  onFetchData: PropTypes.func,
  columns: PropTypes.array,
  customClasses: PropTypes.object,
  defaultPageSize: PropTypes.number,
  defaultSorted: PropTypes.array,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  filtered: PropTypes.array,
  showPagination: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  style: PropTypes.object,
  isStriped: PropTypes.bool,
  isHighlight: PropTypes.bool,
  showPageSizeOptions: PropTypes.bool,
  selectedRowIndices: PropTypes.array,
  handleClickRow: PropTypes.func,
  minRows: PropTypes.number,
  theadProps: PropTypes.object,
  theadFilterProps: PropTypes.object,
  getTdProps: PropTypes.func,
  onPageChange: PropTypes.func,
  disableWhiteSpaceNoWrap: PropTypes.bool,
  resizable: PropTypes.bool,
};

TableWithPaginationAndFiltration.defaultProps = {
  data: [],
  pages: undefined,
  onFetchData: () => null,
  columns: [],
  defaultPageSize: undefined,
  defaultSorted: [],
  pageSize: 0,
  page: undefined,
  filtered: undefined,
  showPagination: true,
  showPaginationBottom: true,
  showPaginationTop: false,
  filterable: true,
  sortable: true,
  style: {},
  isStriped: false,
  isHighlight: true,
  showPageSizeOptions: undefined,
  selectedRowIndices: [],
  handleClickRow: undefined,
  minRows: 3,
  theadProps: undefined,
  theadFilterProps: undefined,
  getTdProps: undefined,
  onPageChange: undefined,
  disableWhiteSpaceNoWrap: false,
  customClasses: {},
  resizable: false,
};

export default TableWithPaginationAndFiltration;

export const types = {
  CLEAR_TABLE_DATA: 'CLEAR_TABLE_DATA',
  SET_AUDIT_JSON: 'SET_AUDIT_JSON',
  UPDATE_AUDIT_JSON: 'UPDATE_AUDIT_JSON',
  SET_CLIENT_NAMES: 'SET_CLIENT_NAMES',
  CLEAR_CLIENT_NAMES: 'CLEAR_CLIENT_NAMES',
  SET_ORGANIZATION_NAMES_FOR_LOGS: 'SET_ORGANIZATION_NAMES_FOR_LOGS',
  CLEAR_ORGANIZATIONS_FOR_LOGS: 'CLEAR_ORGANIZATIONS_FOR_LOGS',
};

export const LOG_TABLE_HEADERS_LOCAL_STORAGE_KEY = 'log_table_headers';

export const LIMIT_LOAD_DATA = 50;

export const MIN_TABLE_ROWS = 10;

export const widgetKeys = {
  LOG_TABLE: 'logs_table',
  RESPONSE_VIEW: 'logs_responseView',
};

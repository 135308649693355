import React from 'react';
import PropTypes from 'prop-types';
import {ReactTableDefaults} from 'react-table';
import {Waypoint} from 'react-waypoint';
import _omit from 'lodash/omit';

import {RenderOrEmpty} from '../index';

function InfiniteTbody(props) {
  const {
    loadData,
    loadDataEnd,
    isThereTbodyData,
    infiniteTbodyClassName,
  } = props;

  const defaultTbodyProps = _omit(
    props,
    ['classes', 'loadData', 'loadDataEnd', 'isThereTbodyData', 'infiniteTbodyClassName'],
  );
  return (
    <>
      <RenderOrEmpty isShow={isThereTbodyData}>
        <div className={infiniteTbodyClassName}>
          <ReactTableDefaults.TbodyComponent
            {...defaultTbodyProps}
          />
          <Waypoint
            onEnter={loadData}
            onLeave={loadDataEnd}
            bottomOffset="-50px"
          />
        </div>
      </RenderOrEmpty>
      <RenderOrEmpty isShow={!isThereTbodyData}>
        <div className={infiniteTbodyClassName} />
      </RenderOrEmpty>
    </>

  );
}

InfiniteTbody.propTypes = {
  loadData: PropTypes.func.isRequired,
  loadDataEnd: PropTypes.func.isRequired,
  infiniteTbodyClassName: PropTypes.string.isRequired,
  isThereTbodyData: PropTypes.bool.isRequired,
};

export default InfiniteTbody;

import {
  primaryColor,
  secondaryColor,
  greenColor,
  defaultFont,
} from '../../styles/style';

const style = {
  switch: {
    alignItems: 'unset',
  },
  switch_inline: {
    height: 'unset',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
  },
  formControl_noMargin: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'start',
    margin: 0,
  },
  formControl_oneLine: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
  },
  formControl_sso: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'start',
    margin: 0,
  },
  formControl_oneLineReversedLeftAligned: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    width: 'fit-content',
  },
  switch__label: {
    ...defaultFont,
    color: '#AAAAAA',
    // fontWeight: '400',
    fontSize: '11px',
    lineHeight: '1.42857',
    transition: '0.3s ease all',
    paddingLeft: '14px',
  },
  switch__label_oneLine: {
    color: primaryColor,
    fontSize: '14px',
  },
  switch__label_policySettings: {
    ...defaultFont,
    color: '#AAAAAA',
    fontSize: '11px',
    lineHeight: '1.42857',
    transition: '0.3s ease all',
    paddingLeft: 0,
  },
  switch__label_sso: {
    paddingLeft: 0,
  },
  switch_td: {
    height: 0,
  },
  switch_checked: {
    color: greenColor,
  },
  switch_unchecked: {
    color: secondaryColor,
  },
  switchBar: {},
  switchBar_checked: {
    backgroundColor: `${greenColor}!important`,
    opacity: '0.45!important',
  },
  switchBar_unchecked: {
    backgroundColor: `${secondaryColor}!important`,
    opacity: '0.45!important',
  },
  switchBarDisabled: {
    cursor: 'none',
  },
  switch_unchecked_disabled: {
    cursor: 'none',
    color: '#f05b4f',
  },
  switch_checked_disabled: {
    cursor: 'none',
    color: '#6cbb7e',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import {
  GridContainer,
  ItemGrid,
} from '../index';

import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';

class NavPills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
    };
  }

  handleChange = (e, active) => {
    this.setState({active});
    this.props.onChange(this.props.tabs[active]);
  };

  handleChangeIndex = (index) => {
    this.setState({active: index});
  };

  get tabContent() {
    if (this.props.isSwipeable) {
      return this.swipeableContent;
    }
    return this.nonSwipeableContent;
  }

  get swipeableContent() {
    const {
      classes,
      tabs,
      direction,
      wrapperClassName,
      contentClassName,
    } = this.props;
    return (
      <div className={classNames(classes.tab__contentWrapper, classes[wrapperClassName])}>
        <SwipeableViews
          axis={direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.active}
          onChangeIndex={this.handleChangeIndex}
        >
          {tabs.map((prop) => (
            <div className={classNames(classes.tab__content, classes[contentClassName])} key={prop.tabButton}>
              {prop.tabContent}
            </div>
          ))}
        </SwipeableViews>
      </div>
    );
  }

  get nonSwipeableContent() {
    const {
      classes,
      tabs,
      contentClassName,
    } = this.props;
    const tab = tabs[this.state.active] || {};
    return (
      <div className={classNames(classes.tab__content, classes[contentClassName])} key={tab.tabButton}>
        {tab.tabContent}
      </div>
    );
  }

  render() {
    const {
      classes,
      tabs,
      color,
      horizontal,
      alignCenter,
      className,
    } = this.props;
    const tabsClasses = {
      root: classNames(classes.root, classes[className]),
      fixed: classes.fixed,
      flexContainer: classNames(
        classes.flexContainer,
        {
          [classes.flexContainer_horizontal]: horizontal !== undefined,
        }
      ),
      indicator: classes.indicator,
    };
    const tabButtons = (
      <Tabs
        classes={tabsClasses}
        value={this.state.active}
        onChange={this.handleChange}
        centered={alignCenter}
      >
        {tabs.map((prop) => {
          const icon = {};
          if (prop.tabIcon !== undefined) {
            icon.icon = <prop.tabIcon className={classes.tab__icon} />;
          }
          const tabClasses = {
            root: classNames(
              classes.tab,
              {
                [classes.tab_horizontal]: horizontal !== undefined,
                [classes.tab_withIcon]: prop.tabIcon !== undefined,
              }
            ),
            wrapper: classes.label,
            selected: classes.secondary,
          };
          return (
            <Tab
              label={prop.tabButton}
              key={prop.tabButton}
              {...icon}
              classes={tabClasses}
            />
          );
        })}
      </Tabs>
    );
    return horizontal !== undefined ? (
      <GridContainer>
        <ItemGrid {...horizontal.tabsGrid}>{tabButtons}</ItemGrid>
        <ItemGrid {...horizontal.contentGrid}>{this.tabContent}</ItemGrid>
      </GridContainer>
    ) : (
      <div>
        {tabButtons}
        {this.tabContent}
      </div>
    );
  }
}

NavPills.defaultProps = {
  active: 0,
  color: 'primary',
  direction: '',
  alignCenter: false,
  horizontal: undefined,
  onChange: () => {},
  className: '',
  wrapperClassName: '',
  contentClassName: '',
  isSwipeable: true,
};

NavPills.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tabButton: PropTypes.string,
    tabIcon: PropTypes.func,
    tabContent: PropTypes.node,
  })).isRequired,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'edit',
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object,
  }),
  alignCenter: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  // If this field is true, there is the ability to swipe tabs.
  // Threat: is this case all tabs render at once
  isSwipeable: PropTypes.bool,
  contentClassName: PropTypes.string,
};

export default withStyles(navPillsStyle)(NavPills);

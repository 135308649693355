import React from 'react';
import {I18n} from 'react-redux-i18n';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import {Refresh} from '@material-ui/icons';
import {Card, CardContent, withStyles} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

import {CustomButton} from '../../material-dashboard-pro-react/components';
import RenderOrEmpty from '../RenderOrEmpty/RenderOrEmpty';

import {style} from './style';

/**
 * Table for observed groups
 * @param {groups} groups - array of groups. Each group must have `name` and `id fields
 * @param {onAddGroupClick} onAddGroupClick
 * - callback on clicking 'add group'. Takes group id as a parameter
 * @param {onAddAllClick} onAddAllClick - callback on clicking 'add all'
 * @param {disabled} disabled - if disabled, groups can't be configured
 */
function ObservedGroups({
  classes,
  groups,
  disabled,
  onAddGroupClick,
  onAddAllClick,
  refreshGroups,
}) {
  const renderHeader = () => (
    <div className={classes.header}>
      <IconButton
        onClick={refreshGroups}
        size="small"
      >
        <Refresh />
      </IconButton>
      <span>{I18n.t('users.usersConfigurer.persona.directoryServices.observedGroups')}</span>
      <span />
    </div>
  );

  const renderCell = (cell) => (
    <div className={classes.cell}>
      <span className={classes.cell__text}>{cell.original.name ?? ''}</span>
      <RenderOrEmpty isShow={!disabled}>
        <IconButton
          onClick={() => onAddGroupClick(cell.original.id)}
          size="small"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </RenderOrEmpty>
    </div>
  );

  const column = {
    Header: renderHeader,
    Cell: renderCell,
  };

  return (
    <Card className={classes.mainContainer}>
      <CardContent>
        <div className={classes.addAllContainer}>
          <CustomButton onClick={onAddAllClick} disabled={disabled}>
            <div className={classes.addAllContainer__button}>
              <AddIcon fontSize="small" />
              <span>{I18n.t('users.usersConfigurer.persona.directoryServices.addAll')}</span>
            </div>
          </CustomButton>
        </div>
        <ReactTable
          className={classes.table}
          data={groups}
          columns={[column]}
          filterable={false}
          sortable={false}
          showPagination={false}
          resizable={false}
        />
      </CardContent>
    </Card>
  );
}

ObservedGroups.propTypes = {
  classes: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onAddGroupClick: PropTypes.func.isRequired,
  onAddAllClick: PropTypes.func.isRequired,
  refreshGroups: PropTypes.func,
  disabled: PropTypes.bool,
};

ObservedGroups.defaultProps = {
  disabled: false,
  refreshGroups: () => {},
};

export default withStyles(style)(ObservedGroups);

import _isNaN from 'lodash/isNaN';

import {
  get,
  makeUrl,
} from '../../../utils/fetcher';

import {
  ALLOWED,
  FORBIDDEN,
  METHODS,
} from '../../../constants';
import {widgetKeys, types} from './constants';

export function getDashboardData(refreshData) {
  const period = refreshData.period;
  const campusId = refreshData.campusId;
  const queryParamsPeriodCampus = {
    campusId: campusId,
    period: period,
  };
  const queryParamsCampusStats = {
    campusId: campusId,
    period: null,
  };
  const queryParamsDecisionIsAllowed = {
    period: period,
    campusId: campusId,
    decision: ALLOWED,
  };
  const queryParamsDecisionIsForbidden = {
    period: period,
    campusId: campusId,
    decision: FORBIDDEN,
  };

  return (dispatch) => {
    dispatch(getAverageLatency(queryParamsPeriodCampus, widgetKeys.PERFORMANCE_STATS));
    dispatch(getCampusesStats(queryParamsCampusStats, widgetKeys.CAMPUSES_STATS));
    dispatch(getTotalRequestsByDecision(
      queryParamsDecisionIsForbidden,
      FORBIDDEN,
      widgetKeys.REQUEST_STATS,
    ));
    dispatch(getTotalRequestsByDecision(
      queryParamsDecisionIsAllowed,
      ALLOWED,
      widgetKeys.REQUEST_STATS,
    ));
    dispatch(getUserRequestsGraph(queryParamsPeriodCampus, widgetKeys.REQUEST_STATS));
  };
}

export function getAverageLatency(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_AVERAGE_LATENCY, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_AVERAGE_LATENCY,
            data,
          });
        } else {
          dispatch({
            type: types.SET_AVERAGE_LATENCY,
            data: 0.0,
          });
        }
      });
  };
}

export function getCampusesStats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CAMPUSES_STATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          Object.keys(data).forEach((key) => {
            const item = data[key];
            let requestsPerSecond = item.requestsPerSecond || 0.0;
            if (_isNaN(requestsPerSecond) || requestsPerSecond === 'Infinity') {
              requestsPerSecond = 0.0;
            }
            item.requestsPerSecond = requestsPerSecond;
          });
          dispatch({
            type: types.SET_CAMPUSES_STATS,
            data,
            campusId: queryParams.campusId,
          });
        } else {
          dispatch({
            type: types.SET_CAMPUSES_STATS,
            data: {},
            campusId: queryParams.campusId,
          });
        }
      });
  };
}

export function getTotalRequestsByDecision(queryParams, decision, widgetKey) {
  const url = makeUrl(METHODS.GET_TOTAL_REQUESTS_BY_DECISION, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_TOTAL_REQUEST_BY_DECISION,
            data,
            decision,
          });
        } else {
          dispatch({
            type: types.SET_TOTAL_REQUEST_BY_DECISION,
            data: 0,
            decision,
          });
        }
      });
  };
}

export function getUserRequestsGraph(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_USER_REQUESTS_GRAPH, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_USER_REQUESTS_GRAPH,
            period: queryParams.period,
            data,
          });
        } else {
          dispatch({
            type: types.SET_USER_REQUESTS_GRAPH,
            period: queryParams.period,
            data: {},
          });
        }
      });
  };
}

export function getOrgsAndSitesStats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_ORGS_AND_SITES_STATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGS_AND_SITES_STATS,
            data,
          });
        } else {
          dispatch({
            type: types.SET_ORGS_AND_SITES_STATS,
            data: {},
          });
        }
      });
  };
}

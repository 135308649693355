import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';

import {Dropdown, ModalWindow} from '../index';
import {Warning} from '../../icons/index';

import style from './style';

function AssignNewSuperAdminPopup(props) {
  const component = new React.Component(props);

  component.state = {
    selectedOperatorIndex: 0,
  };

  const onChangeValue = (e) => {
    component.setState({selectedOperatorIndex: e.target.value});
  };

  const onConfirm = () => {
    const operator = _get(props.operators, component.state.selectedOperatorIndex);
    props.confirm(operator);
  };

  const descriptionContentClass = {
    root: props.classes.dialogDescription,
  };

  const warningContentClass = {
    root: props.classes.dialogWarning,
  };

  const warningIcon = {
    root: props.classes.warningIcon,
  };

  const dropdownClasses = {
    dropdown__menu: props.classes.dropdownMenu,
  };

  const operators = component.props.operators.map((o) => o.email);

  const newRole = I18n.t(`userRoles.${props.newRole}`);
  component.render = () => (
    <ModalWindow
      title={I18n.t('accessManagementPage.operatorInfo.assignNewSuperAdmin.title')}
      cancel={component.props.cancel}
      confirm={onConfirm}
    >
      <>
        <DialogContentText classes={descriptionContentClass}>
          {I18n.t('accessManagementPage.operatorInfo.assignNewSuperAdmin.description', {newRole})}
        </DialogContentText>
        <Dropdown
          classes={dropdownClasses}
          label={I18n.t('accessManagementPage.operatorInfo.assignNewSuperAdmin.label')}
          onChangeValue={onChangeValue}
          options={operators}
          selectedItemIndex={component.state.selectedOperatorIndex}
          customSelectClass="dropdown__select_invertColor"
          customIconClass="dropdown__icon_invertColor"
        />
        <DialogContentText classes={warningContentClass}>
          <Warning classes={warningIcon} />
          {I18n.t('accessManagementPage.operatorInfo.assignNewSuperAdmin.warning', {newRole})}
        </DialogContentText>
      </>
    </ModalWindow>
  );

  return component;
}

AssignNewSuperAdminPopup.propTypes = {
  operators: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  newRole: PropTypes.string.isRequired,
};

export default withStyles(style)(AssignNewSuperAdminPopup);

import React from 'react';
import {I18n} from 'react-redux-i18n';

function LogoutSSO() {
  return (
    <p style={{textAlign: 'center'}}>
      {I18n.t('logoutPageSSO.warning')}
    </p>
  );
}

export default LogoutSSO;

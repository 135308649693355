import initState from '../../reducers/redux-initial-state';

import {types} from './constants';

export default (state = initState.organizationProfilesTabEditFormReducer, action) => {
  switch (action.type) {
    case types.CHANGE_ORGANIZATION_PROFILES_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    }
    case types.SET_EDIT_FROM_ORGANIZATION_PROFILES: {
      return {
        ...state,
        profilesNotInOrganization: action.profilesNotInOrganization,
        profilesInOrganization: action.profilesInOrganization,
      };
    }
    default:
      return state;
  }
};

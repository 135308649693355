import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (
  state = initState.userSessionsReducer,
  action: {type: string, data: []},
) => {
  switch (action.type) {
    case types.SET_USER_SESSIONS: {
      return {
        ...state,
        sessions: action.data,
      };
    }
    default:
      return state;
  }
};

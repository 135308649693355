import {I18n} from 'react-redux-i18n';

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {getBlockPageToCreateOrUpdate, prepareBlockPageData} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {types} from './constants';

export function getBlockPages() {
  const url = makeUrl(METHODS.GET_BLOCK_PAGES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_BLOCK_PAGES,
          data,
        });
      }
    });
}

export function getBlockPageById(blockPageId) {
  const url = makeUrl(METHODS.GET_BLOCK_PAGE_BY_ID, {id: blockPageId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_SELECTED_BLOCK_PAGE,
          data,
        });
      }
    });
}

export function uploadBlockPageLogo(blockPage) {
  const url = makeUrl(METHODS.UPLOAD_BLOCK_PAGE_LOGO);
  const data = new FormData();
  data.append('image', blockPage.contentLogo);

  return (dispatch) => post(
    url,
    data,
    dispatch,
    ...Array(2),
    true,
    I18n.t('serverErrors.blockpages.unable-to-upload-image'),
  )
    .then((response) => {
      if (response && response.success) {
        dispatch(updateBlockPageLogoURL(blockPage, response.data.url));
        return response.data.url;
      }
      return null;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to upload block page logo:', error);
    });
}

export function createBlockPage(newBlockPage) {
  const url = makeUrl(METHODS.CREATE_BLOCK_PAGE);
  const data = getBlockPageToCreateOrUpdate(newBlockPage);
  const blockPageWithThreatPage = prepareBlockPageData(data);
  return (dispatch) => post(url, blockPageWithThreatPage, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndBlockPageState(false, STATES_ENTITY.NONE));
        dispatch(updateBlockPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to create block page:', error);
    });
}

export function updateBlockPage(updatedBlockPage) {
  const url = makeUrl(METHODS.UPDATE_BLOCK_PAGE);
  const data = prepareBlockPageData(updatedBlockPage);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndBlockPageState(false, STATES_ENTITY.NONE));
        dispatch(getBlockPages()).then(() => dispatch({
          type: types.SET_UPDATED_BLOCK_PAGE,
          data: response.data,
        }));
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to edit block page:', error);
    });
}

export function deleteBlockPage(blockPageId) {
  const data = {
    id: blockPageId,
  };
  const url = makeUrl(METHODS.DELETE_BLOCK_PAGE);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updateBlockPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to delete block page:', error);
    });
}

export function changeEditModeAndBlockPageState(isEditMode, blockPageState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_BLOCK_PAGE_STATE,
      isEditMode,
      blockPageState,
    });
  };
}

export function getDataToShowBlockPageDetails(blockPageId, blockPageIndex) {
  return (dispatch) => {
    dispatch(getBlockPageById(blockPageId));
    dispatch(setSelectedBlockPageIndex(blockPageIndex));
  };
}

export function getBlockPagesTableData(blockPagesStats) {
  return (dispatch) => {
    dispatch({
      type: types.SET_BLOCK_PAGES_TABLE_DATA,
      blockPagesStats,
    });
  };
}

export function setSelectedBlockPageIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_BLOCK_PAGE_INDEX,
      selectedBlockPageIndex: index,
    });
  };
}

export function resetSelectedBlockPageIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_BLOCK_PAGE_INDEX,
    });
  };
}

export function resetPageData() {
  return (dispatch) => {
    dispatch(resetSelectedBlockPageIndex());
  };
}

export function updateBlockPageData() {
  return (dispatch) => dispatch(getBlockPages());
}

export function updateBlockPageLogoURL(blockPage, url) {
  return (dispatch) => {
    dispatch({
      type: types.SET_BLOCK_PAGE_LOGO_URL,
      data: {
        url,
        blockPage,
      },
    });
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';
import _findIndex from 'lodash/findIndex';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {Dropdown} from '../../components/index';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {
  setEditableLocalDomainInfo,
  setValidationLocalDomainInfo,
  resetLocalDomainEditFormData,
  updateLocalDomainInfo,
} from './action';

import {STATES_ENTITY} from '../../constants';

import style from './style';

class LocalDomainEditForm extends Component {
  static initialLocalState = {
    validation: {
      domain: true,
      ip: true,
    },
  };

  static shouldUpdateLocalDomainObject(prevProps, props) {
    return !_isEqual(prevProps.localDomain, props.localDomain);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.localDomainState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = LocalDomainEditForm.initialLocalState;
  }

  componentDidMount() {
    this.props.setEditableLocalDomainInfo({
      ...this.props.localDomain,
      networkName: I18n.t('campusPage.localDomainsTab.allWANsOptionLabel'),
      networkId: null,
    });
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.localDomain, nextProps.localDomain)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableLocalDomainInfo, nextProps.editableLocalDomainInfo);
  }

  componentDidUpdate(prevProps) {
    if (LocalDomainEditForm.shouldUpdateLocalDomainObject(prevProps, this.props)
      || LocalDomainEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.setEditableLocalDomainInfo(this.props.localDomain);
    }
    if (LocalDomainEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetLocalDomainEditFormData();
  }

  getSelectedNetworkIndex = () => {
    const {editableLocalDomainInfo, networks} = this.props;

    if (_isNull(editableLocalDomainInfo.networkId)
      || _isEqual('', editableLocalDomainInfo.networkId)) {
      return 0;
    }
    return _findIndex(
      networks,
      (item) => _isEqual(item.id, editableLocalDomainInfo.networkId),
    ) + 1;
  };

  getNetworksOptionsWithAllWAN = () => [
    I18n.t('campusPage.localDomainsTab.allWANsOptionLabel'),
    ...this.props.networks.map((network) => network.name),
  ];

  resetValidation = () => {
    this.props.setValidationLocalDomainInfo(true);
    this.setState(LocalDomainEditForm.initialLocalState);
  };

  validate = (data) => validate(
    [
      {
        name: 'domain',
        validationRules: [isNotEmpty],
      },
      {
        name: 'ip',
        validationRules: [isNotEmpty],
      },
    ],
    data,
    {
      name: true,
      ipAddressRange: true,
    },
  );

  handleInputChange = (e) => {
    const {editableLocalDomainInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableLocalDomainInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateLocalDomainInfo(
      {
        ...editableLocalDomainInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeNetwork = (e) => {
    const {
      editableLocalDomainInfo,
      networks,
    } = this.props;
    const index = e.target.value;
    if (index === 0) {
      this.props.updateLocalDomainInfo(
        {
          ...editableLocalDomainInfo,
          networkName: I18n.t('campusPage.localDomainsTab.allWANsOptionLabel'),
          networkId: null,
          forAllNetworks: true,
        },
        true,
      );
    } else {
      this.props.updateLocalDomainInfo(
        {
          ...editableLocalDomainInfo,
          networkName: networks[index - 1].name,
          networkId: networks[index - 1].id,
          forAllNetworks: false,
        },
        true,
      );
    }
  };

  render() {
    const {
      classes,
      editableLocalDomainInfo,
      isEditMode,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      domain: {
        disabled: !isEditMode,
        value: editableLocalDomainInfo.domain,
        name: 'domain',
        onChange: this.handleInputChange,
        type: 'text',
      },
      ip: {
        disabled: !isEditMode,
        value: editableLocalDomainInfo.ip,
        name: 'ip',
        onChange: this.handleInputChange,
        type: 'text',
      },
    };
    const propsInputLabel = {
      shrink: true,
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <Dropdown
            customSelectClass="dropdown__select_networkSettingsTab"
            disabled={!isEditMode}
            dropdownName="network"
            fullWidth={true}
            label={I18n.t('campusPage.localDomainsTab.labels.network')}
            onChangeValue={this.handleChangeNetwork}
            options={this.getNetworksOptionsWithAllWAN()}
            selectedItemIndex={this.getSelectedNetworkIndex()}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.domain
              ? undefined
              : I18n.t('campusPage.localDomainsTab.errorMessages.domainIsNotValid')}
            error={!validation.domain}
            labelText={I18n.t('campusPage.localDomainsTab.labels.domain')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.domain}
            labelProps={propsInputLabel}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.ip
              ? undefined
              : I18n.t('campusPage.localDomainsTab.errorMessages.ipIsNotValid')}
            error={!validation.ip}
            labelText={I18n.t('campusPage.localDomainsTab.labels.ip')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.ip}
            labelProps={propsInputLabel}
          />
        </div>
      </div>
    );
  }
}

LocalDomainEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  editableLocalDomainInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  localDomain: PropTypes.object.isRequired,
  networks: PropTypes.array.isRequired,

  setEditableLocalDomainInfo: PropTypes.func.isRequired,
  setValidationLocalDomainInfo: PropTypes.func.isRequired,
  resetLocalDomainEditFormData: PropTypes.func.isRequired,
  updateLocalDomainInfo: PropTypes.func.isRequired,
};

LocalDomainEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.policyReducer.policies,
  localDomain: state.campusLocalDomainsTabReducer.selectedLocalDomain,
  localDomainState: state.campusLocalDomainsTabReducer.localDomainState,
  isEditMode: state.campusLocalDomainsTabReducer.isEditMode,
  editableLocalDomainInfo: state.localDomainEditFormReducer.editableLocalDomainInfo,
  networks: state.campusNetworksTabReducer.networks,
});

const mapDispatchToProps = (dispatch) => ({
  setEditableLocalDomainInfo: bindActionCreators(setEditableLocalDomainInfo, dispatch),
  setValidationLocalDomainInfo: bindActionCreators(setValidationLocalDomainInfo, dispatch),
  resetLocalDomainEditFormData: bindActionCreators(resetLocalDomainEditFormData, dispatch),
  updateLocalDomainInfo: bindActionCreators(updateLocalDomainInfo, dispatch),
});

const LocalDomainEditFormWithStyles = withStyles(style)(LocalDomainEditForm);
export default connect(mapStateToProps, mapDispatchToProps)(LocalDomainEditFormWithStyles);

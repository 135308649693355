import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _without from 'lodash/without';

import {
  CampusTable,
  ContainerWithListAndForm,
  CampusCreating,
  CampusDetails,
} from '../../components';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

import {
  changeEditModeAndCampusState,
  createCampus,
  deleteCampus,
  getDataToShowCampusDetails,
  getCampusesTableData,
  getCampusDashboardData,
  resetPageData,
  resetSelectedCampusIndex,
  setSelectedPeriodIndex,
  updateCampus,
  updateCampusesPageData,
} from './action';
import {cancelRequests} from '../../containers/Loading/action';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  MODAL_WINDOW_NOTIFY_TYPE,
  STATES_ENTITY,
} from '../../constants';
import {widgetKeys} from './constants';

class Campus extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateCampusTableData(prevProps, props) {
    return !_isEqual(prevProps.campuses, props.campuses)
      || !_isEqual(prevProps.campusesStats, props.campusesStats);
  }

  constructor(props) {
    super(props);
    this.state = Campus.initialLocalState;
  }

  componentDidMount() {
    if (!_isEmpty(this.props.campusesStats) && !_isEmpty(this.props.campuses)) {
      this.props.getCampusesTableData(this.props.campusesStats);
    } else if (!_isEmpty(this.props.campuses)) {
      this.props.getCampusesTableData(this.props.campuses);
    }
    this.props.updateCampusesPageData();
  }

  componentDidUpdate(prevProps) {
    if (Campus.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.cancelRequests([widgetKeys.NETWORK_STATUSES], false);
      this.props.resetSelectedCampusIndex();
      this.props.updateCampusesPageData();
    }
    if (Campus.shouldUpdateCampusTableData(prevProps, this.props)) {
      if (!_isEmpty(this.props.campusesStats) && !_isEmpty(this.props.campuses)) {
        this.props.getCampusesTableData(this.props.campusesStats);
      } else {
        this.props.getCampusesTableData(this.props.campuses);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetPageData();
    this.interruptRequests(true);
  }

  getDashboardData = () => {
    const {
      averageLatency,
      blocksStats,
      networksStats,
      requestsPerSecond,
      requestsByDecision,
      userRequestsGraph,
    } = this.props;
    return {
      averageLatency: averageLatency,
      blocksStats: blocksStats,
      requestsByDecision: requestsByDecision,
      requestsPerSecond: requestsPerSecond,
      networksStats: networksStats,
      userRequestsGraph: userRequestsGraph,
    };
  };

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isNetworkEditMode,
      isSubnetEditMode,
      isLocalDomainEditMode,
      isLocalForwardingEditMode,
      isValidCampusInfo,
      selectedCampus,
      selectedPeriodIndex,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <CampusCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateCampus}
            handleClickButtonSave={this.handleClickButtonSaveNewCampus}
            isValidCampusInfo={isValidCampusInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <CampusDetails
            campusInfo={selectedCampus}
            dashboardData={this.getDashboardData()}
            handleClickButtonBackInCampusInfo={this.goToCampusesList}
            handleClickButtonDeleteCampus={this.handleClickButtonDeleteCampus}
            handleClickButtonCancelEditCampus={this.handleClickButtonCancelCampusEditing}
            handleClickButtonEditCampus={this.handleClickButtonEditCampus}
            handleClickButtonSaveCampus={this.handleClickButtonSaveCampus}
            handleChangePeriodOnDashboard={this.updateDashboardData}
            isEditMode={isEditMode}
            isSubTabEditMode={{
              isNetworkEditMode,
              isSubnetEditMode,
              isLocalDomainEditMode,
              isLocalForwardingEditMode,
            }}
            isValidCampusInfo={isValidCampusInfo}
            selectedPeriodIndex={selectedPeriodIndex}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  resetLocalState = () => this.setState(Campus.initialLocalState);

  handleClickButtonSaveNewCampus = () => {
    const {newOrUpdatedCampus} = this.props;
    this.props.createCampus(newOrUpdatedCampus)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToCampusesList();
        }
      });
  };

  handleClickButtonCancelCreateCampus = () => {
    this.goToCampusesList();
    this.props.changeEditModeAndCampusState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelCampusEditing = () => {
    this.props.changeEditModeAndCampusState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditCampus = () => {
    this.props.changeEditModeAndCampusState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveCampus = () => {
    const {newOrUpdatedCampus} = this.props;
    this.props.updateCampus(newOrUpdatedCampus);
  };

  handleClickButtonDeleteCampus = () => {
    const {selectedCampus} = this.props;
    this.props.deleteCampus(selectedCampus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToCampusesList();
        }
      });
  };

  showFormCreatingCampus = () => {
    const {
      accountInfo,
      campuses,
    } = this.props;
    if (campuses.length >= accountInfo.maxLocations) {
      this.props.openModalWindow(
        I18n.t('campusPage.errorMessages.campusesListMoreThenMaxCampuses'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      );
      return;
    }

    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndCampusState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedCampusIndex();
  };

  showCampusDetails = (campusIndex) => {
    const {campuses} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowCampusDetails(campuses[campusIndex].id, campusIndex);
  };

  updateDashboardData = (refreshData, selectedPeriodIndex) => {
    this.interruptRequests(false);
    this.props.getCampusDashboardData(refreshData);
    this.props.setSelectedPeriodIndex(selectedPeriodIndex);
  };

  goToCampusesList = () => {
    this.setState(Campus.initialLocalState);
    this.props.resetSelectedCampusIndex();
  };

  interruptRequests = (includeDefaultKey = false) => {
    this.props.cancelRequests(
      _without(Object.values(widgetKeys), widgetKeys.NETWORK_STATUSES),
      includeDefaultKey,
    );
  };

  render() {
    const {
      campusesTableData,
      isEditMode,
      isNetworkEditMode,
      isSubnetEditMode,
      isLocalDomainEditMode,
      isLocalForwardingEditMode,
      selectedCampusIndex,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    return (
      <ContainerWithListAndForm
        list={(
          <CampusTable
            handleClickCampusRow={this.showCampusDetails}
            handleClickButtonNew={this.showFormCreatingCampus}
            isEditMode={
              isEditMode
                || isNetworkEditMode
                || isSubnetEditMode
                || isLocalDomainEditMode
                || isLocalForwardingEditMode
            }
            mode={tableMode}
            tableData={campusesTableData}
            selectedCampusIndex={selectedCampusIndex}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

Campus.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  averageLatency: PropTypes.number.isRequired,
  blocksStats: PropTypes.object.isRequired,
  networksStats: PropTypes.object.isRequired,
  campuses: PropTypes.array.isRequired,
  campusesStats: PropTypes.object.isRequired,
  campusesTableData: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isNetworkEditMode: PropTypes.bool.isRequired,
  isSubnetEditMode: PropTypes.bool.isRequired,
  isLocalDomainEditMode: PropTypes.bool.isRequired,
  isLocalForwardingEditMode: PropTypes.bool.isRequired,
  isValidCampusInfo: PropTypes.bool.isRequired,
  newOrUpdatedCampus: PropTypes.object.isRequired,
  requestsByDecision: PropTypes.object.isRequired,
  requestsPerSecond: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  selectedCampus: PropTypes.object.isRequired,
  selectedCampusIndex: PropTypes.number.isRequired,
  selectedPeriodIndex: PropTypes.number.isRequired,
  userRequestsGraph: PropTypes.object.isRequired,

  changeEditModeAndCampusState: PropTypes.func.isRequired,
  createCampus: PropTypes.func.isRequired,
  deleteCampus: PropTypes.func.isRequired,
  getDataToShowCampusDetails: PropTypes.func.isRequired,
  getCampusDashboardData: PropTypes.func.isRequired,
  getCampusesTableData: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  resetSelectedCampusIndex: PropTypes.func.isRequired,
  resetPageData: PropTypes.func.isRequired,
  setSelectedPeriodIndex: PropTypes.func.isRequired,
  updateCampus: PropTypes.func.isRequired,
  updateCampusesPageData: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

Campus.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,

  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  averageLatency: state.dashboardReducer.averageLatency,
  blocksStats: state.dashboardReducer.blocksStats,
  campusesStats: state.dashboardReducer.campusesStats,
  networksStats: state.dashboardReducer.networksStats,
  requestsPerSecond: state.dashboardReducer.requestsPerSecond,
  requestsByDecision: state.dashboardReducer.requestsByDecision,
  userRequestsGraph: state.dashboardReducer.userRequestsGraph,

  newOrUpdatedCampus: state.campusEditFormReducer.editableCampusInfo,
  isValidCampusInfo: state.campusEditFormReducer.isValidCampusInfo,

  isNetworkEditMode: state.campusNetworksTabReducer.isEditMode,
  isSubnetEditMode: state.campusSubnetsTabReducer.isEditMode,
  isLocalDomainEditMode: state.campusLocalDomainsTabReducer.isEditMode,
  isLocalForwardingEditMode: state.campusLocalForwardingTabReducer.isEditMode,

  campuses: state.campusReducer.campuses,
  campusesTableData: state.campusReducer.campusesTableData,
  campusNetworksStats: state.campusReducer.campusNetworksStats,
  isEditMode: state.campusReducer.isEditMode,
  selectedCampus: state.campusReducer.selectedCampus,
  selectedCampusIndex: state.campusReducer.selectedCampusIndex,
  selectedPeriodIndex: state.campusReducer.selectedPeriodIndex,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndCampusState: bindActionCreators(changeEditModeAndCampusState, dispatch),
  createCampus: bindActionCreators(createCampus, dispatch),
  deleteCampus: bindActionCreators(deleteCampus, dispatch),
  getDataToShowCampusDetails: bindActionCreators(getDataToShowCampusDetails, dispatch),
  getCampusDashboardData: bindActionCreators(getCampusDashboardData, dispatch),
  getCampusesTableData: bindActionCreators(getCampusesTableData, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  resetSelectedCampusIndex: bindActionCreators(resetSelectedCampusIndex, dispatch),
  resetPageData: bindActionCreators(resetPageData, dispatch),
  setSelectedPeriodIndex: bindActionCreators(setSelectedPeriodIndex, dispatch),
  updateCampus: bindActionCreators(updateCampus, dispatch),
  updateCampusesPageData: bindActionCreators(updateCampusesPageData, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campus));

import initState from '../../reducers/redux-initial-state';

import {
  getPolicyToFormCreating,
  getPoliciesTableData,
  getUpdatedPolicyIndex,
  getSortedPolicies,
} from './service';

import {types} from './action';

export default (state = initState.policyReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_POLICY_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        policyState: action.policyState,
      };
    }
    case types.SET_POLICIES: {
      return {
        ...state,
        policies: getSortedPolicies(action.data),
      };
    }
    case types.SET_POLICIES_TABLE_DATA: {
      return {
        ...state,
        policiesTableData: getPoliciesTableData(state.policies),
      };
    }
    case types.SET_POLICY_TO_FORM_CREATING: {
      return {
        ...state,
        selectedPolicy: getPolicyToFormCreating(action.data),
      };
    }
    case types.SET_SELECTED_POLICY: {
      return {
        ...state,
        selectedPolicy: action.data,
      };
    }
    case types.SET_SELECTED_POLICY_INDEX: {
      return {
        ...state,
        selectedPolicyIndex: action.selectedPolicyIndex,
      };
    }
    case types.SET_UPDATED_POLICY: {
      return {
        ...state,
        selectedPolicyIndex: getUpdatedPolicyIndex(action.data.id, state.policies),
        selectedPolicy: action.data,
      };
    }
    case types.RESET_POLICIES: {
      return {
        ...state,
        policies: initState.policyReducer.policies,
      };
    }
    case types.RESET_SELECTED_POLICY_INDEX: {
      return {
        ...state,
        selectedPolicyIndex: initState.policyReducer.selectedPolicyIndex,
        selectedPolicy: initState.policyReducer.selectedPolicy,
      };
    }
    default:
      return state;
  }
};

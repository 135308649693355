import _isNull from 'lodash/isNull';

import {
  post,
  makeUrl,
} from '../../utils/fetcher';

import {
  clearLocalStorage,
  getSSOTokens,
  setJWT,
  setRefresh,
} from '../../utils/jwt';

import {
  setLoggedAccount,
} from '../../utils/account';

import {setLoggedAccountToStore} from '../userAccounts/setLoggedAccountToStore';

import {
  METHODS,
  ROUTES,
} from '../../constants';

import {types} from './actionTypes';
import {getManagementType} from '../../utils/tenants';

export function login(data, browserHistory) {
  const url = makeUrl(METHODS.AUTH);
  const dataWithTenant = {
    ...data,
    managementType: getManagementType(),
  };
  return (dispatch) => post(url, dataWithTenant, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        const _data = response.data;
        setJWT(_data.token);
        setRefresh(_data.refreshToken);
        if (_data.waitForAuthCode) {
          dispatch(openLogin2FAForm());
        } else {
          dispatch(finishSuccessLogin(_data, browserHistory));
        }
        return true;
      }
      dispatch(finishFailedLogin());
      return false;
    })
    .catch(() => {
      dispatch(finishFailedLogin());
      return false;
    });
}

export function loginWithSSO(localStorageTokenId, browserHistory) {
  const url = makeUrl(METHODS.OAUTH_SSO);

  const data = getSSOTokens(localStorageTokenId);

  return (dispatch) => post(url, data, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        const _data = response.data;
        setJWT(_data.token);
        setRefresh(_data.refreshToken);
        dispatch(finishSuccessLogin(_data, browserHistory));
        return true;
      }
      dispatch(finishFailedLogin());
      return false;
    })
    .catch(() => {
      dispatch(finishFailedLogin());
      return false;
    });
}

function finishSuccessLogin(authData, browserHistory, routeOnSuccess = true) {
  return (dispatch) => {
    setJWT(authData.token);
    setRefresh(authData.refreshToken);
    if (!_isNull(authData.accountId)) {
      setLoggedAccount(
        authData.accountId,
        authData.role,
        authData.name,
      );
      dispatch(loginSuccess(authData));
      dispatch(setLoggedAccountToStore());
      if (routeOnSuccess) {browserHistory.push(ROUTES.DASHBOARD);}
      return;
    }

    setLoggedAccount();
    dispatch(loginSuccess(authData));
    dispatch(setLoggedAccountToStore());
    if (routeOnSuccess) {browserHistory.push(ROUTES.NO_ACCOUNTS);}
  };
}

function finishFailedLogin(showErrorMessage = true) {
  return (dispatch) => {
    clearLocalStorage();
    dispatch(loginFailed(showErrorMessage));
  };
}

export function loginSuccess(authData) {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_LOGIN_SUCCESS,
      data: authData,
    });
  };
}

export function loginFailed(showErrorMessage = true) {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_LOGIN_FAILED,
      showErrorMessage,
    });
  };
}

export function showSecondFactorErrorMessage() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_SET_LOGIN_SECOND_FACTOR_STATUS,
      passed: false,
    });
  };
}

export function hideSecondFactorErrorMessage() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_SET_LOGIN_SECOND_FACTOR_STATUS,
      passed: true,
    });
  };
}

export function openLogin2FAForm() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_OPEN_LOGIN_2FA_FORM,
    });
  };
}

export function hideLogin2FAForm() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_HIDE_LOGIN_2FA_FORM,
    });
  };
}

export function login2FA(data, browserHistory, routeOnSuccess) {
  const url = makeUrl(METHODS.AUTH2FA);
  return (dispatch) => post(url, data, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        const _data = response.data;
        if (_data.waitForAuthCode) {
          dispatch(showSecondFactorErrorMessage());
          dispatch(openLogin2FAForm());
        } else {
          dispatch(hideSecondFactorErrorMessage());
          dispatch(finishSuccessLogin(_data, browserHistory, routeOnSuccess));
        }
        return true;
      }
      dispatch(hideSecondFactorErrorMessage());
      dispatch(finishFailedLogin(false));
      return false;
    })
    .catch(() => {
      dispatch(hideSecondFactorErrorMessage());
      dispatch(finishFailedLogin(false));
      return false;
    });
}

export function resendCode() {
  const url = makeUrl(METHODS.RESEND_AUTH_CODE);
  return (dispatch) => post(url, {}, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        setJWT(response.data.token);
        setRefresh(response.data.refreshToken);
        dispatch(hideSecondFactorErrorMessage());
        dispatch(openLogin2FAForm());
        return true;
      }
      dispatch(finishFailedLogin());
      return false;
    })
    .catch(() => {
      dispatch(finishFailedLogin());
      return false;
    });
}

export function setAuthCodeCheckedStatus(isAuthCodeChecked) {
  return (dispatch) => dispatch({
    type: types.ACTION_SET_AUTH_CODE_CHECKED_STATUS,
    isAuthCodeChecked,
  });
}

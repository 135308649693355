import React, {Component} from 'react';
import {I18n} from 'react-redux-i18n';

import {TABS} from '../../app-common/Insights/constants';
import {InsightsLayout} from '../../layouts-common';
import {CategoryInsightsFilter} from '../index';

class CategoryInsights extends Component {
  getHeaders = () => [
    {
      Header: I18n.t(`insights.${TABS.CATEGORY}.table.category`),
      accessor: 'category',
      filterable: false,
    },
    {
      Header: I18n.t(`insights.${TABS.CATEGORY}.table.categoryType`),
      accessor: 'categoryType',
      filterable: false,
    },
    {
      Header: I18n.t(`insights.${TABS.CATEGORY}.table.count`),
      accessor: 'count',
      filterable: false,
    },
    {
      Header: I18n.t(`insights.${TABS.CATEGORY}.table.percent`),
      accessor: 'percent',
      Cell: (row) => Math.round(row.value * 100) / 100,
      filterable: false,
    },
  ];

  render() {
    const columns = this.getHeaders();
    return (
      <InsightsLayout
        tab={TABS.CATEGORY}
        columns={columns}
        filters={<CategoryInsightsFilter />}
      />
    );
  }
}

export default CategoryInsights;

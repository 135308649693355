import {selectDateOrTime} from '../../utils/dashboard';

export const convertGraphDataToView = (graphData, period) =>
  graphData.map((item) => ({
    ...item,
    data: item.data.map((it) => ({
      ...it,
      time: selectDateOrTime(it.time, period),
    })),
  }));

import {
  primaryColor,
} from '../../styles/style';

const style = (theme) => ({
  insightsFiltersTooltip__tooltipPopper: {
  },
  insightsFiltersTooltip__tooltip: {
    background: primaryColor,
    color: '#FFF',
    boxShadow: theme.shadows[1],
    fontSize: 17,
    width: '200px',
    textAlign: 'center',
  },
});

export default style;

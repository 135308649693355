// ##############################
// // // ValidationForms view styles
// #############################

import customCheckboxRadioSwitch from "../customCheckboxRadioSwitch";

const validationFormsStyle = {
  formCategory: {
    marginBottom: "0",
    color: "#999999",
    fontSize: "14px",
    padding: "10px 0 10px"
  },
  ...customCheckboxRadioSwitch,
  center: {
    textAlign: "center"
  }
};

export default validationFormsStyle;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import style from './style';

import {TABS as clientsTabs} from '../../app-common/Clients/constants';
import {ClientOsIcon, EntitiesListWithViewModesCard} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_CREATE],
};

class ClientBundlesTable extends React.Component {
  constructor(props) {
    super(props);

    this.installsCountCellRenderer = (row) =>
      (<span>{`${row.value.used} | ${row.value.max}`}</span>);
    this.osIconCellRenderer = (row) =>
      (<ClientOsIcon iconKey={row.value} osName={row.original.osName} />);
    this.downloadCellRenderer = (row) => (
      <a href={row.value}
         download={true}
         onClick={this.handleClickDownloadRef}
         className={this.props.classes.clientBundlesTable__text_link}
      >
        {I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.download`)}
      </a>
    );
  }

  get columnsFullWidthMode() {
    return [
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.accessKey`),
        accessor: 'id',
        minWidth: 220,
        filterable: false,
        sortable: false,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.expireDate`),
        accessor: 'expireDate',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.installs`),
        accessor: 'installs',
        minWidth: 80,
        filterable: false,
        sortable: false,
        isShow: true,
        Cell: this.installsCountCellRenderer,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.os`),
        accessor: 'osIconKey',
        minWidth: 50,
        filterable: false,
        sortable: true,
        isShow: true,
        style: {textAlign: 'center'},
        Cell: this.osIconCellRenderer,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.architecture`),
        accessor: 'architecture',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.file`),
        accessor: 'file',
        minWidth: 100,
        filterable: false,
        sortable: false,
        isShow: true,
        Cell: this.downloadCellRenderer,
      },
    ];
  }

  get columnsCompressedMode() {
    return [
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.headers.accessKey`),
        accessor: 'id',
        minWidth: 200,
        filterable: false,
        sortable: false,
        isShow: true,
      },
    ];
  }

  handleClickDownloadRef = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      tableData,
      tableStyles,
      mode,
      isEditMode,
      selectedBundleIndex,
      handleClickButtonNew,
      handleClickRow,
    } = this.props;

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.columnsFullWidthMode}
        headersCompressedMode={this.columnsCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t(`clients.${clientsTabs.CONFIGURE}.installForm.table.title`)}
        entityType={I18n.t('entitiesTypes.clientBundle')}
        selectedEntityIndex={selectedBundleIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickRow}
        tableStyles={tableStyles}
        fullscreen={false}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

ClientBundlesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableData: PropTypes.array,
  tableStyles: PropTypes.object,
  selectedBundleIndex: PropTypes.number.isRequired,
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

ClientBundlesTable.defaultProps = {
  tableData: [],
  tableStyles: {},
};

export default withStyles(style)(ClientBundlesTable);

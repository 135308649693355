/* eslint-disable max-len */
import customSelectStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/customSelectStyle';
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';

import {
  defaultFont,
  primaryColor,
  secondaryColor,
} from '../../styles/style';

const style = {
  popperClose: {
    pointerEvents: 'none',
    display: 'none',
  },
  multiDropdown__formControl: {
    ...customSelectStyle.selectFormControl,
    paddingBottom: '10px',
  },
  multiDropdown__menu: {
    ...customSelectStyle.selectMenu,
  },
  multiDropdown__select: {
    ...customInputStyle.input,
    display: 'flex',
    alignItems: 'center',
  },
  multiDropdownSelect__renderValue: {
    width: 'calc(100% - 25px)',
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  multiDropdown__option: {
    ...defaultFont,
    'fontSize': '13px',
    'padding': '10px 20px 10px 0',
    'margin': '0 5px',
    'borderRadius': '2px',
    'color': primaryColor,
    'borderColor': primaryColor,
    '&:hover': {
      backgroundColor: secondaryColor,
      color: '#FFFFFF',
      borderColor: '#FFFFFF',
    },
  },
  multiDropdown__option_selected: {
    backgroundColor: `${secondaryColor}!important`,
    color: '#FFFFFF',
  },
  multiDropdown__option_readOnly: {
    'opacity': '0.5',
    '&:hover': {
      backgroundColor: 'unset',
      color: 'unset',
      borderColor: 'unset',
    },
  },
  multiDropdownOption__text: {
    display: 'inline-block',
    padding: '0',
  },
  multiDropdownOption__checkbox: {
    borderColor: 'inherit',
  },
  multiDropdownOption__checkbox_checked: {
    color: 'inherit',
  },
  multiDropdownOptionCheckbox__icon_checked: {
    width: '20px',
    height: '20px',
    border: '1px solid',
    borderColor: 'inherit',
    borderRadius: '3px',
  },
  multiDropdownOptionCheckbox__icon_unchecked: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid',
    borderColor: 'inherit',
    borderRadius: '3px',
  },
  multiDropdownOption__text_primary: {
    fontSize: 'inherit',
    color: 'inherit',
  },
  multiDropdown__label: {
    ...customInputStyle.labelRoot,
  },
  multiDropdown__select_campusDashboard: {
    minWidth: '150px',
  },
  multiDropdown__select_dashboard: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: 'calc(100vw - 50px)',
    },
  },
  multiDropdown__select_loggingPanel: {
    padding: '4px 0 0',
  },
  multiDropdown__select_policySettingsTab: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  multiDropdown__select_insightsFilters: {
    overflow: 'unset',
    textOverflow: 'unset',
    whiteSpace: 'unset',
    height: 'unset',
    minHeight: '36px',
  },
};

export default style;

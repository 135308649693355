const style = {
  entityWithModesCard__tableBody: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 240px)',
      marginBottom: '20px',
    },
  },
};

export default style;

import _get from 'lodash/get';

import {get, makeUrl} from '../utils/fetcher';

const METHODS = {
  GET_WHITE_BLACK_LISTS: 'getWhiteBlackLists',
};

export function getWhiteBlackLists() {
  const url = makeUrl(METHODS.GET_WHITE_BLACK_LISTS);
  return (dispatch) => get(url, dispatch)
    .then((response) => _get(response, 'data', []));
}

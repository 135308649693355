const style = {
  settings: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  settings__item: {},
  settings__templateSection: {
    width: '49%',
  },
  settings__baseSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  settings__advancedSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  settings__dynamicPoliciesSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  advancedSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  advancedSection__item: {
    flexBasis: '49%',
    width: '0px',
  },
  baseSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  baseSection__item: {
    flexBasis: '49%',
    width: '0px', // to value of drop-downs doesn't broke form structure
  },
  baseSection__item_long: {
    flexBasis: '100%',
    width: '0px', // to value of drop-downs doesn't broke form structure
  },
  baseSection__item_long_disabled: {
    display: 'none',
  },
};

export default style;

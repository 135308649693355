import {types} from './action';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.updatePasswordReducer, action) => {
  switch (action.type) {
    case types.ACTION_UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordUpdated: true,
      };
    }
    case types.ACTION_UPDATE_PASSWORD_FAILED: {
      return {
        ...state,
        isPasswordUpdated: false,
      };
    }
    case types.ACTION_UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        isPasswordUpdatingError: true,
      };
    }
    case types.RESET_UPDATE_PASSWORD_PAGE_DATA: {
      return {
        ...state,
        isPasswordUpdatingError: initState.updatePasswordReducer.isPasswordUpdatingError,
        isPasswordUpdated: initState.updatePasswordReducer.isPasswordUpdated,
      };
    }
    default:
      return state;
  }
};

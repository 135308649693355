const style = {
  confirmEmail: {
    textAlign: 'center',
  },
  confirmEmail__message: {
    margin: '10px 0 20px 0',
  },
};

export default style;

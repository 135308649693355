import {
  defaultFont,
  primaryColor,
  headerHeight,
} from '../../styles/style';

const style = {
  header: {
    backgroundColor: '#FFF',
    boxShadow: 'none',
    borderBottom: `1px solid ${primaryColor}`,
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '1029',
    color: primaryColor,
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    height: headerHeight,
    display: 'flex',
    justifyContent: 'center',
  },
  header__toolbar: {
    'padding': 'auto 15px',
    'minHeight': '50px',
    'display': 'flex',
    'justifyContent': 'space-between',
    '@media (max-width: 960px)': {
      justifyContent: 'flex-end',
    },
  },
  toolbar__sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: '#555555',
  },
  toolbar__left: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar__right: {
    display: 'flex',
  },
  toolbar__icon: {
    color: 'inherit',
    width: '20px',
    height: '17px',
  },
  header__title: {
    ...defaultFont,
    display: 'inline-block',
    margin: '0 0 0 25px',
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    flex: 1,
  },
};

export default style;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
  NetworkStatusIndicator,
  NetworkPolicyIpAddresses,
  Switch,
} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.SITE_WAN_CREATE],
};

class NetworkTable extends Component {
  constructor(props) {
    super(props);

    this.statusIndicatorCellRenderer = (row) => (
      <NetworkStatusIndicator networkStatus={row.value} />
    );
    this.checkedCellRenderer = (row) => (
      <Switch
        isChecked={row.value}
        disabled={true}
        switchClassName="switch_td"
      />
    );
    this.ipAddressCellRenderer = (row) => {
      let value = row.value;
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value.length > 1) {
        value = value.slice(0, 1);
        value.push('...');
      }
      return (
        <NetworkPolicyIpAddresses ipAddresses={value} />
      );
    };

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];
    this.headersFullWidthMode.push(
      {
        Header: I18n.t('campusPage.networksTab.nameHeader'),
        accessor: 'name',
        ...this.getFilter(),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.networksTab.statusHeader'),
        accessor: 'status',
        Cell: this.statusIndicatorCellRenderer,
        filterable: false,
        sortable: false,
        maxWidth: 70,
      },
      {
        Header: I18n.t('campusPage.networksTab.dynamicDnsHeader'),
        accessor: 'dynamicDns',
        filterable: false,
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.networksTab.publicIpAddressHeader'),
        accessor: 'publicIpAddress',
        Cell: this.ipAddressCellRenderer,
        filterable: false,
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.networksTab.blockPageHeader'),
        accessor: 'blockPage',
        filterable: false,
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.networksTab.policyHeader'),
        accessor: 'policy',
        filterable: false,
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.networksTab.stateHeader'),
        accessor: 'state',
        Cell: this.checkedCellRenderer,
        filterable: false,
        sortable: false,
        maxWidth: 70,
      },
    );
    this.headersCompressedMode.push(
      {
        Header: null,
        accessor: 'name',
        ...this.getFilter(),
        sortable: false,
      },
      {
        Header: null,
        accessor: 'status',
        Cell: this.statusIndikatorCellRenderer,
        filterable: false,
        sortable: false,
        maxWidth: 30,
      },
    );
  }

  getFilter = () => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t('campusPage.networksTab.networkNameFilterPlaceholder'),
      ['name'],
    )
  );

  render() {
    const {
      handleClickButtonNew,
      handleClickCampusRow,
      isEditMode,
      mode,
      tableData,
      selectedNetworkIndex,
    } = this.props;

    const networkTableStyles = {
      minHeight: '300px',
      height: 'auto',
      maxHeight: '800px',
    };

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('campusPage.networksTab.title')}
        entityType={I18n.t('entitiesTypes.network')}
        tableStyles={networkTableStyles}
        selectedEntityIndex={selectedNetworkIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickCampusRow}
        customCardClass="card_networks"
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

NetworkTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickCampusRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedNetworkIndex: PropTypes.number.isRequired,
};

NetworkTable.defaultProps = {
  tableData: [],
};

export default NetworkTable;

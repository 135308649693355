const style = {
  grid: {
    // flexWrap: 'nowrap',
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: 'unset',
  },
};

export default style;

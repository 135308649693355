import _cloneDeep from 'lodash/cloneDeep';

export function cloneSubnetInfo(subnet) {
  const newSubnet = _cloneDeep(subnet);
  newSubnet.ipAddressRange = getValidIpAddress(newSubnet.ipAddressRange);
  return newSubnet;
}

function getValidIpAddress(ip) {
  if (ip.length > 0) {
    return ip[0];
  }
  return '';
}

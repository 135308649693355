import {types} from './action';
import initState from '../../reducers/redux-initial-state';

import {getIpTableData} from './service';

export default (state = initState.ipListReducer, action) => {
  switch (action.type) {
    case types.SET_IPs: {
      return {
        ...state,
        ipTableData: getIpTableData(action.data.resolvers),
        anycastIPs: action.data.anycast,
      };
    }
    default:
      return state;
  }
};

import _includes from 'lodash/includes';
import _without from 'lodash/without';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

import {
  REG_EXR,
  TAGS,
} from '../../constants';

export function getWhiteBlackListsTableData(data) {
  return data.map((item) => ({
    name: item.name,
    description: '',
    tags: item.tags,
  }));
}

export function getWhiteBlackListToCreate(currentWhiteBlackList, newData) {
  const newWhiteBlackList = getWhiteBlackListToUpdate(currentWhiteBlackList, newData);
  delete newWhiteBlackList.id;
  return newWhiteBlackList;
}

export function getWhiteBlackListToUpdate(currentWhiteBlackList, newData) {
  return {
    id: newData.id,
    name: newData.name,
    description: newData.description,
    tags: addGlobalTag(newData.isGlobal, currentWhiteBlackList.tags),
    allowed: getDomainsAsArray(newData.allowed),
    blocked: getDomainsAsArray(newData.blocked),
  };
}

function addGlobalTag(isGlobalWhiteBlackList, tags) {
  if (_includes(tags, TAGS.GLOBAL_WHITE_BLACK_LIST)) {
    if (!isGlobalWhiteBlackList) {
      return _without(tags, TAGS.GLOBAL_WHITE_BLACK_LIST);
    }
  } else if (isGlobalWhiteBlackList) {
    return [...tags, TAGS.GLOBAL_WHITE_BLACK_LIST];
  }
  return tags;
}

function getDomainsAsArray(domainsAsString) {
  const domainsArray = domainsAsString.trim().split(REG_EXR.DOMAIN_SPLIT_PARAM);
  const result = [];
  domainsArray.forEach((item) => {
    const _trimItem = item.trim();
    if (!_isEqual(item.trim(), '')) {
      result.push(_trimItem);
    }
  });
  return result;
}

export function getUpdatedWhiteBlackListIndex(updatedWhiteBlackListId, whiteBlackLists) {
  return _findIndex(whiteBlackLists, (o) => _isEqual(o.id, updatedWhiteBlackListId));
}

const style = {
  selectableEntityList__listSection: {
    'display': 'flex',
    'flexDirection': 'row',
    'marginTop': '5px',
    '@media (max-width: 1250px)': {
      flexDirection: 'column',
    },
  },
  selectableEntityListSection__buttonSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectableEntityListElement__selectionButtonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default style;

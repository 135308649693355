import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {
  EntitiesListWithViewModesCard, LabelWithCopyButton,
} from '../index';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED_WIDTH,
  ACCESS_RIGHTS,
} from '../../constants';

import {hasAuthorities} from '../../utils/account';
import {formatDateTimeToUserTimezone} from '../../utils/dateTime';

import style from './style';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE],
};

class ApiKeysTable extends Component {
  constructor(props) {
    super(props);

    this.copyToBufferCellRenderer = (rowInfo) => (
      <LabelWithCopyButton
        id={rowInfo.original.id}
        text={rowInfo.original.apiKey}
        showHeader={false}
        showText={false}
        snackbarMessage={I18n.t('apiAccessManagementPage.table.copyToBufferMessage')}
      />
    );

    this.headersFullMode = [
      {
        Header: I18n.t('apiAccessManagementPage.table.headers.id'),
        accessor: 'id',
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('apiAccessManagementPage.table.headers.createdAt'),
        accessor: 'createdAt',
        Cell: (rowInfo) => formatDateTimeToUserTimezone(rowInfo.value),
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('apiAccessManagementPage.table.headers.lastAccessAt'),
        accessor: 'lastAccessAt',
        Cell: (rowInfo) => formatDateTimeToUserTimezone(rowInfo.value),
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('apiAccessManagementPage.table.headers.lastIp'),
        accessor: 'lastIp',
        Cell: (rowInfo) => ((!_isNil(rowInfo.value)) ? rowInfo.value : '-'),
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('apiAccessManagementPage.table.headers.description'),
        accessor: 'description',
        sortable: false,
        filterable: false,
      },
      {
        Header: '',
        Cell: this.copyToBufferCellRenderer,
        sortable: false,
        filterable: false,
      },
    ];

    this.headersCompressedMode = [
      {
        Header: I18n.t('apiAccessManagementPage.table.headers.id'),
        accessor: 'id',
        sortable: true,
        filterable: false,
      },
    ];
  }

  render() {
    const mode = this.props.isCompressed ? COMPRESSED_WIDTH : FULL_WIDTH;
    const handleClickNew = hasAuthorities('apiAccessManagement') ? this.props.handleClickNew : null;
    const tableClasses = {
      tableBody: this.props.classes.apiKeysTable__tableBody,
    };
    return (
      <EntitiesListWithViewModesCard
        customClasses={tableClasses}
        handleClickButtonNew={handleClickNew}
        mode={mode}
        headersFullWidthMode={this.headersFullMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={this.props.apiKeys}
        disabled={this.props.isEditing}
        title={I18n.t('apiAccessManagementPage.title')}
        entityType={I18n.t('entitiesTypes.apiKey')}
        handleClickRow={this.props.handleClickRow}
        selectedEntityIndex={this.props.selectedApiKeyIndex}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

ApiKeysTable.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  classes: PropTypes.object,
  handleClickRow: PropTypes.func.isRequired,
  isCompressed: PropTypes.bool.isRequired,
  selectedApiKeyIndex: PropTypes.number.isRequired,
  handleClickNew: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

ApiKeysTable.defaultProps = {
  classes: {},
};

export default withStyles(style)(ApiKeysTable);

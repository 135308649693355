import _cloneDeep from 'lodash/cloneDeep';
import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.clientProfileEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_CLIENT_PROFILE_INFO: {
      const newState = _cloneDeep(state);
      newState.settings.editableProfileInfo = _cloneDeep(action.profile);
      return newState;
    }
    case types.RESET_CLIENT_PROFILE_EDIT_FORM_DATA: {
      return _cloneDeep(initState.clientProfileEditFormReducer);
    }
    case types.SET_PROFILE_FROM_TEMPLATE: {
      const newState = _cloneDeep(state);
      newState.settings.editableProfileInfo = _cloneDeep(action.template);
      return newState;
    }

    case types.SET_SELECTED_CLIENT_PROFILE: {
      const newState = _cloneDeep(state);
      newState.settings.selectedProfile = action.profile;
      newState.settings.selectedProfileTemplateIndex =
        initState.clientProfileEditFormReducer.settings.selectedProfileTemplateIndex;
      return newState;
    }
    case types.SET_VALIDATION_CLIENT_PROFILE_INFO: {
      return {
        ...state,
        isValidProfileInfo: action.isValid,
      };
    }
    case types.UPDATE_CLIENT_PROFILE_INFO: {
      const newState = _cloneDeep(state);
      newState.settings.editableProfileInfo = action.profile;
      newState.isValidProfileInfo = action.isValidProfileInfo;
      return newState;
    }
    case types.SET_SELECTED_CLIENT_PROFILE_TEMPLATE_INDEX: {
      const newState = _cloneDeep(state);
      newState.settings.selectedProfileTemplateIndex = action.profileIndex;
      return newState;
    }
    case types.RESET_SELECTED_CLIENT_PROFILE_TEMPLATE: {
      const newState = _cloneDeep(state);
      newState.settings.selectedProfileTemplateIndex =
        initState.clientProfileEditFormReducer.settings.selectedProfileTemplateIndex;
      return newState;
    }

    default:
      return state;
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';

import {
  NotifyAfterActionWithPassword,
  RenderOrEmpty,
  ResetPasswordForm,
} from '../../components';

import {
  resetPageData,
  resetPassword,
} from './action';

import {
  ROUTES,
} from '../../constants';

class ResetPassword extends Component {
  componentWillUnmount() {
    this.props.resetPageData();
  }

  goToLoginPage = () => {
    this.props.history.push(ROUTES.LOGIN);
  };

  render() {
    const {isPasswordReset} = this.props;
    return (
      <>
        <RenderOrEmpty isShow={!isPasswordReset}>
          <ResetPasswordForm
            resetPassword={this.props.resetPassword}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={isPasswordReset}>
          <NotifyAfterActionWithPassword
            buttonText={I18n.t('resetPasswordPage.buttonOk')}
            onClick={this.goToLoginPage}
            isAuth={true}
            text={I18n.t('resetPasswordPage.notifyAboutSentEmail')}
          />
        </RenderOrEmpty>
      </>
    );
  }
}

ResetPassword.propTypes = {
  isPasswordReset: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  resetPageData: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {};

const mapStateToProps = (state) => ({
  isPasswordReset: state.resetPasswordReducer.isPasswordReset,
});

const mapDispatchToProps = (dispatch) => ({
  resetPageData: bindActionCreators(resetPageData, dispatch),
  resetPassword: bindActionCreators(resetPassword, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));

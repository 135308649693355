import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {getOrganizationToCreate} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {types, widgetKeys} from './constants';

export function getOrganizations() {
  const url = makeUrl(METHODS.GET_ORGANIZATIONS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_ORGANIZATIONS,
          data: response.data,
        });
      }
    });
}

export function getOrganizationById(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_BY_ID, {id: organizationId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_SELECTED_ORGANIZATION,
          data,
        });
      }
    });
}

export function createOrganization(newOrganization) {
  const url = makeUrl(METHODS.CREATE_ORGANIZATION);
  const data = getOrganizationToCreate(newOrganization);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndOrganizationState(false, STATES_ENTITY.NONE));
        dispatch(updateOrganizationPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to create organization:', error);
    });
}

export function updateOrganization(updatedOrganization) {
  const url = makeUrl(METHODS.UPDATE_ORGANIZATION);
  const data = updatedOrganization;
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndOrganizationState(false, STATES_ENTITY.NONE));
        dispatch(getOrganizations()).then(() => dispatch({
          type: types.SET_UPDATED_ORGANIZATION,
          data: response.data,
        }));
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to edit organization:', error);
    });
}

export function deleteOrganization(organizationId) {
  const data = {
    id: organizationId,
  };
  const url = makeUrl(METHODS.DELETE_ORGANIZATION);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updateOrganizationPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to delete organization:', error);
    });
}
export function resetOrganizations() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_ORGANIZATIONS,
    });
  };
}

export function changeEditModeAndOrganizationState(isEditMode, organizationState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_ORGANIZATION_STATE,
      isEditMode,
      organizationState,
    });
  };
}

export function getDataToShowOrganizationDetails(organizationId, organizationIndex) {
  return (dispatch) => {
    dispatch(getOrganizationById(organizationId));
    dispatch(setSelectedOrganizationIndex(organizationIndex));
  };
}

export function getOrganizationsTableData(organizationsStats) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ORGANIZATIONS_TABLE_DATA,
      organizationsStats,
    });
  };
}

export function setSelectedOrganizationIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_ORGANIZATION_INDEX,
      selectedOrganizationIndex: index,
    });
  };
}

export function resetSelectedOrganizationIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_ORGANIZATION_INDEX,
    });
  };
}

export function resetPageData() {
  return (dispatch) => {
    dispatch(resetSelectedOrganizationIndex());
  };
}

export function getOrganizationNetworksStats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_NETWORK_STATS, queryParams);
  return (dispatch) => {
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_NETWORKS_STATS,
            data,
          });
        } else {
          dispatch({
            type: types.SET_ORGANIZATION_NETWORKS_STATS,
            data: {},
          });
        }
      });
  };
}

export function updateOrganizationPageData(statsWidgetKey = widgetKeys.ORGANIZATION_NETWORK_STATS) {
  return (dispatch) => {
    dispatch(getOrganizations());
    dispatch(getOrganizationNetworksStats({}, statsWidgetKey));
  };
}

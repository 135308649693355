import {
    dangerColor,
    successColor,
    defaultFont,
  } from '../../material-dashboard-pro-react';
  
  const customAutocompleteStyle = {
    labelRoot: {
      ...defaultFont,
      color: '#AAAAAA',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '1.42857',
      transition: '0.3s ease all'
    },
    labelRootError: {
      color: dangerColor,
    },
    labelRootSuccess: {
      color: successColor,
    },
    formControl: {
      paddingBottom: '10px'
    }
  };
  
  export default customAutocompleteStyle;
  
import {types} from './constants';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.filtersReducer, action) => {
  switch (action.type) {
    case types.UPDATE_FILTER: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case types.REINIT_FILTERS: {
      return {
        ...state,
        ...action.data,
      };
    }

    default:
      return state;
  }
};

import _get from 'lodash/get';
import {getValidData} from '../../utils/dashboard';

export function injectNetworkStats(sites, stats) {
  return sites.map((site) => {
    const netStats = _get(stats, site.id, {});
    return {
      ...site,
      wansUp: _get(netStats, 'up', 0),
      wansDown: _get(netStats, 'down', 0),
    };
  });
}

export function injectSiteStats(sites, stats) {
  return sites.map((site) => {
    const requestStats = _get(stats, `decisionsByLocation.${site.id}`, {});
    const requestsAllowed = _get(requestStats, 'allowed', 0);
    const requestsBlocked = _get(requestStats, 'blocked', 0);
    return {
      ...site,
      queriesPerSecond: parseInt(
        getValidData(
          _get(stats, `requestsPerSecondByLocation.${site.id}`, 0.0),
        ) * 100,
        10) / 100,
      requestsAllowed,
      requestsBlocked,
      requestsTotal: requestsAllowed + requestsBlocked,
    };
  });
}

export function getSiteIds(sites) {
  return sites.map((site) => site.id);
}

export function getOrganizationSitesData(data) {
  return {
    ...data,
    sitesInOrganization: data.locationsInOrganization,
    sitesNotInOrganization: data.locationsNotInOrganization,
  };
}

const style = {
  clientDashboard: {
  },
  clientDashboard__statsPanel: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'flexWrap': 'wrap',
    '@media(max-width: 1300px)': {
      flexDirection: 'column',
      marginTop: '10px',
    },
  },

  statsPanel__block: {
    'marginBottom': '10px',
    '@media(max-width: 1300px)': {
      width: '100%',
    },
  },
  statsPanel__block_left: {
    'flexBasis': '34%',
    'display': 'flex',
    'justifyContent': 'space-between',
    'minWidth': '220px',
    '@media(max-width: 786px)': {
      flexDirection: 'column',
    },
    '@media(min-width: 1301px)': {
      flexDirection: 'column',
    },
  },
  statsPanel__block_right: {
    flexBasis: '65%',
  },
  statsPanel__block_bottom: {
    display: 'none',
    flexBasis: '100%',
  },
  statsPanel__item_graph: {
    flexBasis: '100%',
  },
  statsPanel__card: {
    flexBasis: '48.5%',
  },
};

export default style;

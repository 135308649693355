import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {
  ButtonNew, InfiniteTable, RenderOrEmpty,
} from '../index';

import {RightAvailability} from '../../containers/index';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  ACCESS_RIGHTS,
} from '../../constants';

import style from '../../styles/components/cards';

class InfiniteListWithViewModelsCard extends Component {
  get headerActions() {
    const {
      additionalHeaderActions,
      entityType,
      handleClickButtonNew,
      disabled,
      accessRightsButtonNew,
    } = this.props;
    return (
      <>
        {additionalHeaderActions}
        <RenderOrEmpty isShow={!!handleClickButtonNew}>
          <RightAvailability accessRights={accessRightsButtonNew}>
            <ButtonNew
              customClasses="cardActions__button"
              disabled={disabled}
              entityType={entityType.toUpperCase()}
              onClick={handleClickButtonNew}
            />
          </RightAvailability>
        </RenderOrEmpty>
      </>
    );
  }

  getHeaders = () => {
    const {
      mode,
      headersFullWidthMode,
      headersCompressedMode,
    } = this.props;
    const headersByMode = {
      [FULL_WIDTH]: headersFullWidthMode,
      [COMPRESSED]: headersCompressedMode,
    };
    return headersByMode[mode];
  };

  // getTableStyles = () => {
  //   const {tableStyles} = this.props;
  //   if (Object.keys(tableStyles).length > 0) {
  //     return tableStyles;
  //   }
  //   return {
  //     height: 'calc(100vh - 150px)',
  //   };
  // };

  render() {
    const {
      classes,
      customCardClass,
      classNamesObj,
      handleClickRow,
      disabled,
      selectable,
      tableData,
      title,
      selectedEntityIndex,
      fullscreen,
      tableStyles,
      minRows,
      loadData,
      dataWithSelect,
      handleChangeDataWithSelect,
      selectAll,
      handleChangeSelectAll,
    } = this.props;

    return (
      <Card
        className={classNames(
          classes.card,
          classNamesObj.card,
          {
            [classes[customCardClass]]: !_isUndefined(customCardClass),
            [classes.card_disabled]: disabled,
            [classes.card_fullScreen]: fullscreen,
          },
        )}
      >
        <CardHeader
          classes={classNamesObj.header}
          title={title}
          action={this.headerActions}
        />
        <CardContent
          className={classNamesObj.card__content}
        >
          <InfiniteTable
            columns={this.getHeaders()}
            data={tableData}
            dataWithSelect={dataWithSelect}
            selectAll={selectAll}
            handleChangeSelectAll={handleChangeSelectAll}
            handleChangeDataWithSelect={handleChangeDataWithSelect}
            loadData={loadData}
            handleClickRow={handleClickRow}
            style={tableStyles}
            selectable={selectable}
            classNamesObj={classNamesObj}
            className={classes.table}
            minRows={minRows}
            selectedRowIndices={[selectedEntityIndex]}
          />
        </CardContent>
      </Card>
    );
  }
}

InfiniteListWithViewModelsCard.propTypes = {
  additionalHeaderActions: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.object.isRequired,
  classNamesObj: PropTypes.object,
  customCardClass: PropTypes.string,
  entityType: PropTypes.string,
  headersFullWidthMode: PropTypes.array,
  headersCompressedMode: PropTypes.array,
  handleClickButtonNew: PropTypes.func,
  handleClickRow: PropTypes.func,
  disabled: PropTypes.bool,
  minRows: PropTypes.number,
  mode: PropTypes.oneOf([FULL_WIDTH, COMPRESSED]).isRequired,
  tableData: PropTypes.array,
  dataWithSelect: PropTypes.array,
  handleChangeDataWithSelect: PropTypes.func,
  tableStyles: PropTypes.object,
  title: PropTypes.string,
  selectedEntityIndex: PropTypes.number,
  fullscreen: PropTypes.bool,
  accessRightsButtonNew: PropTypes.array,
  selectable: PropTypes.bool,
  selectAll: PropTypes.bool,
  handleChangeSelectAll: PropTypes.func,
  loadData: PropTypes.func.isRequired,
};

InfiniteListWithViewModelsCard.defaultProps = {
  additionalHeaderActions: [],
  classNamesObj: {},
  customCardClass: undefined,
  entityType: '',
  headersFullWidthMode: [],
  headersCompressedMode: [],
  disabled: false,
  minRows: 0,
  tableData: [],
  dataWithSelect: [],
  handleChangeDataWithSelect: () => {},
  tableStyles: {},
  title: '',
  selectedEntityIndex: -1,
  handleClickButtonNew: null,
  handleClickRow: () => {},
  fullscreen: true,
  accessRightsButtonNew: [ACCESS_RIGHTS.CREATE],
  selectable: false,
  selectAll: false,
  handleChangeSelectAll: () => {},
};

export default withStyles(style)(InfiniteListWithViewModelsCard);

import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {LoginFormSSO} from '../../components';

import {loginWithSSO} from '../../actions/auth/login';

function LoginSSO(props) {
  const {
    authWithSSO,
    isLoggedInFailed,
  } = props;

  return (
    <LoginFormSSO
        loginWithSSO={authWithSSO}
        isLoggedInFailed={isLoggedInFailed}
    />
  );
}

LoginSSO.propTypes = {
  authWithSSO: PropTypes.func.isRequired,
  isLoggedInFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedInFailed: state.authReducer.isLoggedInFailed,
});

const mapDispatchToProps = (dispatch) => ({
  authWithSSO: bindActionCreators(loginWithSSO, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSSO);

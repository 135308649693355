import {
  get,
  makeUrl,
} from '../../../utils/fetcher';

import {METHODS} from '../../../constants';
import {setUserOrganization} from '../../../actions/userOrganizations/switchOrganization';

export const types = {
  OPEN_MODAL_WINDOW: 'OPEN_MODAL_WINDOW',
  CLOSE_MODAL_WINDOW: 'CLOSE_MODAL_WINDOW',
  SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
  SET_ORGANIZATION_INFO: 'SET_ORGANIZATION_INFO',
  SET_POLICIES: 'SET_POLICIES',
};

export function getUserInfo() {
  const url = makeUrl(METHODS.GET_USER_INFO);
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ACCOUNT_INFO,
            data: data.account,
          });
          dispatch(setUserOrganization(data.organization));
        } else {
          throw new Error();
        }
      });
}

export function openModalWindow(data, notifyType) {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_MODAL_WINDOW,
      data,
      notifyType,
    });
  };
}

export function closeModalWindow() {
  return (dispatch) => {
    dispatch({
      type: types.CLOSE_MODAL_WINDOW,
    });
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';
import _findIndex from 'lodash/findIndex';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {
  Dropdown,
  Switch,
} from '../../components/index';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {
  setEditableSubnetInfo,
  setValidationSubnetInfo,
  resetSubnetEditFormData,
  updateSubnetInfo,
} from './action';

import {STATES_ENTITY} from '../../constants';

import style from './style';

class SubnetEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
      ipAddressRange: true,
    },
  };

  static shouldUpdateSubnetObject(prevProps, props) {
    return !_isEqual(prevProps.subnet, props.subnet);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.subnetState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = SubnetEditForm.initialLocalState;
  }

  componentDidMount() {
    this.props.setEditableSubnetInfo({
      ...this.props.subnet,
      policyName: I18n.t('campusPage.subnetsTab.networkDefaultPolicyName'),
      policyId: null,
      networkName: I18n.t('campusPage.subnetsTab.allWANsOptionLabel'),
      networkId: null,
    });
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.subnet, nextProps.subnet)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableSubnetInfo, nextProps.editableSubnetInfo);
  }

  componentDidUpdate(prevProps) {
    if (SubnetEditForm.shouldUpdateSubnetObject(prevProps, this.props)
      || SubnetEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.setEditableSubnetInfo(this.props.subnet);
    }
    if (SubnetEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetSubnetEditFormData();
  }

  getSelectedNetworkIndex = () => {
    const {editableSubnetInfo, networks} = this.props;

    if (_isNull(editableSubnetInfo.networkId)
      || _isEqual('', editableSubnetInfo.networkId)) {
      return 0;
    }
    return _findIndex(
      networks,
      (item) => _isEqual(item.id, editableSubnetInfo.networkId),
    ) + 1;
  };

  getSelectedPolicyIndex = () => {
    const {editableSubnetInfo, policies} = this.props;

    if (_isNull(editableSubnetInfo.policyId)
      || _isEqual('', editableSubnetInfo.policyId)) {
      return 0;
    }
    return _findIndex(
      policies,
      (item) => _isEqual(item.id, editableSubnetInfo.policyId),
    ) + 1;
  };

  getNetworksOptionsWithAllWAN = () => [
    I18n.t('campusPage.subnetsTab.allWANsOptionLabel'),
    ...this.props.networks.map((network) => network.name),
  ];

  getPoliciesOptionsWithDefault = () => [
    I18n.t('campusPage.subnetsTab.networkDefaultPolicyName'),
    ...this.props.policies.map((policy) => policy.name),
  ];

  resetValidation = () => {
    this.props.setValidationSubnetInfo(true);
    this.setState(SubnetEditForm.initialLocalState);
  };

  validate = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
      {
        name: 'ipAddressRange',
        validationRules: [isNotEmpty],
      },
    ],
    data,
    {
      name: true,
      ipAddressRange: true,
    },
  );

  handleCheckboxChange = (e, checked) => {
    const {editableSubnetInfo} = this.props;
    this.props.updateSubnetInfo(
      {
        ...editableSubnetInfo,
        [e.target.name]: checked,
      },
      true,
    );
  };

  handleInputChange = (e) => {
    const {editableSubnetInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableSubnetInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateSubnetInfo(
      {
        ...editableSubnetInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeSubnetPolicy = (e) => {
    const {
      editableSubnetInfo,
      policies,
    } = this.props;
    const index = e.target.value;
    if (index === 0) {
      this.props.updateSubnetInfo(
        {
          ...editableSubnetInfo,
          policyName: I18n.t('campusPage.subnetsTab.networkDefaultPolicyName'),
          policyId: null,
        },
        true,
      );
    } else {
      this.props.updateSubnetInfo(
        {
          ...editableSubnetInfo,
          policyName: policies[index - 1].name,
          policyId: policies[index - 1].id,
        },
        true,
      );
    }
  };

  handleChangeNetwork = (e) => {
    const {
      editableSubnetInfo,
      networks,
    } = this.props;
    const index = e.target.value;
    if (index === 0) {
      this.props.updateSubnetInfo(
        {
          ...editableSubnetInfo,
          networkName: I18n.t('campusPage.subnetsTab.allWANsOptionLabel'),
          networkId: null,
          forAllNetworks: true,
        },
        true,
      );
    } else {
      this.props.updateSubnetInfo(
        {
          ...editableSubnetInfo,
          networkName: networks[index - 1].name,
          networkId: networks[index - 1].id,
          forAllNetworks: false,
        },
        true,
      );
    }
  };

  render() {
    const {
      classes,
      editableSubnetInfo,
      isEditMode,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      name: {
        disabled: !isEditMode,
        value: editableSubnetInfo.name,
        name: 'name',
        onChange: this.handleInputChange,
        type: 'text',
      },
      ipAddressRange: {
        disabled: !isEditMode,
        value: editableSubnetInfo.ipAddressRange,
        name: 'ipAddressRange',
        onChange: this.handleInputChange,
        type: 'text',
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <Dropdown
            customSelectClass="dropdown__select_networkSettingsTab"
            disabled={!isEditMode}
            dropdownName="network"
            fullWidth={true}
            label={I18n.t('campusPage.subnetsTab.networksLabel')}
            onChangeValue={this.handleChangeNetwork}
            options={this.getNetworksOptionsWithAllWAN()}
            selectedItemIndex={this.getSelectedNetworkIndex()}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.name
              ? undefined
              : I18n.t('campusPage.subnetsTab.errorMessages.subnetNameIsNotValid')}
            error={!validation.name}
            labelText={I18n.t('campusPage.subnetsTab.subnetNameLabel')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.name}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.ipAddressRange
              ? undefined
              : I18n.t('campusPage.subnetsTab.errorMessages.ipIsNotValid')}
            error={!validation.ipAddressRange}
            labelText={I18n.t('campusPage.subnetsTab.subnetNetAddressLabel')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.ipAddressRange}
          />
        </div>
        <div className={classes.form__item}>
          <Dropdown
            customSelectClass="dropdown__select_policySettingsTab"
            disabled={!isEditMode}
            dropdownName="policy"
            fullWidth={true}
            label={I18n.t('campusPage.subnetsTab.subnetPolicyLabel')}
            onChangeValue={this.handleChangeSubnetPolicy}
            options={this.getPoliciesOptionsWithDefault()}
            selectedItemIndex={this.getSelectedPolicyIndex()}
          />
        </div>
        <div className={classes.form__item}>
          <Switch
            disabled={!isEditMode}
            isChecked={editableSubnetInfo.enabled}
            label={I18n.t('campusPage.subnetsTab.subnetStateLabel')}
            name="enabled"
            onChange={this.handleCheckboxChange}
          />
        </div>
      </div>
    );
  }
}

SubnetEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  editableSubnetInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  subnet: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  networks: PropTypes.array.isRequired,

  setEditableSubnetInfo: PropTypes.func.isRequired,
  setValidationSubnetInfo: PropTypes.func.isRequired,
  resetSubnetEditFormData: PropTypes.func.isRequired,
  updateSubnetInfo: PropTypes.func.isRequired,
};

SubnetEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.campusReducer.policies,
  subnet: state.campusSubnetsTabReducer.selectedSubnet,
  subnetState: state.campusSubnetsTabReducer.subnetState,
  isEditMode: state.campusSubnetsTabReducer.isEditMode,
  editableSubnetInfo: state.subnetEditFormReducer.editableSubnetInfo,
  networks: state.campusNetworksTabReducer.networks,
});

const mapDispatchToProps = (dispatch) => ({
  setEditableSubnetInfo: bindActionCreators(setEditableSubnetInfo, dispatch),
  setValidationSubnetInfo: bindActionCreators(setValidationSubnetInfo, dispatch),
  resetSubnetEditFormData: bindActionCreators(resetSubnetEditFormData, dispatch),
  updateSubnetInfo: bindActionCreators(updateSubnetInfo, dispatch),
});

const SubnetEditFormWithStyles = withStyles(style)(SubnetEditForm);
export default connect(mapStateToProps, mapDispatchToProps)(SubnetEditFormWithStyles);

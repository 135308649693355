import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import {
  ConfirmationNumber,
  Email,
  LocalLibrary,
  Help,
  Security,
  Server,
  Build,
} from '../../icons/index';
import {RenderOrEmpty} from '../index';
import {LINKS, ROUTES, ACCESS_RIGHTS} from '../../constants';
import {RightAvailability} from '../../containers';

import ActionTemplate from './ActionTemplate';

import style from './style';

class HelpAndSupportHeaderAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  getMenuItems = (data) => {
    const {classes} = this.props;
    return data.map((item) => (
      <RightAvailability
        key={`key-${item.name}`}
        accessRights={item.accessRights !== undefined ? item.accessRights : []}
      >
        <RenderOrEmpty isShow={item.isAppPage}>
          <Link
            href={ROUTES.DEFAULT}
            target=""
            to={item.to}
          >
            <MenuItem className={classes.dropdown__item}>
              {item.icon}
              {item.text}
            </MenuItem>
          </Link>
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!item.isAppPage}>
          <a href={item.to} target="_blank" rel="noreferrer">
            <MenuItem className={classes.dropdown__item}>
              {item.icon}
              {item.text}
            </MenuItem>
          </a>
        </RenderOrEmpty>
      </RightAvailability>
    ));
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  render() {
    const {classes} = this.props;
    const {isOpen} = this.state;
    const menuItemsData = [
      {
        to: LINKS.SYSTEM_STATUS,
        name: 'systemStatus',
        isAppPage: false,
        text: I18n.t('headerUser.helpAndSupportOption.systemStatus'),
        icon: <Help className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE],
      },
      {
        to: LINKS.SETUP_GUIDE,
        name: 'setupPage',
        isAppPage: false,
        text: I18n.t('headerUser.helpAndSupportOption.setupGuide'),
        icon: <LocalLibrary className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE],
      },
      {
        to: LINKS.KNOWLEDGE_BASE,
        name: 'knowledgeBase',
        isAppPage: false,
        text: I18n.t('headerUser.helpAndSupportOption.knowledgeBase'),
        icon: <Email className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE],
      },
      {
        to: LINKS.OPEN_TICKET,
        name: 'openTicket',
        isAppPage: false,
        text: I18n.t('headerUser.helpAndSupportOption.openTicket'),
        icon: <ConfirmationNumber className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE],
      },
      {
        to: ROUTES.IPs_LIST,
        name: 'ipsList',
        isAppPage: true,
        text: I18n.t('headerUser.helpAndSupportOption.ipList'),
        icon: <Server className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE],
      },
      {
        to: ROUTES.ROOT_CERTIFICATE,
        name: 'rootCertificate',
        isAppPage: true,
        text: I18n.t('headerUser.helpAndSupportOption.rootCertificate'),
        icon: <Security className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE],
      },
      {
        to: ROUTES.DOWNLOAD_RELAY,
        name: 'downloadRelay',
        isAppPage: true,
        text: I18n.t('headerUser.helpAndSupportOption.downloadRelay'),
        icon: <Build className={classes.dropdownItem__icon} />,
        accessRights: [ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE],
      },
    ];
    return (
      <ActionTemplate
        actionName={I18n.t('headerUser.helpAndSupportOption.name')}
        classes={classes}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        icon={<Help className={classes.links} />}
        isOpen={isOpen}
      >
        <Paper className={classes.dropdown}>
          <div
            className={classNames(
              classes.dropdown__header,
              classes.dropdown__header_helpAndSupport,
            )}
          >
            {I18n.t('headerUser.helpAndSupportOption.name')}
          </div>
          <MenuList
            className={classes.action__menuList}
            role="menu"
          >
            {this.getMenuItems(menuItemsData)}
          </MenuList>
        </Paper>
      </ActionTemplate>
    );
  }
}

HelpAndSupportHeaderAction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(HelpAndSupportHeaderAction);

import initState from '../../../reducers/redux-initial-state';
import {types} from './constants';
import {
  convertThreatNames,
  getAllowedBlockedNetworkChartData,
  getNetworksStatsAndRequestsPerSecondStats,
  getPieChartData,
  getRequestsPerNetworkTableData,
  getTopCategoriesTableData,
  getTopDomainsTableData,
  getValidData,
} from '../../../utils/dashboard';

export default (state = initState.dashboardReducer, action) => {
  switch (action.type) {
    case types.SET_AVERAGE_LATENCY: {
      return {
        ...state,
        averageLatency: parseInt(getValidData(action.data), 10),
      };
    }
    case types.SET_CATEGORY_GROUPS_STATS: {
      return {
        ...state,
        blocksStats: action.data,
      };
    }
    case types.SET_CAMPUSES_STATS: {
      const networksStatsAndRequestsPerSecondsStats = getNetworksStatsAndRequestsPerSecondStats(
        action.data,
        action.campusId,
      );
      return {
        ...state,
        campusesStats: action.data,
        networksStats: networksStatsAndRequestsPerSecondsStats.networksStats,
        requestsPerSecond: networksStatsAndRequestsPerSecondsStats.requestsPerSecond,
      };
    }
    case types.SET_REQUESTS_PER_NETWORK: {
      return {
        ...state,
        requestsPerNetwork: {
          ...state.requestsPerNetwork,
          tableData: getRequestsPerNetworkTableData(action.data),
        },
      };
    }
    case types.SET_TOP_5_CATEGORIES: {
      return {
        ...state,
        top5Categories: {
          ...state.top5Categories,
          [action.decision]: {
            tableData: getTopCategoriesTableData(action.data),
            chartData: getPieChartData(action.data),
          },
        },
      };
    }
    case types.SET_TOP_5_DOMAINS: {
      return {
        ...state,
        top5Domains: {
          ...state.top5Domains,
          [action.decision]: {
            tableData: getTopDomainsTableData(action.data),
          },
        },
      };
    }
    case types.SET_TOTAL_REQUEST_BY_DECISION: {
      return {
        ...state,
        requestsByDecision: {
          ...state.requestsByDecision,
          [action.decision]: getValidData(action.data),
        },
      };
    }
    case types.SET_USER_REQUESTS_GRAPH: {
      return {
        ...state,
        userRequestsGraph: {
          chartData: getAllowedBlockedNetworkChartData(
            action.data,
            action.period,
          ),
        },
      };
    }
    case types.SET_DASHBOARD_CLIENT_COUNT_STATS: {
      return {
        ...state,
        clientCountStats: action.stats,
      };
    }

    case types.SET_DASHBOARD_CLIENT_THREATS: {
      return {
        ...state,
        threats: convertThreatNames(action.data),
      };
    }
    default:
      return state;
  }
};

import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.updateFirstAndLastNameReducer, action) => {
  switch (action.type) {
    case types.SET_OLD_FIRST_AND_LAST_NAME: {
      const {firstName, lastName} = action;
      return {
        ...state,
        oldFirstName: firstName,
        oldLastName: lastName,
      };
    }
    default: {
      return state;
    }
  }
};

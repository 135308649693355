import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Clear} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

import {EntitiesListWithViewModesCard} from '../index';
import {LoadingLayout} from '../../layouts-common';
import {widgetKeys} from '../../containers/OrganizationSitesTab/constants';
import {ACCESS_RIGHTS} from '../../constants';
import {RightAvailability} from '../../containers/index';

import style from './style';

const rightAvailabilityMap = {
  buttonOrganizationSiteUnlink: [ACCESS_RIGHTS.ORGANIZATION_SITE_LINK],
};

class OrganizationSitesTable extends Component {
  constructor(props) {
    super(props);
    this.actionsCellRenderer = (row) => (
      <RightAvailability accessRights={rightAvailabilityMap.buttonOrganizationSiteUnlink}>
        <div
          className={this.props.classes.organizationSitesTable__cell_action}
          onClick={() => this.props.removeSiteFromOrganization(row.index)}
        >
          <Clear />
        </div>
      </RightAvailability>
    );
  }

  get fullScreenColumns() {
    return [
      {
        Header: I18n.t('organizations.sites.table.headers.sites'),
        accessor: 'name',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.wansUp'),
        accessor: 'wansUp',
        minWidth: 80,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.wansDown'),
        accessor: 'wansDown',
        minWidth: 80,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.queriesPerSecond'),
        accessor: 'queriesPerSecond',
        minWidth: 50,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.requestsAllowed'),
        accessor: 'requestsAllowed',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.requestsBlocked'),
        accessor: 'requestsBlocked',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.requestsTotal'),
        accessor: 'requestsTotal',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('organizations.sites.table.headers.actions'),
        accessor: 'actions',
        Cell: this.actionsCellRenderer,
        minWidth: 50,
        filterable: false,
        sortable: false,
        isShow: true,
      },
    ];
  }

  render() {
    const {
      classes,
      mode,
      isEditMode,
      tableData,
    } = this.props;

    return (
      <LoadingLayout widgetKey={widgetKeys.ORGANIZATION_SITES_STATS}>
        <EntitiesListWithViewModesCard
          mode={mode}
          headersFullWidthMode={this.fullScreenColumns}
          tableData={tableData}
          disabled={isEditMode}
          customClasses={classes}
          fullscreen={false}
          componentHeaderHidden={true}
        />
      </LoadingLayout>
    );
  }
}

OrganizationSitesTable.propTypes = {
  classes: PropTypes.object,
  isEditMode: PropTypes.bool,
  mode: PropTypes.string,
  tableData: PropTypes.array,
  tableStyles: PropTypes.object,
  removeSiteFromOrganization: PropTypes.func.isRequired,
};

OrganizationSitesTable.defaultProps = {
  classes: {},
  isEditMode: false,
  mode: '',
  tableData: [],
  tableStyles: {},
};

export default withStyles(style)(OrganizationSitesTable);

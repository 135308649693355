import {
  greenColor,
  redColor,
  primaryColor,
} from '../../styles/style';

const style = {
  'statsCell': {
    textAlign: 'center',
    transform: 'translateX(-30%)',
  },
  'up': {
    color: greenColor,
  },
  'down': {
    color: redColor,
  },
  'total': {
    color: primaryColor,
  },
  'online': {
    color: greenColor,
  },
  'offline': {
    color: redColor,
  },
  'requestsPerSecond': {
    color: primaryColor,
  },
  '@media(max-width: 1200px)': {
    statsCell: {
      transform: 'unset',
    },
  },
};

export default style;

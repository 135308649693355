import _get from 'lodash/get';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {CardWithHeader, Switch} from '../index';
import {CustomInput} from '../../material-dashboard-pro-react/components';
import style from './style';

class NotificationProfileForm extends React.Component {
  static notificationProfileFields = [
    'email',
    'description',
    'notifications',
  ];

  static initState() {
    return NotificationProfileForm.notificationProfileFields
      .reduce((res, fieldName) => ({
        ...res,
        [fieldName]: false,
      }), {});
  }

  state = {
    hasFieldChanged: NotificationProfileForm.initState(),
  };

  onChange = (e) => {
    const fieldName = e.target.name;
    this.setState((prevState) => ({
      hasFieldChanged: {
        ...prevState.hasFieldChanged,
        [fieldName]: true,
      },
    }));
    this.props.onChange(fieldName, e.target.value);
  };

  get notificationProfileInfo() {
    const {notificationProfile, isEditing} = this.props;
    const fields = NotificationProfileForm.notificationProfileFields;

    return fields.filter((f) => f !== 'notifications').map((f) => {
      const value = _get(notificationProfile, f, '');
      const inputProps = {
        disabled: !isEditing,
        onChange: this.onChange,
        value,
        name: f,
        type: 'text',
        fullWidth: true,
      };
      const error = this.getError(f);

      return (
        <CustomInput
          labelText={I18n.t(`notificationsManagementPage.notificationProfileInfo.${f}`)}
          id={f}
          key={f}
          inputProps={inputProps}
          {...error}
        />
      );
    });
  }

  get notificationProfileSwitches() {
    return this.props.notificationTypes.map((notificationType) => {
      const switchesForType = notificationType.subtypes
        .map((subtype) => this.getSwitch(
          subtype,
          this.props.notificationProfile.notifications.includes(subtype),
        ));

      return (
        <CardWithHeader
          key={notificationType.type}
          cardTitle={I18n.t(`notificationsManagementPage.types.${notificationType.type}`)}
          cardTitleColor="primary"
          content={switchesForType}
          customCardClasses="card_notificationType"
          customCardContentClasses="card__content_notificationType"
        />
      );
    });
  }

  getSwitch = (subtype, active) => {
    const label = I18n.t(`notificationsManagementPage.subtypes.${subtype}`);
    return (
      <Switch
        key={subtype}
        label={label}
        disabled={!this.props.isEditing}
        isChecked={active}
        labelClassName="switch__label_oneLine"
        labelFormClassName="formControl_oneLine"
        name={subtype}
        onChange={() => this.props.onSwitchToggle(subtype, !active)}
      />
    );
  };

  getError = (field) => {
    const isValid = _get(this.props.validationState, field, true);
    const hasChanged = _get(this.state.hasFieldChanged, field, false);

    if (!isValid && hasChanged) {
      const errorText = I18n
        .t(`notificationsManagementPage.newNotificationProfile.errorMessages.${field}`);
      return {
        error: true,
        errorText,
      };
    }

    return {};
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.form}>
        {this.notificationProfileInfo}
        {this.notificationProfileSwitches}
      </div>
    );
  }
}

NotificationProfileForm.propTypes = {
  notificationProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,

  notificationTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    subtypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired).isRequired,

  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSwitchToggle: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isNewNotificationProfile: PropTypes.bool,
  validationState: PropTypes.object,
};

NotificationProfileForm.defaultProps = {
  isEditing: false,
  isNewNotificationProfile: false,
  validationState: {},
};

export default withStyles(style)(NotificationProfileForm);

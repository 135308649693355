/* eslint-disable max-len */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

import {
  NetworkTable,
  NetworkDetails,
  NetworkCreating,
  ContainerWithListAndForm,
} from '../../components/index';

import {getCampusDashboardData, getLocationPolicies} from '../../app-common/Campus/action';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

import {
  changeEditModeAndNetworkState,
  createNetwork,
  deleteNetwork,
  getNetworksCount,
  getDataToShowNetworkDetails,
  getNetworksByCampusId,
  getNetworksStatuses,
  getNetworksTableData,
  setSelectedNetworkIndex,
  resetSelectedNetworkIndex,
  updateNetwork,
} from './action';
import {cancelRequests} from '../Loading/action';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  MODAL_WINDOW_NOTIFY_TYPE,
  PERIODS,
  STATES_ENTITY,
} from '../../constants';
import {widgetKeys} from '../../app-common/Campus/constants';

class CampusNetworksTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdateNetworksTableData(prevProps, props) {
    return !_isEqual(prevProps.networks, props.networks)
      || !_isEqual(prevProps.networksStatusesObject, props.networksStatusesObject);
  }

  static shouldUpdateNetworks(prevProps, props) {
    return !_isEqual(prevProps.campus.id, props.campus.id)
      && !_isNil(props.campus.id);
  }

  constructor(props) {
    super(props);
    // this.isCampusNetworksLoaded = false;
    this.state = CampusNetworksTab.initialLocalState;
  }

  componentDidMount() {
    const {campus} = this.props;
    if (!_isNil(campus.id)) {
      this.props.getNetworksByCampusId(campus.id);
      this.props.getNetworksStatuses();
      this.props.getPolicies(campus.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (CampusNetworksTab.shouldUpdateNetworks(prevProps, this.props)) {
      this.props.getNetworksByCampusId(this.props.campus.id);
      this.props.getNetworksStatuses();
      this.props.getPolicies(this.props.campus.id);
      this.goToNetworksList();
    }
    if (CampusNetworksTab.shouldUpdateNetworksTableData(prevProps, this.props)) {
      this.props.getNetworksTableData();
    }
  }

  componentWillUnmount() {
    this.props.resetSelectedNetworkIndex();
    this.props.cancelRequests([widgetKeys.NETWORK_STATUSES], false);
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isValidNetworkInfo,
      selectedNetwork,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <NetworkCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateNetwork}
            handleClickButtonSave={this.handleClickButtonSaveNewNetwork}
            isValidNetworkInfo={isValidNetworkInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <NetworkDetails
            handleClickButtonBackInNetworkInfo={this.goToNetworksList}
            handleClickButtonCancelEditNetwork={this.handleClickButtonCancelNetworkEditing}
            handleClickButtonDeleteNetwork={this.handleClickButtonDeleteNetwork}
            handleClickButtonEditNetwork={this.handleClickButtonEditNetwork}
            handleClickButtonSaveNetwork={this.handleClickButtonSaveNetwork}
            isEditMode={isEditMode}
            isValidNetworkInfo={isValidNetworkInfo}
            networkInfo={selectedNetwork}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  handleClickButtonSaveNewNetwork = () => {
    const {
      campus,
      newOrUpdatedNetwork,
      selectedPeriodIndex,
    } = this.props;
    this.props.createNetwork(newOrUpdatedNetwork, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToNetworksList();
          this.props.getCampusDashboardData({
            period: PERIODS[Object.keys(PERIODS)[selectedPeriodIndex]],
            campusId: campus.id,
          });
        }
      });
  };

  handleClickButtonCancelCreateNetwork = () => {
    this.goToNetworksList();
    this.props.changeEditModeAndNetworkState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelNetworkEditing = () => {
    this.props.changeEditModeAndNetworkState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditNetwork = () => {
    this.props.changeEditModeAndNetworkState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveNetwork = () => {
    const {
      campus,
      newOrUpdatedNetwork,
      selectedNetwork,
      selectedPeriodIndex,
    } = this.props;
    this.props.updateNetwork(newOrUpdatedNetwork, selectedNetwork, campus.id).then((isSuccess) => {
      if (isSuccess) {
        this.goToNetworksList();
        this.props.getCampusDashboardData({
          period: PERIODS[Object.keys(PERIODS)[selectedPeriodIndex]],
          campusId: campus.id,
        });
      }
    });
  };

  handleClickButtonDeleteNetwork = () => {
    const {
      campus,
      selectedNetwork,
      selectedPeriodIndex,
    } = this.props;
    this.props.deleteNetwork(selectedNetwork.id, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToNetworksList();
          this.props.getCampusDashboardData({
            period: PERIODS[Object.keys(PERIODS)[selectedPeriodIndex]],
            campusId: campus.id,
          });
        }
      });
  };

  showFormCreatingNetwork = () => {
    const {
      accountInfo,
      networks,
    } = this.props;
    this.props.getNetworksCount()
      .then((networksCount) => {
        if (networksCount === -1) {
          this.props.openModalWindow(
            I18n.t('campusPage.networksTab.errorMessages.cannotGetNetworksCount'),
            MODAL_WINDOW_NOTIFY_TYPE.INFO,
          );
          return;
        }

        if (networks.length >= accountInfo.networksPerLocation) {
          this.props.openModalWindow(
            I18n.t('campusPage.networksTab.errorMessages.networksListInCampusMoreThenNetworkPerCampus'),
            MODAL_WINDOW_NOTIFY_TYPE.INFO,
          );
          return;
        }
        if (networksCount >= accountInfo.maxNetworks) {
          this.props.openModalWindow(
            I18n.t('campusPage.networksTab.errorMessages.networksListMoreThenMaxNetworks'),
            MODAL_WINDOW_NOTIFY_TYPE.INFO,
          );
          return;
        }

        this.setState({
          modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
          tableMode: COMPRESSED,
        });
        this.props.changeEditModeAndNetworkState(true, STATES_ENTITY.CREATING);
        this.props.resetSelectedNetworkIndex();
      });
  };

  showNetworkDetails = (networkIndex) => {
    const {networks} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowNetworkDetails(networks[networkIndex].id, networkIndex);
  };

  goToNetworksList = () => {
    this.setState(CampusNetworksTab.initialLocalState);
    this.props.resetSelectedNetworkIndex();
  };

  render() {
    const {
      isEditMode,
      networksTableData,
      selectedNetworkIndex,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    return (
      <ContainerWithListAndForm
        list={(
          <NetworkTable
            tableData={networksTableData}
            mode={tableMode}
            isEditMode={isEditMode}
            selectedNetworkIndex={selectedNetworkIndex}
            handleClickButtonNew={this.showFormCreatingNetwork}
            handleClickCampusRow={this.showNetworkDetails}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

CampusNetworksTab.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  campus: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidNetworkInfo: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  networksTableData: PropTypes.array.isRequired,
  newOrUpdatedNetwork: PropTypes.object.isRequired,
  selectedNetworkIndex: PropTypes.number.isRequired,
  selectedNetwork: PropTypes.object.isRequired,
  selectedPeriodIndex: PropTypes.number.isRequired,

  changeEditModeAndNetworkState: PropTypes.func.isRequired,
  createNetwork: PropTypes.func.isRequired,
  deleteNetwork: PropTypes.func.isRequired,
  getCampusDashboardData: PropTypes.func.isRequired,
  getNetworksCount: PropTypes.func.isRequired,
  getDataToShowNetworkDetails: PropTypes.func.isRequired,
  getNetworksByCampusId: PropTypes.func.isRequired,
  getNetworksStatuses: PropTypes.func.isRequired,
  getNetworksTableData: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  resetSelectedNetworkIndex: PropTypes.func.isRequired,
  updateNetwork: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

CampusNetworksTab.defaultProps = {};

const mapStateToProps = (state) => ({
  campus: state.campusReducer.selectedCampus,
  policies: state.campusReducer.policies,
  selectedPeriodIndex: state.campusReducer.selectedPeriodIndex,

  newOrUpdatedNetwork: state.networkEditFormReducer.editableNetworkInfo,
  isValidNetworkInfo: state.networkEditFormReducer.isValidNetworkInfo,

  accountInfo: state.operatorLayoutReducer.accountInfo,

  isEditMode: state.campusNetworksTabReducer.isEditMode,
  networks: state.campusNetworksTabReducer.networks,
  networksStatusesObject: state.campusNetworksTabReducer.networksStatusesObject,
  networksTableData: state.campusNetworksTabReducer.networksTableData,
  selectedNetwork: state.campusNetworksTabReducer.selectedNetwork,
  selectedNetworkIndex: state.campusNetworksTabReducer.selectedNetworkIndex,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndNetworkState: bindActionCreators(changeEditModeAndNetworkState, dispatch),
  createNetwork: bindActionCreators(createNetwork, dispatch),
  deleteNetwork: bindActionCreators(deleteNetwork, dispatch),
  getCampusDashboardData: bindActionCreators(getCampusDashboardData, dispatch),
  getNetworksCount: bindActionCreators(getNetworksCount, dispatch),
  getDataToShowNetworkDetails: bindActionCreators(getDataToShowNetworkDetails, dispatch),
  getNetworksByCampusId: bindActionCreators(getNetworksByCampusId, dispatch),
  getNetworksStatuses: bindActionCreators(getNetworksStatuses, dispatch),
  getNetworksTableData: bindActionCreators(getNetworksTableData, dispatch),
  getPolicies: bindActionCreators(getLocationPolicies, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  setSelectedNetworkIndex: bindActionCreators(setSelectedNetworkIndex, dispatch),
  resetSelectedNetworkIndex: bindActionCreators(resetSelectedNetworkIndex, dispatch),
  updateNetwork: bindActionCreators(updateNetwork, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampusNetworksTab));

import React from 'react';
import PropTypes from 'prop-types';

function Windows(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="50"
         height="36"
         viewBox="0 0 50 36"
         version="1.1"
         className={props.className}
    >
      <title>{props.title}</title>
      // eslint-disable-next-line max-len
      <path fillOpacity="0.176" d="M 25.295 5.782 L 15.091 7.268 15.091 16.489 L 15.091 25.710 23.795 26.905 C 28.583 27.563, 34.171 28.358, 36.214 28.673 L 39.929 29.244 39.929 16.622 C 39.929 1.998, 41.793 3.380, 25.295 5.782" stroke="none" fill="black" fillRule="evenodd" />
      // eslint-disable-next-line max-len
      <path fillOpacity="0.334" d="M 33.650 5.109 C 26.217 6.264, 26 6.445, 26 11.469 L 26 16 33 16 L 40 16 40 10.559 C 40 7.566, 39.663 4.946, 39.250 4.736 C 38.837 4.526, 36.317 4.694, 33.650 5.109 M 22 6.666 C 15.097 7.753, 15 7.828, 15 12.019 L 15 16 20 16 L 25 16 25 11 C 25 8.250, 24.887 6.061, 24.750 6.136 C 24.613 6.211, 23.375 6.450, 22 6.666 M 15.193 21.250 L 15.500 25.500 20.250 26.046 L 25 26.592 25 21.796 L 25 17 19.943 17 L 14.885 17 15.193 21.250 M 26.195 21.683 C 26.529 26.805, 27.489 27.454, 35.804 28.178 L 39.500 28.500 39.800 22.750 L 40.100 17 32.995 17 L 25.890 17 26.195 21.683" stroke="none" fill="black" fillRule="evenodd" />
      // eslint-disable-next-line max-len
      <path fillOpacity="0.371" d="M 30.250 5.723 C 27.077 6.117, 27 6.243, 27 11.063 L 27 16 33 16 L 39 16 39 10.500 C 39 4.413, 39.335 4.596, 30.250 5.723 M 17.750 7.689 C 16.458 8.027, 16 9.175, 16 12.073 L 16 16 20.500 16 L 25 16 25 11.500 C 25 7.229, 24.860 7.006, 22.250 7.116 C 20.738 7.180, 18.712 7.438, 17.750 7.689 M 16 20.883 C 16 24.963, 17.310 25.953, 22.750 25.986 C 24.752 25.998, 25 25.504, 25 21.500 L 25 17 20.500 17 L 16 17 16 20.883 M 27 21.864 L 27 26.728 31.640 27.364 C 39.221 28.403, 39 28.549, 39 22.500 L 39 17 33 17 L 27 17 27 21.864" stroke="none" fill="black" fillRule="evenodd" />
      // eslint-disable-next-line max-len
      <path fillOpacity="0.296" d="M 34 5.666 C 27.097 6.753, 27 6.828, 27 11.019 L 27 15 33 15 L 39 15 39 10 C 39 6.594, 38.601 5.043, 37.750 5.136 C 37.063 5.211, 35.375 5.450, 34 5.666 M 19.250 7.723 C 16.292 8.090, 16 8.436, 16 11.563 C 16 14.970, 16.040 15, 20.500 15 L 25 15 25 11 C 25 8.381, 24.568 7.055, 23.750 7.160 C 23.063 7.248, 21.038 7.502, 19.250 7.723 M 16 21.500 C 16 23.803, 16.470 25, 17.375 25 C 18.131 25, 20.156 25.281, 21.875 25.625 C 24.971 26.244, 25 26.212, 25 22.125 L 25 18 20.500 18 C 16.024 18, 16 18.019, 16 21.500 M 27 21.981 C 27 26.271, 26.783 26.126, 35.250 27.488 L 39 28.091 39 23.045 L 39 18 33 18 L 27 18 27 21.981" stroke="none" fill="black" fillRule="evenodd" />
    </svg>
  );
}

Windows.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

Windows.defaultProps = {
  className: '',
  title: null,
};

export default Windows;

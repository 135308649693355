import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {I18n} from 'react-redux-i18n';
import {MessageBox, RelayBinariesTable} from '../../components';
import {getRelayBinaries} from './action';

class RelayBinariesList extends React.Component {
  componentDidMount() {
    this.props.getRelayBinaries();
  }

  getTableOrMessage = () => {
    const {builds, networkAgentAllowed} = this.props;
    if (networkAgentAllowed) {
      return (
        <RelayBinariesTable tableData={builds} />
      );
    }
    return (
      <MessageBox
        message={I18n.t('downloadRelayPage.messages.relayUsingNotAllowed')}
      />
    );
  };

  render() {
    return (
      this.getTableOrMessage()
    );
  }
}

RelayBinariesList.propTypes = {
  builds: PropTypes.array.isRequired,
  networkAgentAllowed: PropTypes.bool.isRequired,
  getRelayBinaries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  builds: state.relayBinariesListReducer.builds,
  networkAgentAllowed: state.relayBinariesListReducer.networkAgentAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  getRelayBinaries: bindActionCreators(getRelayBinaries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelayBinariesList);

import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export function getLocalDomain(localDomain) {
  return ({
    id: localDomain.id,
    domain: localDomain.domainName,
    networkId: localDomain.networkId,
    network: localDomain.forAllNetworks ? '<All WANs>' : localDomain.networkName,
    locationId: localDomain.locationId,
    ip: localDomain.ip,
    forAllNetworks: localDomain.forAllNetworks,
  });
}
export function getLocalDomainsTableData(localDomains) {
  return localDomains.map((item) => (getLocalDomain(item)));
}

export function getLocalDomainToCreate(newLocalDomain, campusId) {
  return {
    domainName: newLocalDomain.domain,
    networkId: newLocalDomain.networkId,
    ip: newLocalDomain.ip,
    locationId: campusId,
    forAllNetworks: !newLocalDomain.networkId,
  };
}

export function getLocalDomainToUpdate(srcLocalDomain, campusId) {
  return {
    ...getLocalDomainToCreate(srcLocalDomain, campusId),
    id: srcLocalDomain.id,
  };
}

export function getUpdatedLocalDomainIndex(updatedLocalDomainId, localDomains) {
  return _findIndex(localDomains, (o) => _isEqual(o.id, updatedLocalDomainId));
}

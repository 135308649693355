import initState from '../../reducers/redux-initial-state';

import {types} from './constants';

export default (state = initState.organizationSitesTabEditFormReducer, action) => {
  switch (action.type) {
    case types.CHANGE_ORGANIZATION_SITES_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    }
    case types.SET_EDIT_FROM_ORGANIZATION_SITES: {
      return {
        ...state,
        sitesNotInOrganization: action.sitesNotInOrganization,
        sitesInOrganization: action.sitesInOrganization,
      };
    }
    default:
      return state;
  }
};

/* eslint-disable max-len */
import validationFormsStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

const style = {
  ...validationFormsStyle,
  form: {},
  form__textArea: {
    height: 'calc(100vh - 267px)',
  },
  label: {
    margin: '0',
  },
};

export default style;

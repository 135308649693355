import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import iconButtonStyle from '../../assets/jss/material-dashboard-pro-react/components/iconButtonStyle';

function IconCustomButton({...props}) {
  const {classes, color, children, customClass, ...rest} = props;
  const buttonClasses = classNames(
    classes.button,
    {
      [classes[color]]: color,
      [customClass]: customClass,
    }
  );
  return (
    <IconButton {...rest} className={buttonClasses}>
      {children}
    </IconButton>
  );
}

IconCustomButton.defaultProps = {
  color: 'white',
  customClass: '',
  disabled: false,
};

IconCustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'simple',
    'defaultNoBackground',
    'primaryNoBackground',
    'infoNoBackground',
    'successNoBackground',
    'warningNoBackground',
    'dangerNoBackground',
    'roseNoBackground',
    'twitter',
    'twitterNoBackground',
    'facebook',
    'facebookNoBackground',
    'google',
    'googleNoBackground',
    'linkedin',
    'linkedinNoBackground',
    'pinterest',
    'pinterestNoBackground',
    'youtube',
    'youtubeNoBackground',
    'tumblr',
    'tumblrNoBackground',
    'github',
    'githubNoBackground',
    'behance',
    'behanceNoBackground',
    'dribbble',
    'dribbbleNoBackground',
    'reddit',
    'redditNoBackground',
  ]),
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default withStyles(iconButtonStyle)(IconCustomButton);

const style = {
  operatorOrganizationsTable__cell_action: {
    paddingLeft: '10px',
  },
  operatorOrganizationsTable__dropdown: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  operatorOrganizationsTable__tableBody: {
    '@media(min-height: 700px)': {
      height: 'calc(100vh - 593px)',
    },
  },
};

export default style;

import {createStyles} from '@material-ui/core';
import cardsStyle from '../../styles/components/cards';

const style = createStyles({
  userSessionsCard: {
    ...cardsStyle.card,
  },
  userSessionsCard__header: {
    ...cardsStyle.card__header,
  },
  userSessionsCard__title: {
    ...cardsStyle.card__title,
    ...cardsStyle.card__title_primary,
    borderBottom: 'none',
    fontSize: '20px',
    fontWeight: '400',
  },
  userSessionsCard__content: {
    ...cardsStyle.card__content,
    padding: '0px 20px 5px 20px !important',
  },
});

export default style;

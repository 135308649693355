import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

import {DEFAULT_POLICIES} from '../../constants';

export function getPoliciesTableData(policies) {
  return policies.map((item) => ({
    name: item.name,
    description: item.description,
    tags: item.tags,
  }));
}

export function getSortedPolicies(policies) {
  if (policies.length === 0) {
    return [];
  }

  const sortedPolicies = [{}, {}, {}, {}];
  policies.forEach((item) => {
    switch (item.name) {
      case DEFAULT_POLICIES.NO_POLICY: {
        sortedPolicies[0] = item;
        break;
      }
      case DEFAULT_POLICIES.LOW: {
        sortedPolicies[1] = item;
        break;
      }
      case DEFAULT_POLICIES.HIGH: {
        sortedPolicies[2] = item;
        break;
      }
      case DEFAULT_POLICIES.MODERATE: {
        sortedPolicies[3] = item;
        break;
      }
      default: {
        sortedPolicies.push(item);
      }
    }
  });
  return sortedPolicies.filter((item) => !_isEqual(item, {}));
}

export function getPolicyToFormCreating(policyTemplate) {
  return {
    ...policyTemplate,
    id: null,
  };
}

export function getPolicyToCreate(currentPolicy, newData) {
  const newPolicy = getPolicyToUpdate(currentPolicy, newData);
  delete newPolicy.id;
  return newPolicy;
}

export function getPolicyToUpdate(currentPolicy, newData) {
  const newPolicy = _cloneDeep(currentPolicy);
  const updatedAdvancedSettingsObject = _cloneDeep(newData.advancedSettingsObject);
  const updatedBaseSettingsObject = _cloneDeep(newData.baseSettingsObject);
  const updatedCategoriesObject = _cloneDeep(newData.categoriesObject);

  newPolicy.name = updatedBaseSettingsObject.name.value;
  newPolicy.description = updatedBaseSettingsObject.description.value;

  delete updatedBaseSettingsObject.name;
  delete updatedBaseSettingsObject.description;

  newPolicy.advancedSettings = getValuesObjectAsArray(updatedAdvancedSettingsObject);
  newPolicy.baseSettings = getValuesObjectAsArray(updatedBaseSettingsObject);
  newPolicy.categories = getValuesObjectAsArray(updatedCategoriesObject);

  return newPolicy;
}

function getValuesObjectAsArray(object) {
  return Object.keys(object).map((key) => object[key]);
}

export function getUpdatedPolicyIndex(updatedItemId, policies) {
  return _findIndex(policies, (o) => _isEqual(o.id, updatedItemId));
}

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {NavLink} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import classNames from 'classnames';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import {RenderOrEmpty} from '../index';

function MenuItem(props) {
  const {
    classes,
    isActive,
    isMiniText,
    item,
  } = props;
  const tooltip = {
    classes: {
      tooltip: classes.navLinks__tooltip,
      popper: classes.navLinks__tooltipPopper,
    },
    title: props.isMiniText ? I18n.t(`navMenu.${item.name}`) : '',
    placement: 'right',
  };
  return (
    <ListItem
      key={uuid()}
      className={classes.navLinks__item}
    >
      <Tooltip
        classes={tooltip.classes}
        placement={tooltip.placement}
        title={tooltip.title}
      >
        {/* Get warning in browser console without this div */}
        <div>
          <NavLink
            to={item.path}
            className={classNames(
              classes.navLinks__item_link,
              {
                [classes.navLinks__item_activeLink]: isActive,
                [classes.navLinks__item_linkMini]: isMiniText,
              },
            )}
          >
            <ListItemIcon
              className={
                classNames(classes.listItem__root)
              }
            >
              <item.icon
                className={classNames(
                  classes.navLinks__item_icon,
                  {
                    [classes.navLinks__item_iconMini]: isMiniText,
                  },
                )}
              />
            </ListItemIcon>
            <RenderOrEmpty isShow={!isMiniText}>
              <ListItemText
                primary={I18n.t(`navMenu.${item.name}`)}
                disableTypography={true}
                className={classes.navLinks__item_text}
              />
            </RenderOrEmpty>
          </NavLink>
        </div>
      </Tooltip>
    </ListItem>
  );
}

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  isMiniText: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

MenuItem.defaultProps = {};

export default MenuItem;

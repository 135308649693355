import {types} from './constants';
import initState from '../../reducers/redux-initial-state';
import {getAllowedBlockedNetworkChartData} from '../../utils/dashboard';

export default (state = initState.requestStatsWidgetReducer, action) => {
  switch (action.type) {
    case types.SET_REQUEST_STATS: {
      return {
        ...state,
        requestsByDecision: action.requestsByDecision,
      };
    }
    case types.SET_USER_REQUEST_GRAPH: {
      return {
        ...state,
        userRequestsGraph: {
          chartData: getAllowedBlockedNetworkChartData(
            action.userRequestsGraph,
            action.period,
          ),
        },
      };
    }

    default:
      return state;
  }
};

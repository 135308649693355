export const types = {
  SET_EDITABLE_RELAY_INFO: 'SET_EDITABLE_RELAY_INFO',
  RESET_RELAY_EDIT_FORM_DATA: 'RESET_RELAY_EDIT_FORM_DATA',
  UPDATE_RELAY_INFO: 'UPDATE_RELAY_INFO',
};

export function getEditableRelayInfo(relay) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_RELAY_INFO,
      data: relay,
    });
  };
}

export function updateRelayInfo(updatedRelayInfo) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_RELAY_INFO,
      data: updatedRelayInfo,
    });
  };
}

export function resetRelayEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_RELAY_EDIT_FORM_DATA,
    });
  };
}

export const types = {
  SET_PROFILE_NAMES: 'SET_PROFILE_NAMES',
  SET_SEARCH_OPTION: 'SET_SEARCH_OPTION',
  SET_CLIENTS: 'SET_CLIENTS',
  ADD_CLIENTS: 'ADD_CLIENTS',
  RESET_CLIENTS: 'RESET_CLIENTS',
  RESET_CLIENT_MANAGER_DATA: 'RESET_CLIENT_MANAGER_DATA',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SELECTED_CLIENT: 'SET_SELECTED_CLIENT',
  SET_UPDATED_CLIENT: 'SET_UPDATED_CLIENT',
  CHANGE_EDIT_MODE_AND_CLIENT_STATE: 'CHANGE_EDIT_MODE_AND_CLIENT_STATE',
  SET_SELECTED_CLIENT_INDEX: 'SET_SELECTED_CLIENT_INDEX',
  RESET_SELECTED_CLIENT_INDEX: 'RESET_SELECTED_CLIENT_INDEX',
  SET_SELECTED_CLIENT_STATUS: 'SET_SELECTED_CLIENT_STATUS',
  SET_CLIENT_POLICIES: 'SET_CLIENT_POLICIES',
};

import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {ON_SITE_STATUSES, TABS as clientsTabs} from '../../app-common/Clients/constants';
import {CLIENT_STATUSES, ENTER_KEY_CODE} from '../../constants';
import {Dropdown, PillsWithLabel} from '../../components';
import {CustomButton, CustomInput} from '../../material-dashboard-pro-react/components';
import {Close} from '../../icons';
import {setSearchOption} from '../ClientsManager/action';
import initReducerState from '../../reducers/redux-initial-state';

import style from './style';

class ClientsControls extends React.Component {
  static initState = {
    selectedProfileIndex: 0,
    selectedStatusIndex: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...ClientsControls.initState,
    };
    this.onSiteStatusPills = null;
    this.statusOptions = [
      I18n.t(`clients.${clientsTabs.MANAGE}.controls.filters.allStatusesOption`),
      ...Object.keys(CLIENT_STATUSES),
    ];
  }

  componentDidMount() {
    const {profiles, searchOptions} = this.props;
    const selectedProfileIndex = _findIndex(
      profiles,
      (profile) => profile.id === searchOptions.profileId,
    );
    this.setState({selectedProfileIndex: selectedProfileIndex + 1});
    const selectedStatusIndex = _findIndex(
      Object.keys(CLIENT_STATUSES),
      (statusOption) => statusOption === searchOptions.agentStatus,
    );
    this.setState({selectedStatusIndex: selectedStatusIndex + 1});
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props.profiles, prevProps.profiles)) {
      const {profiles, searchOptions} = this.props;
      const selectedProfileIndex = _findIndex(
        profiles,
        (profile) => profile.id === searchOptions.profileId,
      );
      this.setState({selectedProfileIndex: selectedProfileIndex + 1});
    }

    if (!_isEqual(this.props.searchOptions, prevProps.searchOptions)) {
      const {profiles, searchOptions} = this.props;
      const selectedProfileIndex = _findIndex(
        profiles,
        (profile) => profile.id === searchOptions.profileId,
      );
      this.setState({selectedProfileIndex: selectedProfileIndex + 1});

      const selectedStatusIndex = _findIndex(
        Object.keys(CLIENT_STATUSES),
        (statusOption) => statusOption === searchOptions.agentStatus,
      );
      this.setState({selectedStatusIndex: selectedStatusIndex + 1});

      if (!_isNull(this.onSiteStatusPills)) {
        this.onSiteStatusPills.selectOption(searchOptions.onSiteStatus);
      }
    }
  }

  get agentStatusControls() {
    return (
      <Dropdown
      options={this.statusOptions}
      selectedItemIndex={this.state.selectedStatusIndex}
      customSelectClass="dropdown__select_clientStatus"
      customFormControlClass="dropdown__formControl_clientProfile"
      onChangeValue={this.onStatusChange}
      />
    );
  }

  get onSiteControls() {
    const pills = {
      name: 'onSiteStatus',
      label: '',
      options: [
        {value: null, label: I18n.t(`clients.${clientsTabs.MANAGE}.controls.onSiteStatuses.BOTH`)},
        ...Object.keys(ON_SITE_STATUSES).map((statusValue) => ({
          value: statusValue,
          label: I18n.t(`clients.${clientsTabs.MANAGE}.controls.onSiteStatuses.${statusValue}`),
        }))],
      defaultValue: this.props.searchOptions.onSiteStatus,
      selectOption: this.props.setSearchOption,
      buttonsClassName: 'pills-buttons_clients',
      onRef: (ref) => {this.onSiteStatusPills = ref;},
    };

    return (
      <PillsWithLabel
      {...pills}
      />
    );
  }

  get profileControls() {
    const profilesOptions = this.props.profiles.map((profile) => profile.name);
    profilesOptions.unshift(
      I18n.t(`clients.${clientsTabs.MANAGE}.controls.filters.allProfilesOption`));

    return (
      <Dropdown
      options={profilesOptions}
      selectedItemIndex={this.state.selectedProfileIndex}
      customSelectClass="dropdown__select_clientProfile"
      onChangeValue={this.onProfileChange}
      />
    );
  }

  get clientSearch() {
    const {classes, searchOptions, setSearchOption} = this.props;
    const clientFilterName = 'clientName';

    return (
      <CustomInput
        name={clientFilterName}
        type="text"
        inputProps={{
          value: searchOptions.clientName,
          disabled: this.props.disabled,
          onChange: (e) => setSearchOption(clientFilterName, e.target.value.toLowerCase()),
          onKeyPress: this.onClientNameSearchKeyPressed,
          // eslint-disable-next-line max-len
          placeholder: I18n.t(`clients.${clientsTabs.MANAGE}.controls.filters.placeholders.clientName`),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setSearchOption(clientFilterName, '')}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
        formControlProps={{
          className: classes.clientsControls__userSearchInputForm,
        }}
      />
    );
  }

  onClientNameSearchKeyPressed = (e) => {
    if (e.charCode === ENTER_KEY_CODE) {
      this.props.onRefresh();
    }
  };

  onProfileChange = (e) => {
    const selectedProfileIndex = e.target.value;
    this.setState({selectedProfileIndex});
    let selectedProfileId = null;
    if (selectedProfileIndex > 0) {
      selectedProfileId = this.props.profiles[selectedProfileIndex - 1].id;
    }
    this.props.setSearchOption('profileId', selectedProfileId);
  };

  onStatusChange = (e) => {
    const selectedStatusIndex = e.target.value;
    this.setState({selectedStatusIndex});
    const statusOption = (selectedStatusIndex > 0) ? this.statusOptions[selectedStatusIndex] : null;
    this.props.setSearchOption('agentStatus', statusOption);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onRefresh();
  };

  onReset = () => {
    if (!_isNull(this.onSiteStatusPills)) {
      this.onSiteStatusPills.selectOption(
        initReducerState.clientsManagerReducer.searchOptions.onSiteStatus);
    }
    this.setState({selectedProfileIndex: 0});
    this.setState({selectedStatusIndex: 0});
    this.props.onReset();
  };

  render() {
    const {classes} = this.props;

    return (
      <form className={classes.clientsControls}>
        <div className={classes.clientsControls__row}>
          <div>
            {this.agentStatusControls}
            {this.onSiteControls}
          </div>
          {this.profileControls}

        </div>
        <div className={classes.clientsControls__row}>
          {this.clientSearch}
          <div>
            <CustomButton
              type="submit"
              color="secondary"
              customClasses="clients-controls__button"
              onClick={this.onSubmit}
              size="sm"
            >
              {I18n.t('buttons.submit').toUpperCase()}
            </CustomButton>
            <CustomButton
              color="gray"
              customClasses="clients-controls__button"
              onClick={this.onReset}
              size="sm"
            >
              {I18n.t('buttons.reset').toUpperCase()}
            </CustomButton>
            <CustomButton
              color="primary"
              customClasses="clients-controls__button"
              onClick={this.props.onExport}
              size="sm"
            >
              {I18n.t('buttons.export').toUpperCase()}
            </CustomButton>
          </div>
        </div>
      </form>
    );
  }
}

ClientsControls.propTypes = {
  classes: PropTypes.object.isRequired,
  searchOptions: PropTypes.object.isRequired,
  profiles: PropTypes.array.isRequired,
  setSearchOption: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ClientsControls.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  searchOptions: state.clientsManagerReducer.searchOptions,
  profiles: state.clientsManagerReducer.profileNames,
});

const mapDispatchToProps = {
  setSearchOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ClientsControls));

import {
  get,
  makeUrl,
} from '../../utils/fetcher';

import {
  METHODS,
} from '../../constants';

import {
  types,
} from './actionTypes';

export function getNetworks() {
  const url = makeUrl(METHODS.GET_NETWORKS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_NETWORKS,
          data,
        });
      }
    });
}

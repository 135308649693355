import _cloneDeep from 'lodash/cloneDeep';
import initState from '../../reducers/redux-initial-state';
import {types} from './constants';
import {getAllowedBlockedNetworkChartData, getValidData} from '../../utils/dashboard';

export default (state = initState.clientEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_CLIENT_INFO: {
      const newState = _cloneDeep(state);
      newState.editableClientInfo = _cloneDeep(action.clientInfo);
      return newState;
    }
    case types.RESET_CLIENT_EDIT_FORM_DATA: {
      return _cloneDeep(initState.clientEditFormReducer);
    }
    case types.SET_VALIDATION_CLIENT_INFO: {
      return {
        ...state,
        isValidClientInfo: action.isValid,
      };
    }
    case types.UPDATE_CLIENT_INFO: {
      return {
        ...state,
        editableClientInfo: action.updatedClientInfo,
        isValidClientInfo: action.isValidClientInfo,
      };
    }
    case types.SET_CLIENT_SELECTED_PROFILE_INDEX: {
      return {
        ...state,
        selectedProfileIndex: action.index,
      };
    }
    case types.SET_CLIENT_SELECTED_POLICY_INDEX: {
      return {
        ...state,
        selectedPolicyIndex: action.index,
      };
    }
    case types.SET_CLIENT_SELECTED_PERIOD_INDEX: {
      return {
        ...state,
        selectedPeriodIndex: action.index,
      };
    }
    case types.SET_CLIENT_AVERAGE_LATENCY: {
      return {
        ...state,
        averageLatency: parseInt(getValidData(action.data), 10),
      };
    }
    case types.SET_CLIENT_STATS: {
      return {
        ...state,
        requestsPerSecond: getValidData(action.data.requestsPerSecond),
        requestsByDecision: action.data.requestsByDecision,
      };
    }
    case types.SET_CLIENT_THREATS: {
      return {
        ...state,
        threatStats: action.data,
      };
    }
    case types.SET_CLIENT_REQUESTS_GRAPH: {
      return {
        ...state,
        userRequestsGraph: {
          chartData: getAllowedBlockedNetworkChartData(
            action.data,
            action.period,
          ),
        },
      };
    }

    default:
      return state;
  }
};

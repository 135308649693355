import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';

import {widgetKeys} from './constants';
import {
  DashboardOperatorFiltersPanel,
  OrgsAndSitesStats,
  PerformanceStats,
  RequestsStats,
  StatusStats,
} from '../../../components';

import {getDashboardData} from './action';
import {getCampuses} from '../../../app-common/Campus/action';
import {cancelRequests} from '../../../containers/Loading/action';

import style from './style';

class Dashboard extends Component {
  static shouldReloadDashboard(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(
        _get(prevProps.currentOrganization, 'id', null),
        _get(props.currentOrganization, 'id', null),
      );
  }

  componentDidMount() {
    this.props.getCampuses();
  }

  componentDidUpdate(prevProps) {
    if (Dashboard.shouldReloadDashboard(prevProps, this.props)) {
      this.props.getCampuses();
    }
  }

  componentWillUnmount() {
    this.interruptLoadingRequests(true);
  }

  handleRefresh = (refreshData) => {
    this.interruptLoadingRequests(false);
    this.props.getDashboardData(refreshData);
  };

  interruptLoadingRequests = (includePageKey) => {
    this.props.cancelRequests(Object.values(widgetKeys), includePageKey);
  };

  render() {
    const {
      averageLatency,
      campuses,
      classes,
      loggedAccount,
      accountInfo,
      currentOrganization,
      networksStats,
      requestsByDecision,
      requestsPerSecond,
      userRequestsGraph,
      orgsAndSitesStats,
    } = this.props;

    const graphBlockClasses = classNames(
      classes.stats__block,
      {
        [classes.stats__block_graph_deviceAgentAllowed]: accountInfo.deviceAgentAllowed,
        [classes.stats__block_graph_deviceAgentNotAllowed]: !accountInfo.deviceAgentAllowed,
      },
    );
    const numericBlockClasses = classNames(
      classes.stats__block,
      {
        [classes.stats__block_numeric_deviceAgentAllowed]: accountInfo.deviceAgentAllowed,
        [classes.stats__block_numeric_deviceAgentNotAllowed]: !accountInfo.deviceAgentAllowed,
      },
    );
    const numericCardsClasses = classNames(
      classes.stats__numericCards,
      {
        [classes.stats__numericCards_deviceAgentAllowed]: accountInfo.deviceAgentAllowed,
        [classes.stats__numericCards_deviceAgentNotAllowed]: !accountInfo.deviceAgentAllowed,
      },
    );

    return (
      <div className={classes.dashboard}>
        <div className={classes.dashboard__filtersPanel}>
          <DashboardOperatorFiltersPanel
            campuses={campuses}
            handleRefresh={this.handleRefresh}
            loggedAccount={loggedAccount}
            currentOrganization={currentOrganization}
          />
        </div>
        <div className={classes.dashboard__stats}>
          <div className={numericBlockClasses}>
            <div className={numericCardsClasses}>
              <div className={classes.cardBlocks__block}>
                <StatusStats
                  networksUp={networksStats.up}
                  networksDown={networksStats.down}
                  widgetKey={widgetKeys.CAMPUSES_STATS}
                />
              </div>
              <div className={classes.cardBlocks__block}>
                <PerformanceStats
                  averageLatency={averageLatency}
                  requestsPerSecond={requestsPerSecond}
                  widgetKey={widgetKeys.CAMPUSES_STATS}
                />
              </div>
            </div>
            <div className={numericCardsClasses}>
              <div className={classes.cardBlocks__block}>
                <OrgsAndSitesStats
                  orgs={orgsAndSitesStats.orgs}
                  sites={orgsAndSitesStats.sites}
                  widgetKey={widgetKeys.CAMPUSES_STATS}
                />
              </div>
            </div>
          </div>
          <div className={graphBlockClasses}>
            <RequestsStats
              userRequestsGraph={userRequestsGraph}
              requestsAllowed={requestsByDecision.ALLOWED}
              requestsBlocked={requestsByDecision.FORBIDDEN}
              requestsTotal={requestsByDecision.ALLOWED + requestsByDecision.FORBIDDEN}
              widgetKey={widgetKeys.REQUEST_STATS}
            />
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  averageLatency: PropTypes.number.isRequired,
  blocksStats: PropTypes.object.isRequired,
  campuses: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  networksStats: PropTypes.object.isRequired,
  requestsByDecision: PropTypes.object.isRequired,
  requestsPerSecond: PropTypes.number.isRequired,
  userRequestsGraph: PropTypes.object.isRequired,
  orgsAndSitesStats: PropTypes.array.isRequired,

  cancelRequests: PropTypes.func.isRequired,
  getDashboardData: PropTypes.func.isRequired,
  getCampuses: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  campuses: state.campusReducer.campuses,

  loggedAccount: state.userAccountsReducer.loggedAccount,
  accountInfo: state.operatorLayoutReducer.accountInfo,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  averageLatency: state.dashboardReducer.averageLatency,
  blocksStats: state.dashboardReducer.blocksStats,
  networksStats: state.dashboardReducer.networksStats,
  requestsByDecision: state.dashboardReducer.requestsByDecision,
  requestsPerNetwork: state.dashboardReducer.requestsPerNetwork,
  requestsPerSecond: state.dashboardReducer.requestsPerSecond,
  userRequestsGraph: state.dashboardReducer.userRequestsGraph,
  orgsAndSitesStats: state.dashboardReducer.orgsAndSitesStats,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardData: bindActionCreators(getDashboardData, dispatch),
  getCampuses: bindActionCreators(getCampuses, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Dashboard));

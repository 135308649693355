import _isEqual from 'lodash/isEqual';
import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import {CustomButton} from '../../material-dashboard-pro-react/components';
import {RenderOrEmpty} from '../index';

import './style.css';

class PillsWithLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.defaultValue,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  get options() {
    return this.props.options.map((o) => {
      const value = o.value;
      const disabled = o.disabled;
      const color = this.isSelected(value)
        ? this.props.selectedOptionColor
        : this.props.optionsColor;
      return (
        <CustomButton
          key={o.value}
          value={value}
          color={color}
          onClick={() => this.selectOption(value)}
          customClasses={`pills-buttons ${this.props.buttonsClassName}`}
          disabled={disabled}
        >
          {o.label}
        </CustomButton>
      );
    });
  }

  selectOption = (selectedValue) => {
    this.setState({selectedValue});
    this.props.selectOption(this.props.name, selectedValue);
  };

  isSelected = (value) => _isEqual(value, this.state.selectedValue);

  render() {
    const {label} = this.props;
    return (
      <div className="pills-with-label">
        <RenderOrEmpty isShow={!_isEmpty(label)}>
          <span className="pills-with-label__label">{label}</span>
        </RenderOrEmpty>
        <div className="pills-with-label__options">
          {this.options}
        </div>
      </div>
    );
  }
}

PillsWithLabel.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired).isRequired,
  optionsColor: PropTypes.string,
  selectedOptionColor: PropTypes.string,
  selectOption: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonsClassName: PropTypes.string,
  onRef: PropTypes.func,
};

PillsWithLabel.defaultProps = {
  label: null,
  optionsColor: 'grayBordered',
  selectedOptionColor: 'secondary',
  buttonsClassName: '',
  defaultValue: null,
  onRef: () => {},
};

export default PillsWithLabel;

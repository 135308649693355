import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';
import {Check} from '../../icons/index';
import style from '../../styles/components/button';

function ButtonSave(props) {
  const {
    classes,
    customClasses,
    disabled,
    onClick,
  } = props;

  return (
    <CustomButton
      color="yellow"
      customClasses={customClasses}
      disabled={disabled}
      onClick={onClick}
      size="xs"
    >
      <Check className={classes.button__icon} />
      {I18n.t('buttons.save').toUpperCase()}
    </CustomButton>
  );
}

ButtonSave.propTypes = {
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ButtonSave.defaultProps = {
  customClasses: '',
  disabled: false,
};

export default withStyles(style)(ButtonSave);

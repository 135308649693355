import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import {I18n} from 'react-redux-i18n';

import {
  LocalDomainTable,
  LocalDomainDetails,
  LocalDomainCreating,
  ContainerWithListAndForm,
  MessageBox,
} from '../../components/index';

import {getCampusDashboardData} from '../../app-common/Campus/action';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {
  changeEditModeAndLocalDomainState,
  createLocalDomain,
  deleteLocalDomain,
  getDataToShowLocalDomainDetails,
  getLocalDomains,
  setSelectedLocalDomainIndex,
  resetSelectedLocalDomainIndex,
  updateLocalDomain,
  setLocalDomainsTableData,
} from './action';
import {getNetworksByCampusId} from '../CampusNetworksTab/action';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';

class CampusLocalDomainsTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdateLocalDomainsTableData(prevProps, props) {
    return !_isEqual(prevProps.localDomains, props.localDomains);
  }

  static shouldUpdateLocalDomains(prevProps, props) {
    return !_isEqual(prevProps.campus.id, props.campus.id)
      && !_isNil(props.campus.id);
  }

  constructor(props) {
    super(props);
    this.state = CampusLocalDomainsTab.initialLocalState;
    this.timer = null;
  }

  componentDidMount() {
    const {campus} = this.props;
    this.props.getLocalDomains(campus.id);
    this.props.getNetworksByCampusId(campus.id);
    this.setLocalDomainsAutoUpdate();
  }

  componentDidUpdate(prevProps) {
    if (CampusLocalDomainsTab.shouldUpdateLocalDomains(prevProps, this.props)) {
      this.props.getNetworksByCampusId(this.props.campus.id);
      this.goToLocalDomainsListAndReload();
    } else if (CampusLocalDomainsTab.shouldUpdateLocalDomainsTableData(prevProps, this.props)) {
      this.props.setLocalDomainsTableData();
    }
  }

  componentWillUnmount() {
    this.props.resetSelectedLocalDomainIndex();
    this.stopLocalDomainsAutoUpdate();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isValidLocalDomainInfo,
      selectedLocalDomain,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <LocalDomainCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateLocalDomain}
            handleClickButtonSave={this.handleClickButtonSaveNewLocalDomain}
            isValidLocalDomainInfo={isValidLocalDomainInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <LocalDomainDetails
            handleClickButtonBackInLocalDomainInfo={this.goToLocalDomainsListAndReload}
            handleClickButtonCancelEditLocalDomain={this.handleClickButtonCancelLocalDomainEditing}
            handleClickButtonDeleteLocalDomain={this.handleClickButtonDeleteLocalDomain}
            handleClickButtonEditLocalDomain={this.handleClickButtonEditLocalDomain}
            handleClickButtonSaveLocalDomain={this.handleClickButtonSaveLocalDomain}
            isEditMode={isEditMode}
            isValidLocalDomainInfo={isValidLocalDomainInfo}
            localDomainInfo={selectedLocalDomain}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  getLocalDomainListOrMessage = () => {
    const {tableMode} = this.state;
    const {
      networkAgentAllowed,
      localDomainsTableData,
      isEditMode,
      selectedLocalDomainIndex,
    } = this.props;

    if (networkAgentAllowed) {
      return (
        <LocalDomainTable
          tableData={localDomainsTableData}
          mode={tableMode}
          isEditMode={isEditMode}
          selectedLocalDomainIndex={selectedLocalDomainIndex}
          handleClickButtonNew={this.showFormCreatingLocalDomain}
          handleClickLocalDomainRow={this.showLocalDomainDetails}
        />
      );
    }
    return (
      <MessageBox
        message={I18n.t('campusPage.localDomainsTab.messages.relayUsingNotAllowed')}
      />
    );
  };

  setLocalDomainsAutoUpdate = () => {
    if (_isNil(this.timer)) {
      this.timer = setInterval(() => this.props.getLocalDomains(this.props.campus.id), 5000);
    }
  };

  stopLocalDomainsAutoUpdate = () => {
    clearInterval(this.timer);
  };

  handleClickButtonSaveNewLocalDomain = () => {
    const {
      campus,
      newOrUpdatedLocalDomain,
    } = this.props;
    this.props.createLocalDomain(newOrUpdatedLocalDomain, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToLocalDomainsListAndReload();
        }
      });
  };

  handleClickButtonCancelCreateLocalDomain = () => {
    this.goToLocalDomainsListAndReload();
    this.props.changeEditModeAndLocalDomainState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelLocalDomainEditing = () => {
    this.props.changeEditModeAndLocalDomainState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditLocalDomain = () => {
    this.props.changeEditModeAndLocalDomainState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveLocalDomain = () => {
    const {
      campus,
      newOrUpdatedLocalDomain,
    } = this.props;
    this.props.updateLocalDomain(newOrUpdatedLocalDomain, campus.id);
  };

  handleClickButtonDeleteLocalDomain = () => {
    const {
      campus,
      selectedLocalDomain,
    } = this.props;
    this.props.deleteLocalDomain(selectedLocalDomain.id, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToLocalDomainsListAndReload();
        }
      });
  };

  showFormCreatingLocalDomain = () => {
    this.stopLocalDomainsAutoUpdate();
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndLocalDomainState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedLocalDomainIndex();
  };

  showLocalDomainDetails = (localDomainIndex) => {
    const {localDomains} = this.props;
    this.stopLocalDomainsAutoUpdate();
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowLocalDomainDetails(localDomains[localDomainIndex].id, localDomainIndex);
  };

  goToLocalDomainsListAndReload = () => {
    this.setState(CampusLocalDomainsTab.initialLocalState);
    this.props.resetSelectedLocalDomainIndex();
    this.setLocalDomainsAutoUpdate();
    this.props.getLocalDomains(this.props.campus.id);
  };

  render() {
    const {modeShowingContainer} = this.state;
    return (
      <ContainerWithListAndForm
        list={this.getLocalDomainListOrMessage()}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

CampusLocalDomainsTab.propTypes = {
  campus: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidLocalDomainInfo: PropTypes.bool.isRequired,
  localDomains: PropTypes.array.isRequired,
  localDomainsTableData: PropTypes.array.isRequired,
  newOrUpdatedLocalDomain: PropTypes.object.isRequired,
  selectedLocalDomainIndex: PropTypes.number.isRequired,
  selectedLocalDomain: PropTypes.object.isRequired,
  networkAgentAllowed: PropTypes.bool.isRequired,

  changeEditModeAndLocalDomainState: PropTypes.func.isRequired,
  createLocalDomain: PropTypes.func.isRequired,
  deleteLocalDomain: PropTypes.func.isRequired,
  getDataToShowLocalDomainDetails: PropTypes.func.isRequired,
  getLocalDomains: PropTypes.func.isRequired,
  resetSelectedLocalDomainIndex: PropTypes.func.isRequired,
  updateLocalDomain: PropTypes.func.isRequired,
  setLocalDomainsTableData: PropTypes.func.isRequired,
  getNetworksByCampusId: PropTypes.func.isRequired,
};

CampusLocalDomainsTab.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.policyReducer.policies,

  campus: state.campusReducer.selectedCampus,
  selectedPeriodIndex: state.campusReducer.selectedPeriodIndex,

  newOrUpdatedLocalDomain: state.localDomainEditFormReducer.editableLocalDomainInfo,
  isValidLocalDomainInfo: state.localDomainEditFormReducer.isValidLocalDomainInfo,

  accountInfo: state.operatorLayoutReducer.accountInfo,

  isEditMode: state.campusLocalDomainsTabReducer.isEditMode,
  localDomains: state.campusLocalDomainsTabReducer.localDomains,
  localDomainsTableData: state.campusLocalDomainsTabReducer.localDomainsTableData,
  selectedLocalDomain: state.campusLocalDomainsTabReducer.selectedLocalDomain,
  selectedLocalDomainIndex: state.campusLocalDomainsTabReducer.selectedLocalDomainIndex,
  networkAgentAllowed: state.campusLocalDomainsTabReducer.networkAgentAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndLocalDomainState:
    bindActionCreators(changeEditModeAndLocalDomainState, dispatch),
  createLocalDomain: bindActionCreators(createLocalDomain, dispatch),
  deleteLocalDomain: bindActionCreators(deleteLocalDomain, dispatch),
  getCampusDashboardData: bindActionCreators(getCampusDashboardData, dispatch),
  getDataToShowLocalDomainDetails: bindActionCreators(getDataToShowLocalDomainDetails, dispatch),
  getLocalDomains: bindActionCreators(getLocalDomains, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  setSelectedLocalDomainIndex: bindActionCreators(setSelectedLocalDomainIndex, dispatch),
  resetSelectedLocalDomainIndex: bindActionCreators(resetSelectedLocalDomainIndex, dispatch),
  updateLocalDomain: bindActionCreators(updateLocalDomain, dispatch),
  setLocalDomainsTableData: bindActionCreators(setLocalDomainsTableData, dispatch),
  getNetworksByCampusId: bindActionCreators(getNetworksByCampusId, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampusLocalDomainsTab));

import React from 'react';
import PropTypes from 'prop-types';
import {Manager, Popper, Target} from 'react-popper';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import {RenderOrEmpty} from '../index';
import {ChevronRight} from '../../icons';

import style from './style';

class NestedMenuItem extends React.PureComponent {
  focus = () => this.props.focus(this.props.id);

  render() {
    const {
      label,
      classes,
      list,
      isOpen,
    } = this.props;
    const menuItemClassName = classNames(
      classes.nestedList__menuItem,
      classes.nestedList__menuItem_parent,
      {
        [classes.nestedList__menuItem_active]: isOpen,
      },
    );
    const paperClassName = classNames(classes.nestedList__paper);
    return (
      <Manager>
        <Target>
          <MenuItem
            className={menuItemClassName}
            onMouseOver={this.focus}
            onFocus={this.focus}
          >
            {label}
            <ChevronRight />
          </MenuItem>
        </Target>
        <RenderOrEmpty isShow={isOpen}>
          <Popper
            placement="right-start"
            eventsEnabled={isOpen}
          >
            <Paper className={paperClassName}>
              {list}
            </Paper>
          </Popper>
        </RenderOrEmpty>
      </Manager>
    );
  }
}

NestedMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  focus: PropTypes.func.isRequired,
};

NestedMenuItem.defaultProps = {
};

export default withStyles(style)(NestedMenuItem);

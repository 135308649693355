import {types} from './constants';
import initState from '../../reducers/redux-initial-state';

export default (state = initState.operatorOrganizationsTableReducer, action) => {
  switch (action.type) {
    case types.SET_ORGANIZATION_NAMES: {
      return {
        ...state,
        allOrganizations: action.data,
      };
    }
    case types.UPDATE_OPERATOR_ORGANIZATION_TABLE_ROWS: {
      return {
        ...state,
        rows: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

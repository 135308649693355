export const types = {
  SET_EDITABLE_CLIENT_PROFILE_INFO: 'SET_EDITABLE_CLIENT_PROFILE_INFO',
  RESET_CLIENT_PROFILE_EDIT_FORM_DATA: 'RESET_CLIENT_PROFILE_EDIT_FORM_DATA',
  SET_PROFILE_FROM_TEMPLATE: 'SET_PROFILE_FROM_TEMPLATE',
  SET_VALIDATION_CLIENT_PROFILE_INFO: 'SET_VALIDATION_CLIENT_PROFILE_INFO',
  UPDATE_CLIENT_PROFILE_INFO: 'UPDATE_CLIENT_PROFILE_INFO',
  SET_SELECTED_CLIENT_PROFILE: 'SET_SELECTED_CLIENT_PROFILE_INDEX',
  SET_SELECTED_CLIENT_PROFILE_TEMPLATE_INDEX: 'SET_SELECTED_CLIENT_PROFILE_TEMPLATE_INDEX',
  RESET_SELECTED_CLIENT_PROFILE_TEMPLATE: 'RESET_SELECTED_CLIENT_PROFILE_TEMPLATE',
};

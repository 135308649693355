import {get, makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_NOTIFICATION_TYPES: 'SET_NOTIFICATION_TYPES',
  SET_NOTIFICATION_PROFILES: 'SET_NOTIFICATION_PROFILES',
  SET_EDITING_MODE: 'SET_NOTIFICATIONS_MANAGEMENT_EDITING_MODE',
  CREATE_NOTIFICATION_PROFILE: 'CREATE_NOTIFICATION_PROFILE',
  UPDATE_NOTIFICATION_PROFILE: 'UPDATE_NOTIFICATION_PROFILE',
  DELETE_NOTIFICATION_PROFILE: 'DELETE_NOTIFICATION_PROFILE',
  RESET_STATE: 'RESET_NOTIFICATION_MANAGEMENT_STATE',
};

export function getNotificationTypes() {
  const url = makeUrl(METHODS.GET_NOTIFICATION_TYPES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_NOTIFICATION_TYPES,
          data,
        });
      }
    });
}

export function getNotificationProfiles() {
  const url = makeUrl(METHODS.GET_NOTIFICATION_PROFILES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_NOTIFICATION_PROFILES,
          data,
        });
      }
    });
}

export function createNotificationProfile(profile) {
  const url = makeUrl(METHODS.CREATE_NOTIFICATION_PROFILE);
  return (dispatch) => post(url, profile, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch({
          type: types.CREATE_NOTIFICATION_PROFILE,
        });
      }
      return response.success;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Creation of notification profile failed', error);
    });
}

export function updateNotificationProfile(profile) {
  const url = makeUrl(METHODS.UPDATE_NOTIFICATION_PROFILE);
  return (dispatch) => post(url, profile, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch({
          type: types.UPDATE_NOTIFICATION_PROFILE,
        });
      }
      return response.success;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Update of notification profile failed', error);
    });
}

export function deleteNotificationProfile(id) {
  const url = makeUrl(METHODS.DELETE_NOTIFICATION_PROFILE);
  return (dispatch) => post(url, {id}, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch({
          type: types.DELETE_NOTIFICATION_PROFILE,
        });
      }
      return response.success;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Deletion of notification profile failed', error);
    });
}

export function setEditingMode(isEditingMode) {
  return (dispatch) => dispatch({
    type: types.SET_EDITING_MODE,
    isEditingMode,
  });
}

export function resetState() {
  return (dispatch) => dispatch({
    type: types.RESET_STATE,
  });
}

import React from 'react';
import {I18n} from 'react-redux-i18n';

import {
  CardWithHeader,
} from '../../components';
import {ChangePassword, UpdateEmailForm, UpdateFirstAndLastNameForm} from '../../containers';

export default function Profile() {
  return (
    <CardWithHeader
      customCardContentClasses="card__content_profilePage"
      cardTitle={I18n.t('profilePage.title')}
      content={(
        <>
          <UpdateFirstAndLastNameForm />
          <UpdateEmailForm />
          <ChangePassword />
        </>
      )}
    />
  );
}

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {getWBLists} from './action';

class WbListsContainer extends React.Component {
  componentDidMount() {
    if (!this.props.areWbListsRequested) {
      this.props.getWBLists();
    }
  }

  componentDidUpdate() {
    if (!this.props.areWbListsRequested) {
      this.props.getWBLists();
    }
  }

  render() {
    return null;
  }
}

WbListsContainer.propTypes = {
  getWBLists: PropTypes.func.isRequired,
  areWbListsRequested: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  areWbListsRequested: state.rowActionsReducer.areWbListsRequested,
});

const mapDispatchToProps = {
  getWBLists,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WbListsContainer);

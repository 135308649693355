import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Add from '@material-ui/icons/Add';
import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import {TABS as ClientTabs} from '../../app-common/Clients/constants';
import actionData from './actionData';
import ActionTemplate from '../HeaderActions/ActionTemplate';
import {RenderOrEmpty} from '../index';

import style from './style';
import {RightAvailability} from '../../containers/index';

class ClientActions extends Component {
  static initState = {isOpen: false};

  constructor(props) {
    super(props);
    this.state = {
      ...ClientActions.initState,
    };
  }

  get handlers() {
    const {
      handleDisable,
      handleEnable,
      handleForget,
      handleUninstall,
      handleCancelUninstall,
    } = this.props;
    return ({
      handleDisable,
      handleEnable,
      handleForget,
      handleUninstall,
      handleCancelUninstall,
    });
  }

  get menuItems() {
    const {classes} = this.props;
    return actionData(this.props.client, this.handlers, (key) => I18n.t(key))
      .filter((item) => (item.visible))
      .map((item) => (
        <MenuItem
            key={`menu-item-${item.name}`}
            className={classes.dropdown__item}
            onClick={this.handleActionAndCloseMenu(item.handler)}
        >
          {item.menuItemProps.label}
        </MenuItem>
      ));
  }

  get menu() {
    const {classes} = this.props;
    const {isOpen} = this.state;
    const menuClasses = {
      ...classes,
      root: classes.menu,
    };

    return (
      <ActionTemplate
        actionName=""
        classes={menuClasses}
        handleClick={this.handleClickActionMenuButton}
        handleClose={this.handleCloseActionMenu}
        icon={<Add className={classes.links} />}
        isOpen={isOpen}
      >
        <Paper className={classes.dropdown}>
          <div
            className={classes.dropdown__header}
          >
            {I18n.t(`clients.${ClientTabs.MANAGE}.editClientForm.actionMenuTitle`)}
          </div>
          <MenuList
            className={classes.action__menuList}
            role="menu"
          >
            {this.menuItems}
          </MenuList>
        </Paper>
      </ActionTemplate>
    );
  }

  get buttons() {
    const {client} = this.props;
    return actionData(client, this.handlers, (key) => I18n.t(key))
      .filter((item) => (item.visible))
      .map((item) => (
        <RightAvailability
            key={`action-button-${item.name}`}
            accessRights={item.accessRights}
        >
          <CustomButton
              color={item.buttonProps.color}
              border="solid"
              customClasses="clientActions__actionButton"
              disabled={_isNil(client.id)}
              onClick={item.handler}
              size="xs"
          >
            {item.buttonProps.label}
          </CustomButton>
        </RightAvailability>
      ));
  }

  handleClickActionMenuButton = () => {
    this.setState((prevState) => ({isOpen: !prevState.isOpen}));
  };

  handleCloseActionMenu = () => {
    this.setState({isOpen: false});
  };

  handleActionAndCloseMenu = (handler) => (e) => {
    handler(e);
    this.setState({isOpen: false});
  };

  render() {
    const {client} = this.props;
    return (
      <RenderOrEmpty isShow={!_isNil(client.id)}>
        {this.buttons}
        {this.menu}
      </RenderOrEmpty>
    );
  }
}

ClientActions.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  handleDisable: PropTypes.func.isRequired,
  handleEnable: PropTypes.func.isRequired,
  handleForget: PropTypes.func.isRequired,
  handleUninstall: PropTypes.func.isRequired,
  handleCancelUninstall: PropTypes.func.isRequired,
};

export default withStyles(style)(ClientActions);

import React from 'react';

import {ResponseDetails} from '../../components';
import LS from '../../utils/localStorage';
import {PRINT_RESPONSE_LOCAL_STORAGE_KEY} from './constants';

export default class ResponsePrint extends React.Component {
  constructor(props) {
    super(props);
    const data = LS.getItem(PRINT_RESPONSE_LOCAL_STORAGE_KEY);
    this.state = {
      responseData: JSON.parse(data),
    };
  }

  componentDidMount() {
    setTimeout(this.print, 500);
  }

  print = () => {
    window.print();
    LS.removeItem(PRINT_RESPONSE_LOCAL_STORAGE_KEY);
    window.close();
  };

  render() {
    const {responseData} = this.state;
    return (
      <ResponseDetails
        response={responseData.response}
        log={responseData.log}
        isPrint={true}
      />
    );
  }
}

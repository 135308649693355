import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';

import {PERIODS} from '../../constants';
import {widgetKeys} from '../../app-common/Organizations/constants';
import {filterTypes} from '../Filters/constants';

import {
  PerformanceStatsWidget,
  RequestStatsWidget,
  ThreatStatsWidget,
  UniversalDashboard,
} from '../index';
import {getClientProfileNames, getLocations} from './action';
import {updateFilter} from '../Filters/action';

import style from './style';

class OrganizationDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.periods = [
      // TODO: need to use period keys instead of localized values.
      // TODO: Need to fix it on back-end first.
      ...Object.keys(PERIODS).map((period) =>
        I18n.t(`dashboard.filters.${period}`)),
    ];
  }

  componentDidMount() {
    const {showProfileTab} = this.props;
    const organizationId = this.props.organization.id;
    if (!_isNil(organizationId)) {
      this.props.getLocations({organizationId});
      if (showProfileTab) {
        this.props.getClientProfileNames({organizationId});
      }
    }
    if (!_isNil(this.props.setRef)) {this.props.setRef(this);}
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.organization.id, this.props.organization.id)
      && !_isNil(this.props.organization.id)
    ) {
      const queryParams = {organizationId: this.props.organization.id};
      const {showProfileTab} = this.props;

      this.props.getLocations(queryParams);
      if (showProfileTab) {
        this.props.getClientProfileNames(queryParams);
      }
    }
  }

  componentWillUnmount() {
    if (!_isNil(this.props.setRef)) {this.props.setRef(null);}
  }

  get locationOptions() {
    if (this.props.filters.clientProfile > 0) {
      return [I18n.t('organizations.dashboard.allSites')];
    }
    return [
      I18n.t('organizations.dashboard.allSites'),
      ...this.props.locations.map((location) => location.name),
    ];
  }

  get clientProfilesOptions() {
    if (this.props.filters.location > 0) {
      return [I18n.t('organizations.dashboard.allProfiles')];
    }
    return [
      I18n.t('organizations.dashboard.allProfiles'),
      ...this.props.clientProfiles.map((profile) => profile.name),
    ];
  }

  get filtersMetadata() {
    const locationOptions = this.locationOptions;
    const clientProfilesOptions = this.clientProfilesOptions;

    const {showProfileTab} = this.props;
    if (showProfileTab) {
      return [
        {
          name: 'location',
          type: filterTypes.DROPDOWN,
          label: '',
          initIndex: _get(this.props.filters, 'location', 0),
          options: locationOptions,
          disabled: locationOptions.length <= 1,
        },
        {
          name: 'clientProfile',
          type: filterTypes.DROPDOWN,
          label: '',
          initIndex: _get(this.props.filters, 'clientProfile', 0),
          options: clientProfilesOptions,
          disabled: clientProfilesOptions.length <= 1,
        },
        {
          name: 'period',
          type: filterTypes.DROPDOWN,
          label: '',
          initIndex: 0,
          options: this.periods,
        },
      ];
    }
    return [
      {
        name: 'location',
        type: filterTypes.DROPDOWN,
        label: '',
        initIndex: _get(this.props.filters, 'location', 0),
        options: locationOptions,
        disabled: locationOptions.length <= 1,
      },
      {
        name: 'period',
        type: filterTypes.DROPDOWN,
        label: '',
        initIndex: 0,
        options: this.periods,
      },
    ];
  }

  get widgets() {
    const {classes, organization} = this.props;

    return (
      <div
        className={classes.organizationDashboard__statsPanel}
      >
        <div
          className={classNames(
            classes.statsPanel__block,
            classes.statsPanel__block_left,
          )}
        >
          <div className={classes.statsPanel__card}>
            <ThreatStatsWidget
              filters={this.getWidgetFilters()}
              readyToLoadStats={!_isNil(organization.id)}
              widgetKey={widgetKeys.THREAT_STATS}
            />
          </div>
          <div className={classes.statsPanel__card}>
            <PerformanceStatsWidget
              filters={this.getWidgetFilters()}
              readyToLoadStats={!_isNil(organization.id)}
              widgetKey={widgetKeys.PERFORMANCE_STATS}
            />
          </div>
        </div>
        <div
          className={classNames(
            classes.statsPanel__block,
            classes.statsPanel__block_right,
          )}
        >
          <div className={classes.statsPanel__item_graph}>
            <RequestStatsWidget
              filters={this.getWidgetFilters()}
              readyToLoadStats={!_isNil(organization.id)}
              widgetKey={widgetKeys.REQUEST_STATS}
            />
          </div>
        </div>
      </div>
    );
  }

  getWidgetFilters = () => {
    const {organization, filters, locations, clientProfiles, showProfileTab} = this.props;
    const queryFilters = {organizationId: organization.id};

    if (filters.period >= 0) {
      queryFilters.period = this.periods[filters.period];
    }
    if (filters.location > 0) {
      queryFilters.locationId = locations[filters.location - 1].id;
    }

    if (showProfileTab) {
      if (filters.clientProfile > 0) {
        queryFilters.clientProfileId = clientProfiles[filters.clientProfile - 1].id;
      }
    }

    return queryFilters;
  };

  // ref method
  // eslint-disable-next-line react/no-unused-class-component-methods
  forceReloadLocations = () => {
    const {organization} = this.props;
    this.props.getLocations({organizationId: organization.id});
    this.props.resetFilter('location', 0);
  };

  // ref method
  // eslint-disable-next-line react/no-unused-class-component-methods
  forceReloadClientProfiles = () => {
    const {organization} = this.props;
    this.props.getClientProfileNames({organizationId: organization.id});
    this.props.resetFilter('clientProfile', 0);
  };

  render() {
    return (
      <UniversalDashboard
        filtersMetadata={this.filtersMetadata}
        widgets={this.widgets}
      />
    );
  }
}

OrganizationDashboard.propTypes = {
  classes: PropTypes.object,
  organization: PropTypes.object,
  filters: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  clientProfiles: PropTypes.array.isRequired,
  getLocations: PropTypes.func.isRequired,
  getClientProfileNames: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  setRef: PropTypes.func,
  showProfileTab: PropTypes.bool,

};

OrganizationDashboard.defaultProps = {
  classes: {},
  organization: {},
  setRef: null,
  showProfileTab: true,
};

const mapStateToProps = (state) => ({
  filters: state.filtersReducer,
  locations: state.organizationDashboardReducer.locations,
  clientProfiles: state.organizationDashboardReducer.clientProfiles,
});

const mapDispatchToProps = (dispatch) => ({
  getLocations: bindActionCreators(getLocations, dispatch),
  getClientProfileNames: bindActionCreators(getClientProfileNames, dispatch),
  resetFilter: bindActionCreators(updateFilter, dispatch),
});

export default
connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(OrganizationDashboard));

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {
  ROUTES,
} from '../../constants';

import {
  NotifyAfterActionWithPassword,
  RenderOrEmpty,
  UpdatePasswordForm,
} from '../../components';

import {
  resetPageData,
  updatePasswordByToken,
} from './action';

class UpdatePassword extends Component {
  componentWillUnmount() {
    this.props.resetPageData();
  }

  goToLoginPage = () => {
    this.props.history.push(ROUTES.LOGIN);
  };

  render() {
    const {
      isPasswordUpdated,
      isPasswordUpdatingError,
    } = this.props;

    return (
      <>
        <RenderOrEmpty isShow={!isPasswordUpdated}>
          <UpdatePasswordForm
            token={_get(this.props, 'match.params.token', null)}
            updatePassword={this.props.updatePasswordByToken}
            isUpdatingError={isPasswordUpdatingError}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={isPasswordUpdated}>
          <NotifyAfterActionWithPassword
            buttonColor="secondary"
            buttonText={I18n.t('profilePage.passwordBlock.success.buttonText')}
            isAuth={true}
            onClick={this.goToLoginPage}
            text={I18n.t('profilePage.passwordBlock.success.updated')}
          />
        </RenderOrEmpty>
      </>
    );
  }
}

UpdatePassword.propTypes = {
  isPasswordUpdated: PropTypes.bool.isRequired,
  isPasswordUpdatingError: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  resetPageData: PropTypes.func.isRequired,
  updatePasswordByToken: PropTypes.func.isRequired,
};

UpdatePassword.defaultProps = {};

const mapStateToProps = (state) => ({
  isPasswordUpdated: state.updatePasswordReducer.isPasswordUpdated,
  isPasswordUpdatingError: state.updatePasswordReducer.isPasswordUpdatingError,
});

const mapDispatchToProps = (dispatch) => ({
  resetPageData: bindActionCreators(resetPageData, dispatch),
  updatePasswordByToken: bindActionCreators(updatePasswordByToken, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdatePassword));

const style = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '40px',
  },
  form__block: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  form__formControl_response: {
    transform: 'translate(0, 24px)',
  },
  form__shrink_response: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
};

export default style;

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';
import {
  ClientLocalForwardingCreating,
  ClientLocalForwardingDetails,
  ClientProfileLocalDomainsTable,
  ContainerWithListAndForm,
} from '../../components';
import {
  changeEditModeAndLocalForwardingState,
  createClientLocalForwarding,
  deleteClientLocalForwarding,
  updateClientLocalForwarding,
  getDataToShowLocalForwardingDetails,
  loadClientLocalDomains,
  resetClientLocalForwardingFormData,
  resetSelectedLocalDomainIndex,
  setSelectedLocalDomainIndex,
} from './action';

class ClientProfileLocalForwardingForm extends React.Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateClientLocalForwardingTableData(prevProps, props) {
    return !_isEqual(prevProps.localDomains, props.localDomains)
      || (!_isEqual(prevProps.selectedLocalDomain.id, props.selectedLocalDomain.id)
        && !_isNull(props.selectedLocalDomain.id))
      || (!_isEqual(prevProps.profile.id, props.profile.id));
  }

  constructor(props) {
    super(props);
    this.state = {...ClientProfileLocalForwardingForm.initialLocalState};
    this.validateForm = null;
  }

  componentDidMount() {
    this.props.loadClientLocalDomains(this.props.profile.id);
  }

  componentDidUpdate(prevProps) {
    if (ClientProfileLocalForwardingForm.wasDataScopeSwitched(prevProps, this.props)) {
      this.props.loadClientLocalDomains(this.props.profile.id);
      this.goToLocalDomainList();
    }
    if (ClientProfileLocalForwardingForm.shouldUpdateClientLocalForwardingTableData(
      prevProps,
      this.props,
    )) {
      this.props.loadClientLocalDomains(this.props.profile.id);
    }
  }

  componentWillUnmount() {
    this.props.resetClientLocalForwardingFormData();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <ClientLocalForwardingCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreatingLocalForwarding}
            handleClickButtonSave={this.handleClickButtonCreateLocalForwarding}
            isValidLocalForwardingInfo={this.props.isValidLocalForwardingInfo}
            setFormValidatorRef={this.setFormValidator}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <ClientLocalForwardingDetails
            localForwarding={this.props.selectedLocalDomain}
            handleClickButtonCancel={this.handleClickButtonCancelEditLocalForwarding}
            handleClickButtonSave={this.handleClickButtonSaveLocalForwarding}
            handleClickButtonBack={this.goToLocalDomainList}
            handleClickButtonDelete={this.handleClickButtonDeleteLocalForwarding}
            handleClickButtonEdit={this.handleClickButtonEditLocalForwarding}
            handleClickButtonCancelEdit={this.handleClickButtonCancelEditLocalForwarding}
            isEditMode={this.props.isEditMode}
            isProfileEditMode={this.props.isProfileEditMode}
            isValidLocalForwardingInfo={this.props.isValidLocalForwardingInfo}
            setFormValidatorRef={this.setFormValidator}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  goToLocalDomainList = () => {
    this.setState(ClientProfileLocalForwardingForm.initialLocalState);
    this.props.resetSelectedLocalDomainIndex();
  };

  handleClickButtonCreateLocalForwarding = () => {
    const {newOrUpdatedLocalForwarding} = this.props;

    if (_isNull(this.validateForm()) || this.validateForm()) {
      this.props.createClientLocalForwarding({
        ...newOrUpdatedLocalForwarding,
        profileId: this.props.profile.id,
      })
        .then((isSuccess) => {
          if (isSuccess) {
            this.goToLocalDomainList();
          }
        });
    }
  };

  handleClickButtonSaveLocalForwarding = () => {
    if (_isNull(this.validateForm()) || this.validateForm()) {
      this.props.updateClientLocalForwarding(this.props.newOrUpdatedLocalForwarding);
    }
  };

  handleClickButtonEditLocalForwarding = () => {
    this.props.changeEditModeAndLocalForwardingState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonCancelCreatingLocalForwarding = () => {
    this.goToLocalDomainList();
    this.props.changeEditModeAndLocalForwardingState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelEditLocalForwarding = () => {
    this.props.changeEditModeAndLocalForwardingState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonDeleteLocalForwarding = () => {
    const {selectedLocalDomain} = this.props;
    this.props.deleteClientLocalForwarding(selectedLocalDomain.id, selectedLocalDomain.profileId)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToLocalDomainList();
        }
      });
  };

  showLocalForwardingDetails = (localDomainIndex) => {
    const {localDomains} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowLocalForwardingDetails(
      localDomains[localDomainIndex].id,
      localDomainIndex,
    );
  };

  showFormCreatingLocalForwarding = () => {
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndLocalForwardingState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedLocalDomainIndex();
  };

  setFormValidator = (validator) => {
    this.validateForm = validator;
  };

  render() {
    const {modeShowingContainer, tableMode} = this.state;
    const {
      localDomains,
      isEditMode,
      isProfileEditMode,
      selectedLocalDomainIndex,
    } = this.props;
    const tableStyles = {
      height: 'calc(100vh - 307px)',
    };

    return (
      <ContainerWithListAndForm
        list={(
          <ClientProfileLocalDomainsTable
            tableData={localDomains}
            mode={tableMode}
            isEditMode={isEditMode || isProfileEditMode}
            selectedLocalDomainIndex={selectedLocalDomainIndex}
            tableStyles={tableStyles}
            handleClickButtonNew={this.showFormCreatingLocalForwarding}
            handleClickRow={this.showLocalForwardingDetails}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

ClientProfileLocalForwardingForm.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,

  localDomains: PropTypes.array.isRequired,
  selectedLocalDomain: PropTypes.object.isRequired,
  selectedLocalDomainIndex: PropTypes.number.isRequired,
  newOrUpdatedLocalForwarding: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isProfileEditMode: PropTypes.bool.isRequired,
  isValidLocalForwardingInfo: PropTypes.bool.isRequired,

  loadClientLocalDomains: PropTypes.func.isRequired,
  setSelectedLocalDomainIndex: PropTypes.func.isRequired,
  resetSelectedLocalDomainIndex: PropTypes.func.isRequired,
  resetClientLocalForwardingFormData: PropTypes.func.isRequired,
  getDataToShowLocalForwardingDetails: PropTypes.func.isRequired,
  changeEditModeAndLocalForwardingState: PropTypes.func.isRequired,
  createClientLocalForwarding: PropTypes.func.isRequired,
  updateClientLocalForwarding: PropTypes.func.isRequired,
  deleteClientLocalForwarding: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  isProfileEditMode: state.clientsConfigurerReducer.isEditMode,
  profile: state.clientsConfigurerReducer.selectedProfile,

  localDomains: state.clientProfileLocalForwardingFormReducer.localDomains,
  selectedLocalDomain: state.clientProfileLocalForwardingFormReducer.selectedLocalDomain,
  selectedLocalDomainIndex: state.clientProfileLocalForwardingFormReducer.selectedLocalDomainIndex,
  isEditMode: state.clientProfileLocalForwardingFormReducer.isEditMode,

  isValidLocalForwardingInfo: state.clientLocalForwardingEditFormReducer.isValidLocalForwardingInfo,
  // eslint-disable-next-line max-len
  newOrUpdatedLocalForwarding: state.clientLocalForwardingEditFormReducer.editableLocalForwardingInfo,
});

ClientProfileLocalForwardingForm.defaultProps = {
  currentOrganization: null,
};

const mapDispatchToProps = (dispatch) => ({
  loadClientLocalDomains: bindActionCreators(loadClientLocalDomains, dispatch),
  setSelectedLocalDomainIndex: bindActionCreators(setSelectedLocalDomainIndex, dispatch),
  resetSelectedLocalDomainIndex: bindActionCreators(resetSelectedLocalDomainIndex, dispatch),
  // eslint-disable-next-line max-len
  resetClientLocalForwardingFormData: bindActionCreators(resetClientLocalForwardingFormData, dispatch),
  // eslint-disable-next-line max-len
  getDataToShowLocalForwardingDetails: bindActionCreators(getDataToShowLocalForwardingDetails, dispatch),
  // eslint-disable-next-line max-len
  changeEditModeAndLocalForwardingState: bindActionCreators(changeEditModeAndLocalForwardingState, dispatch),
  createClientLocalForwarding: bindActionCreators(createClientLocalForwarding, dispatch),
  updateClientLocalForwarding: bindActionCreators(updateClientLocalForwarding, dispatch),
  deleteClientLocalForwarding: bindActionCreators(deleteClientLocalForwarding, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileLocalForwardingForm);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import {I18n} from 'react-redux-i18n';

import {
  SubnetTable,
  SubnetDetails,
  SubnetCreating,
  ContainerWithListAndForm,
  MessageBox,
} from '../../components/index';

import {getCampusDashboardData, getLocationPolicies} from '../../app-common/Campus/action';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {
  changeEditModeAndSubnetState,
  createSubnet,
  deleteSubnet,
  getDataToShowSubnetDetails,
  getSubnets,
  setSelectedSubnetIndex,
  resetSelectedSubnetIndex,
  updateSubnet,
  setSubnetTableData,
} from './action';
import {getNetworksByCampusId} from '../CampusNetworksTab/action';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';

class CampusSubnetsTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdateSubnetsTableData(prevProps, props) {
    return !_isEqual(prevProps.subnets, props.subnets)
      || !_isEqual(
        props.subnets.map((item) => item.id),
        props.subnetsTableData.map((item) => item.id),
      );
  }

  static shouldUpdateNets(prevProps, props) {
    return !_isEqual(prevProps.campus.id, props.campus.id)
      && !_isNil(props.campus.id);
  }

  constructor(props) {
    super(props);
    this.state = CampusSubnetsTab.initialLocalState;
    this.timer = null;
  }

  componentDidMount() {
    const {campus} = this.props;
    this.props.getSubnets(campus.id);
    this.props.getNetworksByCampusId(campus.id);
    this.props.getPolicies(campus.id);
    this.setSubnetsAutoUpdate();
  }

  componentDidUpdate(prevProps) {
    if (CampusSubnetsTab.shouldUpdateNets(prevProps, this.props)) {
      this.props.getNetworksByCampusId(this.props.campus.id);
      this.props.getPolicies(this.props.campus.id);
      this.gotoSubnetListAndReload();
    } else if (CampusSubnetsTab.shouldUpdateSubnetsTableData(prevProps, this.props)) {
      this.props.setSubnetTableData();
    }
  }

  componentWillUnmount() {
    this.props.resetSelectedSubnetIndex();
    this.stopSubnetsAutoUpdate();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isValidSubnetInfo,
      selectedSubnet,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <SubnetCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateSubnet}
            handleClickButtonSave={this.handleClickButtonSaveNewSubnet}
            isValidSubnetInfo={isValidSubnetInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <SubnetDetails
            handleClickButtonBackInSubnetInfo={this.gotoSubnetListAndReload}
            handleClickButtonCancelEditSubnet={this.handleClickButtonCancelSubnetEditing}
            handleClickButtonDeleteSubnet={this.handleClickButtonDeleteSubnet}
            handleClickButtonEditSubnet={this.handleClickButtonEditSubnet}
            handleClickButtonSaveSubnet={this.handleClickButtonSaveSubnet}
            isEditMode={isEditMode}
            isValidSubnetInfo={isValidSubnetInfo}
            subnetInfo={selectedSubnet}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  getSubnetListOrMessage = () => {
    const {tableMode} = this.state;
    const {networkAgentAllowed, subnetsTableData, isEditMode, selectedSubnetIndex} = this.props;

    if (networkAgentAllowed) {
      return (
        <SubnetTable
          tableData={subnetsTableData}
          mode={tableMode}
          isEditMode={isEditMode}
          selectedSubnetIndex={selectedSubnetIndex}
          handleClickButtonNew={this.showFormCreatingSubnet}
          handleClickSubnetRow={this.showSubnetDetails}
        />
      );
    }
    return (
      <MessageBox
        message={I18n.t('campusPage.subnetsTab.messages.relayUsingNotAllowed')}
      />
    );
  };

  setSubnetsAutoUpdate = () => {
    if (_isNil(this.timer)) {
      this.timer = setInterval(() => this.props.getSubnets(this.props.campus.id), 5000);
    }
  };

  stopSubnetsAutoUpdate = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  handleClickButtonSaveNewSubnet = () => {
    const {
      campus,
      newOrUpdatedSubnet,
    } = this.props;
    this.props.createSubnet(newOrUpdatedSubnet, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.gotoSubnetListAndReload();
        }
      });
  };

  handleClickButtonCancelCreateSubnet = () => {
    this.gotoSubnetListAndReload();
    this.props.changeEditModeAndSubnetState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelSubnetEditing = () => {
    this.props.changeEditModeAndSubnetState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditSubnet = () => {
    this.props.changeEditModeAndSubnetState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveSubnet = () => {
    const {
      campus,
      newOrUpdatedSubnet,
    } = this.props;
    this.props.updateSubnet(newOrUpdatedSubnet, campus.id);
  };

  handleClickButtonDeleteSubnet = () => {
    const {
      campus,
      selectedSubnet,
    } = this.props;
    this.props.deleteSubnet(selectedSubnet.id, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.gotoSubnetListAndReload();
        }
      });
  };

  showFormCreatingSubnet = () => {
    this.stopSubnetsAutoUpdate();
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndSubnetState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedSubnetIndex();
  };

  showSubnetDetails = (subnetIndex) => {
    const {subnets} = this.props;
    this.stopSubnetsAutoUpdate();
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowSubnetDetails(subnets[subnetIndex].id, subnetIndex);
  };

  gotoSubnetListAndReload = () => {
    this.setState(CampusSubnetsTab.initialLocalState);
    this.props.resetSelectedSubnetIndex();
    this.setSubnetsAutoUpdate();
    this.props.getSubnets(this.props.campus.id);
  };

  render() {
    const {modeShowingContainer} = this.state;
    return (
      <ContainerWithListAndForm
        list={this.getSubnetListOrMessage()}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

CampusSubnetsTab.propTypes = {
  campus: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidSubnetInfo: PropTypes.bool.isRequired,
  subnets: PropTypes.array.isRequired,
  subnetsTableData: PropTypes.array.isRequired,
  newOrUpdatedSubnet: PropTypes.object.isRequired,
  selectedSubnetIndex: PropTypes.number.isRequired,
  selectedSubnet: PropTypes.object.isRequired,
  networkAgentAllowed: PropTypes.bool.isRequired,

  changeEditModeAndSubnetState: PropTypes.func.isRequired,
  createSubnet: PropTypes.func.isRequired,
  deleteSubnet: PropTypes.func.isRequired,
  getDataToShowSubnetDetails: PropTypes.func.isRequired,
  getSubnets: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  resetSelectedSubnetIndex: PropTypes.func.isRequired,
  updateSubnet: PropTypes.func.isRequired,
  setSubnetTableData: PropTypes.func.isRequired,
  getNetworksByCampusId: PropTypes.func.isRequired,
};

CampusSubnetsTab.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.campusReducer.policies,
  campus: state.campusReducer.selectedCampus,
  selectedPeriodIndex: state.campusReducer.selectedPeriodIndex,

  newOrUpdatedSubnet: state.subnetEditFormReducer.editableSubnetInfo,
  isValidSubnetInfo: state.subnetEditFormReducer.isValidSubnetInfo,

  accountInfo: state.operatorLayoutReducer.accountInfo,

  isEditMode: state.campusSubnetsTabReducer.isEditMode,
  subnets: state.campusSubnetsTabReducer.subnets,
  subnetsStatusesObject: state.campusSubnetsTabReducer.subnetsStatusesObject,
  subnetsTableData: state.campusSubnetsTabReducer.subnetsTableData,
  selectedSubnet: state.campusSubnetsTabReducer.selectedSubnet,
  selectedSubnetIndex: state.campusSubnetsTabReducer.selectedSubnetIndex,
  networkAgentAllowed: state.campusSubnetsTabReducer.networkAgentAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndSubnetState: bindActionCreators(changeEditModeAndSubnetState, dispatch),
  createSubnet: bindActionCreators(createSubnet, dispatch),
  deleteSubnet: bindActionCreators(deleteSubnet, dispatch),
  getCampusDashboardData: bindActionCreators(getCampusDashboardData, dispatch),
  getDataToShowSubnetDetails: bindActionCreators(getDataToShowSubnetDetails, dispatch),
  getSubnets: bindActionCreators(getSubnets, dispatch),
  getPolicies: bindActionCreators(getLocationPolicies, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  setSelectedSubnetIndex: bindActionCreators(setSelectedSubnetIndex, dispatch),
  resetSelectedSubnetIndex: bindActionCreators(resetSelectedSubnetIndex, dispatch),
  updateSubnet: bindActionCreators(updateSubnet, dispatch),
  setSubnetTableData: bindActionCreators(setSubnetTableData, dispatch),
  getNetworksByCampusId: bindActionCreators(getNetworksByCampusId, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampusSubnetsTab));

export const types = {
  SET_EDITABLE_CLIENT_INFO: 'SET_EDITABLE_CLIENT_INFO',
  RESET_CLIENT_EDIT_FORM_DATA: 'RESET_CLIENT_EDIT_FORM_DATA',
  SET_VALIDATION_CLIENT_INFO: 'SET_VALIDATION_CLIENT_INFO',
  UPDATE_CLIENT_INFO: 'UPDATE_CLIENT_INFO',
  SET_CLIENT_SELECTED_POLICY_INDEX: 'SET_CLIENT_SELECTED_POLICY_INDEX',
  SET_CLIENT_SELECTED_PROFILE_INDEX: 'SET_CLIENT_SELECTED_PROFILE_INDEX',
  SET_CLIENT_SELECTED_PERIOD_INDEX: 'SET_CLIENT_SELECTED_PERIOD_INDEX',
  SET_CLIENT_STATS: 'SET_CLIENT_STATS',
  SET_CLIENT_THREATS: 'SET_CLIENT_THREATS',
  SET_CLIENT_AVERAGE_LATENCY: 'SET_CLIENT_AVERAGE_LATENCY',
  SET_CLIENT_REQUESTS_GRAPH: 'SET_CLIENT_REQUESTS_GRAPH',
};

export const policyTypes = {
  site: 'SITE',
  client: 'CLIENT',
  profileDefault: 'CLIENT_PROFILE_DEFAULT',
  profileOnsite: 'CLIENT_PROFILE_ONSITE',
  profileOffsite: 'CLIENT_PROFILE_OFFSITE',
};

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {types, widgetKeys} from './constants';
import {getOrganizationProfilesData, getProfileIds} from './service';

export function getOrganizationProfiles(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_CLIENT_PROFILES, {organizationId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_PROFILES,
            data: getOrganizationProfilesData(data),
          });
        }
      });
}

export function getOrganizationProfileStats(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_CLIENT_PROFILE_STATS, {organizationId});
  return (dispatch) =>
    get(url, dispatch, true, widgetKeys.ORGANIZATION_PROFILES_STATS)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_PROFILE_STATS,
            data,
          });
        }
      });
}

export function removeProfileFromOrganization(profileIndex, organizationId, profileId) {
  const url = makeUrl(METHODS.REMOVE_CLIENT_PROFILE_FROM_ORGANIZATION);
  const data = {
    organizationId,
    profileId,
  };

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: types.REMOVE_PROFILE_FROM_ORGANIZATION,
            profileIndex,
          });
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to remove profile from organization:', error);
      });
}

export function updateOrganizationProfiles(organizationId, profilesInOrganization) {
  const url = makeUrl(METHODS.UPDATE_ORGANIZATION_PROFILES);
  const data = {
    organizationId,
    profileIds: getProfileIds(profilesInOrganization),
  };

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeProfilesEditMode(false, STATES_ENTITY.NONE));
          dispatch(getOrganizationProfiles(organizationId));
          // dispatch({
          //   type: types.UPDATE_ORGANIZATION_PROFILES,
          //   profilesInOrganization,
          //   profilesNotInOrganization,
          // });
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update organization profiles:', error);
      });
}

export function changeProfilesEditMode(isEditMode) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_PROFILES_EDIT_MODE,
      isEditMode,
    });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';

import './style.css';

const NO_DATA_VALUE = '—';
export default function ResponseDetails(props) {
  const {response, log, isPrint} = props;

  const field = (f) => (
    <>
      <span className="response-details__name">{I18n.t(`responseView.details.${f}`)}</span>
      <span>{_get(response, f, NO_DATA_VALUE)}</span>
    </>
  );

  const control = () => {
    const controlData = _get(response, 'control', null);
    if (!controlData) return NO_DATA_VALUE;

    const component = Object.keys(controlData).map((c) => {
      const value = _get(controlData, c, NO_DATA_VALUE);
      return (
        <tr key={c}>
          <td className="response-details-control__name">{I18n.t(`responseView.details.${c}`)}</td>
          <td>{value}</td>
        </tr>
      );
    });

    return (
      <table className="response-details__control">
        <tbody>
          {component}
        </tbody>
      </table>
    );
  };

  const details = (data, removeFields, pickFields) => {
    let filteredData = data;
    if (pickFields) {
      filteredData = _pick(data, pickFields);
    }
    if (removeFields) {
      filteredData = _omit(data, removeFields);
    }
    return Object.keys(filteredData)
      .map((d) => (
        <div key={d}>
          <span className="response-details__name">{I18n.t(`responseView.detailsList.${d}`)}</span>
          <span>{_get(data, d, NO_DATA_VALUE)}</span>
        </div>
      ));
  };

  const query = (q) => {
    const domain = _get(q, 'domainName', NO_DATA_VALUE);
    const type = _get(q, 'dnsType', NO_DATA_VALUE);
    const dnsClass = _get(q, 'dnsClass', NO_DATA_VALUE);
    const detailsList = details(q);
    return (
      <div className="response-details__info-block" key={uuid()}>
        {I18n.t('responseView.details.queryRow', {domain, type, dnsClass})}
        <div className="response-details__info-details">{detailsList}</div>
      </div>
    );
  };

  const queries = () => {
    const queriesData = _pick(log, ['domainName', 'dnsType', 'dnsClass']);
    return query(queriesData);
  };

  const answer = (a) => {
    const domain = _get(a, 'domainName', NO_DATA_VALUE);
    const recordType = _get(a, 'recordType', NO_DATA_VALUE);
    const dnsClass = _get(a, 'dnsClass', NO_DATA_VALUE);
    const content = _get(a, 'content', NO_DATA_VALUE);
    const detailsList = details(a, ['length']);
    return (
      <div className="response-details__info-block" key={uuid()}>
        {I18n.t('responseView.details.answerRow', {domain, recordType, dnsClass, content})}
        <div className="response-details__info-details">{detailsList}</div>
      </div>
    );
  };

  const answers = () => {
    const answersData = _get(response, 'answers', null);
    if (!answersData) return NO_DATA_VALUE;

    const component = answersData.map((q) => answer(q));

    return (
      <div className="response-details__info">
        {component}
      </div>
    );
  };

  const containerClassName = classNames('response-details', {
    'response-details_print': isPrint,
  });
  return (
    <div className={containerClassName}>
      <div className="response-details__section">{field('id')}</div>

      <div className="response-details__section">
        <span className="response-details__name">{I18n.t('responseView.details.control')}</span>
        {control()}
      </div>

      <div className="response-details__section">{field('question')}</div>
      <div className="response-details__section">{field('answerRRs')}</div>
      <div className="response-details__section">{field('authorityRRs')}</div>
      <div className="response-details__section">{field('additionalRRs')}</div>

      <div className="response-details__section">
        <span className="response-details__name">{I18n.t('responseView.details.queries')}</span>
        <div className="response-details__info">{queries()}</div>
      </div>

      <div className="response-details__section">
        <span className="response-details__name">{I18n.t('responseView.details.answers')}</span>
        {answers()}
      </div>
    </div>
  );
}

ResponseDetails.propTypes = {
  response: PropTypes.object,
  log: PropTypes.object.isRequired,
  isPrint: PropTypes.bool,
};

ResponseDetails.defaultProps = {
  isPrint: false,
  response: {},
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {CustomButton} from '../../material-dashboard-pro-react/components';

import {logout} from '../../actions/auth/logout';
import {refreshAccountsInfo} from './action';

import {ROUTES} from '../../constants';

import style from './style';

class NoAccounts extends Component {
  static wasAccountSwitched(prevProps, props) {
    return prevProps.loggedAccount.accountId !== props.loggedAccount.accountId;
  }

  componentDidMount() {
    this.props.refreshAccountsInfo(this.props.authData);
  }

  componentDidUpdate(prevProps) {
    if (NoAccounts.wasAccountSwitched(prevProps, this.props)) {
      this.props.history.push(ROUTES.DASHBOARD);
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.noAccounts}>
        <p className={classes.noAccounts__message}>
          {I18n.t('noAccountsPage.message')}
        </p>
        <CustomButton
          fullWidth={true}
          color="secondary"
          onClick={() => this.props.logout(this.props.history)}
        >
          {I18n.t('noAccountsPage.logoutButton')}
        </CustomButton>
      </div>
    );
  }
}

NoAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  authData: PropTypes.object.isRequired,

  logout: PropTypes.func.isRequired,
  refreshAccountsInfo: PropTypes.func.isRequired,
};

NoAccounts.defaultProps = {};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  userAccounts: state.userAccountsReducer.userAccounts,
  authData: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
  refreshAccountsInfo: bindActionCreators(refreshAccountsInfo, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(NoAccounts)));

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {I18n} from 'react-redux-i18n';
import {
  ACCESS_RIGHTS,
  COMPRESSED_MODE_TABLE as COMPRESSED_WIDTH,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
} from '../../constants';
import {hasAuthorities} from '../../utils/account';
import {
  EntitiesListWithViewModesCard,
  FilterWithPlaceholderAndFullTextFilterMethod,
} from '../index';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_CREATE],
};

export default class NotificationProfilesTable extends Component {
  get headersFullMode() {
    return [
      {
        Header: I18n.t('notificationsManagementPage.table.email'),
        accessor: 'email',
        maxWidth: 300,
        sortable: true,
        filterable: true,
        ...this.getEmailFilter(),
      },
      {
        Header: I18n.t('notificationsManagementPage.table.description'),
        accessor: 'description',
        sortable: true,
        filterable: false,
      },
      {
        Header: I18n.t('notificationsManagementPage.table.notifications'),
        accessor: 'notifications',
        sortable: true,
        filterable: false,
      },
    ];
  }

  get headersCompressedMode() {
    return [
      {
        Header: null,
        accessor: 'email',
        sortable: true,
        filterable: true,
        ...this.getEmailFilter(),
      },
    ];
  }

  getEmailFilter = () => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t('notificationsManagementPage.table.emailFilterPlaceholder'),
      ['email'],
    )
  );

  render() {
    const mode = this.props.isCompressed ? COMPRESSED_WIDTH : FULL_WIDTH;

    const handleClickNew = hasAuthorities('notificationsManagement')
      ? this.props.handleClickNew
      : null;

    return (
      <EntitiesListWithViewModesCard
        handleClickButtonNew={handleClickNew}
        mode={mode}
        headersFullWidthMode={this.headersFullMode}
        headersCompressedMode={this.headersCompressedMode}
        defaultSorted={[{
          id: 'email',
          desc: false,
        }]}
        tableData={this.props.notificationProfiles}
        disabled={this.props.isEditing}
        title={I18n.t('notificationsManagementPage.table.title')}
        entityType={I18n.t('entitiesTypes.notificationProfile')}
        handleClickRow={this.props.handleClickRow}
        selectedEntityIndex={this.props.selectedNotificationProfileIndex}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

NotificationProfilesTable.propTypes = {
  notificationProfiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notifications: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  })).isRequired,

  handleClickRow: PropTypes.func.isRequired,
  isCompressed: PropTypes.bool.isRequired,
  selectedNotificationProfileIndex: PropTypes.number.isRequired,
  handleClickNew: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

import {
  makeUrl,
  get,
} from '../../utils/fetcher';

import {
  METHODS,
} from '../../constants';

export const types = {
  RESET_PERSONA_FORM_VALUES: 'RESET_PERSONA_FORM_VALUES',
  SET_PERSONA_FORM_VALUES: 'SET_PERSONA',
  UPDATE_BASE_SETTING: 'UPDATE_PERSONA_BASE_SETTING',
  BIND_DOMAIN: 'BIND_PERSONA_DOMAIN',
  UPDATE_CONFIGURED_GROUPS: 'UPDATE_PERSONA_CONFIGURED_GROUPS',
  UPDATE_OBSERVED_GROUPS: 'UPDATE_PERSONA_OBSERVED_GROUPS',
  SET_DIRECTORY_SERVICES: 'SET_DIRECTORY_SERVICES',
};

export function updateBaseSetting(newSettings) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_BASE_SETTING,
      data: newSettings,
    });
  };
}

export function resetPersonaFormValues() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_PERSONA_FORM_VALUES,
    });
  };
}

export function bindDomain(domain) {
  return (dispatch) => {
    dispatch({
      type: types.BIND_DOMAIN,
      domain,
    });
  };
}

export function getGroupsByDomain(domainId) {
  const url = makeUrl(
    METHODS.GET_USER_GROUP_NAMES, {adDomainId: domainId},
  );
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch(updateObservedGroups(response.data));
      }
    });
}

export function updateObservedGroups(groups) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_OBSERVED_GROUPS,
      groups,
    });
  };
}

export function setUpdatedConfiguredGroups(data) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CONFIGURED_GROUPS,
      data,
    });
  };
}

export function updateDirectoryServices(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DIRECTORY_SERVICES,
      data,
    });
  };
}

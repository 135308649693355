import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {EntitiesListWithViewModesCard} from '../index';
import {FULL_WIDTH_MODE_TABLE} from '../../constants';

import style from './style';

class UsageBillingTable extends Component {
  get headers() {
    const sumWrapper = (value) => ((_isNil(value)) ? '' : `$${value}`);
    const totalsGetter = (column) => ((info) =>
      info.data.reduce((sum, item) => item[column] + sum, 0));
    const totalsSumGetter = (column) => ((info) =>
      sumWrapper(info.data.reduce((sum, item) => item[column] + sum, 0)));
    const cellStyle = {textAlign: 'right'};

    return ([
      {
        Header: I18n.t('organizations.usageBilling.table.headers.organization'),
        accessor: 'name',
        Footer: I18n.t('organizations.usageBilling.table.total'),
        filterable: false,
        sortable: true,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.networkQueries'),
        accessor: 'networkQueries',
        Footer: totalsGetter('networkQueries'),
        filterable: false,
        sortable: true,
        width: 180,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.clientQueries'),
        accessor: 'clientQueries',
        Footer: totalsGetter('clientQueries'),
        filterable: false,
        sortable: true,
        width: 180,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.totalQueries'),
        accessor: 'totalQueries',
        Footer: totalsGetter('totalQueries'),
        filterable: false,
        sortable: true,
        width: 180,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.networkSeats'),
        accessor: 'networkSeats',
        Footer: totalsGetter('networkSeats'),
        filterable: false,
        sortable: true,
        width: 120,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.clientSeats'),
        accessor: 'clientSeats',
        Footer: totalsGetter('clientSeats'),
        filterable: false,
        sortable: true,
        width: 120,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.totalSeats'),
        accessor: 'totalSeats',
        Footer: totalsGetter('totalSeats'),
        filterable: false,
        sortable: true,
        width: 120,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.queryCost'),
        accessor: 'queryCost',
        Footer: totalsSumGetter('queryCost'),
        filterable: false,
        sortable: true,
        width: 120,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
      {
        Header: I18n.t('organizations.usageBilling.table.headers.seatCost'),
        accessor: 'seatCost',
        Footer: totalsSumGetter('seatCost'),
        filterable: false,
        sortable: true,
        width: 120,
        headerStyle: cellStyle,
        style: cellStyle,
        footerStyle: cellStyle,
      },
    ]);
  }

  render() {
    const {tableData, classes} = this.props;
    const tableClasses = {
      tableBody: classes.tableBody,
    };
    return (
      <EntitiesListWithViewModesCard
        mode={FULL_WIDTH_MODE_TABLE}
        fullscreen={false}
        headersFullWidthMode={this.headers}
        headersCompressedMode={this.headers}
        tableData={tableData}
        disabled={true}
        title={I18n.t('organizations.usageBilling.table.title')}
        accessRights={{}}
        customCardClasses={classes.card}
        customClasses={tableClasses}
      />
    );
  }
}

UsageBillingTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableData: PropTypes.array,
};

UsageBillingTable.defaultProps = {
  tableData: [],
};

export default withStyles(style)(UsageBillingTable);

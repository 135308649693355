import {get, makeUrl, post} from '../../utils/fetcher';
import {METHODS, MODAL_WINDOW_NOTIFY_TYPE} from '../../constants';
import {
  hideLogin2FAForm,
  hideSecondFactorErrorMessage,
  openLogin2FAForm, setAuthCodeCheckedStatus,
  showSecondFactorErrorMessage,
} from '../../actions/auth/login';
import {logout} from '../../actions/auth/logout';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

export function getAuthCode() {
  const url = makeUrl(METHODS.GET_AUTH_CODE);
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(hideSecondFactorErrorMessage());
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error while getting auth code', error);
        return false;
      });
}

export function verifyAuthCode(code, browserHistory, errorMessageOnTryLimitExceeded) {
  const url = makeUrl(METHODS.VERIFY_AUTH_CODE);
  return (dispatch) =>
    post(url, code, dispatch, false)
      .then((response) => {
        if (response && response.data) {
          const _data = response.data;
          if (_data.authCodeAccepted) {
            dispatch(hideSecondFactorErrorMessage());
            dispatch(hideLogin2FAForm());
            dispatch(setAuthCodeCheckedStatus(true));
            return true;
          }
          if (!_data.tryLimitExceeded) {
            dispatch(showSecondFactorErrorMessage());
            dispatch(openLogin2FAForm());
            return false;
          }
          dispatch(hideLogin2FAForm());
          dispatch(openModalWindow(
            errorMessageOnTryLimitExceeded,
            MODAL_WINDOW_NOTIFY_TYPE.INFO,
          ));
          return false;
        }
        dispatch(hideSecondFactorErrorMessage());
        dispatch(hideLogin2FAForm());
        logout(browserHistory);
        return false;
      })
      .catch(() => {
        dispatch(hideSecondFactorErrorMessage());
        dispatch(hideLogin2FAForm());
        logout(browserHistory);
        return false;
      });
}

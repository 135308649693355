import React, {useState} from 'react';
import {I18n} from 'react-redux-i18n';
import {withStyles, WithStyles} from '@material-ui/core';
import classNames from 'classnames';
import {CellInfo} from 'react-table';

import {useSelector} from 'react-redux';
import {GridContainer, ItemGrid} from '../../material-dashboard-pro-react/components';
import TabsPanel from '../../components/TabsPanel/TabsPanel';
import RenderOrEmpty from '../../components/RenderOrEmpty/RenderOrEmpty';
import UserInfoPanel from '../../components/UserInfoPanel/UserInfoPanel';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';
import {widgetKeys} from '../../app-common/Users/constants';
import UserSessions from '../UserSessions/UserSessions';
import {ADGroup, ADUserDetails} from '../../app-common/Users/types';
import UserDashboard from '../../components/UserDashboard/UserDashboard';
import {formatPolicy} from '../../utils/policy';

import style from './style';

type UserEditFormProps = WithStyles<typeof style> & {
  userInfo: ADUserDetails;
  isEditMode: boolean;
}

function UserEditForm({
  classes,
  userInfo,
  isEditMode,
}: UserEditFormProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const dashboardShown
    = useSelector((store: any) => store.universalDashboardReducer.isShowDashboard);

  const getNetworkInfoData = () => [
    {
      header: I18n.t('users.usersManager.editUserForm.info.status'),
      value: <StatusIndicator
        status={
          userInfo.userStatus ? `DEVICE_AGENT_${userInfo.userStatus}` : null
        }
      />,
      style: {
        paddingTop: '4px',
        paddingBottom: '4px',
        minHeight: '35px',
      },
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.lastSeen'),
      value: userInfo.lastSeenAt,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.wanIp'),
      value: userInfo.lastWanIp,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.lanIp'),
      value: userInfo.lastLanIp,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.lastDevice'),
      value: userInfo.lastDevice,
    },
  ];

  const groupsRenderer = (groups: Array<ADGroup>) => (
    groups?.map((group, index) => (
      <p key={`group_${index}`} className={classes.userEditForm__tableCell_group}>{group.name}</p>
    ))
  );

  const getUserInfo = () => [
    {
      header: I18n.t('users.usersManager.editUserForm.info.username'),
      value: userInfo.name,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.domain'),
      value: userInfo.domain,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.groups'),
      value: groupsRenderer(userInfo.groups),
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.persona'),
      value: userInfo.persona,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.service'),
      value: userInfo.service,
    },
    {
      header: I18n.t('users.usersManager.editUserForm.info.policy'),
      value: formatPolicy(userInfo.policy, userInfo.policyType),
    },
  ];

  const getColumns = (isEditMode: boolean) => {
    const editableCells = [null];

    const valueCellGetter = (isEditMode
      ? (row: CellInfo) => editableCells[row.index] || row.value || null
      : (row: CellInfo) => row.value || null);

    const headerCellGetter = (row: CellInfo) => (
      <span className={classes.tableHeader}>
        {row.value}
      </span>
    );

    return ([
      {
        Header: null,
        accessor: 'header',
        width: 150,
        filterable: false,
        sortable: false,
        isShow: true,
        Cell: headerCellGetter,
      },
      {
        Header: null,
        accessor: 'value',
        width: 200,
        filterable: false,
        sortable: false,
        isShow: true,
        Cell: valueCellGetter,
      },
    ]);
  };

  const getInfoSubTab = () => {
    const tableBodyClasses = {
      tableBody: classNames({
        [classes.userEditForm__tableBody_info]: dashboardShown,
      }),
    };

    return (
      <ItemGrid className={classes.userEditForm__infoSection}>
        <div className={classes.infoSection__item}>
          <UserInfoPanel
            title={I18n.t('users.usersManager.editUserForm.info.title')}
            columns={getColumns(isEditMode)}
            tableData={getUserInfo()}
            customClasses={tableBodyClasses}
          />
        </div>
        <div className={classes.infoSection__item}>
          <UserInfoPanel
            title={I18n.t('users.usersManager.editUserForm.sessions.title')}
            columns={getColumns(false)}
            tableData={getNetworkInfoData()}
            customClasses={tableBodyClasses}
          />
        </div>
      </ItemGrid>
    );
  };

  const getSessionSubTab = () => {
    const tableBodyClasses = {
      tableBody: classNames({
        [classes.userEditForm__tableBody_sessions]: dashboardShown,
        [classes.userEditForm__tableBody_sessions_noDashboard]: !dashboardShown,
      }),
    };
    return (
      <ItemGrid>
        <UserSessions
          userId={userInfo.id}
          customClasses={tableBodyClasses}
          widgetKey={widgetKeys.SESSIONS}
        />
      </ItemGrid>
    );
  };

  const tabs = [
    {
      text: I18n.t('users.usersManager.editUserForm.info.info'),
      name: 'info',
      content: getInfoSubTab(),
    },
    {
      text: I18n.t('users.usersManager.editUserForm.sessions.tabName'),
      name: 'sessions',
      content: getSessionSubTab(),
    },
  ];

  return (
    <GridContainer direction="column" className={classes.userEditForm}>
      <ItemGrid>
        <UserDashboard
          userId={userInfo.id}
        />
      </ItemGrid>
      <ItemGrid>
        <TabsPanel
          activeTabIndex={activeTabIndex}
          disabled={false}
          handleClickTab={(i) => setActiveTabIndex(i)}
          tabs={tabs}
        />
      </ItemGrid>
      <RenderOrEmpty isShow={!!tabs[activeTabIndex]}>
        {tabs[activeTabIndex].content}
      </RenderOrEmpty>
    </GridContainer>
  );
}

export default withStyles(style)(UserEditForm);

import {types} from '../actions/userAccounts/actionTypes';
import initState from './redux-initial-state';
import sortUserAccounts from '../services/userAccounts/sortUserAccounts';

export default (state = initState.userAccountsReducer, action) => {
  switch (action.type) {
    case types.SET_USER_ACCOUNTS: {
      return {
        ...state,
        userAccounts: sortUserAccounts(action.data),
      };
    }
    case types.SET_LOGGED_ACCOUNT_TO_STORE: {
      return {
        ...state,
        loggedAccount: action.data,
      };
    }
    default:
      return state;
  }
};

const style = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  periodSettings: {
    width: '400px',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  dateTime: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '350px',
  },
  periodSelector: {

  },
  datePicker: {
    paddingTop: '18px',
  },
  datePicker_notVisible: {
    visibility: 'hidden',
  },
  actionsAndCosts: {
    width: '250px',
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionBlock: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingTop: '10px',
    width: '100px',
  },
  costBlock: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingRight: '10px',
    width: '250px',
  },
};

export default style;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import {UnauthorizedLayout} from '../../layouts-common';
import {LoginForm2FA} from '../../components';
import {getAuthCode, verifyAuthCode} from './action';

import style from './style';

class AuthCodeChecker extends Component {
  verifyAuthCode = (code, browserHistory) =>
    this.props.verifyAuthCode(code, browserHistory, I18n.t('authCodeChecker.authCodeFailed'))
      .then((success) => {
        if (success) {
          this.props.onSuccess();
        }
      });

  render() {
    // eslint-disable-next-line no-shadow
    const {classes, isAuthCodeFailed, getAuthCode, isOpen, dialogProps} = this.props;
    const dialogClasses = {
      paper: classes.modalWindow__paper,
      root: classes.modalWindow,
    };
    const layoutClasses = {
      unauthorizedLayout: classes.authCodeChecker__layout,
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={() => {}}
          classes={dialogClasses}
          {...dialogProps}
        >
          <div className={classes.authCodeChecker__background} />
          <UnauthorizedLayout classes={layoutClasses} showSpinner={false}>
            <LoginForm2FA
              login2FA={this.verifyAuthCode}
              resendCode={getAuthCode}
              isSecondFactorFailed={isAuthCodeFailed}
              showTrustDeviceCheckbox={false}
            />
          </UnauthorizedLayout>
        </Dialog>
        {this.props.children}
      </>
    );
  }
}

AuthCodeChecker.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array,
  dialogProps: PropTypes.object,
  isOpen: PropTypes.bool,
  isAuthCodeFailed: PropTypes.bool.isRequired,
  getAuthCode: PropTypes.func.isRequired,
  verifyAuthCode: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

AuthCodeChecker.defaultProps = {
  children: null,
  dialogProps: {},
  isOpen: false,
};

const mapDispatchToProps = {
  getAuthCode,
  verifyAuthCode,
};

export default withStyles(style)(connect(null, mapDispatchToProps)(AuthCodeChecker));

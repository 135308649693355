import React from 'react';
import {I18n} from 'react-redux-i18n';
import {WithStyles, withStyles} from '@material-ui/core';
import classNames from 'classnames';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {LoadingLayout} from '../../layouts-common';
import GroupStatsCard from '../GroupStatsCard/GroupStatsCard';
import RenderOrEmpty from '../RenderOrEmpty/RenderOrEmpty';

import {blackColor,
  brightRedColor,
  darkBlueColor,
  darkerGreenColor,
  grayColor,
  lightBlueColor,
  lightRedColor,
  yellowOtherColor} from '../../styles/style';
import style from './style';

type GraphData = {
  categoryName: string;
  data: {
    time: string;
    value: number;
  }[];
}

type TopCategoriesLineChartProps = WithStyles<typeof style> & {
  widgetKey: string;
  customGraphClass?: string;
  graphData: GraphData[];
}

/**
 * Line chart for categories
 * @param {widgetKey} widgetKey - widget key, used for handling data loading
 * @param {customGraphClass} customGraphClass - class for graph
 * @param {graphData} graphData - array of graph data.
 * For object reference look at {@link GraphData}
 */
function TopCategoriesLineChart({classes,
  widgetKey,
  customGraphClass = '',
  graphData}: TopCategoriesLineChartProps) {
  const lineChartStyles = {
    left: '-25px',
  };

  const COLORS = [
    brightRedColor,
    darkerGreenColor,
    yellowOtherColor,
    darkBlueColor,
    lightBlueColor,
    lightRedColor,
    blackColor,
    grayColor,
  ];

  const thereIsNotGraphData = graphData.length === 0;

  const renderAreas = () => (
    graphData.map((category, i) => {
      const color = COLORS[i % COLORS.length];
      return (
        <Area
          key={`area-${i}`}
          type="monotone"
          data={category.data}
          name={category.categoryName}
          dataKey="value"
          stroke={color}
          fillOpacity={0.25}
          fill={color}
        />
      );
    },
    )
  );

  return (
    <GroupStatsCard
      groupName={I18n.t('dashboard.requestsStats.topCategories')}
      content={(
        <LoadingLayout widgetKey={widgetKey}>
          <div className={classes.requestsStats}>
            <RenderOrEmpty isShow={thereIsNotGraphData}>
              <h3 className={classes.requestsStats__thereIsNotGraphData}>
                {I18n.t('dashboard.requestsStats.graphDataNotAvailable')}
              </h3>
            </RenderOrEmpty>
            <RenderOrEmpty isShow={!thereIsNotGraphData}>
              <div
                className={classNames(
                  classes.requestsStats__graph,
                  {
                    [classes.requestsStats__graph_campusDashboard]:
                      !!customGraphClass,
                  },
                )}
              >
                <ResponsiveContainer width="100%" height={250} >
                  <AreaChart
                    data={graphData}
                    style={lineChartStyles}
                  >
                    <XAxis
                      axisLine={false}
                      tickLine={false}
                      height={40}
                      dy={18}
                      dataKey="time"
                      allowDuplicatedCategory={false}
                    />
                    <YAxis dx={-10} />
                    <CartesianGrid vertical={false} />
                    <Tooltip />
                    <Legend />
                    {renderAreas()}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </RenderOrEmpty>
          </div>
        </LoadingLayout>
      )}
    />
  );
}

export default withStyles(style)(TopCategoriesLineChart);

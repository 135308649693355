const style = {
  clientEditForm: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  clientEditForm__dashboardSection: {
  },
  clientEditForm__infoSection: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'justifyContent': 'space-between',
    '@media(max-width: 1065px)': {
      flexDirection: 'column',
    },
  },
  clientEditForm__sessionSection: {
  },
  clientEditForm__sessionSection_disabled: {
    display: 'none',
  },
  infoSection__item: {
    'minWidth': '400px',
    'flexBasis': '49.5%',
    '@media(max-width: 1065px)': {
      width: '100%',
      marginBottom: '10px',
    },
  },
  clientEditForm__tableBody_sessions: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 750px)',
    },
  },
  clientEditForm__tableBody_sessions_noDashboard: {
    '@media(min-height: 480px)': {
      height: 'calc(100vh - 407px)',
    },
  },
  clientEditForm__tableBody_info: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 700px)',
    },
  },
  tableHeader: {
    fontWeight: '400',
  },
  grid: {},
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

import style from './style';

import {TABS as clientsTabs} from '../../app-common/Clients/constants';
import {PERIODS} from '../../constants';
import {TableWithPaginationAndFiltration} from '../../components';
import {LoadingLayout} from '../../layouts-common';
import {cancelRequests} from '../Loading/action';
import {loadClientSessions} from './action';

class ClientSessions extends React.Component {
  static shouldReloadData(prevProps, props) {
    return !_isEqual(prevProps.clientId, props.clientId);
  }

  constructor(props) {
    super(props);
    this.columns = [
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.sessions.headers.sessionStart`),
        accessor: 'startTime',
        width: 180,
        filterable: false,
        sortable: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.sessions.headers.username`),
        accessor: 'username',
        width: 150,
        filterable: false,
        sortable: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.sessions.headers.site`),
        accessor: 'locationName',
        width: 150,
        filterable: false,
        sortable: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.sessions.headers.wan`),
        accessor: 'networkIp',
        width: 120,
        filterable: false,
        sortable: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.sessions.headers.lan`),
        accessor: 'subnetIp',
        width: 120,
        filterable: false,
        sortable: true,
      },
    ];
  }

  componentDidMount() {
    this.loadSessions();
  }

  componentDidUpdate(prevProps) {
    if (ClientSessions.shouldReloadData(prevProps, this.props)) {
      this.loadSessions();
    }
  }

  componentWillUnmount() {
    this.props.cancelRequests([this.props.widgetKey], false);
  }

  loadSessions = () => {
    const {clientId, widgetKey} = this.props;
    if (!_isNil(clientId)) {
      this.props.loadClientSessions(clientId, PERIODS.LAST_MONTH, widgetKey);
    }
  };

  render() {
    const {classes, customClasses, tableData, widgetKey} = this.props;
    const cardHeaderStyle = {
      root: classes.clientSessionsCard__header,
      title: classes.clientSessionsCard__title,
    };
    const tableClasses = {
      tbody: customClasses.tableBody,
    };

    return (
      <LoadingLayout widgetKey={widgetKey}>
        <Card className={classes.clientSessionsCard}>
          <CardHeader
            classes={cardHeaderStyle}
            title={I18n.t(`clients.${clientsTabs.MANAGE}.sessions.title`)}
          />
          <CardContent className={classes.clientSessionsCard__content}>
            <TableWithPaginationAndFiltration
                columns={this.columns}
                data={tableData}
                showPagination={false}
                defaultPageSize={tableData.length}
                pageSize={tableData.length}
                customClasses={tableClasses}
            />
          </CardContent>
        </Card>
      </LoadingLayout>
    );
  }
}

ClientSessions.propTypes = {
  widgetKey: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  loadClientSessions: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

ClientSessions.defaultProps = {
  customClasses: {},
};

const mapStateToProps = (state) => ({
  tableData: state.clientSessionsReducer.sessions,
});

const mapDispatchToProps = (dispatch) => ({
  loadClientSessions: bindActionCreators(loadClientSessions, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ClientSessions));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import _isNil from 'lodash/isNil';

import {NETWORK_STATUSES} from '../../constants';
import {RenderOrEmpty} from '../index';

import style from './style';

const classNamesMap = {
  [NETWORK_STATUSES.RED]: 'indicator_red',
  [NETWORK_STATUSES.YELLOW]: 'indicator_yellow',
  [NETWORK_STATUSES.GREEN]: 'indicator_green',
  [NETWORK_STATUSES.BLACK]: 'indicator_black',
};

function NetworkStatusIndicator(props) {
  const {
    classes,
    networkStatus,
  } = props;
  return (
    <RenderOrEmpty isShow={!_isNil(networkStatus)}>
      <div
        className={classNames(
          classes.indicator,
          classes[classNamesMap[networkStatus]],
        )}
      />
    </RenderOrEmpty>
  );
}

NetworkStatusIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  networkStatus: PropTypes.oneOf([
    NETWORK_STATUSES.RED,
    NETWORK_STATUSES.GREEN,
    NETWORK_STATUSES.YELLOW,
    NETWORK_STATUSES.BLACK,
  ]),
};

NetworkStatusIndicator.defaultProps = {
  networkStatus: undefined,
};

export default withStyles(style)(NetworkStatusIndicator);

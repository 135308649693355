import _sortBy from 'lodash/sortBy';
import initState from '../../reducers/redux-initial-state';

import {types} from './constants';
import {injectProfileStats} from './service';

export default (state = initState.organizationProfilesTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_PROFILES_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    }
    case types.SET_ORGANIZATION_PROFILES: {
      return {
        ...state,
        profilesNotInOrganization: action.data.profilesNotInOrganization,
        profilesInOrganization: injectProfileStats(
          action.data.profilesInOrganization,
          state.profilesInOrganizationStats,
        ),
      };
    }
    case types.SET_ORGANIZATION_PROFILE_STATS: {
      return {
        ...state,
        profilesInOrganization: injectProfileStats(state.profilesInOrganization, action.data),
        profilesInOrganizationStats: action.data,
      };
    }
    case types.REMOVE_PROFILE_FROM_ORGANIZATION: {
      const newProfilesInOrganization = [...state.profilesInOrganization];
      const removedProfile = newProfilesInOrganization.splice(action.profileIndex, 1);
      return {
        ...state,
        profilesInOrganization: newProfilesInOrganization,
        profilesNotInOrganization: _sortBy(
          [...state.profilesNotInOrganization, removedProfile],
          (profile) => profile.name,
        ),
      };
    }
    case types.UPDATE_ORGANIZATION_PROFILES: {
      return {
        ...state,
        profilesInOrganization: [...action.profilesInOrganization],
        profilesNotInOrganization: [...action.profilesNotInOrganization],
      };
    }

    default:
      return state;
  }
};

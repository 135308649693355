import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';

import {
  ButtonAllowBlockCategory,
  CardWithHeader,
} from '../index';

import {CATEGORY_STATUSES} from '../../constants';

import style from './style';

class PolicyCategoriesTab extends Component {
  shouldComponentUpdate(nextProps) {
    return !_isEqual(nextProps.categories, this.props.categories)
      || !_isEqual(nextProps.formScheme, this.props.formScheme)
      || !_isEqual(nextProps.isEditMode, this.props.isEditMode);
  }

  // getCategoryStatus = (category) => (
  //   _isUndefined(category)
  //   || _isEqual(category.isBlocked, false)
  // );

  getGroupItems = (items) => {
    const {
      categories,
      classes,
      isEditMode,
    } = this.props;

    return items.map((item) => {
      const categoryStatus = _get(
        categories[item],
        `values[${_get(categories[item], 'value')}].name`,
        CATEGORY_STATUSES.ALLOWED,
      );
      return (
        <div
          className={classes.block__item}
          key={uuid()}
        >
          <ButtonAllowBlockCategory
            categoryName={item}
            categoryStatus={categoryStatus}
            customClasses="policyCategoriesTab__buttonCategory"
            onClick={this.props.handleChangeCategory}
            disabled={!isEditMode}
          />
        </div>
      );
    });
  };

  getGroups = () => {
    const {
      classes,
      formScheme,
    } = this.props;
    return formScheme.map((item) => (
      <CardWithHeader
        key={uuid()}
        cardTitle={item.groupName}
        cardTitleColor="primary"
        customCardHeaderClasses="card__header_policyEditForm"
        customCardContentClasses="card__content_policyEditForm"
        content={(
          <div className={classes.block__content}>
            {this.getGroupItems(item.children)}
          </div>
        )}
      />
    ));
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.categoriesTab}>
        {this.getGroups()}
      </div>
    );
  }
}

PolicyCategoriesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  formScheme: PropTypes.array,
  categories: PropTypes.object,
  isEditMode: PropTypes.bool,
  handleChangeCategory: PropTypes.func.isRequired,
};

PolicyCategoriesTab.defaultProps = {
  formScheme: [],
  categories: {},
  isEditMode: false,
};

export default withStyles(style)(PolicyCategoriesTab);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';

import _isNil from 'lodash/isNil';
import {PERIODS} from '../../constants';
import {widgetKeys} from './constants';

import style from './style';
import {filterTypes} from '../Filters/constants';
import {
  PerformanceStatsWidget,
  RequestStatsWidget,
  ThreatStatsWidget,
  UniversalDashboard,
} from '../index';

class ClientDashboard extends Component {
  constructor(props) {
    super(props);
    this.periods = [
      // TODO: need to use period keys instead of localized values.
      // TODO: Need to fix it on back-end first.
      ...Object.keys(PERIODS).map((period) =>
        I18n.t(`dashboard.filters.${period}`)),
    ];
  }

  get filtersMetadata() {
    return [
      {
        name: 'period',
        type: filterTypes.DROPDOWN,
        label: '',
        initIndex: 0,
        options: this.periods,
      },
    ];
  }

  get widgets() {
    const {classes, clientId} = this.props;

    return (
      <div
        className={classes.clientDashboard__statsPanel}
      >
        <div
          className={classNames(
            classes.statsPanel__block,
            classes.statsPanel__block_left,
          )}
        >
          <div className={classes.statsPanel__card}>
            <ThreatStatsWidget
              filters={this.getWidgetFilters()}
              readyToLoadStats={!_isNil(clientId)}
              widgetKey={widgetKeys.THREAT_STATS}
            />
          </div>
          <div className={classes.statsPanel__card}>
            <PerformanceStatsWidget
              filters={this.getWidgetFilters()}
              readyToLoadStats={!_isNil(clientId)}
              widgetKey={widgetKeys.PERFORMANCE_STATS}
            />
          </div>
        </div>
        <div
          className={classNames(
            classes.statsPanel__block,
            classes.statsPanel__block_right,
          )}
        >
          <div className={classes.statsPanel__item_graph}>
            <RequestStatsWidget
              filters={this.getWidgetFilters()}
              readyToLoadStats={!_isNil(clientId)}
              widgetKey={widgetKeys.REQUEST_STATS}
            />
          </div>
        </div>
      </div>
    );
  }

  getWidgetFilters = () => {
    const {clientId, filters} = this.props;
    const queryFilters = {clientId};

    if (filters.period >= 0) {
      queryFilters.period = this.periods[filters.period];
    }
    return queryFilters;
  };

  render() {
    return (
      <UniversalDashboard
        filtersMetadata={this.filtersMetadata}
        widgets={this.widgets}
      />
    );
  }
}

ClientDashboard.propTypes = {
  clientId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object,
};

ClientDashboard.defaultProps = {
  clientId: null,
  filters: {},
};

const mapStateToProps = (state) => ({
  filters: state.filtersReducer,
});

export default connect(mapStateToProps, null)(withStyles(style)(ClientDashboard));

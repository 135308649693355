import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import {I18n} from 'react-redux-i18n';

import {
  LocalForwardingTable,
  LocalForwardingDetails,
  LocalForwardingCreating,
  ContainerWithListAndForm,
  MessageBox,
} from '../../components/index';

import {getCampusDashboardData} from '../../app-common/Campus/action';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

import {
  changeEditModeAndLocalForwardingState,
  createLocalForwarding,
  deleteLocalForwarding,
  getDataToShowLocalForwardingDetails,
  getLocalForwardings,
  setSelectedLocalForwardingIndex,
  resetSelectedLocalForwardingIndex,
  updateLocalForwarding,
  setLocalForwardingsTableData,
} from './action';
import {getSubnets} from '../CampusSubnetsTab/action';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';

class CampusLocalForwardingTab extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static shouldUpdateLocalForwardingTableData(prevProps, props) {
    return !_isEqual(prevProps.localForwardings, props.localForwardings);
  }

  static shouldUpdateLocalForwarding(prevProps, props) {
    return !_isEqual(prevProps.campus.id, props.campus.id)
      && !_isNil(props.campus.id);
  }

  constructor(props) {
    super(props);
    this.state = CampusLocalForwardingTab.initialLocalState;
    this.timer = null;
  }

  componentDidMount() {
    const {campus} = this.props;
    this.props.getSubnets(campus.id);
    this.props.getLocalForwardings(campus.id);
    this.setLocalForwardingAutoUpdate();
  }

  componentDidUpdate(prevProps) {
    const {campus} = this.props;
    if (CampusLocalForwardingTab.shouldUpdateLocalForwarding(prevProps, this.props)) {
      this.props.getSubnets(campus.id);
      this.goToLocalForwardingList();
    } else
      if (CampusLocalForwardingTab.shouldUpdateLocalForwardingTableData(prevProps, this.props)) {
        this.props.setLocalForwardingsTableData();
      }
  }

  componentWillUnmount() {
    this.props.resetSelectedLocalForwardingIndex();
    this.stopLocalForwardingAutoUpdate();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isEditMode,
      isValidLocalForwardingInfo,
      selectedLocalForwarding,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <LocalForwardingCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateLocalForwarding}
            handleClickButtonSave={this.handleClickButtonSaveNewLocalForwarding}
            isValidLocalForwardingInfo={isValidLocalForwardingInfo}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <LocalForwardingDetails
            handleClickButtonBackInLocalForwardingInfo={this.goToLocalForwardingList}
            // eslint-disable-next-line max-len
            handleClickButtonCancelEditLocalForwarding={this.handleClickButtonCancelLocalForwardingEditing}
            handleClickButtonDeleteLocalForwarding={this.handleClickButtonDeleteLocalForwarding}
            handleClickButtonEditLocalForwarding={this.handleClickButtonEditLocalForwarding}
            handleClickButtonSaveLocalForwarding={this.handleClickButtonSaveLocalForwarding}
            isEditMode={isEditMode}
            isValidLocalForwardingInfo={isValidLocalForwardingInfo}
            localForwardingInfo={selectedLocalForwarding}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  getLocalForwardingListOrMessage = () => {
    const {tableMode} = this.state;
    const {
      networkAgentAllowed,
      localForwardingsTableData,
      isEditMode,
      selectedLocalForwardingIndex,
    } = this.props;

    if (networkAgentAllowed) {
      return (
        <LocalForwardingTable
          tableData={localForwardingsTableData}
          mode={tableMode}
          isEditMode={isEditMode}
          selectedLocalForwardingIndex={selectedLocalForwardingIndex}
          handleClickButtonNew={this.showFormCreatingLocalForwarding}
          handleClickLocalForwardingRow={this.showLocalForwardingDetails}
        />
      );
    }
    return (
      <MessageBox
        message={I18n.t('campusPage.localForwardingsTab.messages.relayUsingNotAllowed')}
      />
    );
  };

  setLocalForwardingAutoUpdate = () => {
    if (_isNil(this.timer)) {
      this.timer = setInterval(() => this.props.getLocalForwardings(this.props.campus.id), 5000);
    }
  };

  stopLocalForwardingAutoUpdate = () => {
    clearInterval(this.timer);
  };

  handleClickButtonSaveNewLocalForwarding = () => {
    const {
      campus,
      newOrUpdatedLocalForwarding,
    } = this.props;
    this.props.createLocalForwarding(newOrUpdatedLocalForwarding, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToLocalForwardingList();
        }
      });
  };

  handleClickButtonCancelCreateLocalForwarding = () => {
    this.goToLocalForwardingList();
    this.props.changeEditModeAndLocalForwardingState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelLocalForwardingEditing = () => {
    this.props.changeEditModeAndLocalForwardingState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditLocalForwarding = () => {
    this.props.changeEditModeAndLocalForwardingState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveLocalForwarding = () => {
    const {
      campus,
      newOrUpdatedLocalForwarding,
    } = this.props;
    this.props.updateLocalForwarding(newOrUpdatedLocalForwarding, campus.id);
  };

  handleClickButtonDeleteLocalForwarding = () => {
    const {
      campus,
      selectedLocalForwarding,
    } = this.props;
    this.props.deleteLocalForwarding(selectedLocalForwarding.id, campus.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToLocalForwardingList();
        }
      });
  };

  showFormCreatingLocalForwarding = () => {
    this.stopLocalForwardingAutoUpdate();
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndLocalForwardingState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedLocalForwardingIndex();
  };

  showLocalForwardingDetails = (localForwardingIndex) => {
    const {localForwardings} = this.props;
    this.stopLocalForwardingAutoUpdate();
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowLocalForwardingDetails(
      localForwardings[localForwardingIndex].id,
      localForwardingIndex,
    );
  };

  goToLocalForwardingList = () => {
    this.setState(CampusLocalForwardingTab.initialLocalState);
    this.props.resetSelectedLocalForwardingIndex();
    this.setLocalForwardingAutoUpdate();
    this.props.getLocalForwardings(this.props.campus.id);
  };

  render() {
    const {modeShowingContainer} = this.state;
    return (
      <ContainerWithListAndForm
        list={this.getLocalForwardingListOrMessage()}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

CampusLocalForwardingTab.propTypes = {
  campus: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidLocalForwardingInfo: PropTypes.bool.isRequired,
  localForwardings: PropTypes.array.isRequired,
  localForwardingsTableData: PropTypes.array.isRequired,
  newOrUpdatedLocalForwarding: PropTypes.object.isRequired,
  selectedLocalForwardingIndex: PropTypes.number.isRequired,
  selectedLocalForwarding: PropTypes.object.isRequired,
  networkAgentAllowed: PropTypes.bool.isRequired,

  changeEditModeAndLocalForwardingState: PropTypes.func.isRequired,
  createLocalForwarding: PropTypes.func.isRequired,
  deleteLocalForwarding: PropTypes.func.isRequired,
  getDataToShowLocalForwardingDetails: PropTypes.func.isRequired,
  getLocalForwardings: PropTypes.func.isRequired,
  setLocalForwardingsTableData: PropTypes.func.isRequired,
  resetSelectedLocalForwardingIndex: PropTypes.func.isRequired,
  updateLocalForwarding: PropTypes.func.isRequired,
  getSubnets: PropTypes.func.isRequired,
};

CampusLocalForwardingTab.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.policyReducer.policies,

  campus: state.campusReducer.selectedCampus,
  selectedPeriodIndex: state.campusReducer.selectedPeriodIndex,

  newOrUpdatedLocalForwarding: state.localForwardingEditFormReducer.editableLocalForwardingInfo,
  isValidLocalForwardingInfo: state.localForwardingEditFormReducer.isValidLocalForwardingInfo,

  accountInfo: state.operatorLayoutReducer.accountInfo,

  isEditMode: state.campusLocalForwardingTabReducer.isEditMode,
  localForwardings: state.campusLocalForwardingTabReducer.localForwardings,
  localForwardingsTableData: state.campusLocalForwardingTabReducer.localForwardingsTableData,
  selectedLocalForwarding: state.campusLocalForwardingTabReducer.selectedLocalForwarding,
  selectedLocalForwardingIndex: state.campusLocalForwardingTabReducer.selectedLocalForwardingIndex,
  networkAgentAllowed: state.campusLocalForwardingTabReducer.networkAgentAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndLocalForwardingState:
    bindActionCreators(changeEditModeAndLocalForwardingState, dispatch),
  createLocalForwarding: bindActionCreators(createLocalForwarding, dispatch),
  deleteLocalForwarding: bindActionCreators(deleteLocalForwarding, dispatch),
  getCampusDashboardData: bindActionCreators(getCampusDashboardData, dispatch),
  getDataToShowLocalForwardingDetails:
    bindActionCreators(getDataToShowLocalForwardingDetails, dispatch),
  getLocalForwardings: bindActionCreators(getLocalForwardings, dispatch),
  setLocalForwardingsTableData: bindActionCreators(setLocalForwardingsTableData, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  setSelectedLocalForwardingIndex: bindActionCreators(setSelectedLocalForwardingIndex, dispatch),
  resetSelectedLocalForwardingIndex:
    bindActionCreators(resetSelectedLocalForwardingIndex, dispatch),
  updateLocalForwarding: bindActionCreators(updateLocalForwarding, dispatch),
  getSubnets: bindActionCreators(getSubnets, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampusLocalForwardingTab));

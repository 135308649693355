import {I18n} from 'react-redux-i18n';
import {getResourceWithInterrupt, makeUrlForResource} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const getReport = (reportName) => {
  const errorMessageObject = {
    401: I18n.t('logPage.errorMessages.reportInvalidData'),
    403: I18n.t('logPage.errorMessages.reportAccessForbidden'),
    404: I18n.t('logPage.errorMessages.reportNotFound'),
    0: I18n.t('logPage.errorMessages.loadReportError'),
  };

  if (reportName != null) {
    const url = makeUrlForResource(METHODS.GET_AUDIT_REPORT, reportName);
    return (dispatch) => {
      const requestInfo = getResourceWithInterrupt(url, dispatch, errorMessageObject);
      requestInfo.request
        .then((response) => {
          if (response && response.ok) {
            response.blob().then((blob) => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              const alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = reportName;
              alink.click();
            });
          }
        });
    };
  }
  return () => {};
};

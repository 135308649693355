import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {WhiteBlackListEditForm} from '../../containers/index';

import {ACCESS_RIGHTS, ENTITY_OPENING_MODE} from '../../constants';

import {hasOrgTag} from '../../utils/tags';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.CUSTOM_LIST_EDIT],
  buttonDelete: [ACCESS_RIGHTS.CUSTOM_LIST_DELETE],
};

const rightAvailabilityMapOrgOnly = {
  buttonEdit: [ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG],
  buttonDelete: [ACCESS_RIGHTS.CUSTOM_LIST_DELETE__ORG],
};

function WhitelistDetails(props) {
  const {
    handleClickButtonBack,
    handleClickButtonCancelEdit,
    handleClickButtonDelete,
    handleClickButtonEdit,
    handleClickButtonSave,
    isEditMode,
    isValidWhiteBlackListInfo,
    whiteBlackListInfo,
  } = props;

  const organizationPolicy = hasOrgTag(whiteBlackListInfo.tags);

  let rightAvailabilityMapFinal;
  if (!organizationPolicy) {
    rightAvailabilityMapFinal = rightAvailabilityMap;
  } else {
    rightAvailabilityMapFinal = rightAvailabilityMapOrgOnly;
  }

  return (
    <EntityEditingCard
        title={whiteBlackListInfo.name}
        entityType={I18n.t('entitiesTypes.wbList')}
        isEditMode={isEditMode}
        isTherePathBack={true}
        isThereDeleteBlock={true}
        isThereEditBlock={true}
        isFullscreen={false}
        isValidEntity={isValidWhiteBlackListInfo}
        handleClickButtonBack={handleClickButtonBack}
        handleClickButtonCancel={handleClickButtonCancelEdit}
        handleClickButtonDelete={handleClickButtonDelete}
        handleClickButtonEdit={handleClickButtonEdit}
        handleClickButtonSave={handleClickButtonSave}
        accessRights={rightAvailabilityMapFinal}
        content={<WhiteBlackListEditForm mode={ENTITY_OPENING_MODE.EDIT} />}
    />
  );
}

WhitelistDetails.propTypes = {
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidWhiteBlackListInfo: PropTypes.bool.isRequired,
  whiteBlackListInfo: PropTypes.object.isRequired,
};

export default WhitelistDetails;

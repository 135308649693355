import React, {Component} from 'react';
import {I18n} from 'react-redux-i18n';

import {TABS} from '../../app-common/Insights/constants';
import {InsightsLayout} from '../../layouts-common';
import {TldInsightsFilter} from '../index';

class TldInsights extends Component {
  nameFilter = ({filter, onChange}) => (
    <input
      type="text"
      maxLength="255"
      onChange={(e) => onChange(e.target.value)}
      style={{width: '100%'}}
      value={filter && filter.value ? filter.value : ''}
    />
  );

  typeFilter = ({filter, onChange}) => (
    <input
      type="text"
      maxLength="255"
      onChange={(e) => onChange(e.target.value)}
      style={{width: '100%'}}
      value={filter && filter.value ? filter.value : ''}
    />
  );

  getHeaders = () => [
    {
      Header: I18n.t(`insights.${TABS.TLD}.table.name`),
      accessor: 'name',
      Filter: this.nameFilter,
    },
    {
      Header: I18n.t(`insights.${TABS.TLD}.table.type`),
      accessor: 'type',
      Filter: this.typeFilter,
    },
    {
      Header: I18n.t(`insights.${TABS.TLD}.table.count`),
      accessor: 'count',
      filterable: false,
    },
  ];

  render() {
    const columns = this.getHeaders();
    return (
      <InsightsLayout
        tab={TABS.TLD}
        columns={columns}
        filters={<TldInsightsFilter />}
      />
    );
  }
}

export default TldInsights;

import React from 'react';
import PropTypes from 'prop-types';

function Clients(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         version="1.1"
         aria-hidden="true"
         data-prefix="fas"
         data-icon="clients"
         className={props.className}
         role="img"
    >
      <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />
      <g className="currentLayer">
        <title>Layer 1</title>
        <path fill="rgb(0,0,0)"
          stroke="rgb(0,0,0)"
          strokeWidth="0"
          opacity="0"
          d="M-0.04254913200735902,0.04254914201736426 L37.702130867992636,0.12765914201736445 L38.042550867992645,31.957449142017367 L-0.04254913200735902,31.957449142017367 L-0.04254913200735902,0.04254914201736426 zM5.970890867992638,7.023929142017366 L5.970890867992638,21.984039142017366 L3.9664108679926358,21.984039142017366 L4.968650867992636,24.976069142017366 L20.00224086799264,24.976069142017366 L20.00224086799264,21.984039142017366 L8.977610867992638,21.984039142017366 L8.977610867992638,9.018619142017364 L29.022400867992644,9.018619142017364 L29.022400867992644,7.023929142017366 L5.970890867992638,7.023929142017366 zM23.00896086799264,11.013299142017367 L23.00896086799264,24.976069142017366 L32.029120867992646,24.976069142017366 L32.029120867992646,11.013299142017367 L23.00896086799264,11.013299142017367 z"
          id="svg_1"
          className="selected"
        />
        <path fill="rgb(0,0,0)"
          stroke="rgb(0,0,0)"
          strokeWidth="0"
          opacity="0"
          d="M23.41269838809967,7.3829789012670535 L28.41269838809967,7.3829789012670535 L28.41269838809967,16.38297890126705 L23.41269838809967,16.38297890126705 L23.41269838809967,7.3829789012670535 z"
          id="svg_2"
        />
        <path fill="rgb(254,254,254)"
          stroke="rgb(254,254,254)"
          strokeWidth="0"
          opacity="1"
          d="M5.884255290031433,1.3829789012670546 L27.082127302885056,1.3829789012670546 L27.553191125392914,3.3829789012670535 L8.710638225078583,3.3829789012670535 L8.710638225078583,16.38297890126705 L18.602978497743607,16.38297890126705 L19.074042320251465,19.38297890126705 L4.471063822507858,19.38297890126705 L4,16.38297890126705 L5.884255290031433,15.882978901267052 L5.884255290031433,1.3829789012670546 z"
          id="svg_3"
        />
        <path fill="rgb(254,254,254)"
          stroke="rgb(254,254,254)"
          strokeWidth="0"
          opacity="1"
          d="M5.884255290031433,1.3829789012670546 L27.082127302885056,1.3829789012670546 L27.553191125392914,3.3829789012670535 L8.710638225078583,3.3829789012670535 L8.710638225078583,16.38297890126705 L18.602978497743607,16.38297890126705 L19.074042320251465,19.38297890126705 L4.471063822507858,19.38297890126705 L4,16.38297890126705 L5.884255290031433,15.882978901267052 L5.884255290031433,1.3829789012670546 z"
          id="svg_6"
        />
        <path fill="rgb(254,254,254)"
          stroke="rgb(254,254,254)"
          strokeWidth="0"
          opacity="1"
          d="M21.97872343659401,5.3829789012670535 L30.97872343659401,5.3829789012670535 L30.97872343659401,19.38297890126705 L21.97872343659401,19.38297890126705 L21.97872343659401,5.3829789012670535 zM23.97872343659401,7.3829789012670535 L23.97872343659401,16.38297890126705 L28.97872343659401,16.38297890126705 L28.97872343659401,7.3829789012670535 L23.97872343659401,7.3829789012670535 z"
          id="svg_4"
        />
        <path fill="rgb(254,254,254)"
          stroke="rgb(254,254,254)"
          strokeWidth="0"
          opacity="1"
          d="M21.97872343659401,5.3829789012670535 L30.97872343659401,5.3829789012670535 L30.97872343659401,19.38297890126705 L21.97872343659401,19.38297890126705 L21.97872343659401,5.3829789012670535 zM23.97872343659401,7.3829789012670535 L23.97872343659401,16.38297890126705 L28.97872343659401,16.38297890126705 L28.97872343659401,7.3829789012670535 L23.97872343659401,7.3829789012670535 z"
          id="svg_5"
        />
      </g>
    </svg>
  );
}

Clients.propTypes = {
  className: PropTypes.string,
};

Clients.defaultProps = {
  className: '',
};

export default Clients;

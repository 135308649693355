import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_EDITABLE_LOCAL_FORWARDING_INFO: 'SET_EDITABLE_LOCAL_FORWARDING_INFO',
  SET_VALIDATION_LOCAL_FORWARDING_INFO: 'SET_VALIDATION_LOCAL_FORWARDING_INFO',
  RESET_LOCAL_FORWARDING_EDIT_FORM_DATA: 'RESET_LOCAL_FORWARDING_EDIT_FORM_DATA',
  UPDATE_LOCAL_FORWARDING_INFO: 'UPDATE_LOCAL_FORWARDING_INFO',
  SET_SUBNET_LIST: 'SET_SUBNET_LIST',
};

export function setEditableLocalForwardingInfo(localForwarding) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_LOCAL_FORWARDING_INFO,
      data: localForwarding,
    });
  };
}

export function updateLocalForwardingInfo(updatedLocalForwardingInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_LOCAL_FORWARDING_INFO,
      data: updatedLocalForwardingInfo,
      isValidLocalForwardingInfo: isValid,
    });
  };
}

export function resetLocalForwardingEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_LOCAL_FORWARDING_EDIT_FORM_DATA,
    });
  };
}

export function setValidationLocalForwardingInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_LOCAL_FORWARDING_INFO,
      isValid,
    });
  };
}

export function getSubnets(campusId) {
  const url = makeUrl(METHODS.GET_SUBNETS, {locationId: campusId});
  return (dispatch) =>
    get(url)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: types.SET_SUBNET_LIST,
            subnets: response.data.subnets,
          });
        }
      });
}

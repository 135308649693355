import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {v4 as uuid} from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CustomInput} from '../../material-dashboard-pro-react/components';

import {Delete} from '../../icons';
import {RenderOrEmpty} from '../index';

class InputList extends Component {
  render() {
    const {
      items,
      isEditMode,
      classes,
      inputProps,
      validation,
      getItemErrorMessage,
      onRemoveItem,
      onChangeItem,
    } = this.props;

    return (
      <div className={classes.inputs}>
        {items.map((ip, index) => {
          const propsInput = {
            disabled: !isEditMode,
            value: items[index],
            name: 'items',
            onChange: (e) => onChangeItem(e, index),
            type: 'text',
            endAdornment: (
              <RenderOrEmpty isShow={isEditMode && items.length > 1} >
                <InputAdornment position="end">
                  <IconButton
                      onClick={() => onRemoveItem(index)}
                  >
                    <Delete />
                  </IconButton>
                </InputAdornment>
              </RenderOrEmpty>
            ),
            ...inputProps,
          };
          const propsFormControl = {
            fullWidth: true,
            classes: {
              root: classes.formControl,
            },
          };

          return (
            <CustomInput
                key={`item-${index}`}
                errorText={getItemErrorMessage(index)}
                error={!validation[index]}
                id={uuid()}
                formControlProps={propsFormControl}
                inputProps={propsInput}
            />
          );
        })}
      </div>
    );
  }
}

InputList.propTypes = {
  items: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  inputProps: PropTypes.object,
  validation: PropTypes.array,
  getItemErrorMessage: PropTypes.func,
  onRemoveItem: PropTypes.func.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

InputList.defaultProps = {
  classes: {
    inputs: '',
    formControl: '',
  },
  inputProps: {},
  validation: [],
  getItemErrorMessage: () => '',
};

export default InputList;

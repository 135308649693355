import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import _isEqual from 'lodash/isEqual';

import {PerformanceStats} from '../../components';
import {getPerformanceStats} from './action';
import {cancelRequests} from '../Loading/action';

class PerformanceStatsWidget extends React.Component {
  componentDidMount() {
    const {filters, widgetKey, readyToLoadStats} = this.props;
    this.props.cancelRequests([widgetKey], false);
    if (readyToLoadStats) {
      this.props.getPerformanceStats(filters, widgetKey);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.readyToLoadStats && (
      !_isEqual(prevProps.filters, this.props.filters)
        || !_isEqual(prevProps.widgetKey, this.props.widgetKey)
    )
    ) {
      this.props.cancelRequests([prevProps.widgetKey], false);
      this.props.getPerformanceStats(this.props.filters, this.props.widgetKey);
    }
  }

  render() {
    const {
      classes,
      requestsPerSecond,
      averageLatency,
      widgetKey,
    } = this.props;

    return (
      <PerformanceStats
        classes={classes}
        requestsPerSecond={requestsPerSecond}
        averageLatency={averageLatency}
        widgetKey={widgetKey}
      />
    );
  }
}

PerformanceStatsWidget.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.object,
  readyToLoadStats: PropTypes.bool,
  requestsPerSecond: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  averageLatency: PropTypes.number.isRequired,
  widgetKey: PropTypes.string.isRequired,
  getPerformanceStats: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
};

PerformanceStatsWidget.defaultProps = {
  classes: {},
  filters: {},
  readyToLoadStats: true,
};

const mapStateToProps = (state) => ({
  requestsPerSecond: state.performanceStatsWidgetReducer.requestsPerSecond,
  averageLatency: state.performanceStatsWidgetReducer.averageLatency,
});

const mapDispatchToProps = (dispatch) => ({
  getPerformanceStats: bindActionCreators(getPerformanceStats, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceStatsWidget);

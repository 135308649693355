import {getLoggedAccount} from '../../utils/account';

import {types} from './actionTypes';

export function setLoggedAccountToStore() {
  return (dispatch) => {
    dispatch({
      type: types.SET_LOGGED_ACCOUNT_TO_STORE,
      data: getLoggedAccount(),
    });
  };
}

import {defaultFont} from '../../styles/style';

const style = {
  form: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  form__item: {},
  form__error: {
    ...defaultFont,
    fontSize: '14px',
  },
  form__ipsBlock: {
    marginTop: '20px',
  },
  form__ips: {
    padding: '5px',
  },
};

export default style;

import _sortBy from 'lodash/sortBy';

import {get, makeUrl} from '../../utils/fetcher';
import {convertThreatNames} from '../../utils/dashboard';
import {METHODS} from '../../constants';
import {types} from './constants';

export function getThreats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_CLIENT_THREATS, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        const data = _sortBy(response.data, (item) => item.threat);
        if (response && response.data) {
          dispatch({
            type: types.SET_THREATS,
            data: convertThreatNames(data),
          });
        } else {
          dispatch({
            type: types.SET_THREATS,
            data: [],
          });
        }
      });
}

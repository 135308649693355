import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export function getPersonasTableData(personas) {
  return personas.map((item) => ({
    name: item.name,
    description: item.description,
    tags: item.tags,
  }));
}

export function convertServerPersona(persona) {
  return ({
    ...persona,
    configuredGroups: persona.configuredGroups.map((group) => ({
      ...group,
      id: group.adGroupId,
      name: group.adGroupName,
      adGroupId: undefined,
      adGroupName: undefined,
    })),
  });
}

export function getPersonaToUpdate(newData) {
  return ({
    ...newData,
    domain: undefined,
    domainId: undefined,
    adDomainId: newData.domainId,
  });
}

export function getPersonaToCreate(newData) {
  const newPersona = getPersonaToUpdate(newData);
  delete newPersona.id;
  return newPersona;
}

export function getUpdatedPersonaIndex(updatedItemId, personas) {
  return _findIndex(personas, (o) => _isEqual(o.id, updatedItemId));
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {RELAY_STATUS_INDICATOR_MODES} from '../../constants';

import style from './style';

// eslint-disable-next-line no-shadow
function StatusIndicator({classes, customClasses, status, mode}) {
  if (_isNil(status)) {
    return null;
  }

  let customClassesStr = '';
  if (customClasses) {
    customClasses.split(' ').forEach((classItem) => {
      customClassesStr = classNames(customClassesStr, classes[classItem]);
    });
  }

  return (mode === RELAY_STATUS_INDICATOR_MODES.COMPACT ? (
    <div
      className={classNames(classes.compact, classes[`compact_${status}`], customClassesStr)}
    />
  ) : (
    <div className={classNames(classes.full, classes[`full_${status}`], customClassesStr)}>
      {I18n.t(`statusIndicator.statuses.${status}`)}
    </div>
  ));
}

StatusIndicator.propTypes = {
  mode: PropTypes.oneOf(Object.keys(RELAY_STATUS_INDICATOR_MODES)),
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.string,
  status: PropTypes.string,
};

StatusIndicator.defaultProps = {
  mode: RELAY_STATUS_INDICATOR_MODES.FULL,
  customClasses: '',
  status: null,
};

export default withStyles(style)(StatusIndicator);

import {types} from './constants';
import initState from '../../reducers/redux-initial-state';
import {RequestsByCategory} from './types';

export default (
  state = initState.requestStatsByCategoriesWidgetReducer,
  action: {type: string, data: RequestsByCategory},
) => {
  switch (action.type) {
    case types.SET_USER_REQUESTS_BY_CATEGORIES: {
      return {
        ...state,
        userRequestsByCategories: action.data,
      };
    }
    default:
      return state;
  }
};

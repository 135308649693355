import {types} from './constants';
import {makeUrl, post, get} from '../../utils/fetcher';

import {METHODS, STATES_ENTITY} from '../../constants';
import {getClientLocalDomainsData, getLocalForwardingToCreateOrUpdate} from './utils';

export const loadClientLocalDomains = (profileId) => {
  const url = makeUrl(METHODS.GET_CLIENT_LOCAL_DOMAINS, {profileId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_CLIENT_LOCAL_DOMAINS,
          localDomains: getClientLocalDomainsData(response.data),
        });
      }
    });
};

export const resetClientLocalForwardingFormData = () => (dispatch) => dispatch({
  type: types.RESET_CLIENT_LOCAL_FORWARDING_FORM_DATA,
});

export function getClientLocalDomainById(domainId) {
  const url = makeUrl(METHODS.GET_CLIENT_LOCAL_FORWARDING_BY_ID, {id: domainId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_SELECTED_CLIENT_LOCAL_FORWARDING,
            localForwarding: response.data,
          });
        }
      });
}

export function createClientLocalForwarding(newLocalForwarding) {
  const url = makeUrl(METHODS.CREATE_CLIENT_LOCAL_FORWARDING);
  return (dispatch) =>
    post(url, getLocalForwardingToCreateOrUpdate(newLocalForwarding), dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndLocalForwardingState(false, STATES_ENTITY.NONE));
          dispatch(loadClientLocalDomains(newLocalForwarding.profileId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create roaming client local forwarding:', error);
      });
}

export function updateClientLocalForwarding(updatedLocalForwarding) {
  const url = makeUrl(METHODS.UPDATE_CLIENT_LOCAL_FORWARDING);
  return (dispatch) =>
    post(url, getLocalForwardingToCreateOrUpdate(updatedLocalForwarding), dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(loadClientLocalDomains(updatedLocalForwarding.profileId)).then(() => dispatch({
            type: types.SET_UPDATED_CLIENT_LOCAL_FORWARDING,
            localForwarding: response.data,
          }));
          dispatch(changeEditModeAndLocalForwardingState(false, STATES_ENTITY.NONE));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit roaming client local forwarding:', error);
      });
}

export function deleteClientLocalForwarding(domainId, profileId) {
  const data = {
    id: domainId,
  };
  const url = makeUrl(METHODS.DELETE_CLIENT_LOCAL_FORWARDING);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(loadClientLocalDomains(profileId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete roaming client local forwarding:', error);
      });
}

export function changeEditModeAndLocalForwardingState(isEditMode, localForwardingState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_CLIENT_LOCAL_FORWARDING_STATE,
      isEditMode,
      localForwardingState: localForwardingState,
    });
  };
}

export function getDataToShowLocalForwardingDetails(domainId, domainIndex) {
  return (dispatch) => {
    dispatch(getClientLocalDomainById(domainId));
    dispatch(setSelectedLocalDomainIndex(domainIndex));
  };
}

export function setSelectedLocalDomainIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CLIENT_LOCAL_DOMAIN_INDEX,
      selectedLocalDomainIndex: index,
    });
  };
}

export function resetSelectedLocalDomainIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_CLIENT_LOCAL_FORWARDING_INDEX,
    });
  };
}

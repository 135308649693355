import _isNaN from 'lodash/isNaN';

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  getDataToAssignBlockPageToNetwork,
  getNetworkToCreate,
  getNetworkToUpdate,
} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {widgetKeys} from '../../app-common/Campus/constants';

export const types = {
  CHANGE_EDIT_MODE_AND_NETWORK_STATE: 'CHANGE_EDIT_MODE_AND_NETWORK_STATE',
  SET_CAMPUS_NETWORKS: 'SET_CAMPUS_NETWORKS',
  SET_NETWORKS_STATUS: 'SET_NETWORKS_STATUS',
  SET_NETWORKS_TABLE_DATA: 'SET_NETWORKS_TABLE_DATA',
  SET_SELECTED_NETWORK: 'SET_SELECTED_NETWORK',
  SET_SELECTED_NETWORK_INDEX: 'SET_SELECTED_NETWORK_INDEX',
  SET_UPDATED_NETWORK: 'SET_UPDATED_NETWORK',
  RESET_SELECTED_NETWORK_INDEX: 'RESET_SELECTED_NETWORK_INDEX',
};

export function getNetworksByCampusId(campusId) {
  const url = makeUrl(METHODS.GET_NETWORKS_BY, {locationId: campusId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_CAMPUS_NETWORKS,
            data,
          });
          dispatch({
            type: types.SET_NETWORKS_TABLE_DATA,
          });
        }
      });
}

export function getNetworkById(networkId) {
  const url = makeUrl(METHODS.GET_NETWORK_BY_ID, {id: networkId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_SELECTED_NETWORK,
            data,
          });
        }
      });
}

export function createNetwork(newNetwork, campusId) {
  const url = makeUrl(METHODS.CREATE_NETWORK);
  const data = getNetworkToCreate(newNetwork, campusId);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndNetworkState(false, STATES_ENTITY.NONE));
          dispatch(assignBlockPageToNetwork(newNetwork.blockPageId, response.data.id));
          dispatch(updateNetworksTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create network:', error);
      });
}

export function assignBlockPageToNetwork(blockPageId, networkId) {
  const url = makeUrl(METHODS.ASSIGN_BLOCK_PAGE_TO_NETWORK);
  const data = getDataToAssignBlockPageToNetwork(blockPageId, networkId);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to assign block page to the network:', error);
      });
}

export function updateNetwork(updatedNetwork, selectedNetwork, campusId) {
  const url = makeUrl(METHODS.UPDATE_NETWORK);
  const data = getNetworkToUpdate(updatedNetwork, campusId);

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndNetworkState(false, STATES_ENTITY.NONE));
          if (updatedNetwork.blockPageId === selectedNetwork.blockPageId) {
            dispatch(getNetworksByCampusId(campusId))
              .then(() => dispatch({
                type: types.SET_UPDATED_NETWORK,
                data: response.data,
              }));
          } else {
            dispatch(assignBlockPageToNetwork(updatedNetwork.blockPageId, response.data.id))
              .then(() => dispatch(getNetworksByCampusId(campusId))
                .then(() => dispatch({
                  type: types.SET_UPDATED_NETWORK,
                  data: response.data,
                })));
          }
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit network:', error);
      });
}

export function deleteNetwork(networkId, campusId) {
  const data = {
    id: networkId,
  };
  const url = makeUrl(METHODS.DELETE_NETWORK);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(updateNetworksTabData(campusId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete network:', error);
      });
}

export function getNetworksCount() {
  const url = makeUrl(METHODS.GET_NETWORKS_COUNT);
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && !_isNaN(response.data)) {
          return response.data;
        }
        return -1;
      });
}

export function getDataToShowNetworkDetails(networkId, networkIndex) {
  return (dispatch) => {
    dispatch(getNetworkById(networkId));
    dispatch(setSelectedNetworkIndex(networkIndex));
  };
}

export function getNetworksStatuses(widgetKey = widgetKeys.NETWORK_STATUSES) {
  const url = makeUrl(METHODS.GET_NETWORKS_STATUS);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_NETWORKS_STATUS,
            data,
          });
        }
      });
}

export function changeEditModeAndNetworkState(isEditMode, networkState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_NETWORK_STATE,
      isEditMode,
      networkState,
    });
  };
}

export function getNetworksTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_NETWORKS_TABLE_DATA,
    });
  };
}

export function setSelectedNetworkIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_NETWORK_INDEX,
      selectedNetworkIndex: index,
    });
  };
}

export function resetSelectedNetworkIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_NETWORK_INDEX,
    });
  };
}

export function updateNetworksTabData(campusId) {
  return (dispatch) => {
    dispatch(getNetworksByCampusId(campusId));
    dispatch(getNetworksStatuses());
  };
}

const style = {
  background: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 55px)',
    zIndex: '3000',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: '4000',
  },
  loading_default: {
    position: 'absolute',
    width: '50px',
    height: '50px',
    margin: '-25px 0 0 -25px',
  },
  loading_sm: {
    position: 'absolute',
    width: '25px',
    height: '25px',
    margin: '-12px 0 0 -12px',
  },
};

export default style;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';

import DateTimePicker from 'react-datetime';

import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import {
  CustomButton,
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {
  ButtonExpandLessMore,
  RenderOrEmpty,
  CardWithHeader,
  RenderDivOrEmpty,
  Autocomplete,
  Dropdown,
} from '../index';

import {RoleAvailability} from '../../containers';

import {
  CATEGORIES,
  FILTER_TYPES,
  DECISIONS,
  DATE_FORMAT,
  USER_ROLES,
} from '../../constants';

import {getDateTimeFilterValue, getTimezone} from '../../app-common/Log/service';
import {isAuditDataAvailableForDate} from '../../services/logs/logsAvailability';
import {isValidIpAndCidrMask} from '../../utils/validators';

import style from './style';

// allow to enter UpperCase text in field
const filterCaseSensitive = ['domain', 'decision', 'timezone', 'from', 'till', 'categoryType'];

const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([key, value], index) => [key, fn(key, value, index)],
    ),
  );

class LogOperatorFiltersPanel extends Component {
  static exportAvailabilityMap = {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.OEM_ADMIN]: false,
    [USER_ROLES.OEM_SUPER_ADMIN]: false,
  };

  static oemUserAvailabilityMap = {
    [USER_ROLES.OEM_ADMIN]: false,
    [USER_ROLES.OEM_SUPER_ADMIN]: false,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
    || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateCampusOptions(prevProps, props) {
    return !_isEqual(prevProps.campuses, props.campuses);
  }

  static shouldUpdatePolicyOptions(prevProps, props) {
    return !_isEqual(prevProps.policies, props.policies);
  }

  constructor(props) {
    super(props);
    this.filterTypes = [];
    this.categories = [];
    this.decisions = [];
    this.organizations = [];
    this.campuses = [];
    this.policies = [];
    this.clients = [];
    this.initializeOptions();
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.campuses, nextProps.campuses)
    || !_isEqual(this.props.policies, nextProps.policies)
    || !_isEqual(this.props.isShowAdditionalFilters, nextProps.isShowAdditionalFilters)
    || !_isEqual(this.props.currentFilter, nextProps.currentFilter)
    || !_isEqual(this.props.loggedAccount, nextProps.loggedAccount)
    || !_isEqual(this.props.currentOrganization, nextProps.currentOrganization)
    || !_isEqual(this.props.clients, nextProps.clients)
    || !_isEqual(this.props.organizations, nextProps.organizations);
  }

  componentDidUpdate(prevProps) {
    if (LogOperatorFiltersPanel.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetOptions();
      this.initializeOptions();
    }
  }

  componentWillUnmount() {
    this.resetOptions();
  }

  get optionAll() {
    return {
      name: I18n.t('logPage.filtersPanel.all'),
      value: null,
    };
  }

  getFilterData = () => {
    const {currentFilter} = this.props;
    const trimmedDomain = currentFilter.domain.trim();
    const trimmedNetworkIP = currentFilter.networkIP.trim();
    const trimmedSubnetIP = currentFilter.subnetIP.trim();

    const filterData = {
      time: currentFilter.date,
      from: getDateTimeFilterValue(currentFilter.date, currentFilter.timeStart),
      till: getDateTimeFilterValue(currentFilter.date, currentFilter.timeEnd),
      organizationId: this.organizations[currentFilter.organization].value,
      campusId: this.campuses[currentFilter.campus].value,
      policyId: this.policies[currentFilter.policy].value,
      filterType: this.filterTypes[currentFilter.filterType].value,
      decision: this.decisions[currentFilter.decision].value,
      categoryType: this.categories[currentFilter.category].value,
      domain: !_isEqual(trimmedDomain, '') ? trimmedDomain : null,
      networkIP: !_isEqual(trimmedNetworkIP, '') ? trimmedNetworkIP : null,
      subnetIP: !_isEqual(trimmedSubnetIP, '') ? trimmedSubnetIP : null,
      agentId: this.clients[currentFilter.client].value,
      timezone: getTimezone(),
    };

    return objectMap(filterData, (key, value) =>
      (!filterCaseSensitive.includes(key) && value ? value.toLowerCase() : value));
  };

  getOptions = (localOptions, entities) => [
    this.optionAll,
    ...entities.map((item) => ({
      name: item.name,
      value: item.id,
    })),
  ];

  getClientsOptions = (localOptions, entities) => [
    this.optionAll,
    ...entities.map((item) => ({
      name: item.name,
      value: item.id,
    })).sort(),
  ];

  // TODO: move it to the local state and refactor the component
  resetOptions = () => {
    this.filterTypes = [];
    this.categories = [];
    this.decisions = [];
    this.organizations = [];
    this.campuses = [];
    this.policies = [];
    this.clients = [];
  };

  initializeOptions = () => {
    this.organizations.push(this.optionAll);
    this.campuses.push(this.optionAll);
    this.policies.push(this.optionAll);
    this.filterTypes.push(this.optionAll);
    this.decisions.push(this.optionAll);
    this.categories.push(this.optionAll);
    this.clients.push(this.optionAll);

    Object.keys(FILTER_TYPES).map((key) => this.filterTypes.push({
      name: I18n.t(`filterTypes.${key}`),
      value: FILTER_TYPES[key],
    }));

    Object.keys(CATEGORIES).map((key) => this.categories.push({
      name: I18n.t(`categories.${key}`),
      value: CATEGORIES[key],
    }));

    Object.keys(DECISIONS).map((key) => this.decisions.push({
      name: I18n.t(`decisions.${key}`),
      value: DECISIONS[key],
    }));
  };

  validateIP = () => {
    const {networkIP, subnetIP} = this.props.currentFilter;
    const trimmedNetworkIP = networkIP.trim();
    const trimmedSubnetIP = subnetIP.trim();
    const isValidNetworkIP = trimmedNetworkIP === '' || isValidIpAndCidrMask(trimmedNetworkIP);
    const isValidSubnetIP = trimmedSubnetIP === '' || isValidIpAndCidrMask(trimmedSubnetIP);
    this.props.setValidation('networkIP', isValidNetworkIP);
    this.props.setValidation('subnetIP', isValidSubnetIP);

    return isValidNetworkIP && isValidSubnetIP;
  };

  handleExportButton = () => {
    if (this.validateIP()) {
      this.props.handleExport(this.getFilterData());
    }
  };

  handleResetButton = () => {
    this.props.handleReset();
  };

  handleSubmitButton = () => {
    if (this.validateIP()) {
      this.props.handleSubmit(this.getFilterData());
      this.props.handleChangeFilterForRequest(this.getFilterData());
    }
  };

  handleChangeDropdown = (e) => {
    this.props.handleChangeFilter(`${e.target.name}`, e.target.value);
  };

  handleChangeSelect = (e) => {
    this.props.handleChangeFilter(`${e.name}`, e.value);
  };

  handleChangeInput = (e) => {
    this.props.handleChangeFilter(`${e.target.name}`, e.target.value);
    this.props.setValidation(e.target.name, true);
  };

  handleChangeDatepicker = (e) => {
    const dateValue = _isObject(e) ? moment(e).format(DATE_FORMAT.MMDDYYYY_RIGHT_SLASH) : e;
    this.props.handleChangeFilter('date', dateValue);
  };

  handleBlurDatePicker = () => {
    const {
      initialFilter,
      currentFilter,
    } = this.props;
    if (!moment(currentFilter.date, DATE_FORMAT.MMDDYYYY_RIGHT_SLASH, true).isValid()) {
      this.props.handleChangeFilter('date', initialFilter.date);
    }
  };

  handleChangeTimePickerStart = (e) => this.handleChangeTimePicker(e, 'timeStart');

  handleChangeTimePickerEnd = (e) => this.handleChangeTimePicker(e, 'timeEnd');

  handleChangeTimePicker = (e, timePickerName) => {
    const timeValue = _isObject(e) ? moment(e).format(DATE_FORMAT.TIME_PICKER_LOG_FILTERS) : e;
    this.props.handleChangeFilter(`${timePickerName}`, timeValue);
  };

  handleBlurTimePickerStart = () => {
    const {
      initialFilter,
      currentFilter,
    } = this.props;
    if (!moment(currentFilter.timeStart, DATE_FORMAT.TIME_PICKER_LOG_FILTERS, true).isValid()) {
      this.props.handleChangeFilter('timeStart', initialFilter.timeStart);
    }
  };

  handleBlurTimePickerEnd = () => {
    const {
      initialFilter,
      currentFilter,
    } = this.props;
    const format = DATE_FORMAT.TIME_PICKER_LOG_FILTERS;
    if (
      !moment(currentFilter.timeEnd, DATE_FORMAT.TIME_PICKER_LOG_FILTERS, true).isValid()
      || moment(currentFilter.timeStart, format).isAfter(moment(currentFilter.timeEnd, format))
    ) {
      this.props.handleChangeFilter('timeEnd', initialFilter.timeEnd);
    }
  };

  validateDate = (date) => isAuditDataAvailableForDate(date, this.props.maxAuditQueryDays);

  render() {
    const {
      classes,
      campuses,
      policies,
      currentFilter,
      isShowAdditionalFilters,
      showHideAdditionalFilters,
      clients,
      organizations,
      currentOrganization,
    } = this.props;

    const organizationsOptions = this.getOptions(
      this.organizations,
      (_isNil(currentOrganization))
        ? organizations
        : organizations.filter((org) => _isEqual(org.id, currentOrganization.id)),
    );
    const campusesOptions = this.getOptions(this.campuses, campuses);
    const policiesOptions = this.getOptions(this.policies, policies);
    const clientsOptions = this.getClientsOptions(this.clients, clients);

    this.organizations = organizationsOptions;
    this.campuses = campusesOptions;
    this.policies = policiesOptions;
    this.clients = clientsOptions;

    const timePickerProps = {
      start: {
        name: 'timeStart',
        placeholder: I18n.t('logPage.filtersPanel.placeholders.timePickerPlaceholder'),
      },
      end: {
        name: 'timeEnd',
        placeholder: I18n.t('logPage.filtersPanel.placeholders.timePickerPlaceholder'),
      },
    };

    const organizationsAllowed = _get(this.props.accountInfo, 'organizationsAllowed', false);

    const labelProps = {
      classes: {
        root: classes.form__formControl_response,
        shrink: classes.form__shrink_response,
      },
      shrink: true,
    };

    return (
      <CardWithHeader
        cardTitle={I18n.t('logPage.filtersPanel.title')}
        cardTitleColor="primary"
        customCardClasses="card_logOperatorFilterPanel"
        content={(
          <div className={classes.filtersPanel}>
            <div className={classes.commonFilters__container}>
              <div className={classes.commonFilters__item}>
                <InputLabel className={classes.filters__label}>
                  {I18n.t('logPage.filtersPanel.labels.datepickerLabel')}
                </InputLabel>
                <DateTimePicker
                  dateFormat={true}
                  defaultValue={currentFilter.date}
                  onBlur={this.handleBlurDatePicker}
                  onChange={this.handleChangeDatepicker}
                  timeFormat={false}
                  value={currentFilter.date}
                  isValidDate={this.validateDate}
                />
              </div>
              <RenderDivOrEmpty
                isShow={organizationsAllowed}
                className={classes.commonFilters__item}
              >
                <Autocomplete
                  formControlProps={{
                    fullWidth: true,
                  }}
                  closeMenuOnSelect={true}
                  labelText={I18n.t('logPage.filtersPanel.labels.organizationLabel')}
                  inputProps={{
                    onChange: this.handleChangeSelect,
                    options: organizationsOptions.map((item, index) => (
                      {
                        label: item.name,
                        value: index,
                        name: 'organization',
                      }
                    )),
                    value: {
                      label: organizationsOptions[currentFilter.organization].name,
                      value: currentFilter.organization,
                      name: 'organization',
                    },
                  }}
                  isCreatable={false}
                  labelProps={labelProps}
                />
              </RenderDivOrEmpty>
              <div className={classes.commonFilters__item}>
                <Autocomplete
                    formControlProps={{
                      fullWidth: true,
                    }}
                    closeMenuOnSelect={true}
                    labelText={I18n.t('logPage.filtersPanel.labels.campusLabel')}
                    inputProps={{
                      onChange: this.handleChangeSelect,
                      options: campusesOptions.map((item, index) => (
                        {
                          label: item.name,
                          value: index,
                          name: 'campus',
                        }
                      )),
                      value: {
                        label: campusesOptions[currentFilter.campus].name,
                        value: currentFilter.campus,
                        name: 'campus',
                      },
                    }}
                    isCreatable={false}
                    labelProps={labelProps}
                />
              </div>
            </div>
            <div className={classes.filtersPanel__showHideControl}>
              <ButtonExpandLessMore
                customClasses="logOperatorFiltersPanel__showHideAdditionalFiltersButton"
                isExpandLess={isShowAdditionalFilters}
                onClick={showHideAdditionalFilters}
                text={I18n.t('logPage.filtersPanel.advancedOptions')}
              />
            </div>
            <RenderOrEmpty isShow={isShowAdditionalFilters}>
              <div className={classes.additionalFilters__container}>
                <div className={classes.additionalFilters__item}>
                  <InputLabel className={classes.filters__label}>
                    {I18n.t('logPage.filtersPanel.labels.timePickerLabel')}
                  </InputLabel>
                  <div className={classes.additionalFiltersItem__timePickers}>
                    <DateTimePicker
                      className={classes.additionalFilters__timePicker}
                      dateFormat={false}
                      defaultValue={currentFilter.timeStart}
                      inputProps={timePickerProps.start}
                      onBlur={this.handleBlurTimePickerStart}
                      onChange={this.handleChangeTimePickerStart}
                      value={currentFilter.timeStart}
                    />
                    <DateTimePicker
                      className={classes.additionalFilters__timePicker}
                      dateFormat={false}
                      defaultValue={currentFilter.timeEnd}
                      inputProps={timePickerProps.end}
                      onBlur={this.handleBlurTimePickerEnd}
                      onChange={this.handleChangeTimePickerEnd}
                      timeFormat={true}
                      value={currentFilter.timeEnd}
                    />
                  </div>
                </div>
                <RoleAvailability availability={LogOperatorFiltersPanel.oemUserAvailabilityMap}>
                  <div className={classes.additionalFilters__item}>
                    <Autocomplete
                    formControlProps={{
                      fullWidth: true,
                    }}
                    closeMenuOnSelect={true}
                    labelText={I18n.t('logPage.filtersPanel.labels.policyLabel')}
                    inputProps={{
                      onChange: this.handleChangeSelect,
                      options: policiesOptions.map((item, index) => (
                        {
                          label: item.name,
                          value: index,
                          name: 'policy',
                        }
                      )),
                      value: {
                        label: policiesOptions[currentFilter.policy].name,
                        value: currentFilter.policy,
                        name: 'policy',
                      },
                    }}
                    isCreatable={false}
                    labelProps={labelProps}
                    />
                  </div>
                </RoleAvailability>
                <div className={classes.additionalFilters__item}>
                  <InputLabel
                    htmlFor="domain-label"
                    className={classes.filters__label}
                  >
                    {I18n.t('logPage.filtersPanel.labels.domainLabel')}
                  </InputLabel>
                  <CustomInput
                    id="domain-input"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: I18n.t('logPage.filtersPanel.placeholders.domainPlaceholder'),
                      name: 'domain',
                      value: currentFilter.domain,
                      onChange: this.handleChangeInput,
                    }}
                  />
                </div>
                <div className={classes.additionalFilters__item}>
                  <Dropdown
                    dropdownName="decision"
                    fullWidth={true}
                    label={I18n.t('logPage.filtersPanel.labels.decisionLabel')}
                    onChangeValue={this.handleChangeDropdown}
                    selectedItemIndex={currentFilter.decision}
                    options={this.decisions.map((item) => item.name)}
                    customSelectClass="dropdown__select_loggingPanel"
                  />
                </div>
                <div className={classes.additionalFilters__item}>
                  <InputLabel
                    htmlFor="wan-ip-input"
                    className={classes.filters__label}
                  >
                    {I18n.t('logPage.filtersPanel.labels.networkIpLabel')}
                  </InputLabel>
                  <CustomInput
                    id="wan-ip-input"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: I18n.t('logPage.filtersPanel.placeholders.networkIpPlaceholder'),
                      name: 'networkIP',
                      value: currentFilter.networkIP,
                      onChange: this.handleChangeInput,
                    }}
                    error={!currentFilter.validation.networkIP}
                  />
                </div>
                <div className={classes.additionalFilters__item}>
                  <Dropdown
                    dropdownName="category"
                    fullWidth={true}
                    label={I18n.t('logPage.filtersPanel.labels.categoryLabel')}
                    onChangeValue={this.handleChangeDropdown}
                    selectedItemIndex={currentFilter.category}
                    options={this.categories.map((item) => item.name)}
                    customSelectClass="dropdown__select_loggingPanel"
                  />
                </div>
                <RoleAvailability availability={LogOperatorFiltersPanel.oemUserAvailabilityMap}>
                  <div className={classes.additionalFilters__item}>
                    <InputLabel
                    htmlFor="lan-ip-input"
                    className={classes.filters__label}
                    >
                      {I18n.t('logPage.filtersPanel.labels.subnetIpLabel')}
                    </InputLabel>
                    <CustomInput
                    id="lan-ip-input"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: I18n.t('logPage.filtersPanel.placeholders.subnetIpPlaceholder'),
                      name: 'subnetIP',
                      value: currentFilter.subnetIP,
                      onChange: this.handleChangeInput,
                    }}
                    error={!currentFilter.validation.subnetIP}
                    />
                  </div>
                </RoleAvailability>
                <RoleAvailability availability={LogOperatorFiltersPanel.oemUserAvailabilityMap}>
                  <div className={classes.additionalFilters__item}>
                    <Autocomplete
                      formControlProps={{
                        fullWidth: true,
                      }}
                      closeMenuOnSelect={true}
                      labelText={I18n.t('logPage.filtersPanel.labels.clientLabel')}
                      inputProps={{
                        onChange: this.handleChangeSelect,
                        options: clientsOptions.map((item, index) => (
                          {
                            label: item.name,
                            value: index,
                            name: 'client',
                          }
                        )),
                        value: {
                          label: clientsOptions[currentFilter.client].name,
                          value: currentFilter.client,
                          name: 'client',
                        },
                      }}
                      isCreatable={false}
                      labelProps={labelProps}
                    />
                  </div>
                </RoleAvailability>
              </div>
            </RenderOrEmpty>
            <div className={classes.buttons__container}>
              <RoleAvailability availability={LogOperatorFiltersPanel.exportAvailabilityMap}>
                <div className={classes.buttons__item}>
                  <CustomButton
                    onClick={this.handleExportButton}
                    color="primary"
                  >
                    {I18n.t('logPage.filtersPanel.buttons.buttonExport')}
                  </CustomButton>
                </div>
              </RoleAvailability>
              <div className={classes.buttons__item}>
                <CustomButton
                  onClick={this.handleResetButton}
                  color="gray"
                >
                  {I18n.t('logPage.filtersPanel.buttons.buttonReset')}
                </CustomButton>
              </div>
              <div className={classes.buttons__item}>
                <CustomButton
                  onClick={this.handleSubmitButton}
                  color="secondary"
                >
                  {I18n.t('logPage.filtersPanel.buttons.buttonSubmit')}
                </CustomButton>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

LogOperatorFiltersPanel.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  campuses: PropTypes.array,
  classes: PropTypes.object.isRequired,
  currentFilter: PropTypes.object.isRequired,
  initialFilter: PropTypes.object.isRequired,
  isShowAdditionalFilters: PropTypes.bool,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  policies: PropTypes.array,
  maxAuditQueryDays: PropTypes.number.isRequired,
  setValidation: PropTypes.func.isRequired,
  clients: PropTypes.array,
  organizations: PropTypes.array,

  handleSubmit: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  handleChangeFilterForRequest: PropTypes.func.isRequired,
  showHideAdditionalFilters: PropTypes.func.isRequired,
};

LogOperatorFiltersPanel.defaultProps = {
  policies: [],
  campuses: [],
  clients: [],
  organizations: [],
  isShowAdditionalFilters: false,
  currentOrganization: null,
};

export default withStyles(style)(LogOperatorFiltersPanel);

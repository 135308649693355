import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {TableWithPaginationAndFiltration} from '../index';

import style from './style';

class RelayBinariesTable extends Component {
  constructor(props) {
    super(props);

    this.downloadLinkCellRenderer = (rowInfo) => (
      <a href={rowInfo.value} download={true} className={this.props.classes.relayBinariesTable__text_link}>
        {I18n.t('downloadRelayPage.relayBinaries.link')}
      </a>
    );

    this.headers = [
      {
        Header: I18n.t('downloadRelayPage.relayBinaries.labels.os'),
        accessor: 'os',
        sortable: false,
      },
      {
        Header: I18n.t('downloadRelayPage.relayBinaries.labels.arch'),
        accessor: 'arch',
        sortable: false,
      },
      {
        Header: I18n.t('downloadRelayPage.relayBinaries.labels.version'),
        accessor: 'version',
        sortable: false,
      },
      {
        Header: I18n.t('downloadRelayPage.relayBinaries.labels.downloadLink'),
        accessor: 'bundleLink',
        Cell: this.downloadLinkCellRenderer,
        sortable: false,
      },
    ];
  }

  getTableStyles = () => {
    const {tableStyles} = this.props;
    if (Object.keys(tableStyles).length > 0) {
      return tableStyles;
    }
    return {
      height: 'calc(100vh - 150px)',
    };
  };

  render() {
    const {tableData} = this.props;
    return (
      <TableWithPaginationAndFiltration
        columns={this.headers}
        data={tableData}
        defaultPageSize={tableData.length}
        pageSize={tableData.length}
        showPagination={false}
        style={this.getTableStyles()}
      />
    );
  }
}

RelayBinariesTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    os: PropTypes.string.isRequired,
    arch: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  })),
  classes: PropTypes.object.isRequired,
  tableStyles: PropTypes.object,
};

RelayBinariesTable.defaultProps = {
  tableData: [],
  tableStyles: {},
};

export default withStyles(style)(RelayBinariesTable);

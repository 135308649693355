import {
  defaultFont,
  primaryColor,
} from '../../styles/style';

const style = {
  modalWindow: {
    zIndex: '5000',
  },
  modalWindow__paper: {
    backgroundColor: primaryColor,
    borderRadius: '5px',
    border: `1px solid ${primaryColor}`,
    padding: '10px 20px',
  },
  modalWindow_information: {
    backgroundColor: '#FFF',
    border: '1px solid #FFF',
  },
  modalWindow__titleRoot: {
    paddingBottom: '0',
  },
  modalWindow__title: {
    ...defaultFont,
    color: '#FFF',
    marginBottom: '10px',
    textAlign: 'center',
  },
  modalWindow__title_notify: {
    minWidth: '250px',
    maxWidth: '500px',
  },
  modalWindow__title_deleteEntity: {
    width: '250px',
  },
  modalWindow__actions: {
    width: '210px',
    margin: '10px auto',
    justifyContent: 'space-around',
  },
  modalWindow__actionsWithInput: {
    width: '290px',
    margin: '8px 4px',
    alignItems: 'normal',
    justifyContent: 'space-around',
  },
};

export default style;

import {
  defaultFont,
  primaryColor,
} from '../../styles/style';

const style = {
  noAccounts: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  noAccounts__message: {
    ...defaultFont,
    color: primaryColor,
    whiteSpace: 'pre-line',
    fontSize: '15px',
    margin: '0 0 20px',
  },
};

export default style;

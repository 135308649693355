import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';
import _isEmpty from 'lodash/isEmpty';

import {withStyles} from '@material-ui/core/styles';

import {
  ChangePasswordForm,
  NotificationCardWithHeader,
  EntityCreatingCard,
  RenderOrEmpty,
} from '../../components';

import {
  changePassword,
  resetEnteredIncorrectCurrentPasswordError,
  resetChangePasswordPageData,
} from './action';

import style from './style';

class ChangePassword extends Component {
  static initialLocalState = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',

    isValidForm: false,
  };

  static wasAccountSwitched(prevProps, props) {
    return prevProps.loggedAccount.accountId !== props.loggedAccount.accountId;
  }

  constructor(props) {
    super(props);
    this.state = ChangePassword.initialLocalState;
  }

  componentDidUpdate(prevProps) {
    if (ChangePassword.wasAccountSwitched(prevProps, this.props)) {
      this.resetLocalState();
    }
  }

  componentWillUnmount() {
    this.props.resetChangePasswordPageData();
  }

  onBlur = (e) => {
    let isValid = true;
    Object.keys(e).forEach((key) => {
      isValid = isValid && e[key];
    });
    this.setState({isValidForm: isValid});
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // onClickCancel = () => this.props.history.goBack();

  onSubmit = () => {
    const {
      currentPassword,
      newPassword,
      isValidForm,
    } = this.state;
    if (isValidForm) {
      this.props.changePassword({
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
    }
  };

  get isValidForm() {
    return this.state.isValidForm
      && !_isEmpty(this.state.currentPassword)
      && !_isEmpty(this.state.newPassword)
      && !_isEmpty(this.state.confirmNewPassword);
  }

  resetLocalState = () => this.setState(ChangePassword.initialLocalState);

  // goToDefaultPage = () => {
  //   this.props.history.push(ROUTES.DEFAULT);
  // };

  resetState = () => {
    this.resetLocalState();
    this.props.resetChangePasswordPageData();
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
    } = this.state;

    const {
      enteredIncorrectCurrentPassword,
      isPasswordChanged,
      classes,
    } = this.props;

    return (
      <>
        <RenderOrEmpty isShow={!isPasswordChanged}>
          <EntityCreatingCard
            title={I18n.t('profilePage.passwordBlock.title')}
            handleClickButtonSave={this.onSubmit}
            isValidEntity={this.isValidForm}
            isFullscreen={false}
            content={(
              <ChangePasswordForm
                confirmNewPassword={confirmNewPassword}
                currentPassword={currentPassword}
                enteredIncorrectCurrentPassword={enteredIncorrectCurrentPassword}
                hideErrorMessageAboutIncorrectCurrentPassword={
                  this.props.resetEnteredIncorrectCurrentPasswordError
                }
                newPassword={newPassword}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            )}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={isPasswordChanged}>
          <NotificationCardWithHeader
            cardTitle={I18n.t('profilePage.passwordBlock.title')}
            cardTitleColor="edit"
            content={(
              <div className={classes.changePassword__notificationMessage}>
                {I18n.t('profilePage.passwordBlock.success.text')}
              </div>
            )}
            onClickOk={this.resetState}
          />
        </RenderOrEmpty>
      </>
    );
  }
}

ChangePassword.propTypes = {
  history: PropTypes.object.isRequired,
  isPasswordChanged: PropTypes.bool.isRequired,
  enteredIncorrectCurrentPassword: PropTypes.bool.isRequired,

  changePassword: PropTypes.func.isRequired,
  resetEnteredIncorrectCurrentPasswordError: PropTypes.func.isRequired,
  resetChangePasswordPageData: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

ChangePassword.defaultProps = {};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,

  isPasswordChanged: state.changePasswordReducer.isPasswordChanged,
  enteredIncorrectCurrentPassword: state.changePasswordReducer.enteredIncorrectCurrentPassword,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: bindActionCreators(changePassword, dispatch),
  resetEnteredIncorrectCurrentPasswordError:
    bindActionCreators(resetEnteredIncorrectCurrentPasswordError, dispatch),
  resetChangePasswordPageData: bindActionCreators(resetChangePasswordPageData, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(ChangePassword)));

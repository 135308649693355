import initState from '../../reducers/redux-initial-state';
import {types} from './action';

export default (state = initState.insightsLogsReducer, action) => {
  switch (action.type) {
    case types.LOAD_MORE_INSIGHTS_LOGS: {
      return {
        ...state,
        logs: [
          ...state.logs,
          ...action.logs,
        ],
      };
    }
    case types.SET_INSIGHTS_LOGS: {
      return {
        ...state,
        logs: action.logs,
      };
    }
    case types.SET_DOMAIN_LOGS: {
      return {
        ...state,
        domains: action.domains,
      };
    }
    case types.SET_FQDN_LOGS: {
      return {
        ...state,
        fqdns: action.fqdns,
      };
    }
    case types.RESET_INSIGHTS_LOGS: {
      return {
        ...state,
        logs: initState.insightsLogsReducer.logs,
      };
    }
    case types.RESET_INSIGHTS_DOMAIN_LOGS: {
      return {
        ...state,
        domains: initState.insightsLogsReducer.domains,
      };
    }
    case types.RESET_INSIGHTS_FQDN_LOGS: {
      return {
        ...state,
        fqdns: initState.insightsLogsReducer.fqdns,
      };
    }
    default:
      return state;
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import {TABS as clientsTabs} from '../../app-common/Clients/constants';
import {EntitiesListWithViewModesCard} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

import './style.css';
import style from './style';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.CLIENT_PROFILE_CREATE],
};

class ClientProfilesTable extends Component {
  constructor(props) {
    super(props);

    this.countCellRenderer = (row) => {
      const online = row.value.onlineCount;
      const offline = row.value.offlineCount;
      return (
        <div className="client-profiles-table__cell">
          <span>{online + offline}</span>
          /
          <span className="client-profiles-table__stats_online">{online}</span>
          /
          <span className="client-profiles-table__stats_offline">{offline}</span>
        </div>
      );
    };

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];

    this.headersFullWidthMode.push(
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.table.headers.name`),
        accessor: 'name',
        filterable: false,
        sortable: true,
        minWidth: 300,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.table.headers.description`),
        accessor: 'description',
        sortable: true,
        filterable: false,
        minWidth: 400,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.table.headers.clientStats`),
        accessor: 'clientStats',
        Cell: this.countCellRenderer,
        sortable: false,
        filterable: false,
        minWidth: 100,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.table.headers.defaultPolicy`),
        accessor: 'defaultPolicy',
        sortable: true,
        filterable: false,
        minWidth: 200,
      },
    );
    this.headersCompressedMode.push({
      Header: I18n.t(`clients.${clientsTabs.CONFIGURE}.table.headers.name`),
      accessor: 'name',
      filterable: false,
      sortable: true,
    });
  }

  render() {
    const {
      classes,
      mode,
      handleClickButtonNew,
      handleClickRow,
      isEditMode,
      tableData,
      selectedProfileIndex,
    } = this.props;

    const cardClasses = {
      card: classes.card,
    };

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t(`clients.${clientsTabs.CONFIGURE}.table.title`)}
        entityType={I18n.t('entitiesTypes.clientProfile')}
        selectedEntityIndex={selectedProfileIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickRow}
        fullscreen={false}
        accessRights={rightAvailabilityMap}
        showOnAccessDenyButtonCreate={true}
        classes={cardClasses}
      />
    );
  }
}

ClientProfilesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedProfileIndex: PropTypes.number.isRequired,
};

ClientProfilesTable.defaultProps = {
  tableData: [],
};

export default withStyles(style)(ClientProfilesTable);

import React from 'react';
import {matchSorter} from 'match-sorter';

export const FilterWithPlaceholderAndFullTextFilterMethod = (
  placeholder,
  keys,
) => ({
  // eslint-disable-next-line
  Filter: ({filter, onChange}) => (
    <input
      type="text"
      placeholder={placeholder}
      // eslint-disable-next-line
      value={filter ? filter.value : ''}
      onChange={(e) => onChange(e.target.value)}
    />
  ),
  filterMethod: (filter, rows) =>
    matchSorter(
      rows,
      filter.value,
      {
        keys: keys,
      },
    ),
  filterAll: true,
});

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import _get from 'lodash/get';
import {
  USER_ROLES,
  getRightsByRoleAndPermission,
  getDefaultPermissionByRole,
} from '../../constants';

// rights check; if access forbidden, child component should be seen,
// but on interaction show modal windows with message of lacking rights to do
function RightAvailabilityOrWarn(props) {
  const {
    role,
    children,
    currentOrganization,
    accessRights,
    overrideRights,
    onClickDeny,
    limitDenyFor,
    alternativeChild,
  } = props;

  let child;

  // for a few cases when need to check rights outside of static setup
  // TODO: override not only to allow, but also to forbid, if needed
  if (overrideRights) {
    child = children;
    return child;
  }

  // define by role if no organization is chosen
  const currentUserPermission = _get(
    currentOrganization,
    'permission',
    getDefaultPermissionByRole(role),
  );

  // get rightList for current role and permission
  const userRightsList = getRightsByRoleAndPermission(role, currentUserPermission);

  if (children != null
    && userRightsList != null
    && accessRights.every((right) => userRightsList.includes(right))
  ) {
    child = children;
  } else if (children != null && (limitDenyFor.length === 0
      || limitDenyFor.some((rule) =>
        rule.role === role && rule.permission === currentUserPermission))) {
    // copy object and child object to be able to modify `onClick` value
    child = {...children};
    child.props = {...children.props};
    child.props.onClick = onClickDeny;
  } else {
    child = alternativeChild;
  }

  return child;
}

RightAvailabilityOrWarn.propTypes = {
  alternativeChild: PropTypes.node,
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
  currentOrganization: PropTypes.object,
  accessRights: PropTypes.array.isRequired,
  overrideRights: PropTypes.bool,
  onClickDeny: PropTypes.func.isRequired,
  limitDenyFor: PropTypes.array,
};

RightAvailabilityOrWarn.defaultProps = {
  alternativeChild: null,
  role: USER_ROLES.VIEWER,
  currentOrganization: null,
  overrideRights: false,
  limitDenyFor: [],
};

const mapStateToProps = (state) => ({
  role: state.userAccountsReducer.loggedAccount.role,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

export default connect(mapStateToProps, null)(RightAvailabilityOrWarn);

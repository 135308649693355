import initState from '../../reducers/redux-initial-state';
import {types} from './action';
import {
  getSubnetsTableData,
  getUpdatedSubnetIndex,
} from './service';

export default (state = initState.campusSubnetsTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_SUBNET_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        subnetState: action.subnetState,
      };
    }
    case types.SET_SUBNETS: {
      return {
        ...state,
        subnets: action.data.subnets,
        networkAgentAllowed: action.data.networkAgentAllowed,
        subnetsTableData: getSubnetsTableData(action.data.subnets),
      };
    }
    case types.SET_SUBNET_TABLE_DATA: {
      return {
        ...state,
        subnetsTableData: getSubnetsTableData(state.subnets),
      };
    }
    case types.SET_SELECTED_SUBNET: {
      return {
        ...state,
        selectedSubnet: action.data,
      };
    }
    case types.SET_SELECTED_SUBNET_INDEX: {
      return {
        ...state,
        selectedSubnetIndex: action.selectedSubnetIndex,
      };
    }
    case types.SET_UPDATED_SUBNET: {
      return {
        ...state,
        selectedSubnetIndex: getUpdatedSubnetIndex(action.data.id, state.subnets),
        selectedSubnet: action.data,
      };
    }
    case types.RESET_SELECTED_SUBNET_INDEX: {
      return {
        ...state,
        selectedSubnetIndex: initState.campusSubnetsTabReducer.selectedSubnetIndex,
        selectedSubnet: initState.campusSubnetsTabReducer.selectedSubnet,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import _uniq from 'lodash/uniq';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip';

import initState from '../../reducers/redux-initial-state';
import {COMPONENT_TYPES, makeOptions} from './constants';

export const processFilters = {
  [COMPONENT_TYPES.DROPDOWN]: (value, options) => options[value].id,
  [COMPONENT_TYPES.AUTOCOMPLETE]: (value, options) => value.map((v) => options[v.index].id),
  [COMPONENT_TYPES.MULTIDROPDOWN]: (value, options) => value.map((v) => options[v].id),
  [COMPONENT_TYPES.INPUT]: (value) => value.trim(),
};

const getCategorySubtypes = (categories, result = []) => {
  if (Array.isArray(categories)) {
    return result;
  }
  return Object.entries(categories)
    .reduce((res, c) => [...res, c[0], ...getCategorySubtypes(c[1], [])], []);
};

export const getCategoriesSubtypesOptions = (categories, selectedType = null) => {
  let filteredCategories;
  if (!_isNil(selectedType)) {
    const selectedCategories = _get(categories, selectedType, null);
    filteredCategories = selectedCategories ? [selectedCategories] : [];
  } else {
    filteredCategories = Object.values(categories);
  }

  const options = filteredCategories.reduce((res, c) => [...getCategorySubtypes(c), ...res], []);
  return makeOptions(_uniq(options).sort());
};

export const getInitialFilterState = (tab, filter) =>
  _get(initState, `insightsReducer.filters.${tab}.${filter}.value`);

export const normalizeSearchOptions = (tab, options) => {
  const normalizedOptions = {};
  Object.entries(options).forEach(([key, value]) => {
    if (value !== null) {
      if (typeof value === 'string') {
        const trimmedValue = value.trim();
        if (!_isEmpty(trimmedValue)) {
          normalizedOptions[key] = trimmedValue;
        }
      } else {
        normalizedOptions[key] = value;
      }
    }
  });
  return normalizedOptions;
};

export const withTooltip = (showTooltip, tooltipOptions, component) => {
  if (!showTooltip) {
    return component;
  }
  return (
    <Tooltip {...tooltipOptions}>
      {component}
    </Tooltip>
  );
};

export const clearFilters = (filters) => {
  const emptyValues = {
    [COMPONENT_TYPES.DROPDOWN]: 0,
    [COMPONENT_TYPES.MULTIDROPDOWN]: [],
    [COMPONENT_TYPES.AUTOCOMPLETE]: [],
    [COMPONENT_TYPES.INPUT]: '',
  };
  const clearedFilters = {};
  Object.keys(filters).forEach((key) => {
    const filter = filters[key];
    if (!filter.isApplied) {
      clearedFilters[key] = filter;
    } else {
      clearedFilters[key] = {
        ...filter,
        isApplied: false,
        value: emptyValues[filter.type],
      };
    }
  });
  return clearedFilters;
};

import initState from '../../reducers/redux-initial-state';

import {getWhiteBlackListInfo} from './service';

import {types} from './action';

export default (state = initState.whiteBlackListEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_WHITE_BLACK_LIST_INFO: {
      return {
        ...state,
        editableWhiteBlackListInfo: getWhiteBlackListInfo(action.data),
      };
    }
    case types.SET_VALIDATION_WHITE_BLACK_LIST_INFO: {
      return {
        ...state,
        isValidWhiteBlackListInfo: action.isValid,
      };
    }
    case types.RESET_WHITE_BLACK_LIST_FORM_DATA: {
      return {
        ...state,
        ...initState.whiteBlackListEditFormReducer,
      };
    }
    case types.UPDATE_WHITE_BLACK_LIST_INFO: {
      return {
        ...state,
        editableWhiteBlackListInfo: action.data,
        isValidWhiteBlackListInfo: action.isValidWhiteBlackListInfo,
      };
    }
    default:
      return state;
  }
};

export const types = {
  SET_EDITABLE_LOCAL_DOMAIN_INFO: 'SET_EDITABLE_LOCAL_DOMAIN_INFO',
  SET_VALIDATION_LOCAL_DOMAIN_INFO: 'SET_VALIDATION_LOCAL_DOMAIN_INFO',
  RESET_LOCAL_DOMAIN_EDIT_FORM_DATA: 'RESET_LOCAL_DOMAIN_EDIT_FORM_DATA',
  UPDATE_LOCAL_DOMAIN_INFO: 'UPDATE_LOCAL_DOMAIN_INFO',
};

export function setEditableLocalDomainInfo(localDomain) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_LOCAL_DOMAIN_INFO,
      data: localDomain,
    });
  };
}

export function updateLocalDomainInfo(updatedLocalDomainInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_LOCAL_DOMAIN_INFO,
      data: updatedLocalDomainInfo,
      isValidLocalDomainInfo: isValid,
    });
  };
}

export function resetLocalDomainEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_LOCAL_DOMAIN_EDIT_FORM_DATA,
    });
  };
}

export function setValidationLocalDomainInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_LOCAL_DOMAIN_INFO,
      isValid,
    });
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import {withStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {RenderOrEmpty} from '../index';

import {Visibility, VisibilityOff} from '../../icons';

import {VALID_PASSWORD_LENGTH} from '../../constants';

import style from './style';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: {
        areEqualNewPasswords: true,
        isValidNewPassword: true,
        isValidCurrentPassword: true,
      },

      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
    };
  }

  onBlurCurrentPassword = (e) => {
    const {validation} = this.state;
    const newValidation = _cloneDeep(validation);
    newValidation.isValidCurrentPassword = e.target.value.length > 0;
    this.props.onBlur(newValidation);
    this.setState({
      validation: newValidation,
    });
  };

  onBlurNewPassword = (e) => {
    const {confirmNewPassword} = this.props;
    const {validation} = this.state;
    const newValidation = _cloneDeep(validation);
    newValidation.isValidNewPassword = e.target.value.length >= VALID_PASSWORD_LENGTH;
    if (!_isEqual(confirmNewPassword, '')) {
      newValidation.areEqualNewPasswords = _isEqual(confirmNewPassword, e.target.value);
    }
    this.props.onBlur(newValidation);
    this.setState({
      validation: newValidation,
    });
  };

  onBlurConfirmPassword = (e) => {
    const {newPassword} = this.props;
    const {validation} = this.state;
    const newValidation = _cloneDeep(validation);
    newValidation.areEqualNewPasswords = _isEqual(newPassword, e.target.value);
    this.props.onBlur(newValidation);
    this.setState({
      validation: newValidation,
    });
  };

  getEndAdornment = (showPassword, onClick) => (
    <InputAdornment position="end">
      <IconButton
        onClick={onClick}
        onMouseDown={this.handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  showHidePassword = (passwordName) => {
    this.setState((prevState) => ({
      [passwordName]: !prevState[passwordName],
    }));
  };

  showHideCurrentPassword = () => {
    if (this.props.enteredIncorrectCurrentPassword) {
      this.props.hideErrorMessageAboutIncorrectCurrentPassword();
    }
    this.showHidePassword('showCurrentPassword');
  };

  showHideNewPassword = () => this.showHidePassword('showNewPassword');

  showHideConfirmNewPassword = () => this.showHidePassword('showConfirmNewPassword');

  render() {
    const {
      validation,
      showCurrentPassword,
      showNewPassword,
      showConfirmNewPassword,
    } = this.state;

    const {
      currentPassword,
      newPassword,
      confirmNewPassword,

      classes,
      enteredIncorrectCurrentPassword,
      hideErrorMessageAboutIncorrectCurrentPassword,
      onChange,
    } = this.props;

    const onChangeCurrentPassword = (e) => {
      if (enteredIncorrectCurrentPassword) {
        hideErrorMessageAboutIncorrectCurrentPassword();
      }
      onChange(e);
    };

    const propsInput = {
      currentPassword: {
        value: currentPassword,
        name: 'currentPassword',
        onBlur: this.onBlurCurrentPassword,
        onChange: onChangeCurrentPassword,
        type: showCurrentPassword ? 'text' : 'password',
        endAdornment: this.getEndAdornment(showCurrentPassword, this.showHideCurrentPassword),
      },
      newPassword: {
        value: newPassword,
        name: 'newPassword',
        onBlur: this.onBlurNewPassword,
        onChange: onChange,
        type: showNewPassword ? 'text' : 'password',
        endAdornment: this.getEndAdornment(showNewPassword, this.showHideNewPassword),
      },
      confirmNewPassword: {
        value: confirmNewPassword,
        name: 'confirmNewPassword',
        onBlur: this.onBlurConfirmPassword,
        onChange: onChange,
        type: showConfirmNewPassword ? 'text' : 'password',
        endAdornment: this.getEndAdornment(showConfirmNewPassword, this.showHideConfirmNewPassword),
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };
    return (
      <form>
        <RenderOrEmpty isShow={enteredIncorrectCurrentPassword}>
          <InputLabel
            error={true}
            className={classes.changePasswordForm__errorMessage}
          >
            {I18n.t('profilePage.passwordBlock.errorMessages.currentPasswordIsIncorrect')}
          </InputLabel>
        </RenderOrEmpty>
        <CustomInput
          errorText={validation.isValidCurrentPassword
            ? undefined
            : I18n.t('profilePage.passwordBlock.errorMessages.currentPasswordIsNotValid')}
          error={!validation.isValidCurrentPassword}
          labelText={I18n.t('profilePage.passwordBlock.currentPassword')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.currentPassword}
        />
        <CustomInput
          errorText={validation.isValidNewPassword
            ? undefined
            : I18n.t(
              'profilePage.passwordBlock.errorMessages.newPasswordIsNotValid',
              {
                count: VALID_PASSWORD_LENGTH - 1,
              },
            )}
          error={!validation.isValidNewPassword}
          labelText={I18n.t('profilePage.passwordBlock.newPassword')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.newPassword}
        />
        <CustomInput
          errorText={validation.areEqualNewPasswords
            ? undefined
            : I18n.t('profilePage.passwordBlock.errorMessages.newPasswordsDoNotMatch')}
          error={!validation.areEqualNewPasswords}
          labelText={I18n.t('profilePage.passwordBlock.confirmNewPassword')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.confirmNewPassword}
        />
      </form>
    );
  }
}

ChangePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  confirmNewPassword: PropTypes.string.isRequired,
  currentPassword: PropTypes.string.isRequired,
  enteredIncorrectCurrentPassword: PropTypes.bool.isRequired,
  hideErrorMessageAboutIncorrectCurrentPassword: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {};

export default withStyles(style)(ChangePasswordForm);

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';

import _isEqual from 'lodash/isEqual';

import {
  updateEditableSingleSignOnConfiguration,
  setEditableSingleSignOnConfiguration,
} from './action';

import {
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {
  LabelWithCopyButton,
  NotifyModalWindow,
  RenderOrEmpty,
  Autocomplete,
  Switch,
  Dropdown,
} from '../../components/index';

import {
  validate,
  isNotEmpty,
  arrayIsNotEmpty,
} from '../../utils/validators';

import {AUTH_PROVIDERS, STATES_ENTITY} from '../../constants';

import style from './style';

class SingleSignOnEditForm extends React.Component {
  static initialLocalState = {
    validation: {
      clientId: true,
      oauthTenantId: true,
      selectedProviderAdminRoles: true,
      selectedProviderViewerRoles: true,
      selectedProviderServiceDeskRoles: true,
    },
    openConfigureSingleSignOnModalWindow: true,
  };

  static shouldUpdateSingleSignOnConfiguration(prevProps, props) {
    return !_isEqual(prevProps.singleSignOnConfiguration, props.singleSignOnConfiguration);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
          && _isEqual(props.singleSignOnState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.authProvidersList = [];
    this.selectedAuthProviderIndex = 0;
    this.authProvidersList.push(...Object.keys(AUTH_PROVIDERS).map((authProvider) =>
      I18n.t(`configureSingleSignOnPage.authProviders.${authProvider}`)));
    const isCreating = props.singleSignOnState === STATES_ENTITY.CREATING;
    this.state = {
      ...SingleSignOnEditForm.initialLocalState,
      openConfigureSingleSignOnModalWindow: isCreating,
    };
  }

  componentDidMount() {
    this.props.setEditableSingleSignOnConfiguration(this.props.singleSignOnConfiguration);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(
      this.props.editableSingleSignOnConfiguration,
      nextProps.editableSingleSignOnConfiguration,
    )
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.singleSignOnConfiguration, nextProps.singleSignOnConfiguration)
      || !_isEqual(
        this.state.openConfigureSingleSignOnModalWindow,
        nextState.openConfigureSingleSignOnModalWindow,
      );
  }

  componentDidUpdate(prevProps) {
    if (SingleSignOnEditForm.shouldUpdateSingleSignOnConfiguration(prevProps, this.props)
        || SingleSignOnEditForm.shouldResetFormData(prevProps, this.props)
    ) {
      this.props.setEditableSingleSignOnConfiguration(this.props.singleSignOnConfiguration);
      const isCreating = this.props.singleSignOnState === STATES_ENTITY.CREATING;
      this.setState({openConfigureSingleSignOnModalWindow: isCreating});
    }
  }

  validate = (data) => validate(
    [
      {
        name: 'clientId',
        validationRules: [isNotEmpty],
      },
      {
        name: 'oauthTenantId',
        validationRules: [isNotEmpty],
      },
      {
        name: 'selectedProviderAdminRoles',
        validationRules: [arrayIsNotEmpty],
      },
      {
        name: 'selectedProviderViewerRoles',
        validationRules: [arrayIsNotEmpty],
      },
      {
        name: 'selectedProviderServiceDeskRoles',
        validationRules: [arrayIsNotEmpty],
      },
    ],
    data,
    {
      clientId: true,
      oauthTenantId: true,
      selectedProviderAdminRoles: true,
      selectedProviderViewerRoles: true,
      selectedProviderServiceDeskRoles: true,
    },
  );

  handleChangeInput = (e) => {
    const {editableSingleSignOnConfiguration} = this.props;

    const validationResult = this.validate({
      ...this.props.editableSingleSignOnConfiguration,
      [e.target.name]: e.target.value,
    });

    this.props.updateEditableSingleSignOnConfiguration(
      {
        ...editableSingleSignOnConfiguration,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeAuthProviderDropdown = (e) => {
    this.selectedAuthProviderIndex = e.target.value;
  };

  enableDisableSingleSignOn = () => {
    const {editableSingleSignOnConfiguration} = this.props;

    this.props.updateEditableSingleSignOnConfiguration(
      {
        ...editableSingleSignOnConfiguration,
        ssoEnabled: !editableSingleSignOnConfiguration.ssoEnabled,
      },
      true,
    );
  };

  handleChangeSelectedProviderAdminRolesDropdown = (newValue) => {
    const {editableSingleSignOnConfiguration} = this.props;

    const validationResult = this.validate({
      ...this.props.editableSingleSignOnConfiguration,
      selectedProviderAdminRoles: newValue,
    });

    this.props.updateEditableSingleSignOnConfiguration(
      {
        ...editableSingleSignOnConfiguration,
        selectedProviderAdminRoles: newValue,
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeSelectedProviderViewerRolesDropdown = (newValue) => {
    const {editableSingleSignOnConfiguration} = this.props;

    const validationResult = this.validate({
      ...this.props.editableSingleSignOnConfiguration,
      selectedProviderViewerRoles: newValue,
    });

    this.props.updateEditableSingleSignOnConfiguration(
      {
        ...editableSingleSignOnConfiguration,
        selectedProviderViewerRoles: newValue,
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeSelectedProviderServiceDeskRolesDropdown = (newValue) => {
    const {editableSingleSignOnConfiguration} = this.props;

    const validationResult = this.validate({
      ...this.props.editableSingleSignOnConfiguration,
      selectedProviderServiceDeskRoles: newValue,
    });

    this.props.updateEditableSingleSignOnConfiguration(
      {
        ...editableSingleSignOnConfiguration,
        selectedProviderServiceDeskRoles: newValue,
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleClickOK = () => {
    this.setState({
      openConfigureSingleSignOnModalWindow: false,
    });
  };

  render() {
    const {editableSingleSignOnConfiguration, classes, isEditMode} = this.props;
    const {openConfigureSingleSignOnModalWindow, validation} = this.state;

    const labelProps = {
      selectedProviderRoles: {
        classes: {
          root: classes.form__formControl_response,
          shrink: classes.form__shrink_response,
        },
        shrink: true,
      },
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <Switch
            isChecked={editableSingleSignOnConfiguration.ssoEnabled}
            label={I18n.t('configureSingleSignOnPage.buttons.enableSingleSignOn')}
            name="enableSingleSignOn"
            switchClassName="switch_inline"
            labelFormClassName="formControl_sso"
            labelClassName="switch__label_sso"
            inputProps={{
              disabled: !isEditMode,
              onChange: this.enableDisableSingleSignOn,
            }}
          />
        </div>
        <div className={classes.form__block}>
          <CustomInput
            labelText={I18n.t('configureSingleSignOnPage.labels.callbackURL')}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              disabled: true,
              placeholder: I18n.t('configureSingleSignOnPage.placeholders.callbackURL'),
              value: editableSingleSignOnConfiguration.callbackURL,
              name: 'callbackURL',
              onChange: this.handleChangeInput,
            }}
          />
          <LabelWithCopyButton
            id="copy-callback-url"
            text={editableSingleSignOnConfiguration.callbackURL}
            showHeader={false}
            showText={false}
            snackbarMessage={I18n.t('configureSingleSignOnPage.copyCallbackURLToBufferMessage')}
          />
        </div>
        <div className={classes.form__item}>
          <Dropdown
            disabled={!isEditMode}
            label={I18n.t('configureSingleSignOnPage.labels.authenticationProvider')}
            fullWidth={true}
            options={this.authProvidersList}
            selectedItemIndex={this.selectedAuthProviderIndex}
            onChangeValue={this.handleChangeAuthProviderDropdown}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            labelText={I18n.t('configureSingleSignOnPage.labels.clientId')}
            errorText={
              validation.clientId
                ? undefined
                // eslint-disable-next-line max-len
                : I18n.t('configureSingleSignOnPage.errorMessages.configureSingleSignOnPageClientIDIsNotValid')
            }
            error={!validation.clientId}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              disabled: !isEditMode,
              placeholder: I18n.t('configureSingleSignOnPage.placeholders.clientId'),
              name: 'clientId',
              value: editableSingleSignOnConfiguration.clientId,
              onChange: this.handleChangeInput,
            }}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            errorText={
              validation.oauthTenantId
                ? undefined
                // eslint-disable-next-line max-len
                : I18n.t('configureSingleSignOnPage.errorMessages.configureSingleSignOnPageOauthTenantIdIsNotValid')
            }
            error={!validation.oauthTenantId}
            labelText={I18n.t('configureSingleSignOnPage.labels.oauthTenantId')}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              disabled: !isEditMode,
              placeholder: I18n.t('configureSingleSignOnPage.placeholders.oauthTenantId'),
              name: 'oauthTenantId',
              value: editableSingleSignOnConfiguration.oauthTenantId,
              onChange: this.handleChangeInput,
            }}
          />
        </div>
        <div className={classes.form__item}>
          <Autocomplete
            errorText={
              validation.selectedProviderAdminRoles
                ? undefined
                // eslint-disable-next-line max-len
                : I18n.t('configureSingleSignOnPage.errorMessages.configureSingleSignOnPageProviderAdminRolesAreNotValid')
            }
            error={!validation.selectedProviderAdminRoles}
            labelText={I18n.t('configureSingleSignOnPage.labels.selectedProviderAdminRoles')}
            helpText={I18n.t('configureSingleSignOnPage.help.selectedProviderAdminRoles')}
            formControlProps={{
              fullWidth: true,
            }}
            isDisabled={!isEditMode}
            inputProps={{
              // eslint-disable-next-line max-len
              placeholder: I18n.t('configureSingleSignOnPage.placeholders.selectedProviderAdminRoles'),
              name: 'selectedProviderAdminRoles',
              isMulti: true,
              value: editableSingleSignOnConfiguration.selectedProviderAdminRoles.map((role) => {
                if (role.label) return role;
                return (
                  {
                    label: role,
                    value: role,
                  }
                );
              }),
              onChange: this.handleChangeSelectedProviderAdminRolesDropdown,
              options: [],
              id: uuid(),
              noOptionsMessage: () => null,
            }}
            labelProps={labelProps.selectedProviderRoles}
            isCreatable={true}
          />
        </div>
        <div className={classes.form__item}>
          <Autocomplete
            errorText={
              validation.selectedProviderViewerRoles
                ? undefined
                // eslint-disable-next-line max-len
                : I18n.t('configureSingleSignOnPage.errorMessages.configureSingleSignOnPageProviderViewerRolesAreNotValid')
            }
            error={!validation.selectedProviderViewerRoles}
            labelText={I18n.t('configureSingleSignOnPage.labels.selectedProviderViewerRoles')}
            helpText={I18n.t('configureSingleSignOnPage.help.selectedProviderViewerRoles')}
            formControlProps={{
              fullWidth: true,
            }}
            isDisabled={!isEditMode}
            inputProps={{
              // eslint-disable-next-line max-len
              placeholder: I18n.t('configureSingleSignOnPage.placeholders.selectedProviderViewerRoles'),
              name: 'selectedProviderViewerRoles',
              isMulti: true,
              value: editableSingleSignOnConfiguration.selectedProviderViewerRoles.map((role) => {
                if (role.label) return role;
                return (
                  {
                    label: role,
                    value: role,
                  }
                );
              }),
              onChange: this.handleChangeSelectedProviderViewerRolesDropdown,
              options: [],
              noOptionsMessage: () => null,
            }}
            labelProps={labelProps.selectedProviderRoles}
            isCreatable={true}
          />
        </div>
        <div className={classes.form__item}>
          <Autocomplete
            errorText={
              validation.selectedProviderServiceDeskRoles
                ? undefined
                // eslint-disable-next-line max-len
                : I18n.t('configureSingleSignOnPage.errorMessages.configureSingleSignOnPageProviderServiceDeskRolesAreNotValid')
            }
            error={!validation.selectedProviderServiceDeskRoles}
            labelText={I18n.t('configureSingleSignOnPage.labels.selectedProviderServiceDeskRoles')}
            helpText={I18n.t('configureSingleSignOnPage.help.selectedProviderServiceDeskRoles')}
            formControlProps={{
              fullWidth: true,
            }}
            isDisabled={!isEditMode}
            inputProps={{
              // eslint-disable-next-line max-len
              placeholder: I18n.t('configureSingleSignOnPage.placeholders.selectedProviderServiceDeskRoles'),
              name: 'selectedProviderServiceDeskRoles',
              isMulti: true,
              // eslint-disable-next-line max-len
              value: editableSingleSignOnConfiguration.selectedProviderServiceDeskRoles.map((role) => {
                if (role.label) return role;
                return (
                  {
                    label: role,
                    value: role,
                  }
                );
              }),
              onChange: this.handleChangeSelectedProviderServiceDeskRolesDropdown,
              options: [],
              noOptionsMessage: () => null,
            }}
            labelProps={labelProps.selectedProviderRoles}
            isCreatable={true}
          />
        </div>
        <RenderOrEmpty isShow={openConfigureSingleSignOnModalWindow}>
          <NotifyModalWindow
                                text={I18n.t('modalWindow.configureSingleSignOn')}
                                close={this.handleClickOK}
          />
        </RenderOrEmpty>
      </div>
    );
  }
}

SingleSignOnEditForm.propTypes = ({
  classes: PropTypes.object.isRequired,
  editableSingleSignOnConfiguration: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  singleSignOnConfiguration: PropTypes.object.isRequired,
  singleSignOnState: PropTypes.string.isRequired,
  setEditableSingleSignOnConfiguration: PropTypes.func.isRequired,
  updateEditableSingleSignOnConfiguration: PropTypes.func.isRequired,
});

const mapStateToProps = (state) => ({
  // eslint-disable-next-line max-len
  editableSingleSignOnConfiguration: state.singleSignOnEditFormReducer.editableSingleSignOnConfiguration,
  singleSignOnConfiguration: state.singleSignOnReducer.selectedSingleSignOnConfiguration,
  singleSignOnState: state.singleSignOnReducer.singleSignOnState,
  isEditMode: state.singleSignOnReducer.isEditMode,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditableSingleSignOnConfiguration: bindActionCreators(
    updateEditableSingleSignOnConfiguration,
    dispatch,
  ),
  setEditableSingleSignOnConfiguration: bindActionCreators(
    setEditableSingleSignOnConfiguration,
    dispatch,
  ),
});

export default
connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SingleSignOnEditForm));

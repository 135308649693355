import React from 'react';
import PropTypes from 'prop-types';

import {ArrowBack} from '../../icons/index';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

function ButtonBack(props) {
  const {
    customClasses,
    disabled,
    onClick,
  } = props;

  return (
    <CustomButton
      color="primaryNoBackground"
      customClasses={customClasses}
      onClick={onClick}
      disabled={disabled}
      size="xs"
    >
      <ArrowBack />
    </CustomButton>
  );
}

ButtonBack.propTypes = {
  customClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ButtonBack.defaultProps = {
  customClasses: '',
  disabled: false,
};

export default ButtonBack;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import style from './style';

class InsightsFiltersTooltip extends React.PureComponent {
  render() {
    const {filtersEnabled, children, tooltipOptions, tooltipClasses, classes} = this.props;
    const defaultTooltipClasses = {
      tooltip: classes.insightsFiltersTooltip__tooltip,
      popper: classes.insightsFiltersTooltip__tooltipPopper,
    };
    const tooltipOptionsOrDefault = {
      classes: {...defaultTooltipClasses, ...tooltipClasses},
      placement: 'bottom-start',
      title: I18n.t('insights.filters.disabled'),
      ...tooltipOptions,
    };
    if (filtersEnabled) {
      return children;
    }
    return (
      <Tooltip
        {...tooltipOptionsOrDefault}
      >
        <div>
          {children}
        </div>
      </Tooltip>
    );
  }
}

InsightsFiltersTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  filtersEnabled: PropTypes.bool.isRequired,
  tooltipOptions: PropTypes.object,
  tooltipClasses: PropTypes.object,
};
InsightsFiltersTooltip.defaultProps = {
  tooltipOptions: {},
  tooltipClasses: {},
};

export default withStyles(style)(InsightsFiltersTooltip);

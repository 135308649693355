const style = {
  organizationPersonasTab__tableBody: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 802px)',
    },
  },
  organizationPersonasTab__tableBody_noDashboard: {
    '@media(min-height: 480px)': {
      height: 'calc(100vh - 452px)',
    },
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import {RenderOrEmpty} from '../index';

import style from './style';

function ContainerWithListAndForm(props) {
  const {
    classes,
    list,
    form,
  } = props;
  const listClassName = classNames(classes.list, {
    [classes.list_compressed]: !!form,
  });
  return (
    <div className={classes.container}>
      <RenderOrEmpty isShow={!!list}>
        <div className={listClassName}>
          {list}
        </div>
      </RenderOrEmpty>
      <RenderOrEmpty isShow={!!form}>
        <div className={classes.form}>
          {form}
        </div>
      </RenderOrEmpty>
    </div>
  );
}

ContainerWithListAndForm.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.node,
  form: PropTypes.node,
};

ContainerWithListAndForm.defaultProps = {
  list: null,
  form: null,
};

export default withStyles(style)(ContainerWithListAndForm);

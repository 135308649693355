const style = {
  name: {
    textAlign: 'center',
  },
  default: {
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'center',
  },
};

export default style;

import cardsStyle from '../../styles/components/cards';

const style = () => ({
  clientInfoTableCard: {
    ...cardsStyle.card,
  },
  clientInfoTableCard__header: {
    ...cardsStyle.card__header,
  },
  clientInfoTableCard__title: {
    ...cardsStyle.card__title,
    ...cardsStyle.card__title_primary,
    borderBottom: 'none',
    fontSize: '20px',
    fontWeight: '400',
  },
  clientInfoTableCard__content: {
    ...cardsStyle.card__content,
    padding: '0px 20px 5px 20px !important',
  },
});

export default style;

import {types} from './constants';
import {types as authTypes} from '../../actions/auth/actionTypes';
import initState from '../../reducers/redux-initial-state';
import {
  getCampusesTableData,
  getUpdatedCampusIndex,
} from './service';

export default (state = initState.campusReducer, action) => {
  switch (action.type) {
    case authTypes.ACTION_LOGOUT_SUCCESS: {
      return {
        ...initState.campusReducer,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_CAMPUS_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        campusState: action.campusState,
      };
    }
    case types.SET_CAMPUSES: {
      return {
        ...state,
        campuses: action.data,
      };
    }
    case types.SET_SELECTED_CAMPUS: {
      return {
        ...state,
        selectedCampus: action.data,
      };
    }
    case types.SET_CAMPUSES_TABLE_DATA: {
      return {
        ...state,
        campusesTableData: getCampusesTableData(
          state.campuses,
          action.campusesStats,
        ),
      };
    }
    case types.SET_SELECTED_CAMPUS_INDEX: {
      return {
        ...state,
        selectedCampusIndex: action.selectedCampusIndex,
      };
    }
    case types.SET_SELECTED_PERIOD_INDEX: {
      return {
        ...state,
        selectedPeriodIndex: action.index,
      };
    }
    case types.SET_UPDATED_CAMPUS: {
      return {
        ...state,
        selectedCampusIndex: getUpdatedCampusIndex(action.data.id, state.campuses),
        selectedCampus: action.data,
      };
    }
    case types.RESET_SELECTED_CAMPUS_INDEX: {
      return {
        ...state,
        selectedCampusIndex: initState.campusReducer.selectedCampusIndex,
        selectedCampus: initState.campusReducer.selectedCampus,
      };
    }
    case types.RESET_CAMPUSES: {
      return {
        ...state,
        campuses: initState.campusReducer.campuses,
      };
    }
    case types.SET_CAMPUS_POLICIES: {
      return {
        ...state,
        policies: action.data,
      };
    }
    default:
      return state;
  }
};

import {types} from './constants';
import {types as authTypes} from '../../actions/auth/actionTypes';
import initState from '../../reducers/redux-initial-state';
import {
  getBlockPagesTableData,
  getUpdatedBlockPageIndex,
} from './service';

export default (state = initState.blockPageReducer, action) => {
  switch (action.type) {
    case authTypes.ACTION_LOGOUT_SUCCESS: {
      return {
        ...initState.blockPageReducer,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_BLOCK_PAGE_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        blockPageState: action.blockPageState,
      };
    }
    case types.SET_BLOCK_PAGES: {
      return {
        ...state,
        blockPages: action.data,
      };
    }
    case types.SET_SELECTED_BLOCK_PAGE: {
      return {
        ...state,
        selectedBlockPage: action.data,
      };
    }
    case types.SET_BLOCK_PAGES_TABLE_DATA: {
      return {
        ...state,
        blockPagesTableData: getBlockPagesTableData(
          state.blockPages,
          action.blockPagesStats,
        ),
      };
    }
    case types.SET_SELECTED_BLOCK_PAGE_INDEX: {
      return {
        ...state,
        selectedBlockPageIndex: action.selectedBlockPageIndex,
      };
    }
    case types.SET_UPDATED_BLOCK_PAGE: {
      return {
        ...state,
        selectedBlockPageIndex: getUpdatedBlockPageIndex(action.data.id, state.blockPages),
        selectedBlockPage: action.data,
      };
    }
    case types.RESET_SELECTED_BLOCK_PAGE_INDEX: {
      return {
        ...state,
        selectedBlockPageIndex: initState.blockPageReducer.selectedBlockPageIndex,
        selectedBlockPage: initState.blockPageReducer.selectedBlockPage,
      };
    }
    case types.SET_BLOCK_PAGE_LOGO_URL: {
      return {
        ...state,
        selectedBlockPage: {
          ...action.data.blockPage,
          contentLogoURL: action.data.url,
        },
      };
    }
    default:
      return state;
  }
};

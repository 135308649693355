import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';

import {withStyles} from '@material-ui/core/styles';

import {
  GroupStatsCard,
  RenderDivOrEmpty,
} from '../index';
import {LoadingLayout} from '../../layouts-common';
import {TABS as clientsTabs} from '../../app-common/Clients/constants';

import style from './style';

function ThreatStats(props) {
  const {
    classes,
    stats,
    widgetKey,
  } = props;

  const threatData = stats.map((item) => {
    const countClasses = classNames(
      classes.part_count,
      {
        [classes.part_count_danger]: item.count > 0,
      },
    );
    return (
      <div key={item.threat} className={classes.item}>
        <span className={classes.part_threat}>{item.threat}</span>
        <span className={countClasses}>{item.count}</span>
      </div>
    );
  });

  return (
    <GroupStatsCard
        groupName={I18n.t(`clients.${clientsTabs.MANAGE}.editClientForm.threats.title`)}
        customCardClassName="card_threats"
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <RenderDivOrEmpty isShow={!_isEmpty(threatData)} className={classes.container}>
              {threatData}
            </RenderDivOrEmpty>
          </LoadingLayout>
        )}
    />
  );
}

ThreatStats.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.array,
  widgetKey: PropTypes.string.isRequired,
};
ThreatStats.defaultProps = {
  stats: [],
};

export default withStyles(style)(ThreatStats);

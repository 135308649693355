import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import initState from '../../reducers/redux-initial-state';
import {types} from './constants';

export default (state = initState.clientBundleEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_CLIENT_BUNDLE_INFO: {
      const newState = _cloneDeep(state);
      newState.editableBundleInfo = _cloneDeep(action.bundle);
      newState.editableBundleInfo.maxInstalls = _get(action.bundle, 'installs.max', '');
      return newState;
    }
    case types.RESET_CLIENT_BUNDLE_EDIT_FORM_DATA: {
      return _cloneDeep(initState.clientBundleEditFormReducer);
    }
    case types.SET_SELECTED_CLIENT_BUNDLE: {
      const newState = _cloneDeep(state);
      newState.selectedBundle = action.bundle;
      return newState;
    }
    case types.SET_VALIDATION_CLIENT_BUNDLE_INFO: {
      return {
        ...state,
        isValidBundleInfo: action.isValid,
      };
    }
    case types.UPDATE_CLIENT_BUNDLE_INFO: {
      const newState = _cloneDeep(state);
      newState.editableBundleInfo = action.bundle;
      newState.isValidBundleInfo = action.isValidBundleInfo;
      return newState;
    }
    case types.SET_SELECTED_PLATFORM_INDEX: {
      return {
        ...state,
        selectedPlatformIndex: action.index,
      };
    }
    case types.SET_SELECTED_ARCHITECTURE_INDEX: {
      return {
        ...state,
        selectedArchitectureIndex: action.index,
      };
    }
    case types.SET_SELECTED_DURATION_INDEX: {
      return {
        ...state,
        selectedDurationIndex: action.index,
      };
    }
    case types.SET_ARCHITECTURES: {
      return {
        ...state,
        architectures: action.architectures,
      };
    }

    default:
      return state;
  }
};

import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import initState from '../../reducers/redux-initial-state';
import {types} from './constants';
import {getUpdatedProfileIndex} from './utils';

export default (state = initState.clientsConfigurerReducer, action) => {
  switch (action.type) {
    case types.SET_PROFILES: {
      return {
        ...state,
        profiles: action.profiles,
      };
    }
    case types.SET_PLATFORMS: {
      return {
        ...state,
        platforms: action.platforms,
      };
    }
    case types.RESET_CLIENT_CONFIGURER_DATA: {
      return {
        ...initState.clientsConfigurerReducer,
      };
    }
    case types.SET_SELECTED_CLIENT_PROFILE: {
      return {
        ...state,
        selectedProfile: action.profile,
      };
    }
    case types.SET_SELECTED_CLIENT_PROFILE_COPY: {
      const newState = _cloneDeep(state);
      newState.profileToCopy = _cloneDeep(action.profile);
      newState.profileToCopy.name = _get(action, 'currentProfile.name', '');
      newState.profileToCopy.description = _get(action, 'currentProfile.description', '');
      newState.profileToCopy.id = _get(action, 'currentProfile.id', null);
      return newState;
    }

    case types.SET_UPDATED_CLIENT_PROFILE: {
      return {
        ...state,
        selectedProfileIndex: getUpdatedProfileIndex(action.profile.id, state.profiles),
        selectedProfile: action.profile,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_CLIENT_PROFILE_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        profileState: action.profileState,
      };
    }
    case types.SET_SELECTED_CLIENT_PROFILE_INDEX: {
      return {
        ...state,
        selectedProfileIndex: action.selectedProfileIndex,
      };
    }
    case types.RESET_SELECTED_CLIENT_PROFILE_INDEX: {
      return {
        ...state,
        selectedProfile: initState.clientsConfigurerReducer.selectedProfile,
        selectedProfileIndex: initState.clientsConfigurerReducer.selectedProfileIndex,
      };
    }
    case types.SET_INSTALLERS: {
      return {
        ...state,
        installers: action.installers,
      };
    }
    case types.SET_CLIENT_PROFILE_POLICIES: {
      return {
        ...state,
        policies: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityCreatingCard} from '../index';
import {
  OrganizationEditForm,
  OrganizationSitesTab,
  OrganizationProfilesTab,
} from '../../containers';

class OrganizationCreating extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = [
      {
        text: I18n.t('organizations.editForm.tabs.sites'),
        name: 'sites',
      },
      {
        text: I18n.t('organizations.editForm.tabs.profiles'),
        name: 'profiles',
      },
    ];
  }

  getTabContent = (tabName) => {
    const tabContentMap = {
      settings: <OrganizationSitesTab />,
      profiles: <OrganizationProfilesTab />,
    };

    return tabContentMap[tabName];
  };

  render() {
    const {
      classes,
      handleClickButtonCancel,
      handleClickButtonSave,
      isValidOrganizationInfo,
    } = this.props;

    return (
      <EntityCreatingCard
        title={I18n.t('organizations.editForm.createOrganization')}
        handleClickButtonCancel={handleClickButtonCancel}
        handleClickButtonSave={handleClickButtonSave}
        isValidEntity={isValidOrganizationInfo}
        customCardContentClass="card__content_organization"
        content={(
          <OrganizationEditForm
            classes={classes}
            tabs={this.tabs}
            getTabContent={this.getTabContent}
          />
        )}
      />
    );
  }
}

OrganizationCreating.propTypes = {
  classes: PropTypes.object,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidOrganizationInfo: PropTypes.bool.isRequired,
};

OrganizationCreating.defaultProps = {
  classes: {},
};

export default OrganizationCreating;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  EntityEditingCard,
} from '../index';

import {
  ClientProfileEditForm,
  ClientProfileInstallForm,
  ClientProfileLocalForwardingForm,
  ClientProfileSettingsEditForm,
} from '../../containers/index';
import {TABS as clientTabs} from '../../app-common/Clients/constants';

import style from './style';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.CLIENT_PROFILE_EDIT],
  buttonDelete: [ACCESS_RIGHTS.CLIENT_PROFILE_DELETE],
};

class ClientProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.tabs = [
      {
        text: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.settingsTab.tabName`),
        name: 'settings',
      },
      {
        text: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.localForwardingTab.tabName`),
        name: 'localForwarding',
      },
      // TODO: uncomment when the components will be implemented
      // {
      //   text:
      //    I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.localRedirectsTab.tabName`),
      //   name: 'localRedirects',
      // },
      {
        text: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.installTab.tabName`),
        name: 'install',
      },
    ];
  }

  getTabContent = (tabName) => {
    const tabContentMap = {
      settings: <ClientProfileSettingsEditForm
                  copyProfile={this.props.copyProfile}
      />,
      localForwarding: <ClientProfileLocalForwardingForm />,
      localRedirects: <div>Local Redirects tab...</div>,
      install: <ClientProfileInstallForm />,
    };

    return tabContentMap[tabName];
  };

  render() {
    const {
      classes,
      profile,
      getPolicies,
      handleClickButtonBack,
      handleClickButtonDelete,
      handleClickButtonEdit,
      handleClickButtonCancelEdit,
      handleClickButtonSave,
      isValidProfileInfo,
      isEditMode,
      isSubTabEditMode,
    } = this.props;

    const hasSubTabEditMode = Object.keys(isSubTabEditMode).reduce((allValid, key) =>
      allValid || isSubTabEditMode[key], false);

    return (
      <EntityEditingCard
        title={profile.name}
        entityType={I18n.t('entitiesTypes.clientProfile')}
        isThereDeleteBlock={true}
        isTherePathBack={true}
        isThereEditBlock={!hasSubTabEditMode}
        isEditMode={isEditMode || hasSubTabEditMode}
        isValidEntity={isValidProfileInfo}
        handleClickButtonBack={handleClickButtonBack}
        handleClickButtonDelete={handleClickButtonDelete}
        handleClickButtonEdit={handleClickButtonEdit}
        handleClickButtonCancel={handleClickButtonCancelEdit}
        handleClickButtonSave={handleClickButtonSave}
        customCardContentClass="card__content_clientProfile"
        accessRights={rightAvailabilityMap}
        showOnAccessDenyButtonDelete={true}
        isFullscreen={false}
        content={(
          <ClientProfileEditForm
            classes={classes}
            tabs={this.tabs}
            getTabContent={this.getTabContent}
            getPolicies={getPolicies}
          />
        )}
      />
    );
  }
}

ClientProfileDetails.propTypes = {
  profile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  copyProfile: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isSubTabEditMode: PropTypes.object.isRequired,
  isValidProfileInfo: PropTypes.bool.isRequired,
};

export default withStyles(style)(ClientProfileDetails);

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';
import {types} from './constants';
import {getPersonaIds} from './service';

export function getOrganizationPersonas(organizationId) {
  const url = makeUrl(METHODS.GET_ORGANIZATION_PERSONAS, {organizationId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_PERSONAS,
            data,
          });
        }
      });
}

export function removePersonaFromOrganization(personaIndex, organizationId, personaId) {
  const url = makeUrl(METHODS.REMOVE_PERSONA_FROM_ORGANIZATION);
  const data = {
    personaId: personaId,
  };

  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: types.REMOVE_PERSONA_FROM_ORGANIZATION,
            personaIndex,
          });
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to remove persona from organization:', error);
      });
}

export function updateOrganizationPersonas(organizationId, personasInOrganization) {
  const url = makeUrl(METHODS.UPDATE_ORGANIZATION_PERSONAS);
  const data = {
    organizationId,
    personaIds: getPersonaIds(personasInOrganization),
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changePersonasEditMode(false, STATES_ENTITY.NONE));
          dispatch(getOrganizationPersonas(organizationId));
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to update organization personas:', error);
      });
}

export function changePersonasEditMode(isEditMode) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_PERSONAS_EDIT_MODE,
      isEditMode,
    });
  };
}

const style = {
  rowContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  groupsContainer: {
    'display': 'flex',
    'gap': '16px',
    'alignItems': 'start',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 0,
    },
  },
  groupsContainer__item: {
    flex: 1,
  },
  dropdown: {
    minWidth: '150px',
  },
};

export default style;

import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core';
import classNames from 'classnames';
import {I18n} from 'react-redux-i18n';
import {useSelector} from 'react-redux';

import {PerformanceStatsWidget,
  ThreatStatsWidget,
  UniversalDashboard} from '../../containers';
import {filterTypes} from '../../containers/Filters/constants';
import {PERIODS} from '../../constants';
import {widgetKeys} from '../../app-common/Users/constants';

import style from './style';
import UserDetailsGraphs from '../UserDetailsGraphs/UserDetailsGraphs';

type UserDashboardProps = WithStyles<typeof style> & {
  userId: string;
}

/**
 * Dashboard to show user's stats and activity
 * @param {userId} userId - id of user to get stats of
 */
function UserDashboard({classes, userId}: UserDashboardProps) {
  const filters = useSelector((store: any) => store.filtersReducer);

  const periods = [
    ...Object.keys(PERIODS).map((period) =>
      I18n.t(`dashboard.filters.${period}`)),
  ];

  const filtersMetadata = () => [
    {
      name: 'period',
      type: filterTypes.DROPDOWN,
      label: '',
      initIndex: 0,
      options: periods,
    },
  ];

  const getWidgetFilters = () => {
    const queryFilters: {adUserId: string, period?: string} = {adUserId: userId};
    if (filters.period >= 0) {
      queryFilters.period = periods[filters.period];
    }
    return queryFilters;
  };

  const widgets = () => (
    <div className={classes.userDashboard__statsPanel}>
      <div
          className={classNames(
            classes.statsPanel__block,
            classes.statsPanel__block_left,
          )}
      >
        <div className={classes.statsPanel__card}>
          <ThreatStatsWidget
              filters={getWidgetFilters()}
              readyToLoadStats={!!userId}
              widgetKey={widgetKeys.THREAT_STATS}
          />
        </div>
        <div className={classes.statsPanel__card}>
          <PerformanceStatsWidget
              filters={getWidgetFilters()}
              readyToLoadStats={!!userId}
              widgetKey={widgetKeys.PERFORMANCE_STATS}
          />
        </div>
      </div>
      <div className={classNames(
        classes.statsPanel__block,
        classes.statsPanel__block_right,
      )}
      >
        <div className={classes.statsPanel__item_graph}>
          <UserDetailsGraphs
            filters={getWidgetFilters()}
            readyToLoadStats={!!userId}
          />
        </div>
      </div>
    </div>
  );

  return (
    <UniversalDashboard
      filtersMetadata={filtersMetadata()}
      widgets={widgets()}
    />
  );
}

export default withStyles(style)(UserDashboard);

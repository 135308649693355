const marginBottom = '15px';

const style = {
  dashboard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dashboard__filtersPanel: {
    marginBottom: marginBottom,
    flexBasis: '5%',
  },

  dashboard__stats: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'flexWrap': 'wrap',
    '@media (max-width: 1350px)': {
      flexDirection: 'column',
    },
  },

  stats__block: {
    marginBottom: marginBottom,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stats__block_numeric: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stats__block_numeric_deviceAgentNotAllowed: {
    flexBasis: '29%',
  },
  stats__block_numeric_deviceAgentAllowed: {
    flexBasis: '39%',
  },
  stats__numericCards: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  stats__numericCards_deviceAgentAllowed: {
    flexBasis: '49%',
  },
  stats__numericCards_deviceAgentNotAllowed: {
    flexBasis: '100%',
  },

  stats__block_graph_deviceAgentAllowed: {
    flexBasis: '60%',
  },
  stats__block_graph_deviceAgentNotAllowed: {
    flexBasis: '70%',
  },
  stats__block_bottom: {
    display: 'none',
    flexBasis: '100%',
  },

  cardBlocks__block: {
    'flexBasis': '48%',
    '&:last-child': {
      '@media (max-width: 1350px)': {
        marginTop: '10px',
      },
    },
  },

  dashboard__pairBlocks: {
    'marginBottom': marginBottom,
    'flexBasis': '30%',
    'display': 'flex',
    'justifyContent': 'space-between',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  dashboard__pairBlocks_first: {
    flexBasis: '49.5%',
  },
  dashboard__pairBlocks_second: {
    flexBasis: '49.5%',
  },

  topCategories: {
    'display': 'flex',
    'align-items': 'center',
    'justifyContent': 'space-between',
    '@media (max-width: 540px)': {
      flexDirection: 'column',
    },
  },
  topCategories__graph: {
    flexBasis: '49%',
  },
  topCategories__table: {
    flexBasis: '49%',
  },
  topBlock__thereIsNotData: {
    padding: '15px 0',
    margin: '0 auto',
    fontSize: '20px',
    width: '200px',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {CustomInput, FormControlLabel} from '../../material-dashboard-pro-react/components';
import {COMPONENT_TYPES} from '../../app-common/Insights/constants';

import initState from '../../reducers/redux-initial-state';
import {MultiDropdown, RenderOrEmpty, Dropdown, Autocomplete} from '../index';
import style from './style';

class InsightsFilters extends React.PureComponent {
  get componentByType() {
    return {
      [COMPONENT_TYPES.DROPDOWN]: this.getDropdown,
      [COMPONENT_TYPES.MULTIDROPDOWN]: this.getMultiDropdown,
      [COMPONENT_TYPES.AUTOCOMPLETE]: this.getAutocomplete,
      [COMPONENT_TYPES.INPUT]: this.getInput,
    };
  }

  setFilter = (filterName, value) => this.props.setFilter(
    filterName,
    {isApplied: true, value},
  );

  setAppliance = (filterName, isApplied) => this.props.setFilter(
    filterName,
    {
      ..._get(initState, `insightsReducer.filters.${this.props.tabName}.${filterName}`),
      isApplied,
    },
  );

  getMultiDropdown = (f) => {
    const displayedField = f.id === 'client' ? 'clientName' : undefined;

    return (
      <MultiDropdown
        customSelectClass="multiDropdown__select_insightsFilters"
        multiDropdownName={f.name}
        fullWidth={true}
        onChangeValue={(e) => this.setFilter(f.id, e.target.value)}
        options={f.options}
        selectedItemsIndexes={f.value}
        placeholder={I18n.t('insights.filters.placeholder')}
        displayedField={displayedField}
      />
    );
  };

  getAutocomplete = (f) => {
    const displayedField = f.id === 'client' ? 'clientName' : 'name';

    return (
      <Autocomplete
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          placeholder: I18n.t('insights.filters.placeholder'),
          name: f.name,
          isMulti: true,
          onChange: (e) => this.setFilter(f.id, e),
          options: f.options.map((option, index) => (
            {
              label: option[displayedField],
              value: option[displayedField],
              index,
            }
          )),
          value: f.value,
        }}
        isCreatable={false}
      />
    );
  };

  getDropdown = (f) => (
    <Dropdown
      customSelectClass="multiDropdown__select_insightsFilters"
      dropdownName={f.name}
      fullWidth={true}
      onChangeValue={(e) => this.setFilter(f.id, e.target.value)}
      options={f.options.map((o) => o.name)}
      selectedItemIndex={f.value}
    />
  );

  getInput = (f) => {
    const {classes} = this.props;
    const inputClasses = {
      formControl: classes.insightsFilters__input,
    };
    return (
      <CustomInput
        id={f.id}
        key={f.name}
        inputProps={{
          value: f.value,
          onChange: (e) => this.handleInputChange(f, e),
          placeholder: f.placeholder,
        }}
        classes={inputClasses}
        error={!f.isValid}
      />
    );
  };

  handleInputChange = (field, e) => {
    this.props.setValidation(field.id, true);
    this.setFilter(field.id, e.target.value);
  };

  render() {
    const checkboxClasses = {
      checked: this.props.classes.insightsFilters__checkbox_checked,
    };
    return this.props.filters.map((f) => {
      const getComponent = this.componentByType[f.type];
      return (
        <div key={f.id}>
          <FormControlLabel
            control={(
              <Checkbox
                classes={checkboxClasses}
                checked={f.isApplied}
                onChange={(e) => this.setAppliance(f.id, e.target.checked)}
                name={f.name}
              />
            )}
            label={f.name}
          />
          <RenderOrEmpty isShow={f.isApplied && !!getComponent}>
            {getComponent(f)}
          </RenderOrEmpty>
        </div>
      );
    });
  }
}

InsightsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isApplied: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired).isRequired,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number),
      PropTypes.number, PropTypes.string]),
    type: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  setFilter: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  setValidation: PropTypes.func.isRequired,
};
InsightsFilters.defaultProps = {};

export default withStyles(style)(InsightsFilters);

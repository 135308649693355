import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {ROUTES} from '../../constants';
import {ApiKeysTable, ContainerWithListAndForm} from '../../components';
import {ApiKeyCreatingForm, ApiKeyEditForm} from '../../containers';
import {getApiKeys, resetState, setEditingMode, updateApiKey} from './action';
import {getUserAccounts} from '../../actions/userAccounts/getUserAccounts';
import {hasAuthorities} from '../../utils/account';

class ApiAccessManagement extends React.Component {
  static initialState = {
    selectedApiKey: null,
    selectedApiKeyIndex: -1,
    isNewApiKey: false,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId);
  }

  constructor(props) {
    super(props);
    this.state = {...ApiAccessManagement.initialState};
  }

  componentDidMount() {
    if (this.props.accountInfo.apiAccessAllowed && hasAuthorities('apiAccessManagement')) {
      this.props.getApiKeys();
    } else {
      this.props.history.push(ROUTES.DEFAULT);
    }
  }

  componentDidUpdate(prevProps) {
    if (ApiAccessManagement.wasDataScopeSwitched(prevProps, this.props)) {
      if (this.props.accountInfo.apiAccessAllowed && hasAuthorities('apiAccessManagement')) {
        this.props.getApiKeys();
        this.resetState();
      } else {
        this.props.history.push(ROUTES.DEFAULT);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  get apiKeyForm() {
    if (this.state.selectedApiKey) {
      return (
        <ApiKeyEditForm apiKey={this.state.selectedApiKey}
                        apiKeys={this.props.apiKeys}
                        updatingCallback={this.updatingCallback}
                        resetSelectedApiKey={this.resetState}
                        updateApiKey={this.updateApiKey}
        />
      );
    }
    if (this.state.isNewApiKey) {
      return (
        <ApiKeyCreatingForm resetForm={this.resetState}
                         updatingCallback={this.updatingCallback}
        />
      );
    }
    return null;
  }

  get isTableCompressed() {
    return !!this.state.selectedApiKey || this.state.isNewApiKey;
  }

  selectApiKey = (e) => this.setState({
    selectedApiKey: _get(this.props.apiKeys, e, null),
    selectedApiKeyIndex: e,
  });

  resetState = () => {
    this.props.setEditingMode(false);
    this.setState({...ApiAccessManagement.initialState});
  };

  updateApiKey = (apiKey) => this.props.updateApiKey(apiKey)
    .then(() => this.updatingCallback());

  updatingCallback = () => {
    this.props.setEditingMode(false);
    this.setState({...ApiAccessManagement.initialState});
    this.props.getApiKeys();
  };

  openAddingForm = () => {
    this.props.setEditingMode(true);
    this.setState({
      ...ApiAccessManagement.initialState,
      isNewApiKey: true,
    });
  };

  render() {
    return (
      <ContainerWithListAndForm
        list={(
          <ApiKeysTable apiKeys={this.props.apiKeys}
                        handleClickRow={this.selectApiKey}
                        isCompressed={this.isTableCompressed}
                        isEditing={this.props.isEditingMode}
                        selectedApiKeyIndex={this.state.selectedApiKeyIndex}
                        handleClickNew={this.openAddingForm}
          />
        )}
        form={this.apiKeyForm}
      />
    );
  }
}

ApiAccessManagement.propTypes = {
  getApiKeys: PropTypes.func.isRequired,
  apiKeys: PropTypes.array.isRequired,
  accountInfo: PropTypes.object.isRequired,
  updateApiKey: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setEditingMode: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  resetState: PropTypes.func.isRequired,

  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  getUserAccounts: PropTypes.func.isRequired,
};

ApiAccessManagement.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  apiKeys: state.apiAccessManagementReducer.apiKeys,
  isEditingMode: state.apiAccessManagementReducer.isEditingMode,

  accountInfo: state.operatorLayoutReducer.accountInfo,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

const mapDispatchToProps = {
  getApiKeys,
  updateApiKey,
  getUserAccounts,
  setEditingMode,
  resetState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiAccessManagement));

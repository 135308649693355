import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import {withStyles} from '@material-ui/core/styles';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import style from './style';

import {CustomInput, CustomLabel} from '../../material-dashboard-pro-react/components';
import {TABS as clientTabs} from '../../app-common/Clients/constants';
import {MAX_LOCAL_DNS_IPS, STATES_ENTITY} from '../../constants';
import {ButtonNew, InputList, RenderOrEmpty, Switch} from '../../components';
import {areAllValidIp, isNotEmpty, validate} from '../../utils/validators';
import {
  setValidationResultLocalForwardingInfo,
  updateLocalForwardingInfo,
  resetLocalForwardingEditFormData,
} from './action';

class ClientLocalForwardingEditForm extends React.Component {
  static initialLocalState = {
    validation: {
      domain: true,
      resolverIps: [],
    },
  };

  static shouldResetFormData(prevProps, props) {
    return (!_isEqual(prevProps.isEditMode, props.isEditMode)
        && _isEqual(props.localForwardingState, STATES_ENTITY.EDITING_CANCELED))
      || !_isEqual(prevProps.domain, props.domain);
  }

  constructor(props) {
    super(props);
    this.state = _cloneDeep(ClientLocalForwardingEditForm.initialLocalState);
  }

  componentDidMount() {
    this.props.updateLocalForwardingInfo(this.props.domain, true);
    this.resetValidation();
    this.props.setFormValidatorRef(this.validateFormData);
  }

  componentDidUpdate(prevProps) {
    if (ClientLocalForwardingEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.updateLocalForwardingInfo(this.props.domain, true);
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetLocalForwardingEditFormData();
    this.props.setFormValidatorRef(null);
  }

  handleChangeDomainInput = (e) => {
    const {editableLocalForwardingInfo} = this.props;

    // eslint-disable-next-line
    const newValidation = {...this.state.validation};
    newValidation.domain = true;
    this.setState({validation: newValidation});
    this.props.updateLocalForwardingInfo(
      {
        ...editableLocalForwardingInfo,
        domain: e.target.value,
      },
      this.getValidationResult(newValidation),
    );
  };

  handleChangeResolverIpInput = (e, index) => {
    const {editableLocalForwardingInfo} = this.props;
    const resolverIps = [...editableLocalForwardingInfo.resolverIps];
    resolverIps[index] = e.target.value;
    const updatedInfo = {
      ...editableLocalForwardingInfo,
      resolverIps,
    };

    // eslint-disable-next-line
    const newValidation = {...this.state.validation};
    newValidation.resolverIps[index] = true;
    this.setState({validation: newValidation});
    this.props.updateLocalForwardingInfo(
      updatedInfo,
      this.getValidationResult(newValidation),
    );
  };

  handleChangeSwitch = (e) => {
    const {editableLocalForwardingInfo} = this.props;
    this.props.updateLocalForwardingInfo(
      {
        ...editableLocalForwardingInfo,
        useDeviceResolvers: e.target.checked,
      },
      true,
    );
    this.resetValidation();
  };

  addResolverIp = () => {
    const {editableLocalForwardingInfo} = this.props;
    if (editableLocalForwardingInfo.resolverIps.length >= MAX_LOCAL_DNS_IPS) {
      return false;
    }
    const resolverIps = [...editableLocalForwardingInfo.resolverIps, ''];
    this.props.updateLocalForwardingInfo(
      {
        ...editableLocalForwardingInfo,
        resolverIps,
      },
      true,
    );

    const {validation} = this.state;
    const ipsValidation = [...validation.resolverIps, true];
    this.setState((prevState) => ({
      validation: {
        ...prevState.validation,
        resolverIps: ipsValidation,
      },
    }));
  };

  removeResolverIp = (index) => {
    const {editableLocalForwardingInfo} = this.props;
    if (editableLocalForwardingInfo.resolverIps.length === 1) {
      return false;
    }
    const resolverIps = [...editableLocalForwardingInfo.resolverIps];
    resolverIps.splice(index, 1);
    this.props.updateLocalForwardingInfo(
      {
        ...editableLocalForwardingInfo,
        resolverIps,
      },
      true,
    );

    const {validation} = this.state;
    const ipsValidation = [...validation.resolverIps];
    ipsValidation.splice(index, 1);
    this.setState((prevState) => ({
      validation: {
        ...prevState.validation,
        resolverIps: ipsValidation,
      },
    }));
  };

  resetValidation = () => {
    this.props.setValidationResultLocalForwardingInfo(true);
    const initValidation = {
      ...ClientLocalForwardingEditForm.initialLocalState.validation,
      resolverIps: Array.from(
        {length: Math.max(1, this.props.domain.resolverIps.length)},
        () => true),
    };
    this.setState({validation: initValidation});
  };

  getValidationResult = (validationState) =>
    validationState.domain && validationState.resolverIps.reduce(
      (isValid, item) => isValid && item,
      true,
    );

  validateFormData = () => {
    const {editableLocalForwardingInfo} = this.props;
    const fieldsToValidate = [{
      name: 'domain',
      validationRules: [isNotEmpty],
    }];
    if (!editableLocalForwardingInfo.useDeviceResolvers) {
      fieldsToValidate.push({
        name: 'resolverIps',
        type: 'array',
        validationRules: [areAllValidIp],
      });
    }
    const validationResult = validate(
      fieldsToValidate,
      editableLocalForwardingInfo,
      // eslint-disable-next-line
      this.state.validation,
    );
    this.setState({
      validation: validationResult.validationState,
    });
    this.props.setValidationResultLocalForwardingInfo(
      validationResult.isValid,
    );
    return validationResult.isValid;
  };

  getIpErrorMessage = (index) => {
    if (!this.state.validation.resolverIps[index]) {
      return I18n.t(`clients.${clientTabs.CONFIGURE}.localForwarding.errorMessages.ipIsNotValid`);
    }
    return undefined;
  };

  render() {
    const {
      classes,
      isEditMode,
      editableLocalForwardingInfo,
    } = this.props;
    const {validation} = this.state;
    const propsDomainInput = {
      disabled: !isEditMode,
      value: editableLocalForwardingInfo.domain,
      name: 'domain',
      onChange: this.handleChangeDomainInput,
      type: 'text',
    };
    const propsFormControl = {
      fullWidth: true,
    };

    const propsIpInputLabel = {
      shrink: true,
      className: classes.form__ipsLabel,
    };
    const ipInputListClasses = {
      inputs: classes.form__ipsBlock,
      formControl: classes.form__ipsFormControl,
    };

    return (
      <div className={classes.clientBundleEdit}>
        <CustomInput
          errorText={validation.domain
            ? undefined
            // eslint-disable-next-line max-len
            : I18n.t(`clients.${clientTabs.CONFIGURE}.localForwarding.errorMessages.domainNotValid`)}
          error={!validation.domain}
          labelText={I18n.t(`clients.${clientTabs.CONFIGURE}.localForwarding.labels.domain`)}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsDomainInput}
          labelProps={{shrink: true}}
        />
        <CustomLabel
          id={uuid()}
          labelText={(
            <span>
              {I18n.t(`clients.${clientTabs.CONFIGURE}.localForwarding.labels.resolvers`)}
              <RenderOrEmpty
                isShow={isEditMode
                  && editableLocalForwardingInfo.resolverIps.length < MAX_LOCAL_DNS_IPS
                  && !editableLocalForwardingInfo.useDeviceResolvers}
              >
                <ButtonNew
                  customClasses="cardActions__button"
                  onClick={this.addResolverIp}
                />
              </RenderOrEmpty>
            </span>
          )}
          labelProps={propsIpInputLabel}
          formControlProps={propsFormControl}
          error={false}
        >
          <div className={classes.form__switch}>
            <Switch
              name="useDeviceResolvers"
              // eslint-disable-next-line max-len
              label={I18n.t(`clients.${clientTabs.CONFIGURE}.localForwarding.labels.useDeviceResolvers`)}
              disabled={!isEditMode}
              isChecked={editableLocalForwardingInfo.useDeviceResolvers}
              onChange={this.handleChangeSwitch}
              labelFormClassName="formControl_oneLineReversedLeftAligned"
              labelClassName="switch__label_oneLine"
            />
            <RenderOrEmpty isShow={!editableLocalForwardingInfo.useDeviceResolvers} >
              <InputList
                items={editableLocalForwardingInfo.resolverIps}
                isEditMode={isEditMode}
                classes={ipInputListClasses}
                validation={validation.resolverIps}
                getItemErrorMessage={this.getIpErrorMessage}
                onRemoveItem={this.removeResolverIp}
                onChangeItem={this.handleChangeResolverIpInput}
              />
            </RenderOrEmpty>
          </div>
        </CustomLabel>
      </div>
    );
  }
}

ClientLocalForwardingEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  domain: PropTypes.object.isRequired,
  editableLocalForwardingInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,

  updateLocalForwardingInfo: PropTypes.func.isRequired,
  setValidationResultLocalForwardingInfo: PropTypes.func.isRequired,
  resetLocalForwardingEditFormData: PropTypes.func.isRequired,
  setFormValidatorRef: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isEditMode: state.clientProfileLocalForwardingFormReducer.isEditMode,
  localForwardingState: state.clientProfileLocalForwardingFormReducer.localForwardingState,
  domain: state.clientProfileLocalForwardingFormReducer.selectedLocalDomain,

  // eslint-disable-next-line max-len
  editableLocalForwardingInfo: state.clientLocalForwardingEditFormReducer.editableLocalForwardingInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateLocalForwardingInfo: bindActionCreators(updateLocalForwardingInfo, dispatch),
  // eslint-disable-next-line max-len
  setValidationResultLocalForwardingInfo: bindActionCreators(setValidationResultLocalForwardingInfo, dispatch),
  resetLocalForwardingEditFormData: bindActionCreators(resetLocalForwardingEditFormData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(style)(ClientLocalForwardingEditForm));

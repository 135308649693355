/* eslint-disable max-len */
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';

const style = {
  loginFormSSO__errorMessage: {
    ...customInputStyle.labelRoot,
    ...customInputStyle.labelRootError,
    fontSize: '12px',
    textAlign: 'center',
  },
  loginFormSSO__ssoContainer: {
    marginTop: '50px',
  },
  unauthorizedForm__microsoftSSOIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
  },
};

export default style;

import _cloneDeep from 'lodash/cloneDeep';

import initState from '../../reducers/redux-initial-state';
import {types} from './action';

export default (state = initState.campusRelaysTabReducer, action) => {
  switch (action.type) {
    case types.SET_RELAYS: {
      return {
        ...state,
        relays: action.relays,
      };
    }
    case types.UPDATE_RELAY_DATA: {
      const relays = _cloneDeep(state.relays);
      const relayIndex = relays.findIndex((item) => item.id === action.data.id);

      if (relayIndex !== -1) {
        relays[relayIndex] = action.data;
      }
      return {
        ...state,
        relays,
      };
    }
    case types.DELETE_RELAY: {
      const relays = _cloneDeep(state.relays);
      const relayIndex = relays.findIndex((item) => item.id === action.data.id);

      if (relayIndex !== -1) {
        relays.splice(relayIndex, 1);
      }

      return {
        ...state,
        relays,
      };
    }
    case types.SET_SELECTED_RELAY_INDEX: {
      return {
        ...state,
        selectedRelayIndex: action.selectedRelayIndex,
      };
    }
    case types.RESET_SELECTED_RELAY_INDEX: {
      return {
        ...state,
        selectedRelayIndex: initState.campusRelaysTabReducer.selectedRelayIndex,
      };
    }
    case types.CLEAR_RELAYS: {
      return {...initState.campusRelaysTabReducer};
    }
    case types.SET_SELECTED_RELAY_TABLE_ROW_DATA: {
      return {
        ...state,
        selectedRelayTableRowData: action.selectedRelayTableRowData,
      };
    }
    case types.RESET_SELECTED_RELAY_TABLE_ROW_DATA: {
      return {
        ...state,
        selectedRelayTableRowData: initState.campusRelaysTabReducer.selectedRelayTableRowData,
      };
    }
    case types.CHANGE_EDIT_MODE_AND_RELAY_STATE: {
      return {
        ...state,
        editMode: action.editMode,
        relayState: action.relayState,
      };
    }
    case types.SET_NETWORK_AGENT_ALLOWED_STATUS: {
      return {
        ...state,
        networkAgentAllowed: action.networkAgentAllowed,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import {EntityCreatingCard} from '../index';

import {SingleSignOnEditForm} from '../../containers/index';

function SingleSignOnCreating(props) {
  const {
    handleClickButtonSave,
    isValidSingleSignOnConfiguration,
    title,
  } = props;

  return (
    <EntityCreatingCard
      title={title}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidSingleSignOnConfiguration}
      content={
        <SingleSignOnEditForm isEditForm={false} />
      }
    />
  );
}

SingleSignOnCreating.propTypes = {
  handleClickButtonSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isValidSingleSignOnConfiguration: PropTypes.bool.isRequired,
};

export default SingleSignOnCreating;

/* eslint-disable max-len */
import customSelectStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/customSelectStyle';
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';

import {
  secondaryColor,
} from '../../styles/style';

const style = {
  popperClose: {
    pointerEvents: 'none',
    display: 'none',
  },
  dropdown__formControl: {
    ...customSelectStyle.selectFormControl,
    paddingBottom: '10px',
  },
  dropdown__formControl_clientEditForm: {
    padding: '0',
  },
  dropdown__menu: {
    ...customSelectStyle.selectMenu,
  },
  dropdown__select: {
    ...customInputStyle.input,
    display: 'flex',
    alignItems: 'center',
  },
  dropdownSelect__renderValue: {
    width: 'calc(100% - 25px)',
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropdown__option: {
    'fontSize': '13px',
    'padding': '10px 20px',
    'margin': '0 5px',
    'borderRadius': '2px',
    'transition': 'all 150ms linear',
    'display': 'block',
    'clear': 'both',
    'fontWeight': '400',
    'lineHeight': '2',
    'whiteSpace': 'nowrap',
    'color': '#333',
    '&:hover': {
      backgroundColor: secondaryColor,
      color: '#FFFFFF',
    },
  },
  dropdown__option_selected: {
    backgroundColor: `${secondaryColor}!important`,
    color: '#FFFFFF',
  },
  dropdown__label: {
    ...customInputStyle.labelRoot,
  },
  dropdown__select_campusDashboard: {
    minWidth: '150px',
  },
  dropdown__select_clientEditForm: {
    minWidth: '150px',
    height: '20px',
    padding: '0',
  },
  dropdown__select_clientProfile: {
    minWidth: '150px',
  },
  dropdown__select_userGroup: {
    minWidth: '150px',
  },
  dropdown__select_userPersona: {
    minWidth: '150px',
  },
  dropdown__formControl_clientProfile: {
    marginRight: '30px',
  },
  dropdown__formControl_userGroup: {
    marginRight: '30px',
  },
  dropdown__select_clientStatus: {
    minWidth: '150px',
  },
  dropdown__select_userStatus: {
    minWidth: '150px',
  },
  dropdown__select_dashboard: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: 'calc(100vw - 50px)',
    },
  },
  clientsTable__dropdownSelect: {
    minWidth: '178px',
  },
  dropdown__select_insights: {
    minWidth: '150px',
  },
  dropdown__select_loggingPanel: {
    padding: '4px 0 0',
  },
  dropdown__select_policySettingsTab: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  dropdown__operatorOrganizations: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  dropdown__select_policyTldTab: {
    minWidth: '150px',
  },
  dropdown__select_accountSwitcher: {
    'minWidth': '150px',
    'margin': '0 10px',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  dropdown__select_filter: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: 'calc(100vw - 50px)',
    },
  },
  dropdown__formControl_filter: {
    'minWidth': '150px',
    '@media (max-width: 500px)': {
      minWidth: 'calc(100vw - 50px)',
    },
  },
  dropdown__select_invertColor: {
    'color': '#fff',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  dropdown__icon_invertColor: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  clientsTable__dropdown: {
    '& > div': {
      paddingBottom: 0,
    },
    'paddingBottom': 0,
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';
import {ClientLocalForwardingEditForm} from '../../containers';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_EDIT],
  buttonDelete: [ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_DELETE],
};

class ClientLocalForwardingDetails extends React.Component {
  render() {
    const {
      localForwarding,
      handleClickButtonBack,
      handleClickButtonDelete,
      handleClickButtonEdit,
      handleClickButtonCancelEdit,
      handleClickButtonSave,
      setFormValidatorRef,
      isValidLocalForwardingInfo,
      isEditMode,
      isProfileEditMode,
    } = this.props;

    return (
      <EntityEditingCard
        title={localForwarding.domain}
        entityType={I18n.t('entitiesTypes.clientProfileLocalForwarding')}
        isThereDeleteBlock={true}
        isTherePathBack={true}
        isThereEditBlock={!isProfileEditMode}
        isEditMode={isEditMode || isProfileEditMode}
        isValidEntity={isValidLocalForwardingInfo}
        handleClickButtonBack={handleClickButtonBack}
        handleClickButtonDelete={handleClickButtonDelete}
        handleClickButtonEdit={handleClickButtonEdit}
        handleClickButtonCancel={handleClickButtonCancelEdit}
        handleClickButtonSave={handleClickButtonSave}
        accessRights={rightAvailabilityMap}
        content={(
          <ClientLocalForwardingEditForm
            setFormValidatorRef={setFormValidatorRef}
          />
        )}
      />
    );
  }
}

ClientLocalForwardingDetails.propTypes = {
  localForwarding: PropTypes.object.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  setFormValidatorRef: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isProfileEditMode: PropTypes.bool.isRequired,
  isValidLocalForwardingInfo: PropTypes.bool.isRequired,
};

export default ClientLocalForwardingDetails;

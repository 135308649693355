import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import {CardWithHeader, FieldsList, RenderOrEmpty} from '../../components';
import {Invoices} from '../../containers';

import {LINKS} from '../../constants';
import {getSubscriptionDetails} from './action';

import style from './style';

class Subscription extends React.Component {
  componentDidMount() {
    this.getDetails();
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props.accountInfo, prevProps.accountInfo)) {
      this.getDetails();
    }
  }

  get isSubscriber() {
    const subscriptionId = _get(this.props.accountInfo, 'subscriptionIDs[0]', null);
    return subscriptionId && !_isEmpty(subscriptionId);
  }

  get isPaymentDetailsEnabled() {
    const disablePaymentDetails = _get(this.props.accountInfo, 'disablePaymentDetails', false);
    return !disablePaymentDetails;
  }

  get subscriptionInfo() {
    const {accountInfo} = this.props;
    return [
      {
        label: I18n.t('subscriptionPage.accountStatus'),
        value: accountInfo.status,
      },
      {
        label: I18n.t('subscriptionPage.accountType'),
        value: accountInfo.type,
      },
      {
        label: I18n.t('subscriptionPage.maxCampuses'),
        value: accountInfo.maxLocations,
      },
      {
        label: I18n.t('subscriptionPage.maxNetworks'),
        value: accountInfo.maxNetworks,
      },
    ];
  }

  get billingInfo() {
    const {subscriptionDetails} = this.props;
    return [
      {
        label: I18n.t('billingDetails.fields.subscriptionID'),
        value: _get(subscriptionDetails, 'subscription_id', '—'),
      },
      {
        label: I18n.t('billingDetails.fields.subscriptionName'),
        value: _get(subscriptionDetails, 'name', '—'),
      },
      {
        label: I18n.t('billingDetails.fields.customerID'),
        value: _get(subscriptionDetails, 'customer.customer_id', '—'),
      },
      {
        label: I18n.t('billingDetails.fields.customerName'),
        value: this.customerName,
      },
      {
        label: I18n.t('billingDetails.fields.lastBillDate'),
        value: _get(subscriptionDetails, 'last_billing_at', '—'),
      },
      {
        label: I18n.t('billingDetails.fields.nextBillDate'),
        value: _get(subscriptionDetails, 'next_billing_at', '—'),
      },
    ];
  }

  get customerName() {
    const {subscriptionDetails} = this.props;
    const customerName = [
      _get(subscriptionDetails, 'customer.salutation'),
      _get(subscriptionDetails, 'customer.first_name'),
      _get(subscriptionDetails, 'customer.last_name'),
    ].join(' ').trim();

    return _isEmpty(customerName) ? '—' : customerName;
  }

  getDetails = () => {
    if (this.isSubscriber) {
      this.props.getSubscriptionDetails();
    }
  };

  render() {
    const {classes} = this.props;
    return (
      <>
        <CardWithHeader
          cardTitle={I18n.t('subscriptionPage.title')}
          cardTitleColor="primary"
          content={(
            <>
              <div className={classes.subscription}>
                <FieldsList fields={this.subscriptionInfo} />
              </div>
              <RenderOrEmpty isShow={!!this.isSubscriber}>
                <h4>{I18n.t('billingDetails.zohoDetails')}</h4>
                <div className={classes.subscription}>
                  <FieldsList fields={this.billingInfo} />
                </div>
              </RenderOrEmpty>
              <p className={classes.subscription__contact}>
                {I18n.t('subscriptionPage.subscriptionContact.text')}
                <a
                  className={classes.link}
                  href={LINKS.CONTACT_SALES}
                  target="_blank"
                  rel="noreferrer"
                >
                  {I18n.t('subscriptionPage.subscriptionContact.link')}
                </a>
              </p>
            </>
          )}
        />
        <RenderOrEmpty isShow={!!this.isSubscriber && !!this.isPaymentDetailsEnabled}>
          <Invoices />
        </RenderOrEmpty>
      </>
    );
  }
}

Subscription.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  subscriptionDetails: PropTypes.object.isRequired,
  getSubscriptionDetails: PropTypes.func.isRequired,
};

Subscription.defaultProps = {};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  subscriptionDetails: state.subscriptionReducer.subscriptionDetails,
});

const mapDispatchToProps = {getSubscriptionDetails};

export default connect(mapStateToProps, mapDispatchToProps)((withStyles)(style)(Subscription));

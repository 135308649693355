const style = {
  container: {
    display: 'flex',
  },
  list: {
    width: '100%',
  },
  list_compressed: {
    'width': '25%',
    '@media (min-width: 2048px)': {
      width: '15%',
    },
  },
  form: {
    'width': '75%',
    '@media (min-width: 2048px)': {
      width: '85%',
    },
  },
};

export default style;

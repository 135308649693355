import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
  Tags,
} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.CUSTOM_LIST_CREATE],
};

class WhitelistTable extends Component {
  constructor(props) {
    super(props);

    this.tagsCellRenderer = (row) => (
      <Tags tags={row.value} />
    );

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];
    this.headersFullWidthMode.push(
      {
        Header: I18n.t('whitelistPage.listName'),
        accessor: 'name',
        ...this.getFilter(),
        sortable: false,
        maxWidth: 300,
      },
      {
        Header: I18n.t('whitelistPage.tags'),
        accessor: 'tags',
        Cell: this.tagsCellRenderer,
        sortable: false,
        filterable: false,
      },
    );
    this.headersCompressedMode.push({
      Header: null,
      accessor: 'name',
      ...this.getFilter(),
      sortable: false,
    });
  }

  getFilter = () => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t('whitelistPage.listNameFilterPlaceholder'),
      ['name'],
    )
  );

  render() {
    const {
      handleClickButtonNew,
      handleClickCampusRow,
      isEditMode,
      mode,
      tableData,
      selectedWhiteBlackListIndex,
    } = this.props;
    const tableStyle = {
      height: 'calc(100vh - 151px)',
      paddingBottom: '30px',
    };
    return (
      <EntitiesListWithViewModesCard
        fullscreen={false}
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('whitelistPage.listsTableTitle')}
        entityType={I18n.t('entitiesTypes.wbList')}
        selectedEntityIndex={selectedWhiteBlackListIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickCampusRow}
        tableStyles={tableStyle}
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

WhitelistTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickCampusRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedWhiteBlackListIndex: PropTypes.number.isRequired,
};

WhitelistTable.defaultProps = {
  tableData: [],
};

export default WhitelistTable;

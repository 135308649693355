import React, {useEffect} from 'react';
import {I18n} from 'react-redux-i18n';
import {Card, CardContent, CardHeader, WithStyles, withStyles} from '@material-ui/core';

import {useDispatch, useSelector} from 'react-redux';
import {LoadingLayout} from '../../layouts-common';
import {TableWithPaginationAndFiltration} from '../../components';
import {PERIODS} from '../../constants';
import {loadUserSessions} from './action';

import style from './style';

type UserSessionsProps = WithStyles<typeof style> & {
  userId: string;
  widgetKey: string;
  customClasses: {
    tableBody: string;
  };
}

function UserSessions({classes, userId, widgetKey, customClasses}: UserSessionsProps) {
  const dispatch = useDispatch();

  // Using any as a type for store because function createStore doesn't support types
  // and gives an error. Maybe we should use redux toolkit in the future for creating store
  const sessions = useSelector((store: any) => store.userSessionsReducer.sessions);

  const columns = [
    {
      Header: I18n.t('users.usersManager.editUserForm.sessions.device'),
      accessor: 'hostname',
      width: 180,
      filterable: false,
      sortable: true,
    },
    {
      Header: I18n.t('users.usersManager.editUserForm.sessions.wan'),
      accessor: 'networkIp',
      width: 180,
      filterable: false,
      sortable: true,
    },
    {
      Header: I18n.t('users.usersManager.editUserForm.sessions.lan'),
      accessor: 'subnetIp',
      width: 180,
      filterable: false,
      sortable: true,
    },
    {
      Header: I18n.t('users.usersManager.editUserForm.sessions.site'),
      accessor: 'locationName',
      width: 180,
      filterable: false,
      sortable: true,
    },
    {
      Header: I18n.t('users.usersManager.editUserForm.sessions.sessionStart'),
      accessor: 'sessionStartAt',
      width: 180,
      filterable: false,
      sortable: true,
    },
  ];

  const loadSessions = () => {
    if (userId) {
      dispatch(loadUserSessions(userId, PERIODS.LAST_MONTH, widgetKey) as any);
    }
  };

  useEffect(() => {
    loadSessions();
  }, [userId]);

  const cardHeaderStyle = {
    root: classes.userSessionsCard__header,
    title: classes.userSessionsCard__title,
  };

  return (
    <LoadingLayout widgetKey={widgetKey}>
      <Card className={classes.userSessionsCard}>
        <CardHeader
          classes={cardHeaderStyle}
          title={I18n.t('users.usersManager.editUserForm.sessions.title')}
        />
        <CardContent className={classes.userSessionsCard__content}>
          <TableWithPaginationAndFiltration
              columns={columns}
              data={sessions}
              showPagination={false}
              defaultPageSize={sessions.length}
              pageSize={sessions.length}
              customClasses={{
                tbody: customClasses.tableBody,
              }}
          />
        </CardContent>
      </Card>
    </LoadingLayout>
  );
}

export default withStyles(style)(UserSessions);

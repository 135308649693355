import {types} from './constants';
import {METHODS} from '../../constants';
import {get, makeUrl} from '../../utils/fetcher';

export const getLocations = (queryParams) => {
  const url = makeUrl(METHODS.GET_CAMPUSES, queryParams);
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_DASHBOARD_LOCATIONS,
            data,
          });
        }
      });
};

export const getClientProfileNames = (queryParams) => {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILE_NAMES, queryParams);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_DASHBOARD_PROFILE_NAMES,
          data: response.data,
        });
      }
    });
};

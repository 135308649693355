import {
  transition,
} from '../../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';

import {
  headerHeight,
  sidebarWidth,
  sidebarMiniWidth,
  editModeDisabledStyle,
} from '../../../styles/style';

const style = (theme) => ({
  operatorLayout: {
    'position': 'relative',
    'top': '0',
    'height': '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  operatorLayout_disabled: {
    ...editModeDisabledStyle,
  },
  operatorLayout__sidebar: {},
  operatorLayout__header: {},
  operatorLayout__container: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${sidebarWidth})`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  container__sidebar_mini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${sidebarMiniWidth})`,
    },
  },
  container__perfectScrollbar: {
    overflow: 'hidden !important',
  },
  operatorLayout__content: {
    'marginTop': headerHeight,
    'padding': '0',
    'height': `calc(100vh - ${headerHeight})`,
    'overflow': 'auto!important',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
});

export default style;

import cardsStyles from '../../styles/components/cards';

const style = {
  filtersPanel: {
    ...cardsStyles.card,
    boxSizing: 'border-box',
    padding: '15px 20px',
  },
  filtersPanel__container: {
    'display': 'flex',
    'justifyContent': 'flex-start',
    'alignItems': 'baseline',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  filtersPanel__item: {
    '@media (min-width: 600px)': {
      flexBasis: '30%',
    },
    '@media (min-width: 800px)': {
      flexBasis: '25%',
    },
    '@media (min-width: 1200px)': {
      flexBasis: '20%',
    },
    '@media (min-width: 1400px)': {
      flexBasis: '15%',
    },
    '@media (min-width: 1600px)': {
      flexBasis: '10%',
    },
  },
};

export default style;

import {defaultFont, primaryColor} from '../../styles/style';

const style = {
  nestedList__menu: {
    padding: 0,
    maxHeight: '60vh',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
  },
  nestedList__paper: {
    overflowY: 'auto',
  },
  nestedList__menuItem: {
    ...defaultFont,
    'fontWeight': '400',
    'whiteSpace': 'nowrap',
    'fontSize': '14px',
    'padding': '10px 20px',
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
    },
  },
  nestedList__menuItem_parent: {
    padding: '10px 5px 10px 20px',
  },
  nestedList__menuItem_active: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
  },
};

export default style;

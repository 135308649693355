const style = {
  usersControls: {},
  usersControls__row: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'padding': '3px 20px',

    '@media only screen and (max-width: 640px)': {
      padding: '10px 20px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  usersControls__userSearchInputForm: {
    paddingTop: '1px',
    width: '400px',
  },
  usersControls__dropDownOptions: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {RelayEditForm} from '../../containers/index';

function RelayDetails(props) {
  const {
    handleClickButtonBackInRelayList,
    handleClickButtonCancelEditRelay,
    handleClickButtonDeleteRelay,
    handleClickButtonEditRelay,
    handleClickButtonSaveRelay,
    editMode,
  } = props;

  return (
    <EntityEditingCard
      entityType={I18n.t('entitiesTypes.relay')}
      isEditMode={editMode}
      isTherePathBack={true}
      isThereDeleteBlock={false}
      isThereEditBlock={false}
      isValidEntity={true}
      handleClickButtonBack={handleClickButtonBackInRelayList}
      handleClickButtonCancel={handleClickButtonCancelEditRelay}
      handleClickButtonDelete={handleClickButtonDeleteRelay}
      handleClickButtonEdit={handleClickButtonEditRelay}
      handleClickButtonSave={handleClickButtonSaveRelay}
      customCardClass="card_relays"
      customCardContentClass="card__content_relays"
      content={(
        <RelayEditForm
          closeForm={handleClickButtonBackInRelayList}
          deleteRelay={handleClickButtonDeleteRelay}
        />
      )}
    />
  );
}

RelayDetails.propTypes = {
  handleClickButtonBackInRelayList: PropTypes.func.isRequired,
  handleClickButtonCancelEditRelay: PropTypes.func.isRequired,
  handleClickButtonDeleteRelay: PropTypes.func.isRequired,
  handleClickButtonEditRelay: PropTypes.func.isRequired,
  handleClickButtonSaveRelay: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default RelayDetails;

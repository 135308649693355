import {
  primaryColor,
  secondaryColor,
  defaultFont,
  editModeDisabledStyle,
} from '../../styles/style';

const style = {
  panel: {
    marginTop: '8px',
    marginBottom: '9px',
    backgroundColor: primaryColor,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    ...defaultFont,
    fontSize: '12px',
  },
  panel_disabled: {
    ...editModeDisabledStyle,
  },
  panel__tab: {
    'borderRadius': '5px',
    'color': '#FFF',
    'padding': '10px',
    'cursor': 'pointer',
    'width': '135px',
    'textAlign': 'center',
    'textTransform': 'uppercase',
    '&:hover': {
      background: '#4e4e53',
    },
  },
  panel__tab_active: {
    'background': secondaryColor,
    '&:hover': {
      background: secondaryColor,
    },
  },
};

export default style;

import {types} from './constants';

export function updateEditOrganizationSites(sitesInOrganization, sitesNotInOrganization) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDIT_FROM_ORGANIZATION_SITES,
      sitesInOrganization,
      sitesNotInOrganization,
    });
  };
}

export function changeSitesEditMode(isEditMode) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_ORGANIZATION_SITES_EDIT_MODE,
      isEditMode,
    });
  };
}

import _isNil from 'lodash/isNil';

export const createLoadingWidgetReducers = (widgetKeys) => {
  const reducers = {};
  Object.values(widgetKeys).forEach((value) => {
    reducers[value] = {
      loadingMap: {},
      isLoading: false,
    };
  });
  return reducers;
};

export const convertUserOrganization = (organization) => {
  if (_isNil(organization)) {return null;}
  return ({
    id: organization.organizationId,
    name: organization.organizationName,
    permission: organization.permission,
  });
};

export const convertUserOrganizations = (organizations) =>
  organizations.map((org) => convertUserOrganization(org));

import React from 'react';
import PropTypes from 'prop-types';

function Organizations(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
           version="1.1"
           aria-hidden="true"
           data-prefix="fas"
           data-icon="clients"
           className={props.className}
           role="img"
    >
      <g id="surface1">
        <path style={{fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: '1', strokeWidth: '1', strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(100%,100%,100%)', strokeOpacity: '1', strokeMiterlimit: '4'}} d="M 148.518319 44.987558 L 235.486261 44.987558 L 239.024784 48.515046 L 240.021552 50.496238 L 240.021552 134.479456 L 235.486261 139.988137 L 148.518319 139.988137 L 144.979795 136.50897 L 143.983028 134.479456 L 143.983028 50.496238 Z M 148.518319 44.987558 " transform="matrix(0.0783784,0,0,0.0808383,0,0)" />
        <path style={{fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: '1', strokeWidth: '1', strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(100%,100%,100%)', strokeOpacity: '1', strokeMiterlimit: '4'}} d="M 184.003233 155.016204 L 200.001347 155.016204 L 200.001347 174.489873 L 200.499731 175.021412 L 284.477371 175.021412 L 290.009429 179.515336 L 290.009429 211.021123 L 275.008082 211.021123 L 275.008082 191.499132 L 274.509698 191.015914 L 200.001347 191.015914 L 200.001347 211.021123 L 184.003233 211.021123 L 184.003233 191.499132 L 183.504849 191.015914 L 108.996498 191.015914 L 108.996498 211.021123 L 93.995151 211.021123 L 93.995151 179.515336 L 97.483836 175.987847 L 99.477371 175.021412 L 184.003233 175.021412 Z M 184.003233 155.016204 " transform="matrix(0.0783784,0,0,0.0808383,0,0)" />
        <path style={{fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: '1', strokeWidth: '1', strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(100%,100%,100%)', strokeOpacity: '1', strokeMiterlimit: '4'}} d="M 68.477909 226.000868 L 134.513739 226.000868 L 138.002425 228.5136 L 138.999192 230.494792 L 138.999192 296.502315 L 136.507274 299.981481 L 134.513739 300.996238 L 68.477909 300.996238 L 64.989224 298.483507 L 63.992457 296.502315 L 63.992457 230.494792 L 66.484375 227.015625 Z M 68.477909 226.000868 " transform="matrix(0.0783784,0,0,0.0808383,0,0)" />
        <path style={{fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: '1', strokeWidth: '1', strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(100%,100%,100%)', strokeOpacity: '1', strokeMiterlimit: '4'}} d="M 158.485991 226.000868 L 225.518588 226.000868 L 229.007274 229.480035 L 230.004041 231.509549 L 230.004041 295.487558 L 225.518588 300.996238 L 158.485991 300.996238 L 154.997306 297.517072 L 154.000539 295.487558 L 154.000539 231.509549 Z M 158.485991 226.000868 " transform="matrix(0.0783784,0,0,0.0808383,0,0)" />
        <path style={{fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: '1', strokeWidth: '1', strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(100%,100%,100%)', strokeOpacity: '1', strokeMiterlimit: '4'}} d="M 249.490841 226.000868 L 315.476832 226.000868 L 319.015356 228.5136 L 320.012123 230.494792 L 320.012123 296.502315 L 317.520205 299.981481 L 315.476832 300.996238 L 249.490841 300.996238 L 246.002155 298.483507 L 245.005388 296.502315 L 245.005388 230.494792 L 247.497306 227.015625 Z M 249.490841 226.000868 " transform="matrix(0.0783784,0,0,0.0808383,0,0)" />
      </g>
    </svg>
  );
}

Organizations.propTypes = {
  className: PropTypes.string,
};

Organizations.defaultProps = {
  className: '',
};

export default Organizations;

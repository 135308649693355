import {getUserAccounts} from '../../actions/userAccounts/getUserAccounts';
import {switchAccount} from '../../actions/userAccounts/switchAccount';

export function refreshAccountsInfo(authData) {
  return (dispatch) => dispatch(getUserAccounts())
    .then((accounts) => {
      if (accounts.length > 0) {
        dispatch(switchAccount(accounts[0], authData));
      }
    });
}

import {types} from './constants';

export function getEditableProfileInfo(profile) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_CLIENT_PROFILE_INFO,
      profile,
      isValidProfileInfo: true,
    });
  };
}

export function resetProfileEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CLIENT_PROFILE_EDIT_FORM_DATA,
    });
  };
}

export function updateProfileInfo(updatedProfileInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CLIENT_PROFILE_INFO,
      profile: updatedProfileInfo,
      isValidProfileInfo: isValid,
    });
  };
}

export function setProfileFromTemplate(template) {
  return (dispatch) => {
    dispatch({
      type: types.SET_PROFILE_FROM_TEMPLATE,
      template,
    });
  };
}

export function setValidationResultProfileInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_CLIENT_PROFILE_INFO,
      isValid,
    });
  };
}

export function setSelectedProfileTemplateIndex(profileIndex) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CLIENT_PROFILE_TEMPLATE_INDEX,
      profileIndex,
    });
  };
}

export function resetSelectedProfileTemplate() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_CLIENT_PROFILE_TEMPLATE,
    });
  };
}

import {get, makeUrl} from '../utils/fetcher';

const METHODS = {
  GET_ROOT_GROUPS: 'getRootGroups',
  GET_GROUPS_TREE: 'getGroupsTree',
  GET_SUBGROUPS_OF_GROUP: 'getSubGroupsOfGroup',
};

export function getRootGroups() {
  const url = makeUrl(METHODS.GET_ROOT_GROUPS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    });
}

export function getGroupsTree() {
  const url = makeUrl(METHODS.GET_GROUPS_TREE);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    });
}

export function getSubGroupsOfGroup(groupName) {
  const url = makeUrl(METHODS.GET_SUBGROUPS_OF_GROUP, {name: groupName});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    });
}

import _includes from 'lodash/includes';
import _get from 'lodash/get';

import {ROLES_AVAILABILITY, USER_ROLES} from '../constants';

import LS from './localStorage';

const LOCAL_STORAGE_KEY = 'scout_dns_logged_account';

export function hasAccount() {
  return _includes(Object.values(USER_ROLES), getLoggedAccount().role);
}

export function getLoggedAccount() {
  const loggedAccount = LS.getItem(LOCAL_STORAGE_KEY);
  return loggedAccount !== null ? JSON.parse(loggedAccount) : {};
}

export function setLoggedAccount(accountId, role, email, organizationId) {
  LS.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({accountId, role, email, organizationId}),
  );
}

export function removeLoggedAccount() {
  LS.removeItem(LOCAL_STORAGE_KEY);
}

export function hasAuthorities(componentName) {
  const role = getLoggedAccount().role;
  return _get(ROLES_AVAILABILITY, [componentName, role], true);
}

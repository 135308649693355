import _sortBy from 'lodash/sortBy';

import {types} from './constants';
import {METHODS} from '../../constants';
import {widgetKeys} from '../../app-common/Organizations/constants';
import {get, makeUrl} from '../../utils/fetcher';
import {convertThreatNames} from '../../utils/dashboard';

export function setEditableOrganizationInfo(organization) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_ORGANIZATION_INFO,
      organizationInfo: organization,
      isValidOrganizationInfo: true,
    });
  };
}

export function resetOrganizationEditFormData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_ORGANIZATION_EDIT_FORM_DATA,
    });
  };
}

export function updateOrganizationInfo(updatedOrganizationInfo, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ORGANIZATION_INFO,
      updatedOrganizationInfo,
      isValidOrganizationInfo: isValid,
    });
  };
}

export function setValidationResultOrganizationInfo(isValid) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VALIDATION_ORGANIZATION_INFO,
      isValid,
    });
  };
}

export function setSelectedPeriodIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ORGANIZATION_SELECTED_PERIOD_INDEX,
      index,
    });
  };
}

export function getOrganizationRequestsGraph(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_USER_REQUESTS_GRAPH, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_REQUESTS_GRAPH,
            period: queryParams.period,
            data,
          });
        } else {
          dispatch({
            type: types.SET_ORGANIZATION_REQUESTS_GRAPH,
            period: queryParams.period,
            data: {},
          });
        }
      });
}

export function getOrganizationsAverageLatency(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_AVERAGE_LATENCY, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ORGANIZATION_AVERAGE_LATENCY,
            data,
          });
        } else {
          dispatch({
            type: types.SET_ORGANIZATION_AVERAGE_LATENCY,
            data: 0.0,
          });
        }
      });
}

export function getOrganizationThreats(queryParams, widgetKey) {
  const url = makeUrl(METHODS.GET_GROUP_THREATS, queryParams);
  return (dispatch) =>
    get(url, dispatch, true, widgetKey)
      .then((response) => {
        const data = _sortBy(response.data, (item) => item.threat);
        if (response && response.data) {
          dispatch({
            type: types.SET_ORGANIZATION_THREATS,
            data: convertThreatNames(data),
          });
        } else {
          dispatch({
            type: types.SET_ORGANIZATION_THREATS,
            data: [],
          });
        }
      });
}

export function getDashboardData(params) {
  const period = params.period;
  const organizationId = params.organizationId;
  const queryParamsPeriodOrganization = {organizationId, period};

  return (dispatch) => {
    dispatch(getOrganizationsAverageLatency(
      queryParamsPeriodOrganization,
      widgetKeys.PERFORMANCE_STATS),
    );
    dispatch(getOrganizationThreats(queryParamsPeriodOrganization, widgetKeys.THREAT_STATS));
    dispatch(getOrganizationRequestsGraph(queryParamsPeriodOrganization, widgetKeys.REQUEST_STATS));
  };
}

const style = {
  insights: {
    backgroundColor: '#fff',
    minHeight: '100%',
  },
  title: {
    fontSize: '17px',
    padding: '10px 20px',
  },
};

export default style;

import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';

import {CustomButton} from '../../material-dashboard-pro-react/components/index';

import {CATEGORY_STATUSES} from '../../constants';

import style from './style';

function ButtonAllowBlockCategory(props) {
  const statusMap = {
    [CATEGORY_STATUSES.ALLOWED]: CATEGORY_STATUSES.FORBIDDEN,
    [CATEGORY_STATUSES.FORBIDDEN]: CATEGORY_STATUSES.ALLOWED,
  };

  const handleClick = () => {
    props.onClick({
      name: props.categoryName,
      status: statusMap[props.categoryStatus],
    });
  };

  const {
    categoryName,
    categoryStatus,
    classes,
    customClasses,
    disabled,
  } = props;

  const colorsMap = {
    [CATEGORY_STATUSES.ALLOWED]: 'gray',
    [CATEGORY_STATUSES.FORBIDDEN]: 'secondary',
  };

  return (
    <CustomButton
      color={colorsMap[categoryStatus]}
      customClasses={customClasses}
      onClick={handleClick}
      disabled={disabled}
    >
      <p className={classes.text}>
        {categoryName.toUpperCase()}
      </p>
    </CustomButton>
  );
}

ButtonAllowBlockCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryStatus: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ButtonAllowBlockCategory.defaultProps = {
  customClasses: '',
};

export default withStyles(style)(ButtonAllowBlockCategory);

import _get from 'lodash/get';

import {TABS} from '../../app-common/Insights/constants';

const filterDataHandler = {
  [TABS.DOMAIN]: (record) => ({
    domainName: _get(record, 'domain', null),
    parentDomain: _get(record, 'parentDomain', null),
  }),
  [TABS.CATEGORY]: (record) => ({
    category: getFilterAsArray(record, 'category', null),
    domainName: _get(record, 'domain', null),
    parentDomain: _get(record, 'parentDomain', null),
  }),
  [TABS.TLD]: (record) => ({
    tldName: getFilterAsArray(record, 'name', null),
    domainName: _get(record, 'domain', null),
    parentDomain: _get(record, 'parentDomain', null),
  }),
  [TABS.RECORD_TYPE]: (record) => ({
    recordType: getFilterAsArray(record, 'recordTypeNumber'),
    domainName: _get(record, 'domain', null),
    parentDomain: _get(record, 'parentDomain', null),
  }),
};

export function getFilterDataByTab(tab, record) {
  return filterDataHandler[tab](record);
}

function getFilterAsArray(record, key) {
  const value = _get(record, key, null);
  if (value === null) return [];
  return [value];
}

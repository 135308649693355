import {TABS as ClientTabs} from '../../app-common/Clients/constants';
import {ACCESS_RIGHTS} from '../../constants';

const getActions = (client, handlers, t) => ([
  {
    name: 'disable',
    handler: handlers.handleDisable,
    visible: !client.uninstall && client.enabled,
    buttonProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.buttons.disable`),
      color: 'primaryNoBackground',
    },
    menuItemProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.actionMenu.disable`),
    },
    accessRights: [ACCESS_RIGHTS.CLIENT_DISABLE_OR_ENABLE],
  },
  {
    name: 'enable',
    handler: handlers.handleEnable,
    visible: !client.uninstall && !client.enabled,
    buttonProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.buttons.enable`),
      color: 'greenNoBackground',
    },
    menuItemProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.actionMenu.enable`),
    },
    accessRights: [ACCESS_RIGHTS.CLIENT_DISABLE_OR_ENABLE],
  },
  {
    name: 'forget',
    handler: handlers.handleForget,
    visible: true,
    buttonProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.buttons.forget`),
      color: 'primaryNoBackground',
    },
    menuItemProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.actionMenu.forget`),
    },
    accessRights: [ACCESS_RIGHTS.CLIENT_FORGET],
  },
  {
    name: 'uninstall',
    handler: handlers.handleUninstall,
    visible: !client.uninstall,
    buttonProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.buttons.uninstall`),
      color: 'redNoBackground',
    },
    menuItemProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.actionMenu.uninstall`),
    },
    accessRights: [ACCESS_RIGHTS.CLIENT_UNINSTALL_OR_CANCEL],
  },
  {
    name: 'cancel-uninstall',
    handler: handlers.handleCancelUninstall,
    visible: client.uninstall,
    buttonProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.buttons.cancel`),
      color: 'greenNoBackground',
    },
    menuItemProps: {
      label: t(`clients.${ClientTabs.MANAGE}.editClientForm.actionMenu.cancel`),
    },
    accessRights: [ACCESS_RIGHTS.CLIENT_UNINSTALL_OR_CANCEL],
  },
]);

export default getActions;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
} from '../index';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.SITE_REDIRECTS_CREATE],
};

class LocalDomainTable extends Component {
  constructor(props) {
    super(props);

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];
    this.headersFullWidthMode.push(
      {
        Header: I18n.t('campusPage.localDomainsTab.labels.network'),
        accessor: 'network',
        ...this.getFilter(
          I18n.t('campusPage.localDomainsTab.filterPlaceholders.network'),
          'network',
        ),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.localDomainsTab.labels.domain'),
        accessor: 'domain',
        ...this.getFilter(
          I18n.t('campusPage.localDomainsTab.filterPlaceholders.domain'),
          'domain',
        ),
        sortable: false,
      },
      {
        Header: I18n.t('campusPage.localDomainsTab.labels.ip'),
        accessor: 'ip',
        ...this.getFilter(
          I18n.t('campusPage.localDomainsTab.filterPlaceholders.ip'),
          'ip',
        ),
        sortable: false,
      },
    );
    this.headersCompressedMode.push(
      {
        Header: null,
        accessor: 'domain',
        ...this.getFilter(I18n.t('campusPage.localDomainsTab.filterPlaceholders.domain'), 'domain'),
        sortable: false,
      },
    );
  }

  getFilter = (filterLabel, fieldName) => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      filterLabel,
      [fieldName],
    )
  );

  render() {
    const {
      handleClickButtonNew,
      handleClickLocalDomainRow,
      isEditMode,
      mode,
      tableData,
      selectedLocalDomainIndex,
    } = this.props;

    const localDomainTableStyles = {
      minHeight: '300px',
      height: 'auto',
      maxHeight: '800px',
    };

    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('campusPage.localDomainsTab.title')}
        entityType={I18n.t('entitiesTypes.localDomain')}
        tableStyles={localDomainTableStyles}
        selectedEntityIndex={selectedLocalDomainIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickLocalDomainRow}
        customCardClass="card_localDomains"
        accessRights={rightAvailabilityMap}
      />
    );
  }
}

LocalDomainTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickLocalDomainRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedLocalDomainIndex: PropTypes.number.isRequired,
};

LocalDomainTable.defaultProps = {
  tableData: [],
};

export default LocalDomainTable;

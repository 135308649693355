import {createStyles} from '@material-ui/core';

const style = createStyles({
  userEditForm: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  userEditForm__infoSection: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'justifyContent': 'space-between',
    '@media(max-width: 1065px)': {
      flexDirection: 'column',
    },
  },
  userEditForm__sessionSection_disabled: {
    display: 'none',
  },
  infoSection__item: {
    'minWidth': '400px',
    'flexBasis': '49.5%',
    '@media(max-width: 1065px)': {
      width: '100%',
      marginBottom: '10px',
    },
  },
  userEditForm__tableBody_sessions: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 750px)',
    },
  },
  userEditForm__tableBody_sessions_noDashboard: {
    '@media(min-height: 480px)': {
      height: 'calc(100vh - 407px)',
    },
  },
  userEditForm__tableBody_info: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 700px)',
    },
  },
  userEditForm__tableCell_group: {
    marginTop: '5px',
    marginBottom: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tableHeader: {
    fontWeight: '400',
  },
  grid: {},
});

export default style;

import _cloneDeep from 'lodash/cloneDeep';
import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.personaEditFormReducer, action) => {
  switch (action.type) {
    case types.RESET_PERSONA_FORM_VALUES: {
      return {
        ...state,
        directoryServices: initState.personaEditFormReducer.directoryServices,
        baseSettingsObject: initState.personaEditFormReducer.baseSettingsObject,
      };
    }
    case types.UPDATE_BASE_SETTING: {
      return {
        ...state,
        baseSettingsObject: action.data,
      };
    }
    case types.BIND_DOMAIN: {
      return {
        ...state,
        directoryServices: {
          ...state.directoryServices,
          domain: action.domain.fqdn,
          domainId: action.domain.id,
        },
      };
    }
    case types.UPDATE_CONFIGURED_GROUPS: {
      return {
        ...state,
        directoryServices: {
          ...state.directoryServices,
          configuredGroups: action.data,
        },
      };
    }
    case types.UPDATE_OBSERVED_GROUPS: {
      return {
        ...state,
        directoryServices: {...state.directoryServices, observedGroups: action.groups},

      };
    }
    case types.SET_DIRECTORY_SERVICES: {
      return {
        ...state,
        directoryServices: _cloneDeep(action.data),
      };
    }

    default:
      return state;
  }
};

import initState from '../../reducers/redux-initial-state';
import {types} from './action';
import {
  getLocalForwardingsTableData,
  getUpdatedLocalForwardingIndex,
} from './service';

export default (state = initState.campusLocalForwardingTabReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_LOCAL_FORWARDING_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        localForwardingState: action.localForwardingState,
      };
    }
    case types.SET_LOCAL_FORWARDINGS: {
      return {
        ...state,
        localForwardings: action.data.localForwardings,
        networkAgentAllowed: action.data.networkAgentAllowed,
        localForwardingsTableData: getLocalForwardingsTableData(action.data.localForwardings),
      };
    }
    case types.SET_LOCAL_FORWARDINGS_TABLE_DATA: {
      return {
        ...state,
        localForwardingsTableData: getLocalForwardingsTableData(state.localForwardings),
      };
    }
    case types.SET_SELECTED_LOCAL_FORWARDING: {
      return {
        ...state,
        selectedLocalForwarding: action.data,
      };
    }
    case types.SET_SELECTED_LOCAL_FORWARDING_INDEX: {
      return {
        ...state,
        selectedLocalForwardingIndex: action.selectedLocalForwardingIndex,
      };
    }
    case types.SET_UPDATED_LOCAL_FORWARDING: {
      return {
        ...state,
        selectedLocalForwardingIndex: getUpdatedLocalForwardingIndex(
          action.data.id,
          state.localForwardings,
        ),
        selectedLocalForwarding: action.data,
      };
    }
    case types.RESET_SELECTED_LOCAL_FORWARDING_INDEX: {
      return {
        ...state,
        selectedLocalForwardingIndex:
          initState.campusLocalForwardingTabReducer.selectedLocalForwardingIndex,
        selectedLocalForwarding: initState.campusLocalForwardingTabReducer.selectedLocalForwarding,
      };
    }
    default:
      return state;
  }
};

const style = {
  card: {
    height: 'calc(100% - 130px)',
  },
  tableBody: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 405px)',
      marginBottom: '10px',
    },
  },
  numberCell: {
    textAlign: 'right',
  },
  tableCell: {
    textAlign: 'right',
  },
};

export default style;

import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';

import {types} from './constants';
import {get, makeUrl, postWithCsvResponse} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {formatMoment} from '../../utils/dateTime';
import {convertUsageBillingTableData} from './utils';
import {widgetKeys} from '../../app-common/Insights/constants';

export const changeInvoiceDate = (invoiceDate) =>
  (dispatch) => dispatch({
    type: types.SET_INVOICE_DATE,
    invoiceDate,
  });

export const setBillingPeriod = (selectedPeriodIndex, period) =>
  (dispatch) => dispatch({
    type: types.SET_BILLING_PERIOD,
    selectedPeriodIndex,
    period,
  });

export const setTableCostsCalculated = (flag) =>
  (dispatch) => dispatch({
    type: types.SET_TABLE_COSTS_CALCULATED,
    tableCostsCalculated: flag,
  });

export const updateTableCost = (name, value) =>
  (dispatch) => dispatch({
    type: types.UPDATE_COST,
    name,
    value,
  });

const makeUsageBillingQueryParams = (period, costs) => {
  const {queryCost, seatCost} = costs;
  const params = {};
  if (!_isNil(period)) {
    if (period.invoiceDate) {
      params.invoiceDate = formatMoment(period.invoiceDate);
    } else {
      params.from = formatMoment(period.from);
      params.till = formatMoment(period.till);
    }
  }
  if (!_isEmpty(queryCost)) {
    params.queryCost = queryCost;
  }
  if (!_isEmpty(seatCost)) {
    params.seatCost = seatCost;
  }
  return params;
};

export const getOrganizationsUsageStats = (period, costs, widgetKey = widgetKeys.USAGE_BILLING) => {
  const params = makeUsageBillingQueryParams(period, costs);
  const url = makeUrl(METHODS.GET_USAGE_BILLING_STATS, params);
  return (dispatch) => get(url, dispatch, widgetKey)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_ORGANIZATIONS_USAGE_STATS,
          usageBilling: convertUsageBillingTableData(response.data.usageBilling),
        });
      }
    });
};

export const exportCSV = (period, costs, widgetKey = widgetKeys.USAGE_BILLING) => {
  const params = makeUsageBillingQueryParams(period, costs);
  const url = makeUrl(METHODS.EXPORT_USAGE_BILLING_STATS);
  return (dispatch) => postWithCsvResponse(url, params, dispatch, true, widgetKey)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to export Usage/Billing to CSV:', error);
    });
};

export const clearOrganizationUsageStats = () =>
  (dispatch) => dispatch({
    type: types.SET_ORGANIZATIONS_USAGE_STATS,
    data: {organizationUsageStats: []},
  });

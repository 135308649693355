import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {
  EntitiesListWithViewModesCard, Tags,
} from '../index';
import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED_WIDTH, ACCESS_RIGHTS,
} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.CUSTOM_LIST_TLD_CREATE],
};

export default function TldListTable(props) {
  const tagsCellRenderer = (row) => (
    <Tags tags={row.value} />
  );

  const headersFullMode = [{
    Header: I18n.t('tldListsPage.table.headers.name'),
    accessor: 'name',
    width: 300,
    sortable: false,
    filterable: false,
  },
  {
    Header: I18n.t('tldListsPage.table.headers.tldTags'),
    accessor: 'tags',
    Cell: tagsCellRenderer,
    sortable: false,
    filterable: false,
    maxWidth: 300,
  },
  {
    Header: I18n.t('tldListsPage.table.headers.description'),
    accessor: 'description',
    sortable: false,
    filterable: false,
  }];

  const headersCompressedMode = [{
    Header: I18n.t('tldListsPage.table.headers.name'),
    accessor: 'name',
    sortable: false,
    filterable: false,
  }];

  const mode = props.isCompressed ? COMPRESSED_WIDTH : FULL_WIDTH;
  const tableStyles = {
    height: 'calc(100vh - 151px)',
    paddingBottom: '30px',
  };
  return (
    <EntitiesListWithViewModesCard
      fullscreen={false}
      handleClickButtonNew={props.handleClickNew}
      mode={mode}
      headersFullWidthMode={headersFullMode}
      headersCompressedMode={headersCompressedMode}
      tableData={props.lists}
      disabled={props.isEditing}
      title={I18n.t('tldListsPage.title')}
      entityType={I18n.t('entitiesTypes.tldFilterList')}
      handleClickRow={props.handleClickRow}
      selectedEntityIndex={props.selectedListIndex}
      tableStyles={tableStyles}
      accessRights={rightAvailabilityMap}
    />
  );
}

TldListTable.propTypes = {
  lists: PropTypes.array.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  isCompressed: PropTypes.bool.isRequired,
  selectedListIndex: PropTypes.number.isRequired,
  handleClickNew: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

TldListTable.defaultProps = {};

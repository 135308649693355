export const types = {
  SET_EDITABLE_CLIENT_BUNDLE_INFO: 'SET_EDITABLE_CLIENT_BUNDLE_INFO',
  RESET_CLIENT_BUNDLE_EDIT_FORM_DATA: 'RESET_CLIENT_BUNDLE_EDIT_FORM_DATA',
  SET_VALIDATION_CLIENT_BUNDLE_INFO: 'SET_VALIDATION_CLIENT_BUNDLE_INFO',
  UPDATE_CLIENT_BUNDLE_INFO: 'UPDATE_CLIENT_BUNDLE_INFO',
  SET_SELECTED_CLIENT_BUNDLE: 'SET_SELECTED_CLIENT_BUNDLE',
  SET_SELECTED_PLATFORM_INDEX: 'SET_SELECTED_PLATFORM_INDEX',
  SET_SELECTED_ARCHITECTURE_INDEX: 'SET_SELECTED_ARCHITECTURE_INDEX',
  SET_SELECTED_DURATION_INDEX: 'SET_SELECTED_DURATION_INDEX',
  SET_ARCHITECTURES: 'SET_ARCHITECTURES',
};

import {METHODS} from '../../constants';
import initState from '../../reducers/redux-initial-state';
import {get, makeUrl} from '../../utils/fetcher';
import {AppDispatch} from '../../utils/store';

import {topCategoryCount, types} from './constants';
import {convertGraphDataToView} from './utils';
import {RequestsByCategory} from './types';

export const getRequestsStatsByCategories = (
  queryParams: {[key: string]: string | number},
  period: string,
  widgetKey: string,
) => {
  const params = {
    ...queryParams,
    amount: topCategoryCount,
  };
  const url = makeUrl(METHODS.GET_REQUEST_STATS_BY_CATEGORIES, params);
  return (dispatch: AppDispatch) => get(url, dispatch, true, widgetKey)
    .then((response) => {
      let data: RequestsByCategory[] =
          initState.requestStatsByCategoriesWidgetReducer.userRequestsByCategories;
      if (response && response.data) {
        data = convertGraphDataToView(response.data, period);
      }
      dispatch({
        type: types.SET_USER_REQUESTS_BY_CATEGORIES,
        data,
      });
      return data;
    });
};

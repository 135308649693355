import {formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift} from '../../utils/dateTime';
import {getOrDefault} from '../../utils/common';
import {Session} from './types';

export const getSessionData = (data: Session[]) => data.map((item) => ({
  ...item,
  hostname: getOrDefault(item, 'hostname', '-'),
  networkIp: getOrDefault(item, 'networkIp', '-'),
  subnetIp: getOrDefault(item, 'subnetIp', '-'),
  locationName: getOrDefault(item, 'locationName', 'ROAMING'),
  sessionStartAt: formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift(
    getOrDefault(item, 'sessionStartAt', '')),
}));

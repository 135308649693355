import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {types} from './constants';
import {getSessionData} from './utils';

export const loadClientSessions = (clientId, period, widgetKey) => {
  const url = makeUrl(METHODS.GET_CLIENT_SESSIONS, {clientId, period});
  return (dispatch) => get(url, dispatch, true, widgetKey)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_CLIENT_SESSIONS,
          sessions: getSessionData(response.data),
        });
      }
    });
};

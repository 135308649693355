import initState from '../../reducers/redux-initial-state';

import {cloneBlockPageInfo} from './service';

import {types} from './constants';

export default (state = initState.blockPageEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_BLOCK_PAGE_INFO: {
      return {
        ...state,
        editableBlockPageInfo: cloneBlockPageInfo(action.blockPageInfo),
      };
    }
    case types.SET_VALIDATION_BLOCK_PAGE_INFO: {
      return {
        ...state,
        isValidBlockPageInfo: action.isValidBlockPageInfo,
      };
    }
    case types.RESET_BLOCK_PAGE_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.blockPageEditFormReducer,
      };
    }
    case types.UPDATE_BLOCK_PAGE_INFO: {
      return {
        ...state,
        editableBlockPageInfo: action.updatedBlockPageInfo,
        isValidBlockPageInfo: action.isValidBlockPageInfo,
      };
    }
    default:
      return state;
  }
};

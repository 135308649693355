import _cloneDeep from 'lodash/cloneDeep';

import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.localForwardingEditFormReducer, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_LOCAL_FORWARDING_INFO: {
      return {
        ...state,
        editableLocalForwardingInfo: _cloneDeep(action.data),
      };
    }
    case types.SET_VALIDATION_LOCAL_FORWARDING_INFO: {
      return {
        ...state,
        isValidLocalForwardingInfo: action.isValid,
      };
    }
    case types.RESET_LOCAL_FORWARDING_EDIT_FORM_DATA: {
      return {
        ...state,
        ...initState.localForwardingEditFormReducer,
      };
    }
    case types.UPDATE_LOCAL_FORWARDING_INFO: {
      return {
        ...state,
        editableLocalForwardingInfo: action.data,
        isValidLocalForwardingInfo: action.isValidLocalForwardingInfo,
      };
    }
    case types.SET_SUBNET_LIST: {
      return {
        ...state,
        subnets: action.subnets,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import _get from 'lodash/get';
import {NavPills} from '../../material-dashboard-pro-react/components';

import {AuditReports} from '../../containers';

import {TABS} from './constants';
import style from './style';

class LogAndAuditReports extends React.PureComponent {
  render() {
    const {classes} = this.props;

    const filename = _get(this.props, 'match.params.filename', null);
    return (
      <div className={classes.logAndAuditReports}>
        <NavPills
            color="secondary"
            className="root_fullScreen"
            wrapperClassName="tab__contentWrapper_fullScreen"
            contentClassName="tab__content_fullScreen"
            isSwipeable={false}
            tabs={[
              // {
              //   tabButton: I18n.t(`logPage.${TABS.LOG}.name`),
              //   tabContent: <ClientsManager />,
              // },
              {
                tabButton: I18n.t(`logPage.${TABS.REPORTS}.name`),
                tabContent: <AuditReports filename={filename} />,
              },
            ]}
        />
      </div>
    );
  }
}

LogAndAuditReports.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

// const mapStateToProps = (state) => ({
//   accountInfo: state.operatorLayoutReducer.accountInfo,
// });
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(LogAndAuditReports));

const style = {
  mainContainer: {
    overflow: 'auto',
    flex: 2,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  actionsContainer__button: {
    padding: 0,
  },
  actionsContainer__button_up: {
    transform: 'rotate(-90deg)',
  },
  actionsContainer__button_down: {
    transform: 'rotate(90deg)',
  },
  table: {
    maxHeight: '500px',
    minHeight: '150px',
    overflowY: 'auto',
  },
  table__cell_center: {
    textAlign: 'center',
  },
  group: {
    textTransform: 'uppercase',
  },
};

export default style;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';

import {withStyles} from '@material-ui/core/styles';

import {
  Dropdown,
  StatusStats,
  PerformanceStats,
  BlocksStats,
  RequestsStats,
  ButtonExpandLessMore,
  RenderOrEmpty,
} from '../index';

import {widgetKeys} from '../../app-common/Campus/constants';
import {RightAvailability} from '../../containers';
import {PERIODS} from '../../constants';

import style from './style';

class CampusDashboard extends Component {
  static shouldUpdateDashboardData(prevProps, props) {
    return !_isEqual(prevProps.campusId, props.campusId)
      && !_isNull(props.campusId);
  }

  constructor(props) {
    super(props);
    this.state = {
      isShowDashboard: true,
    };
    this.initialDropDownEvent = {target: {value: 0}};
    this.dropdownPeriodsList = [];
    this.dropdownPeriodsList.push(...Object.keys(PERIODS).map((period) =>
      I18n.t(`dashboard.filters.${period}`)));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(nextProps.campusId, this.props.campusId)
    || !_isEqual(nextProps.averageLatency, this.props.averageLatency)
    || !_isEqual(nextProps.blocksStats, this.props.blocksStats)
    || !_isEqual(nextProps.requestsPerSecond, this.props.requestsPerSecond)
    || !_isEqual(nextProps.requestsByDecision, this.props.requestsByDecision)
    || !_isEqual(nextProps.networksStats, this.props.networksStats)
    || !_isEqual(nextProps.userRequestsGraph, this.props.userRequestsGraph)
    || !_isEqual(nextProps.selectedPeriodIndex, this.props.selectedPeriodIndex)
    || !_isEqual(nextState.selectedCampusIndex, this.state.selectedCampusIndex)
    || !_isEqual(nextState.isShowDashboard, this.state.isShowDashboard);
  }

  componentDidUpdate(prevProps) {
    if (CampusDashboard.shouldUpdateDashboardData(prevProps, this.props)) {
      this.handleChangePeriodDropdown(this.initialDropDownEvent);
    }
  }

  handleChangePeriodDropdown = (e) => {
    const {campusId} = this.props;
    this.props.handleChangePeriod(
      {
        period: PERIODS[Object.keys(PERIODS)[e.target.value]],
        campusId: campusId,
      },
      e.target.value,
    );
  };

  handleChangeShowMode = () => {
    const {isShowDashboard} = this.state;
    this.setState({
      isShowDashboard: !isShowDashboard,
    });
  };

  render() {
    const {
      averageLatency,
      blocksStats,
      classes,
      networksStats,
      requestsPerSecond,
      requestsByDecision,
      selectedPeriodIndex,
      userRequestsGraph,
      accessRights,
    } = this.props;

    const {isShowDashboard} = this.state;
    return (
      <div className={classes.campusDashboard}>
        <div
          className={classNames(
            classes.campusDashboard__controlPanel,
            {
              [classes.campusDashboard__controlPanel_dashboardIsHidden]: !isShowDashboard,
            },
          )}
        >
          <ButtonExpandLessMore
            customClasses="campusDashboard__buttonShowHideDashboard"
            text={I18n.t('campusPage.dashboardTitle')}
            isExpandLess={isShowDashboard}
            onClick={this.handleChangeShowMode}
          />
          <RenderOrEmpty isShow={isShowDashboard}>
            <div className={classes.controlPanel__item_dropdown}>
              <Dropdown
                options={this.dropdownPeriodsList}
                selectedItemIndex={selectedPeriodIndex}
                onChangeValue={this.handleChangePeriodDropdown}
                customSelectClass="dropdown__select_campusDashboard"
              />
            </div>
          </RenderOrEmpty>
        </div>
        <RenderOrEmpty isShow={isShowDashboard}>
          <div className={classes.campusDashboard__statsPanel}>
            <div
              className={classNames(
                classes.statsPanel__block,
                classes.statsPanel__block_left,
              )}
            >
              <div className={classes.statsPanel__card}>
                <StatusStats
                  networksUp={networksStats.up}
                  networksDown={networksStats.down}
                  widgetKey={widgetKeys.CAMPUSES_STATS}
                />
              </div>
              <div className={classes.statsPanel__card}>
                <PerformanceStats
                  averageLatency={averageLatency}
                  requestsPerSecond={requestsPerSecond}
                  widgetKey={widgetKeys.PERFORMANCE_STATS}
                />
              </div>
            </div>
            <RightAvailability accessRights={accessRights}>
              <div
                className={classNames(
                  classes.statsPanel__block,
                  classes.statsPanel__block_right,
                )}
              >
                <div className={classes.statsPanel__item_graph}>
                  <RequestsStats
                    userRequestsGraph={userRequestsGraph}
                    requestsAllowed={requestsByDecision.ALLOWED}
                    requestsBlocked={requestsByDecision.FORBIDDEN}
                    requestsTotal={requestsByDecision.ALLOWED + requestsByDecision.FORBIDDEN}
                    customGraphClass="requestsStats__graph_campusDashboard"
                    widgetKey={widgetKeys.REQUEST_STATS}
                  />
                </div>
              </div>
            </RightAvailability>
            <div
              className={classNames(
                classes.statsPanel__block,
                classes.statsPanel__block_bottom,
              )}
            >
              <BlocksStats
                applications={blocksStats.applications}
                content={blocksStats.content}
                whiteBlackList={blocksStats.whiteBlackList}
                threats={blocksStats.threats}
                widgetKey={widgetKeys.BLOCK_STATS}
              />
            </div>
          </div>
        </RenderOrEmpty>
      </div>
    );
  }
}

CampusDashboard.propTypes = {
  averageLatency: PropTypes.number.isRequired,
  blocksStats: PropTypes.object.isRequired,
  campusId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleChangePeriod: PropTypes.func.isRequired,
  networksStats: PropTypes.object.isRequired,
  requestsPerSecond: PropTypes.number.isRequired,
  requestsByDecision: PropTypes.object.isRequired,
  selectedPeriodIndex: PropTypes.number.isRequired,
  userRequestsGraph: PropTypes.object.isRequired,
  accessRights: PropTypes.array,
};

CampusDashboard.defaultProps = {
  campusId: null,
  accessRights: [],
};

export default withStyles(style)(CampusDashboard);

import {
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  loginFailed,
  loginSuccess,
} from '../auth/login';

import {
  removeJWT,
  removeRefresh,
  setJWT,
  setRefresh,
} from '../../utils/jwt';

import {
  removeLoggedAccount,
  setLoggedAccount,
} from '../../utils/account';

import {setLoggedAccountToStore} from './setLoggedAccountToStore';

import {METHODS} from '../../constants';

import {types} from './actionTypes';

export function switchAccount(account, authData) {
  const url = makeUrl(METHODS.SWITCH_ACCOUNT);
  return (dispatch) => post(url, account, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        setJWT(data.token);
        setRefresh(data.refreshToken);
        setLoggedAccount(
          data.accountId,
          data.role,
          data.name,
        );
        dispatch(loginSuccess(authData));
        dispatch(switchAccountSuccess());
        dispatch(setLoggedAccountToStore());
        return true;
      }
      removeJWT();
      removeRefresh();
      removeLoggedAccount();
      dispatch(loginFailed());
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to switch account:', error);
    });
}

export function switchAccountSuccess() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_SWITCH_ACCOUNT_SUCCESS,
    });
  };
}

import {
  makeUrl,
  post,
} from '../../utils/fetcher';

import {METHODS} from '../../constants';

export const types = {
  ACTION_RESET_PASSWORD_SUCCESS: 'ACTION_RESET_PASSWORD_SUCCESS',
  ACTION_RESET_PASSWORD_FAILED: 'ACTION_RESET_PASSWORD_FAILED',
};

export function resetPassword(data) {
  const url = makeUrl(METHODS.RESET_PASSWORD);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response) {
        dispatch(resetPasswordSuccess());
        return null;
      }
      dispatch(resetPasswordFailed());
      return null;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Reset password failed', error);
    });
}

function resetPasswordSuccess() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_RESET_PASSWORD_SUCCESS,
    });
  };
}

function resetPasswordFailed() {
  return (dispatch) => {
    dispatch({
      type: types.ACTION_RESET_PASSWORD_FAILED,
    });
  };
}

export function resetPageData() {
  return (dispatch) => dispatch(resetPasswordFailed());
}

import {createStyles} from '@material-ui/core';

import cardsStyle from '../../styles/components/cards';

const style = createStyles({
  userInfoTableCard: {
    ...cardsStyle.card,
  },
  userInfoTableCard__header: {
    ...cardsStyle.card__header,
  },
  userInfoTableCard__title: {
    ...cardsStyle.card__title,
    ...cardsStyle.card__title_primary,
    borderBottom: 'none',
    fontSize: '20px',
    fontWeight: 400,
  },
  userInfoTableCard__content: {
    ...cardsStyle.card__content,
    padding: '0px 20px 5px 20px !important',
  },
});

export default style;

import {
  get,
  makeUrl,
  post,
} from '../../utils/fetcher';

import {
  getPolicyToCreate,
  getPolicyToUpdate,
} from './service';

import {
  METHODS,
  STATES_ENTITY,
} from '../../constants';

export const types = {
  CHANGE_EDIT_MODE_AND_POLICY_STATE: 'CHANGE_EDIT_MODE_AND_POLICY_STATE',
  SAVE_EDITED_POLICY: 'SAVE_EDITED_POLICY',
  SET_DEFAULT_POLICIES: 'SET_DEFAULT_POLICIES',
  SET_POLICIES: 'SET_POLICIES',
  SET_POLICIES_TABLE_DATA: 'SET_POLICIES_TABLE_DATA',
  SET_POLICY_TO_FORM_CREATING: 'SET_POLICY_TO_FORM_CREATING',
  SET_SELECTED_POLICY: 'SET_SELECTED_POLICY',
  SET_SELECTED_POLICY_INDEX: 'SET_SELECTED_POLICY_INDEX',
  SET_UPDATED_POLICY: 'SET_UPDATED_POLICY',
  RESET_POLICIES: 'RESET_POLICIES',
  RESET_SELECTED_POLICY_INDEX: 'RESET_SELECTED_POLICY_INDEX',
};

export function getPolicies() {
  const url = makeUrl(METHODS.GET_POLICIES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_POLICIES,
          data,
        });
      }
    });
}

export function getPolicyById(policyId) {
  const url = makeUrl(METHODS.GET_POLICY_BY_ID, {id: policyId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_SELECTED_POLICY,
          data,
        });
      }
    });
}

export function createPolicy(currentPolicy, newData) {
  const url = makeUrl(METHODS.CREATE_POLICY);
  const data = getPolicyToCreate(currentPolicy, newData);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndPolicyState(false, STATES_ENTITY.NONE));
        dispatch(updatePolicyPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to create policy:', error);
    });
}

export function updatePolicy(currentPolicy, newData) {
  const url = makeUrl(METHODS.UPDATE_POLICY);
  const data = getPolicyToUpdate(currentPolicy, newData);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndPolicyState(false, STATES_ENTITY.NONE));
        dispatch(getPolicies())
          .then(() => dispatch({
            type: types.SET_UPDATED_POLICY,
            data: response.data,
          }))
          .then(() => dispatch(getPoliciesTableData()));
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to edit policy:', error);
    });
}

export function deletePolicy(policyId) {
  const url = makeUrl(METHODS.DELETE_POLICY);
  const data = {
    id: policyId,
  };
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updatePolicyPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to delete policy:', error);
    });
}

export function getDataToShowPolicyDetails(policyId, policyIndex) {
  return (dispatch) => {
    dispatch(getPolicyById(policyId));
    dispatch(setSelectedPolicyIndex(policyIndex));
  };
}

export function resetPolicies() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_POLICIES,
    });
  };
}

export function changeEditModeAndPolicyState(isEditMode, policyState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_POLICY_STATE,
      isEditMode,
      policyState,
    });
  };
}

export function getPoliciesTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_POLICIES_TABLE_DATA,
    });
  };
}

export function setPolicyToFormCreating() {
  const url = makeUrl(METHODS.GET_POLICY_TEMPLATE);
  return (dispatch) => get(url)
    .then((response) => {
      if (response && response.success) {
        dispatch({
          type: types.SET_POLICY_TO_FORM_CREATING,
          data: response.data,
        });
      }
    });
}

export function setSelectedPolicyIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_POLICY_INDEX,
      selectedPolicyIndex: index,
    });
  };
}

export function resetSelectedPolicyIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_POLICY_INDEX,
    });
  };
}

export function updatePolicyPageData() {
  return (dispatch) => {
    dispatch(resetSelectedPolicyIndex());
    dispatch(getPolicies());
  };
}

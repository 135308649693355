import React from 'react';

import {InsightsLayout} from '../../layouts-common';
import {DomainInsightsFilter} from '../index';

import {TABS} from '../../app-common/Insights/constants';

import './style.css';

function DomainInsights() {
  return (
    <InsightsLayout
      tab={TABS.DOMAIN}
      filters={<DomainInsightsFilter />}
    />
  );
}

export default DomainInsights;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';
import {GridContainer, ItemGrid} from '../../material-dashboard-pro-react/components';

import {
  EntityEditingCard,
  RenderOrEmpty,
  TabsPanel,
} from '../index';

import {
  OrganizationSitesTab,
  OrganizationProfilesTab,
  OrganizationInfoTab,
  OrganizationDashboard,
  OrganizationPersonasTab,
} from '../../containers/index';
import {ACCESS_RIGHTS} from '../../constants';

import style from './style';

const rightAvailabilityMap = {
  buttonDelete: [ACCESS_RIGHTS.ORGANIZATION_DELETE],
};

class OrganizationDetails extends Component {
  static initState = {
    activeTabIndex: 0,
  };

  constructor(props) {
    super(props);

    const {showProfileTab} = this.props;

    if (!showProfileTab) {
      this.tabs = [
        {
          text: I18n.t('organizations.tabs.sites.title'),
          name: 'sites',
        },
        {
          text: I18n.t('organizations.tabs.info.title'),
          name: 'info',
        },
      ];
    } else {
      this.tabs = [
        {
          text: I18n.t('organizations.tabs.sites.title'),
          name: 'sites',
        },
        {
          text: I18n.t('organizations.tabs.profiles.title'),
          name: 'profiles',
        },
        {
          text: I18n.t('organizations.tabs.personas.title'),
          name: 'personas',
        },
        {
          text: I18n.t('organizations.tabs.info.title'),
          name: 'info',
        },
      ];
    }
    this.state = {...OrganizationDetails.initState};
    this.dashboard = null;
  }

  getTabContent = (tabName) => {
    const {
      handleClickButtonEdit,
      handleClickButtonCancelEdit,
      handleClickButtonSave,
    } = this.props;

    const tabContentMap = {
      sites: <OrganizationSitesTab onUpdateSites={this.forceReloadDashboardLocations} />,
      profiles: <OrganizationProfilesTab
                  onUpdateClientProfiles={this.forceReloadDashboardClientProfiles}
      />,
      personas: <OrganizationPersonasTab />,
      info: <OrganizationInfoTab
              handleClickButtonCancelEdit={handleClickButtonCancelEdit}
              handleClickButtonEdit={handleClickButtonEdit}
              handleClickButtonSave={handleClickButtonSave}
      />,
    };
    return tabContentMap[tabName];
  };

  handleClickTab = (tabIndex) => {
    this.setState({
      activeTabIndex: tabIndex,
    });
  };

  forceReloadDashboardLocations = () => {
    if (!_isNil(this.dashboard)) {this.dashboard.forceReloadLocations();}
  };

  forceReloadDashboardClientProfiles = () => {
    if (!_isNil(this.dashboard)) {this.dashboard.forceReloadClientProfiles();}
  };

  setDashboardRef = (ref) => {
    this.dashboard = ref;
  };

  render() {
    const {
      classes,
      organization,
      handleClickButtonBack,
      handleClickButtonDelete,
      isEditMode,
      isSubTabEditMode,
      showProfileTab,
    } = this.props;
    const {activeTabIndex} = this.state;

    const hasEditMode = isEditMode
      || Object.keys(isSubTabEditMode).reduce((allValid, key) =>
        allValid || isSubTabEditMode[key], false);
    const tabContent = this.getTabContent(this.tabs[activeTabIndex].name);

    return (
      <EntityEditingCard
        key="organization-details"
        title={organization.name}
        entityType={I18n.t('entitiesTypes.organization')}
        isThereDeleteBlock={true}
        isTherePathBack={true}
        isEditMode={hasEditMode}
        handleClickButtonBack={handleClickButtonBack}
        handleClickButtonDelete={handleClickButtonDelete}
        customCardContentClass="card__content_organization"
        accessRights={rightAvailabilityMap}
        content={(
          <GridContainer direction="column" className={classes.grid}>
            <ItemGrid>
              <OrganizationDashboard
                organization={organization}
                setRef={this.setDashboardRef}
                showProfileTab={showProfileTab}
              />
            </ItemGrid>
            <ItemGrid>
              <TabsPanel
                activeTabIndex={activeTabIndex}
                disabled={hasEditMode}
                handleClickTab={this.handleClickTab}
                tabs={this.tabs}
              />
            </ItemGrid>
            <RenderOrEmpty isShow={!_isNil(tabContent)}>
              <ItemGrid>
                {tabContent}
              </ItemGrid>
            </RenderOrEmpty>
          </GridContainer>
        )}
      />
    );
  }
}

OrganizationDetails.propTypes = {
  organization: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isSubTabEditMode: PropTypes.object.isRequired,
  isValidOrganizationInfo: PropTypes.bool.isRequired,
  showProfileTab: PropTypes.bool,
};

OrganizationDetails.defaultProps = {
  showProfileTab: true,
};

export default withStyles(style)(OrganizationDetails);

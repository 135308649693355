import _isEmpty from 'lodash/isEmpty';
import {
  dangerColor,
  primaryColor,
} from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';
import {blackColor, secondaryColor, whiteColor} from '../../styles/style';

const styles = (errorText, labelText) => ({
  input: (baseStyles) => ({
    ...baseStyles,
    'cursor': 'text',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    marginTop: labelText ? 16 : 0,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    'border': 'none',
    'background': 'none',
    'boxShadow': 'none',
    'minHeight': 40,
    '&:hover': {
      '&:before': {
        backgroundColor: !_isEmpty(errorText) ? dangerColor : blackColor,
      },
      'cursor': 'pointer',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: !_isEmpty(errorText) ? 2 : 1,
      backgroundColor: !_isEmpty(errorText) ? dangerColor : '#d2d2d2',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: 2,
      backgroundColor: !_isEmpty(errorText) ? dangerColor : primaryColor,
      transform: state.isFocused ? 'scaleX(1)' : 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    width: 0,
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    'padding': 0,
    'color': blackColor,
    'opacity': '54%',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    '&:hover': {
      cursor: 'default',
    },
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: '#aaa',
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    lineHeight: 1.42857,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    'margin': '0 5px',
    'padding': '10px 10px',
    'display': 'block',
    'fontSize': '13px',
    'transition': 'all 0.15s linear',
    'fontWeight': 400,
    'lineHeight': 2,
    'borderRadius': '2px',
    '&:hover': {
      color: whiteColor,
      backgroundColor: secondaryColor,
      cursor: 'pointer',
    },
    'color': state.isSelected || state.isFocused ? whiteColor : '#333',
    'backgroundColor': state.isSelected || state.isFocused ? secondaryColor : 'transparent',
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
});

export default styles;

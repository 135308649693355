import {types} from './constants';

export function updateEditOrganizationPersonas(personasInOrganization, personasNotInOrganization) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDIT_FROM_ORGANIZATION_PERSONAS,
      personasInOrganization,
      personasNotInOrganization,
    });
  };
}

import _difference from 'lodash/difference';
import _isNil from 'lodash/isNil';

export const areTwoArraysEqualIgnoringOrder = (first, second) => {
  if (first.length !== second.length) {
    return false;
  }

  return _difference(first, second).length === 0;
};

export const insertIntoSortedArray = (array, items, accessor) => {
  const newArray = (!_isNil(array)) ? [...array] : [];
  items.forEach((i) => {
    const indexToInsert = newArray.findIndex((el) => el[accessor] < i[accessor]);
    if (indexToInsert < 0) {
      newArray.push(i);
    } else {
      newArray.splice(indexToInsert, 0, i);
    }
  });
  return newArray;
};

export const arrayToString = (array) => {
  if (!array || array.length === 0) {
    return '';
  }
  const firstItem = array[0];
  const tail = array.splice(1).reduce((acc, item) => `${acc}, ${item}`, '');
  return firstItem + tail;
};

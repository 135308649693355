import _get from 'lodash/get';
import {
  makeUrl,
  post,
} from '../../utils/fetcher';

import {METHODS} from '../../constants';
import {types} from './actionTypes';

export const setUserOrganization = (organization) => (dispatch) => {
  dispatch({
    type: types.SET_USER_ORGANIZATION,
    data: organization,
  });
};

export const switchOrganization = (organization) => {
  const url = makeUrl(METHODS.SWITCH_ORGANIZATION);
  const requestData = {
    organizationId: _get(organization, 'id', null),
  };
  return (dispatch) => post(url, requestData, dispatch, false)
    .then((response) => {
      if (response && response.data) {
        dispatch(setUserOrganization(response.data));
        return response.data;
      }
      dispatch(setUserOrganization(null));
      return null;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Unable to switch organization:', error);
    });
};

import initState from '../../reducers/redux-initial-state';

import {
  getWhiteBlackListsTableData,
  getUpdatedWhiteBlackListIndex,
} from './service';

import {types} from './action';

export default (state = initState.whiteBlackListReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_WHITE_BLACK_LIST_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        whiteBlackListState: action.whiteBlackListState,
      };
    }
    case types.SET_WHITE_BLACK_LISTS: {
      return {
        ...state,
        whiteBlackLists: action.data,
      };
    }
    case types.SET_SELECTED_WHITE_BLACK_LIST: {
      return {
        ...state,
        selectedWhiteBlackList: action.data,
      };
    }
    case types.SET_WHITE_BLACK_LISTS_TABLE_DATA: {
      return {
        ...state,
        whiteBlackListsTableData: getWhiteBlackListsTableData(state.whiteBlackLists),
      };
    }
    case types.SET_SELECTED_WHITE_BLACK_LIST_INDEX: {
      return {
        ...state,
        selectedWhiteBlackListIndex: action.selectedWhiteBlackListIndex,
      };
    }
    case types.SET_UPDATED_WHITE_BLACK_LIST: {
      return {
        ...state,
        selectedWhiteBlackListIndex: getUpdatedWhiteBlackListIndex(
          action.data.id,
          state.whiteBlackLists,
        ),
        selectedWhiteBlackList: action.data,
      };
    }
    case types.RESET_SELECTED_WHITE_BLACK_LIST_INDEX: {
      return {
        ...state,
        selectedWhiteBlackListIndex: initState.whiteBlackListReducer.selectedWhiteBlackListIndex,
        selectedWhiteBlackList: initState.whiteBlackListReducer.selectedWhiteBlackList,
      };
    }
    case types.RESET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST: {
      return {
        ...state,
        policiesAssigned: initState.whiteBlackListReducer.policiesAssigned,
      };
    }
    case types.SET_POLICIES_ASSIGNED_TO_WHITE_BLACK_LIST: {
      return {
        ...state,
        policiesAssigned: action.policiesAssigned,
      };
    }
    default:
      return state;
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {
  getEditableCampusInfo,
  setValidationResultCampusInfo,
  resetCampusEditFormData,
  updateCampusInfo,
} from './action';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {STATES_ENTITY} from '../../constants';

import style from './style';

class CampusEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
      address: true,
    },
  };

  static shouldUpdateCampusObject(prevProps, props) {
    return !_isEqual(prevProps.campus, props.campus);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.campusState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = CampusEditForm.initialLocalState;
  }

  componentDidMount() {
    this.props.getEditableCampusInfo(this.props.campus);
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.campus, nextProps.campus)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableCampusInfo, nextProps.editableCampusInfo);
  }

  componentDidUpdate(prevProps) {
    if (CampusEditForm.shouldUpdateCampusObject(prevProps, this.props)
      || CampusEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.getEditableCampusInfo(this.props.campus);
    }
    if (CampusEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetCampusEditFormData();
  }

  resetValidation = () => {
    this.props.setValidationResultCampusInfo(true);
    this.setState(CampusEditForm.initialLocalState);
  };

  validate = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
      {
        name: 'address',
        validationRules: [],
      },
    ],
    data,
    {
      name: true,
      address: true,
    },
  );

  handleChangeCampusInfo = (e) => {
    const {editableCampusInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableCampusInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateCampusInfo(
      {
        ...editableCampusInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  render() {
    const {
      classes,
      editableCampusInfo,
      isEditMode,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      name: {
        disabled: !isEditMode,
        value: editableCampusInfo.name,
        name: 'name',
        onChange: this.handleChangeCampusInfo,
        type: 'text',
      },
      address: {
        disabled: !isEditMode,
        value: editableCampusInfo.address,
        name: 'address',
        onChange: this.handleChangeCampusInfo,
        type: 'text',
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.name
              ? undefined
              : I18n.t('campusPage.errorMessages.campusNameIsNotValid')}
            error={!validation.name}
            labelText={I18n.t('campusPage.campusName')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.name}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            labelText={I18n.t('campusPage.campusAddress')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.address}
          />
        </div>
      </div>
    );
  }
}

CampusEditForm.propTypes = {
  campus: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editableCampusInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,

  getEditableCampusInfo: PropTypes.func.isRequired,
  setValidationResultCampusInfo: PropTypes.func.isRequired,
  resetCampusEditFormData: PropTypes.func.isRequired,
  updateCampusInfo: PropTypes.func.isRequired,
};

CampusEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  campus: state.campusReducer.selectedCampus,
  campusState: state.campusReducer.campusState,
  isEditMode: state.campusReducer.isEditMode,

  editableCampusInfo: state.campusEditFormReducer.editableCampusInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getEditableCampusInfo: bindActionCreators(getEditableCampusInfo, dispatch),
  setValidationResultCampusInfo: bindActionCreators(setValidationResultCampusInfo, dispatch),
  resetCampusEditFormData: bindActionCreators(resetCampusEditFormData, dispatch),
  updateCampusInfo: bindActionCreators(updateCampusInfo, dispatch),
});

const CampusEditFormWithStyles = withStyles(style)(CampusEditForm);
export default connect(mapStateToProps, mapDispatchToProps)(CampusEditFormWithStyles);

import {types} from './constants';

export function updateEditableSingleSignOnConfiguration(updatedSingleSignOnConfiguration, isValid) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_SINGLE_SIGN_ON_CONFIGURATION,
      updatedSingleSignOnConfiguration,
      isValidSingleSignOnConfiguration: isValid,
    });
  };
}

export function setEditableSingleSignOnConfiguration(singleSignOnConfiguration) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EDITABLE_SINGLE_SIGN_ON_CONFIGURATION,
      singleSignOnConfiguration: singleSignOnConfiguration,
      isValidSingleSignOnConfiguration: true,
    });
  };
}

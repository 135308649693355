import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import _clone from 'lodash/clone';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import OperatorForm from '../../components/OperatorForm/OperatorForm';
import {USER_ROLES} from '../../constants';
import EntityCreatingCard from '../../components/EntityCreatingCard/EntityCreatingCard';
import {isEmail, isNotEmpty, validate} from '../../utils/validators';
import {createOperator} from '../../app-common/AccessManagement/action';
import {getOrganizationsData} from '../OperatorOrganizationsTable/utils';

class NewOperatorForm extends React.Component {
  static validationRules = [{
    name: 'email',
    validationRules: [isNotEmpty, isEmail],
  }, {
    name: 'firstName',
    validationRules: [isNotEmpty],
  }, {
    name: 'lastName',
    validationRules: [isNotEmpty],
  }, {
    name: 'role',
    validationRules: [isNotEmpty],
  }];

  state = {
    operator: {
      firstName: '',
      lastName: '',
      email: '',
      role: USER_ROLES.ADMIN,
      organizations: [],
    },
    validationResult: {
      validationState: {},
      isValid: false,
    },
  };

  onChange = (fieldName, value) =>
    this.setState((prevState) => {
      const operator = _clone(prevState.operator);
      _set(operator, fieldName, value.trim());

      const validationResult = validate(
        NewOperatorForm.validationRules,
        operator,
        prevState.validationResult.validationState,
      );

      return {operator, validationResult};
    });

  saveOperator = () => {
    const operatorOrganizations = getOrganizationsData(this.props.operatorOrganizationRows);
    this.props.createOperator(
      {
        ...this.state.operator,
        organizations: operatorOrganizations,
      }).then(this.props.updatingCallback);
  };

  isValidOperator = () => (
    this.state.validationResult.isValid
      && (!_isEqual(this.state.operator.role, USER_ROLES.ORGANIZATION_OPERATOR)
      || (_isEqual(this.state.operator.role, USER_ROLES.ORGANIZATION_OPERATOR)
          && !_isEmpty(this.props.operatorOrganizationRows)
          && this.props.operatorOrganizationRows
            .findIndex((item) => item.selectedOrganizationIndex >= 0) >= 0))
  );

  render() {
    return (
      <EntityCreatingCard
        title={I18n.t('accessManagementPage.newOperator.title')}
        content={(
          <OperatorForm
            operator={this.state.operator}
            accountInfo={this.props.accountInfo}
            onChange={this.onChange}
            isNewOperator={true}
            isEditing={true}
            validationState={this.state.validationResult.validationState}
          />
        )}
        handleClickButtonCancel={this.props.resetAddingOfOperator}
        handleClickButtonSave={this.saveOperator}
        isValidEntity={this.isValidOperator()}
      />
    );
  }
}

NewOperatorForm.propTypes = {
  operatorOrganizationRows: PropTypes.array.isRequired,
  resetAddingOfOperator: PropTypes.func.isRequired,
  updatingCallback: PropTypes.func.isRequired,
  createOperator: PropTypes.func.isRequired,
  accountInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  operatorOrganizationRows: state.operatorOrganizationsTableReducer.rows,
  accountInfo: state.operatorLayoutReducer.accountInfo,
});

const mapDispatchToProps = {
  createOperator,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOperatorForm);

import {get, makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_API_KEYS: 'SET_API_KEYS',
  SET_API_KEYS_EDITING_MODE: 'SET_API_KEYS_EDITING_MODE',
  RESET_API_KEYS_STATE: 'RESET_API_KEYS_STATE',
};

export function getApiKeys() {
  const url = makeUrl(METHODS.GET_API_KEYS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_API_KEYS,
          data,
        });
      }
    });
}

export function revokeApiKey(keyId) {
  const url = makeUrl(METHODS.REVOKE_API_KEY);
  const data = {id: keyId};
  return (dispatch) => post(url, data, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Deleting of API key failed', error);
    });
}

export function updateApiKey(apiKey) {
  const url = makeUrl(METHODS.UPDATE_API_KEY);
  return (dispatch) => post(url, apiKey, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Updating of API key failed', error);
    });
}

export function createApiKey(apiKey) {
  const url = makeUrl(METHODS.CREATE_API_KEY);
  return (dispatch) => post(url, apiKey, dispatch)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Adding of new API key failed', error);
    });
}

export function setEditingMode(isEditingMode) {
  return (dispatch) => dispatch({
    type: types.SET_API_KEYS_EDITING_MODE,
    isEditingMode,
  });
}

export function resetState() {
  return (dispatch) => dispatch({
    type: types.RESET_API_KEYS_STATE,
  });
}

import {connect} from 'react-redux';
import React from 'react';
import {bindActionCreators} from 'redux';
import {PropTypes} from 'prop-types';
import _isEqual from 'lodash/isEqual';
import {
  getReport,
} from './action';

class AuditReports extends React.Component {
  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  componentDidMount() {
    this.props.getReport(this.props.filename);
  }

  componentDidUpdate() {
    if (AuditReports.wasDataScopeSwitched()) {
      this.props.getReport(this.prop.filename);
    }
  }

  render() {
    return (
      <div />
    );
  }
}

AuditReports.propTypes = {
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  filename: PropTypes.string.isRequired,
  getReport: PropTypes.func.isRequired,
};

AuditReports.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  getReport: bindActionCreators(getReport, dispatch),

});

// const AuditReportsWithStyles = withStyles(style)(AuditReports);
export default connect(mapStateToProps, mapDispatchToProps)(AuditReports);

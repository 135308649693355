import _cloneDeep from 'lodash/cloneDeep';

import {formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift} from '../../utils/dateTime';

export const getRelayTableRowData = (relay, networks) => {
  const network = networks.find((item) => item.id === relay.networkId);
  const cloned = _cloneDeep(relay);
  delete cloned.networkId;
  cloned.network = network ? network.name : '—';

  cloned.lastConfigUpdatingAt = cloned.lastConfigUpdatingAt
    ? formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithShift(cloned.lastConfigUpdatingAt)
    : '—';

  return cloned;
};

export const getRelayTableData = (relays, networks) =>
  relays.map((relay) => getRelayTableRowData(relay, networks));

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';

import {
  Breadcrumbs,
  IconMenu,
  RenderDivOrEmpty,
  TableWithPaginationAndFiltration,
} from '../../components';
import {CustomButton} from '../../material-dashboard-pro-react/components';
import {MoreVert, Export} from '../../icons/index';
import {TABS as insightsTabs, TABS, COMPONENTS} from '../../app-common/Insights/constants';
import {
  getInsightsData,
  getInsightsComponents,
  getInsightsSelectedRecords,
} from '../../app-common/Insights/selectors';
import {
  disableFilters,
  enableFilters,
  setInsightsComponents,
  setInsightsSelectedRecords,
  exportInsights,
} from '../../app-common/Insights/action';
import {getFilterDataByTab} from '../InsightsLogs/service';
import {InsightsLogs, InsightsDomainLogs, InsightsFqdnLogs, ResponseView} from '../index';

import './style.css';

const INSIGHTS_ITEM_KEYS = {
  [insightsTabs.DOMAIN]: 'domain',
  [insightsTabs.TLD]: 'name',
  [insightsTabs.CATEGORY]: 'category',
  [insightsTabs.RECORD_TYPE]: 'recordType',
};

class InsightsTable extends React.Component {
  constructor(props) {
    super(props);
    this.componentRendererMap = {
      [COMPONENTS.INSIGHTS]: this.getTable,
      [COMPONENTS.DOMAINS]: this.getDomains,
      [COMPONENTS.FQDNS]: this.getFqdns,
      [COMPONENTS.LOGS]: this.getLogs,
      [COMPONENTS.INSPECT_RESPONSE]: this.getResponse,
    };
    this.componentDetailsRendererMap = {
      [COMPONENTS.INSIGHTS]: this.getInsightsDetails,
      [COMPONENTS.DOMAINS]: this.getDomainsDetails,
      [COMPONENTS.FQDNS]: this.getFqdnsDetails,
      [COMPONENTS.LOGS]: this.getLogsDetails,
      [COMPONENTS.INSPECT_RESPONSE]: () => null,
    };
    this.componentExportMap = {
      [COMPONENTS.INSIGHTS]: this.exportInsights,
      [COMPONENTS.DOMAINS]: this.exportInsightsDomains,
      [COMPONENTS.FQDNS]: this.exportInsightsFqdns,
      [COMPONENTS.LOGS]: this.exportInsightsLogs,
      [COMPONENTS.INSPECT_RESPONSE]: () => null,
    };

    this.actionsCellRenderer = (row) => {
      const data = {
        ..._get(row, 'original', {}),
        index: row.index,
      };
      return (
        <IconMenu
          icon={<MoreVert />}
          listPlacement="right-start"
          listItems={[
            {
              label: I18n.t('insights.rowAction.showDomains'),
              id: COMPONENTS.DOMAINS,
              onClick: () => this.openComponent(data, COMPONENTS.DOMAINS),
            },
            {
              label: I18n.t('insights.rowAction.showFqdns'),
              id: COMPONENTS.FQDNS,
              onClick: () => this.openComponent(data, COMPONENTS.FQDNS),
            },
            {
              label: I18n.t('insights.rowAction.showLogs'),
              id: COMPONENTS.LOGS,
              onClick: () => this.openComponent(data, COMPONENTS.LOGS),
            },
          ]}
        />
      );
    };
  }

  get columns() {
    return [
      {
        accessor: 'actions',
        filterable: false,
        sortable: false,
        width: 70,
        Cell: this.actionsCellRenderer,
      },
      ...this.props.columns,
    ];
  }

  exportInsights = () => {
    const {tab} = this.props;
    this.props.exportInsights(tab);
  };

  exportInsightsDomains = (currentComponent) => {
    const {tab, selectedRecords} = this.props;
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSIGHTS, {}),
      ..._get(selectedRecords, COMPONENTS.DOMAINS, {}),
      ..._get(selectedRecords, COMPONENTS.FQDNS, {}),
    };
    const filterData = getFilterDataByTab(tab, record);

    this.props.exportInsights(tab, currentComponent.key, filterData);
  };

  exportInsightsFqdns = (currentComponent) => {
    const {tab, selectedRecords} = this.props;
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSIGHTS, {}),
      ..._get(selectedRecords, COMPONENTS.DOMAINS, {}),
      ..._get(selectedRecords, COMPONENTS.FQDNS, {}),
    };
    const filterData = getFilterDataByTab(tab, record);

    this.props.exportInsights(tab, currentComponent.key, filterData);
  };

  exportInsightsLogs = (currentComponent) => {
    const {tab, selectedRecords} = this.props;
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSIGHTS, {}),
      ..._get(selectedRecords, COMPONENTS.DOMAINS, {}),
      ..._get(selectedRecords, COMPONENTS.FQDNS, {}),
    };
    const filterData = getFilterDataByTab(tab, record);

    // we need no limit on rows for export, unless backend has it
    filterData.rows = 100000;
    this.props.exportInsights(tab, currentComponent.key, filterData);
  };

  getComponents = () => {
    const components = [];
    const breadcrumbsItems = [];
    const {components: propsComponents, tab} = this.props;

    propsComponents.forEach((id) => {
      const component = id ? this.componentRendererMap[id]() : null;
      const details = id ? this.componentDetailsRendererMap[id]() : '';

      // eslint-disable-next-line no-shadow
      let name;
      if (id === COMPONENTS.INSIGHTS) {
        name = I18n.t(`insights.breadcrumbs.${tab}`);
      } else {
        name = I18n.t(`insights.breadcrumbs.${id}`);
      }

      breadcrumbsItems.push({id, details, name});
      components.push(<div className="insights-table__components" key={id}>{component}</div>);
    });

    return {components, breadcrumbsItems};
  };

  getInsightsDetails = () => {
    const {tab, selectedRecords} = this.props;

    const key = INSIGHTS_ITEM_KEYS[tab];
    return _get(selectedRecords, [COMPONENTS.INSIGHTS, key], '');
  };

  getDomainsDetails = () =>
    _get(this.props.selectedRecords, [COMPONENTS.DOMAINS, 'parentDomain'], null);

  getFqdnsDetails = () => _get(this.props.selectedRecords, [COMPONENTS.FQDNS, 'domain'], null);

  getLogsDetails = () => _get(this.props.selectedRecords, [COMPONENTS.LOGS, 'queryID'], '');

  getTable = () => {
    const {data, columns} = this.props;

    if (!columns) return null;

    const tableStyle = {
      flexGrow: 1,
      height: 'calc(100vh - 235px)',
      minHeight: '300px',
    };
    const tableSortingProps = [{
      id: 'count',
      desc: true,
    }];
    return (
      <TableWithPaginationAndFiltration
        columns={this.columns}
        data={data}
        pageSize={data.length}
        showPagination={false}
        defaultSorted={tableSortingProps}
        style={tableStyle}
      />
    );
  };

  getDomains = () => {
    const {tab, selectedRecords} = this.props;

    const showLogs = (record) => this.openComponent(record, COMPONENTS.LOGS);
    const showFqdns = (record) => this.openComponent(record, COMPONENTS.FQDNS);
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSIGHTS, {}),
    };
    const filterData = getFilterDataByTab(tab, record);
    return (
      <InsightsDomainLogs
        showLogs={showLogs}
        showFqdns={showFqdns}
        filterData={filterData}
        tab={tab}
      />
    );
  };

  getFqdns = () => {
    const {tab, selectedRecords} = this.props;
    const showLogs = (record) => this.openComponent(record, COMPONENTS.LOGS);
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSIGHTS, {}),
      ..._get(selectedRecords, COMPONENTS.DOMAINS, {}),
    };
    const filterData = getFilterDataByTab(tab, record);
    return (
      <InsightsFqdnLogs
        showLogs={showLogs}
        filterData={filterData}
        tab={tab}
      />
    );
  };

  getLogs = () => {
    const {selectedRecords, tab} = this.props;
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSIGHTS, {}),
      ..._get(selectedRecords, COMPONENTS.DOMAINS, {}),
      ..._get(selectedRecords, COMPONENTS.FQDNS, {}),
    };
    const filterData = getFilterDataByTab(tab, record);
    const showResponse = (r) => this.openComponent(r, COMPONENTS.INSPECT_RESPONSE);
    return (
      <InsightsLogs
        total={record.count}
        filterData={filterData}
        tab={tab}
        showResponse={showResponse}
      />
    );
  };

  getResponse = () => {
    const {selectedRecords} = this.props;
    const record = {
      ..._get(selectedRecords, COMPONENTS.LOGS, {}),
    };
    return <ResponseView logData={record} />;
  };

  openComponent = (record, id) => {
    const {components, tab, selectedRecords} = this.props;
    const selectedRecordsFromProps = _cloneDeep(selectedRecords);
    const currentComponent = components[components.length - 1];
    selectedRecordsFromProps[currentComponent] = record;
    components.push(id);
    this.props.disableFilters(tab);
    this.props.setInsightsComponents(tab, components);
    this.props.setInsightsSelectedRecords(tab, selectedRecordsFromProps);
  };

  goToComponent = (id) => {
    const {components, tab, selectedRecords} = this.props;
    const componentIndex = components.findIndex((c) => c === id);
    const currentComponents = components.slice(0, componentIndex + 1);
    const removedComponents = components.slice(componentIndex + 1, components.length);

    if (componentIndex === 0) {
      this.props.enableFilters(tab);
    }
    const prevSelectedRecords = _cloneDeep(selectedRecords);

    removedComponents.forEach((rc) => {
      prevSelectedRecords[rc] = undefined;
    });

    prevSelectedRecords[id] = undefined;
    const currentSelectedRecords = _pick(prevSelectedRecords, components);
    this.props.setInsightsSelectedRecords(tab, currentSelectedRecords);
    this.props.setInsightsComponents(tab, currentComponents);
  };

  render() {
    const {components, breadcrumbsItems} = this.getComponents();

    const currentComponent = components[components.length - 1];

    const onExport = this.componentExportMap[currentComponent.key];

    return (
      <Breadcrumbs
        items={breadcrumbsItems}
        goToComponent={this.goToComponent}
        action={(
          <RenderDivOrEmpty isShow={currentComponent.key !== COMPONENTS.INSPECT_RESPONSE}>
            <CustomButton
              color="secondaryNoBackground"
              customClasses="filtersPanel__button_export"
              onClick={() => onExport(currentComponent)}
              size="sm"
            >
              <div className="insights-table__export-button-container">
                <Export />
                {I18n.t('buttons.export').toUpperCase()}
              </div>
            </CustomButton>
          </RenderDivOrEmpty>
        )}
      >
        {components}
      </Breadcrumbs>
    );
  }
}

InsightsTable.propTypes = {
  tab: PropTypes.oneOf(Object.values(TABS)).isRequired,
  data: PropTypes.array.isRequired,
  components: PropTypes.array.isRequired,
  selectedRecords: PropTypes.object.isRequired,
  exportInsights: PropTypes.func.isRequired,
  columns: PropTypes.array,
  disableFilters: PropTypes.func.isRequired,
  enableFilters: PropTypes.func.isRequired,
  setInsightsComponents: PropTypes.func.isRequired,
  setInsightsSelectedRecords: PropTypes.func.isRequired,
};

InsightsTable.defaultProps = {
  columns: null,
};

const mapStateToProps = (state, props) => ({
  data: getInsightsData(props.tab)(state),
  components: getInsightsComponents(props.tab)(state),
  selectedRecords: getInsightsSelectedRecords(props.tab)(state),
});

const mapDispatchToProps = {
  enableFilters,
  disableFilters,
  setInsightsComponents,
  setInsightsSelectedRecords,
  exportInsights,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsTable);

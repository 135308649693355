import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {EntityEditingCard, LabelWithCopyButton} from '../index';

import {SingleSignOnEditForm} from '../../containers/index';

import {ACCESS_RIGHTS} from '../../constants';

import {
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import style from './style';

const rightAvailabilityMap = {
  buttonDelete: [ACCESS_RIGHTS.SINGLE_SIGN_ON_CONFIGURATION_DELETE],
  buttonEdit: [ACCESS_RIGHTS.SINGLE_SIGN_ON_CONFIGURATION_EDIT],
};

function SingleSignOnDetails(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonEdit,
    handleClickButtonSave,
    handleClickButtonDelete,
    isValidSingleSignOnConfiguration,
    isEditMode,
    title,
    classes,
    loginURL,
  } = props;

  return (
    <EntityEditingCard
      title={title}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonEdit={handleClickButtonEdit}
      handleClickButtonSave={handleClickButtonSave}
      handleClickButtonDelete={handleClickButtonDelete}
      isValidEntity={isValidSingleSignOnConfiguration}
      isEditMode={isEditMode}
      isThereEditBlock={true}
      deleteModalWindowText={I18n.t('modalWindow.deleteSingleSignOnConfiguration')}
      deleteButtonText={I18n.t('configureSingleSignOnPage.buttons.deleteSingleSignOn')}
      editButtonText={I18n.t('configureSingleSignOnPage.buttons.editSingleSignOn')}
      isThereDeleteBlock={true}
      accessRights={rightAvailabilityMap}
      customCardContentClass="card__content_block-page"
      content={(
        <>
          <div className={classes.ssoDetails__loginURL}>
            <CustomInput
                labelText={I18n.t('configureSingleSignOnPage.labels.loginURL')}
                formControlProps={{
                  fullWidth: true,
                }}
                helpText={I18n.t('configureSingleSignOnPage.help.loginURL')}
                inputProps={{
                  disabled: true,
                  value: loginURL,
                  name: 'loginURL',
                }}
            />
            <LabelWithCopyButton
                id="copy-login-url"
                text={loginURL}
                showHeader={false}
                showText={false}
                snackbarMessage={I18n.t('configureSingleSignOnPage.copyLoginURLToBufferMessage')}
            />
          </div>
          <SingleSignOnEditForm isEditForm={true} />
        </>
      )}
    />
  );
}

SingleSignOnDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isValidSingleSignOnConfiguration: PropTypes.bool.isRequired,
  loginURL: PropTypes.string.isRequired,
};

export default withStyles(style)(SingleSignOnDetails);

import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';

import {TAGS} from '../../constants';

export function getWhiteBlackListInfo(whiteBlackList) {
  return {
    id: whiteBlackList.id,
    name: whiteBlackList.name,
    description: whiteBlackList.description,
    allowed: getDomainsAsString(whiteBlackList.allowed),
    blocked: getDomainsAsString(whiteBlackList.blocked),
    isGlobal: _includes(whiteBlackList.tags, TAGS.GLOBAL_WHITE_BLACK_LIST),
    organizationId: whiteBlackList.organizationId,
    tags: whiteBlackList.tags,
  };
}

function getDomainsAsString(domainsArray) {
  if (domainsArray.length > 0) {
    domainsArray.sort((domain1, domain2) =>
      getDomainToCompare(domain1).localeCompare(getDomainToCompare(domain2)));
    let domains = '';
    const indexOfLastItem = domainsArray.length - 1;
    for (let i = 0; i < indexOfLastItem; i++) {
      domains = `${domains}${domainsArray[i]}\n`;
    }
    return `${domains}${domainsArray[indexOfLastItem]}`;
  }
  return '';
}

function getDomainToCompare(domain) {
  if (_isEqual(domain.substring(0, 2), '*.')) {
    return domain.substring(2, domain.length);
  }
  return domain;
}

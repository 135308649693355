/* eslint-disable max-len */
import {
  defaultFont,
  secondaryColor,
} from '../../styles/style';

const style = {
  subscription: {
    'display': 'grid',
    'gridTemplateColumns': '1fr 1fr 1fr 1fr',
    'gridColumnGap': '20px',
    'gridRowGap': '10px',
    'marginBottom': '20px',
    '@media (max-width: 1100px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media (max-width: 640px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (max-width: 480px)': {
      gridTemplateColumns: '1fr',
    },
  },
  subscription__contact: {
    ...defaultFont,
    marginTop: '10px',
    fontSize: '16px',
  },
  link: {
    'color': `${secondaryColor}!important`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export default style;

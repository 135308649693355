import {types} from './constants';
import {get, makeUrl, post} from '../../utils/fetcher';

import {METHODS, STATES_ENTITY} from '../../constants';
import {
  getClientProfilesData,
  getPlatforms,
  getProfileToCreate,
  getDataToAssignBlockPageToProfile,
} from './utils';
import {loadClientLocalDomains} from '../ClientProfileLocalForwardingForm/action';

export const loadPlatforms = () => {
  const url = makeUrl(METHODS.GET_PLATFORMS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_PLATFORMS,
          platforms: getPlatforms(response.data),
        });
      }
    });
};

export const loadProfiles = () => {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILES);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_PROFILES,
          profiles: getClientProfilesData(response.data),
        });
      }
    });
};

export const loadInstallers = () => {
  const url = makeUrl(METHODS.GET_CLIENT_INSTALLERS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_INSTALLERS,
          installers: response.data,
        });
      }
    });
};

export function getClientProfilePolicies(profileId) {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILE_POLICIES, {profileId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_CLIENT_PROFILE_POLICIES,
            data,
          });
        }
      });
}

export const resetClientsConfigurerData = () => (dispatch) => dispatch({
  type: types.RESET_CLIENT_CONFIGURER_DATA,
});

export function getProfileById(profileId) {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILE_BY_ID, {id: profileId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_SELECTED_CLIENT_PROFILE,
            profile: response.data,
          });
        }
      });
}

export function copyProfile(profileId, currentProfile) {
  const url = makeUrl(METHODS.GET_CLIENT_PROFILE_BY_ID, {id: profileId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch(loadClientLocalDomains(profileId));
          dispatch({
            type: types.SET_SELECTED_CLIENT_PROFILE_COPY,
            profile: response.data,
            currentProfile,
          });
        }
      });
}

export function createProfile(newProfile, localDomains = []) {
  const url = makeUrl(METHODS.CREATE_CLIENT_PROFILE);
  const data = getProfileToCreate(newProfile, localDomains);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndProfileState(false, STATES_ENTITY.NONE));
          dispatch(loadProfiles());
          return {
            isSuccess: true,
            profileId: response.data.id,
          };
        }
        return {
          isSuccess: false,
        };
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to create roaming client profile:', error);
      });
}

export function updateProfile(updatedProfile) {
  const url = makeUrl(METHODS.UPDATE_CLIENT_PROFILE);
  const data = updatedProfile;
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(changeEditModeAndProfileState(false, STATES_ENTITY.NONE));
          dispatch(loadProfiles()).then(() => dispatch({
            type: types.SET_UPDATED_CLIENT_PROFILE,
            profile: response.data,
          }));
          return {
            isSuccess: true,
            profileId: response.data.id,
          };
        }
        return {
          isSuccess: false,
        };
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to edit roaming client profile:', error);
      });
}

export function deleteProfile(profileId) {
  const data = {
    id: profileId,
  };
  const url = makeUrl(METHODS.DELETE_CLIENT_PROFILE);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          dispatch(loadProfiles());
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to delete roaming client profile:', error);
      });
}

export function changeEditModeAndProfileState(isEditMode, profileState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_CLIENT_PROFILE_STATE,
      isEditMode,
      profileState,
    });
  };
}

export function getDataToShowProfileDetails(profileId, profileIndex) {
  return (dispatch) => {
    dispatch(getProfileById(profileId));
    dispatch(setSelectedProfileIndex(profileIndex));
  };
}

export function setSelectedProfileIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CLIENT_PROFILE_INDEX,
      selectedProfileIndex: index,
    });
  };
}

export function resetSelectedProfileIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_CLIENT_PROFILE_INDEX,
    });
  };
}

export function assignBlockPageToProfile(blockPageId, profileId) {
  const url = makeUrl(METHODS.ASSIGN_BLOCK_PAGE_TO_PROFILE);
  const data = getDataToAssignBlockPageToProfile(blockPageId, profileId);
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => {
        if (response && response.success) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to assign block page to client profile:', error);
      });
}

export const types = {
  SET_AVERAGE_LATENCY: 'SET_AVERAGE_LATENCY',
  SET_CATEGORY_GROUPS_STATS: 'SET_CATEGORY_GROUPS_STATS',
  SET_CAMPUSES_STATS: 'SET_CAMPUSES_STATS',
  SET_REQUESTS_PER_NETWORK: 'SET_REQUESTS_PER_NETWORK',
  SET_TOP_5_CATEGORIES: 'SET_5_CATEGORIES',
  SET_TOP_5_DOMAINS: 'SET_TOP_5_DOMAINS',
  SET_TOTAL_REQUEST_BY_DECISION: 'SET_TOTAL_REQUEST_BY_DECISION',
  SET_USER_REQUESTS_GRAPH: 'SET_USER_REQUESTS_GRAPH',
  SET_DASHBOARD_CLIENT_COUNT_STATS: 'SET_DASHBOARD_CLIENT_COUNT_STATS',
  SET_DASHBOARD_CLIENT_THREATS: 'SET_DASHBOARD_CLIENT_THREATS',
};

export const widgetKeys = {
  REQUEST_STATS: 'mainDashboard_requestStats',
  CLIENT_STATS: 'mainDashboard_clientStats',
  THREAT_STATS: 'mainDashboard_threatStats',
  CAMPUSES_STATS: 'mainDashboard_campusStats',
  PERFORMANCE_STATS: 'mainDashboard_performanceStats',
  TOP_DOMAINS_ALLOWED: 'mainDashboard_topDomainsAllowed',
  TOP_DOMAINS_FORBIDDEN: 'mainDashboard_topDomainsForbidden',
  TOP_CATEGORIES_ALLOWED: 'mainDashboard_topCategoriesAllowed',
  TOP_CATEGORIES_FORBIDDEN: 'mainDashboard_topCategoriesForbidden',
  REQUESTS_PER_NETWORK: 'mainDashboard_requestsPerNetwork',
  BLOCKED_STATS: 'mainDashboard_blockedStats',
};

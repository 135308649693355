import React, {PureComponent} from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';
import _isUndefined from 'lodash/isUndefined';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {RenderOrEmpty} from '../index';

import {Check} from '../../icons/index';
import style from './style';

class MultiDropdown extends PureComponent {
  getOptionsAsMenuItems = () => {
    const {
      classes,
      options,
      selectedItemsIndexes,
      readOnlyItemIcon,
    } = this.props;
    const menuItemClassesGetter = (item) => ({
      root: classes.multiDropdown__option,
      selected: (_get(item, 'readOnly', false))
        ? classes.multiDropdown__option_readOnly
        : classes.multiDropdown__option_selected,
    });
    const listItemTextClasses = {
      root: classes.multiDropdownOption__text,
      primary: classes.multiDropdownOption__text_primary,
    };
    const checkboxClasses = {
      default: classes.multiDropdownOption__checkbox,
      checked: classes.multiDropdownOption__checkbox_checked,
    };
    return options.map((item, index) => {
      const isReadOnly = _get(item, 'readOnly', false);
      return (
        <MenuItem
          classes={menuItemClassesGetter(item)}
          key={uuid()}
          value={`${index}`}
        >
          <RenderOrEmpty isShow={!isReadOnly}>
            <Checkbox
              classes={checkboxClasses}
              checked={_includes(selectedItemsIndexes, index)}
              checkedIcon={<Check className={classes.multiDropdownOptionCheckbox__icon_checked} />}
              icon={<Check className={classes.multiDropdownOptionCheckbox__icon_unchecked} />}
            />
          </RenderOrEmpty>
          <RenderOrEmpty isShow={isReadOnly}>
            <Checkbox
              classes={checkboxClasses}
              checked={true}
              disabled={true}
              checkedIcon={<Check className={classes.multiDropdownOptionCheckbox__icon_checked} />}
              icon={<Check className={classes.multiDropdownOptionCheckbox__icon_unchecked} />}
            />
            {readOnlyItemIcon}
          </RenderOrEmpty>
          <ListItemText
            primary={item[this.props.displayedField]}
            classes={listItemTextClasses}
          />
        </MenuItem>
      );
    });
  };

  getSelectedValuesAsString = (selectedValues) => {
    const {options, placeholder} = this.props;
    if (_isEmpty(selectedValues)) {
      return placeholder;
    }
    return selectedValues.map((index) => options[index][this.props.displayedField]).join(', ');
  };

  handleClickOnMenuItem = (e) => {
    const {selectedItemsIndexes, options} = this.props;
    const newIndexes = e.target.value.map((item) => parseInt(item, 10));
    const oldReadonlyIndexes = selectedItemsIndexes
      .filter((idx) => _get(options[idx], 'readOnly', false));
    const newNonReadOnlyIndexes = newIndexes
      .filter((idx) => !_get(options[idx], 'readOnly', false));
    this.props.onChangeValue({
      ...e,
      target: {
        ...e.target,
        value: [...newNonReadOnlyIndexes, ...oldReadonlyIndexes],
      },
    });
  };

  render() {
    const {
      classes,
      multiDropdownName,
      disabled,
      fullWidth,
      label,
      selectedItemsIndexes,
      customLabelClass,
      customSelectClass,
    } = this.props;
    const formControlProps = {
      fullWidth: true,
    };

    const inputProps = {
      disabled: disabled,
      type: 'text',
      value: this.getSelectedValuesAsString(selectedItemsIndexes),
    };
    return (
      <FormControl
        fullWidth={fullWidth}
        className={classes.multiDropdown__formControl}
        disabled={disabled}
      >
        <RenderOrEmpty isShow={disabled}>
          <CustomInput
            formControlProps={formControlProps}
            labelText={label}
            id={uuid()}
            inputProps={inputProps}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!disabled}>
          <RenderOrEmpty isShow={label !== undefined}>
            <InputLabel
              className={classNames(
                classes.multiDropdown__label,
                {
                  [classes[customLabelClass]]: !_isUndefined(customLabelClass),
                },
              )}
              htmlFor="multiDropdown-component"
            >
              {label}
            </InputLabel>
          </RenderOrEmpty>
          <Select
            MenuProps={{
              className: classes.multiDropdown__menu,
            }}
            multiple={true}
            classes={{
              select: classNames(
                classes.multiDropdown__select,
                {
                  [classes[customSelectClass]]: !_isUndefined(customSelectClass),
                },
              ),
            }}
            onChange={this.handleClickOnMenuItem}
            value={selectedItemsIndexes.map((item) => item.toString())}
            displayEmpty={true}
            renderValue={(selected) => (
              <p className={classes.multiDropdownSelect__renderValue}>
                {this.getSelectedValuesAsString(selected)}
              </p>
            )}
            inputProps={{
              name: multiDropdownName,
              id: 'multiDropdown-component',
            }}
          >
            {this.props.options.length === 0 && (
              <MenuItem value="" disabled={true}>
                {I18n.t('components.multiDropdown.noOptions')}
              </MenuItem>
            )}
            {this.getOptionsAsMenuItems()}
          </Select>
        </RenderOrEmpty>
      </FormControl>
    );
  }
}

MultiDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  multiDropdownName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  selectedItemsIndexes: PropTypes.array,
  onChangeValue: PropTypes.func.isRequired,
  customLabelClass: PropTypes.string,
  customSelectClass: PropTypes.string,
  placeholder: PropTypes.string,
  readOnlyItemIcon: PropTypes.node,
  displayedField: PropTypes.string,
};

MultiDropdown.defaultProps = {
  multiDropdownName: 'multiDropdown',
  disabled: false,
  fullWidth: false,
  label: undefined,
  options: [],
  selectedItemsIndexes: [1],
  customLabelClass: undefined,
  customSelectClass: undefined,
  placeholder: '',
  readOnlyItemIcon: null,
  displayedField: 'name',
};

export default withStyles(style)(MultiDropdown);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

import {
  Breadcrumbs,
  ButtonExpandLessMore,
  LogOperatorFiltersPanel,
  LogTable,
  RenderOrEmpty,
} from '../../components';

import {ResponseView} from '../../containers';

import {NoData} from '../index';

import {DATE_FORMAT} from '../../constants';
import {
  LOG_TABLE_HEADERS_LOCAL_STORAGE_KEY,
  LIMIT_LOAD_DATA,
  MIN_TABLE_ROWS,
  widgetKeys,
} from './constants';

import {
  getAuditCsv,
  getLogs,
  loadMoreLogs,
  getLoggingFiltersPanelData,
  resetPageData,
} from './action';
import {cancelRequests} from '../../containers/Loading/action';
import {getDateTimeFilterValue, getTimezone} from './service';

import {requestWbLists} from '../../containers/WbListActionsContainer/action';

import './style.css';

const COMPONENTS = {
  LOGS: 'logs',
  INSPECT_RESPONSE: 'response',
};

class Log extends Component {
  static initialFilter = {
    date: moment().format(DATE_FORMAT.MMDDYYYY_RIGHT_SLASH),
    campus: 0,
    timeStart: '12:00 AM',
    timeEnd: '11:59 PM',
    policy: 0,
    filterType: 0,
    category: 0,
    decision: 0,
    domain: '',
    networkIP: '',
    subnetIP: '',
    client: 0,
    isShowAdditionalFilters: false,
    validation: {
      networkIP: true,
      subnetIP: true,
    },
    timezone: getTimezone(),
    organization: 0,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.componentRendererMap = {
      [COMPONENTS.LOGS]: this.getLogs,
      [COMPONENTS.INSPECT_RESPONSE]: this.getResponse,
    };
    this.state = {...this.getInitialLocalState()};
  }

  componentDidMount() {
    this.props.getLoggingFiltersPanelData(
      _get(this.props.accountInfo, 'organizationsAllowed', false),
      _get(this.props.accountInfo, 'deviceAgentAllowed', false),
    );
  }

  componentDidUpdate(prevProps) {
    if (Log.wasDataScopeSwitched(prevProps, this.props)) {
      this.interruptRequests();
      this.props.resetPageData();
      this.resetLocalState();
      this.props.getLoggingFiltersPanelData(
        _get(this.props.accountInfo, 'organizationsAllowed', false),
        _get(this.props.accountInfo, 'deviceAgentAllowed', false),
      );
      this.props.requestWbLists();
    }
  }

  componentWillUnmount() {
    this.interruptRequests();
    this.props.resetPageData();
  }

  get components() {
    return this.state.components.map((c) => {
      const id = c.id;
      const component = id ? this.componentRendererMap[id]() : null;
      return (
        <div className="logPage__components" key={id}>
          {component}
        </div>
      );
    });
  }

  getLogs = () => {
    const {tableData, accountInfo} = this.props;
    const {
      isShowFilters,
      isShowAdditionalFilters,
    } = this.state;

    const tableStyles = {
      height: isShowFilters ? 'calc(100vh - 472px)' : 'calc(100vh - 182px)',
    };
    const tableClassNames = {
      tbody: [{
        className: 'tbodyInfinite_additionalOptionsIsOpen',
        condition: isShowAdditionalFilters && isShowFilters,
      }],
    };
    const showResponse = (r) => this.openComponent(r, COMPONENTS.INSPECT_RESPONSE);

    return (
      <LogTable
        customHeadersLocalStorageKey={LOG_TABLE_HEADERS_LOCAL_STORAGE_KEY}
        fullScreenMode={!isShowFilters}
        accountInfo={accountInfo}
        headerActionNode={(
          <ButtonExpandLessMore
            customClasses="logTable__showHideLogFiltersButton"
            isExpandLess={isShowFilters}
            onClick={this.showHideFilters}
            text={isShowFilters
              ? I18n.t('logPage.table.hideFiltersButton')
              : I18n.t('logPage.table.showFiltersButton')}
          />
        )}
        hasHeadersPicker={true}
        minRows={MIN_TABLE_ROWS}
        tableClassNames={tableClassNames}
        tableData={tableData}
        tableStyles={tableStyles}
        loadData={this.loadMoreData}
        showResponse={showResponse}
      />
    );
  };

  getResponse = () => {
    const {selectedRecords} = this.state;
    const record = {
      ..._get(selectedRecords, COMPONENTS.INSPECT_RESPONSE, {}),
    };
    return <ResponseView logData={record} />;
  };

  // eslint-disable-next-line
  getInitialLocalState = () => ({
    currentFilter: Log.initialFilter,
    filterForRequest: {
      time: Log.initialFilter.date,
      from: getDateTimeFilterValue(Log.initialFilter.date, Log.initialFilter.timeStart),
      till: getDateTimeFilterValue(Log.initialFilter.date, Log.initialFilter.timeEnd),
      timezone: getTimezone(),
    },
    isShowFilters: true,
    isShowAdditionalFilters: false,
    components: [{
      id: COMPONENTS.LOGS,
      name: I18n.t(`logPage.breadcrumbs.${COMPONENTS.LOGS}`),
    }],
    selectedRecords: {},
  });

  interruptRequests = (includePage = true) => {
    this.props.cancelRequests(Object.values(widgetKeys), includePage);
  };

  resetLocalState = () => this.setState({...this.getInitialLocalState()});

  handleSubmit = (filterForRequest) => {
    this.interruptRequests(false);
    this.props.getLogs({
      ...filterForRequest,
      limit: LIMIT_LOAD_DATA,
    });
  };

  handleExport = (filterForRequest) => {
    this.props.getAuditCsv(filterForRequest);
  };

  handleReset = () => this.setState({
    currentFilter: Log.initialFilter,
  });

  handleChangeFilter = (filterName, value) => {
    const {currentFilter} = this.state;

    this.setState({
      currentFilter: {
        ...currentFilter,
        [filterName]: value,
      },
    });
  };

  handleChangeFilterForRequest = (newFilter) => {
    this.setState({
      filterForRequest: newFilter,
    });
  };

  loadMoreData = () => {
    const {tableData} = this.props;
    const {filterForRequest} = this.state;

    const countLastLoadItems = tableData.length % LIMIT_LOAD_DATA;
    if (_isEqual(countLastLoadItems, 0)) {
      this.props.loadMoreLogs({
        ...filterForRequest,
        offset: tableData.length,
        limit: LIMIT_LOAD_DATA,
      });
    }
  };

  showHideFilters = () => {
    this.setState((prevState) => ({
      isShowFilters: !prevState.isShowFilters,
    }));
  };

  showHideAdditionalFilters = () => {
    this.setState((prevState) => ({
      isShowAdditionalFilters: !prevState.isShowAdditionalFilters,
    }));
  };

  goToComponent = (id) => this.setState((prevState) => {
    const components = _cloneDeep(prevState.components);
    const componentIndex = components.findIndex((c) => c.id === id);

    return {components: components.slice(0, componentIndex + 1)};
  });

  openComponent = (record, id) => this.setState((prevState) => {
    const components = _cloneDeep(prevState.components);
    const selectedRecords = _cloneDeep(prevState.selectedRecords);
    components.push({
      id,
      name: I18n.t(`logPage.breadcrumbs.${id}`),
    });
    selectedRecords[id] = record;
    return {components, selectedRecords};
  });

  setValidation = (fieldName, value) => {
    this.setState((prevState) => {
      const modifiedFilter = _cloneDeep(prevState.currentFilter);
      modifiedFilter.validation[fieldName] = value;
      return {currentFilter: modifiedFilter};
    });
  };

  render() {
    const {
      accountInfo,
      campuses,
      loggedAccount,
      currentOrganization,
      policies,
      clients,
      organizations,
    } = this.props;

    const {
      currentFilter,
      isShowFilters,
      isShowAdditionalFilters,
    } = this.state;

    const maxAuditQueryDays = _get(accountInfo, 'maxAuditQueryDays', null);
    return (
      <>
        <RenderOrEmpty isShow={!!maxAuditQueryDays}>
          <RenderOrEmpty isShow={isShowFilters}>
            <LogOperatorFiltersPanel
              campuses={campuses}
              currentFilter={currentFilter}
              initialFilter={Log.initialFilter}
              isShowAdditionalFilters={isShowAdditionalFilters}
              loggedAccount={loggedAccount}
              accountInfo={accountInfo}
              currentOrganization={currentOrganization}
              policies={policies}
              clients={clients}
              organizations={organizations}
              showHideAdditionalFilters={this.showHideAdditionalFilters}
              handleSubmit={this.handleSubmit}
              handleReset={this.handleReset}
              handleChangeFilter={this.handleChangeFilter}
              handleExport={this.handleExport}
              maxAuditQueryDays={maxAuditQueryDays}
              setValidation={this.setValidation}
              handleChangeFilterForRequest={this.handleChangeFilterForRequest}
            />
          </RenderOrEmpty>
          <Breadcrumbs
            items={this.state.components}
            goToComponent={this.goToComponent}
          >
            {this.components}
          </Breadcrumbs>
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!maxAuditQueryDays}>
          <NoData />
        </RenderOrEmpty>
      </>
    );
  }
}

Log.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  campuses: PropTypes.array.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  policies: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,

  getLogs: PropTypes.func.isRequired,
  getAuditCsv: PropTypes.func.isRequired,
  getLoggingFiltersPanelData: PropTypes.func.isRequired,
  resetPageData: PropTypes.func.isRequired,
  loadMoreLogs: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
  requestWbLists: PropTypes.func.isRequired,
};

Log.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
  campuses: state.campusReducer.campuses,
  policies: state.policyReducer.policies,
  clients: state.logReducer.clientNames,
  organizations: state.logReducer.organizations,
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,
  tableData: state.logReducer.tableData,
});

const mapDispatchToProps = (dispatch) => ({
  getAuditCsv: bindActionCreators(getAuditCsv, dispatch),
  getLogs: bindActionCreators(getLogs, dispatch),
  getLoggingFiltersPanelData: bindActionCreators(getLoggingFiltersPanelData, dispatch),
  resetPageData: bindActionCreators(resetPageData, dispatch),
  loadMoreLogs: bindActionCreators(loadMoreLogs, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
  requestWbLists: bindActionCreators(requestWbLists, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Log);

import {MODAL_WINDOW_NOTIFY_TYPE} from '../../../constants';

export function getNotificationBannerData(accountInfo) {
  return {
    statusType: `${accountInfo.type.toLowerCase()}_${accountInfo.status.toLowerCase()}`,
    untilExpirationSeconds: accountInfo.untilExpirationSeconds,
    accountStatus: accountInfo.status.toLowerCase(),
  };
}

export function getTextToDisplayInModalWindow(data, notifyType) {
  const typeHandlersMap = {
    [MODAL_WINDOW_NOTIFY_TYPE.ERROR]: getErrorText,
    [MODAL_WINDOW_NOTIFY_TYPE.INFO]: getInfoText,
  };
  const handler = typeHandlersMap[notifyType];
  return handler(data);
}

function getErrorText(data) {
  if (data.errorMessage) {
    return data.errorMessage;
  }
  if (data.message) {
    return data.message;
  }
}

function getInfoText(data) {
  return data;
}

/* eslint-disable max-len */
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';
import {
  defaultFont,
  primaryColor,
} from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react';
import {linkColor} from '../../styles/style';

const style = {
  loginForm2FA__errorMessage: {
    ...customInputStyle.labelRoot,
    ...customInputStyle.labelRootError,
    fontSize: '12px',
    textAlign: 'center',
  },

  loginForm2FA__sectionLinks: {
    textAlign: 'left',
  },
  loginForm2FA__linkItem: {
    margin: '5px 0',
  },
  loginForm2FA__link: {
    ...defaultFont,
    'color': linkColor,
    'fontSize': '16px',
    'fontWeight': '400',
    'cursor': 'pointer',
    'textDecoration': 'underline',
    '&:focus,&:hover': {
      color: linkColor,
      textDecoration: 'none',
    },
  },
  loginForm2FA__timer: {
    ...defaultFont,
    color: primaryColor,
    fontSize: '16px',
  },
  loginForm2FA__time: {
    ...defaultFont,
    color: primaryColor,
    fontWeight: '400',
  },

  loginForm2FA__sectionTrustDevice: {
    textAlign: 'center',
  },
  loginForm2FA__checkbox: {
    color: primaryColor,
  },
  loginForm2FA__checkbox_checked: {
    color: primaryColor,
  },
  loginForm2FA__checkboxFormControl: {
    margin: '5px 20px 0 0',
  },
  loginForm2FA__checkboxLabel: {
    fontSize: '16px',
  },
  loginForm2FA__checkboxIcon_checked: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  loginForm2FA__checkboxIcon_unchecked: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
};

export default style;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _without from 'lodash/without';
import {
  CustomInput,
} from '../../material-dashboard-pro-react/components';

import style from './style';

import {STATES_ENTITY} from '../../constants';
import {widgetKeys} from '../../app-common/Organizations/constants';
import {DESCRIPTION_MAX_LEN, DESCRIPTION_ROW_COUNT} from './constants';

import {getPolicies} from '../../app-common/Policy/action';
import {
  setEditableOrganizationInfo,
  resetOrganizationEditFormData,
  setValidationResultOrganizationInfo,
  updateOrganizationInfo,
  setSelectedPeriodIndex,
} from './action';
import {cancelRequests} from '../Loading/action';
import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

class OrganizationEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
    },
    dashboardShown: true,
  };

  static shouldUpdateOrganizationObject(prevProps, props) {
    return !_isEqual(prevProps.organization, props.organization);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.organizationState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = {...OrganizationEditForm.initialLocalState};
  }

  componentDidMount() {
    this.props.setEditableOrganizationsInfo(this.props.organization);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props.organization, nextProps.organization)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableOrganizationInfo, nextProps.editableOrganizationInfo)
      || !_isEqual(this.props.dashboardData, nextProps.dashboardData)
      || !_isEqual(this.props.selectedPeriodIndex, nextProps.selectedPeriodIndex)
      || !_isEqual(this.state.dashboardShown, nextState.dashboardShown)
      || !_isEqual(this.state.activeTabIndex, nextState.activeTabIndex);
  }

  componentDidUpdate(prevProps) {
    if (OrganizationEditForm.shouldUpdateOrganizationObject(prevProps, this.props)
      || OrganizationEditForm.shouldResetFormData(prevProps, this.props)
    ) {
      this.props.setEditableOrganizationsInfo(this.props.organization);
    }
  }

  componentWillUnmount() {
    this.props.resetOrganizationsEditFormData();
    this.interruptStatsRequests();
  }

  handleChangeInput = (e) => {
    const {editableOrganizationInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableOrganizationInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateOrganizationsInfo(
      {
        ...editableOrganizationInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  interruptStatsRequests = (includeNetworkStats = false) => {
    const values = Object.values(widgetKeys);
    const selectedValues = (includeNetworkStats)
      ? values
      : _without(values, widgetKeys.ORGANIZATION_NETWORK_STATS);
    this.props.cancelRequests(selectedValues, false);
  };

  validate = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
    ],
    data,
    {
      name: true,
    },
  );

  render() {
    const {
      classes,
      editableOrganizationInfo,
      isEditMode,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      name: {
        disabled: !isEditMode,
        value: editableOrganizationInfo.name,
        name: 'name',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      description: {
        disabled: !isEditMode,
        value: editableOrganizationInfo.description,
        name: 'description',
        onChange: this.handleChangeInput,
        type: 'text',
        multiline: true,
        disableUnderline: true,
        rows: DESCRIPTION_ROW_COUNT,
        max: DESCRIPTION_MAX_LEN,
        classes: {
          input: classes.form__input,
          root: (!isEditMode)
            ? classes.form__inputRoot_description_view
            : classes.form__inputRoot_description_edit,
          focused: classes.form__inputRoot_description_focused,
        },
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };
    const labelProps = {
      description: {
        classes: {
          root: classes.form__formControl_description,
          shrink: classes.form__shrink_description,
        },
      },
    };

    return (
      <div className={classes.form}>
        <div className={classes.form__item}>
          <CustomInput
            errorText={validation.name
              ? undefined
              : I18n.t('organizations.errorMessages.organizationNameIsNotValid')}
            error={!validation.name}
            labelText={I18n.t('organizations.editForm.labels.name')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.name}
          />
        </div>
        <div className={classes.form__item}>
          <CustomInput
            labelText={I18n.t('organizations.editForm.labels.description')}
            id={uuid()}
            formControlProps={propsFormControl}
            inputProps={propsInput.description}
            labelProps={labelProps.description}
          />
        </div>
      </div>
    );
  }
}

OrganizationEditForm.propTypes = ({
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  organizationState: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  editableOrganizationInfo: PropTypes.object.isRequired,
  selectedPeriodIndex: PropTypes.number.isRequired,
  dashboardData: PropTypes.object.isRequired,

  resetOrganizationsEditFormData: PropTypes.func.isRequired,
  setEditableOrganizationsInfo: PropTypes.func.isRequired,
  setValidationResultOrganizationsInfo: PropTypes.func.isRequired,
  updateOrganizationsInfo: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  // getDashboardData: PropTypes.func.isRequired,
  setSelectedPeriodIndex: PropTypes.func.isRequired,
  cancelRequests: PropTypes.func.isRequired,
});

const mapStateToProps = (state) => ({
  organization: state.organizationsReducer.selectedOrganization,
  organizationState: state.organizationsReducer.organizationState,
  isEditMode: state.organizationsReducer.isEditMode,
  profiles: state.organizationsReducer.profileNames,

  editableOrganizationInfo: state.organizationEditFormReducer.editableOrganizationInfo,
  selectedPeriodIndex: state.organizationEditFormReducer.selectedPeriodIndex,

  policies: state.policyReducer.policies,

  dashboardData: {
    averageLatency: state.organizationEditFormReducer.averageLatency,
    requestsPerSecond: state.organizationEditFormReducer.requestsPerSecond,
    requestsByDecision: state.organizationEditFormReducer.requestsByDecision,
    userRequestsGraph: state.organizationEditFormReducer.userRequestsGraph,
    threatStats: state.organizationEditFormReducer.threatStats,
  },
});

const mapDispatchToProps = (dispatch) => ({
  resetOrganizationsEditFormData: bindActionCreators(resetOrganizationEditFormData, dispatch),
  setEditableOrganizationsInfo: bindActionCreators(setEditableOrganizationInfo, dispatch),
  setValidationResultOrganizationsInfo: bindActionCreators(
    setValidationResultOrganizationInfo,
    dispatch,
  ),
  updateOrganizationsInfo: bindActionCreators(updateOrganizationInfo, dispatch),
  getPolicies: bindActionCreators(getPolicies, dispatch),
  // getDashboardData: bindActionCreators(getDashboardData, dispatch),
  setSelectedPeriodIndex: bindActionCreators(setSelectedPeriodIndex, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default
connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(OrganizationEditForm));

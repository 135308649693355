import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {
  ClientActions,
  ClientOsIcon,
  EntityEditingCard,
} from '../index';
import {ClientEditForm} from '../../containers';
import {ACCESS_RIGHTS} from '../../constants';

import style from './style';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.CLIENT_EDIT],
};

class ClientDetails extends Component {
  get title() {
    const {client} = this.props;
    return (
      <div>
        <ClientOsIcon iconKey={client.osIconKey} osName={client.osName} />
        {client.clientName}
      </div>
    );
  }

  get actions() {
    const {
      client,
      handleDisable,
      handleEnable,
      handleForget,
      handleUninstall,
      handleCancelUninstall,
    } = this.props;
    return (
      <ClientActions
      client={client}
      handleDisable={handleDisable}
      handleEnable={handleEnable}
      handleForget={handleForget}
      handleUninstall={handleUninstall}
      handleCancelUninstall={handleCancelUninstall}
      />
    );
  }

  render() {
    const {
      handleClickButtonBack,
      handleClickButtonEdit,
      handleClickButtonCancelEdit,
      handleClickButtonSave,
      isEditMode,
      isValidClientInfo,
    } = this.props;

    return (
      <EntityEditingCard
        title={this.title}
        entityType={I18n.t('entitiesTypes.client')}
        isThereDeleteBlock={false}
        isTherePathBack={true}
        isThereEditBlock={true}
        isEditMode={isEditMode}
        isValidEntity={isValidClientInfo}
        isFullscreen={false}
        handleClickButtonBack={handleClickButtonBack}
        handleClickButtonEdit={handleClickButtonEdit}
        handleClickButtonCancel={handleClickButtonCancelEdit}
        handleClickButtonSave={handleClickButtonSave}
        customCardContentClass="card__content_client"
        customCardClass="card__client"
        additionalActions={this.actions}
        accessRights={rightAvailabilityMap}
        content={
          <ClientEditForm />
        }
      />
    );
  }
}

ClientDetails.propTypes = {
  client: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidClientInfo: PropTypes.bool.isRequired,
  handleDisable: PropTypes.func.isRequired,
  handleEnable: PropTypes.func.isRequired,
  handleForget: PropTypes.func.isRequired,
  handleUninstall: PropTypes.func.isRequired,
  handleCancelUninstall: PropTypes.func.isRequired,
};

export default withStyles(style)(ClientDetails);
